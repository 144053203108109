<!--To imrpove load speed for the admin data form list, they are loaded as a reference -->
<!--ObjectComponent will resolve it if its object is a reference-->

<div class="reference-spinner-container" *ngIf="!object || isReference(object)">
    <mat-progress-spinner
        diameter="40"
        class="inline"
        mode="indeterminate"
    ></mat-progress-spinner>
    <p>Building Form...</p>
</div>
<div class="wrapper absolute flex stack" *ngIf="fullObject && fullObject?.displayName">
    <div mat-dialog-title class="flex" *ngIf="dialogReference">
        <div>{{ displayName }}</div>
        <div class="spacer"></div>
        <button
            mat-icon-button
            matTooltip="History"
            *ngIf="fullObject?.id"
            (click)="getAllVersions()"
        >
            <mat-icon>history</mat-icon>
        </button>
        <button
            mat-icon-button
            matTooltip="Form Properties"
            tab-index="100"
            *ngIf="!historyMode"
            (click)="onConfigureForm($event)"
        >
            <mat-icon>construction</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="builder-dialog" *ngIf="fullObject?.displayName">
        <as-split
            direction="horizontal"
            class="flexible"
            cdkDropListGroup
            *ngIf="!isReference(object!) && object?.displayName"
        >
            <as-split-area class="split-pane" [size]="25" [order]="0" cdkScrollable>
                <div class="container flex stack builder-available-panel">
                    <div
                        class="builder-available-search full"
                        *ngIf="!!availableFields.length && !historyMode"
                    >
                        <mat-form-field color="primary" class="full">
                            <mat-icon matPrefix class="search-icon">search</mat-icon>
                            <mat-label>Search Data Fields</mat-label>
                            <input
                                matInput
                                #search
                                placeholder="Enter at least 3 characters to search"
                                [formControl]="searchTermControl"
                                (focusout)="onFocusOut($event)"
                            />
                            <button
                                matSuffix
                                type="button"
                                mat-icon-button
                                (click)="resetSearchFilter($event)"
                                *ngIf="!!searchTermControl.value"
                            >
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div
                        class="builder-toolbox"
                        [class.builder-toolbox-drop]="
                            !isDraggingToolboxField && draggingOver
                        "
                        *ngIf="!historyMode && !loadingFields"
                    >
                        <p class="builder-available" *ngIf="!!availableFields.length">
                            Control Fields
                        </p>
                        <div
                            class="wrapper flex stack builder-available-list"
                            cdkDropList
                            [cdkDropListAutoScrollStep]="autoScrollStep"
                            [cdkDropListSortingDisabled]="true"
                            [cdkDropListConnectedTo]="dragDropService.dropLists"
                            [cdkDropListEnterPredicate]="allowDropPredicate"
                            (cdkDropListEntered)="onDragEnter($event)"
                            (cdkDropListExited)="onDragExit($event)"
                            (cdkDropListDropped)="onDrop($event)"
                        >
                            <div
                                class="builder-field"
                                [class.builder-hover]="!draggingOver"
                                *ngFor="let field of controlFields"
                                cdkDrag
                                [cdkDragData]="field"
                                (cdkDragStarted)="onDragStart($event)"
                                (cdkDragMoved)="onDragMoved($event)"
                                (cdkDragEnded)="onDragEnd($event)"
                                (cdkDragReleased)="onDragReleased($event)"
                            >
                                <div
                                    class="builder-placeholder"
                                    *cdkDragPlaceholder
                                ></div>
                                <ng-container *ngIf="!isGroupField(field)">
                                    <data-form-field
                                        class="builder-field"
                                        *cdkDragPreview
                                        [field]="field"
                                        [control]="field.previewControl"
                                        [mode]="mode"
                                    ></data-form-field>
                                </ng-container>
                                <ng-container *ngIf="isGroupField(field)">
                                    <div *cdkDragPreview class="row builder-field">
                                        <div class="full between">
                                            <h3>{{ field.field.displayName }}</h3>
                                        </div>
                                        <p class="full subtitle" *ngIf="!viewOnly">
                                            {{ field.field.description }}
                                        </p>
                                    </div>
                                </ng-container>
                                <div class="flex center-align">
                                    <div
                                        *ngIf="useDragHandles"
                                        class="toolbox-drag-handle fixed"
                                        cdkDragHandle
                                    >
                                        <svg
                                            width="24px"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                                            ></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </div>
                                    <div
                                        class="flexible flex stack builder-available-field"
                                    >
                                        <div>{{ field.field.displayName }}</div>
                                        <div class="secondary small">
                                            {{ field.field.name }}
                                        </div>
                                    </div>
                                    <div class="flex stack type-owner">
                                        <div
                                            class="secondary"
                                            *ngIf="field.field.data_type"
                                        >
                                            {{ field.field.data_type!.displayName }}
                                        </div>
                                        <div class="small">
                                            <mat-chip-set>
                                                <mat-chip
                                                    *ngIf="!field.field.owner"
                                                    color="primary"
                                                    selected
                                                >
                                                    MedaSystems
                                                </mat-chip>
                                                <mat-chip
                                                    *ngIf="!!field.field.owner"
                                                    color="accent"
                                                    selected
                                                >
                                                    {{ field.field.owner.displayName }}
                                                </mat-chip>
                                            </mat-chip-set>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="builder-available">
                            <span>Data Field</span>
                            <button
                                mat-icon-button
                                *ngIf="!loadingFields"
                                type="button"
                                (click)="addDataField($event)"
                            >
                                <mat-icon>add_box</mat-icon>
                            </button>
                        </div>
                        <div
                            class="wrapper flex stack builder-available-list"
                            cdkDropList
                            [cdkDropListAutoScrollStep]="autoScrollStep"
                            [cdkDropListSortingDisabled]="true"
                            [cdkDropListConnectedTo]="dragDropService.dropLists"
                            [cdkDropListEnterPredicate]="allowDropPredicate"
                            (cdkDropListEntered)="onDragEnter($event)"
                            (cdkDropListExited)="onDragExit($event)"
                            (cdkDropListDropped)="onDrop($event)"
                        >
                            <div
                                class="builder-loading error not-found"
                                *ngIf="!availableFields.length && loadingFields"
                            >
                                Loading available fields...
                            </div>
                            <div
                                class="builder-field"
                                [class.builder-hover]="!draggingOver"
                                *ngFor="let field of filteredFields"
                                cdkDrag
                                [cdkDragData]="field"
                                (cdkDragStarted)="onDragStart($event)"
                                (cdkDragMoved)="onDragMoved($event)"
                                (cdkDragEnded)="onDragEnd($event)"
                                (cdkDragReleased)="onDragReleased($event)"
                            >
                                <div
                                    class="builder-placeholder"
                                    *cdkDragPlaceholder
                                ></div>
                                <data-form-field
                                    class="builder-field"
                                    *cdkDragPreview
                                    [field]="field"
                                    [control]="field.previewControl"
                                    [mode]="mode"
                                ></data-form-field>
                                <div class="flex center-align">
                                    <div
                                        *ngIf="useDragHandles"
                                        class="toolbox-drag-handle fixed"
                                        cdkDragHandle
                                    >
                                        <svg
                                            width="24px"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                                            ></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </div>
                                    <div
                                        class="flexible flex stack builder-available-field"
                                    >
                                        <div>{{ field.field.displayName }}</div>
                                        <div class="secondary small">
                                            {{ field.field.name }}
                                        </div>
                                    </div>
                                    <div class="flex stack type-owner">
                                        <div
                                            class="secondary"
                                            *ngIf="field.field.data_type"
                                        >
                                            {{ field.field.data_type!.displayName }}
                                        </div>
                                        <div class="small">
                                            <mat-chip-set>
                                                <mat-chip
                                                    *ngIf="!field.field.owner"
                                                    color="primary"
                                                    selected
                                                >
                                                    MedaSystems
                                                </mat-chip>
                                                <mat-chip
                                                    *ngIf="!!field.field.owner"
                                                    color="accent"
                                                    selected
                                                >
                                                    {{ field.field.owner.displayName }}
                                                </mat-chip>
                                            </mat-chip-set>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="historyMode">
                        <h3 class="builder-available">Versions</h3>
                        <div
                            *ngIf="!versions?.length"
                            class="builder-loading error not-found"
                        >
                            Loading Versions ...
                        </div>
                        <mat-list role="list" class="version-list">
                            <div
                                *ngFor="let form of versions; let i = index"
                                class="version"
                            >
                                <mat-list-item
                                    [class.active]="activeVersion === i"
                                    role="listitem"
                                    (click)="setSelectedFormVersion($event, form, i)"
                                >
                                    <div class="version-header">
                                        <p>Version: {{ form.version }}</p>
                                        <button
                                            mat-icon-button
                                            matTooltip="Version Properties"
                                            tab-index="100"
                                            (click)="onConfigureForm($event, form)"
                                        >
                                            <mat-icon>construction</mat-icon>
                                        </button>
                                    </div>

                                    <p class="secondary">
                                        Last Edit:
                                        {{
                                            form.last_modified | localizedDate: "medium"
                                        }}
                                    </p>
                                    <p class="secondary">
                                        By: {{ form.last_modified_by?.displayName }}
                                    </p>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </div>
                        </mat-list>
                    </div>
                </div>
            </as-split-area>
            <as-split-area class="split-pane" [size]="75" [order]="2" cdkScrollable>
                <div class="wrapper flex stack builder-border">
                    <div class="builder-preview">Form Preview</div>
                    <div class="container flexible flex stack">
                        <div class="flexible flex stack">
                            <p class="subtitle" *ngIf="!viewOnly && !!fullObject">
                                {{ fullObject.description }}
                            </p>
                            <data-form-editor-group
                                class="flexible flex stack"
                                [class.no-interactions]="historyMode"
                                [form]="fullObject"
                                [mode]="mode"
                                [viewOnly]="viewOnly"
                                [autoScrollStep]="autoScrollStep"
                                [useDragHandles]="useDragHandles"
                                [formGroup]="formGroup"
                                [group]="formGroup"
                                [fields]="formFields"
                                [productOptions]="productOptions"
                                (formFieldAdded)="
                                    onFormFieldAdded($event.field, $event.group)
                                "
                                (formFieldRemoved)="onFormFieldRemoved($event.field)"
                                (formFieldChanged)="
                                    onFormFieldChanged($event.field, $event.group)
                                "
                            ></data-form-editor-group>
                        </div>
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </mat-dialog-content>
    <div class="row flex object-actions mat-dialog-actions" *ngIf="dialogReference">
        <div class="spacer"></div>
        <button
            type="button"
            *ngIf="!loading"
            class="fixed"
            mat-flat-button
            mat-dialog-close
        >
            Cancel
        </button>
        <button
            type="button"
            *ngIf="hasChanged && !loading"
            mat-flat-button
            color="primary"
            (click)="onRevert()"
        >
            Undo
        </button>
        <div
            [matTooltip]="disabledReason"
            [matTooltipDisabled]="hasChanged && isValid"
            *ngIf="!historyMode"
        >
            <button
                type="submit"
                *ngIf="!loading"
                mat-flat-button
                color="accent"
                [disabled]="!hasChanged || !isValid"
                (click)="hasChanged && isValid && onSave()"
            >
                <span *ngIf="mode == ObjectViewMode.Create">Create Form</span>
                <span *ngIf="mode == ObjectViewMode.Edit">Update Form</span>
            </button>

            <mat-spinner
                *ngIf="loading"
                mode="indeterminate"
                [diameter]="50"
            ></mat-spinner>
        </div>
    </div>
</div>
