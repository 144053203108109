import {
    APIListResult,
    APIObject,
    ObjectOrReference,
    ObjectReference,
    PaginatedList,
} from "../../services/models/api-object";
import { APIService } from "../../services/api.service";
import { EventEmitter } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subject, Subscription } from "rxjs";
import {
    catchError,
    tap,
    debounceTime,
    filter,
    finalize,
    map,
    concatMap,
} from "rxjs/operators";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { RequestFilter } from "./request";
import { PageEvent } from "@angular/material/paginator";

export type Ordering = { field: string; ascending: boolean };
export type OrderingList = Ordering[];
export type Search = {
    term: string | undefined | null;
    ordering: OrderingList;
    skipActivity?: boolean;
    filters?: RequestFilter;
};

export class SearchableList<T extends APIObject> extends DataSource<
    ObjectOrReference<T>
> {
    protected static minimumFilterLength: number = 3;
    public static defaultPageSize: number = 10;

    list: PaginatedList<T>;
    minimumFilterLength: number = SearchableList.minimumFilterLength;

    searchError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>(
        true,
    );
    hasLoaded: boolean = false;
    listChanged: BehaviorSubject<readonly ObjectOrReference<T>[]> = new BehaviorSubject<
        readonly ObjectOrReference<T>[]
    >([]);
    loadingProgress: boolean = false;
    searchEvent: BehaviorSubject<Search>;
    searchComplete: Subject<any>; //only emits when searching is set to false
    filter: (filters: RequestFilter) => RequestFilter;
    postSearch: (list: PaginatedList<T>) => PaginatedList<T> = (
        list: PaginatedList<T>,
    ) => {
        return list;
    };
    protected renderChangesSubscription?: Subscription = undefined;

    get api(): APIService<T> {
        return this.service;
    }
    get pageSize() {
        return this._pageSize;
    }

    constructor(
        protected service: APIService<T>,
        f: (filters: RequestFilter) => RequestFilter = (filters: RequestFilter) =>
            filters,
        public _pageSize: number = SearchableList.defaultPageSize,
        listTitle?: string,
    ) {
        super();
        this.list = new PaginatedList<T>(_pageSize);
        this.filter = f;

        this.searchEvent = new BehaviorSubject<Search>({
            term: null,
            ordering: [],
        });
        this.searchComplete = new Subject<T[]>();
        this.updateChangeSubscription();

        if (listTitle) {
            this.listTitle = listTitle;
        }
    }

    protected _searching: boolean = false;
    get searching(): boolean {
        return this._searching;
    }
    set searching(v: boolean) {
        this._searching = v;
        if (!v) this.searchComplete.next(this.list.items);
    }
    get items(): ObjectOrReference<T>[] {
        return this.list.items;
    }
    get count(): number {
        return this.list.count;
    }
    get searchTerm(): string | undefined | null {
        return this.searchEvent.getValue()?.term;
    }
    get ordering(): OrderingList {
        return this.searchEvent.getValue()?.ordering;
    }
    set ordering(v: OrderingList) {
        this.update(this.searchTerm, v);
    }
    get filters(): RequestFilter {
        return this._filters(this.searchEvent.getValue());
    }
    get isPaginated(): boolean {
        return this.list.pageSize != -1 && this.list.pageSize != this.list.items.length;
    }

    get canAddPage(): boolean {
        if (!this.initialized || this.list.pageSize == -1) return true;

        return this.list.page * this.list.pageSize < this.list.count;
    }
    initialized = false;

    protected updateChangeSubscription(): void {
        this.renderChangesSubscription?.unsubscribe();
        this.renderChangesSubscription = this.searchEvent
            .pipe(
                debounceTime(250),
                filter((search: Search) => {
                    this.loadingProgress = true;
                    return (
                        !search.term || search.term.length >= this.minimumFilterLength
                    );
                }),
                filter(() => {
                    return this.canAddPage;
                }),
                tap(() => {
                    this.searching = true;
                }),
                concatMap((search: Search) => {
                    return this.search(search);
                }),
                tap((list: PaginatedList<T>) => {
                    if (!this.pageEvent) {
                        if (this.currentPage !== list.page - 1) {
                            //most likely a filter was applied while not on the first page
                            this.currentPage = list.page - 1;
                            this._pageSize = list.pageSize;
                        }
                    } else this.pageEvent = undefined;
                }),
                map((list: PaginatedList<T>) => {
                    return this.postSearch(list);
                }),
                finalize(() => {
                    this.searching = false;
                    this.hasLoaded = true;
                    if (!this.initialized) {
                        this.initialized = true;
                    }
                }),
            )
            .subscribe((list: PaginatedList<T>) => {
                this.list = list;
                this.listChanged.next(this.list.items);
                this.loadingProgress = false;
            });
    }
    connect(
        collectionViewer: CollectionViewer,
    ): Observable<readonly ObjectOrReference<T>[]> {
        if (!this.renderChangesSubscription) this.updateChangeSubscription();

        return this.listChanged.asObservable();
    }
    disconnect(collectionViewer?: CollectionViewer): void {
        this.renderChangesSubscription?.unsubscribe();
        this.renderChangesSubscription = undefined;
    }
    searchCompleteSub?: Subscription;
    refresh(skipActivity: boolean = false): void {
        this.update(this.searchTerm, undefined, skipActivity);
    }

    replaceReference(replace: T | undefined): boolean {
        let replaced = false;
        if (replace) {
            replaced = !!this.list.items.find(
                (v: ObjectOrReference<T>) =>
                    v instanceof ObjectReference && v.id == replace.id,
            );
            if (replaced) {
                this.list.items = this.list.items.map((v: ObjectOrReference<T>) => {
                    if (v instanceof ObjectReference && v.id == replace.id) {
                        return replace;
                    }
                    return v;
                });
                this.listChanged.next(this.list.items);
            }
        }
        return replaced;
    }

    update(
        searchTerm?: string | null,
        ordering?: OrderingList,
        skipActivity: boolean = false,
        newFilters?: RequestFilter,
    ): RequestFilter {
        const last = this.searchEvent.getValue();
        const filters = newFilters ?? last.filters ?? this.filters;
        this.searchEvent.next({
            term: searchTerm ?? last.term,
            ordering: ordering ?? last.ordering,
            skipActivity,
            filters: filters,
        });
        return filters;
    }

    updateItems(newItems: T[]): void {
        this.list.items = [...newItems];
        this.listChanged.next(this.list.items);
    }

    clear(pageSize: number = 10): void {
        this.list.items = [];
        this.list.count = 0;
        this.list.pageSize = pageSize;
        this.list.page = 1;
        this.listChanged.next(this.list.items);
    }

    protected _filters(search: Search): RequestFilter {
        const filters: RequestFilter = {};
        const order = search.ordering
            .map(
                (ordering: Ordering) =>
                    (ordering.ascending ? "" : "-") + ordering.field,
            )
            .join(",");
        if (order && order != "") filters["ordering"] = order;
        if (search.term && search.term.length >= this.minimumFilterLength)
            filters["contains"] = search.term;
        return this.filter(filters);
    }
    protected searchResultToPaginatedList(result: APIListResult<T>): PaginatedList<T> {
        let ret = new PaginatedList<T>(0);
        if (Array.isArray(result)) {
            ret.pageSize = result.length;
            ret.count = result.length;
            ret.page = 1;
            ret.items = [...result];
        } else if (result instanceof APIObject) {
            ret.pageSize = 1;
            ret.count = 1;
            ret.page = 1;
            ret.items = [result];
        } else {
            ret = result;
        }
        return ret;
    }
    protected search(search: Search): Observable<PaginatedList<T>> {
        const filters = this._filters(search);
        let obs = of(new PaginatedList<T>(this.list.pageSize));
        if (filters) {
            const page = this.list.pageSize == -1 ? undefined : this.list.page;
            const pageSize = this.list.pageSize == -1 ? undefined : this.pageSize;

            obs = this.service
                .list(filters, page, pageSize, !search.skipActivity)
                .pipe(map((result) => this.searchResultToPaginatedList(result)));
        }
        return obs.pipe(
            catchError((err: HttpErrorResponse) => {
                this.searchError.emit(err);
                return of(new PaginatedList<T>(this.list.pageSize));
            }),
            finalize(() => {
                this.searching = false;
                this.hasLoaded = true;
            }),
        );
    }

    listTitle = "";
    paginatorSizeOptions = [5, 10, 15, 25, 50, 75, 100]; // the default is 10

    increasePageSizeSearch(toAdd = 10) {
        if (this.canAddPage) {
            this._pageSize += toAdd;
            //will just increase page size
            this.update(null);
        }
    }
    //this is only to keep track for the paginator
    currentPage = 0;
    pageEvent?: PageEvent;
    handlePageEvent(event: PageEvent) {
        this.pageEvent = event;
        //pageIndex from PageEvent is 0 based but the Django pagination is 1 based
        this.list.page = event.pageIndex + 1;
        this._pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.update(null);

        if (this.listTitle) {
            const element = document.getElementById(this.listTitle);
            if (element) {
                element.scrollIntoView();
            }
        }
    }
    get paginatorLength() {
        return this.list.count;
    }
}
