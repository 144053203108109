<h3 mat-dialog-title>Reassign Task</h3>
<object-form [controller]="controller" [formGroup]="formGroup" [hideControls]="true">
    <div class="wrapper flex stack full">
        <mat-dialog-content>
            <div
                *ngIf="errorMessage"
                class="error bold"
                [innerHTML]="errorMessage"
            ></div>
            <div class="flex stack even-spacing">
                <div class="description" *ngIf="!!taskDescription">
                    {{ taskDescription }}
                </div>
                <div>
                    <mat-form-field class="full">
                        <mat-label>Assignee</mat-label>
                        <mat-select formControlName="member">
                            <mat-select-trigger>
                                <div *ngIf="assignee && assignee != newAccount">
                                    {{
                                    formGroup.controls.member.value?.account.displayName
                                    }}
                                </div>
                                <div
                                    *ngIf="assignee && assignee != newAccount"
                                    class="small"
                                >
                                    {{ memberContext(formGroup.controls.member.value) }}
                                </div>
                                <div *ngIf="!assignee">Unassigned</div>
                                <div *ngIf="assignee && assignee == newAccount">
                                    Add New User
                                </div>
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let member of availableAssignees"
                                [value]="member"
                            >
                                {{ member.account.displayName }}
                                <span class="small option-spacer">
                                    {{ memberContext(member) }}
                                </span>
                            </mat-option>
                            <mat-option [value]="null">Unassigned</mat-option>
                            <mat-option [value]="newAccount">Add New User</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="assignee == newAccount">
                        <mat-form-field
                            appearance="outline"
                            class="full input access-type"
                            *ngIf="isPharmaStaff"
                        >
                            <mat-label>Organization</mat-label>
                            <mat-select formControlName="override">
                                <mat-option
                                    *ngFor="let override of availableTeams"
                                    [value]="override"
                                >
                                    {{ override.team.organization.displayName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="full input">
                            <mat-label>Role</mat-label>
                            <mat-select formControlName="role">
                                <mat-option
                                    *ngFor="let role of availableRoles"
                                    [value]="role.value"
                                >
                                    {{ role.display }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="flex row">
                            <mat-form-field appearance="outline" class="column input">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="first_name" />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="column input">
                                <mat-label>Last Name</mat-label>
                                <input matInput formControlName="last_name" />
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="outline" class="full input">
                            <mat-label>Email Address</mat-label>
                            <input matInput formControlName="email" />
                            <mat-error *ngIf="!!email.errors">
                                <span *ngIf="email.errors?.invalidEmailAddress">
                                    Please enter a valid email address.
                                </span>
                                <span *ngIf="email.errors?.required">
                                    This field is required.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions form-actions>
            <div class="spacer"></div>
            <button mat-flat-button type="button" class="fixed" mat-dialog-close>
                Cancel
            </button>
            <mat-spinner
                *ngIf="committing"
                mode="indeterminate"
                [diameter]="50"
            ></mat-spinner>
            <button
                *ngIf="!committing"
                mat-flat-button
                type="button"
                class="fixed"
                color="accent"
                [disabled]="!isValid"
                (click)="isValid && onSave()"
            >
                Reassign
            </button>
        </mat-dialog-actions>
    </div>
</object-form>
