<div class="flex" *ngIf="showExportButton">
    <div class="spacer"></div>
    <button mat-flat-button class="brand-medium" (click)="export($event)">
        <mat-icon matTooltip="Download">file_download</mat-icon>
        Export Details
    </button>
</div>

<div *ngFor="let form of dataForms; let index = index" class="form-summary">
    <div class="title">{{ form.display_name }}</div>
    <data-form
        [object]="form"
        [repository]="repository"
        [mode]="ObjectViewMode.View"
        [viewOnly]="true"
        [allowExport]="false"
        [useSpinner]="false"
    ></data-form>
</div>
