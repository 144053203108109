import { HttpClient } from "@angular/common/http";
import { enableProdMode, Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class EnvironmentService {
    configUrl = "assets/config/environment.json";

    production: boolean = false;
    services: string = "services";
    authorizationClient: string = "";
    authorizationEndpoint: string = "o/authorize/";
    tokenEndpoint: string = "o/token/";
    websocketEndpoint: string = "ws/session";
    websocketScheme: string = "ws:/";
    websocketReconnectInterval: number = 10;

    auth0Client: string = "";
    auth0Domain: string = "auth-dev.medasystems.com";
    auth0Audience: string = "https://medasystems-local";

    // Properties received from the backend services
    activityTimeout: number = 1800;
    intakeDomain: string = "";

    docusignClientId: string = "";
    docusignRoot: string = "";
    docusignAuthorizationService: string = "oauth/auth";

    constructor(private http: HttpClient) {}

    load(): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            this.getEnvironmentFile().subscribe((response: any) => {
                Object.keys(response).forEach((key: string) => {
                    if (response.hasOwnProperty(key) && this.hasOwnProperty(key))
                        (this as any)[key] = response[key];
                    if (key == "production" && response[key]) enableProdMode();
                });
                resolve(true);
            });
        });
    }

    protected getEnvironmentFile(): Observable<Object> {
        return this.http.get(this.configUrl);
    }
}
