<object-form
    class="wrapper object-details"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div
        class="collapsible-section"
        *ngIf="defaultsArray?.length && mode == ObjectViewMode.Create"
    >
        <div
            class="collapsible-header flex flexible full"
            (click)="toggleDefaultValues($event)"
        >
            <div class="header-name">Default Template Values</div>
            <div class="spacer"></div>
            <mat-icon class="fixed" *ngIf="showDefaultValues">expand_less</mat-icon>
            <mat-icon class="fixed" *ngIf="!showDefaultValues">expand_more</mat-icon>
        </div>
        <div
            class="collapsible-content"
            *ngIf="showDefaultValues"
            formArrayName="default_context"
        >
            <mat-form-field
                class="full"
                *ngFor="let control of defaultsArray.controls; index as idx"
            >
                <mat-label>{{ templateFieldNames[idx] }}</mat-label>
                <input
                    matInput
                    [formControlName]="idx"
                    [placeholder]="templateFieldNames[idx]"
                />
            </mat-form-field>
        </div>
    </div>
    <div>
        <div class="flex section stack">
            <div class="title" *ngIf="mode != ObjectViewMode.Create">
                Template Details
            </div>
            <mat-form-field class="flexible">
                <mat-label>Name</mat-label>
                <mat-icon matSuffix>sticky_note_2</mat-icon>
                <input
                    matInput
                    placeholder="Template Name"
                    formControlName="name"
                    [required]="true"
                />
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
            <mat-form-field class="flexible">
                <mat-label>Message Subject</mat-label>
                <input
                    matInput
                    placeholder="Message Subject"
                    formControlName="subject"
                    [required]="true"
                />
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
            <rich-text-editor
                matInput
                [formControl]="templateControl"
                [value]="templateControl.value"
                [required]="true"
                placeholder="Template text goes here..."
            ></rich-text-editor>
        </div>
        <div
            class="wrapper flex stack section content-card"
            *ngIf="mode != ObjectViewMode.Create && defaultsArray?.length"
            formArrayName="default_context"
        >
            <div class="title">Template Variables</div>
            <mat-form-field
                class="full"
                *ngFor="let control of defaultsArray.controls; index as idx"
            >
                <mat-label>{{ templateFieldNames[idx] }}</mat-label>
                <input
                    matInput
                    [formControlName]="idx"
                    [placeholder]="templateFieldNames[idx]"
                />
            </mat-form-field>
        </div>
    </div>
</object-form>
