import { NgModule } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { AppnotificationDrawerComponent } from "./appnotification-drawer/appnotification-drawer.component";
import { CommonModule as EAPCommonModule } from "src/common/common.module";
import { CommonModule } from "@angular/common";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LocalizedDatePipe } from "src/common/utilities/localized-date.pipe";
import { MatChipsModule } from "@angular/material/chips";
import { FormsModule, ReactiveFormsModule} from "@angular/forms"
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

@NgModule({
    declarations: [AppnotificationDrawerComponent],
    exports: [AppnotificationDrawerComponent],
    imports: [
        EAPCommonModule,
        CommonModule,
        MatBadgeModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        ScrollingModule,
        MatDividerModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatChipsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [LocalizedDatePipe],
})
export class NotificationsModule {}
