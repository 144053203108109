import { APIObject, ObjectReference } from "./api-object";

export class DiscussionEntry extends APIObject {
    static object_type: string = "program.discussionentry";
    account!: ObjectReference; // Account
    timestamp!: Date; // read-only
    message!: string;

    initialize(data?: any, patch: boolean = false) {
        this._readOnly.push(...["timestamp"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "account", ObjectReference);
        this.setMember(data, patch, "timestamp", Date);
        this.setMember(data, patch, "message");
    }
}
