import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldModule,
} from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FileUploadModule } from "ng2-file-upload";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { QuillModule } from "ngx-quill";
import { CommonModule as EAPCommonModule } from "../common/common.module";
import { AssignmentComponent } from "./components/assignment/assignment.component";
import { AssignmentGroupComponent } from "./components/assignment/assignment-group.component";
import { DelegateAssignmentDialog } from "./components/assignment/delegate-assignment.dialog";
import { TaskTableComponent } from "./components/assignment/task-table.component";
import { AddRoleDialog } from "./components/case/add-role.dialog";
import { CaseComponent } from "./components/case/case.component";
import { CaseAuditComponent } from "./components/case/case-audit/case-audit.component";
import { RejectCaseDialog } from "./components/case/reject-case.component";
import { CaseOverviewComponent } from "./components/case-overview/case-overview.component";
import { CaseSummary } from "./components/case/case-summary.component";
import { CommunicationComponent } from "./components/communication/communication.component";
import { DiscussionComponent } from "./components/discussion/discussion.component";
import { CompleteTodoComponent } from "./components/document-repository/complete-todo.component";
import { ConfirmBulkUploadDialog } from "./components/document-repository/ConfirmBulkUpload";
import { DocumentComponent } from "./components/document-repository/document.component";
import { DocumentRepositoryComponent } from "./components/document-repository/document-repository.component";
import { HelpDialogComponent } from "./components/help-dialog/help-dialog.component";
import { InquiryComponent } from "./components/inquiry/inquiry.component";
import { InfinitePatientsComponent } from "./components/patients/infinite-patients.component";
import { PaginatedPatientsComponent } from "./components/patients/paginated-patients.component";
import { PatientsComponent } from "./components/patients/patients.component";
import { AddProgramAdminDialog } from "./components/program/add-admin.dialog";
import { ProgramComponent } from "./components/program/program.component";
import { ProgramListComponent } from "./components/program/program-list.component";
import { CreateRequestDialog } from "./components/request/create-request.component";
import { GlobalShipmentListComponent } from "./components/shipment/global-shipment-list.component";
import { ShipmentComponent } from "./components/shipment/shipment.component";
import { ShipmentListComponent } from "./components/shipment/shipment-list.component";
import { TeamComponent } from "./components/team/team.component";
import { TodosComponent } from "./components/todos/todos.component";
import { DocusignTabsDialog } from "../integration/docusign/tabs/docusign-tabs.dialog";
import { ServicesModule } from "../services/services.module";
import { BdcWalkModule } from "third-party/bdc-walkthrough/src/public-api";
import { FrequencyAssignmentDialog } from "./components/assignment/frequency-assignment.dialog";

@NgModule({
    imports: [
        BdcWalkModule,
        CommonModule,
        DragDropModule,
        EAPCommonModule,
        FileUploadModule,
        FontAwesomeModule,
        FormsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        PdfViewerModule,
        ReactiveFormsModule,
        ServicesModule,
        ScrollingModule,
        QuillModule.forRoot(),
    ],
    declarations: [
        AddProgramAdminDialog,
        AddRoleDialog,
        AssignmentComponent,
        AssignmentGroupComponent,
        CaseAuditComponent,
        CaseComponent,
        CaseOverviewComponent,
        CaseSummary,
        CommunicationComponent,
        CompleteTodoComponent,
        ConfirmBulkUploadDialog,
        CreateRequestDialog,
        DelegateAssignmentDialog,
        DiscussionComponent,
        DocumentComponent,
        DocumentRepositoryComponent,
        DocusignTabsDialog,
        GlobalShipmentListComponent,
        HelpDialogComponent,
        InfinitePatientsComponent,
        InquiryComponent,
        PaginatedPatientsComponent,
        PatientsComponent,
        ProgramComponent,
        ProgramListComponent,
        RejectCaseDialog,
        ShipmentComponent,
        ShipmentListComponent,
        TaskTableComponent,
        TeamComponent,
        TodosComponent,
        FrequencyAssignmentDialog,
    ],
    exports: [
        DocumentRepositoryComponent,
        InfinitePatientsComponent,
        PaginatedPatientsComponent,
        PatientsComponent,
        ProgramListComponent,
        TodosComponent,
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, // NOSONAR
            useValue: { appearance: "outline" },
        },
    ],
})
export class ProgramModule {}
