import { APIObject, ObjectOrReference, ObjectReference } from "./api-object";
import { Assignment } from "./assignment";
import { Case } from "./case";
import { DataForm } from "./data";
import { DiscussionEntry } from "./discussion";
import { Document } from "./document";
import { Inquiry } from "./inquiry";
import { Message } from "./message";

export type AppNotificationRepository = Case | Inquiry;
export class AppNotification extends APIObject {
    static object_type: string = "notifications.appnotification";
    static readonly maxNotifications = 9;

    static matBadge(notifiations?: number) {
        if (!notifiations) return undefined;

        return notifiations > AppNotification.maxNotifications ?
                `${AppNotification.maxNotifications}+`
            :   `${notifiations}`;
    }

    repository!: ObjectOrReference<AppNotificationRepository>;
    account!: ObjectReference;
    message!: string;
    object!: ObjectOrReference<APIObject>;
    is_read!: boolean;
    detail_message!: string;

    initialize(data: any, patch: boolean): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "repository", ObjectReference);
        this.setMember(data, patch, "account", ObjectReference);
        this.setMember(data, patch, "message");
        this.setMember(data, patch, "is_read");
        this.setMember(data, patch, "object", ObjectReference);
        this.setMember(data, patch, "detail_message");
    }

    get isRead() {
        return this.is_read;
    }

    get detailMessage() {
        return this.detail_message;
    }
}
