import { Directive, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { DataForm, DataType } from "src/services/models/data";
import { ObjectViewMode } from "../../object.component";

@Directive()
export class BaseDataTypeComponent {
    @Input() type?: DataType;
    @Input() attributes: any;
    @Input() control!: UntypedFormControl;
    @Input() mode: ObjectViewMode = ObjectViewMode.View;
    @Input() label?: string;
    @Input() required: boolean = false;
    @Input() compoundIndex: number = 0;
    @Input() form?: DataForm;

    get inputClass(): string {
        if (!this.attributes?.layout) return "full input";

        const width: string = this.attributes.layout.width;
        if (width == "25%") return "quarter input";
        else if (width == "50%") return "half input";
        else if (width == "75%") return "three-fourth input";
        else if (width == "100%") return "full input";
        else if (width) return width + " input";
        else return "full input";
    }
    get hasError(): boolean {
        return !!(
            this.control?.invalid &&
            (this.control?.touched || this.control?.dirty)
        );
    }
    get errorMessage(): string | undefined {
        return this.getErrorMessage();
    }
    get placeholder(): string {
        return this.getPlaceholder();
    }
    get instructions(): string {
        return this.attributes?.instructions;
    }

    protected getErrorMessage(): string | undefined {
        if (this.control?.hasError("required")) return "This field is required.";
        else if (this.control?.hasError("pattern"))
            return "Please enter a valid input.";
        if (this.control?.hasError("invalidEmailAddress"))
            return "Please Enter a valid email address.";
        return undefined;
    }
    protected getPlaceholder(): string {
        return (
            this.attributes?.placeholder ||
            this.attributes?.alt?.display_name ||
            this.label ||
            ""
        );
    }

    get isRequired(): boolean {
        return this.required;
    }

    get hasValues(): boolean {
        return !!this.control.value;
    }
    get displayValue(): string {
        return this.control.value;
    }
    get viewOnly(): boolean {
        return this.mode == ObjectViewMode.View;
    }
}
