import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { DataFieldDefinition } from "../../../services/models/organization";
import { DataField, Inquiry } from "../../../services/models/inquiry";
import { ObjectViewMode } from "../object.component";
import { ObjectReference } from "../../../services/models/api-object";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { Case } from "../../../services/models/case";

@Component({
    selector: "data-fields",
    templateUrl: "./data-fields.component.html",
    styleUrls: ["./data-fields.component.scss"],
})
export class DataFieldsComponent extends SessionComponent {
    protected data_: any;
    protected dataFields_: DataFieldDefinition[] = [];
    protected dataFieldValues_: DataField[] = [];
    editing?: DataField;
    isAdding: boolean = false;
    formGroup?: UntypedFormGroup;

    get data(): any {
        return this.data_;
    }
    @Input() set data(v: any) {
        this.data_ = v;
        this.updateDataFieldValues();
    }
    @Output() dataChange: EventEmitter<any> = new EventEmitter<any>(true);
    @Output() isEditing: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    @Input() repository: Case | Inquiry | ObjectReference | undefined;

    @Input() tab!: string;
    @Output() moveInternalToShared = new EventEmitter();
    triggerShareEvent(field: string): void {
        this.moveInternalToShared.emit({ [field]: this.data[field] });
    }

    @Input() set dataFields(v: DataFieldDefinition[]) {
        this.dataFields_ = v;
        this.updateDataFieldValues();
    }
    get dataFields(): DataFieldDefinition[] {
        return this.dataFields_;
    }

    @Input() mode: ObjectViewMode = ObjectViewMode.View;
    @Input() owner?: ObjectReference;

    get fieldCount(): number {
        return this.dataFieldValues.length;
    }
    get dataFieldValues(): DataField[] {
        return this.dataFieldValues_;
    }
    get fieldType(): string {
        return this.formGroup?.value.type || "text.short";
    }
    filteredOptions?: Observable<DataFieldDefinition[]>;
    get isInquiry(): boolean {
        return this.repository?.type === "program.inquiry";
    }

    constructor(protected fb: UntypedFormBuilder) {
        super();
    }

    editField(event: MouseEvent, field: DataField): void {
        this.terminateEvent(event);
        this.formGroup = this.fb.group({
            displayName: [field.displayName, Validators.required],
            value: [field.value, Validators.required],
            owner: [field.owner],
            creator: [field.creator],
            type: [field.type],
        });
        this.filteredOptions = this.formGroup.get("displayName")?.valueChanges.pipe(
            startWith(""),
            map((v: string) =>
                typeof v == "object" ?
                    (v as DataFieldDefinition).displayName?.toLowerCase() ?? ""
                :   v.toLowerCase(),
            ),
            map((v: string) =>
                this.dataFields.filter(
                    (dfd: DataFieldDefinition) =>
                        dfd.displayName?.toLowerCase().includes(v),
                ),
            ),
        );
        this.formGroup
            .get("displayName")
            ?.valueChanges.pipe(filter((v: any) => typeof v == "object"))
            .subscribe(
                (v: DataFieldDefinition) =>
                    this.formGroup?.get("type")?.setValue(v.type),
            );
        setTimeout(() => {
            this.isEditing.emit(true);
            this.editing = field;
        });
    }

    addLineOnEnter(event: Event, input: HTMLTextAreaElement | HTMLInputElement): void {
        this.terminateEvent(event);
        const currentCursorPosition = input.selectionStart;
        const oldValue = this.formGroup?.value.value;
        // this adds a linebreak to where ever the cursor is in the text area
        const newValue =
            oldValue.slice(0, currentCursorPosition) +
            "\n" +
            oldValue.slice(currentCursorPosition);
        this.formGroup?.get("value")?.setValue(newValue);
    }
    addField(event: MouseEvent): void {
        const field = {
            name: "new.data.field",
            creator: this.currentAccount?.id,
            owner: this.owner?.id,
            type: "text.short",
        };
        this.dataFieldValues_.push(field);
        this.isAdding = true;
        this.editField(event, field);
    }
    cancelEdit(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.isAdding)
            this.dataFieldValues_ = this.dataFieldValues.filter(
                (df: DataField) => df !== this.editing,
            );
        this.editing = undefined;
        this.isAdding = false;
        this.isEditing.emit(false);
    }
    commitEdit(event: MouseEvent): void {
        if (this.editing && this.formGroup) {
            if (typeof this.formGroup.value.displayName === "object") {
                const def = this.formGroup.value.displayName as DataFieldDefinition;
                this.editing.displayName = def.displayName;
                this.editing.name = def.field!;
                this.editing.type = def.type;
            } else {
                this.editing.displayName = this.formGroup.value.displayName;
                this.editing.name = this.displayNameToFieldName(
                    this.editing.displayName!,
                );
                this.editing.type = this.formGroup.value.type;
            }
            this.editing.owner = this.formGroup.value.owner;
            this.editing.creator = this.formGroup.value.creator;
            this.editing.value = this.formGroup.value.value;
            this.dataChange.emit(this.fieldsToData(this.dataFieldValues));
            this.sortDataFields();
        }
        this.isAdding = false;
        this.cancelEdit(event);
    }
    canChangeFieldType(field: DataField): boolean {
        return (
            !this.dataFields.find(
                (dfd: DataFieldDefinition) => dfd.field == field.name,
            ) && typeof this.formGroup?.value.displayName != "object"
        );
    }
    changeFieldType(event: MouseEvent, type: string): void {
        this.terminateEvent(event);
        this.formGroup?.get("type")?.setValue(type);
    }

    optionToDisplayName(dfd: DataFieldDefinition): string {
        return dfd?.displayName ?? "";
    }
    displayNameToFieldName(name: string): string {
        const field = name
            .trim()
            .replace(/\.+$/, "")
            .replace(/\.+/g, " ")
            .replace(/ +/g, ".")
            .toLowerCase();
        let index = 1;
        let fieldName = field;
        while (this.dataFieldValues.find((df: DataField) => df.name == fieldName))
            fieldName = field + "." + index++;
        return fieldName;
    }

    protected fieldsToData(fields: DataField[]): any {
        const data: any = {};
        for (let field of fields) {
            const fieldData: any = { ...field };
            delete fieldData.name;
            data[field.name] = fieldData;
        }
        return data;
    }
    protected parseDataField(field: string, data: any): DataField {
        let value: any = data;
        let owner, creator, type, displayName;
        if (typeof data === "object" && !!data) {
            if (data.hasOwnProperty("value")) value = data["value"];
            if (data.hasOwnProperty("owner")) owner = data["owner"];
            if (data.hasOwnProperty("creator")) creator = data["creator"];
            if (data.hasOwnProperty("type")) type = data["type"];
            if (data.hasOwnProperty("displayName")) displayName = data["displayName"];
        }
        const def = this.dataFields.find(
            (dfd: DataFieldDefinition) => dfd.field == field,
        );
        const order = def?.order === undefined ? -1 : def.order;
        type = def?.type ?? type;
        displayName = (def?.displayName ?? displayName) || field;
        if (!type) {
            // Try to infer the type from the value - by default we'll assume it's text based
            type = "text.short";
            if (typeof value === "string") {
                if (value.length > 64) type = "text.long";
                else if (value.indexOf("\n") != -1) type = "text.long"; // NOSONAR
            }
        }
        return {
            name: field,
            value: value,
            owner: owner,
            creator: creator,
            type: type,
            displayName: displayName,
            order: order,
        };
    }
    protected updateDataFieldValues(): void {
        const keys = this.data ? Object.keys(this.data) : [];
        keys.sort((a: string, b: string) => a.localeCompare(b));
        const values = keys
            .map((key: string) => this.parseDataField(key, this.data[key]))
            .filter(
                (field: DataField) =>
                    field.name !== "sender" &&
                    field.name !== "completion" &&
                    field.name !== "metadata" &&
                    field.name !== "source" &&
                    !field.name.startsWith("*"),
            );
        if (this.editing) {
            const found = values.find(
                (field: DataField) => field.name == this.editing?.name,
            );
            if (found) {
                found.value = this.editing.value;
                found.type = this.editing.type;
                this.editing = found;
            } else {
                values.push(this.editing);
            }
        }
        this.dataFieldValues_ = values;
        this.sortDataFields();
    }
    protected sortDataFields(): void {
        this.dataFieldValues_.sort((a: DataField, b: DataField) => {
            const aOrder = a.order ?? -1;
            const bOrder = b.order ?? -1;
            if (aOrder == -1 && bOrder == -1) {
                const aname = a.displayName ?? a.name;
                const bname = b.displayName ?? b.name;
                if (aname < bname) return -1;
                if (aname == bname) return 0;
                return 1;
            } else if (aOrder == -1) {
                return 1;
            } else if (bOrder == -1) {
                return -1;
            }

            return aOrder - bOrder;
        });
    }

    canEditField(field: DataField): boolean {
        /* Editing of data fields are disabled for now until the rules for editing data are decided

        const owner = field.owner || this.owner?.id;
        const isOwner = !!field.owner && this.currentAccount?.id === owner;
        const isCreator = !!field.creator && this.currentAccount?.id === field.creator;
        let isAdministrator = false;
        let notViewStaff = true;
        for(const r of this.currentAccount!.roles) {
            if(r.role.includes("admin")) isAdministrator = true

            if(r.role.includes("view")) notViewStaff = false
        }

        return (this.mode !== ObjectViewMode.View && (isOwner || isAdministrator || isCreator) && notViewStaff);
        */
        return false;
    }

    get canAdd(): boolean {
        return this.mode !== ObjectViewMode.View;
    }
}
