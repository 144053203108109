import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { mergeMap, debounceTime, filter } from "rxjs/operators";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { ChangeDetectorRef, ViewChild, Component, Input } from "@angular/core";
import { Sort } from "@angular/material/sort";
import {
    DataType,
    DataTypeLookupValue,
    CompoundDataType,
    CompoundPropertyTypes,
} from "src/services/models/data";
import { ObjectComponent, ObjectViewMode } from "../../object.component";
import { SearchableList } from "src/common/utilities/searchable-list";
import { Program } from "src/services/models/program";
import { GenerateUniqueIdentifier } from "src/common/utilities/utilities";
import { DataTypeService } from "src/services/data.services";
import {
    ErrorStateMatcher,
    ShowOnDirtyErrorStateMatcher,
} from "@angular/material/core";
import { ObjectFactory, ObjectOrReference } from "src/services/models/api-object";
import { of } from "rxjs";
import { MatTable } from "@angular/material/table";

@Component({
    selector: "data-type-editor",
    templateUrl: "./data-type.component.html",
    styleUrls: ["../data-admin.component.scss"],
})
export class DataTypeEditorComponent extends ObjectComponent<DataType> {
    @ViewChild("lookupTable") lookupTable?: MatTable<DataTypeLookupValue>;
    @ViewChild("compoundTable") compoundTable?: MatTable<CompoundDataType>;
    @Input() set dataTypes(v: ObjectOrReference<DataType>[]) {
        this._availableDataTypes = v;
        this.updateCompoundTypeChildren();
    }
    get availableDataTypes(): ObjectOrReference<DataType>[] {
        return this._availableDataTypes.filter(
            (type: ObjectOrReference<DataType>) => type.id != this.fullObject?.id,
        );
    }
    protected _availableDataTypes: ObjectOrReference<DataType>[] = [];

    // This allows us to show errors as soon as they appear instead of waiting for blur
    errorMatcher: ErrorStateMatcher = new ShowOnDirtyErrorStateMatcher();
    displayNameMaxLength: number = 64; // maximum display name length to prevent users from writing books in display names

    get type(): string | undefined {
        return this.formGroup.get("_type")?.value;
    }
    get compoundTypeControl() {
        return this.compoundTypeFormGroup.get("type") as FormControl;
    }
    get availableDataTypesObs() {
        return of(this.availableDataTypes);
    }
    get repositoryType(): string {
        switch (this.fullObject?.owner?.type) {
            case "program.program":
                return "program";
            case "program.case":
                return "case";
            case "program.inquiry":
                return "case";
            case "iam.organization":
                return "organization";
            case "iam.account":
                return "account";
        }
        return "repository";
    }
    get identifierControl(): AbstractControl | null {
        return this.formGroup.get("name");
    }

    get lookupValues(): DataTypeLookupValue[] {
        return this.formGroup.get("lookup")?.value || [];
    }
    set lookupValues(v: DataTypeLookupValue[]) {
        this.formGroup.get("lookup")?.setValue(v);
    }
    get sortedLookupValues(): DataTypeLookupValue[] {
        return this._sortedLookupValues ?? this.lookupValues;
    }
    protected _sortedLookupValues?: DataTypeLookupValue[];
    get isLookupSorted(): boolean {
        return !!this._sortedLookupValues;
    }

    get compoundTypes(): CompoundDataType[] {
        return this.formGroup.get("compound")?.value || [];
    }
    set compoundTypes(v: CompoundDataType[]) {
        this.formGroup.get("compound")?.setValue(v);
    }
    get sortedCompoundTypes(): CompoundDataType[] {
        return this._sortedCompoundTypes ?? this.compoundTypes;
    }
    protected _sortedCompoundTypes?: CompoundDataType[];
    get isCompoundSorted(): boolean {
        return !!this._sortedCompoundTypes;
    }
    get compoundPropertyTypes(): CompoundPropertyTypes {
        return this.fullObject?.attributes?.propertyTypes ?? {};
    }

    lookupValueFormGroup: FormGroup;
    _editLookupValue?: DataTypeLookupValue;
    lookupColumns: string[] = ["display_name", "value", "actions"];
    willOverride: DataType[] = [];

    compoundTypeFormGroup: FormGroup;
    _editCompoundType?: CompoundDataType;
    revertCompoundType?: CompoundDataType;
    compoundColumns: string[] = [
        "child",
        "display_name",
        "name",
        "required",
        "multiple",
        "actions",
    ];

    constructor(
        protected service: DataTypeService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service);

        // When we're not editing lookup values or compound properties, keep a blank form group around (to prevent template warnings)
        this.lookupValueFormGroup = this.formBuilder.group({});
        this.compoundTypeFormGroup = this.formBuilder.group({});
    }
    protected createObjectForm(): UntypedFormGroup {
        const fg = this.formBuilder.group({
            _type: [null],
            description: [null],
            display_name: [
                null,
                [Validators.required, Validators.maxLength(this.displayNameMaxLength)],
            ],
            name: [null, Validators.required],
            lookup: [null],
            compound: [null],
            owner: [null],
        });
        fg.get("_type")?.valueChanges.subscribe((type: string) => {
            if (type == "compound") this.dialogReference?.updateSize("80vw");
            else this.dialogReference?.updateSize("50vw");
        });

        // Automatically generate an identifier if we haven't manually edited the field
        fg
            .get("display_name")
            ?.valueChanges.pipe(debounceTime(100))
            .subscribe((value: string) => {
                const name = GenerateUniqueIdentifier(value, []);
                if (
                    this.mode == ObjectViewMode.Create &&
                    !this.identifierControl?.touched
                )
                    this.identifierControl?.setValue(name);
            });
        return fg;
    }

    get canDelete(): boolean {
        return (
            this.currentAccount?.hasRole("object.admin", this.fullObject?.owner) ||
            this.currentAccount?.hasRole(
                "organization.administrator",
                this.fullObject?.owner,
            ) ||
            this.currentAccount?.isSystemAdministrator ||
            (this.fullObject?.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (this.fullObject?.owner as Program).organization,
                )) ||
            (this.fullObject?.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (this.fullObject?.owner as Program).organization,
                )) ||
            false
        );
    }
    get isEditing(): boolean {
        return this.mode != ObjectViewMode.View;
    }

    ngAfterViewInit(): void {
        this.identifierControl?.valueChanges
            .pipe(
                debounceTime(400),
                filter(
                    (name: string) =>
                        !!this.fullObject && name != this.fullObject?.name,
                ),
                mergeMap((name: string) =>
                    this.service.conflicts(name, [
                        this.fullObject?.owner?.id ?? "0",
                        "0",
                    ]),
                ),
            )
            .subscribe((conflicts: DataType[]) => {
                if (conflicts.length > 0) {
                    const error = conflicts.filter(
                        (type: DataType) =>
                            type.owner?.id == this.fullObject?.owner?.id,
                    );
                    this.willOverride = conflicts.filter(
                        (type: DataType) =>
                            type.owner?.id != this.fullObject?.owner?.id,
                    );
                    if (error.length)
                        this.identifierControl?.setErrors({ conflict: true });
                } else this.willOverride = [];
            });
    }

    // Converting this to a reactive form validator function
    protected isUniqueLookupValue(lookupValue: DataTypeLookupValue): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const values = this.lookupValues
                .filter((value: DataTypeLookupValue) => value != lookupValue)
                .filter((value: DataTypeLookupValue) => value.value == control.value);
            return values.length ? ({ unique: values[0] } as ValidationErrors) : null;
        };
    }

    editingLookupValue(lookupValue: DataTypeLookupValue): boolean {
        return this.isEditing && this._editLookupValue == lookupValue;
    }
    editLookupValue(lookupValue: DataTypeLookupValue): void {
        if (this.isEditing) {
            this.cancelEditLookupValue(false);
            this.lookupValueFormGroup = this.formBuilder.group({
                display_name: [
                    lookupValue.display_name,
                    [
                        Validators.required,
                        Validators.maxLength(this.displayNameMaxLength),
                    ],
                ],
                value: [
                    lookupValue.value,
                    [Validators.required, this.isUniqueLookupValue(lookupValue)],
                ],
            });
            this._editLookupValue = lookupValue;

            // Automatically generate a unique identifier if we haven't manually edited the field yet
            this.lookupValueFormGroup
                .get("display_name")
                ?.valueChanges.pipe(debounceTime(100))
                .subscribe((value: string) => {
                    const siblings = this.lookupValues
                        .filter((v: DataTypeLookupValue) => v !== lookupValue)
                        .map((v: DataTypeLookupValue) => v.value);
                    const name = GenerateUniqueIdentifier(value, siblings);
                    const valueControl = this.lookupValueFormGroup.get("value");
                    if (lookupValue._new && !valueControl?.touched)
                        valueControl?.setValue(name);
                });
        }
    }

    cancelEditLookupValue(commit: boolean): void {
        if (commit && this._editLookupValue) {
            this._editLookupValue.display_name =
                this.lookupValueFormGroup?.get("display_name")?.value;
            this._editLookupValue.value =
                this.lookupValueFormGroup?.get("value")?.value;
            this.formGroup.get("lookup")?.markAsDirty();
            delete this._editLookupValue._new;
        } else if (!commit && this._editLookupValue?._new) {
            this.removeLookupValue(this._editLookupValue);
        }
        this._editLookupValue = undefined;
        this.lookupValueFormGroup = this.formBuilder.group({});
    }
    addLookupValue(): void {
        const siblings = this.lookupValues.map(
            (value: DataTypeLookupValue) => value.value,
        );
        const displayName = "New Value";
        const value = GenerateUniqueIdentifier(displayName, siblings);
        this.lookupValues = [
            {
                value: value,
                display_name: displayName,
                _new: true,
            },
            ...this.lookupValues,
        ];
        this.editLookupValue(this.lookupValues[0]);
        this.formGroup.get("lookup")?.markAsDirty();
    }
    removeLookupValue(lookupValue: DataTypeLookupValue): void {
        this.lookupValues = this.lookupValues.filter(
            (value: DataTypeLookupValue) => value != lookupValue,
        );
        this.formGroup.get("lookup")?.markAsDirty();
        this.lookupTable?.renderRows();
    }
    moveLookupValueToTop(event: MouseEvent, lookupValue: DataTypeLookupValue): void {
        const others = this.lookupValues.filter(
            (value: DataTypeLookupValue) => value != lookupValue,
        );
        this.lookupValues = [lookupValue, ...others];
        this.formGroup.get("lookup")?.markAsDirty();
        this.lookupTable?.renderRows();
    }
    moveLookupValueToBottom(event: MouseEvent, lookupValue: DataTypeLookupValue): void {
        const others = this.lookupValues.filter(
            (value: DataTypeLookupValue) => value != lookupValue,
        );
        this.lookupValues = [...others, lookupValue];
        this.formGroup.get("lookup")?.markAsDirty();
        this.lookupTable?.renderRows();
    }
    onLookupValueDrop(event: CdkDragDrop<DataTypeLookupValue>): void {
        if (event.currentIndex != event.previousIndex) {
            const values = this.lookupValues;
            const lookupValue = values.splice(event.previousIndex, 1)[0];
            values.splice(event.currentIndex, 0, lookupValue);
            this.lookupValues = values;
            this.formGroup.get("lookup")?.markAsDirty();
            this.lookupTable?.renderRows();
        }
    }
    onLookupSortChange(event: Sort): void {
        if (event.direction == "") this._sortedLookupValues = undefined;
        else {
            this._sortedLookupValues = [...this.lookupValues].sort(
                (a: DataTypeLookupValue, b: DataTypeLookupValue) => {
                    let sortValue = 0;
                    if (event.active == "display_name")
                        sortValue = a.display_name > b.display_name ? 1 : -1;
                    else if (event.active == "value")
                        sortValue = a.value > b.value ? 1 : -1;
                    else if (event.active == "actions") {
                        const aValue = this.isUniqueLookupValue(a) ? 1 : 0;
                        const bValue = this.isUniqueLookupValue(b) ? 1 : 0;
                        sortValue = aValue > bValue ? 1 : -1;
                    }
                    if (event.direction == "desc") sortValue *= -1;
                    return sortValue;
                },
            );
        }
        this.lookupTable?.renderRows();
    }

    // Convert this to a validator function since we're using reactive form group now
    protected isUniqueCompoundType(compound: CompoundDataType): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const values = this.compoundTypes
                .filter((type: CompoundDataType) => type != compound)
                .filter((type: CompoundDataType) => type.name == control.value);
            return values.length ? ({ unique: values[0] } as ValidationErrors) : null;
        };
    }

    editingCompoundType(compound: CompoundDataType): boolean {
        return this.isEditing && this._editCompoundType == compound;
    }
    editCompoundType(compound: CompoundDataType): void {
        if (this.isEditing) {
            this.cancelEditCompoundType(false);
            this.compoundTypeFormGroup = this.formBuilder.group({
                type: [compound.child, [Validators.required]],
                display_name: [
                    compound.display_name,
                    [
                        Validators.required,
                        Validators.maxLength(this.displayNameMaxLength),
                    ],
                ],
                identifier: [
                    compound.name,
                    [Validators.required, this.isUniqueCompoundType(compound)],
                ],
                required: [compound.required],
                multiple: [compound.multiple],
            });
            this._editCompoundType = compound;
            if (compound.id) {
                // Disable editing of type and identifier if this is an existing property
                this.compoundTypeFormGroup.get("type")?.disable();
                this.compoundTypeFormGroup.get("identifier")?.disable();
            } else {
                // Allow editing of type and identifier for new properties
                this.compoundTypeFormGroup.get("type")?.enable();
                this.compoundTypeFormGroup.get("identifier")?.enable();
            }
            this.compoundTypeFormGroup
                .get("display_name")
                ?.valueChanges.pipe(debounceTime(100))
                .subscribe((value: string) => {
                    // Get a list of all property names (both historic and current)
                    const historicProperties = Object.keys(
                        this.compoundPropertyTypes,
                    ).filter((identifier: string) => identifier != compound.name);
                    const existingProperties = this.compoundTypes
                        .filter((type: CompoundDataType) => type != compound)
                        .filter(
                            (type: CompoundDataType) =>
                                type.name &&
                                historicProperties.indexOf(type.name) == -1,
                        )
                        .map((value: CompoundDataType) => value.name!);
                    const siblings = [...historicProperties, ...existingProperties];

                    // Generate a unique identifier across property names
                    const name = GenerateUniqueIdentifier(value, siblings);

                    // If this is a new property and we haven't edited the identifier field, automatically set the identifier
                    const identifierControl =
                        this.compoundTypeFormGroup.get("identifier");
                    if (!compound.id && !identifierControl?.touched)
                        identifierControl?.setValue(name);
                });
            this.compoundTypeFormGroup
                .get("identifier")
                ?.valueChanges.pipe(debounceTime(100))
                .subscribe((value: string) => {
                    if (!compound.id) {
                        // For new properties, if this identifier was historically used, but isn't one of the current ones,
                        // ensure that the type gets set to the historic type, and prevent editing
                        const historic = this.compoundPropertyTypes[value];
                        const existing = this.compoundTypes
                            .filter((type: CompoundDataType) => type != compound)
                            .find((type: CompoundDataType) => type.name == value);
                        if (historic && !existing) {
                            const type = this.availableDataTypes.find(
                                (type: ObjectOrReference<DataType>) =>
                                    type.name == historic,
                            );
                            if (type)
                                this.compoundTypeFormGroup.get("type")?.setValue(type);
                            this.compoundTypeFormGroup.get("type")?.disable();
                        } else {
                            this.compoundTypeFormGroup.get("type")?.enable();
                        }
                    }
                });
        }
    }
    compoundHasHistoricIdentifier(compound: CompoundDataType): boolean {
        const value = this.compoundTypeFormGroup.get("identifier")?.value;
        const historic = this.compoundPropertyTypes[value];
        const existing = this.compoundTypes
            .filter((type: CompoundDataType) => type != compound)
            .find((type: CompoundDataType) => type.name == value);

        return !!historic && !!existing;
    }
    cancelEditCompoundType(commit: boolean = false): void {
        if (commit && this._editCompoundType) {
            this._editCompoundType.child =
                this.compoundTypeFormGroup.get("type")?.value;
            this._editCompoundType.display_name =
                this.compoundTypeFormGroup.get("display_name")?.value;
            this._editCompoundType.name =
                this.compoundTypeFormGroup.get("identifier")?.value;
            this._editCompoundType.required =
                this.compoundTypeFormGroup.get("required")?.value;
            this._editCompoundType.multiple =
                this.compoundTypeFormGroup.get("multiple")?.value;
            this.formGroup.get("compound")?.markAsDirty();
        } else if (!commit && this._editCompoundType && !this._editCompoundType.id) {
            this.removeCompoundType(this._editCompoundType);
        }
        this._editCompoundType = undefined;
        this.compoundTypeFormGroup = this.formBuilder.group({});
    }
    addCompoundType(): void {
        const shortText = this.availableDataTypes.find(
            (type: ObjectOrReference<DataType>) => type.name == "text.short",
        );
        const siblings = this.compoundTypes.map(
            (value: CompoundDataType) => value.name!,
        );
        const displayName = "New Property";
        const name = GenerateUniqueIdentifier(displayName, siblings);
        const newCompound = ObjectFactory.makeObject<CompoundDataType>(
            {
                display_name: displayName,
                name: name,
                order: this.compoundTypes.length,
                required: false,
                multiple: false,
                child: shortText,
            },
            CompoundDataType.object_type,
        ) as CompoundDataType;
        if (newCompound) {
            this.compoundTypes = [...this.compoundTypes, newCompound];
            this.editCompoundType(newCompound);
            this.formGroup.get("compound")?.markAsDirty();
        }
    }
    removeCompoundType(compound: CompoundDataType): void {
        this.compoundTypes = this.compoundTypes.filter(
            (type: CompoundDataType) => type != compound,
        );
        this.formGroup.get("compound")?.markAsDirty();
        this.compoundTable?.renderRows();
    }
    moveCompoundTypeToTop(event: MouseEvent, compound: CompoundDataType): void {
        const others = this.compoundTypes.filter(
            (type: CompoundDataType) => type != compound,
        );
        this.compoundTypes = [compound, ...others];
        this.formGroup.get("compound")?.markAsDirty();
        this.compoundTable?.renderRows();
    }
    moveCompoundTypeToBottom(event: MouseEvent, compound: CompoundDataType): void {
        const others = this.compoundTypes.filter(
            (type: CompoundDataType) => type != compound,
        );
        this.compoundTypes = [...others, compound];
        this.formGroup.get("compound")?.markAsDirty();
        this.compoundTable?.renderRows();
    }
    onCompoundTypeDrop(event: CdkDragDrop<CompoundDataType>): void {
        if (event.currentIndex != event.previousIndex) {
            const values = this.compoundTypes;
            const lookupValue = values.splice(event.previousIndex, 1)[0];
            values.splice(event.currentIndex, 0, lookupValue);
            values.forEach(
                (type: CompoundDataType, index: number) => (type.order = index),
            );
            this.compoundTypes = values;
            this.formGroup.get("compound")?.markAsDirty();
            this.compoundTable?.renderRows();
        }
    }
    onCompoundSortChange(event: Sort): void {
        if (event.direction == "") this._sortedCompoundTypes = undefined;
        else {
            this._sortedCompoundTypes = [...this.compoundTypes].sort(
                (a: CompoundDataType, b: CompoundDataType) => {
                    let sortValue = 0;
                    if (event.active == "display_name")
                        sortValue = a.display_name > b.display_name ? 1 : -1;
                    else if (event.active == "name")
                        sortValue = a.name! > b.name! ? 1 : -1;
                    else if (event.active == "actions") {
                        const aValue = this.isUniqueCompoundType(a) ? 1 : 0;
                        const bValue = this.isUniqueCompoundType(b) ? 1 : 0;
                        sortValue = aValue > bValue ? 1 : -1;
                    }
                    if (event.direction == "desc") sortValue *= -1;
                    return sortValue;
                },
            );
        }
        this.compoundTable?.renderRows();
    }
    updateCompoundTypeChildren(): void {
        this.compoundTypes.forEach((compound: CompoundDataType) => {
            const child =
                this.availableDataTypes.find(
                    (type: ObjectOrReference<DataType>) => type.id == compound.child.id,
                ) ?? compound.child;
            ObjectFactory.objectObservable(child).subscribe(
                (t: DataType | undefined) => (compound.child = t ?? compound.child),
            );
        });
    }

    handleKeyDown(event: KeyboardEvent): void {
        super.handleKeyDown(event);
        if (this.isEditing && !!this._editLookupValue && event.keyCode === 13) {
            if (this.lookupValueFormGroup.valid) this.cancelEditLookupValue(true);
            else {
                this.lookupValueFormGroup.get("value")?.markAsTouched();
            }
        } else if (this.isEditing && !!this._editCompoundType && event.keyCode === 13) {
            if (this.compoundTypeFormGroup.valid) this.cancelEditCompoundType(true);
        }
        if (
            this.isEditing &&
            event.keyCode === 13 &&
            (this._editLookupValue || this._editCompoundType)
        ) {
            event.preventDefault(); // Don't submit the form if we're just commiting an edit to a sub form
        }
    }

    protected precommitTransform(v: any): any {
        v = super.precommitTransform(v);
        if (this.type != "lookup") delete v.lookup;
        if (this.type != "compound") delete v.compound;

        // Ensure that the property types attributes is updated with any new values
        if (this.type == "compound") {
            const attributes = this.fullObject?.attributes ?? { propertyTypes: {} };
            this.compoundTypes.forEach(
                (type: CompoundDataType) =>
                    (attributes.propertyTypes[type.name!] = type.child.name),
            );
            v.attributes = attributes;
        }
        return v;
    }

    protected getIsValid(): boolean {
        let valid = super.getIsValid();
        if (this.type == "lookup") {
            const hasDuplicates =
                this.lookupValues
                    .map((lookupValue: DataTypeLookupValue) => lookupValue.value)
                    .filter(
                        (value: string, index: number, array: string[]) =>
                            array.indexOf(value) !== index,
                    ).length > 0;
            const hasEmptyValues =
                this.lookupValues.filter(
                    (lookupValue: DataTypeLookupValue) =>
                        !lookupValue.value || lookupValue.value == "",
                ).length > 0;
            const hasValues = this.lookupValues.length > 0;
            valid = valid && !hasDuplicates && !hasEmptyValues && hasValues;
        }
        if (this.type == "compound") {
            const hasDuplicates =
                this.compoundTypes
                    .map((compound: CompoundDataType) => compound.name!)
                    .filter(
                        (name: string, index: number, array: string[]) =>
                            name && array.indexOf(name) !== index,
                    ).length > 0;
            const hasValues = this.compoundTypes.length > 0;
            const uniqueError = this.compoundTypeFormGroup
                .get("identifier")
                ?.hasError("unique") as boolean;
            const requiredError = this.compoundTypeFormGroup
                .get("identifier")
                ?.hasError("required") as boolean;
            valid =
                valid && !hasDuplicates && hasValues && !uniqueError && !requiredError;
        }
        return valid;
    }

    protected setObject(v?: DataType | undefined): void {
        super.setObject(v);

        let type: string = "basic";
        if (v?.lookup?.length) type = "lookup";
        else if (v?.compound?.length) type = "compound";

        if (this.mode == ObjectViewMode.Create && !v?.lookup?.length) {
            this.formGroup.get("_type")?.setValue("compound");
            this.formGroup.get("_type")?.enable();
        } else {
            this.formGroup.get("_type")?.setValue(type);
            this.formGroup.get("_type")?.disable();
            if (
                type == "compound" &&
                this.fullObject &&
                !this.fullObject.attributes?.propertyTypes
            ) {
                if (!this.fullObject.attributes) this.fullObject.attributes = {};
                this.fullObject.attributes.propertyTypes = Object.assign(
                    {},
                    ...(this.fullObject.compound?.map((compound: CompoundDataType) => ({
                        [compound.name!]: compound.child.name,
                    })) ?? []),
                );
            }
        }

        this.compoundTypes.sort(
            (a: CompoundDataType, b: CompoundDataType) => a.order - b.order,
        );
        this.updateCompoundTypeChildren();

        this.objectName =
            this.mode == ObjectViewMode.Create ?
                "New Data Type"
            :   this.fullObject?.display_name ?? "Data Type";
    }
}
