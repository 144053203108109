import { ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core";
import { ObjectAdminComponent } from "../../../common/components/object-admin.component";
import { Organization } from "../../../services/models/organization";
import { OrganizationComponent } from "./organization.component";
import { OrganizationService } from "../../../services/iam.services";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, filter } from "rxjs/operators";
import { Sort } from "@angular/material/sort";
import {
    ObjectViewEntryPoint,
    ObjectViewMode,
} from "src/common/components/object.component";
import { RequestFilter } from "src/common/utilities/request";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatFooterRowDef, MatTable } from "@angular/material/table";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "organization-admin",
    templateUrl: "./organization-admin.component.html",
    styleUrls: ["./organization.component.scss"],
})
export class OrganizationAdminComponent extends ObjectAdminComponent<Organization> {
    objectView = OrganizationComponent;
    displayedColumns: string[] = ["org_name", "program_count", "actions"];

    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
    @ViewChild(MatFooterRowDef, { static: true }) footerDef?: MatFooterRowDef;
    @ViewChild(MatTable, { static: true }) table?: MatTable<Document>;
    @ViewChild("search") searchElement?: ElementRef;
    @ViewChild("filter") filterElement?: ElementRef;

    searchTermControl: UntypedFormControl = new UntypedFormControl();
    showSearch: boolean = false;

    constructor(
        protected service: OrganizationService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => this.updateList(term));
    }

    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    resetSearchTerm(event?: MouseEvent): void {
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
    }
    toggleSearch(event: MouseEvent): void {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
    }
    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }
    onFocusOut(event: any): void {
        this.autoToggleSearch();
    }
    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        return filters;
    }

    createObject(
        event?: MouseEvent,
        asDialog?: boolean,
    ): ObjectViewEntryPoint<Organization> | undefined {
        const entryPoint = super.createObject(event, asDialog);
        if (entryPoint?.mode != ObjectViewMode.Create) {
            entryPoint?.dialogReference?.updateSize(undefined, "78%");
        } else {
            (entryPoint as OrganizationComponent)?.updateObjectForm();
        }
        return entryPoint;
    }

    protected objectDialogConfiguration(
        object: Organization,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        if (mode != ObjectViewMode.Create) {
            return {
                ...config,
                width: "90%",
                minHeight: "75%",
            };
        }
        return config;
    }
}
