import { ObjectOrReference, ObjectReference } from "src/services/models/api-object";
import { NamedObject } from "./api-object";
import { Case } from "./case";
import { Inquiry } from "./inquiry";
import { Program } from "./program";
import { Organization } from "./organization";
import { Account } from "./account";

export class Patient extends NamedObject {
    static object_type: string = "program.patient";
    reference_identifier!: number;
    program?: ObjectOrReference<Program>;
    physician_name!: string;
    institution_name!: string;
    status!: string;
    contact!: string;
    received_at!: Date;
    pharma!: ObjectOrReference<Organization>;
    source?: string;
    type!: string;
    object!: ObjectOrReference<Case | Inquiry>;
    owners!: ObjectOrReference<Account>[];
    alert!: any;
    country!: string;
    initialize(data?: any, patch: boolean = false) {
        this._references.push(...["program", "pharma", "object", "owners"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "reference_identifier");
        this.setMember(data, patch, "program", Program);
        this.setMember(data, patch, "physician_name");
        this.setMember(data, patch, "country");
        this.setMember(data, patch, "institution_name");
        this.setMember(data, patch, "status");
        this.setMember(data, patch, "contact");
        this.setMember(data, patch, "received_at", Date);
        this.setMember(data, patch, "pharma", Organization);
        this.setMember(data, patch, "source");
        this.setMember(data, patch, "type");
        this.setMember(data, patch, "object", ObjectReference);
        this.setMember(data, patch, "owners", Account, true);
        this.setMember(data, patch, "alert");

        if (this.object) this.id = this.object.id;
    }
}
