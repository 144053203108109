import { Directive, EventEmitter, Input, Output, inject } from "@angular/core";
import {
    AbstractControl,
    FormArray,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
} from "@angular/forms";
import { Observable, of, Subject, Subscription } from "rxjs";
import { debounceTime, finalize, map, mergeMap, tap } from "rxjs/operators";
import {
    APIObject,
    ObjectFactory,
    ObjectReference,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import { SessionComponent } from "src/services/components/session.component";
import { APIService } from "src/services/api.service";
import { ConfirmDialog } from "./confirm.dialog";
import { Breadcrumb, BreadcrumbProvider } from "./breadcrumbs/breadcrumb.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

/**
 * Values to pass to switch modes in object views since they can be repurposed for multiple uses.
 */
export enum ObjectViewMode {
    Admin = "admin",
    Create = "create",
    Edit = "edit",
    View = "view",
}

export interface TabError {
    message: string;
    severity: string;
}

/**
 * Represents the components that host an APIObject view and can be instanced dynamically,
 * which are either the card/widget view,
 * the dialog/modal view, or (for legacy support) the ObjectComponent itself.
 */
export interface ObjectViewEntryPoint<T extends APIObject> {
    object: OptionalObjectOrReference<T>;
    autosave: boolean;
    autosaveOnCreate: boolean;
    usePatch: boolean;
    dialogReference?: MatDialogRef<any>;
    mode: ObjectViewMode;
}

export interface FormControlSet {
    [key: string]: AbstractControl;
}
export interface FormError {
    control: string;
    error: string;
    value: any;
}

@Directive()
export class ObjectComponent<T extends APIObject>
    extends SessionComponent
    implements ObjectViewEntryPoint<T>, BreadcrumbProvider
{
    protected object_?: T;
    protected original_?: T;
    protected committing: boolean = false;
    @Input() mode!: ObjectViewMode;

    protected formGroup_!: UntypedFormGroup;
    protected formGroupSubscription?: Subscription;
    protected formBuilder: UntypedFormBuilder;
    protected dialog: MatDialog;

    ObjectViewMode = ObjectViewMode;
    objectName: string = "Object";
    tabErrors: { [key: string]: TabError | undefined } = {};
    loading: boolean = false;

    constructor(protected api: APIService<T>) {
        super();
        this.formBuilder = inject(UntypedFormBuilder);
        this.dialog = inject(MatDialog);
        this.formGroup = this.createObjectForm();
    }

    get controller(): this {
        return this;
    }
    get fullObject(): T | undefined {
        return !this.object || this.object instanceof ObjectReference ?
                undefined
            :   this.object;
    }
    get object(): OptionalObjectOrReference<T> {
        return this.getObject();
    }
    get formButtonText(): string {
        return "Save";
    }
    @Input() set object(v: T | ObjectReference | undefined) {
        this.setObjectOrReference(v);
    }
    @Input() autosave: boolean = false;
    @Input() autosaveOnCreate: boolean = false;
    @Input() usePatch: boolean = true;
    @Input() dialogReference?: MatDialogRef<any> = undefined;
    get isDialog(): boolean {
        return !!this.dialogReference;
    }
    get formGroup(): UntypedFormGroup {
        return this.formGroup_;
    }
    @Input() set formGroup(v: UntypedFormGroup) {
        this.formGroupSubscription?.unsubscribe();
        this.formGroup_ = v;
        this.formGroupSubscription = this.formGroup_?.valueChanges
            .pipe(debounceTime(1000))
            .subscribe((value: any) => {
                if (this.formGroup?.valid && this.formGroup?.dirty && this.autosave)
                    this.onAutosave(value);
            });
    }
    get hasChanged(): boolean {
        return this.getHasChanged();
    }
    get isValid(): boolean {
        return this.getIsValid();
    }
    get submitProcessing(): boolean {
        return false;
    }

    @Output() objectChange: EventEmitter<T> = new EventEmitter<T>(true);

    getBreadcrumbs(current: boolean): Breadcrumb | Breadcrumb[] | undefined {
        return undefined;
    }

    onCancel(): void {
        this.dialogReference?.close();
    }
    onRevert(): void {
        this.object = this.original_;
        this.formGroup?.markAsPristine();
    }
    onSave(): void {
        const enabled = this.formGroup?.enabled;
        if (!enabled) this.setFormGroupEnabled(true);
        const value = this.formGroup?.value;
        if (!enabled) this.setFormGroupEnabled(false);
        this.onAutosave(value);
    }
    onDelete(): void {
        if (this.object) {
            this.dialog
                .open(ConfirmDialog, {
                    data: {
                        message:
                            "Are you sure you want to delete '" +
                            this.object.displayName +
                            "'?",
                    },
                    disableClose: true,
                    hasBackdrop: true,
                    minWidth: "50vw",
                })
                .afterClosed()
                .pipe(
                    mergeMap((confirm: boolean) =>
                        confirm ? this.api.destroy(this.object as T) : of(null),
                    ),
                )
                .subscribe();
        }
    }

    get exists(): boolean {
        const object = this.object;
        return !!object && object.hasOwnProperty("id") && !!object.id;
    }

    getSubGroupByName(name: string): UntypedFormGroup {
        return this.formGroup.get(name) as UntypedFormGroup;
    }

    setTabError(tab: string, error?: TabError): void {
        this.tabErrors[tab] = error;
    }

    objectSet: Subject<T | ObjectReference | undefined> = new Subject();
    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({});
    }
    protected getObject(): T | undefined {
        return this.object_;
    }
    protected setObject(v?: T): void {
        // Whether this is set to autosave determines how we handle object and original
        // for the purposes of autosaving and reverting.
        // With autosave on, 'object' represents the cached object which will be updated with any change to the data
        // Original represents the original object data, which will be used to revert the object to its original state
        // unless the object gets set again
        // With autosave off, 'object' represents a copy of the 'original' object and is not part of the cache
        // That means any changes to the object will not be reflected in the original object or the cache until it is saved
        let object = this.autosave ? this.object_ : this.original_;
        let original = this.autosave ? this.original_ : this.object_;

        if (object && v && object.id == v.id) {
            const cached = object.is_cached; // preserve the cache status
            object.update(v);
            object.is_cached = cached;
        } else {
            object = v;
        }

        if (v === original) this.objectChange.emit(object);
        if (object) {
            original = new this.api.type(object);
            this.objectSet.next(object); //if you need something to happen as soon as object is defined, subscribe to objectSet
        } else original = undefined;

        if (object && this.formGroup) this.resetObject(object);

        // see explanation above
        if (this.autosave) {
            this.object_ = object;
            this.original_ = original;
        } else {
            this.object_ = original;
            this.original_ = object;
        }
    }
    protected setObjectOrReference(v: OptionalObjectOrReference<T>): void {
        ObjectFactory.objectObservable(v)
            .pipe(
                tap(() => (this.loading = true)),
                finalize(() => (this.loading = false)),
            )
            .subscribe((o: T | undefined) => this.setObject(o));
    }
    protected resetObject(v: T): void {
        this.formGroup?.reset(v);
        this.formGroup?.markAsPristine();
    }
    protected setFormGroupEnabled(v: boolean): void {
        if (v) this.formGroup?.enable({ onlySelf: true, emitEvent: false });
        else this.formGroup?.disable({ onlySelf: true, emitEvent: false });
    }
    protected updateObject(): void {
        this.setObjectOrReference(this.object?.asReference);
    }

    protected getHasChanged(): boolean {
        return !!this.formGroup?.dirty && !this.committing;
    }
    protected getIsValid(): boolean {
        return !!this.formGroup?.valid && !!this.object;
    }
    handleKeyDown(event: KeyboardEvent): void {
        if (this.autosave && event.keyCode === 13 && !event.shiftKey)
            event.preventDefault();
    }
    /**
     * Provides a point where we can add or manipulate form data immediately
     * before it is submitted to the server.
     *
     * @protected
     * @param {*} v
     * @returns {T}
     * @memberof ObjectComponent
     */
    protected commitObject(v: any): T {
        const commitObject = ObjectFactory.makeObject<T>({}, this.api.object_type) as T;
        const object = this.object;
        commitObject?.update(object);
        commitObject?.update(v);
        return commitObject;
    }
    protected precommitTransform(v: any): any {
        return v;
    }

    protected commit(v: any): Observable<T | undefined> {
        const exists = this.exists;
        v = this.precommitTransform(v);
        if (exists && this.usePatch) return this.api.patch(this.object as T, v);
        const obj = this.commitObject(v);
        return exists ?
                this.api.update(obj)
            :   this.api.create(obj).pipe(
                    map((result: T | undefined) => {
                        if (!result) throw new Error("No object returned");
                        return result;
                    }),
                );
    }
    protected onAutosave(value: any): void {
        of(value)
            .pipe(
                tap(() => (this.committing = true)),
                mergeMap((v: any) => this.commit(v)),
                finalize(() => (this.committing = false)),
            )
            .subscribe({
                next: (result: T | undefined) => this.onCommitSuccess(result),
                error: (err: any) => this.onCommitError(err),
            });
    }
    protected onCommitSuccess(v: T | undefined): boolean {
        const adding = !this.exists && !!v?.id;
        this.object = v;
        if (adding) {
            if (this.dialogReference) this.dialogReference.close(v);
            else if (this.autosaveOnCreate) this.autosave = true;
        } else {
            this.objectChange.emit(this.object);
            if (this.dialogReference && !this.autosave) this.dialogReference.close(v);
        }
        if (this.mode == ObjectViewMode.Create) this.mode = ObjectViewMode.Edit;
        return adding;
    }
    protected onCommitError(err: any): void {}

    protected onLogout() {
        super.onLogout();
        if (this.dialogReference) this.dialogReference.close();
    }

    /**
     * KEEP: For debugging form.
     * https://stackoverflow.com/a/44280487/1253298
     */
    public getFormValidationErrors() {
        console.info(`Errors: `);
        this.formErrors(this.formGroup.controls).forEach((error: FormError) => {
            console.info(
                `Control: ${error.control}, Error: ${error.error}, Value: ${error.value}`,
            );
        });
    }
    protected formErrors(controls: FormControlSet, baseName: string = ""): FormError[] {
        let errors: FormError[] = [];
        Object.keys(controls).forEach((key: string) => {
            const control = controls[key];
            const childName = baseName + key;
            if (control instanceof FormGroup) {
                errors = errors.concat(this.formErrors(control.controls, key + "."));
            } else if (control instanceof FormArray) {
                const arrayControls: FormControlSet = {};
                control.controls.forEach(
                    (ctrl: AbstractControl, index: number) =>
                        (arrayControls[index.toString()] = ctrl),
                );
                errors = errors.concat(this.formErrors(arrayControls, key + "."));
            } else if (control.errors) {
                Object.keys(control.errors).forEach((errorKey: string) => {
                    errors.push({
                        control: childName,
                        error: errorKey,
                        value: control.errors![errorKey],
                    });
                });
            }
        });
        return errors;
    }

    public goToNextFormStep(): void {} // NOSONAR
}
