<div class="container">
    <div class="types-header flex">
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search margin-right"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Data Types</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Types"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                [disabled]="!list.hasLoaded"
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Create Data Type
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="display_name">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="flex stack types-name"
            >
                Name
            </mat-header-cell>
            <mat-cell
                *matCellDef="let type"
                (click)="editObject($event, type, true)"
                class="flex stack types-name"
            >
                <div *ngIf="isReference(type)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(type)">
                    <div>{{ type.display_name || type.name }}</div>
                    <div *ngIf="!!type.display_name" class="secondary super-small">
                        {{ type.name }}
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="is_compound">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="flex stack types-chip"
            >
                Type
            </mat-header-cell>
            <mat-cell
                *matCellDef="let type"
                (click)="editObject($event, type, true)"
                class="flex stack types-chip"
            >
                <div *ngIf="isReference(type)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(type)">
                    <mat-chip-set>
                        <mat-chip
                            *ngIf="!!type.compound.length"
                            color="accent"
                            selected
                        >
                            Compound
                        </mat-chip>
                        <mat-chip
                            *ngIf="!type.compound.length && !!type.lookup"
                            color="primary"
                            selected
                        >
                            Lookup
                        </mat-chip>
                        <mat-chip
                            *ngIf="!type.compound.length && !type.lookup"
                            color="primary"
                        >
                            Basic
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="owner_id">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="flex stack types-chip"
            >
                Owner
            </mat-header-cell>
            <mat-cell
                *matCellDef="let type"
                (click)="editObject($event, type, true)"
                class="flex stack types-chip"
            >
                <div *ngIf="isReference(type)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(type)">
                    <mat-chip-set>
                        <mat-chip *ngIf="!type.owner" color="primary" selected>
                            MedaSystems
                        </mat-chip>
                        <mat-chip *ngIf="!!type.owner" color="accent" selected>
                            {{ type.owner.displayName }}
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="used_in">
            <mat-header-cell *matHeaderCellDef class="flex stack types-chip">
                Used
            </mat-header-cell>
            <mat-cell *matCellDef="let type" class="flex stack types-chip">
                <div *ngIf="isReference(type)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(type)">
                    <mat-chip-set>
                        <mat-chip
                            *ngIf="!!type.used_in || !!type.used_by"
                            color="accent"
                            selected
                        >
                            In-Use
                        </mat-chip>
                        <mat-chip *ngIf="!type.used_in && !type.used_by">
                            Unused
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let type" class="overflow">
                <div *ngIf="isReference(type)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(type)">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ type: type }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="list.hasLoaded">No data types found.</span>
                <span *ngIf="!list.hasLoaded">Loading data types ...</span>
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-type="type">
                <button mat-menu-item (click)="viewObject($event, type, true)">
                    View
                </button>
                <button
                    mat-menu-item
                    (click)="canEdit(type) && editObject($event, type, true)"
                    [disabled]="!canEdit(type)"
                >
                    Edit
                </button>
                <button
                    mat-menu-item
                    (click)="canDuplicate(type) && duplicateObject($event, type, true)"
                    [disabled]="!canDuplicate(type)"
                >
                    Duplicate
                </button>
                <button
                    mat-menu-item
                    *ngIf="hasDeletePermission(type)"
                    (click)="canDelete(type) && deleteObject($event, type)"
                    [disabled]="!canDelete(type)"
                >
                    <mat-icon
                        *ngIf="!canDelete(type)"
                        matTooltip="In-Use"
                        class="error"
                    >
                        warning
                    </mat-icon>
                    Delete
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
