<div class="wrapper tab-header-wrapper">
    <ng-content select="[tab-header]"></ng-content>
    <div class="tabs tile fixed flex stack" id="doctorTask5">
        <button
            mat-button
            class="tab-button mat-focus-indicator"
            role="tab"
            *ngFor="let tab of tabs; index as i"
            [disabled]="selectedIndex == i"
            [class.selected]="selectedIndex == i"
            (click)="onSelectTab(tab, i)"
            [attr.id]="
                tab.label === 'Team' ? 'doctorTask6'
                : tab.label === 'Checklist' ? 'doctorTask8'
                : null
            "
        >
            <ng-template [ngIf]="tab.labelTemplate" [ngIfElse]="tabTextLabel">
                <ng-template [cdkPortalOutlet]="tab.labelTemplate"></ng-template>
            </ng-template>
            <ng-template #tabTextLabel>{{ tab.label }}</ng-template>
        </button>
    </div>
</div>
<div #tabBodyWrapper class="tab-body-wrapper wrapper flexible">
    <detail-tab-body
        *ngFor="let tab of tabs; index as i"
        [class.detail-tab-body-active]="selectedIndex === i"
        [content]="tab.content"
        [position]="tab.position"
        [origin]="tab.origin"
    ></detail-tab-body>
</div>

<bdc-walk-popup
    #doctorTask5
    name="doctorTask5"
    [mustCompleted]="{ doctorOnboardingWelcome: true, doctorTask4: true }"
    [closeOnClick]="true"
    [onButtonCompleteTask]="{ doctorTask5: true }"
    buttonText="Continue"
    [showButton]="true"
    [showCloseButton]="false"
    class="doctorTask5Popup"
    xPosition="before"
    yPosition="below"
    [horizontal]="true"
    [arrow]="true"
>
    These tools will help you complete the tasks that the pharmaceutical company have
    assigned to your team
</bdc-walk-popup>

<bdc-walk-popup
    #doctorTask6
    name="doctorTask6"
    [mustCompleted]="{ doctorOnboardingWelcome: true, doctorTask5: true }"
    [closeOnClick]="true"
    [onButtonCompleteTask]="{ doctorTask6: true }"
    buttonText="Continue"
    [showCloseButton]="false"
    [showButton]="true"
    class="doctorTask6Popup"
>
    To add additional team members to the case, like a nurse or pharmacist, go to the
    Team tab. Your team member will receive an email invitation to log in and join the
    case.
</bdc-walk-popup>

<bdc-walk-popup
    #doctorTask8
    name="doctorTask8"
    yPosition="above"
    [mustCompleted]="{ doctorOnboardingWelcome: true, doctorTask7: true }"
    [closeOnClick]="true"
    [showCloseButton]="false"
    class="doctorTask8Popup"
>
    Click the 'Checklist' tab to get back to the checklist.
</bdc-walk-popup>
