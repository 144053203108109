<data-filter
    *ngIf="showCasesFilter && mode !== 'recent'"
    [dateFilterConfigs]="casesDateFilterConfigurations"
    [dynamicOptions]="dynamicCasesOptions"
    [filterDefinitions]="filterCasesDefinitions"
    [filterFormGroup]="filterCasesFormGroup"
    [filterTitle]="title"
    [getOptionValue]="getOptionValue"
    [getOptionDisplay]="getOptionDisplay"
    (cancelFilter)="cancel($event)"
    (clearAllFilters)="clearAllFilters($event)"
    (resetFilter)="resetFilter($event)"
></data-filter>

<data-filter
    *ngIf="showPendingInquiriesFilter && mode === 'recent'"
    [dateFilterConfigs]="pendingInquiriesDateFilterConfigurations"
    [dynamicOptions]="dynamicPendingInquiriesOptions"
    [filterDefinitions]="filterPendingInquiriesDefinitions"
    [filterFormGroup]="filterPendingInquiriesFormGroup"
    [filterTitle]="title"
    [getOptionValue]="getOptionValue"
    [getOptionDisplay]="getOptionDisplay"
    (cancelFilter)="cancel($event)"
    (clearAllFilters)="clearAllFilters($event)"
    (resetFilter)="resetFilter($event)"
></data-filter>

<div class="list-container">
    <div class="list-header flex">
        <div class="title" [id]="list.listTitle">{{ title }}</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <ng-container *ngIf="mode != 'recent'">
                <mat-form-field
                    color="primary"
                    *ngIf="!isSearchEmpty"
                    class="case-search"
                >
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <mat-label>Search Cases</mat-label>
                    <input
                        matInput
                        #search
                        placeholder="Enter at least 3 characters to search"
                        [formControl]="searchTermControl"
                        (focusout)="onFocusOut($event)"
                    />
                    <button
                        matSuffix
                        type="button"
                        mat-icon-button
                        (click)="resetSearchTerm($event)"
                        *ngIf="!!searchTermControl.value"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <button
                    mat-flat-button
                    class="brand-medium"
                    *ngIf="isSearchEmpty"
                    (click)="toggleSearch($event)"
                    matTooltip="Search Cases"
                >
                    <mat-icon>search</mat-icon>
                    Search
                </button>
            </ng-container>
            <ng-container>
                <button
                    [disabled]="!list.hasLoaded"
                    mat-flat-button
                    class="brand-medium"
                    (click)="toggleFilter($event)"
                    [matTooltip]="getFilterTooltipText()"
                >
                    <mat-icon>filter_list</mat-icon>
                    Filter
                </button>
            </ng-container>
            <ng-container *ngIf="mode != 'recent' && canBulkAction">
                <button
                    (click)="sendBulkMessage($event)"
                    [disabled]="!list.hasLoaded"
                    mat-flat-button
                    class="brand-medium"
                    matTooltip="Click to send a message to all public accounts in the cases displayed based on the current filters"
                >
                    <mat-icon>message</mat-icon>
                    Bulk Communication
                </button>
                <button
                    *ngIf="showBulkUpload"
                    (click)="bulkDocumentUpload($event)"
                    [disabled]="!list.hasLoaded"
                    mat-flat-button
                    class="brand-medium"
                    matTooltip="Click to upload files to all the cases displayed based on the current filters"
                >
                    <mat-icon>upload_file</mat-icon>
                    Upload
                </button>
            </ng-container>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="reference_identifier">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Case #</mat-header-cell>
            <mat-cell
                *matCellDef="let case"
                [ngClass]="{ clickable: isObject(case) }"
                (click)="showObject($event, case)"
            >
                <div *ngIf="isReference(case)" referenceWrapper [primary]="true"></div>
                <div *ngIf="isObject(case)">
                    {{ caseReference(case) || 0 | number | noComma }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <ng-container>
                    <div class="stack">
                        <div>{{ case.displayName }}</div>
                        <div
                            *ngIf="isObject(case) && mode == 'pharma'"
                            class="secondary super-small"
                        >
                            {{ programName(case) }}
                        </div>
                        <div
                            *ngIf="!isObject(case) && mode == 'pharma'"
                            referenceWrapper
                            [secondary]="true"
                        ></div>
                    </div>
                    <mat-icon
                        *ngIf="case.alert"
                        class="patient-alert"
                        [ngClass]="case.alert.severity"
                        [matTooltip]="case.alert.message"
                    >
                        warning
                    </mat-icon>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <ng-container>
                    <mat-chip-set>
                        <mat-chip *ngIf="isInquiry(case)" color="not-primary">
                            Inquiry
                        </mat-chip>
                        <mat-chip *ngIf="!isInquiry(case)" color="primary" selected>
                            Case
                        </mat-chip>
                    </mat-chip-set>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <ng-container *ngIf="isObject(case)">
                    <mat-chip-set>
                        <mat-chip
                            *ngIf="isPatient(case) && !isCaregiver(case)"
                            color="primary"
                            selected
                        >
                            Patient
                        </mat-chip>
                        <mat-chip *ngIf="isCaregiver(case)" color="primary" selected>
                            Caregiver
                        </mat-chip>
                        <mat-chip
                            *ngIf="isPhysicianInquiry(case)"
                            color="accent"
                            selected
                        >
                            Physician
                        </mat-chip>
                        <mat-chip
                            *ngIf="isPhysicianStaff(case)"
                            color="accent"
                            selected
                        >
                            Physician Staff
                        </mat-chip>
                        <mat-chip *ngIf="isInternal(case)">Internal</mat-chip>
                    </mat-chip-set>
                </ng-container>
                <ng-container *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contact">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Contact</mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <div class="stack" *ngIf="isObject(case)">
                    <div
                        class="italic clickable"
                        (click)="showObject($event, case)"
                        *ngIf="isPatient(case)"
                    >
                        Contact Blinded
                    </div>
                    <div
                        class="bold clickable"
                        (click)="showObject($event, case)"
                        *ngIf="!isPatient(case)"
                    >
                        <div>{{ case.contact }}</div>
                    </div>
                </div>
                <div class="stack flexible" *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="owners__full_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Assigned To
            </mat-header-cell>
            <mat-cell
                *matCellDef="let case"
                class="flex stack assigned"
                (click)="showObject($event, case)"
            >
                <ng-container *ngIf="isObject(case)">
                    <div
                        *ngFor="let ref of case.owners"
                        [class.brand-medium]="ref.id == currentAccount?.id"
                        [class.bold]="ref.id == currentAccount?.id"
                    >
                        {{ ref.displayName }}
                    </div>
                    <div *ngIf="!case.owners.length" class="secondary italic">
                        Not Assigned
                    </div>
                </ng-container>
                <ng-container *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="received_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Received
            </mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <ng-container *ngIf="isObject">
                    {{ case.received_at | localizedDate }}
                </ng-container>
                <ng-container *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="institution_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Institution
            </mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <div class="stack" *ngIf="isObject(case)">
                    <div
                        [class.secondary]="!institutionName(case)"
                        [class.italic]="!institutionName(case)"
                    >
                        {{ institutionName(case) || "Not Specified" }}
                    </div>
                </div>
                <div class="stack flexible" *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Country</mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <div class="stack" *ngIf="isObject(case)">
                    <div
                        [class.secondary]="!institutionName(case)"
                        [class.italic]="!institutionName(case)"
                    >
                        {{ case?.country || "Not Specified" }}
                    </div>
                </div>
                <div class="stack flexible" *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="program__name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Program</mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <ng-container *ngIf="isObject(case)">
                    <div
                        [class.secondary]="!programName(case)"
                        [class.italic]="!programName(case)"
                    >
                        {{ programName(case) || "Not Specified" }}
                    </div>
                </ng-container>
                <ng-container *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="physician_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Physician
            </mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <div class="stack" *ngIf="isObject(case)">
                    <div>{{ physicianName(case) || "Not Specified" }}</div>
                    <div class="secondary super-small">
                        {{ institutionName(case) || "Institution Not Specified" }}
                    </div>
                </div>
                <div class="stack flexible" *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                    <div referenceWrapper [secondary]="true"></div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="organization_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Drug Company
            </mat-header-cell>
            <mat-cell *matCellDef="let case" (click)="showObject($event, case)">
                <div class="stack" *ngIf="isObject(case)">
                    <div
                        [class.secondary]="!organizationName(case)"
                        [class.italic]="!organizationName(case)"
                    >
                        {{ organizationName(case) || "Not Specified" }}
                    </div>
                    <div class="secondary super-small">{{ programName(case) }}</div>
                </div>
                <div class="stack flexible" *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                    <div referenceWrapper [secondary]="true"></div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="case_status__name">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [class.recent]="mode == 'recent'"
            >
                Status
            </mat-header-cell>
            <mat-cell
                *matCellDef="let case"
                (click)="showObject($event, case)"
                [class.recent]="mode == 'recent'"
            >
                <ng-container *ngIf="isObject(case) && caseStatus(case)">
                    {{ caseStatus(case) }}
                </ng-container>
                <ng-container *ngIf="isReference(case) || !caseStatus(case)">
                    <div referenceWrapper [primary]="true"></div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [class.recent]="mode == 'recent'"
            >
                Created On
            </mat-header-cell>
            <mat-cell
                *matCellDef="let case"
                (click)="showObject($event, case)"
                [class.recent]="mode == 'recent'"
            >
                <ng-container *ngIf="isObject(case)">
                    {{ caseCreatedAt(case) | localizedDate }}
                </ng-container>
                <ng-container *ngIf="isReference(case)">
                    <div referenceWrapper [primary]="true"></div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let case" class="overflow">
                <button
                    *ngIf="isObject(case)"
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ case: case }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="table-footer">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="list.searching && !list.hasLoaded">
                    <mat-progress-spinner
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                    Loading...
                </span>
                <span *ngIf="list.hasLoaded && !list.list.items.length">
                    No patients found.
                </span>
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.background-highlight]="isHighlighted(row)"
        ></mat-row>
        <mat-footer-row
            *matFooterRowDef="['table-footer']"
            [class.hidden-rows]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-case="case">
                <button mat-menu-item (click)="showObject($event, case, true)">
                    View
                </button>
                <button
                    mat-menu-item
                    (click)="canEdit(case) && showObject($event, case)"
                    [disabled]="!canEdit(case)"
                >
                    Edit
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator
        [class.paddingBottom]="mode !== 'recent'"
        *ngIf="enablePagination"
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
