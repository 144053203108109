import { TranslateService } from "@ngx-translate/core";
import { Component, inject } from "@angular/core";
import { LocalizedDatePipe } from "src/common/utilities/localized-date.pipe";
import { SessionComponent } from "src/services/components/session.component";
import { Sort } from "@angular/material/sort";
import { AuditService } from "src/services/iam.services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Audit, AuditFilter, AuditFilterOption } from "src/services/models/audit";
import { debounceTime } from "rxjs";
import { PageEvent } from "@angular/material/paginator";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { AuditDetailComponent } from "./audit-detail.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { RequestFilter } from "src/common/utilities/request";
import {
    APIListResult,
    ObjectOrReference,
    PaginatedList,
} from "src/services/models/api-object";

@Component({
    selector: "admin-audit",
    templateUrl: "./admin-audit.component.html",
    styleUrls: ["./admin-audit.component.scss"],
})
export class AdminAuditComponent extends SessionComponent {
    auditService: AuditService;
    auditTrail: ObjectOrReference<Audit>[] = [];
    datePipe: LocalizedDatePipe;

    displayedColumns: string[] = ["timestamp", "account", "reference_type", "event"];

    filterFormGroup: FormGroup;

    defaultFilters?: AuditFilter;
    updatedFilters?: AuditFilter;
    showFilter: boolean = false;
    hideUnavailable: boolean = false;
    page: number = 1;
    perPage: number = 50;
    pageSizeOptions: number[] = [5, 10, 15, 25, 50, 75, 100];
    paginatorLength: number = 0;

    constructor(formBuilder: FormBuilder) {
        super();
        this.auditService = inject(AuditService);
        let translateService = inject(TranslateService);
        this.datePipe = new LocalizedDatePipe(translateService);

        this.filterFormGroup = formBuilder.group({
            filterObjectType: [],
            filterEvent: [],
            filterUser: [],
            filterStartDate: [],
            filterEndDate: [],
        });

        this.updateAuditFilters();
        this.updateAuditTrail();
        this.filterFormGroup.valueChanges.pipe(debounceTime(400)).subscribe(() => {
            this.page = 1;
            this.updateAuditFilters();
            this.updateAuditTrail();
        });
    }

    get filters(): RequestFilter {
        let filters: any = {
            page: this.page,
            page_size: this.perPage,
        };
        const reference_type = this.filterFormGroup.get("filterObjectType")?.value;
        const event = this.filterFormGroup.get("filterEvent")?.value;
        const user = this.filterFormGroup.get("filterUser")?.value;
        const startDate = this.filterFormGroup.get("filterStartDate")?.value;
        const endDate = this.filterFormGroup.get("filterEndDate")?.value;

        if (reference_type) filters["reference_type"] = reference_type.join(",");
        if (event) {
            const events = event.filter((e: any) => typeof e === "string");
            const activities = event.filter((e: any) => typeof e !== "string");
            if (events.length > 0) filters["events"] = events.join(",");
            if (activities.length > 0) filters["activities"] = activities.join(",");
        }
        if (user) filters["account"] = user.join(",");
        if (startDate) filters["startDate"] = startDate.toISOString();
        if (endDate) filters["endDate"] = endDate.toISOString();
        return filters;
    }

    onSortChange(event: Sort): void {}

    hasGroupDetails(entry: Audit): boolean {
        return false;
    }
    groupDetails(entry: Audit): any[] {
        return [];
    }
    groupEvent(entry: Audit, detail: any): any {
        return undefined;
    }

    clearFilters(event: MouseEvent): void {
        this.filterFormGroup.reset();
        this.updatedFilters = undefined;
        this.updateAuditTrail();
    }

    sortFilterOption(a: AuditFilterOption, b: AuditFilterOption): number {
        if (a.displayName < b.displayName) return -1;
        if (a.displayName > b.displayName) return 1;
        return 0;
    }

    handlePageEvent(event: PageEvent): void {
        this.page = event.pageIndex + 1;
        this.perPage = event.pageSize;
        this.updateAuditTrail();
    }

    showAuditDetail(entry: Audit): void {
        const dialogConfig = {
            minWidth: "66vw",
            minHeight: "66vh",
            maxWidth: "90vw",
            maxHeight: "90vh",
            disableClose: true,
            hasBackdrop: true,
        };
        const instance = ObjectAdminComponent.showObject<Audit>(
            entry,
            AuditDetailComponent,
            ObjectViewMode.View,
            dialogConfig,
        ) as AuditDetailComponent;
        instance.filters = this.defaultFilters;
    }

    get objectTypes(): AuditFilterOption[] {
        const types = this.updatedFilters?.types ?? this.defaultFilters?.types ?? [];
        return types.sort(this.sortFilterOption);
    }
    get events(): AuditFilterOption[] {
        let events = this.updatedFilters?.events ?? this.defaultFilters?.events ?? [];
        events = events.filter((v: AuditFilterOption) => !!v.value);
        const activities =
            this.updatedFilters?.activities ?? this.defaultFilters?.activities ?? [];
        return [...events, ...activities].sort(this.sortFilterOption);
    }
    get users(): AuditFilterOption[] {
        const users = this.updatedFilters?.users ?? this.defaultFilters?.users ?? [];
        return users.sort(this.sortFilterOption);
    }

    entryType(entry: Audit): string {
        const type = this.defaultFilters?.types.find(
            (t: AuditFilterOption) => t.value == entry.reference?.type,
        );
        if (!type?.displayName && !entry.reference?.type) return "Unknown Type";
        return type?.displayName ?? entry.reference?.type ?? "Unknown Type";
    }
    entryEvent(entry: Audit): string {
        const event =
            this.defaultFilters?.events.find(
                (t: AuditFilterOption) => entry.event && t.value == entry.event,
            ) ??
            this.defaultFilters?.activities.find(
                (t: AuditFilterOption) => t.value == entry.activity,
            );
        return event?.displayName ?? entry.event ?? entry.activity.toString();
    }

    protected updatedFilterCount(
        def: AuditFilterOption[],
        filtered: AuditFilterOption[],
    ): AuditFilterOption[] {
        return def.map((option: AuditFilterOption) => {
            const found = filtered.find(
                (v: AuditFilterOption) => v.value == option.value,
            );
            return {
                value: option.value,
                displayName: option.displayName,
                count: found ? found.count : 0,
            };
        });
    }

    protected updateAuditFilters(): void {
        this.auditService.filters(this.filters).subscribe((filter: AuditFilter) => {
            if (!this.defaultFilters) this.defaultFilters = filter;
            else {
                this.updatedFilters = {
                    earliest: filter.earliest,
                    latest: filter.latest,
                    types: this.updatedFilterCount(
                        this.defaultFilters.types,
                        filter.types,
                    ),
                    events: this.updatedFilterCount(
                        this.defaultFilters.events,
                        filter.events,
                    ),
                    activities: this.updatedFilterCount(
                        this.defaultFilters.activities,
                        filter.activities,
                    ),
                    users: this.updatedFilterCount(
                        this.defaultFilters.users,
                        filter.users,
                    ),
                };
            }
        });
    }
    protected updateAuditTrail(): void {
        this.auditService.list(this.filters).subscribe((list: APIListResult<Audit>) => {
            const paginatedList = list as PaginatedList<Audit>;
            this.auditTrail = paginatedList.items;
            this.paginatorLength = paginatedList.count;
        });
    }
}
