<h3 mat-dialog-title>Export Case Data</h3>
<mat-dialog-content>
    <div
        class="wrapper flex stack full"
        [formControl]="formControl"
        style="padding: 1rem"
    >
        <mat-form-field class="full">
            <mat-label>
                Select the program(s) that you want to download case data for.
            </mat-label>
            <mat-select
                required
                formControlName="programs"
                multiple
                placeholder="Select one or more programs"
            >
                <mat-option *ngFor="let program of programs" [value]="program">
                    {{ program.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="spacer"></div>
    <button mat-flat-button type="button" class="fixed" mat-dialog-close>Cancel</button>
    <button
        mat-flat-button
        type="button"
        class="fixed"
        color="accent"
        [disabled]="!control.valid"
        (click)="submit($event)"
    >
        Submit
    </button>
</mat-dialog-actions>
