import { TeamMember } from "./../../../services/models/team";
import { NgControl, ControlValueAccessor, FormControl } from "@angular/forms";
import {
    Component,
    HostBinding,
    Renderer2,
    Optional,
    Self,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    HostListener,
    Input,
} from "@angular/core";
import { Subject } from "rxjs";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { QuillEditorComponent } from "ngx-quill";
import "quill-mention";
import { MatFormFieldControl } from "@angular/material/form-field";
// this is the format the mention functionality expects
interface FormattedAccount {
    id: string | undefined;
    value: string | undefined;
    email: string | undefined;
}
/**
 * A RichTextEditor is an interface for editing rich text within web browsers, which presents the user with a "what-you-see-is-what-you-get" (WYSIWYG) editing area. The aim is to reduce the effort for users trying to express their formatting directly as valid HTML markup.
 * This is meant to be used inside of a mat-form-field tag.
 * This is a wrapper around the ngx-quill RTE component. See : https://www.npmjs.com/package/ngx-quill for docs.
 * @param value Any - the value of the form field, mainly to decide whether or not to float the label
 * @param required Boolean(Optional) - Whether or not the field is requried
 * @param disabled Boolean(Optional) - Whether or not the user is allowed to interact with this field
 * @param modules Object (Optional)- Object to customize or add features to the Quill rich text editor. For the toolbar options an empty array will use the default options.
 * @param disableToolbar Boolean(Optional) - Whether or not all styling options are disabled. Default is false
 * @param enableMentions Boolean(Optional) - Whether or not the @ functionality is enabled. Default is false. Requires the staffAccount array and current AccountId string
 * @param staffAccounts Role[](Optional) - Array of roles that you want to @ at
 * @param currentAccountId string(Optional) - id of the current user account
 * @param bindings object(Optional) - object with customized key bindings (see discussion.component for an example)
 * @param customToolbar string[] - array of strings that will enable features of the RTE, see bellow for the default
 * @param customStyles object (optional) - object to set styles on the rich-text-editor (not the container ! the actual input field).
 */
@Component({
    selector: "rich-text-editor",
    templateUrl: "./rich-text-editor.component.html",
    styleUrls: ["./rich-text-editor.component.scss"],
    providers: [{ provide: MatFormFieldControl, useExisting: RichTextEditorComponent }],
})
export class RichTextEditorComponent
    implements MatFormFieldControl<any>, ControlValueAccessor, OnDestroy, OnInit
{
    @ViewChild(QuillEditorComponent) editor!: QuillEditorComponent;
    @Input("aria-describedby") userAriaDescribedBy: string;
    @Input() modules: {
        toolbar: any[] | false;
        mention: {};
        keyboard: { bindings: {} };
    } = {
        toolbar: [],
        mention: {},
        keyboard: { bindings: {} },
    };
    @Input() disableToolbar = false;
    @Input() enableMentions = false;
    @Input() staffAccounts: TeamMember[] = [];
    @Input() currentAccountId: string | undefined = "";
    @Input() bindings = {};
    @Input() customToolbar = [] as any;

    get defaultStyles() {
        // styles for any instance of this component
        return { padding: "12px 15px" };
    }

    @Input() customStyles?: { [key: string]: string };

    get styles() {
        if (!this.customStyles) return this.defaultStyles;
        //BE AWARE: if custom styles has the same key name as any key in default, it will overwrite it to whatever the value is in the custom styles
        return { ...this.defaultStyles, ...this.customStyles };
    }
    private defaultToolbar = [
        // default customization, just remove the features you dont want in a copy
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button

        ["link"], // link and image, video (image and video is disabled for now)
    ];
    ngOnInit() {
        if (!this.disableToolbar) {
            this.modules.toolbar =
                this.customToolbar.length ? this.customToolbar : this.defaultToolbar;
        } else this.modules.toolbar = false; // disabled
        if (this.enableMentions) {
            this.modules.mention = {
                defaultMenuOrientation: "top",
                allowedChars: /^[A-Za-z\s]*$/,
                // what the object structure looks like in account parameter in onSelect
                // default looks like: ['id', 'value', 'denotationChar', 'link', 'target','disabled']
                dataAttributes: ["id", "value", "email"],
                onSelect: (account: FormattedAccount, insertItem: Function) => {
                    const editor = this.editor.quillEditor;
                    insertItem(account);
                    // necessary because quill-mention triggers changes as 'api' instead of 'user'
                    editor.insertText(editor.getLength() - 1, "", "user");
                },
                source: (searchTerm: string, renderList: Function): void => {
                    const formattedAccounts: FormattedAccount[] = [];
                    this.staffAccounts.forEach((r: TeamMember) => {
                        if (r.account?.id === this.currentAccountId) return; //dont want users to be able to mention themselves
                        formattedAccounts.push({
                            id: r.account.id,
                            value: r.account.name,
                            email: r.email,
                        });
                    });

                    if (searchTerm.length === 0) {
                        renderList(formattedAccounts, searchTerm);
                    } else {
                        // FYI this is case sensitive
                        const matches: FormattedAccount[] = formattedAccounts.filter(
                            (acc) =>
                                acc.value
                                    ?.toLowerCase()
                                    .includes(searchTerm.toLowerCase()),
                        );
                        renderList(matches, searchTerm);
                    }
                },
            };
        } else {
            this.modules.mention = {}; //disabled
        }

        this.modules.keyboard.bindings = this.bindings;
    }

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private elementRef: ElementRef<HTMLElement>,
        private renderer: Renderer2,
    ) {
        this.userAriaDescribedBy = "";
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            // see: https://stackoverflow.com/a/51837753/17073650
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    controlType = "rich-text-editor";
    @HostBinding() id = `rich-text-editor-${RichTextEditorComponent.nextId++}`;
    @HostBinding("class.floating")
    get shouldLabelFloat() {
        return true;
    }

    @HostListener("document:click", ["$event"])
    handleClickOutside(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target as Node)) {
            this.touched = true;
            this.focused = false;

            this.onTouched();
            this.stateChanges.next();
        }
    }
    setDescribedByIds(ids: string[]) {
        const controlElement = this.elementRef.nativeElement.querySelector(
            ".rich-text-editor-container",
        );
        if (controlElement) {
            this.renderer.setAttribute(
                controlElement,
                "aria-describedby",
                ids.join(" "),
            );
        }
    }

    static nextId = 0;
    stateChanges = new Subject<void>();

    value_: string = "";
    @Input()
    get value() {
        return this.value_;
    }

    set value(v: any) {
        this.value_ = v;
        this.stateChanges.next();
        this.propagateChange(this.value_);
    }

    get required() {
        return this._required;
    }
    @Input()
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(v: string) {
        this._placeholder = v;
        this.stateChanges.next();
    }

    private _placeholder: string = "";

    writeValue(value: any) {
        if (value) {
            this.value = value;
        }
    }

    propagateChange = (_: any) => {};
    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched() {} // NOSONAR

    focused: boolean = false;
    touched: boolean = false;

    onTouched: any = () => {};
    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        this.touched = true;
        this.focused = false;

        this.onTouched();
        this.stateChanges.next();
    }

    get empty() {
        if (!this.value) {
            return true;
        }
        return this.value.length <= 0;
    }

    get errorState(): boolean {
        return !!(this.ngControl ? this.ngControl.invalid && this.touched : false);
    }

    onContainerClick(event: MouseEvent) {
        if (!this.focused) {
            this.editor.quillEditor.focus();
            this.focused = true;
            this.stateChanges.next();
        }
    }

    get formControl() {
        return this.ngControl.control as FormControl;
    }
}
