import { GenerateUniqueIdentifier } from "src/common/utilities/utilities";
import { debounceTime, map } from "rxjs";
import { AfterViewInit, Component, Inject } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { DataForm } from "src/services/models/data";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

type DataFormPropetiesData = {
    form: DataForm;
    namePrefix?: string;
    siblings?: string[];
    viewOnly: boolean;
    title?: string;
};

@Component({
    templateUrl: "./data-form-properties.component.html",
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormPropertiesComponent implements AfterViewInit {
    form: DataForm;
    formGroup: FormGroup;

    autogenerateName: boolean;
    canInstantiate: boolean;
    namePrefix?: string;
    siblings?: string[];
    viewOnly = false;
    title = "Form Properties";
    constructor(
        @Inject(MAT_DIALOG_DATA) data: DataFormPropetiesData,
        protected formBuilder: FormBuilder,
        protected dialogReference: MatDialogRef<DataFormPropertiesComponent>,
    ) {
        this.formGroup = this.formBuilder.group({
            display_name: [null, Validators.required],
            name: [null, Validators.required],
            description: [],
        });
        this.form = data.form;
        if (data.viewOnly) {
            this.viewOnly = true;
        }
        if (data.title) {
            this.title = data.title;
        }
        this.autogenerateName = !this.form.name;
        this.canInstantiate = this.form.attributes?.canInstantiate;
        this.namePrefix = data.namePrefix;
        this.siblings = data.siblings;
    }

    ngAfterViewInit(): void {
        this.formGroup
            .get("display_name")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onDisplayNameChanged(v));
        this.formGroup
            .get("name")
            ?.valueChanges.pipe(
                debounceTime(400),
                map((v: string) => v.trim().replace(/ /g, "-").toLowerCase()),
            )
            .subscribe((v: string) => this.onNameChanged(v));
        this.formGroup
            .get("description")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onDescriptionChanged(v));

        this.updateProperties();
        if (this.viewOnly) {
            this.formGroup.disable();
        }
    }

    onDisplayNameChanged(value: string): void {
        this.form.display_name = value;

        if (this.autogenerateName) {
            const prefix = this.namePrefix ? this.namePrefix + "." : "";
            const name = GenerateUniqueIdentifier(
                prefix + "." + (value || "unnamed"),
                this.siblings ?? [],
                "-",
            );
            this.formGroup.get("name")?.setValue(name);
        }
    }
    onNameChanged(value: string): void {
        this.form.name = value;
    }
    onIdentifierFocus(): void {
        const control = this.formGroup.get("name");
        if (this.form.name != control?.value) control?.setValue(this.form.name);
    }
    onDescriptionChanged(value: string): void {
        this.form.description = value;
    }

    toggleAutogenerate(): void {
        this.autogenerateName = !this.autogenerateName;
        this.updateEnableDisable();
        this.onDisplayNameChanged(this.form.display_name);
    }
    toggleCanInstantiate(): void {
        this.canInstantiate = !this.canInstantiate;
        if (!this.form.attributes) this.form.attributes = {};
        this.form.attributes.canInstantiate = this.canInstantiate;
        this.formGroup.markAsDirty();
    }
    protected updateEnableDisable(): void {
        if (this.autogenerateName) {
            this.formGroup.get("name")?.disable();
        } else {
            this.formGroup.get("name")?.enable();
        }
    }
    protected updateProperties(): void {
        this.formGroup.get("display_name")?.setValue(this.form.displayName);
        this.formGroup.get("name")?.setValue(this.form.name);
        this.formGroup.get("description")?.setValue(this.form.description);

        this.updateEnableDisable();
    }
}
