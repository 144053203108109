import { EnvironmentService } from "src/app/environment.service";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
    inject,
} from "@angular/core";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { Account } from "src/services/models/account";
import { AccountService } from "src/services/iam.services";
import { Role, RoleDefinition } from "src/services/models/role";
import { AccountComponent } from "./account.component";
import { UntypedFormControl } from "@angular/forms";
import {
    ObjectViewEntryPoint,
    ObjectViewMode,
} from "src/common/components/object.component";
import { debounceTime, filter } from "rxjs/operators";
import { Sort } from "@angular/material/sort";
import { RequestFilter } from "src/common/utilities/request";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatFooterRowDef, MatTable } from "@angular/material/table";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "account-admin",
    templateUrl: "./account-admin.component.html",
    styleUrls: ["./account-admin.component.scss"],
})
export class AccountAdminComponent extends ObjectAdminComponent<Account> {
    static buildMap = (
        propName: string,
        arr: RoleDefinition[],
    ): Map<string, RoleDefinition> => {
        let retVal: Map<string, RoleDefinition> = new Map<string, RoleDefinition>();
        for (const element of arr) {
            let obj = element;
            retVal.set(obj.value, obj);
        }
        return retVal;
    };

    roles: Map<string, RoleDefinition> = AccountAdminComponent.buildMap(
        "role",
        Role.roles,
    );
    objectView = AccountComponent;
    displayedColumns: string[] = [
        "account_name",
        "account_email",
        "role_count",
        "actions",
    ];
    environmentService: EnvironmentService;

    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
    @ViewChild(MatFooterRowDef, { static: true }) footerDef?: MatFooterRowDef;
    @ViewChild(MatTable, { static: true }) table?: MatTable<Document>;
    @ViewChild("search") searchElement?: ElementRef;
    @ViewChild("filter") filterElement?: ElementRef;

    searchTermControl: UntypedFormControl = new UntypedFormControl();
    showSearch: boolean = false;

    constructor(
        protected service: AccountService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
        this.environmentService = inject(EnvironmentService);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => this.updateList(term));
    }

    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    resetSearchTerm(event?: MouseEvent): void {
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
    }
    toggleSearch(event: MouseEvent): void {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
    }
    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }
    onFocusOut(event: any): void {
        this.autoToggleSearch();
    }
    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    auth0Sync(event: MouseEvent, account: Account): void {
        this.service.auth0Sync(account).subscribe();
    }
    get hasAuth0Connection(): boolean {
        return this.environmentService.auth0Client != "";
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        return filters;
    }

    createObject(
        event?: MouseEvent,
        asDialog?: boolean,
    ): ObjectViewEntryPoint<Account> | undefined {
        const entryPoint = super.createObject(event, asDialog);
        if (entryPoint?.mode != ObjectViewMode.Create) {
            entryPoint?.dialogReference?.updateSize(undefined, "78%");
        }
        return entryPoint;
    }

    protected objectDialogConfiguration(
        object: Account,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        if (mode != ObjectViewMode.Create) {
            return {
                ...config,
                width: "90%",
                minHeight: "75%",
            };
        }
        return config;
    }
}
