import { Component } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
    selector: "dt-checkbox",
    template: `
        <mat-checkbox [checked]="hasValues" color="accent" (change)="onChecked($event)">
            {{ checkboxText || instructions || label }}
        </mat-checkbox>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeCheckbox extends BaseDataTypeComponent {
    checkedValue(): any {
        if (this.type?.name == "date" || this.type?.name == "datetime")
            return new Date();
        if (this.type?.name == "boolean") return true;
        if (this.type?.name == "checkbox") return true;
        return true;
    }

    onChecked(event: MatCheckboxChange): void {
        const value = event.checked ? new Date() : undefined;
        this.control.setValue(value);
    }

    get checkboxText(): string | undefined {
        return this.attributes?.alt?.checkboxText;
    }
}
