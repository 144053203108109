<div class="container">
    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Forms">
            <ng-template matTabContent>
                <data-form-admin [repository]="repository"></data-form-admin>
            </ng-template>
        </mat-tab>
        <mat-tab label="Fields">
            <ng-template matTabContent>
                <data-field-list [repository]="repository"></data-field-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Types">
            <ng-template matTabContent>
                <data-type-admin [repository]="repository"></data-type-admin>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
