import { DataFormAttributes } from "./../../../services/models/data";
import { finalize } from "rxjs";
import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import { ObjectViewMode } from "./../../../common/components/object.component";
import { InquiryService, ProgramService } from "./../../../services/program.services";
import { Component, OnInit, ViewChild, inject } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { ActivatedRoute, Router } from "@angular/router";
import { IntakeService } from "../../../services/program.services";
import { Intake } from "../../../services/models/intake";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { Program } from "../../../services/models/program";
import { Location } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { DataForm } from "src/services/models/data";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
    CompoundDataTypeFactory,
    DataFieldService,
    DataFormFieldFactory,
    DataFormService,
    DataTypeService,
} from "src/services/data.services";

enum IntakeStep {
    Role = 0,
    Patient = 1,
    Provider = 2,
    Submitted = 3,
    Error = 4,
}
type SelectionObject = { value: string; display: string };

@Component({
    selector: "intake",
    templateUrl: "./intake.component.html",
    styleUrls: ["./intake.component.scss"],
})
export class IntakeComponent extends SessionComponent implements OnInit {
    intakeRoles: SelectionObject[] = [
        { value: "provider", display: "Healthcare Provider" },
        { value: "patient", display: "Patient" },
        { value: "family", display: "Family Member" },
    ];

    @ViewChild(DataFormComponent) formComponent?: DataFormComponent;

    ObjectViewMode = ObjectViewMode;
    protected snackBar!: MatSnackBar;
    protected intakeService: IntakeService;
    protected inquiryService: InquiryService;
    readonly currentYear: number = new Date().getFullYear();
    intakeError?: string;
    intake?: Intake;
    logo?: string;
    _currentStep: IntakeStep = IntakeStep.Role;
    lastStep?: IntakeStep;
    steps = IntakeStep;

    formGroup: UntypedFormGroup;
    patientFormGroup: UntypedFormGroup;
    providerFormGroup: UntypedFormGroup;

    get templateLogoSource(): string | undefined {
        if (!this.logo && this.intake?.logo)
            // random will force the browser to load image from the server instead of the cache
            // only way for logo updates to be rendered immediately in the intake component without backend changes
            this.logo =
                this.intakeService.session.environment.services +
                "/media/" +
                this.intake?.logo +
                "?random+=" +
                Math.random();
        return this.logo;
    }
    get currentStep(): IntakeStep {
        return this._currentStep;
    }
    set currentStep(step: IntakeStep) {
        if (step != this._currentStep) {
            this.lastStep = this._currentStep;
            this._currentStep = step;
            if (step == IntakeStep.Role) this.lastStep = undefined;
        }
    }
    get canSubmit(): boolean {
        return (
            this.currentStep == IntakeStep.Patient ||
            this.currentStep == IntakeStep.Provider
        );
    }
    get physicianLink(): string {
        return (
            window.location.origin +
            "/" +
            this.route.snapshot.url.join("/") +
            "?role=provider"
        );
    }

    get availableRoles(): SelectionObject[] {
        return this.intakeRoles.filter((role: SelectionObject) => {
            return (
                (role.value == "provider" && this.intake?.forms.hcp) ||
                (role.value == "patient" &&
                    this.intake?.forms.allow_patient &&
                    this.intake?.forms.patient) ||
                (role.value == "family" &&
                    this.intake?.forms.allow_family &&
                    this.intake?.forms.family)
            );
        });
    }

    get responseTime() {
        return this?.selectedProgram?.response_time;
    }
    errors(path: string | string[]): ValidationErrors | null | undefined {
        return this.formGroup.get(path)?.errors;
    }
    dropDownErrorMessage: string =
        "Invalid selection, please select an option from the dropdown.";

    constructor(
        protected route: ActivatedRoute,
        protected fb: UntypedFormBuilder,
        protected location: Location,
        protected router: Router,
    ) {
        super();
        this.intakeService = inject(IntakeService);
        this.inquiryService = inject(InquiryService);
        this.snackBar = inject(MatSnackBar);

        /* Ensure factories necessary for intake are available */
        inject(ProgramService);
        inject(DataTypeService);
        inject(DataFieldService);
        inject(DataFormService);
        inject(DataFormFieldFactory);
        inject(CompoundDataTypeFactory);

        this.session.onMessage.subscribe((msg: string) => {
            this.snackBar.open(msg, undefined, { duration: 2500 });
        });
        this.patientFormGroup = this.fb.group({
            caregiver: [null],
            acknowledge_phi: [false, Validators.requiredTrue],
            comm_consent: [null, Validators.requiredTrue],
        });
        this.providerFormGroup = this.fb.group({
            comm_consent: [null, Validators.requiredTrue],
            share_auth: [null, Validators.requiredTrue],
            acknowledge_phi: [null, Validators.requiredTrue],
        });
        this.formGroup = this.fb.group({
            role: [null, Validators.required],
        });
    }

    selectedProgram?: Program;
    orgSlug: string | undefined | null;

    ngOnInit(): void {
        const routeParams = this.route.snapshot.paramMap;
        const organizationSlug = routeParams.get("organizationSlug");
        this.orgSlug = organizationSlug;
        const programSlug = routeParams.get("programSlug") ?? undefined;

        this.formGroup.get("role")?.valueChanges.subscribe(() => this.onNextButton());

        const role = this.route.snapshot.queryParamMap.get("role");

        if (organizationSlug) {
            this.intakeService.config(organizationSlug, programSlug).subscribe({
                next: (intake: Intake | undefined) => {
                    this.intake = intake;
                    this.selectedProgram = this.intake?.programs.find(
                        (p: Program) => p.slug && p.slug == programSlug,
                    );
                    const selectedRole = this.availableRoles.find(
                        (r: SelectionObject) => r.value == role,
                    );
                    const roleField = this.formGroup.get("role");
                    roleField?.setValue(selectedRole?.value);
                    if (!selectedRole && this.availableRoles.length == 1) {
                        roleField?.setValue(this.availableRoles[0].value);
                    }
                },
                error: (err: any) =>
                    (this.intakeError =
                        err?.error?.error_description ??
                        err?.error?.detail ??
                        err?.error?.error ??
                        err?.message ??
                        "Invalid Request"),
            });
        } else this.intakeError = "Invalid Request";
    }

    get selectedForm(): DataForm | undefined {
        if (this.currentForm == "provider") return this.intake?.forms.hcp;
        else if (this.currentForm == "patient") return this.intake?.forms.patient;
        else if (this.currentForm == "family") return this.intake?.forms.family;
        return undefined;
    }
    get currentForm(): string {
        if (!this.formGroup.get("role")?.value) {
            return "";
        }
        if (this.formGroup.get("role")?.value == "family") {
            return "patient";
        }
        return this.formGroup.get("role")!.value;
    }
    get currentFormName(): string | undefined {
        if (this.currentForm == "provider") return this.intake?.forms.hcp?.name;
        else if (this.currentForm == "patient") return this.intake?.forms.patient?.name;
        else if (this.currentForm == "family") return this.intake?.forms.family?.name;
        return undefined;
    }

    get intakeFormAttributes(): DataFormAttributes {
        return {
            intake: true,
        };
    }

    copyPhysicianLink(event: MouseEvent): void {
        this.terminateEvent(event);
        this.session.message = "The link has been copied to your clipboard";
    }

    productDisplay(product?: Program): string {
        return product?.drug_name ?? product?.displayName ?? "";
    }

    onBackButton(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.lastStep !== undefined) this.currentStep = this.lastStep;
        if (this.currentStep == IntakeStep.Role) {
            this.formGroup.removeControl("provider");
            this.formGroup.removeControl("patient");
            this.formGroup.get("role")?.setValue(undefined);
        }
    }
    onNextButton(event?: MouseEvent): void {
        if (event) this.terminateEvent(event);
        if (this.currentStep == IntakeStep.Role) {
            let requireCaregiver = false;
            switch (this.currentForm) {
                case "provider":
                    this.currentStep = IntakeStep.Provider;
                    this.formGroup.setControl("provider", this.providerFormGroup);
                    break;
                case "patient":
                case "family":
                    this.currentStep = IntakeStep.Patient;
                    this.formGroup.setControl("patient", this.patientFormGroup);
                    break;
            }
            const patientGroup = this.formGroup.get("patient") as UntypedFormGroup;
            const caregiverControl = patientGroup?.get(
                "caregiver",
            ) as UntypedFormControl;
            const validators = requireCaregiver ? [Validators.required] : null;
            caregiverControl?.setValidators(validators);
        }
    }
    onSubmit(form: any): void {
        if (form instanceof DataForm) {
            this.formComponent!.submitProcessing = true;

            const formData = this.formGroup.value;
            const currentForm = formData[this.currentForm];

            let role = "provider.physician";
            if (this.currentForm == "provider" && currentForm?.is_staff)
                role = "provider.staff";
            else if (this.currentForm == "patient") role = "patient";
            else if (this.currentForm == "caregiver") role = "patient.caregiver";

            // clean up the form values
            form.template = form.asReference;
            form.id = undefined;
            if (!form.owner) form.owner = this.intake?.organization;

            let data: any = {
                "request.role": role,
                "request.source": "hosted",
                "request.form": form.serialize(),
            };

            if (currentForm["comm_consent"])
                data["request.consent.communication"] = new Date();
            if (currentForm["acknowledge_phi"])
                data["request.consent.phi"] = new Date();
            if (currentForm["share_auth"]) data["request.consent.share"] = new Date();

            this.inquiryService
                .intake(data)
                .pipe(finalize(() => (this.formComponent!.submitProcessing = false)))
                .subscribe({
                    next: () => {
                        this.session.message =
                            "Your request has been successfully submitted.";
                        this.currentStep = IntakeStep.Submitted;
                    },
                    error: (res: HttpErrorResponse) => {
                        try {
                            this.session.message = res.error.join(" ");
                        } catch {
                            this.session.message = "An Error Occurred";
                        }

                        this.currentStep = IntakeStep.Error;
                    },
                });
        } else {
            // Placeholder, remove later: MED-1134 Moved DataFormComponent to be an object component
        }
    }

    get hasChanged(): boolean {
        return !!this.formComponent?.hasChanged;
    }
    get isValid(): boolean {
        const providerValid = this.providerFormGroup.valid;
        const patientValid = this.patientFormGroup.valid;
        let valid = !!this.formComponent?.isValid;
        if (this.currentStep == this.steps.Provider && !providerValid) valid = false;
        if (this.currentStep == this.steps.Patient && !patientValid) valid = false;
        return valid;
    }

    handleKeyDown(event: KeyboardEvent): void {
        if (event.keyCode === 13 && !event.shiftKey) event.preventDefault();
    }
}
