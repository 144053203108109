import { DashboardComponent } from "src/app/components/dashboard/dashboard.component";
import { Component, OnInit } from "@angular/core";
import { contentStack, ContentView } from "../content-stack.component";

export interface Breadcrumb {
    icon?: any;
    text: string;
    onSelect?: (event: MouseEvent) => void;
}
export interface BreadcrumbProvider {
    getBreadcrumbs(current: boolean): Breadcrumb | Breadcrumb[] | undefined;
}

@Component({
    selector: "breadcrumbs",
    templateUrl: "./breadcrumb.component.html",
    styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
    crumbs: Breadcrumb[] = [];

    get stackCount(): number {
        return contentStack?.count ?? 0;
    }
    getCrumbs(stack: ContentView<any>[]): Breadcrumb[] {
        const breadcrumbs = stack.slice().reverse();
        let crumbs =
            breadcrumbs
                ?.map(
                    (
                        view: ContentView<any>,
                        index: number,
                        array: ContentView<any>[],
                    ) => {
                        const isCurrentView = index == array.length - 1;
                        let crumbs;
                        if (
                            view.content?.instance &&
                            "getBreadcrumbs" in view.content.instance
                        ) {
                            const c =
                                view.content?.instance.getBreadcrumbs(isCurrentView);
                            if (c) crumbs = Array.isArray(c) ? c : [c];
                        }
                        if (!crumbs && view.breadcrumbText) {
                            crumbs = [
                                {
                                    icon: view.breadcrumbIcon,
                                    text: view.breadcrumbText,
                                },
                            ];
                        }
                        crumbs = crumbs ?? [];
                        crumbs.forEach((crumb: Breadcrumb) => {
                            const fn = crumb.onSelect;
                            crumb.onSelect = (event: MouseEvent) => {
                                contentStack?.selectCrumb(event, index);
                                if (fn) fn(event);
                            };
                        });
                        return crumbs;
                    },
                )
                .reduce(
                    (previous: Breadcrumb[], current: Breadcrumb[]) =>
                        previous.concat(...current),
                    [],
                ) || [];

        // If the dashboard component isn't our root, insert it artificially so that there's a home element
        if (!!breadcrumbs.length && breadcrumbs[0].type != DashboardComponent) {
            crumbs = [DashboardComponent.breadcrumb, ...crumbs];
        }
        return crumbs;
    }

    ngOnInit(): void {
        this.crumbs = this.getCrumbs(contentStack?.breadcrumbs ?? []);
        contentStack?.onStackChanged.subscribe(
            (stack: ContentView<any>[]) => (this.crumbs = this.getCrumbs(stack)),
        );
    }

    iconIsMaterial(icon?: any): boolean {
        return typeof icon === "string";
    }
    iconIsAwesome(icon?: any): boolean {
        return !!icon && typeof icon !== "string";
    }
    faIcon(icon?: any): any {
        return icon;
    }

    onSelect(event: MouseEvent, crumb: Breadcrumb): void {
        if (crumb.onSelect) crumb.onSelect(event);
    }
}
