<mat-form-field>
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
        type="text"
        aria-label="Search"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="auto"
        [placeholder]="placeholder"
        #trigger="matAutocompleteTrigger"
    />
    <mat-hint *ngIf="hint?.condition" [class]="hint?.class">{{ hint?.text }}</mat-hint>

    <mat-spinner *ngIf="loading" matPrefix [diameter]="20"></mat-spinner>
    <mat-icon *ngIf="!trigger.panelOpen" matSuffix style="cursor: pointer">
        arrow_drop_down
    </mat-icon>
    <mat-error *ngIf="invalidAutoComplete">Please select a valid option.</mat-error>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ option.displayName }}
            <span class="secondary small">{{ option.name }}</span>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
