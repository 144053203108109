<div class="intake-app-bar mat-elevation-z4">
    <div class="wrapper flex full intake-header">
        <ng-container *ngIf="intake && intake.url">
            <a [href]="intake.url" class="title">
                <img
                    [src]="templateLogoSource"
                    class="brand-logo"
                    *ngIf="templateLogoSource"
                    [alt]="intake.organization.displayName"
                />
                <span *ngIf="!templateLogoSource">
                    {{ intake.organization.displayName }}
                </span>
            </a>
        </ng-container>
        <ng-container *ngIf="intake && !intake.url">
            <div class="title">
                <img
                    [src]="templateLogoSource"
                    class="brand-logo"
                    *ngIf="templateLogoSource"
                    [alt]="intake.organization.displayName"
                />
                <span *ngIf="!templateLogoSource">
                    {{ intake.organization.displayName }}
                </span>
            </div>
        </ng-container>
        <div class="spacer"></div>
        <div class="powered-by flex stack">
            <div class="flex tag secondary">
                powered by&nbsp;
                <img alt="logo" class="logo" src="/assets/logo.png" />
            </div>
        </div>
    </div>
</div>
<div class="intake-content-wrapper">
    <div class="content flex">
        <div class="intake-form flex stack" *ngIf="!intake">
            <div class="intake-header">
                Please visit
                <a href="https://medasystems.com">the MedaSystems website</a>
                for more information about our Expanded Access management tools.
            </div>
        </div>
        <form
            class="intake-form flex stack"
            *ngIf="!!intake"
            [formGroup]="formGroup"
            novalidate
        >
            <div class="intake-header" *ngIf="false">Requests for Expanded Access</div>
            <div
                class="intake-header content-card"
                *ngIf="currentStep == steps.Submitted"
            >
                <span>Thank you!</span>
                <p class="submitted-subtitle">
                    We’ve received your inquiry. You should get an email confirmation in
                    your inbox shortly.
                </p>
                <p class="submitted-subtitle" *ngIf="responseTime">
                    We usually respond to inquiries within {{ responseTime }}.
                </p>
            </div>
            <div class="intake-header content-card" *ngIf="currentStep == steps.Error">
                <p class="submitted-subtitle">
                    There was an error processing your inquiry. Please contact the
                    system administrator.
                </p>
            </div>
            <div class="intake-step flex stack" *ngIf="currentStep == steps.Role">
                <div class="instructions">
                    Thanks for your interest. Please tell us about yourself.
                </div>
                <mat-form-field class="intake-role">
                    <mat-label>I am a...</mat-label>
                    <mat-select formControlName="role">
                        <mat-option
                            *ngFor="let option of availableRoles"
                            [value]="option.value"
                        >
                            {{ option.display }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="errors('role')?.required">
                        This field is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div
                class="intake-step patient-step flex stack content-card"
                *ngIf="
                    intake &&
                    (currentStep == steps.Patient || currentStep == steps.Provider)
                "
            >
                <data-form
                    [object]="selectedForm"
                    [mode]="ObjectViewMode.Create"
                    [organization]="intake.organization"
                    [productOptions]="intake.programs"
                    [intake]="true"
                    [createAttributes]="intakeFormAttributes"
                    [hideControls]="true"
                    (formSubmitted)="onSubmit($event)"
                >
                    <ng-container
                        *ngIf="currentStep == steps.Patient"
                        [formGroup]="patientFormGroup"
                    >
                        <div class="flex stack">
                            <mat-checkbox formControlName="acknowledge_phi">
                                I understand that any personal health information which
                                I submit will be handled in accordance with the privacy
                                policies of {{ intake!.organization!.displayName }} and
                                MedaSystems.
                            </mat-checkbox>
                            <mat-checkbox formControlName="comm_consent">
                                By submitting this inquiry I consent to receive
                                communications from
                                {{ intake!.organization!.displayName }}.
                            </mat-checkbox>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="currentStep == steps.Provider"
                        [formGroup]="providerFormGroup"
                    >
                        <div class="flex stack">
                            <mat-checkbox formControlName="share_auth">
                                I confirm that I have all authorizations required by
                                applicable local law to share the patient data for this
                                submission regardless of whether such data is
                                identified, de-identified, or identifiable.
                            </mat-checkbox>
                            <mat-checkbox formControlName="acknowledge_phi">
                                I understand that any personal health information which
                                I submit will be handled in accordance with the privacy
                                policies of {{ intake!.organization!.displayName }} and
                                MedaSystems.
                            </mat-checkbox>
                            <mat-checkbox formControlName="comm_consent">
                                By submitting this inquiry I consent to receive
                                communications from
                                {{ intake!.organization!.displayName }}.
                            </mat-checkbox>
                        </div>
                    </ng-container>

                    <div
                        form-actions
                        class="row flex intake-actions mat-dialog-actions"
                    >
                        <div class="spacer"></div>
                        <button
                            type="button"
                            mat-flat-button
                            (click)="onBackButton($event)"
                            *ngIf="lastStep !== undefined && availableRoles.length > 1"
                        >
                            Back
                        </button>
                        <button
                            type="button"
                            mat-flat-button
                            color="accent"
                            (click)="isValid && onNextButton($event)"
                            *ngIf="!canSubmit"
                            [disabled]="!isValid"
                        >
                            Next
                        </button>
                        <mat-spinner
                            *ngIf="formComponent?.submitProcessing"
                            mode="indeterminate"
                            [diameter]="50"
                        ></mat-spinner>
                        <button
                            type="button"
                            *ngIf="
                                formComponent &&
                                !formComponent.submitProcessing &&
                                canSubmit
                            "
                            mat-flat-button
                            color="accent"
                            [disabled]="!hasChanged || !isValid"
                            (click)="hasChanged && isValid && formComponent.onSave()"
                        >
                            Submit
                        </button>
                    </div>
                </data-form>
            </div>

            <div
                class="intake-actions flex"
                *ngIf="currentStep != steps.Submitted"
            ></div>
        </form>
    </div>
    <div class="flex footer">
        <div class="spacer"></div>
        <div class="copyright">
            &copy;{{ currentYear }} MedaSystems, Inc. All Rights Reserved.&nbsp;|&nbsp;
            <a href="https://medasystems.com/privacy-policy">Privacy Policy</a>
        </div>
        <div class="spacer"></div>
    </div>
</div>
