// date-control-utils.ts
import { FormControl } from '@angular/forms';
import { merge } from 'rxjs';

export interface DateControlConfig {
    betweenStartDate: FormControl;
    betweenEndDate: FormControl;
    dateBefore: FormControl;
    dateAfter: FormControl;
}

export function setDateDisableControls(dateConfigs: DateControlConfig[]): void {
    dateConfigs.forEach((config) => {
        merge(
            config.betweenEndDate.valueChanges,
            config.betweenStartDate.valueChanges
        ).subscribe(() => {
            if (
                config.betweenEndDate.value instanceof Date &&
                config.betweenStartDate.value instanceof Date
            ) {
                config.dateAfter.reset();
                config.dateBefore.reset();
            }
        });

        config.dateAfter.valueChanges.subscribe(() => {
            if (config.dateAfter.value instanceof Date) {
                config.dateBefore.reset();
                config.betweenEndDate.reset();
                config.betweenStartDate.reset();
            }
        });

        config.dateBefore.valueChanges.subscribe(() => {
            if (config.dateBefore.value instanceof Date) {
                config.dateAfter.reset();
                config.betweenEndDate.reset();
                config.betweenStartDate.reset();
            }
        });
    });
}
