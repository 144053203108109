import { Component } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";

@Component({
    selector: "dt-text-long",
    template: `
        <p *ngIf="viewOnly && !hasValues && compoundIndex == 0" class="secondary">
            Value not provided
        </p>
        <p *ngIf="viewOnly && hasValues" class="long-text">{{ displayValue }}</p>
        <ng-container *ngIf="!autoresize && !viewOnly">
            <mat-form-field class="column" [ngClass]="inputClass">
                <mat-label>{{ label }}</mat-label>
                <textarea
                    matInput
                    #input1
                    [placeholder]="placeholder"
                    [formControl]="control"
                    [required]="isRequired"
                    [maxlength]="maxLength"
                ></textarea>
                <mat-hint *ngIf="!!instructions">
                    <quill-view-html [content]="instructions"></quill-view-html>
                </mat-hint>
                <mat-hint align="end" *ngIf="!!maxLength">
                    {{ input1.value.length }} / {{ maxLength }}
                </mat-hint>
                <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="!!autoresize && !viewOnly">
            <mat-form-field class="column" [ngClass]="inputClass">
                <mat-label>{{ label }}</mat-label>
                <textarea
                    matInput
                    #input
                    [placeholder]="placeholder"
                    [formControl]="control"
                    [required]="isRequired"
                    cdkTextareaAutosize
                    [cdkAutosizeMinRows]="minLines"
                    [cdkAutosizeMaxRows]="maxLines"
                    [maxlength]="maxLength"
                ></textarea>
                <mat-hint *ngIf="!!instructions">
                    <quill-view-html [content]="instructions"></quill-view-html>
                </mat-hint>
                <mat-hint align="end" *ngIf="!!maxLength">
                    {{ input.value.length }} / {{ maxLength }}
                </mat-hint>
                <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
            </mat-form-field>
        </ng-container>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeTextLong extends BaseDataTypeComponent {
    get maxLength(): number | null {
        return this.attributes?.validators?.max_length || null; //null will cause no maxlength to be set
    }
    get minLines(): number {
        return this.attributes?.layout?.min_lines || 3;
    }
    get maxLines(): number {
        return this.attributes?.layout?.max_lines || 7;
    }
    get autoresize(): boolean {
        return !!this.attributes?.layout?.autoresize;
    }
}
