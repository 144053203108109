<div class="flex align-center" mat-dialog-title [formGroup]="formGroup">
    <div>{{ title }}</div>
    <div class="spacer"></div>
    <div class="title-1 flex inquiry-case">
        Inquiry
        <mat-slide-toggle class="case-toggle" formControlName="createAsCase" (change)="updateValidity()">
            Case
        </mat-slide-toggle>
    </div>
</div>
<mat-dialog-content [formGroup]="formGroup">
    <div class="form-field align-center" *ngIf="organizations.length > 1">
        <h4 class="label fixed">Organization</h4>
        <mat-form-field class="full">
            <mat-label>Organization</mat-label>
            <mat-select
                formControlName="organization"
                [compareWith]="compareObjects"
                (selectionChange)="orgChange($event)"
            >
                <mat-option *ngFor="let org of organizations" [value]="org">
                    {{ org.displayName }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('organization', 'required')">
                This field is required.
            </mat-error>
        </mat-form-field>
    </div>
    <data-form
        class="inquiry-form"
        *ngIf="intakeForm"
        [object]="intakeForm"
        [mode]="ObjectViewMode.Create"
        [organization]="organization"
        [hideControls]="true"
    ></data-form>
    <div *ngIf="createAsCase">
        <div class="form-field align-center">
            <h4 class="label fixed">Internal Case Name</h4>
            <mat-form-field class="full">
                <mat-label>Internal Case Name</mat-label>
                <input
                    matInput
                    placeholder="Internal Case Name"
                    formControlName="name"
                />
                <mat-error *ngIf="hasError('name', 'uniqueName')">
                    A case with this name already exists. Please choose a different case
                    name.
                </mat-error>
                <mat-error *ngIf="hasError('name', 'required')">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field align-center">
            <h4 class="label fixed">Workflow</h4>
            <mat-form-field class="flexible full">
                <mat-label>Workflow</mat-label>
                <mat-select formControlName="workflow">
                    <mat-option *ngFor="let workflow of workflows" [value]="workflow">
                        {{ workflow.displayName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('workflow', 'required')">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field align-center">
            <h4 class="label fixed">Owner</h4>
            <mat-form-field class="flexible full">
                <mat-label>Owner</mat-label>
                <mat-select formControlName="primary">
                    <mat-option
                        *ngFor="let staff of staffTeam?.members"
                        [value]="staff"
                    >
                        {{ staff.displayName }}
                    </mat-option>
                </mat-select>
                <mat-hint>
                    The case owner will be automatically assigned administrative tasks
                    within the case workflow
                </mat-hint>
                <mat-error *ngIf="hasError('primary', 'required')">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field align-center">
            <h4 class="label fixed">Additional Administrators</h4>
            <mat-form-field class="flexible full">
                <mat-label>Additional Administrator(s) (optional)</mat-label>
                <mat-select formControlName="secondary" multiple="multiple">
                    <mat-option
                        *ngFor="let staff of staffTeam?.members"
                        [value]="staff"
                        [disabled]="primary?.account?.id == staff.account.id"
                    >
                        {{ staff.displayName }}
                    </mat-option>
                </mat-select>
                <mat-hint>
                    Additional administrators will be added to the case team and receive
                    task notifications, but will not be automatically assigned to any
                    workflow tasks.
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="spacer"></div>
    <button mat-flat-button type="button" class="fixed" mat-dialog-close="">
        Cancel
    </button>
    <button
        mat-flat-button
        type="button"
        class="fixed"
        color="accent"
        [disabled]="!intakeFormGroup?.valid || !formGroup.valid || loading"
        (click)="formGroup.valid && create($event)"
    >
        {{ title }}
    </button>
</mat-dialog-actions>
