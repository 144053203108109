import { EventEmitter, Type } from "@angular/core";
import { Observable, Subscription, debounceTime, filter, of } from "rxjs";
import { RequestFilter } from "src/common/utilities/request";

export type ObjectFactoryRegistration<T extends APIObject> = {
    type: Type<T>;
    factory: ObjectFactory<T>;
};
type ObjectFactoryRegistry = { [object_type: string]: ObjectFactoryRegistration<any> };
type CachedObjects<T extends APIObject> = { [key: string]: ObjectOrReference<T> };
export type ObjectChangeEvent<T extends APIObject> = {
    object: ObjectOrReference<T>;
    matched_filters?: number[];
    change_set?: any; // only used in Update events
    replace?: T; // used when replacing an ObjectReference with an instance
};
export class PaginatedList<T extends APIObject> {
    page: number;
    count: number;
    pageSize: number;
    items: ObjectOrReference<T>[];

    constructor(pageSizeOrArray?: number | T[]) {
        this.page = 1;
        if (Array.isArray(pageSizeOrArray)) {
            this.count = pageSizeOrArray.length;
            this.pageSize = this.count;
            this.items = pageSizeOrArray;
        } else {
            this.count = 0;
            this.pageSize = pageSizeOrArray ?? 10;
            this.items = [];
        }
    }
}
export type APIListResult<T extends APIObject> =
    | ObjectOrReference<T>
    | ObjectOrReference<T>[]
    | PaginatedList<T>;
export class FactoryNotFoundError extends Error {}

export abstract class APIObject {
    static object_type: string = "";
    name?: string; // optional
    type!: string;
    id: string | undefined; // uuid, optional
    is_reference!: boolean;
    is_cached: boolean;

    created_at!: Date; // read-only
    modified_at!: Date; // read-only
    modified_by?: ObjectOrReference<APIObject>; // read-only

    objectUpdated: EventEmitter<any> = new EventEmitter<any>(true);

    protected _optional: string[] = ["id"];
    protected _readOnly: string[] = [
        "is_reference",
        "objectUpdated",
        "created_at",
        "modified_at",
        "modified_by",
        "is_cached",
    ];
    protected _references: string[] = ["modified_by"];
    protected _referenceSubscriptions: {
        [key: string]: { [type: string]: Subscription };
    } = {};
    protected _referencesSubscribed: boolean = false;

    get asReference(): ObjectReference {
        return new ObjectReference(this);
    }
    get displayName(): string | undefined {
        return this.id;
    }
    get isComplete(): boolean {
        return !!this.id && !this.is_reference;
    }
    get tabSubtitle(): string | undefined {
        return undefined;
    }
    get isNew(): boolean {
        return new Date().getTime() - this.created_at.getTime() < 5 * 60 * 1000;
    }
    get recentlyModified(): boolean {
        return new Date().getTime() - this.modified_at.getTime() < 5 * 60 * 1000;
    }

    constructor(data?: any) {
        this.is_cached = false;
        if (data && typeof data === "string") {
            try {
                data = JSON.parse(data);
            } catch {
                data = null;
            }
        }
        this.initialize(data && typeof data === "object" ? data : null);

        if (this.is_reference) return this.asReference; // NOSONAR
    }

    initialize(data?: any, patch: boolean = false): void {
        const prototype = Object.getPrototypeOf(this);
        this.type = prototype.constructor.object_type;
        this.setMember(data, patch, "type");
        this.setMember(data, patch, "id");
        this.setMember(data, patch, "is_reference");
        this.setMember(data, patch, "created_at", Date);
        this.setMember(data, patch, "modified_at", Date);
        this.setMember(data, patch, "modified_by", ObjectReference); // Account
    }

    // This function will make sure we're watching for events where a related object which is initialized as a reference
    // gets updated by the full object
    subscribeToReferenceUpdates(): void {
        if (this._referencesSubscribed) return;
        this._referencesSubscribed = true;
        this._references
            .map((key: string) => ({ key: key, obj: (this as any)[key] }))
            .filter((ref: { key: string; obj: any }) => !!ref.obj)
            .forEach((ref: { key: string; obj: any }) => {
                if (ref.obj instanceof ObjectReference) {
                    const factory = ObjectFactory.getObjectFactory(ref.obj.type);
                    if (factory) {
                        const subscription = factory.objectUpdated
                            .pipe(
                                filter(
                                    (event: ObjectChangeEvent<APIObject>) =>
                                        event.object.id == ref.obj.id,
                                ),
                                debounceTime(250),
                            )
                            .subscribe((event: ObjectChangeEvent<APIObject>) => {
                                const self = this as any;
                                self[ref.key] = event.replace ?? event.object;
                                this._referenceSubscriptions[ref.key][
                                    ref.obj.type
                                ].unsubscribe();
                                delete this._referenceSubscriptions[ref.key];
                            });
                        if (!this._referenceSubscriptions[ref.key])
                            this._referenceSubscriptions[ref.key] = {};
                        this._referenceSubscriptions[ref.key][ref.obj.type] =
                            subscription;
                    }
                } else if (Array.isArray(ref.obj)) {
                    const array = ref.obj.filter(
                        (o: any) => o instanceof ObjectReference && o.id,
                    );
                    const types = [
                        ...new Set(array.map((o: ObjectReference) => o.type)),
                    ];
                    if (!this._referenceSubscriptions[ref.key])
                        this._referenceSubscriptions[ref.key] = {};
                    types.forEach((type: string) => {
                        const factory = ObjectFactory.getObjectFactory(type);
                        const ids = array
                            .filter((o: ObjectReference) => o.type == type)
                            .map((o: ObjectReference) => o.id as string);
                        if (factory && ids.length) {
                            const subscription = factory.objectUpdated
                                .pipe(
                                    filter(
                                        (event: ObjectChangeEvent<APIObject>) =>
                                            !!event.object.id &&
                                            ids.includes(event.object.id),
                                    ),
                                    debounceTime(250),
                                )
                                .subscribe((event: ObjectChangeEvent<APIObject>) => {
                                    const self = this as any;
                                    let index = -1;
                                    self[ref.key] = self[ref.key].map(
                                        (v: APIObject) => {
                                            if (v.id == event.object.id)
                                                return event.replace ?? event.object;
                                            return v;
                                        },
                                    );
                                    const referencesLeft = self[ref.key].filter(
                                        (v: APIObject) =>
                                            !!v &&
                                            v instanceof ObjectReference &&
                                            v.type == type,
                                    );
                                    if (!referencesLeft.length) {
                                        this._referenceSubscriptions[ref.key][
                                            type
                                        ].unsubscribe();
                                        delete this._referenceSubscriptions[ref.key][
                                            type
                                        ];
                                    }
                                });
                            this._referenceSubscriptions[ref.key][type] = subscription;
                        }
                    });
                }
            });
    }
    unsubscribeFromReferenceUpdates(): void {
        Object.keys(this._referenceSubscriptions).forEach((key: string) => {
            Object.keys(this._referenceSubscriptions[key]).forEach((type: string) => {
                this._referenceSubscriptions[key][type].unsubscribe();
            });
        });
    }
    update(data?: any): this {
        let change_set = this.diff(data);
        if (!this.is_reference) delete data["is_reference"]; // we don't want to update this field on full objects
        this.initialize(data, true);

        if (change_set !== undefined) {
            this.objectUpdated.emit(change_set);
        }

        return this;
    }
    serialize(nested: string[] = []): any {
        let obj: any = {};
        for (const key in this) {
            if (this.hasOwnProperty(key)) {
                const optional: boolean = this._optional.indexOf(key) !== -1;
                const readOnly: boolean = this._readOnly.indexOf(key) !== -1;
                if (key.startsWith("_") || readOnly) continue;
                let value = this[key];
                const isReference: boolean = this._references.indexOf(key) !== -1;
                if (value !== undefined && value !== null)
                    obj[key] = APIObject.serializeValue(
                        value,
                        nested,
                        key + ".",
                        isReference,
                    );
                else if (!value && !optional) obj[key] = null;
            }
        }
        return obj;
    }
    json(): string {
        return JSON.stringify(this.serialize());
    }
    formData(): FormData {
        let obj: FormData = new FormData();
        for (const key in this) {
            if (this.hasOwnProperty(key)) {
                const optional: boolean = this._optional.indexOf(key) !== -1;
                const readOnly: boolean = this._readOnly.indexOf(key) !== -1;
                if (key.startsWith("_") || readOnly) continue;
                let value: any = this[key];
                if (value !== undefined && value !== null) {
                    if (value instanceof APIObject) {
                        if (value.id) obj.append(key, value.id);
                        else obj.append(key, value.json());
                    } else obj.append(key, value);
                } else if (!value && !optional) obj.append(key, "");
            }
        }
        return obj;
    }
    diff(other: any): any {
        return APIObject.diff_(this, other, this._readOnly);
    }

    protected makeObject<T>(v: any, type: Type<T>): T | ObjectReference | null {
        if (v) {
            let t;
            if (
                v instanceof APIObject &&
                type.hasOwnProperty("object_type") &&
                v.type == (type as any).object_type
            )
                t = v;
            else if (
                v instanceof APIObject &&
                type.hasOwnProperty("object_type") &&
                (type as any).object_type == "object.reference"
            )
                t = v.asReference;
            else t = new type(v);
            if (t instanceof APIObject && !t.is_cached && t.type) {
                try {
                    t = ObjectFactory.makeObject(v, t.type) as T | ObjectReference;
                } catch (exc) {
                    if (
                        exc instanceof FactoryNotFoundError &&
                        t instanceof ObjectReference
                    ) {
                        // we're okay with this.  most common scenario is for role objects that reference object types we haven't loaded factories for yet,
                        // but those don't necessarily need to be converted to full objects
                    } else throw exc;
                }
            }
            return t as T | ObjectReference;
        }
        return null;
    }
    protected setMember(
        data: any,
        patch: boolean,
        key: string,
        type?: Type<any>,
        array: boolean = false,
        nested?: string,
        internal?: string,
    ): void {
        if (
            !this.updateMember(data, key, type, array, nested, internal) &&
            !patch &&
            !this.hasOwnProperty(key) &&
            !this.optional(key)
        ) {
            internal = internal ?? key;
            const value = array ? [] : null;
            Object.assign(this, { [internal]: value });
        }
    }
    protected updateMember(
        data: any,
        key: string,
        type?: Type<any>,
        array: boolean = false,
        nested?: string,
        internal?: string,
    ): boolean {
        internal = internal ?? key;
        if (internal && data && !data.hasOwnProperty(key)) key = internal;
        if (data?.hasOwnProperty(key) && data[key] !== undefined) {
            let value = null;
            let self: any = this;
            if (type) {
                let nestedData = data[key];
                if (nestedData && nested && this.id) {
                    if (array && Array.isArray(nestedData))
                        nestedData = nestedData.map((v: any) => {
                            v[nested] = this.id;
                            return v;
                        });
                    else if (!array) nestedData[nested] = this.id;
                }
                if (array && Array.isArray(nestedData))
                    value = nestedData.map((v: any) => this.makeObject(v, type));
                else if (array)
                    value = nestedData ? [this.makeObject(nestedData, type)] : [];
                else if (
                    type !== ObjectReference &&
                    self[internal] &&
                    self[internal] instanceof ObjectReference &&
                    nestedData
                )
                    value = this.makeObject(nestedData, type);
                else if (
                    type !== ObjectReference &&
                    self[internal] &&
                    self[internal] instanceof APIObject &&
                    nestedData &&
                    self[internal].id == nestedData.id
                )
                    value = self[internal].update(nestedData);
                else if (type == ObjectReference && nestedData instanceof APIObject)
                    value = nestedData;
                else if (nestedData !== null) value = this.makeObject(nestedData, type);
            } else if (array && data[key] && !Array.isArray(data[key]))
                value = [data[key]];
            else value = data[key];
            Object.assign(this, { [internal]: value });
            return true;
        }
        return false;
    }
    protected optional(key: string): boolean {
        const optional = this._optional.indexOf(key) !== -1;
        const readOnly = this._readOnly.indexOf(key) !== -1;
        return optional || readOnly;
    }

    static checkDiff(value: any, otherValue: any): any {
        if (!!value && !!otherValue) {
            if (
                value instanceof APIObject &&
                otherValue instanceof APIObject &&
                value.id !== otherValue.id
            )
                return APIObject.serializeValue(otherValue);
            else if (otherValue instanceof APIObject && otherValue.id)
                return APIObject.serializeValue(otherValue);
            else if (value instanceof APIObject) {
                let valueDiff = value.diff(otherValue);
                if (valueDiff) {
                    if (otherValue instanceof ObjectReference)
                        valueDiff = APIObject.serializeValue(otherValue);
                    else if (otherValue.hasOwnProperty("id"))
                        valueDiff["id"] = otherValue.id;
                    return valueDiff;
                }
            } else if (Array.isArray(value) && Array.isArray(otherValue)) {
                if (!APIObject.arrayEquals(value, otherValue, true))
                    return APIObject.serializeValue(otherValue);
            } else if (value !== otherValue)
                return APIObject.serializeValue(otherValue);
        } else if (value != otherValue) return APIObject.serializeValue(otherValue);
        return undefined;
    }
    static diff_(me: any, other: any, readOnly: string[] = []): any {
        const diff: any = {};
        for (const key in me) {
            if (me.hasOwnProperty(key)) {
                if (key.startsWith("_") || readOnly.indexOf(key) !== -1) continue;
                let value = me[key];
                let otherValue = other.hasOwnProperty(key) ? other[key] : undefined;
                const diffValue = APIObject.checkDiff(value, otherValue);
                if (diffValue !== undefined) diff[key] = diffValue;
            }
        }
        for (const key in other) {
            if (other.hasOwnProperty(key) && !this.hasOwnProperty(key)) {
                if (key.startsWith("_") || readOnly.indexOf(key) !== -1) continue;
                if (other[key]) diff[key] = APIObject.serializeValue(other[key]);
            }
        }
        return Object.keys(diff).length > 0 ? diff : undefined;
    }
    static serializeValue(
        value: any,
        nested: string[] = [],
        prefix: string = "",
        isReference: boolean = false,
    ): any {
        if (value instanceof APIObject) {
            const nestedKeys = nested
                .filter((n: string) => n.startsWith(prefix))
                .map((n: string) => n.replace(prefix, ""));
            if (isReference) value = value.asReference;
            return value.serialize(nestedKeys);
        } else if (Array.isArray(value))
            return value.map((v: any) => APIObject.serializeValue(v, nested, prefix));
        return value;
    }
    static compareObjects(a: any, b: any): boolean {
        if (a && b) {
            if (a instanceof APIObject && b instanceof APIObject) return a.id === b.id;
            if (a instanceof APIObject)
                return a.id === b || (b.hasOwnProperty("id") && a.id === b.id);
            if (b instanceof APIObject)
                return a === b.id || (a.hasOwnProperty("id") && a.id === b.id);
        }
        return a === b;
    }
    static isEqual(a: any, b: any): boolean {
        if (a instanceof APIObject && b instanceof APIObject)
            return a.diff(b) !== undefined;
        if (!!a && !!b && Array.isArray(a) && Array.isArray(b))
            return APIObject.arrayEquals(a, b, true);
        if (!!a && !!b && typeof a == "object" && typeof b == "object") {
            const diff = APIObject.diff_(a, b, a.readOnly);
            return !diff;
        }
        return APIObject.compareObjects(a, b);
    }
    static arrayEquals(a: any[], b: any[], useDiff: boolean = false): boolean {
        if (!a && !b) return true;
        if (!Array.isArray(a) || !Array.isArray(b)) return APIObject.isEqual(a, b);
        if (a.length !== b.length) return false;
        let orderDifferent = false;
        if (
            a.length &&
            b.length &&
            a[0].hasOwnProperty("order") &&
            b[0].hasOwnProperty("order")
        ) {
            let aCopy = [...a];
            let bCopy = [...b];

            aCopy.sort((x, y) => (x.order || 0) - (y.order || 0));
            bCopy.sort((x, y) => (x.order || 0) - (y.order || 0));
            orderDifferent = aCopy.every(
                (val, index) => val.order === bCopy[index].order,
            );
        }

        const change = a.filter(
            (x: any) =>
                !b.find((y: any) =>
                    useDiff ? APIObject.isEqual(x, y) : APIObject.compareObjects(x, y),
                ),
        );
        return change.length === 0 && !orderDifferent;
    }
}

export class NamedObject extends APIObject {
    name: string | undefined;

    get displayName(): string | undefined {
        return this.name;
    }

    initialize(data?: any, patch: boolean = false): void {
        super.initialize(data, patch);

        // don't overwrite the "name" field for DataForm objects
        // that are not references (MED-2904)
        if (
            !(
                this.displayName &&
                data.name === this.displayName &&
                data.type === "program.dataform" &&
                !data.is_reference
            )
        ) {
            this.setMember(data, patch, "name");
        }
    }
}

export class ObjectReference extends NamedObject {
    static object_type: string = "object.reference";

    initialize(data?: any, patch: boolean = false): void {
        super.initialize(data, patch);
        if (!this.name && data instanceof APIObject) this.name = data.displayName;
        this.is_reference = true;
    }

    get asReference(): ObjectReference /* NOSONAR */ {
        return this;
    }
}

export class ProgramReference extends ObjectReference {
    static object_type: string = "program.program";
    deleted?: boolean;
    initialize(data: any, patch: boolean = false): void {
        super.initialize(data, patch);

        this.setMember(data, patch, "deleted");
    }
}

export type ObjectMap = { [key: string]: APIObject };
export type ObjectOrReference<T extends APIObject> = T | ObjectReference;
export type OptionalObjectOrReference<T extends APIObject> =
    | T
    | ObjectReference
    | undefined;

export function objectsOnly<T extends APIObject>(
    arr: ObjectOrReference<T>[] | undefined,
    type: Type<T>,
): T[] {
    return (arr ?? [])
        .filter((item: ObjectOrReference<T>) => item instanceof type)
        .map((item: ObjectOrReference<T>) => item as T);
}
export function referencesOnly<T extends APIObject>(arr?: ObjectOrReference<T>[]): T[] {
    return (arr ?? [])
        .filter((item: ObjectOrReference<T>) => item instanceof ObjectReference)
        .map((item: ObjectOrReference<T>) => item as T);
}

// Base class that implements object caching
export abstract class ObjectFactory<T extends APIObject> {
    static objectFactory: ObjectFactoryRegistry = {};
    static registerObjectFactory(service: ObjectFactory<any>): void {
        ObjectFactory.objectFactory[service.object_type] = {
            type: service.type,
            factory: service,
        };
    }
    static getObjectFactory<T extends APIObject>(
        object_type?: string,
    ): ObjectFactory<T> | undefined {
        return object_type ?
                ObjectFactory.objectFactory[object_type]?.factory
            :   undefined;
    }
    static getObjectFactoryFromType<T extends APIObject>(
        type: Type<T>,
    ): ObjectFactory<T> | undefined {
        const object_type = new type().type;
        return ObjectFactory.getObjectFactory(object_type);
    }
    static makeObject<T extends APIObject>(
        data: any,
        object_type?: string,
    ): ObjectOrReference<T> {
        object_type = object_type ?? (data ? data["type"] : undefined); // if it's not explicitly specified, try to pull it from the data
        const factory = ObjectFactory.getObjectFactory<T>(object_type);
        if (factory === undefined)
            throw new FactoryNotFoundError(
                "Factory for " + object_type + " not found.",
            );
        return factory.makeObject(data);
    }

    protected _cache: CachedObjects<T>;
    protected _object_type: string;

    objectUpdated: EventEmitter<ObjectChangeEvent<T>> = new EventEmitter<
        ObjectChangeEvent<T>
    >(true);
    objectCreated: EventEmitter<ObjectChangeEvent<T>> = new EventEmitter<
        ObjectChangeEvent<T>
    >(true);
    objectDeleted: EventEmitter<ObjectChangeEvent<T>> = new EventEmitter<
        ObjectChangeEvent<T>
    >(true);

    nested: string[] = [];

    constructor(readonly type: Type<T>) {
        this._cache = {};

        this._object_type = this.makeObject({}).type;
        ObjectFactory.registerObjectFactory(this); // automatically register this service as an object factory
    }

    get object_type(): string {
        return this._object_type;
    }

    retrieve(
        id: string,
        filters?: RequestFilter,
        force?: boolean,
    ): Observable<T | undefined> {
        return of(undefined);
    }

    protected cachedObject(id?: string): OptionalObjectOrReference<T> {
        return id ? this._cache[id] : undefined;
    }
    getReferenceType(): typeof ObjectReference {
        return ObjectReference;
    }
    protected makeObject(
        data: any,
        created: boolean = false,
        matchedFilters?: number[],
    ): ObjectOrReference<T> {
        let object = undefined;
        let cached = this.cachedObject(data?.id);

        if (cached && !(cached instanceof ObjectReference)) {
            object = cached;
            // Only update the object if it's not a reference
            if (!data.is_reference) {
                const change_set = object.diff(data);
                if (change_set != undefined) {
                    object = object.update(data);
                }
            }
        } else {
            if (data?.hasOwnProperty("is_reference") && data?.is_reference) {
                const ReferenceType = this.getReferenceType();
                object = new ReferenceType(data);
            } else object = new this.type(data);
            if (
                cached instanceof ObjectReference &&
                object instanceof ObjectReference
            ) {
                object = cached; // don't update a reference with a different reference
            }
            if (object.id) {
                if (this._cache[object.id]) cached = this._cache[object.id];
                if (cached != object) {
                    cached?.unsubscribeFromReferenceUpdates();
                    object.subscribeToReferenceUpdates();
                }
                this._cache[object.id] = object;
                object.is_cached = true;
            }
        }
        if (created) {
            this.objectCreated.emit({
                object: object,
                matched_filters: matchedFilters,
            });
        }
        if (
            cached &&
            cached instanceof ObjectReference &&
            !(object instanceof ObjectReference)
        ) {
            this.objectUpdated.emit({
                object: cached,
                matched_filters: matchedFilters,
                replace: object,
            });
        }
        return object;
    }
    protected updateObject(
        object: ObjectOrReference<T>,
        data: any,
        matchedFilters?: number[],
    ): ObjectOrReference<T> {
        if (object instanceof ObjectReference && !data?.is_reference)
            return this.makeObject(data, false, matchedFilters);

        // There are cases where the returned object is not the same as the previous object
        // Check for this case, and if necessary, create a new object instead of updating the existing one
        if (object.id != data.id || object.type != data.type) {
            return ObjectFactory.makeObject(data);
        }

        const change_set = object?.diff(data);
        if (object && change_set != undefined) {
            object = object.update(data);
            const event: ObjectChangeEvent<T> = {
                object: object,
                change_set: change_set,
                matched_filters: matchedFilters,
            };
            this.objectUpdated.emit(event);
        }
        return object;
    }
    resolveReference(
        objectOrReference: OptionalObjectOrReference<T>,
    ): Observable<T | undefined> {
        return ObjectFactory.objectObservable<T>(objectOrReference);
    }
    static objectObservable<U extends APIObject>(
        objectOrReference: OptionalObjectOrReference<U>,
        force: boolean = false,
    ): Observable<U | undefined> {
        if (objectOrReference) {
            if (objectOrReference instanceof ObjectReference) {
                if (objectOrReference.id) {
                    const factory = ObjectFactory.getObjectFactory<U>(
                        objectOrReference.type,
                    );
                    if (factory && factory instanceof ObjectFactory)
                        return factory.retrieve(objectOrReference.id, undefined, force);
                }
                return of(undefined);
            }
        }
        return of(objectOrReference);
    }
}
