<h3 mat-dialog-title>Add Program Administrator</h3>
<form class="wrapper flex stack full" novalidate (ngSubmit)="onSave()">
    <mat-dialog-content>
        <mat-form-field class="full">
            <mat-label>Account</mat-label>
            <mat-select
                [formControl]="accountControl"
                placeholder="Select a Staff Member"
            >
                <ng-container *ngIf="available.length">
                    <mat-option
                        *ngFor="let account of available"
                        [value]="account"
                        [disabled]="isProgramAdmin(account)"
                    >
                        {{ account.displayName }}
                    </mat-option>
                </ng-container>
                <ng-container *ngIf="!available.length">
                    <mat-option disabled>No Staff Members Found</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="spacer"></div>
        <mat-spinner *ngIf="loading" mode="indeterminate" [diameter]="50"></mat-spinner>
        <button mat-dialog-close mat-button>Cancel</button>
        <button
            *ngIf="!loading"
            mat-flat-button
            type="button"
            class="fixed"
            color="accent"
            [disabled]="!accountControl.valid"
            (click)="accountControl.valid && onSave()"
        >
            Add
        </button>
    </mat-dialog-actions>
</form>
