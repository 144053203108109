import { HttpHeaders } from "@angular/common/http";

export type RequestMethod = "get" | "post" | "put" | "patch" | "delete";
export type RequestFilter = { [key: string]: string };
export type RequestOptions = {
    headers?: HttpHeaders;
    body?: any;
    observe?: string;
    responseType?: string;
};

export enum WebsocketMessageType {
    OBJECT = "object", // send when an object changes on the server
    MESSAGE = "message", // generic message
    REQUEST = "request", // a message that expects a response
    RESPONSE = "response",
    SUBSCRIBE = "subscribe",
    UNSUBSCRIBE = "unsubscribe",
    ERROR = "error",
}
export interface WebsocketMessage {
    type: WebsocketMessageType;
    sequence?: number;
    token?: string;
    data?: any;
}
export interface WebsocketRequest extends WebsocketMessage {
    action: string; // in the form <service>:<action> for non-REST requests or REST.<method>:<endpoint> for REST requests
    filters?: RequestFilter;
}
export interface WebsocketResponse extends WebsocketMessage {
    status?: number; // for REST responses
    errors?: any;
}

export enum WebsocketObjectAction {
    CREATE = "create",
    RETRIEVE = "retrieve",
    UPDATE = "update",
    PATCH = "patch",
    DELETE = "delete",
    LIST = "list",
    RELATED = "related", // for cascading related notifications to parent objects
}
export interface WebsocketObjectData {
    type: string;
    id: string;
}
export interface WebsocketObjectMessage extends WebsocketMessage {
    action: string; // when receiving an object message this field does not follow the <service>:<action> pattern, it is just the action
    object?: WebsocketObjectData; // the object reference to the object that the message refers - this is distinct from the message data
    matched_filters?: number[]; // the filter indexes of any filter subscriptions that the update matches
}

export function queryStringFromFilters(filters?: RequestFilter): string {
    let queryString = "";
    if (filters) {
        let first = true;
        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                const value = filters[key];
                queryString += first ? "?" : "&";
                queryString += key;
                if (value === null) queryString += "=0";
                else if (value || value !== "")
                    queryString += "=" + encodeURIComponent(value);
                first = false;
            }
        }
    }
    return queryString;
}

export function formDataFromObject(data: any): string {
    const requestBody = [];
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            requestBody.push(
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
            );
        }
    }
    return requestBody.join("&");
}
