import { Injector, NgModule } from "@angular/core";
import {
    CommonModule as AngularCommonModule,
    DatePipe,
    registerLocaleData,
} from "@angular/common";
import localeEnGB from "@angular/common/locales/en-GB";
import localeFr from "@angular/common/locales/fr";
import { MatPaginatorModule } from "@angular/material/paginator";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MomentDateModule } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";

import { AngularSplitModule } from "angular-split";
import { FileUploadModule } from "ng2-file-upload";
import { QuillModule } from "ngx-quill";

import { AuditTrailComponent } from "src/common/components/audit-trail/audit-trail.component";
import { ObjectAutocompleteSearchComponent } from "src/common/components/autocomplete-search/autocomplete-search.component";
import { BreadcrumbComponent } from "src/common/components/breadcrumbs/breadcrumb.component";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { ContentStack } from "src/common/components/content-stack.component";
import { DataAdminComponent } from "src/common/components/data-admin/data-admin.component";
import { DataFieldEditorComponent } from "src/common/components/data-admin/data-field/data-field.component";
import { DataFieldListComponent } from "src/common/components/data-admin/data-field/data-field-list.component";
import { DataFormEditorComponent } from "src/common/components/data-admin/data-form/data-form.component";
import { DataFormAdminComponent } from "src/common/components/data-admin/data-form/data-form-admin.component";
import { DataFormFieldEditorComponent } from "src/common/components/data-admin/data-form/data-form-field.component";
import { DataFormGroupEditorComponent } from "src/common/components/data-admin/data-form/data-form-group.component";
import { DataFormPropertiesComponent } from "src/common/components/data-admin/data-form/data-form-properties.component";
import { DataTypeEditorComponent } from "src/common/components/data-admin/data-type/data-type.component";
import { DataTypeAdminComponent } from "src/common/components/data-admin/data-type/data-type-admin.component";
import { DataFieldsComponent } from "src/common/components/data-fields/data-fields.component";
import { DataFilterComponent } from "src/common/components/data-filter/data-filter.component";
import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import { DataFormFieldComponent } from "src/common/components/data-form/data-form-field.component";
import { DataFormGroupComponent } from "src/common/components/data-form/data-form-group.component";
import { DataFormListComponent } from "src/common/components/data-form/data-form-list.component";
import { DataFormSelectComponent } from "src/common/components/data-form/data-form-select.component";
import { DataTypeComponent } from "src/common/components/data-form/data-type.component";
import { DataTypeCheckbox } from "src/common/components/data-form/data-types/data-type-checkbox.component";
import { DataTypeDate } from "src/common/components/data-form/data-types/data-type-date.component";
import { DataTypeCompound } from "src/common/components/data-form/data-types/data-type-compound.component";
import { DataTypeDocument } from "src/common/components/data-form/data-types/data-type-document.component";
import { DataTypeTextEmail } from "src/common/components/data-form/data-types/data-type-email.component";
import { DataTypeTextLong } from "src/common/components/data-form/data-types/data-type-long.component";
import { DataTypeLookup } from "src/common/components/data-form/data-types/data-type-lookup.component";
import {
    DataTypeMultiple,
    DataTypeMultipleDialog,
} from "src/common/components/data-form/data-types/data-type-multiple.component";
import { DataTypeProgram } from "src/common/components/data-form/data-types/data-type-program.component";
import { DataTypeRadio } from "src/common/components/data-form/data-types/data-type-radio.component";
import { DataTypeTextShort } from "src/common/components/data-form/data-types/data-type-short.component";
import { DataTypeToggle } from "src/common/components/data-form/data-types/data-type-toggle.component";
import { HealthCheckComponent } from "src/common/components/health-check.component";
import { ObjectFormComponent } from "src/common/components/object-form.component";
import { OrderingDirective } from "src/common/components/ordering.directive";
import { ReferenceWrapperDirective } from "src/common/components/reference-wrapper.directive";
import { RichTextEditorComponent } from "src/common/components/rich-text-editor/rich-text-editor.component";
import { ScrollingListComponent } from "src/common/components/scrolling-list.component";
import {
    DetailTabGroup,
    DetailTab,
    DetailTabLabel,
    DetailTabBody,
    DetailTabContent,
    DetailTabBodyPortal,
} from "src/common/components/tab-detail/tab-detail.component";
import {
    TabContainer,
    TabFrameComponent,
} from "src/common/components/tab-frame/tab-frame.component";
import { ConfirmRecipientsDialog } from "src/common/components/template/ConfirmBulkRecipients";
import { TemplateComponent } from "src/common/components/template/template.component";
import { TemplateListComponent } from "src/common/components/template/template-list.component";
import { SendTemplateDialog } from "src/common/components/template/send-template.dialog";
import { ResizedDirective } from "src/common/directives/resize.directive";
import { DragDropService } from "src/common/utilities/drag-drop";
import { FileSizePipe } from "src/common/utilities/file-size.pipe";
import { setAppInjector } from "src/common/utilities/injector";
import { LocalizedDatePipe } from "src/common/utilities/localized-date.pipe";
import { NoCommaPipe } from "src/common/utilities/no-comma.pipe";
import { BdcWalkModule } from "third-party/bdc-walkthrough/src/public-api";
import { RelativeDatePipe } from "./utilities/relative-date.pipe";

@NgModule({
    declarations: [
        AuditTrailComponent,
        BreadcrumbComponent,
        ConfirmDialog,
        ConfirmRecipientsDialog,
        ContentStack,
        DataAdminComponent,
        DataFieldEditorComponent,
        DataFieldListComponent,
        DataFieldsComponent,
        DataFilterComponent,
        DataFormAdminComponent,
        DataFormComponent,
        DataFormEditorComponent,
        DataFormFieldComponent,
        DataFormFieldEditorComponent,
        DataFormGroupComponent,
        DataFormGroupEditorComponent,
        DataFormListComponent,
        DataFormPropertiesComponent,
        DataFormSelectComponent,
        DataTypeAdminComponent,
        DataTypeCheckbox,
        DataTypeComponent,
        DataTypeCompound,
        DataTypeDate,
        DataTypeDocument,
        DataTypeEditorComponent,
        DataTypeLookup,
        DataTypeMultiple,
        DataTypeMultipleDialog,
        DataTypeProgram,
        DataTypeRadio,
        DataTypeTextEmail,
        DataTypeTextLong,
        DataTypeTextShort,
        DataTypeToggle,
        DetailTab,
        DetailTabBody,
        DetailTabBodyPortal,
        DetailTabContent,
        DetailTabGroup,
        DetailTabLabel,
        FileSizePipe,
        HealthCheckComponent,
        LocalizedDatePipe,
        NoCommaPipe,
        ObjectAutocompleteSearchComponent,
        ObjectFormComponent,
        OrderingDirective,
        ReferenceWrapperDirective,
        ResizedDirective,
        RichTextEditorComponent,
        ScrollingListComponent,
        SendTemplateDialog,
        TabContainer,
        TabFrameComponent,
        TemplateComponent,
        TemplateListComponent,
        RelativeDatePipe,
    ],
    imports: [
        AngularCommonModule,
        AngularSplitModule,
        BdcWalkModule,
        DragDropModule,
        FileUploadModule,
        FontAwesomeModule,
        FormsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        MomentDateModule,
        PortalModule,
        QuillModule.forRoot(),
        ReactiveFormsModule,
        ScrollingModule,
        TranslateModule.forRoot(),
        MatPaginatorModule,
    ],
    exports: [
        AuditTrailComponent,
        BreadcrumbComponent,
        ConfirmDialog,
        ConfirmRecipientsDialog,
        ContentStack,
        DataAdminComponent,
        DataFieldEditorComponent,
        DataFieldListComponent,
        DataFieldsComponent,
        DataFilterComponent,
        DataFormAdminComponent,
        DataFormComponent,
        DataFormEditorComponent,
        DataFormFieldComponent,
        DataFormFieldEditorComponent,
        DataFormGroupEditorComponent,
        DataFormListComponent,
        DataFormPropertiesComponent,
        DataTypeAdminComponent,
        DataTypeEditorComponent,
        DetailTab,
        DetailTabBodyPortal,
        DetailTabContent,
        DetailTabGroup,
        DetailTabLabel,
        FileSizePipe,
        HealthCheckComponent,
        LocalizedDatePipe,
        NoCommaPipe,
        ObjectFormComponent,
        OrderingDirective,
        ResizedDirective,
        ReferenceWrapperDirective,
        RichTextEditorComponent,
        ScrollingListComponent,
        SendTemplateDialog,
        TabContainer,
        TabFrameComponent,
        TemplateComponent,
        TemplateListComponent,
        TranslateModule,
        RelativeDatePipe,
    ],
    providers: [DatePipe, DragDropService],
})
export class CommonModule {
    constructor(injector: Injector) {
        registerLocaleData(localeEnGB, "en-GB");
        registerLocaleData(localeFr, "fr-FR");
        setAppInjector(injector);
    }
}
