<object-form [controller]="controller" [formGroup]="formGroup">
    <div class="wrapper flex stack">
        <ng-container *ngIf="mode != ObjectViewMode.View">
            <mat-form-field class="flexible">
                <mat-label>Display Name</mat-label>
                <textarea
                    matInput
                    formControlName="display_name"
                    [cdkTextareaAutosize]="true"
                    [cdkAutosizeMinRows]="1"
                    [cdkAutosizeMaxRows]="12"
                ></textarea>
            </mat-form-field>
            <mat-form-field class="flexible">
                <mat-label>Identifier</mat-label>
                <input matInput formControlName="name" />
                <mat-error *ngIf="!!identifierControl?.getError('conflict')">
                    This identifier must be unique among data fields within this
                    {{ repositoryType }}.
                </mat-error>
                <mat-icon
                    matSuffix
                    *ngIf="!!willOverride.length"
                    class="amber"
                    matTooltip="This identifier conflicts with other available data fields and may override default behavior.  Please ensure that this behavior is intended or change the value."
                >
                    warning
                </mat-icon>
            </mat-form-field>
            <autocomplete-search
                class="flexible"
                label="Data Type"
                [formControl]="dataTypeControl"
                [options]="getDataTypeObs()"
                placeholder="Search Data Types"
            ></autocomplete-search>
            <mat-form-field class="flexible">
                <mat-label>Description (Optional)</mat-label>
                <textarea
                    matInput
                    formControlName="description"
                    [cdkTextareaAutosize]="true"
                    [cdkAutosizeMinRows]="3"
                    [cdkAutosizeMaxRows]="12"
                ></textarea>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="mode == ObjectViewMode.View">
            <div class="row flex flexible">
                <div class="column flex flexible">
                    <div class="info field-header">Name</div>
                    <div class="flex stack">
                        <div>{{ fullObject?.display_name }}</div>
                        <div class="secondary small">{{ fullObject?.name }}</div>
                    </div>
                </div>
            </div>
            <div class="row flex flexible">
                <div class="column flex flexible">
                    <div class="info field-header">Data Type</div>
                    <div class="flex stack">
                        <div>{{ fullObject?.data_type?.displayName }}</div>
                        <div class="secondary small">
                            {{ fullObject?.data_type?.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex flexible">
                <div class="column flex flexible">
                    <div class="info field-header">Description</div>
                    <div *ngIf="fullObject?.description">
                        {{ fullObject?.description }}
                    </div>
                    <div *ngIf="!fullObject?.description" class="secondary">
                        Not Specified
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</object-form>
