import { Inquiry } from "./../../../services/models/inquiry";
import { DatePipe } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { ObjectComponent } from "src/common/components/object.component";
import {
    CompoundDataTypeFactory,
    DataFieldValueService,
    DataFormService,
} from "src/services/data.services";
import { APIListResult } from "src/services/models/api-object";
import { Case } from "src/services/models/case";
import { DataForm } from "src/services/models/data";
import { CaseService } from "src/services/program.services";

@Component({
    templateUrl: "./case-summary.component.html",
    selector: "case-summary",
    styleUrls: ["./case.component.scss"],
})
export class CaseSummary extends ObjectComponent<DataForm> {
    @Input() showExportButton = true;
    private _repository?: Case | Inquiry;
    @Input() set repository(c: Case | Inquiry | undefined) {
        this._repository = c;
        this.updateForms();
    }
    get repository(): Case | Inquiry | undefined {
        return this._repository;
    }

    dataForms?: DataForm[];
    datePipe: DatePipe;
    caseService: CaseService;

    constructor(service: DataFormService) /* NOSONAR */ {
        super(service);
        inject(DataFieldValueService);
        inject(CompoundDataTypeFactory);
        this.datePipe = inject(DatePipe);
        this.caseService = inject(CaseService);
    }

    updateForms(): void {
        let owned = this.repository?.id ?? "0";
        if (this.repository instanceof Case) owned += "," + this.repository?.shared.id;
        this.api
            .list({
                owned: owned,
                is_template: "False",
                version: "1",
            })
            .subscribe(
                (dataForms: APIListResult<DataForm>) =>
                    (this.dataForms = dataForms as DataForm[]),
            );
    }
    exportAsPdf(event: MouseEvent) {
        this.terminateEvent(event);
        if (!this.dataForms || !this.repository || !this.showExportButton) return;

        const date = this.datePipe
            .transform(new Date(), "mediumDate")
            ?.replaceAll(", ", "_")
            .replaceAll(" ", "_");

        const ids = this.dataForms.map((df: DataForm) => df.id!);
        const reference = this.repository.reference_identifier;
        const caseName = this.repository.displayName?.replaceAll(" ", "_") ?? "unnamed";
        const fileName = `${reference}_${caseName}_Summary_Forms_${date}.pdf`;

        (this.api as DataFormService).exportAsPdf(
            fileName,
            ids,
            `Case ${reference} Summary`,
        );
    }
    exportFullCase(event: MouseEvent): void {
        this.terminateEvent(event);
        if (
            !this.repository ||
            !this.showExportButton ||
            !(this.repository instanceof Case)
        )
            return;

        const date = this.datePipe
            .transform(new Date(), "mediumDate")
            ?.replaceAll(", ", "_")
            .replaceAll(" ", "_");

        const reference = this.repository.reference_identifier;
        const caseName = this.repository.displayName?.replaceAll(" ", "_") ?? "unnamed";
        const fileName = `${reference}_${caseName}_${date}.zip`;

        this.caseService.export(this.repository, fileName);
    }
    export(event: MouseEvent): void {
        if (this.repository instanceof Case) this.exportFullCase(event);
        else if (this.repository) this.exportAsPdf(event);
        else this.terminateEvent(event);
    }
}
