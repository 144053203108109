<div class="wrapper absolute flex stack">
    <div class="settings-overview-wrapper">
        <div class="settings-overview flex mat-elevation-z4">
            <div class="header-panel flex stack"></div>
            <div class="header-panel flex stack organization-name">
                <div class="flexible" *ngIf="organizations.length > 1">
                    <mat-form-field class="organization-select">
                        <mat-select [formControl]="currentOrganizationControl">
                            <mat-option *ngFor="let org of organizations" [value]="org">
                                {{ org.displayName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    Settings
                </div>
                <div *ngIf="organizations.length == 1">
                    {{ organizations[0].displayName }} Settings
                </div>
            </div>
            <div class="header-panel flex stack"></div>
        </div>
    </div>
    <div class="wrapper flexible flex stack organization-view" cdkScrollable>
        <mat-spinner
            *ngIf="!dashboardRoleDefined"
            style="margin: 0 auto"
            mode="indeterminate"
            [diameter]="50"
        ></mat-spinner>
        <organization
            *ngIf="dashboardRoleDefined"
            [object]="fullObject"
            [isHcpStaff]="isHcpStaff"
        ></organization>
    </div>
</div>
