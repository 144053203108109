import { SearchableListComponent } from "./../../../common/components/searchable-list.component";
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { mergeMap } from "rxjs/operators";
import { ObjectViewMode } from "src/common/components/object.component";
import { Organization } from "src/services/models/organization";
import { Program } from "../../../services/models/program";
import { ProgramService } from "../../../services/program.services";
import { ProgramComponent } from "./program.component";
import { of } from "rxjs";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "program-list",
    templateUrl: "./program-list.component.html",
    styleUrls: ["./program.component.scss"],
})
export class ProgramListComponent extends SearchableListComponent<Program> {
    objectView = ProgramComponent;

    tabSubtitle(object: Program): string | undefined {
        return object.organization.displayName + " Program";
    }

    protected _organization?: Organization;
    @Input() set organization(v: Organization | undefined) {
        this._organization = v;
        this.list.refresh();
    }
    get organization(): Organization | undefined {
        return this._organization;
    }

    constructor(
        protected service: ProgramService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "program-list");
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }
    get isOrganizationAdministrator(): boolean {
        return (
            !!this.currentAccount?.hasRole("object.admin", this.organization) ||
            !!this.currentAccount?.isSystemAdministrator ||
            false
        );
    }

    get displayedColumns(): string[] {
        return ["name", "deleted", "actions"];
    }
    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["organization"] = this._organization?.id ? this._organization.id : "0";
        filters["use_reference"] = "True";
        return filters;
    }
    createProgram(event: MouseEvent) {
        const programComponent = this.createObject(event);
        programComponent?.dialogReference?.updateSize(undefined, "78%"); // dialog needs a set height in order for overflow:scroll to work
    }
    newObject(data?: any): Program | undefined {
        data = {
            ...data,
            organization: this.organization?.asReference,
        };
        return super.newObject(data);
    }

    disableObject(
        event: MouseEvent,
        object: Program,
        message?: string | undefined,
    ): void {
        super.disableObject(
            event,
            object,
            "Are you sure you want to close '" + object.displayName + "'?",
        );
    }

    reopenProgram(_e: Event, p: Program) {
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        "Are you sure you want to re-open '" + p.displayName + "'?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) => {
                    if (confirm) {
                        p.deleted = false;
                        return this.service.update(p);
                    }
                    return of(null);
                }),
            )
            .subscribe((prog) => {
                if (prog) {
                    p.update({ ...prog });
                }
            });
    }

    protected objectDialogConfiguration(
        object: Program,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        if (mode != ObjectViewMode.Create) {
            return {
                ...config,
                width: "90%",
                minHeight: "75%",
            };
        }
        return config;
    }
}
