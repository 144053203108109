import {
    CdkDragMove,
    CdkDragRelease,
    CdkDragEnter,
    CdkDragExit,
    CdkDropList,
} from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

type DropListOptions = {
    list: CdkDropList;
    options: any;
};

@Injectable()
export class DragDropService {
    get dropLists(): CdkDropList[] {
        const options = Object.values(this._dropLists);
        return options.map((dlo: DropListOptions) => dlo.list);
    }

    _dropLists: { [id: string]: DropListOptions } = {};
    currentHoverDropListId?: string;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    register(dropList: CdkDropList, options: any = {}): void {
        this._dropLists[dropList.id] = { list: dropList, options: options };
    }
    dragEntered(event: CdkDragEnter): void {
        this.currentHoverDropListId = event.container.id;
    }
    dragExited(event: CdkDragExit): void {
        this.currentHoverDropListId = undefined;
    }
    dragMoved(event: CdkDragMove): void {
        const elementFromPoint = this.document.elementFromPoint(
            event.pointerPosition.x,
            event.pointerPosition.y,
        );
        const dropList =
            elementFromPoint?.classList.contains("cdk-drop-list") ?
                elementFromPoint
            :   elementFromPoint?.closest(".cdk-drop-list");
        this.currentHoverDropListId = dropList?.id;
    }
    dragReleased(event: CdkDragRelease): void {
        this.currentHoverDropListId = undefined;
    }
}
