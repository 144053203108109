<div class="filters-root content-card" [formGroup]="filterFormGroup">
    <h3>Filter {{ filterTitle }}</h3>

    <div class="filters-container">
        <ng-container *ngFor="let filterDef of filterDefinitions">
            <div class="filter-card">
                <mat-form-field color="primary">
                    <mat-icon matPrefix class="search-icon">filter_list</mat-icon>
                    <mat-label>&nbsp;&nbsp;{{ filterDef.displayName }}</mat-label>
                    <mat-select
                        [formControlName]="filterDef.controlName"
                        [placeholder]="
                            filterDef.placeholder ? filterDef.placeholder : ''
                        "
                        multiple
                    >
                        <mat-optgroup label="{{ filterDef.displayName }}">
                            <mat-option
                                *ngFor="let option of getOptions(filterDef.controlName)"
                                [value]="getOptionValue(option, filterDef.controlName)"
                                [disabled]="
                                    isOptionDisabled ? isOptionDisabled(option) : false
                                "
                            >
                                {{ getOptionDisplay(option) }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
    <ng-container *ngFor="let config of dateFilterConfigs; let i = index">
        <div class="filters-container">
            <div class="filter-card">
                <mat-form-field color="primary">
                    <mat-label>
                        {{ config.betweenDateLabel || "Assigned Between" }}
                    </mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input
                            matStartDate
                            placeholder="Start Date"
                            [formControlName]="config.betweenStartDateControlName"
                        />
                        <input
                            matEndDate
                            placeholder="End Date"
                            [formControlName]="config.betweenEndDateControlName"
                        />
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY - MM/DD/YYY</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <div class="filter-card">
                <mat-form-field color="primary">
                    <mat-label>
                        {{ config.dateBeforeLabel || "Assigned Before" }}
                    </mat-label>
                    <input
                        matInput
                        [matDatepicker]="beforePicker"
                        [formControlName]="config.dateBeforeControlName"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="beforePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #beforePicker="matDatepicker"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="filter-card">
                <mat-form-field color="primary">
                    <mat-label>
                        {{ config.dateAfterLabel || "Assigned After" }}
                    </mat-label>
                    <input
                        matInput
                        [matDatepicker]="afterPicker"
                        [formControlName]="config.dateAfterControlName"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="afterPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #afterPicker="matDatepicker"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </ng-container>

    <div class="filter-buttons-container">
        <button
            mat-flat-button
            class="brand-medium"
            matTooltip="Cancel"
            (click)="onCancel($event)"
        >
            Cancel
        </button>
        <button
            mat-flat-button
            class="brand-medium"
            matTooltip="Reset filters to default"
            (click)="onReset($event)"
        >
            Reset
        </button>
        <button
            mat-raised-button
            color="accent"
            matTooltip="Clear All"
            (click)="onClearAll($event)"
        >
            Clear All
        </button>
    </div>
</div>
