export type Country = { value: string; display_name: string };
export type State = { name: string; abbreviation: string };

export const Countries: Country[] = [
    { value: "United States", display_name: "United States" },
    { value: "Canada", display_name: "Canada" },
    { value: "Afghanistan", display_name: "Afghanistan" },
    { value: "Albania", display_name: "Albania" },
    { value: "Algeria", display_name: "Algeria" },
    { value: "American Samoa", display_name: "American Samoa" },
    { value: "Andorra", display_name: "Andorra" },
    { value: "Angola", display_name: "Angola" },
    { value: "Anguilla", display_name: "Anguilla" },
    { value: "Antarctica", display_name: "Antarctica" },
    { value: "Antigua and Barbuda", display_name: "Antigua and Barbuda" },
    { value: "Argentina", display_name: "Argentina" },
    { value: "Armenia", display_name: "Armenia" },
    { value: "Aruba", display_name: "Aruba" },
    { value: "Australia", display_name: "Australia" },
    { value: "Austria", display_name: "Austria" },
    { value: "Azerbaijan", display_name: "Azerbaijan" },
    { value: "Bahamas (the)", display_name: "Bahamas (the)" },
    { value: "Bahrain", display_name: "Bahrain" },
    { value: "Bangladesh", display_name: "Bangladesh" },
    { value: "Barbados", display_name: "Barbados" },
    { value: "Belarus", display_name: "Belarus" },
    { value: "Belgium", display_name: "Belgium" },
    { value: "Belize", display_name: "Belize" },
    { value: "Benin", display_name: "Benin" },
    { value: "Bermuda", display_name: "Bermuda" },
    { value: "Bhutan", display_name: "Bhutan" },
    {
        value: "Bolivia (Plurinational State of)",
        display_name: "Bolivia (Plurinational State of)",
    },
    {
        value: "Bonaire, Sint Eustatius and Saba",
        display_name: "Bonaire, Sint Eustatius and Saba",
    },
    { value: "Bosnia and Herzegovina", display_name: "Bosnia and Herzegovina" },
    { value: "Botswana", display_name: "Botswana" },
    { value: "Bouvet Island", display_name: "Bouvet Island" },
    { value: "Brazil", display_name: "Brazil" },
    {
        value: "British Indian Ocean Territory (the)",
        display_name: "British Indian Ocean Territory (the)",
    },
    { value: "Brunei Darussalam", display_name: "Brunei Darussalam" },
    { value: "Bulgaria", display_name: "Bulgaria" },
    { value: "Burkina Faso", display_name: "Burkina Faso" },
    { value: "Burundi", display_name: "Burundi" },
    { value: "Cabo Verde", display_name: "Cabo Verde" },
    { value: "Cambodia", display_name: "Cambodia" },
    { value: "Cameroon", display_name: "Cameroon" },
    { value: "Cayman Islands (the)", display_name: "Cayman Islands (the)" },
    {
        value: "Central African Republic (the)",
        display_name: "Central African Republic (the)",
    },
    { value: "Chad", display_name: "Chad" },
    { value: "Chile", display_name: "Chile" },
    { value: "China", display_name: "China" },
    { value: "Christmas Island", display_name: "Christmas Island" },
    {
        value: "Cocos (Keeling) Islands (the)",
        display_name: "Cocos (Keeling) Islands (the)",
    },
    { value: "Colombia", display_name: "Colombia" },
    { value: "Comoros (the)", display_name: "Comoros (the)" },
    {
        value: "Congo (the Democratic Republic of the)",
        display_name: "Congo (the Democratic Republic of the)",
    },
    { value: "Congo (the)", display_name: "Congo (the)" },
    { value: "Cook Islands (the)", display_name: "Cook Islands (the)" },
    { value: "Costa Rica", display_name: "Costa Rica" },
    { value: "Croatia", display_name: "Croatia" },
    { value: "Cuba", display_name: "Cuba" },
    { value: "Cura\u00E7ao", display_name: "Cura\u00E7ao" },
    { value: "Cyprus", display_name: "Cyprus" },
    { value: "Czechia", display_name: "Czechia" },
    { value: "C\u00F4te d'Ivoire", display_name: "C\u00F4te d'Ivoire" },
    { value: "Denmark", display_name: "Denmark" },
    { value: "Djibouti", display_name: "Djibouti" },
    { value: "Dominica", display_name: "Dominica" },
    { value: "Dominican Republic (the)", display_name: "Dominican Republic (the)" },
    { value: "Ecuador", display_name: "Ecuador" },
    { value: "Egypt", display_name: "Egypt" },
    { value: "El Salvador", display_name: "El Salvador" },
    { value: "Equatorial Guinea", display_name: "Equatorial Guinea" },
    { value: "Eritrea", display_name: "Eritrea" },
    { value: "Estonia", display_name: "Estonia" },
    { value: "Eswatini", display_name: "Eswatini" },
    { value: "Ethiopia", display_name: "Ethiopia" },
    {
        value: "Falkland Islands (the) [Malvinas]",
        display_name: "Falkland Islands (the) [Malvinas]",
    },
    { value: "Faroe Islands (the)", display_name: "Faroe Islands (the)" },
    { value: "Fiji", display_name: "Fiji" },
    { value: "Finland", display_name: "Finland" },
    { value: "France", display_name: "France" },
    { value: "French Guiana", display_name: "French Guiana" },
    { value: "French Polynesia", display_name: "French Polynesia" },
    {
        value: "French Southern Territories (the)",
        display_name: "French Southern Territories (the)",
    },
    { value: "Gabon", display_name: "Gabon" },
    { value: "Gambia (the)", display_name: "Gambia (the)" },
    { value: "Georgia", display_name: "Georgia" },
    { value: "Germany", display_name: "Germany" },
    { value: "Ghana", display_name: "Ghana" },
    { value: "Gibraltar", display_name: "Gibraltar" },
    { value: "Greece", display_name: "Greece" },
    { value: "Greenland", display_name: "Greenland" },
    { value: "Grenada", display_name: "Grenada" },
    { value: "Guadeloupe", display_name: "Guadeloupe" },
    { value: "Guam", display_name: "Guam" },
    { value: "Guatemala", display_name: "Guatemala" },
    { value: "Guernsey", display_name: "Guernsey" },
    { value: "Guinea", display_name: "Guinea" },
    { value: "Guinea-Bissau", display_name: "Guinea-Bissau" },
    { value: "Guyana", display_name: "Guyana" },
    { value: "Haiti", display_name: "Haiti" },
    {
        value: "Heard Island and McDonald Islands",
        display_name: "Heard Island and McDonald Islands",
    },
    { value: "Holy See (the)", display_name: "Holy See (the)" },
    { value: "Honduras", display_name: "Honduras" },
    { value: "Hong Kong", display_name: "Hong Kong" },
    { value: "Hungary", display_name: "Hungary" },
    { value: "Iceland", display_name: "Iceland" },
    { value: "India", display_name: "India" },
    { value: "Indonesia", display_name: "Indonesia" },
    { value: "Iran (Islamic Republic of)", display_name: "Iran (Islamic Republic of)" },
    { value: "Iraq", display_name: "Iraq" },
    { value: "Ireland", display_name: "Ireland" },
    { value: "Isle of Man", display_name: "Isle of Man" },
    { value: "Israel", display_name: "Israel" },
    { value: "Italy", display_name: "Italy" },
    { value: "Jamaica", display_name: "Jamaica" },
    { value: "Japan", display_name: "Japan" },
    { value: "Jersey", display_name: "Jersey" },
    { value: "Jordan", display_name: "Jordan" },
    { value: "Kazakhstan", display_name: "Kazakhstan" },
    { value: "Kenya", display_name: "Kenya" },
    { value: "Kiribati", display_name: "Kiribati" },
    {
        value: "Korea (the Democratic People's Republic of)",
        display_name: "Korea (the Democratic People's Republic of)",
    },
    { value: "Korea (the Republic of)", display_name: "Korea (the Republic of)" },
    { value: "Kuwait", display_name: "Kuwait" },
    { value: "Kyrgyzstan", display_name: "Kyrgyzstan" },
    {
        value: "Lao People's Democratic Republic (the)",
        display_name: "Lao People's Democratic Republic (the)",
    },
    { value: "Latvia", display_name: "Latvia" },
    { value: "Lebanon", display_name: "Lebanon" },
    { value: "Lesotho", display_name: "Lesotho" },
    { value: "Liberia", display_name: "Liberia" },
    { value: "Libya", display_name: "Libya" },
    { value: "Liechtenstein", display_name: "Liechtenstein" },
    { value: "Lithuania", display_name: "Lithuania" },
    { value: "Luxembourg", display_name: "Luxembourg" },
    { value: "Macao", display_name: "Macao" },
    { value: "Madagascar", display_name: "Madagascar" },
    { value: "Malawi", display_name: "Malawi" },
    { value: "Malaysia", display_name: "Malaysia" },
    { value: "Maldives", display_name: "Maldives" },
    { value: "Mali", display_name: "Mali" },
    { value: "Malta", display_name: "Malta" },
    { value: "Marshall Islands (the)", display_name: "Marshall Islands (the)" },
    { value: "Martinique", display_name: "Martinique" },
    { value: "Mauritania", display_name: "Mauritania" },
    { value: "Mauritius", display_name: "Mauritius" },
    { value: "Mayotte", display_name: "Mayotte" },
    { value: "Mexico", display_name: "Mexico" },
    {
        value: "Micronesia (Federated States of)",
        display_name: "Micronesia (Federated States of)",
    },
    { value: "Moldova (the Republic of)", display_name: "Moldova (the Republic of)" },
    { value: "Monaco", display_name: "Monaco" },
    { value: "Mongolia", display_name: "Mongolia" },
    { value: "Montenegro", display_name: "Montenegro" },
    { value: "Montserrat", display_name: "Montserrat" },
    { value: "Morocco", display_name: "Morocco" },
    { value: "Mozambique", display_name: "Mozambique" },
    { value: "Myanmar", display_name: "Myanmar" },
    { value: "Namibia", display_name: "Namibia" },
    { value: "Nauru", display_name: "Nauru" },
    { value: "Nepal", display_name: "Nepal" },
    { value: "Netherlands (the)", display_name: "Netherlands (the)" },
    { value: "New Caledonia", display_name: "New Caledonia" },
    { value: "New Zealand", display_name: "New Zealand" },
    { value: "Nicaragua", display_name: "Nicaragua" },
    { value: "Niger (the)", display_name: "Niger (the)" },
    { value: "Nigeria", display_name: "Nigeria" },
    { value: "Niue", display_name: "Niue" },
    { value: "Norfolk Island", display_name: "Norfolk Island" },
    {
        value: "Northern Mariana Islands (the)",
        display_name: "Northern Mariana Islands (the)",
    },
    { value: "Norway", display_name: "Norway" },
    { value: "Oman", display_name: "Oman" },
    { value: "Pakistan", display_name: "Pakistan" },
    { value: "Palau", display_name: "Palau" },
    { value: "Palestine, State of", display_name: "Palestine, State of" },
    { value: "Panama", display_name: "Panama" },
    { value: "Papua New Guinea", display_name: "Papua New Guinea" },
    { value: "Paraguay", display_name: "Paraguay" },
    { value: "Peru", display_name: "Peru" },
    { value: "Philippines (the)", display_name: "Philippines (the)" },
    { value: "Pitcairn", display_name: "Pitcairn" },
    { value: "Poland", display_name: "Poland" },
    { value: "Portugal", display_name: "Portugal" },
    { value: "Puerto Rico", display_name: "Puerto Rico" },
    { value: "Qatar", display_name: "Qatar" },
    {
        value: "Republic of North Macedonia",
        display_name: "Republic of North Macedonia",
    },
    { value: "Romania", display_name: "Romania" },
    { value: "Russian Federation (the)", display_name: "Russian Federation (the)" },
    { value: "Rwanda", display_name: "Rwanda" },
    { value: "Réunion", display_name: "Réunion" },
    { value: "Saint Barthélemy", display_name: "Saint Barthélemy" },
    {
        value: "Saint Helena, Ascension and Tristan da Cunha",
        display_name: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { value: "Saint Kitts and Nevis", display_name: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", display_name: "Saint Lucia" },
    { value: "Saint Martin (French part)", display_name: "Saint Martin (French part)" },
    { value: "Saint Pierre and Miquelon", display_name: "Saint Pierre and Miquelon" },
    {
        value: "Saint Vincent and the Grenadines",
        display_name: "Saint Vincent and the Grenadines",
    },
    { value: "Samoa", display_name: "Samoa" },
    { value: "San Marino", display_name: "San Marino" },
    { value: "Sao Tome and Principe", display_name: "Sao Tome and Principe" },
    { value: "Saudi Arabia", display_name: "Saudi Arabia" },
    { value: "Senegal", display_name: "Senegal" },
    { value: "Serbia", display_name: "Serbia" },
    { value: "Seychelles", display_name: "Seychelles" },
    { value: "Sierra Leone", display_name: "Sierra Leone" },
    { value: "Singapore", display_name: "Singapore" },
    { value: "Sint Maarten (Dutch part)", display_name: "Sint Maarten (Dutch part)" },
    { value: "Slovakia", display_name: "Slovakia" },
    { value: "Slovenia", display_name: "Slovenia" },
    { value: "Solomon Islands", display_name: "Solomon Islands" },
    { value: "Somalia", display_name: "Somalia" },
    { value: "South Africa", display_name: "South Africa" },
    {
        value: "South Georgia and the South Sandwich Islands",
        display_name: "South Georgia and the South Sandwich Islands",
    },
    { value: "South Sudan", display_name: "South Sudan" },
    { value: "Spain", display_name: "Spain" },
    { value: "Sri Lanka", display_name: "Sri Lanka" },
    { value: "Sudan (the)", display_name: "Sudan (the)" },
    { value: "Suriname", display_name: "Suriname" },
    { value: "Svalbard and Jan Mayen", display_name: "Svalbard and Jan Mayen" },
    { value: "Sweden", display_name: "Sweden" },
    { value: "Switzerland", display_name: "Switzerland" },
    { value: "Syrian Arab Republic", display_name: "Syrian Arab Republic" },
    { value: "Taiwan (Province of China)", display_name: "Taiwan (Province of China)" },
    { value: "Tajikistan", display_name: "Tajikistan" },
    {
        value: "Tanzania, United Republic of",
        display_name: "Tanzania, United Republic of",
    },
    { value: "Thailand", display_name: "Thailand" },
    { value: "Timor-Leste", display_name: "Timor-Leste" },
    { value: "Togo", display_name: "Togo" },
    { value: "Tokelau", display_name: "Tokelau" },
    { value: "Tonga", display_name: "Tonga" },
    { value: "Trinidad and Tobago", display_name: "Trinidad and Tobago" },
    { value: "Tunisia", display_name: "Tunisia" },
    { value: "Turkey", display_name: "Turkey" },
    { value: "Turkmenistan", display_name: "Turkmenistan" },
    {
        value: "Turks and Caicos Islands (the)",
        display_name: "Turks and Caicos Islands (the)",
    },
    { value: "Tuvalu", display_name: "Tuvalu" },
    { value: "Uganda", display_name: "Uganda" },
    { value: "Ukraine", display_name: "Ukraine" },
    { value: "United Arab Emirates (the)", display_name: "United Arab Emirates (the)" },
    {
        value: "United Kingdom of Great Britain and Northern Ireland (the)",
        display_name: "United Kingdom of Great Britain and Northern Ireland (the)",
    },
    {
        value: "United States Minor Outlying Islands (the)",
        display_name: "United States Minor Outlying Islands (the)",
    },
    { value: "Uruguay", display_name: "Uruguay" },
    { value: "Uzbekistan", display_name: "Uzbekistan" },
    { value: "Vanuatu", display_name: "Vanuatu" },
    {
        value: "Venezuela (Bolivarian Republic of)",
        display_name: "Venezuela (Bolivarian Republic of)",
    },
    { value: "Viet Nam", display_name: "Viet Nam" },
    { value: "Virgin Islands (British)", display_name: "Virgin Islands (British)" },
    { value: "Virgin Islands (U.S.)", display_name: "Virgin Islands (U.S.)" },
    { value: "Wallis and Futuna", display_name: "Wallis and Futuna" },
    { value: "Western Sahara", display_name: "Western Sahara" },
    { value: "Yemen", display_name: "Yemen" },
    { value: "Zambia", display_name: "Zambia" },
    { value: "Zimbabwe", display_name: "Zimbabwe" },
    { value: "Åland Islands", display_name: "Åland Islands" },
];

export const USStates: State[] = [
    {
        name: "Alabama",
        abbreviation: "AL",
    },
    {
        name: "Alaska",
        abbreviation: "AK",
    },
    {
        name: "American Samoa",
        abbreviation: "AS",
    },
    {
        name: "Arizona",
        abbreviation: "AZ",
    },
    {
        name: "Arkansas",
        abbreviation: "AR",
    },
    {
        name: "California",
        abbreviation: "CA",
    },
    {
        name: "Colorado",
        abbreviation: "CO",
    },
    {
        name: "Connecticut",
        abbreviation: "CT",
    },
    {
        name: "Delaware",
        abbreviation: "DE",
    },
    {
        name: "District Of Columbia",
        abbreviation: "DC",
    },
    {
        name: "Federated States Of Micronesia",
        abbreviation: "FM",
    },
    {
        name: "Florida",
        abbreviation: "FL",
    },
    {
        name: "Georgia",
        abbreviation: "GA",
    },
    {
        name: "Guam",
        abbreviation: "GU",
    },
    {
        name: "Hawaii",
        abbreviation: "HI",
    },
    {
        name: "Idaho",
        abbreviation: "ID",
    },
    {
        name: "Illinois",
        abbreviation: "IL",
    },
    {
        name: "Indiana",
        abbreviation: "IN",
    },
    {
        name: "Iowa",
        abbreviation: "IA",
    },
    {
        name: "Kansas",
        abbreviation: "KS",
    },
    {
        name: "Kentucky",
        abbreviation: "KY",
    },
    {
        name: "Louisiana",
        abbreviation: "LA",
    },
    {
        name: "Maine",
        abbreviation: "ME",
    },
    {
        name: "Marshall Islands",
        abbreviation: "MH",
    },
    {
        name: "Maryland",
        abbreviation: "MD",
    },
    {
        name: "Massachusetts",
        abbreviation: "MA",
    },
    {
        name: "Michigan",
        abbreviation: "MI",
    },
    {
        name: "Minnesota",
        abbreviation: "MN",
    },
    {
        name: "Mississippi",
        abbreviation: "MS",
    },
    {
        name: "Missouri",
        abbreviation: "MO",
    },
    {
        name: "Montana",
        abbreviation: "MT",
    },
    {
        name: "Nebraska",
        abbreviation: "NE",
    },
    {
        name: "Nevada",
        abbreviation: "NV",
    },
    {
        name: "New Hampshire",
        abbreviation: "NH",
    },
    {
        name: "New Jersey",
        abbreviation: "NJ",
    },
    {
        name: "New Mexico",
        abbreviation: "NM",
    },
    {
        name: "New York",
        abbreviation: "NY",
    },
    {
        name: "North Carolina",
        abbreviation: "NC",
    },
    {
        name: "North Dakota",
        abbreviation: "ND",
    },
    {
        name: "Northern Mariana Islands",
        abbreviation: "MP",
    },
    {
        name: "Ohio",
        abbreviation: "OH",
    },
    {
        name: "Oklahoma",
        abbreviation: "OK",
    },
    {
        name: "Oregon",
        abbreviation: "OR",
    },
    {
        name: "Palau",
        abbreviation: "PW",
    },
    {
        name: "Pennsylvania",
        abbreviation: "PA",
    },
    {
        name: "Puerto Rico",
        abbreviation: "PR",
    },
    {
        name: "Rhode Island",
        abbreviation: "RI",
    },
    {
        name: "South Carolina",
        abbreviation: "SC",
    },
    {
        name: "South Dakota",
        abbreviation: "SD",
    },
    {
        name: "Tennessee",
        abbreviation: "TN",
    },
    {
        name: "Texas",
        abbreviation: "TX",
    },
    {
        name: "Utah",
        abbreviation: "UT",
    },
    {
        name: "Vermont",
        abbreviation: "VT",
    },
    {
        name: "Virgin Islands",
        abbreviation: "VI",
    },
    {
        name: "Virginia",
        abbreviation: "VA",
    },
    {
        name: "Washington",
        abbreviation: "WA",
    },
    {
        name: "West Virginia",
        abbreviation: "WV",
    },
    {
        name: "Wisconsin",
        abbreviation: "WI",
    },
    {
        name: "Wyoming",
        abbreviation: "WY",
    },
];

export const latlong: { [country: string]: { latitude: number; longitude: number } } = {
    Andorra: { latitude: 42.5, longitude: 1.5 },
    "United Arab Emirates": { latitude: 24, longitude: 54 },
    Afghanistan: { latitude: 33, longitude: 65 },
    "Antigua and Barbuda": { latitude: 17.05, longitude: -61.8 },
    Anguilla: { latitude: 18.25, longitude: -63.1667 },
    Albania: { latitude: 41, longitude: 20 },
    Armenia: { latitude: 40, longitude: 45 },
    "Netherlands Antilles": { latitude: 12.25, longitude: -68.75 },
    Angola: { latitude: -12.5, longitude: 18.5 },
    "Asia/Pacific Region": { latitude: 35, longitude: 105 },
    Antarctica: { latitude: -90, longitude: 0 },
    Argentina: { latitude: -34, longitude: -64 },
    "American Samoa": { latitude: -14.3333, longitude: -170 },
    Austria: { latitude: 47.3333, longitude: 13.3333 },
    Australia: { latitude: -27, longitude: 133 },
    Aruba: { latitude: 12.5, longitude: -69.9667 },
    Azerbaijan: { latitude: 40.5, longitude: 47.5 },
    "Bosnia and Herzegovina": { latitude: 44, longitude: 18 },
    Barbados: { latitude: 13.1667, longitude: -59.5333 },
    Bangladesh: { latitude: 24, longitude: 90 },
    Belgium: { latitude: 50.8333, longitude: 4 },
    "Burkina Faso": { latitude: 13, longitude: -2 },
    Bulgaria: { latitude: 43, longitude: 25 },
    Bahrain: { latitude: 26, longitude: 50.55 },
    Burundi: { latitude: -3.5, longitude: 30 },
    Benin: { latitude: 9.5, longitude: 2.25 },
    Bermuda: { latitude: 32.3333, longitude: -64.75 },
    "Brunei Darussalam": { latitude: 4.5, longitude: 114.6667 },
    Bolivia: { latitude: -17, longitude: -65 },
    Brazil: { latitude: -10, longitude: -55 },
    Bahamas: { latitude: 24.25, longitude: -76 },
    Bhutan: { latitude: 27.5, longitude: 90.5 },
    "Bouvet Island": { latitude: -54.4333, longitude: 3.4 },
    Botswana: { latitude: -22, longitude: 24 },
    Belarus: { latitude: 53, longitude: 28 },
    Belize: { latitude: 17.25, longitude: -88.75 },
    Canada: { latitude: 54, longitude: -100 },
    "Cocos (Keeling) Islands": { latitude: -12.5, longitude: 96.8333 },
    "Congo, The Democratic Republic of the": { latitude: 0, longitude: 25 },
    "Central African Republic": { latitude: 7, longitude: 21 },
    Congo: { latitude: -1, longitude: 15 },
    Switzerland: { latitude: 47, longitude: 8 },
    "C\u00F4te d'Ivoire": { latitude: 8, longitude: -5 },
    "Cook Islands": { latitude: -21.2333, longitude: -159.7667 },
    Chile: { latitude: -30, longitude: -71 },
    Cameroon: { latitude: 6, longitude: 12 },
    China: { latitude: 35, longitude: 105 },
    Colombia: { latitude: 4, longitude: -72 },
    "Costa Rica": { latitude: 10, longitude: -84 },
    Cuba: { latitude: 21.5, longitude: -80 },
    "Cura\u00E7ao": { latitude: 16, longitude: -24 },
    "Christmas Island": { latitude: -10.5, longitude: 105.6667 },
    Cyprus: { latitude: 35, longitude: 33 },
    "Czech Republic": { latitude: 49.75, longitude: 15.5 },
    Germany: { latitude: 51, longitude: 9 },
    Djibouti: { latitude: 11.5, longitude: 43 },
    Denmark: { latitude: 56, longitude: 10 },
    Dominica: { latitude: 15.4167, longitude: -61.3333 },
    "Dominican Republic": { latitude: 19, longitude: -70.6667 },
    Algeria: { latitude: 28, longitude: 3 },
    Ecuador: { latitude: -2, longitude: -77.5 },
    Estonia: { latitude: 59, longitude: 26 },
    Egypt: { latitude: 27, longitude: 30 },
    "Western Sahara": { latitude: 24.5, longitude: -13 },
    Eritrea: { latitude: 15, longitude: 39 },
    Spain: { latitude: 40, longitude: -4 },
    Ethiopia: { latitude: 8, longitude: 38 },
    Europe: { latitude: 47, longitude: 8 },
    Finland: { latitude: 62, longitude: 26 },
    Fiji: { latitude: -18, longitude: 175 },
    "Falkland Islands (Malvinas)": { latitude: -51.75, longitude: -59 },
    "Micronesia, Federated States of": { latitude: 6.9167, longitude: 158.25 },
    "Faroe Islands": { latitude: 62, longitude: -7 },
    France: { latitude: 46, longitude: 2 },
    Gabon: { latitude: -1, longitude: 11.75 },
    "United Kingdom": { latitude: 54, longitude: -2 },
    Grenada: { latitude: 12.1167, longitude: -61.6667 },
    Georgia: { latitude: 42, longitude: 43.5 },
    "French Guiana": { latitude: 4, longitude: -53 },
    Ghana: { latitude: 8, longitude: -2 },
    Gibraltar: { latitude: 36.1833, longitude: -5.3667 },
    Greenland: { latitude: 72, longitude: -40 },
    Gambia: { latitude: 13.4667, longitude: -16.5667 },
    Guinea: { latitude: 11, longitude: -10 },
    Guadeloupe: { latitude: 16.25, longitude: -61.5833 },
    "Equatorial Guinea": { latitude: 2, longitude: 10 },
    Greece: { latitude: 39, longitude: 22 },
    "South Georgia and the South Sandwich Islands": { latitude: -54.5, longitude: -37 },
    Guatemala: { latitude: 15.5, longitude: -90.25 },
    Guam: { latitude: 13.4667, longitude: 144.7833 },
    "Guinea-Bissau": { latitude: 12, longitude: -15 },
    Guyana: { latitude: 5, longitude: -59 },
    "Hong Kong": { latitude: 22.25, longitude: 114.1667 },
    "Heard Island and McDonald Islands": { latitude: -53.1, longitude: 72.5167 },
    Honduras: { latitude: 15, longitude: -86.5 },
    Croatia: { latitude: 45.1667, longitude: 15.5 },
    Haiti: { latitude: 19, longitude: -72.4167 },
    Hungary: { latitude: 47, longitude: 20 },
    Indonesia: { latitude: -5, longitude: 120 },
    Ireland: { latitude: 53, longitude: -8 },
    Israel: { latitude: 31.5, longitude: 34.75 },
    India: { latitude: 20, longitude: 77 },
    "British Indian Ocean Territory": { latitude: -6, longitude: 71.5 },
    Iraq: { latitude: 33, longitude: 44 },
    "Iran, Islamic Republic of": { latitude: 32, longitude: 53 },
    Iceland: { latitude: 65, longitude: -18 },
    Italy: { latitude: 42.8333, longitude: 12.8333 },
    Jamaica: { latitude: 18.25, longitude: -77.5 },
    Jordan: { latitude: 31, longitude: 36 },
    Japan: { latitude: 36, longitude: 138 },
    Kenya: { latitude: 1, longitude: 38 },
    Kyrgyzstan: { latitude: 41, longitude: 75 },
    Cambodia: { latitude: 13, longitude: 105 },
    Kiribati: { latitude: 1.4167, longitude: 173 },
    Comoros: { latitude: -12.1667, longitude: 44.25 },
    "Saint Kitts and Nevis": { latitude: 17.3333, longitude: -62.75 },
    "Korea, Democratic People's Republic of": { latitude: 40, longitude: 127 },
    "Korea, Republic of": { latitude: 37, longitude: 127.5 },
    Kuwait: { latitude: 29.3375, longitude: 47.6581 },
    "Cayman Islands": { latitude: 19.5, longitude: -80.5 },
    Kazakhstan: { latitude: 48, longitude: 68 },
    "Lao People's Democratic Republic": { latitude: 18, longitude: 105 },
    Lebanon: { latitude: 33.8333, longitude: 35.8333 },
    "Saint Lucia": { latitude: 13.8833, longitude: -61.1333 },
    Liechtenstein: { latitude: 47.1667, longitude: 9.5333 },
    "Sri Lanka": { latitude: 7, longitude: 81 },
    Liberia: { latitude: 6.5, longitude: -9.5 },
    Lesotho: { latitude: -29.5, longitude: 28.5 },
    Lithuania: { latitude: 55, longitude: 24 },
    Luxembourg: { latitude: 49.75, longitude: 6 },
    Latvia: { latitude: 57, longitude: 25 },
    Libya: { latitude: 25, longitude: 17 },
    Morocco: { latitude: 32, longitude: -5 },
    Monaco: { latitude: 43.7333, longitude: 7.4 },
    "Moldova, Republic of": { latitude: 47, longitude: 29 },
    Montenegro: { latitude: 42.5, longitude: 19.4 },
    Madagascar: { latitude: -20, longitude: 47 },
    "Marshall Islands": { latitude: 9, longitude: 168 },
    "North Macedonia": { latitude: 41.8333, longitude: 22 },
    Mali: { latitude: 17, longitude: -4 },
    Myanmar: { latitude: 22, longitude: 98 },
    Mongolia: { latitude: 46, longitude: 105 },
    Macao: { latitude: 22.1667, longitude: 113.55 },
    "Northern Mariana Islands": { latitude: 15.2, longitude: 145.75 },
    Martinique: { latitude: 14.6667, longitude: -61 },
    Mauritania: { latitude: 20, longitude: -12 },
    Montserrat: { latitude: 16.75, longitude: -62.2 },
    Malta: { latitude: 35.8333, longitude: 14.5833 },
    Mauritius: { latitude: -20.2833, longitude: 57.55 },
    Maldives: { latitude: 3.25, longitude: 73 },
    Malawi: { latitude: -13.5, longitude: 34 },
    Mexico: { latitude: 23, longitude: -102 },
    Malaysia: { latitude: 2.5, longitude: 112.5 },
    Mozambique: { latitude: -18.25, longitude: 35 },
    Namibia: { latitude: -22, longitude: 17 },
    "New Caledonia": { latitude: -21.5, longitude: 165.5 },
    Niger: { latitude: 16, longitude: 8 },
    "Norfolk Island": { latitude: -29.0333, longitude: 167.95 },
    Nigeria: { latitude: 10, longitude: 8 },
    Nicaragua: { latitude: 13, longitude: -85 },
    Netherlands: { latitude: 52.5, longitude: 5.75 },
    Norway: { latitude: 62, longitude: 10 },
    Nepal: { latitude: 28, longitude: 84 },
    Nauru: { latitude: -0.5333, longitude: 166.9167 },
    Niue: { latitude: -19.0333, longitude: -169.8667 },
    "New Zealand": { latitude: -41, longitude: 174 },
    Oman: { latitude: 21, longitude: 57 },
    Panama: { latitude: 9, longitude: -80 },
    Peru: { latitude: -10, longitude: -76 },
    "French Polynesia": { latitude: -15, longitude: -140 },
    "Papua New Guinea": { latitude: -6, longitude: 147 },
    Philippines: { latitude: 13, longitude: 122 },
    Pakistan: { latitude: 30, longitude: 70 },
    Poland: { latitude: 52, longitude: 20 },
    "Saint Pierre and Miquelon": { latitude: 46.8333, longitude: -56.3333 },
    "Puerto Rico": { latitude: 18.25, longitude: -66.5 },
    Palestine: { latitude: 32, longitude: 35.25 },
    Portugal: { latitude: 39.5, longitude: -8 },
    Palau: { latitude: 7.5, longitude: 134.5 },
    Paraguay: { latitude: -23, longitude: -58 },
    Qatar: { latitude: 25.5, longitude: 51.25 },
    Réunion: { latitude: -21.1, longitude: 55.6 },
    Romania: { latitude: 46, longitude: 25 },
    Serbia: { latitude: 44, longitude: 21 },
    Russia: { latitude: 60, longitude: 100 },
    Rwanda: { latitude: -2, longitude: 30 },
    "Saudi Arabia": { latitude: 25, longitude: 45 },
    "Solomon Islands": { latitude: -8, longitude: 159 },
    Seychelles: { latitude: -4.5833, longitude: 55.6667 },
    Sudan: { latitude: 15, longitude: 30 },
    Sweden: { latitude: 62, longitude: 15 },
    Singapore: { latitude: 1.3667, longitude: 103.8 },
    "Saint Helena": { latitude: -15.9333, longitude: -5.7 },
    Slovenia: { latitude: 46, longitude: 15 },
    "Svalbard and Jan Mayen": { latitude: 78, longitude: 20 },
    Slovakia: { latitude: 48.6667, longitude: 19.5 },
    "Sierra Leone": { latitude: 8.5, longitude: -11.5 },
    "San Marino": { latitude: 43.7667, longitude: 12.4167 },
    Senegal: { latitude: 14, longitude: -14 },
    Somalia: { latitude: 10, longitude: 49 },
    Suriname: { latitude: 4, longitude: -56 },
    "São Tomé and Príncipe": { latitude: 1, longitude: 7 },
    "El Salvador": { latitude: 13.8333, longitude: -88.9167 },
    Syria: { latitude: 35, longitude: 38 },
    Eswatini: { latitude: -26.5, longitude: 31.5 },
    "Turks and Caicos Islands": { latitude: 21.75, longitude: -71.5833 },
    Chad: { latitude: 15, longitude: 19 },
    "French Southern Territories": { latitude: -43, longitude: 67 },
    Togo: { latitude: 8, longitude: 1.1667 },
    Thailand: { latitude: 15, longitude: 100 },
    Tajikistan: { latitude: 39, longitude: 71 },
    Tokelau: { latitude: -9, longitude: -172 },
    Turkmenistan: { latitude: 40, longitude: 60 },
    Tunisia: { latitude: 34, longitude: 9 },
    Tonga: { latitude: -20, longitude: -175 },
    Turkey: { latitude: 39, longitude: 35 },
    "Trinidad and Tobago": { latitude: 11, longitude: -61 },
    Tuvalu: { latitude: -8, longitude: 178 },
    Taiwan: { latitude: 23.5, longitude: 121 },
    Tanzania: { latitude: -6, longitude: 35 },
    Ukraine: { latitude: 49, longitude: 32 },
    Uganda: { latitude: 1, longitude: 32 },
    "United States Minor Outlying Islands": { latitude: 19.2833, longitude: 166.6 },
    "United States": { latitude: 38, longitude: -97 },
    Uruguay: { latitude: -33, longitude: -56 },
    Uzbekistan: { latitude: 41, longitude: 64 },
    "Vatican City": { latitude: 41.9, longitude: 12.45 },
    "Saint Vincent and the Grenadines": { latitude: 13.25, longitude: -61.2 },
    Venezuela: { latitude: 8, longitude: -66 },
    "British Virgin Islands": { latitude: 18.5, longitude: -64.5 },
    "U.S. Virgin Islands": { latitude: 18.3333, longitude: -64.8333 },
    Vietnam: { latitude: 16, longitude: 106 },
    Vanuatu: { latitude: -16, longitude: 167 },
    "Wallis and Futuna": { latitude: -13.3, longitude: -176.2 },
    Samoa: { latitude: -13.5833, longitude: -172.3333 },
    Yemen: { latitude: 15, longitude: 48 },
    Mayotte: { latitude: -12.8333, longitude: 45.1667 },
    "South Africa": { latitude: -29, longitude: 24 },
    Zambia: { latitude: -15, longitude: 30 },
    Zimbabwe: { latitude: -20, longitude: 30 },
};
