import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "confirm-recipients-dialog",
    template: `
        <h3 mat-dialog-title>Confirm Recipients</h3>

        <mat-dialog-content>
            <p>
                Please confirm the accounts to send a message to. You can select or
                unselect each email.
            </p>

            <mat-form-field class="full">
                <mat-label>Emails</mat-label>
                <mat-select [formControl]="targetsControl" multiple>
                    <mat-option *ngFor="let target of data.targets" [value]="target">
                        {{ target }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-dialog-content>

        <mat-dialog-actions>
            <div class="spacer"></div>
            <button mat-button (click)="onCancel()">Cancel</button>
            <button mat-button (click)="onConfirm()" color="accent" mat-flat-button>
                Confirm
            </button>
        </mat-dialog-actions>
    `,
})
export class ConfirmRecipientsDialog {
    get targets() {
        return this.targetsControl.value;
    }
    targetsControl: FormControl;
    constructor(
        public dialogRef: MatDialogRef<ConfirmRecipientsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { targets: string[] },
    ) {
        this.targetsControl = new FormControl(this.data.targets);
    }

    onConfirm(): void {
        this.dialogRef.close(this.targetsControl.value);
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
