<div class="case-audit-container">
    <mat-table
        [dataSource]="auditTrail"
        matSort
        (matSortChange)="onSortChange($event)"
        *ngIf="auditTrail"
    >
        <ng-container matColumnDef="timestamp">
            <mat-header-cell *matHeaderCellDef>Timestamp</mat-header-cell>
            <mat-cell *matCellDef="let entry" [class.audit-top]="entry.expanded">
                <div class="flex stack">
                    <div>{{ entry.timestamp | localizedDate }}</div>
                    <div class="secondary small">
                        {{ entry.timestamp | date: "mediumTime" }}
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="account">
            <mat-header-cell *matHeaderCellDef>Account</mat-header-cell>
            <mat-cell *matCellDef="let entry" [class.audit-top]="entry.expanded">
                <span *ngIf="entry.account">{{ entry.account.displayName }}</span>
                <span *ngIf="!entry.account" class="italic">MedaSystems</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let entry" [class.audit-top]="entry.expanded">
                {{ entry.entry_type }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="event">
            <mat-header-cell *matHeaderCellDef>Event</mat-header-cell>
            <mat-cell
                *matCellDef="let entry"
                class="flex stack center"
                [class.audit-top]="entry.expanded"
            >
                <div class="flex full center">
                    <div class="spacer">{{ entry.event }}</div>
                    <button
                        mat-icon-button
                        *ngIf="hasGroupDetails(entry)"
                        (click)="entry.expanded = !entry.expanded"
                    >
                        <mat-icon>
                            {{ entry.expanded ? "expand_less" : "expand_more" }}
                        </mat-icon>
                    </button>
                </div>
                <ng-container *ngIf="entry.expanded">
                    <ng-container *ngFor="let detail of groupDetails(entry)">
                        <div
                            *ngFor="let event of groupEvent(entry, detail)"
                            class="flex full group-indent"
                        >
                            {{ event }}
                        </div>
                    </ng-container>
                </ng-container>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="spinner-container" *ngIf="!auditTrail">
        <mat-progress-spinner
            diameter="50"
            class="inline"
            mode="indeterminate"
        ></mat-progress-spinner>
    </div>
    <div *ngIf="auditTrail && !auditTrail.length">No Events found.</div>
</div>
