import { NamedObject, ObjectReference } from "./api-object";

export class Template extends NamedObject {
    static object_type: string = "notifications.template";
    text?: string;
    html!: string;
    subject!: string;
    owner?: ObjectReference; // optional
    default_context!: any;

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["owner", "text"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "subject");
        this.setMember(data, patch, "text");
        this.setMember(data, patch, "html");
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "default_context");
    }
}
