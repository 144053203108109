<div mat-dialog-title>
    <div class="flexible flex stack">
        <div>Signature Tabs</div>
        <div class="secondary small">
            Click in the document below at the location(s) you'd like the assignee to
            sign.
        </div>
    </div>
</div>
<mat-dialog-content [style.position]="'relative'">
    <pdf-viewer
        *ngIf="documentContents"
        #pdfContainer
        [style.width]="pageWidth"
        [style.height]="pageHeight"
        [src]="documentContents"
        [(page)]="page"
        [show-all]="false"
        [show-borders]="true"
        [zoom]="zoom"
        (after-load-complete)="onPDFLoaded($event)"
    ></pdf-viewer>
    <div class="pdf-loading" *ngIf="!pdf">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <div
        class="tab-container"
        (click)="addTab($event)"
        [style.width]="pageWidth"
        [style.height]="pageHeight"
        *ngIf="pdf"
    >
        <div
            cdkDrag
            cdkDragBoundary=".pdf-container"
            (cdkDragMoved)="dragSignHere($event, tab)"
            (cdkDragEnded)="dropSignHere($event, tab)"
            class="sign-here"
            *ngFor="let tab of tabsForCurrentPage()"
            [ngStyle]="styleForTab(tab)"
        >
            <div class="flex stack">{{ tab.label || 'Sign Here' }}</div>
            <button
                mat-icon-button
                class="remove-sign-here"
                (click)="removeTab($event, tab)"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <div
            cdkDrag
            cdkDragBoundary=".pdf-container"
            (cdkDragMoved)="dragSignHere($event, tab)"
            (cdkDragEnded)="dropSignHere($event, tab)"
            class="sign-here"
            *ngFor="let tab of draggedtabsForCurrentPage()"
            [ngStyle]="dragstyleForTab(tab)"
        >
            <div class="flex stack">{{ tab.label || 'Sign Here' }}</div>
            <button
                mat-icon-button
                class="remove-sign-here"
                (click)="removeTab($event, tab, true)"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="page-controls flex flexible" *ngIf="!!totalPages">
        <button
            mat-icon-button
            [disabled]="totalPages < 2 || page === 1"
            (click)="totalPages > 1 && page !== 1 && firstPage()"
        >
            <mat-icon>first_page</mat-icon>
        </button>
        <button
            mat-icon-button
            [disabled]="page == 1"
            (click)="page > 1 && previousPage()"
        >
            <mat-icon>navigate_before</mat-icon>
        </button>
        <mat-form-field class="page-field">
            <mat-label>Page</mat-label>
            <mat-select [(ngModel)]="page">
                <mat-option *ngFor="let index of pageArray" [value]="index + 1">
                    {{ index + 1 }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button
            mat-icon-button
            [disabled]="page == totalPages"
            (click)="page != totalPages && nextPage()"
        >
            <mat-icon>navigate_next</mat-icon>
        </button>
        <button
            mat-icon-button
            [disabled]="totalPages < 2 || page == totalPages"
            (click)="totalPages > 1 && page !== totalPages && lastPage()"
        >
            <mat-icon>last_page</mat-icon>
        </button>
    </div>
    <div class="spacer"></div>
    <button
        type="button"
        class="fixed"
        mat-flat-button
        color="primary"
        mat-dialog-close
    >
        Cancel
    </button>
    <button
        type="button"
        mat-flat-button
        color="accent"
        [disabled]="!allTabs.length"
        (click)="allTabs.length && close()"
    >
        Save
    </button>
</mat-dialog-actions>
