import { ObjectReference } from "./../../../../services/models/api-object";
import { FormControl } from "@angular/forms";
import {
    Component,
    ViewChild,
    ElementRef,
    Input,
    ChangeDetectorRef,
    inject,
} from "@angular/core";
import { SearchableListComponent } from "../../searchable-list.component";
import { ObjectViewEntryPoint, ObjectViewMode } from "../../object.component";
import { Sort } from "@angular/material/sort";
import { Case } from "src/services/models/case";
import { Program } from "src/services/models/program";
import { ObjectView } from "../../object-admin.component";
import { DataFormEditorComponent } from "./data-form.component";
import { DataFormService } from "src/services/data.services";
import { Organization } from "src/services/models/organization";
import { DataForm, DataFormReference } from "src/services/models/data";
import { OrganizationService } from "src/services/iam.services";
import { Inquiry } from "src/services/models/inquiry";
import { ObjectRepository } from "src/services/models/compound";
import { MatTable } from "@angular/material/table";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";
import { PaginatedList } from "src/services/models/api-object";

@Component({
    selector: "data-form-admin",
    templateUrl: "./data-form-admin.component.html",
    styleUrls: ["../data-admin.component.scss"],
})
export class DataFormAdminComponent extends SearchableListComponent<DataForm> {
    objectView: ObjectView<DataForm> | undefined = DataFormEditorComponent;
    @ViewChild("search") searchElement: ElementRef<any> | undefined;
    @ViewChild(MatTable) _matTable?: MatTable<any>;

    @Input() set repository(v: ObjectRepository | undefined) {
        this._repository = v;
        this.list.refresh();

        if (this.repository instanceof Organization)
            this._organization = this.repository;
        else if (this.repository instanceof Program) {
            this.organizationService
                .retrieve(this.repository.organization.id ?? "0")
                .subscribe(
                    (org: Organization | undefined) => (this._organization = org),
                );
        } else if (this.repository instanceof Case) {
            this.organizationService
                .retrieve(this.repository.shared.organization?.id ?? "0")
                .subscribe(
                    (org: Organization | undefined) => (this._organization = org),
                );
        } else if (this.repository instanceof Inquiry) {
            this.organizationService
                .retrieve(this.repository.organization?.id ?? "0")
                .subscribe(
                    (org: Organization | undefined) => (this._organization = org),
                );
        }
    }
    get repository(): ObjectRepository | undefined {
        return this._repository;
    }
    get organization(): Organization | undefined {
        return this._organization;
    }
    protected _repository?: ObjectRepository;
    protected _organization?: Organization;
    protected organizationService: OrganizationService;

    searchTermControl: FormControl = new FormControl();
    showSearch: boolean = false;
    get loading(): boolean {
        return !this.list.hasLoaded;
    }

    get displayedColumns(): string[] {
        return ["display_name", "owner_id", "actions"];
    }

    constructor(
        protected service: DataFormService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
        this.organizationService = inject(OrganizationService);
    }

    isSystemOwned(form: DataForm | DataFormReference | ObjectReference): boolean {
        return (
            (form instanceof DataForm || form instanceof DataFormReference) &&
            !form.owner
        );
    }

    canEdit(form: DataForm): boolean {
        return (
            this.currentAccount?.hasRole("object.edit", form.owner) ||
            this.currentAccount?.hasRole("object.admin", form.owner) ||
            this.currentAccount?.hasRole("organization.administrator", form.owner) ||
            this.currentAccount?.isSystemAdministrator ||
            (form.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.edit",
                    (form.owner as Program).organization,
                )) ||
            (form.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (form.owner as Program).organization,
                )) ||
            (form.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (form.owner as Program).organization,
                )) ||
            false
        );
    }
    canDelete(form: DataForm): boolean {
        return (
            this.currentAccount?.hasRole("object.admin", form.owner) ||
            this.currentAccount?.hasRole("organization.administrator", form.owner) ||
            this.currentAccount?.isSystemAdministrator ||
            (form.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (form.owner as Program).organization,
                )) ||
            (form.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (form.owner as Program).organization,
                )) ||
            false
        );
    }

    editObject(
        event: MouseEvent | undefined,
        object: DataForm,
        asDialog?: boolean,
        viewOnly?: boolean,
        isDuplicate = false,
    ): ObjectViewEntryPoint<DataForm> | undefined {
        const instance = super.editObject(
            event,
            object,
            asDialog,
            viewOnly,
        ) as DataFormEditorComponent;
        instance.autosave = false;
        instance.organization = this.organization;
        instance.duplicating = isDuplicate;
        return instance;
    }
    duplicateObject(
        event: MouseEvent,
        object: DataForm,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<DataForm> | undefined {
        const duplicate = object.duplicate(object.owner ?? this.repository);
        const instance = this.editObject(event, duplicate, asDialog, undefined, true);
        if (asDialog && instance?.dialogReference)
            instance.dialogReference
                .afterClosed()
                .subscribe((form: DataForm) => this.onAfterCreate(form, instance));
        return instance;
    }
    newObject(data?: any): DataForm | undefined {
        return super.newObject({
            ...data,
            display_name: "New Form",
            owner: this.repository,
        });
    }
    protected objectDialogConfiguration(
        object: DataForm,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            width: "90vw",
            height: "90vh",
        };
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["next"] = "0";
        filters["is_template"] = "true";
        filters["use_reference"] = "True";
        if (this.repository?.type == "program.case")
            filters["owned"] =
                this.repository.id + "," + (this.repository as Case).shared.id;
        else if (this.repository?.type == "program.program")
            filters["owned"] =
                this.repository.id + "," + (this.repository as Program).organization.id;
        else filters["owned"] = this.repository?.id ?? "";
        filters["owned"] += ",0";
        return filters;
    }
    protected resolveReferences(list: PaginatedList<any>): PaginatedList<any> {
        return list;
    }
}
