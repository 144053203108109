import { NgModule } from "@angular/core";
import { CommonModule as EAPCommonModule } from "../common/common.module";
import { CommonModule } from "@angular/common";
import { ServicesModule } from "../services/services.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { IntakeComponent } from "./components/intake/intake.component";
import { MatIconModule } from "@angular/material/icon";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
@NgModule({
    imports: [
        EAPCommonModule,
        CommonModule,
        ServicesModule,
        ReactiveFormsModule,
        ScrollingModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        ClipboardModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatRadioModule,
        MatProgressSpinnerModule,
    ],
    declarations: [IntakeComponent],
    exports: [],
    providers: [],
})
export class IntakeModule {}
