<h3 mat-dialog-title *ngIf="!isInvite && !bulkMode">Send Message</h3>
<h3 mat-dialog-title *ngIf="!isInvite && bulkMode">Send Bulk Message</h3>
<h3 mat-dialog-title *ngIf="isInvite">Send Invitation</h3>
<form [formGroup]="formGroup" novalidate (ngSubmit)="onSend()">
    <mat-dialog-content>
        <div *ngIf="errorMessage" class="error bold" [innerHTML]="errorMessage"></div>
        <mat-form-field class="full">
            <mat-label *ngIf="!bulkMode">To</mat-label>
            <mat-label *ngIf="bulkMode">Cases</mat-label>
            <mat-icon matSuffix>email</mat-icon>
            <mat-select placeholder="To" formControlName="target" [multiple]="bulkMode">
                <mat-option value="external" *ngIf="!isInvite && !bulkMode">
                    Un-Affiliated Email
                </mat-option>
                <mat-option *ngFor="let contact of contacts" [value]="contact">
                    {{ roleDisplay(contact) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="full" *ngIf="targetIsUnAffiliated && !isInvite">
            <mat-label>Un-Affiliated Email</mat-label>
            <mat-icon matSuffix>email</mat-icon>
            <input
                matInput
                formControlName="unAffiliatedTarget"
                placeholder="Enter Non Affiliated Email"
                required
            />

            <mat-error *ngIf="unAffiliatedEmail.errors?.invalidEmailAddress">
                Please enter a valid email address.
            </mat-error>
            <mat-error *ngIf="unAffiliatedEmail.errors?.required">
                This field is required.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full" *ngIf="!isInvite && !bulkMode">
            <mat-label>CC</mat-label>
            <mat-icon matSuffix>groups</mat-icon>
            <mat-select placeholder="CC" formControlName="copies" multiple>
                <mat-option
                    *ngFor="let contact of contacts"
                    [value]="contact"
                    [disabled]="contact == formGroup.controls.target.value"
                >
                    {{ roleDisplay(contact) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="full" *ngIf="!isInvite && !bulkMode">
            <mat-icon matSuffix>groups</mat-icon>
            <mat-label>CC Un-Affiliated Emails</mat-label>
            <input
                formControlName="chipListInput"
                style="width: 100%"
                #chiplistInput
                matInput
                placeholder="Enter Email"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addUnAffiliatedEmail($event)"
                (focusout)="onFocusOut($event)"
            />

            <mat-hint *ngIf="!unAffiliatedCopiesList.length">
                Hit the enter or ',' key to add emails to CC
            </mat-hint>
            <mat-error *ngIf="chipListInput.errors?.invalidEmailAddress">
                Please enter a valid email address.
            </mat-error>
            <mat-error *ngIf="chipListInput.errors?.required">
                This field is required.
            </mat-error>
            <mat-chip-grid #chipList formArrayName="copies">
                <mat-chip
                    removable
                    *ngFor="let item of unAffiliatedCopiesList; let i = index"
                    (removed)="removeEmail(i)"
                    color="accent"
                >
                    {{item}}
                    <button matChipRemove [attr.aria-label]="'remove ' + item">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-grid>
        </mat-form-field>
        <mat-form-field class="full">
            <mat-label>Template</mat-label>
            <mat-select placeholder="Template not specified" formControlName="template">
                <mat-select-trigger>
                    {{ formGroup.controls.template.value?.displayName }}
                </mat-select-trigger>
                <mat-option
                    *ngIf="!availableTemplates?.length"
                    [value]="null"
                    [disabled]="true"
                >
                    No Available Templates
                </mat-option>
                <mat-option *ngIf="!!formGroup.controls.template.value" [value]="null">
                    No Template
                </mat-option>
                <mat-option
                    *ngFor="let template of availableTemplates"
                    [value]="template"
                >
                    {{ template.displayName }}
                    <span class="secondary small" *ngIf="template?.owner">
                        {{ template?.owner?.displayName }}
                    </span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="full subject-margin">
            <mat-label>Subject</mat-label>
            <input
                matInput
                #input1
                placeholder="Subject"
                formControlName="subject"
                [maxLength]="subjectMaxLength"
            />
            <mat-hint align="end">
                {{ input1.value.length}} / {{ subjectMaxLength }}
            </mat-hint>
            <mat-error *ngIf="formGroup.hasError('required', 'subject')">
                This field is required.
            </mat-error>
            <mat-error *ngIf="formGroup.hasError('maxlength', 'subject')">
                This field must be less than {{ subjectMaxLength }} characters, it
                currently contains {{ input1.value.length }}.
            </mat-error>
        </mat-form-field>
        <rich-text-editor
            matinput
            [formControl]="messageFormControl"
            [value]="formGroup.controls.message.value"
            [required]="true"
            [customStyles]="{'min-height': '8rem'}"
            placeholder="Message"
        ></rich-text-editor>
        <div class="row flex stack full" *ngIf="canAttachDocuments">
            <div class="title-1">Attachments</div>
            <div class="table full">
                <div class="header">
                    <div class="row flex">
                        <div class="column flex content-middle available-file">
                            File
                        </div>
                        <div class="column flex content-middle available-repo">
                            Status
                        </div>
                        <div class="column flex icon-column"></div>
                    </div>
                </div>
                <div class="body striped no-border">
                    <div
                        class="row flex bold info not-found"
                        *ngIf="!attached.length && !queue?.length"
                    >
                        No Documents Attached.
                    </div>
                    <div class="row flex no-border" *ngFor="let queueItem of queue">
                        <div class="column flex content-middle available-file">
                            {{ queueItem.file.name }}
                        </div>
                        <div
                            class="column flex content-middle available-repo secondary"
                        >
                            <span
                                *ngIf="!queueItem.isSuccess && !queueItem.isError"
                                class="blue"
                            >
                                In Upload Queue
                            </span>
                            <span *ngIf="queueItem.isSuccess" class="green">
                                Uploaded
                            </span>
                            <span *ngIf="queueItem.isError" class="red">
                                Error Uploading
                            </span>
                        </div>
                        <div class="column flex icon-column content-middle">
                            <button
                                mat-icon-button
                                class="red"
                                *ngIf="!queueItem.isSuccess && !queueItem.isError"
                                (click)="removeQueueItem($event, queueItem)"
                            >
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="row flex no-border" *ngFor="let document of attached">
                        <div class="column flex stack content-middle available-file">
                            <div>{{ document.displayName }}</div>
                            <div
                                *ngIf="!!asDocument(document)?.alias"
                                class="file-name secondary super-small"
                            >
                                {{ document.name }}
                            </div>
                        </div>
                        <div
                            class="column flex content-middle secondary available-repo"
                        >
                            <span
                                *ngIf="asDocument(document)?.repository?.id == repository?.id"
                            >
                                Shared
                            </span>
                            <span
                                *ngIf="asDocument(document)?.repository?.id != repository?.id"
                            >
                                Will be Shared
                            </span>
                        </div>
                        <div class="column fixed icon-column content-middle">
                            <button
                                mat-icon-button
                                class="red"
                                (click)="removeAttachment($event, document)"
                            >
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex" *ngIf="uploader">
                <div class="column uploader">
                    <div
                        ng2FileDrop
                        class="drop-zone"
                        [class.over]="fileOver"
                        (fileOver)="fileOver=$event"
                        [uploader]="uploader"
                    >
                        <input
                            type="file"
                            ng2FileSelect
                            id="file-select"
                            [uploader]="uploader"
                            hidden
                        />
                        <strong>Drop files</strong>
                        here
                        <span *ngIf="availableDocuments.length">,</span>
                        <span *ngIf="!availableDocuments.length">or</span>
                        <label for="file-select" class="mat-raised-button">
                            click to browse
                        </label>
                        <span *ngIf="availableDocuments.length">
                            , or select documents below to attach
                        </span>
                        .
                    </div>
                </div>
            </div>
            <div
                class="title-1 available"
                matTooltip="Click to Expand"
                *ngIf="availableDocuments.length"
                (click)="showAvailableDocuments = !showAvailableDocuments"
            >
                Available Documents ({{ availableDocuments.length }})
                <mat-icon *ngIf="!showAvailableDocuments">expand_more</mat-icon>
                <mat-icon *ngIf="showAvailableDocuments">expand_less</mat-icon>
            </div>
            <div
                class="row flex"
                *ngIf="availableDocuments.length && showAvailableDocuments"
            >
                <div class="table full">
                    <div class="header">
                        <div class="row flex">
                            <div class="column flex content-middle available-file">
                                File
                            </div>
                            <div class="column flex content-middle available-repo">
                                Repository
                            </div>
                            <div class="column fixed attach-column content-middle">
                                Attach
                            </div>
                        </div>
                    </div>
                    <div class="body striped no-border">
                        <div
                            class="row flex no-border"
                            *ngFor="let doc of availableDocuments"
                        >
                            <div
                                class="column flex stack content-middle available-file"
                            >
                                <div>{{ doc.displayName }}</div>
                                <div
                                    *ngIf="!!asDocument(doc)?.alias"
                                    class="file-name secondary super-small"
                                >
                                    {{ doc.name }}
                                </div>
                            </div>
                            <div
                                class="column flex content-middle available-repo secondary"
                            >
                                {{ asDocument(doc)?.repositoryType ?? '' }}
                            </div>
                            <div class="column fixed icon-column content-middle">
                                <button
                                    mat-icon-button
                                    class="primary"
                                    (click)="attachDocument($event, doc)"
                                >
                                    <mat-icon>attach_email</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="spacer"></div>
        <button type="button" class="fixed" mat-flat-button mat-dialog-close>
            Cancel
        </button>
        <mat-spinner *ngIf="loading" mode="indeterminate" [diameter]="50"></mat-spinner>
        <button
            type="button"
            class="fixed"
            mat-flat-button
            color="accent"
            *ngIf="!loading"
            [disabled]="!formGroup.valid"
            (click)="formGroup.valid && onSend()"
        >
            Send
        </button>
    </mat-dialog-actions>
</form>

<bdc-walk-popup
    #doctorTask7
    name="doctorTask7"
    xPosition="before"
    yPosition="above"
    [mustCompleted]="{doctorOnboardingWelcome: true, doctorTask2: true}"
    [closeOnClick]="true"
    [showCloseButton]="false"
    class="doctorTask7Popup"
>
    For now, just close this popup.
</bdc-walk-popup>
