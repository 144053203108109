<object-form
    class="flexible"
    [controller]="controller"
    [formGroup]="formGroup"
    [hideControls]="true"
    *ngIf="mode == ObjectViewMode.Create"
>
    <div *ngIf="!uploader">
        <div class="spinner">
            <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
        </div>
        <div class="spinner-message">Loading ...</div>
    </div>
    <div class="wrapper flex stack full">
        <div class="document-content">
            <mat-radio-group [formControl]="existingControl">
                <ng-container *ngIf="!!uploader">
                    <div class="row flex" *ngIf="isCaseRepository">
                        <mat-radio-button [value]="false" [checked]="!existing">
                            Upload new documents
                        </mat-radio-button>
                    </div>
                    <div class="row flex" *ngIf="!isCaseRepository || !existing">
                        <div class="column uploader drop-zone-container">
                            <div
                                ng2FileDrop
                                class="drop-zone"
                                [class.over]="fileOver"
                                (fileOver)="fileOver = $event"
                                [uploader]="uploader"
                            >
                                <div class="flex stack center drop-items">
                                    <mat-icon class="upload-icon">
                                        cloud_upload
                                    </mat-icon>
                                    <input
                                        type="file"
                                        ng2FileSelect
                                        [id]="elementId"
                                        [uploader]="uploader"
                                        hidden
                                        multiple
                                    />
                                    <div>
                                        <p>Drag & drop your files here</p>
                                        <p>OR</p>
                                    </div>
                                    <label
                                        [for]="elementId"
                                        class="mat-flat-button file-button"
                                    >
                                        Browse Files
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row flex" *ngIf="!isCaseRepository || !existing">
                        <div class="full">
                            <div class="header">
                                <p class="uploaded-files">Uploaded Files</p>
                            </div>
                            <div class="body">
                                <div class="row flex" *ngFor="let item of queue">
                                    <div class="uploaded-file-card">
                                        <mat-icon class="icon folder">
                                            folder_outline
                                        </mat-icon>
                                        <div class="flex stack card-content">
                                            <div class="file-details">
                                                <span class="file-name">
                                                    {{ item.file.name }}
                                                </span>
                                                <span *ngIf="item.progress !== 100">
                                                    {{ item.progress }}%
                                                </span>
                                            </div>
                                            <div
                                                class="progress-bar"
                                                *ngIf="item.progress !== 100"
                                            >
                                                <span
                                                    class="bar"
                                                    [style]="
                                                        'width: ' + item.progress + '%'
                                                    "
                                                ></span>
                                            </div>
                                            <span
                                                class="file-size"
                                                *ngIf="item.progress == 100"
                                            >
                                                {{ item.file.size | fileSize }}
                                            </span>
                                        </div>
                                        <div class="icons-container">
                                            <button
                                                *ngIf="
                                                    item.progress !== 100 ||
                                                    (item.isError && !item.isSuccess)
                                                "
                                                mat-button
                                                class="cancel icon"
                                                (click)="removeQueueItem($event, item)"
                                            >
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                            <mat-icon
                                                *ngIf="
                                                    item.progress == 100 &&
                                                    item.isSuccess
                                                "
                                            >
                                                done
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row flex" *ngIf="isCaseRepository || bulkMode">
                    <mat-radio-button [value]="true" [checked]="existing || !uploader">
                        Copy existing documents
                    </mat-radio-button>
                </div>
                <mat-checkbox
                    *ngIf="showShareOption"
                    (change)="shareDocuments($event)"
                    [formControl]="shareCheckbox"
                    form
                >
                    {{ shareOptionText }}
                </mat-checkbox>
                <div
                    class="flex stack"
                    *ngIf="(isCaseRepository || bulkMode) && existing"
                >
                    <div class="copy-document-list-container">
                        <div
                            class="row flex bold info not-found"
                            *ngIf="!availableDocuments.length"
                        >
                            No available documents found.
                        </div>
                        <div
                            class="uploaded-file-card"
                            *ngFor="let doc of availableDocuments; let i = index"
                            formArrayName="copy"
                        >
                            <mat-icon class="icon folder">folder_outline</mat-icon>
                            <div class="flex stack card-content">
                                <div class="file-details">
                                    <span class="file-name">{{ doc.name }}</span>
                                </div>
                                <span class="file-size">{{ doc.size | fileSize }}</span>
                            </div>
                            <div class="column fixed icon-column">
                                <mat-checkbox [formControlName]="i"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <div form-actions class="row flex document-actions mat-dialog-actions">
        <div class="spacer"></div>
        <button mat-flat-button type="button" class="fixed" mat-dialog-close>
            Cancel
        </button>
        <button
            mat-flat-button
            type="button"
            class="fixed"
            color="accent"
            *ngIf="!existing && !!uploader && !loading"
            [disabled]="!queue?.length || isUploading || !!editing"
            (click)="uploadDocuments()"
        >
            Add File{{ (queue?.length || 0) > 1 ? "s" : "" }}
        </button>
        <mat-spinner *ngIf="loading" mode="indeterminate" [diameter]="50"></mat-spinner>
        <button
            mat-flat-button
            type="button"
            class="fixed"
            color="accent"
            *ngIf="existing && !loading"
            [disabled]="!isValid"
            (click)="isValid && copyDocuments()"
        >
            Copy File{{ checkedDocuments.length > 1 ? "s" : "" }}
        </button>
    </div>
    <div
        form-extra
        class="wrapper absolute upload-dim flex stack"
        *ngIf="redactionsEnabled && isUploading"
    >
        <div class="spacer"></div>
        <div class="upload-message">
            Uploading {{ (queue?.length || 0) > 1 ? "files" : "file" }} and analyzing
            for protected health information.
        </div>
        <mat-progress-spinner
            class="upload-spinner"
            mode="indeterminate"
        ></mat-progress-spinner>
        <div class="upload-message small">
            Depending on the size of the uploaded
            {{ (queue?.length || 0) > 1 ? "files" : "file" }}, this could take a few
            minutes, please be patient.
        </div>
        <div class="spacer"></div>
    </div>
</object-form>
<ng-container *ngIf="mode != ObjectViewMode.Create">
    <div mat-dialog-title class="redaction-header">
        <div class="flexible flex stack">
            <div>{{ fullObject?.displayName }}</div>
            <div class="secondary small" *ngIf="!!fullObject?.alias">
                {{ fullObject?.name }}
            </div>
        </div>
        <mat-icon
            class="fixed error"
            *ngIf="needsRedaction"
            matTooltip="Document has potential PHI information and should be reviewed for redaction"
        >
            warning
        </mat-icon>
        <mat-icon
            class="fixed warning"
            *ngIf="!needsRedaction && redactionProcessing"
            matTooltip="Document is still being scanned for potential PHI.  Care should be taken when reviewing."
        >
            warning
        </mat-icon>
    </div>
    <mat-dialog-content class="document-view-content">
        <div class="document-image" *ngIf="isPDF && !!documentContents">
            <pdf-viewer
                *ngIf="documentContents"
                [src]="documentContents"
                [style.width]="pageWidth"
                [style.height]="pageHeight"
                [(page)]="page"
                [show-all]="false"
                [zoom]="zoom"
                (after-load-complete)="onPDFLoaded($event)"
            ></pdf-viewer>
        </div>
        <img
            [alt]="fullObject?.displayName"
            class="document-image"
            [src]="image"
            *ngIf="!!fullObject?.file_format?.startsWith('image/') && !!image"
        />
        <div class="document-loading" *ngIf="loading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <canvas
            [style.height]="pageHeight"
            [style.width]="pageWidth"
            class="redaction-canvas"
            [class.is-pdf]="isPDF"
            *ngIf="!loading && mode == ObjectViewMode.Edit"
            (contextmenu)="onRightClick($event)"
            (mousedown)="onMouseDown($event)"
            (mouseup)="onMouseUp($event)"
            (mouseleave)="onMouseLeave($event)"
            (mousemove)="onMouseMove($event)"
            (mouseenter)="onMouseEnter($event)"
        ></canvas>
        <div class="wrapper redaction-wrapper" [class.is-pdf]="isPDF" *ngIf="!loading">
            <div
                class="redacted"
                [class.view-only]="mode == ObjectViewMode.View"
                *ngFor="let redaction of currentRedactions; index as i"
                (click)="mode != ObjectViewMode.View && toggleRedaction(redaction)"
                [class.visible]="redactionIsVisible(redaction)"
                [ngStyle]="boundingBoxStyle(redactionBoundingBox(redaction))"
                [matTooltip]="redactionInfo(redaction)"
                (contextmenu)="onRightClick($event, redaction)"
            ></div>
            <div
                class="redacted-add"
                *ngIf="mode == ObjectViewMode.Edit && addedRedactionBox"
                [ngStyle]="boundingBoxStyle(addedRedactionBox)"
            ></div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-actions redaction-actions">
        <div
            class="threshold-slider flex stack"
            *ngIf="mode == ObjectViewMode.Edit && hasAutomatedRedactions"
        >
            <div>Redaction Threshold</div>
            <div class="flex slider">
                <mat-slider
                    class="full"
                    min="0"
                    max="1"
                    step="0.0005"
                    [displayWith]="formatThreshold"
                >
                    <input matSliderThumb [(ngModel)]="redactionThreshold" />
                </mat-slider>
                {{ formatThreshold(redactionThreshold) }}
            </div>
        </div>
        <div class="spacer"></div>
        <div
            class="page-controls flex"
            *ngIf="isPDF && !!documentContents && !!totalPages"
        >
            <button
                mat-icon-button
                [disabled]="totalPages < 2 || page === 1"
                (click)="totalPages > 1 && page !== 1 && firstPage()"
            >
                <mat-icon>first_page</mat-icon>
            </button>
            <button
                mat-icon-button
                [disabled]="page == 1"
                (click)="page > 1 && previousPage()"
            >
                <mat-icon>navigate_before</mat-icon>
            </button>
            <mat-form-field class="page-field compact">
                <mat-label>Page</mat-label>
                <mat-select [(ngModel)]="page" [ngModelOptions]="{ standalone: true }">
                    <mat-option *ngFor="let index of pageArray" [value]="index + 1">
                        {{ index + 1 }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button
                mat-icon-button
                [disabled]="page == totalPages"
                (click)="page != totalPages && nextPage()"
            >
                <mat-icon>navigate_next</mat-icon>
            </button>
            <button
                mat-icon-button
                [disabled]="totalPages < 2 || page == totalPages"
                (click)="totalPages > 1 && page !== totalPages && lastPage()"
            >
                <mat-icon>last_page</mat-icon>
            </button>
        </div>
        <div class="spacer"></div>
        <button
            mat-flat-button
            type="button"
            class="fixed"
            color="primary"
            mat-dialog-close
            *ngIf="!hasChanges"
        >
            Close
        </button>
        <button
            mat-flat-button
            type="button"
            class="fixed"
            *ngIf="hasChanges"
            (click)="handleCancel($event)"
        >
            Cancel
        </button>
        <button
            mat-flat-button
            type="button"
            class="fixed"
            color="accent"
            *ngIf="hasChanges"
            (click)="acceptRedactions()"
        >
            Accept Redactions
            {{
                phiDocumentsTotal > 1 ?
                    "(" +
                    (phiDocumentsTotal - phiDocuments.length + 1) +
                    "/" +
                    phiDocumentsTotal +
                    ")"
                :   ""
            }}
        </button>
    </mat-dialog-actions>

    <div
        class="right-click-menu"
        [style.left]="contextMenuPosition.x + 'px'"
        [style.top]="contextMenuPosition.y + 'px'"
        [matMenuTriggerFor]="rightMenu"
    ></div>
    <mat-menu #rightMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button
                mat-menu-item
                *ngIf="item && redactionIsVisible(item)"
                (click)="toggleRedaction(item)"
            >
                Redact Block
            </button>
            <button
                mat-menu-item
                *ngIf="item && !redactionIsVisible(item)"
                (click)="toggleRedaction(item)"
            >
                Unredact Block
            </button>
            <button mat-menu-item *ngIf="item" (click)="removeRedaction(item)">
                Remove Block
            </button>
            <button mat-menu-item (click)="removeAllRedactions()">
                Remove All Blocks
            </button>
            <button mat-menu-item (click)="resetRedactions()">Undo Changes</button>
        </ng-template>
    </mat-menu>
</ng-container>
<bdc-walk-popup
    #doctorTask7
    name="doctorTask7"
    yPosition="above"
    [mustCompleted]="{ doctorOnboardingWelcome: true, doctorTask2: true }"
    [closeOnClick]="true"
    [showCloseButton]="false"
    class="doctorTask7Popup"
>
    For now, just close this popup.
</bdc-walk-popup>
