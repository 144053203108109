import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "fileSize" })
export class FileSizePipe implements PipeTransform {
    private units = ["bytes", "kb", "mb", "gb", "tb", "pb"];

    transform(value?: number | string, precision: number = 2): string {
        if (value === undefined || !value) return "0 bytes";
        if (typeof value == "string") {
            value = parseInt(value);
        }
        let unit = 0;
        while (value >= 1024) {
            value /= 1024;
            unit++;
        }
        return value.toFixed(+precision) + " " + this.units[unit];
    }
}
