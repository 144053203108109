<div class="container" style="margin-bottom: 1rem">
    <div class="flex between team-header">
        <div class="title" [id]="list.listTitle">Shipments</div>
        <div>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createNewForm()"
                [disabled]="!organization || !dataForm || viewOnly"
            >
                <mat-icon>open_in_new</mat-icon>
                <span class="add-button-text">Add Shipment</span>
            </button>
            <mat-form-field color="primary">
                <mat-label>Search Shipments</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="legacyShipments.length">
        <h4>Legacy Shipments</h4>
        <mat-table [dataSource]="legacyShipments">
            <ng-container matColumnDef="shipment">
                <mat-header-cell *matHeaderCellDef>Shipment Date</mat-header-cell>
                <mat-cell *matCellDef="let shipment">
                    <span
                        *ngIf="shipment.ship_date"
                        [matTooltip]="
                            shipment.tracking_reference ?
                                shipment.tracking_reference
                            :   ''
                        "
                    >
                        {{ shipment.ship_date | localizedDate }}
                    </span>
                    <span *ngIf="!shipment.ship_date" class="secondary">
                        Not Shipped
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                <mat-cell *matCellDef="let shipment">
                    {{ shipment.total_quantity }} {{ shipment.total_unit }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="received">
                <mat-header-cell *matHeaderCellDef>Received Date</mat-header-cell>
                <mat-cell *matCellDef="let shipment">
                    <span *ngIf="shipment.received_date">
                        {{ shipment.received_date | localizedDate }}
                    </span>
                    <span *ngIf="!shipment.received_date" class="secondary">
                        Not Received
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
                <mat-cell *matCellDef="let shipment" class="overflow">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ shipment: shipment }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="legacyColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: legacyColumns"></mat-row>
            <mat-menu #overflow="matMenu">
                <ng-template matMenuContent let-shipment="shipment">
                    <button
                        mat-menu-item
                        (click)="editObject($event, shipment, true, true)"
                    >
                        View
                    </button>
                    <button
                        mat-menu-item
                        (click)="editObject($event, shipment, true)"
                        [disabled]="viewOnly"
                    >
                        Edit
                    </button>
                    <button
                        mat-menu-item
                        (click)="deleteShipment(shipment)"
                        [disabled]="viewOnly"
                    >
                        Delete
                    </button>
                </ng-template>
            </mat-menu>
        </mat-table>
    </div>
    <div
        *ngIf="newShipments.length || !(newShipments.length || legacyShipments.length)"
    >
        <h4 *ngIf="legacyShipments.length" style="margin-top: 40px">New Shipments</h4>

        <mat-table [dataSource]="newShipments" class="new-shipments-table">
            <ng-container matColumnDef="reference_identifier">
                <mat-header-cell *matHeaderCellDef>Shipment #</mat-header-cell>
                <mat-cell
                    *matCellDef="let shipment"
                    class="clickable"
                    (click)="editForm(shipment)"
                    (keydown)="onKeyPress($event)"
                    (keyup)="onKeyUp($event)"
                    (keypress)="onKeyDown($event)"
                >
                    {{ shipment.reference_identifier }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="form">
                <mat-header-cell *matHeaderCellDef>Form</mat-header-cell>
                <mat-cell
                    *matCellDef="let shipment"
                    (click)="editForm(shipment)"
                    (keydown)="onKeyPress($event)"
                    (keyup)="onKeyUp($event)"
                    (keypress)="onKeyDown($event)"
                >
                    {{ shipment.data_form.display_name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell
                    *matCellDef="let shipment"
                    (click)="editForm(shipment)"
                    (keydown)="onKeyPress($event)"
                    (keyup)="onKeyUp($event)"
                    (keypress)="onKeyDown($event)"
                >
                    <mat-chip-set>
                        <mat-chip
                            *ngIf="!isResupply(shipment)"
                            color="primary"
                            selected
                        >
                            Initial
                        </mat-chip>
                        <mat-chip *ngIf="isResupply(shipment)" color="accent" selected>
                            Resupply
                        </mat-chip>
                    </mat-chip-set>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef>Quantity Requested</mat-header-cell>
                <mat-cell
                    *matCellDef="let shipment"
                    (click)="editForm(shipment)"
                    (keydown)="onKeyPress($event)"
                    (keyup)="onKeyUp($event)"
                    (keypress)="onKeyDown($event)"
                >
                    {{ formatQuantity(shipment.data_form) || "Not Specified" }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>Created At</mat-header-cell>
                <mat-cell
                    *matCellDef="let shipment"
                    (click)="editForm(shipment)"
                    (keydown)="onKeyPress($event)"
                    (keyup)="onKeyUp($event)"
                    (keypress)="onKeyDown($event)"
                >
                    {{ shipment.created_at | localizedDate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
                <mat-cell *matCellDef="let shipment" class="overflow">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ shipment: shipment }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="no-records">
                <mat-footer-cell
                    *matFooterCellDef
                    [attr.colspan]="columns.length"
                    class="error not-found"
                >
                    <span *ngIf="list.hasLoaded">
                        No shipments have been requested yet!
                    </span>
                    <span *ngIf="!list.hasLoaded">
                        <mat-progress-spinner
                            diameter="20"
                            class="inline"
                            mode="indeterminate"
                        ></mat-progress-spinner>
                        Loading...
                    </span>
                </mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns"></mat-row>
            <mat-footer-row
                *matFooterRowDef="['no-records']"
                [class.hidden-footer]="
                    !!(newShipments.length || legacyShipments.length)
                "
            ></mat-footer-row>
            <mat-menu #overflow="matMenu">
                <ng-template matMenuContent let-shipment="shipment">
                    <button mat-menu-item (click)="viewForm(shipment.data_form)">
                        View
                    </button>
                    <button
                        mat-menu-item
                        (click)="editForm(shipment)"
                        [disabled]="viewOnly"
                    >
                        Edit
                    </button>
                    <button
                        mat-menu-item
                        (click)="deleteShipment(shipment)"
                        [disabled]="viewOnly"
                    >
                        Delete
                    </button>
                </ng-template>
            </mat-menu>
        </mat-table>
        <mat-paginator
            (page)="list.handlePageEvent($event)"
            [pageSizeOptions]="list.paginatorSizeOptions"
            [pageSize]="list.pageSize"
            [length]="list.paginatorLength"
            [disabled]="list.searching"
            [showFirstLastButtons]="true"
            [pageIndex]="list.currentPage"
        ></mat-paginator>
    </div>
</div>
