<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <mat-form-field class="full">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Role Name" formControlName="display_name" />
    </mat-form-field>
</object-form>
