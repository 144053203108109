<div class="notficationsRoot" *ngIf="sideNavOpened">
    <div class="flex stack">
        <div class="flex row menuHeader">
            <mat-icon class="icon">notifications_none</mat-icon>
            <p class="menuTitle">Notifications</p>
            <div class="readAll">
                <button mat-flat-button color="accent" (click)="markAllAsRead($event)">
                    Mark all as Read
                </button>
            </div>
        </div>
        <div class="flex row">
            <form [formGroup]="unreadGroup" class="showUnread">
                <mat-slide-toggle 
                    type="checkbox" 
                    (change)="updateShowUnread()" 
                    [formControl]="unreadGroup.controls.showUnread"
                    ngDefaultControl
                >
                Only Show Unread
                </mat-slide-toggle>
            </form>
        </div>
    </div>

    <div class="notficationsContainer" (scroll)="handleScroll($event)">
        <div *ngFor="let notification of list.items; index as i">
            <div
                class="notificationItem"
                (click)="handleItemClick($event, notification)"
            >
                <div class="flex stack">
                    <mat-icon class="icon" fontSet="material-icons-outlined">
                        {{ getIcon(notification) }}
                    </mat-icon>
                </div>

                <div>
                    @if (isObjectReference(notification)) {
                        <div referenceWrapper [primary]="true"></div>
                    } @else {
                        <p
                            class="notificationTitle"
                            [matTooltip]="
                                toolTipForNotification(asObject(notification))
                            "
                        >
                            <span
                                [matBadge]="badgeForMessage(asObject(notification))"
                                matBadgeColor="accent"
                                [matBadgeOverlap]="false"
                                style="padding-right: 0.25rem"
                            >
                                {{ asObject(notification).message }}
                            </span>
                        </p>

                        <mat-chip
                            [color]="getChipColor(asObject(notification))"
                            highlighted
                            [matTooltip]="
                                localizeDate(asObject(notification).created_at)
                            "
                        >
                            {{ asObject(notification).created_at | relativeDate }}
                        </mat-chip>
                    }

                    <div class="notificationMessage">
                        @if (isObjectReference(asObject(notification))) {
                            <div referenceWrapper [primary]="true"></div>
                            <div referenceWrapper [primary]="true"></div>
                            <div referenceWrapper [primary]="true"></div>
                        } @else {
                            <p *ngIf="asObject(notification).detailMessage">
                                {{ asObject(notification).detailMessage }}
                            </p>
                        }
                    </div>
                </div>
            </div>
        </div>

        <mat-spinner
            *ngIf="list.searching && canHaveMore"
            mode="indeterminate"
            [diameter]="50"
        ></mat-spinner>

        <div class="notificationItem" *ngIf="!canHaveMore">
            <p>You are all caught up !</p>
            <mat-icon>celebration_outline</mat-icon>
        </div>
    </div>
</div>
