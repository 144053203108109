import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { DataFieldValue, DataForm, DataFormField } from "src/services/models/data";
import { Program } from "src/services/models/program";
import { ObjectViewMode } from "../object.component";
import {
    ObjectFactory,
    ObjectOrReference,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import { forkJoin } from "rxjs";
import { defined } from "src/common/utilities/flatten";

@Component({
    selector: "data-form-field",
    template: `
        <div class="flex data-field-container">
            <h4 class="column label fixed">{{ hidePrompt ? "" : prompt }}</h4>
            <div class="column flex flexible data-form-content">
                <data-type
                    class="flexible"
                    [label]="label"
                    [mode]="mode"
                    [form]="form"
                    [type]="_field?.field?.data_type"
                    [inline]="inline"
                    [control]="control"
                    [multiple]="isMultiple"
                    [attributes]="_field?.attributes"
                    [options]="options"
                    [required]="!allowPartial && !!_field?.required"
                ></data-type>
            </div>
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormFieldComponent {
    protected _field?: DataFormField;
    protected _value: DataFieldValue[] = [];

    @Input() set field(v: OptionalObjectOrReference<DataFormField>) {
        ObjectFactory.objectObservable(v).subscribe((f: DataFormField | undefined) => {
            this._field = f;
        });
    }
    @Input() set value(v: ObjectOrReference<DataFieldValue>[]) {
        forkJoin(
            v.map((tv: ObjectOrReference<DataFieldValue>) =>
                ObjectFactory.objectObservable(tv),
            ),
        ).subscribe((v: (DataFieldValue | undefined)[]) => (this._value = defined(v)));
    }
    @Input() mode: ObjectViewMode = ObjectViewMode.View;
    @Input() control?: AbstractControl;
    @Input() allowPartial: boolean = false;
    @Input() form?: DataForm;

    @Input() productOptions?: ObjectOrReference<Program>[];

    _label?: string;
    @Input() set label(v: string | undefined) {
        this._label = v;
    }

    get prompt(): string | undefined {
        return this._field?.attributes?.prompt || this.displayName;
    }
    get hidePrompt(): boolean {
        return this._field?.attributes?.hidePrompt || this.isInstructions;
    }
    get displayName(): string | undefined {
        return this._field?.displayName;
    }
    get label(): string | undefined {
        return (
            this._label ??
            this._field?.prompt ??
            this._field?.attributes?.label ??
            this._field?.field.display_name
        );
    }
    get inline(): boolean {
        return (
            !!this._field?.field?.data_type?.isCompound &&
            this._field.attributes?.layout?.compound == "inline"
        );
    }
    get isInstructions(): boolean {
        return this._field?.field?.data_type?.name == "instructions";
    }
    get isCompound(): boolean {
        return this._field?.field?.data_type?.isCompound ?? false;
    }
    get isMultiple(): boolean {
        return this._field?.multiple ?? false;
    }
    get options(): any[] {
        return this._field?.field?.data_type?.lookup ?? this.productOptions ?? [];
    }
}
