import { DataForm } from "./data";
import { APIObject, ObjectReference } from "./api-object";
import { Program } from "./program";

export class IntakeSettings extends APIObject {
    owner_id!: string;
    owner_type!: string;
    hcp?: DataForm;
    patient?: DataForm;
    family?: DataForm;
    allow_patient!: boolean;
    allow_family!: boolean;

    initialize(data?: any, patch: boolean = false): void {
        this.setMember(data, patch, "owner_id");
        this.setMember(data, patch, "owner_type");
        this.setMember(data, patch, "hcp", DataForm);
        this.setMember(data, patch, "patient", DataForm);
        this.setMember(data, patch, "family", DataForm);
        this.setMember(data, patch, "allow_patient");
        this.setMember(data, patch, "allow_family");
    }
}

export class Intake extends APIObject {
    type: string = "";
    organization!: ObjectReference; // The organization that owns this intake
    programs!: Program[]; // The programs available for this intake config
    logo?: string; // read-only, optional - URL of the logo file
    url?: string; // read-only, optional - URL to return to on logo click
    forms!: IntakeSettings;

    initialize(data?: any, patch: boolean = false) {
        this._readOnly.push(...["logo", "url"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "organization", ObjectReference);
        this.setMember(data, patch, "programs", Program, true);
        this.setMember(data, patch, "logo");
        this.setMember(data, patch, "url");
        this.setMember(data, patch, "forms", IntakeSettings);
    }
}
