<authenticated-frame
    (notificationClick)="sideNav?.toggle()"
    [notificationCount]="notificationCount"
>
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav
            cdkScrollable
            #sidenav
            mode="over"
            position="end"
            (scroll)="handleNotificationScroll($event)"
        >
            <appnotification-drawer
                [sideNavOpened]="!!sideNav?.opened"
                (notificationCount)="notificationCount = $event"
                (closeDrawer)="sideNav?.close()"
            ></appnotification-drawer>
        </mat-sidenav>
        <mat-sidenav-content>
            <content-stack></content-stack>
        </mat-sidenav-content>
    </mat-sidenav-container>
</authenticated-frame>
