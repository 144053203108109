import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, User as Auth0User } from "@auth0/auth0-angular";
import {
    Observable,
    Subject,
    Subscription,
    catchError,
    delay,
    map,
    mergeMap,
    of,
    share,
    take,
    tap,
} from "rxjs";
import { EnvironmentService } from "src/app/environment.service";
import { AppInjector } from "src/common/utilities/injector";
import {
    RequestFilter,
    formDataFromObject,
    queryStringFromFilters,
} from "src/common/utilities/request";
import { generateCode, generateCodeChallenge } from "src/common/utilities/utilities";

type StoredToken = {
    at: string;
    ex: number;
    rt: string;
};

export interface AuthenticationState {
    next: string;
    params: string;
}

@Injectable()
export class AuthenticationService implements OnDestroy {
    readonly http: HttpClient;
    readonly router: Router;
    readonly environment: EnvironmentService;
    protected auth0?: AuthService;

    protected _token: Subject<string | undefined> = new Subject<string | undefined>();
    protected _tokenRequest?: Observable<string | undefined>;
    protected _refreshSubscription?: Subscription;

    constructor() {
        this.http = inject(HttpClient);
        this.router = inject(Router);
        this.environment = inject(EnvironmentService);
    }

    get isAuth0Client(): boolean {
        return this.environment.auth0Client != "";
    }

    ngOnDestroy(): void {
        this._refreshSubscription?.unsubscribe(); // clean up our subscription if one exists
    }

    loadAuth0Library(): void {
        if (this.isAuth0Client && !this.auth0) {
            console.log("Loading Auth0 Library...");
            this.auth0 = AppInjector.get(AuthService); // get the auth0 client (it's not available in the constructor
        }
    }

    authorize(next?: string): void {
        // create a state based on the proposed uri and query string
        const state: AuthenticationState = {
            next: next ?? "/",
            params: window.location.search ?? "",
        };
        if (this.isAuth0Client) {
            this.loadAuth0Library();
            // for Auth0 clients, just ship it off to them
            this.auth0?.loginWithRedirect({
                authorizationParams: {
                    scope: "openid profile email user_metadata",
                },
            });
        } else {
            // For local auth clients, build an OAuth2 authorization message
            const code_verifier = generateCode(16);
            const code_challenge = generateCodeChallenge(code_verifier);
            const redirect = [window.location.origin, "login-auth"].join("/");
            const encoded_state = btoa(JSON.stringify(state));
            const filters: RequestFilter = {
                client_id: this.environment.authorizationClient,
                response_type: "code",
                redirect_uri: redirect,
                code_challenge: code_challenge,
                code_challenge_method: "S256",
                state: encoded_state,
            };
            const endpoint =
                [
                    this.environment.services,
                    this.environment.authorizationEndpoint,
                ].join("/") + queryStringFromFilters(filters);
            localStorage.setItem("cv", code_verifier);
            window.location.href = endpoint;
        }
    }
    unauthorize(next?: string): void {
        localStorage.removeItem("st"); // clear any stored token
        this._refreshSubscription?.unsubscribe();
        this._refreshSubscription = undefined; // clear any subscription
        if (this.isAuth0Client) {
            // if it's an auth0 client, make sure we log out there as well
            this.loadAuth0Library();
            this.auth0?.logout({
                logoutParams: {
                    returnTo:
                        next ? window.location.origin + next : window.location.origin,
                },
            });
        } else {
            this.router.navigate(["/login"]);
        }
    }

    token(code?: string): Observable<string | undefined> {
        // return the next token that is emitted on the _token subject
        return this._token.pipe(
            tap({
                subscribe: () => this.checkToken(code), // when we subscribe initiate a token request
            }),
            take(1),
        );
    }

    protected checkToken(code?: string): void {
        if (!this._tokenRequest) {
            if (this.isAuth0Client) {
                this.loadAuth0Library();
                this.tokenRequest(this.getAuth0Token());
                // if it's an auth0 client, grab the token from the auth0 client
            } else if (code) this.tokenRequest(this.swapAuthorizationCode(code));
            // if we've got an authorization code, swap it for the 2nd half of the oauth process
            else this.tokenRequest(this.checkStoredToken()); // check if we've got a stored token, or request a local one
        }
    }
    protected tokenRequest(request: Observable<string | undefined>): void {
        this._tokenRequest = request; // store the request, while it's in flight, so we don't get duplicates
        this._tokenRequest.subscribe((token: string | undefined) => {
            // make the request
            this._tokenRequest = undefined; // clear our stored request, so we can do it again
            this._token.next(token); // emit our token
        });
    }

    protected getAuth0Token(): Observable<string | undefined> {
        return (
            this.auth0?.user$.pipe(
                mergeMap((user: Auth0User | null | undefined) => {
                    if (user) {
                        return this.auth0!.getAccessTokenSilently({
                            authorizationParams: {
                                scope: "openid profile email user_metadata",
                                audience: this.environment.auth0Audience,
                            },
                        });
                    }
                    return of(undefined);
                }),
            ) ?? of(undefined)
        );
    }
    protected swapAuthorizationCode(code?: string): Observable<string | undefined> {
        const code_verifier = localStorage.getItem("cv");
        localStorage.removeItem("cv");
        const request = {
            client_id: this.environment.authorizationClient,
            grant_type: "authorization_code",
            redirect_uri: [window.location.origin, "login-auth"].join("/"),
            code: code,
            code_verifier: code_verifier,
            scope: "read write",
        };
        return this.localTokenRequest(request);
    }
    protected checkStoredToken(): Observable<string | undefined> {
        const storedToken = localStorage.getItem("st");
        let token = undefined;
        try {
            token = storedToken ? JSON.parse(storedToken) : undefined; // old tokens weren't stored obfuscated with base64
        } catch {
            try {
                const decodedToken = storedToken ? atob(storedToken) : undefined; // newer tokens need to be deobfuscated
                token = decodedToken ? JSON.parse(decodedToken) : undefined;
            } catch {}
        }
        if (token) {
            const now = Math.round(new Date().getTime() / 1000);
            const tokenExpired = token.ex - now < 0;
            return tokenExpired ?
                    this.refresh(token.rt) // if we're expired, grab a refresh token
                :   of(token.at).pipe(delay(10)); // 10ms delay gets us out of the current execution cycle so the token appears in the subject
        }
        return of(undefined).pipe(delay(10));
    }
    protected refresh(refresh_token?: string): Observable<string | undefined> {
        if (refresh_token) {
            const request = {
                client_id: this.environment.authorizationClient,
                grant_type: "refresh_token",
                refresh_token: refresh_token,
            };
            return this.localTokenRequest(request);
        }
        return of(undefined);
    }

    protected localTokenRequest(request: any): Observable<string | undefined> {
        const body = formDataFromObject(request);
        const headers = new HttpHeaders().set(
            "Content-Type",
            "application/x-www-form-urlencoded",
        );
        const endpoint = [
            this.environment.services,
            this.environment.tokenEndpoint,
        ].join("/");
        return this.http.post(endpoint, body, { headers: headers }).pipe(
            share(),
            map((response: any) => this.localTokenResponse(response)),
            catchError(() => of(undefined)),
        );
    }
    protected localTokenResponse(response: any): string | undefined {
        const seconds = Number(response.expires_in) || 1800;
        const expires = Math.round(new Date().getTime() / 1000) + seconds;
        const newToken: StoredToken = {
            at: response.access_token,
            ex: expires,
            rt: response.refresh_token,
        };
        const jsonToken = JSON.stringify(newToken);
        const encodedToken = btoa(jsonToken);
        localStorage.setItem("st", encodedToken);
        this.scheduleTokenRefresh(response.refresh_token, seconds);
        return newToken.at;
    }
    protected scheduleTokenRefresh(
        refresh_token: string | undefined,
        seconds: number,
    ): void {
        this._refreshSubscription?.unsubscribe();
        this._refreshSubscription = of(refresh_token)
            .pipe(
                delay((seconds - 60) * 1000), // go a minute early
            )
            .subscribe((refresh_token: string | undefined) => {
                const request = this.refresh(refresh_token);
                this.tokenRequest(request);
            });
    }
}
