import {
    ChangeDetectorRef,
    Component,
    Input,
    QueryList,
    ViewChildren,
    ViewContainerRef,
    inject,
} from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import {
    faBuilding,
    faPenFancy,
    faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import {
    DocusignEnvelopeService,
    DocusignService,
} from "src/services/docusign.service";
import { Assignment, AssignmentReference } from "src/services/models/assignment";
import {
    AssignmentService,
    CaseService,
    InquiryService,
    StatusService,
} from "src/services/program.services";
import { DataFormService } from "src/services/data.services";
import { Case, Status } from "src/services/models/case";
import { ObjectViewMode } from "src/common/components/object.component";
import { AssignmentComponent } from "./assignment.component";
import { CaseComponent } from "../case/case.component";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { CASE_TAB_NAMES, TabChangeService } from "src/services/component.services";
import { SendTemplateDialog } from "src/common/components/template/send-template.dialog";
import { DocumentRepository } from "src/services/models/document";
import { TASK_TYPE } from "src/services/models/task";
import { DocumentTabChangeMetadata } from "src/program/components/document-repository/document-repository.component";
import { TeamMember } from "src/services/models/team";
import { Message } from "src/services/models/message";
import { ConfirmDialog, ConfirmDialogData } from "src/common/components/confirm.dialog";
import { DataForm } from "src/services/models/data";
import {
    APIListResult,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
    PaginatedList,
} from "src/services/models/api-object";
import { Organization } from "src/services/models/organization";
import { BdcWalkService } from "third-party/bdc-walkthrough/src/public-api";
import { DelegateAssignmentDialog } from "./delegate-assignment.dialog";
import { AppNotification } from "src/services/models/appNotification";
import { AppNotificationService } from "src/services/notification.services";
import { RoleService } from "src/services/iam.services";
import { LocalizedDatePipe } from "src/common/utilities/localized-date.pipe";
import { RequestFilter, WebsocketObjectAction } from "src/common/utilities/request";
import { isDialogOpen } from "src/common/utilities/utilities";
import { FrequencyAssignmentDialog } from "./frequency-assignment.dialog";
import { RelatedObjectEvent } from "src/services/api.service";
import { DocusignEnvelope } from "src/services/models/docusign";

export type TabChangeObjectMetaData = DocumentTabChangeMetadata & {
    tabName?: CASE_TAB_NAMES;
    references: { [key: string]: any };
};

@Component({
    selector: "task-table",
    templateUrl: "task-table.component.html",
    styleUrls: ["./task-table.component.scss"],
})
export class TaskTableComponent extends ObjectAdminComponent<Assignment> {
    @Input() viewOnly = false;
    @Input() isNested = false;
    @Input() set case(ref: OptionalObjectOrReference<Case>) {
        this.caseService.resolveReference(ref).subscribe((c?: Case) => {
            this._case = c;

            const assignees = this._case?.teamTab(this.currentAccount!) || [];
            this.availableAssignees = assignees;
        });
    }
    setCaseAssignmentSub() {
        // this will only listen to Assignment created events under the group for the case

        this.caseService.relatedObjectEvent
            .pipe(
                filter(
                    (event: RelatedObjectEvent<any, Case>) =>
                        event.relation?.id == this.case?.id &&
                        event.relation?.type == this.case?.type &&
                        event.relatedObject?.type == Assignment.object_type &&
                        event.relatedObject?.parent?.id == this._group?.id &&
                        event.action === WebsocketObjectAction.CREATE,
                ),
            )
            .subscribe((e) => {
                const existing = this.list.items.find(
                    (a) => a.id == e.relatedObject?.id,
                );
                if (!existing) {
                    this.list.items.push(e.relatedObject);
                }
            });
    }
    availableAssignees: TeamMember[] = [];

    get case() {
        return this._case;
    }
    @Input() set group(ref: OptionalObjectOrReference<Assignment>) {
        this.service.resolveReference(ref).subscribe((group?: Assignment) => {
            this._group = group;
            this.updateAssignments();
        });
    }
    @Input() isDashboard: boolean = false;
    @Input() notifications: AppNotification[] = [];
    @Input() firstUncompletedTask?: Assignment;

    protected walkthroughService: BdcWalkService;
    protected dataFormService: DataFormService;
    protected caseService: CaseService;
    protected statusService: StatusService;
    protected inquiryService: InquiryService;
    protected docusignService: DocusignService;
    protected roleService: RoleService;

    protected _group?: Assignment;
    protected _case?: Case;

    removedSummaryTask: { [key: string]: Assignment } = {};

    get organization(): OptionalObjectOrReference<Organization> {
        return this.case instanceof Case ? this.case.shared.organization : undefined;
    }
    get isCaseClosed() {
        return this.case instanceof Case && this.case.isCaseClosed;
    }
    get isPhysician(): boolean {
        return (
            this.case instanceof Case &&
            !!this.case?.isPhysicianStaff(this.currentAccount)
        );
    }
    get isCaseOwner(): boolean {
        const owner =
            this.case instanceof Case ?
                this.case?.teamMember("pharma", "owner")
            :   undefined;
        return !!this.currentAccount && owner?.account.id == this.currentAccount?.id;
    }
    get walkthroughEnabled(): boolean {
        return false;
    }
    appNotificationService: AppNotificationService;
    tabChangeService: TabChangeService;
    datePipe: LocalizedDatePipe;
    constructor(
        protected service: AssignmentService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, -1);
        this.caseService = inject(CaseService);
        this.walkthroughService = inject(BdcWalkService);
        this.dataFormService = inject(DataFormService);
        this.statusService = inject(StatusService);
        this.inquiryService = inject(InquiryService);
        this.docusignService = inject(DocusignService);
        this.appNotificationService = inject(AppNotificationService);
        this.roleService = inject(RoleService);
        this.tabChangeService = inject(TabChangeService);

        const translateService = inject(TranslateService);
        inject(DocusignEnvelopeService);
        this.datePipe = new LocalizedDatePipe(translateService);
    }

    protected override filter(filters: RequestFilter): RequestFilter {
        filters["workflow"] = this.case?.id ?? "0";
        filters["parent"] = this._group?.id ?? "0";
        filters["ordering"] = this.isNested ? "-created_at" : "order";

        return filters;
    }

    summarizeAssignments(assignments: Assignment[]): Assignment[] {
        const _case = this.case instanceof Case ? this.case : undefined;
        const summarize = _case?.isMemberOfTeam(this.currentAccount, "provider");
        const removedIds: string[] = [];
        assignments
            .filter((a: Assignment) => a.task.taskType == "summary")
            .forEach((summary: Assignment) => {
                if (summarize) {
                    summary.references
                        ?.filter(
                            (ref: AssignmentReference) => ref.item_type == "summary",
                        )
                        .forEach((ref: AssignmentReference) => {
                            if (ref.reference?.id !== undefined)
                                removedIds.push(ref.reference.id);
                        });
                } else if (summary.id !== undefined) {
                    removedIds.push(summary.id);
                    if (summary.parent?.id) {
                        this.removedSummaryTask[summary.parent.id] = summary;
                    }
                }
            });
        const summarized = assignments.filter(
            (a: Assignment) => removedIds.indexOf(a.id ?? "0") == -1,
        );
        return summarized;
    }

    protected override postSearch(items: Assignment[]): Assignment[] {
        items = super.postSearch(items);
        items = this.summarizeAssignments(items);
        this.checkPendingTasksForms(items);
        return items;
    }
    protected updateAssignments(): void {
        this.updateList(null); // must be null to force a new search as opposed to undefined
    }

    isPlaceholder(assignment: ObjectOrReference<Assignment>): boolean {
        return assignment instanceof ObjectReference || this.list.searching;
    }
    asAssignment(assignment: ObjectOrReference<Assignment>): Assignment {
        return assignment as Assignment;
    }

    checkPendingTasksForms(assignments: ObjectOrReference<Assignment>[]) {
        const pendingFormAssignments = assignments
            .filter(
                (a: ObjectOrReference<Assignment>) =>
                    a instanceof Assignment &&
                    (a.task.taskType === TASK_TYPE.DATA ||
                        a.task.taskType === TASK_TYPE.DATA_REVIEW) &&
                    a.pending,
            )
            .map((a: ObjectOrReference<Assignment>) => a as Assignment);

        pendingFormAssignments.forEach((assignment: Assignment) => {
            const references = assignment.references;

            const formReference = references?.find(
                (aRef) =>
                    aRef.item_type === "form" &&
                    aRef.reference?.type === "program.dataform",
            );

            if (
                assignment instanceof Assignment &&
                formReference?.reference?.id &&
                this.canCompleteTask(assignment)
            ) {
                this.dataFormService
                    .retrieve(formReference.reference.id)
                    .subscribe((form: DataForm | undefined) => {
                        const formIsValid = form?.is_complete && form?.values.length;
                        const assignmentPending =
                            assignment.pending && !assignment.completed;
                        const canComplete =
                            formIsValid &&
                            assignmentPending &&
                            !form?.attributes?.task_reopened;
                        if (canComplete) {
                            // TODO: Complete assignment
                        }
                    });
            }
        });
    }
    protected resolveReferences(
        list: PaginatedList<Assignment>,
    ): PaginatedList<Assignment> {
        // resolve docusign envelope references
        list.items.forEach((assignment: ObjectOrReference<Assignment>) => {
            if (assignment instanceof Assignment) {
                assignment.references
                    ?.filter(
                        (ref: AssignmentReference) =>
                            ref.reference && ref.item_type == "docusign.envelope",
                    )
                    ?.forEach((ref: AssignmentReference) => {
                        if (ref.reference instanceof ObjectReference) {
                            ObjectFactory.objectObservable<DocusignEnvelope>(
                                ref.reference,
                            ).subscribe(
                                (env: DocusignEnvelope | undefined) =>
                                    (ref.reference = env),
                            );
                        }
                    });
            }
        });
        return super.resolveReferences(list);
    }

    handleClick(e: MouseEvent, assignment: ObjectOrReference<Assignment>) {
        this.terminateEvent(e); //prevent checkbox from flipping
        if (assignment instanceof Assignment && assignment?.completed) return;
        this.completeTaskWithConfirmation(assignment);
    }

    caseNameForTask(task: ObjectOrReference<Assignment>): string {
        return (task instanceof Assignment ? task.case_name : undefined) ?? "";
    }

    isDocusignTask(task: ObjectOrReference<Assignment>): boolean {
        return (
            task instanceof Assignment &&
            task.task.taskType == TASK_TYPE.DOCUSIGN_SIGNATURE &&
            this.docusignService.isDocusignEnabled
        );
    }

    showAssignment(task: ObjectOrReference<Assignment>, mode?: ObjectViewMode): void {
        if (this.case && this.case instanceof Case && task instanceof Assignment) {
            const instance = AssignmentComponent.showAssignment(
                task,
                this.case,
                this.service,
                this.currentAccount,
                mode,
            );
            instance.parentAssignments = [this.group as Assignment];
        }
    }

    canEditTask(task: ObjectOrReference<Assignment>): boolean {
        if (task instanceof ObjectReference) return false;
        const taskEditable = !(this.isCaseClosed || !!task.completed);
        const hasPermission =
            this.case instanceof Case &&
            !!this.case?.isPharmaStaff(this.currentAccount) &&
            (this.isCaseOwner || !!this.case?.isEditor(this.currentAccount));
        return taskEditable && hasPermission && !this.viewOnly;
    }

    editTask(event: MouseEvent, task: ObjectOrReference<Assignment>): void {
        if (task instanceof Assignment) this.showAssignment(task, ObjectViewMode.Edit);
    }

    canViewTask(task: ObjectOrReference<Assignment>): boolean {
        return task instanceof Assignment;
    }

    viewTask(event: MouseEvent, task: ObjectOrReference<Assignment>): void {
        if (task instanceof Assignment) this.showAssignment(task, ObjectViewMode.View);
    }

    canCompleteTask(task: ObjectOrReference<Assignment>): boolean {
        if (task instanceof ObjectReference) return false;
        const canEdit = this.canEditTask(task);
        const isAssigned = task.assignee?.id == this.currentAccount?.id;
        const isPending = task.pending;

        if (this.viewOnly) {
            return isAssigned && isPending;
        } else {
            return (canEdit || isAssigned) && isPending;
        }
    }
    isAssignee(task: ObjectOrReference<Assignment>): boolean {
        return (
            task instanceof Assignment && task.assignee?.id === this.currentAccount?.id
        );
    }
    showTask(event: MouseEvent, task: ObjectOrReference<Assignment>): void {
        if (
            task instanceof Assignment &&
            this.isDashboard &&
            task.workflow?.type == "program.case"
        ) {
            this.caseService
                .retrieve(task.workflow.id!)
                .subscribe((c: Case | undefined) => {
                    if (c) {
                        const instance = ObjectAdminComponent.showObject<Case>(
                            c,
                            CaseComponent,
                            ObjectViewMode.Edit,
                        ) as CaseComponent;
                        instance.showTask(task);
                    } else {
                        // Display a message that the case was not found
                    }
                });
        } else if (
            task instanceof Assignment &&
            this.isDashboard &&
            task.workflow?.type == "program.inquiry"
        ) {
            this.caseService
                .list({
                    access: this.currentAccount?.id ?? "0",
                    inquiry: task.workflow?.id ?? "0",
                })
                .subscribe((list: APIListResult<Case>) => {
                    const cases = list as Case[];
                    if (cases.length) {
                        const instance = ObjectAdminComponent.showObject<Case>(
                            cases[0],
                            CaseComponent,
                            ObjectViewMode.Edit,
                        ) as CaseComponent;
                        instance.showTask(task);
                    }
                });
        } else if (task instanceof Assignment && this.case) {
            this.showAssignment(task);
        }
    }

    identify(_: number, item: ObjectOrReference<Assignment>) {
        return `${item.id}:${!!(item as Assignment).completed}`;
    }

    completeTaskWithConfirmation(assignment: ObjectOrReference<Assignment>): void {
        if (assignment instanceof ObjectReference) return;
        //view only can only complete a task IF they are assigned to it
        if (this.viewOnly && assignment?.assignee?.id !== this.currentAccount?.id)
            return;
        let data: ConfirmDialogData;
        if (assignment.completed) {
            data = {
                title: `Reopen Task Confirmation`,
                message: `Are you sure you want to reopen this previously completed task? If you select reopen you will have to complete this task again.`,
                ok: "Reopen",
            };
        } else {
            data = {
                title: `Complete Task Confirmation: ${assignment.displayName}`,
                message: `Are you sure you want to mark ${assignment.displayName} completed?`,
                ok: "Complete",
            };
        }
        if (!isDialogOpen(this.dialog, ConfirmDialog)) {
            this.dialog
                .open(ConfirmDialog, {
                    disableClose: true,
                    data,
                })
                .afterClosed()
                .subscribe((confirm: boolean) => {
                    if (confirm) {
                        if (assignment.completed) {
                            assignment.completed = undefined;
                            assignment.completed_by = undefined;
                        } else {
                            assignment.completed = new Date();
                            assignment.completed_by = this.currentAccount;
                        }

                        this.service.update(assignment).subscribe();
                    }
                });
        }
    }
    canReopenTask(task: ObjectOrReference<Assignment>): boolean {
        if (task instanceof ObjectReference) return false;

        return !!task.completed && this.canEditTask(task) && !this.isNested;
    }

    canDelegateTask(task: ObjectOrReference<Assignment>): boolean {
        if (task instanceof ObjectReference) return false;
        const isAssigned = task.assignee?.id == this.currentAccount?.id;
        return (!this.viewOnly && !task.completed) || (isAssigned && !this.viewOnly);
    }
    assigneeIsCurrentUser(assignment: ObjectOrReference<Assignment>) {
        return (
            assignment instanceof Assignment &&
            assignment?.assignee?.id === this?.currentAccount?.id
        );
    }
    delegateTask(event: MouseEvent, assignment: ObjectOrReference<Assignment>): void {
        if (assignment instanceof Assignment) {
            const newTask = ObjectFactory.makeObject<Assignment>(
                assignment,
                Assignment.object_type,
            );

            if (!isDialogOpen(this.dialog, DelegateAssignmentDialog)) {
                if (newTask && newTask instanceof Assignment) {
                    newTask.assigned_by = this.currentAccount?.asReference;
                    this.dialog.open(DelegateAssignmentDialog, {
                        data: {
                            object: newTask,
                            case: this.case,
                            caseTeam: (this.case as Case)?.caseTeam(
                                this.currentAccount,
                            ),
                        },
                        minWidth: "50%",
                        disableClose: true,
                        hasBackdrop: true,
                    });
                }
            }
        }
    }

    onActionLinkClick(assignment: ObjectOrReference<Assignment>, event?: MouseEvent) {
        if (!this.taskEnabled(assignment)) {
            event?.preventDefault();
            return;
        }

        if (event) this.terminateEvent(event);
        if (!(assignment instanceof Assignment)) return;
        if (!(this.case instanceof Case)) return;
        if (this.viewOnly && assignment?.assignee?.id !== this.currentAccount?.id) {
            return;
        }
        const { tabName, ...metadata } = assignment.taskTypeToTabGroupRelation;
        const references = assignment?.references;

        if (assignment.task?.taskType?.startsWith("message.")) {
            const organization = assignment.task.owner ?? this.case?.owner;
            const owners =
                this?.case?.isPhysicianStaff(this.currentAccount) ?
                    [this.case?.owner.id]
                :   [this?.case?.shared?.program?.id, this.case?.owner.id];
            let sources: DocumentRepository[] = [];
            if (this.currentAccount)
                sources = [...sources, this.currentAccount.asReference];
            if (organization) sources = [...sources, organization];

            const subject = references?.find((r) => {
                return r?.item_type === "message.title";
            })?.item_data;

            const template = references?.find((r) => {
                return r?.item_type === "notifications.template";
            })?.reference;

            const physician = this.case?.teamMember("provider", "physician");
            let contacts =
                this.case?.shared
                    ?.teamMembers()
                    .filter((tm: TeamMember) => !tm.private) ?? [];
            // add back private members on teams the current user exists in
            for (const team of this.case?.shared?.teams ?? []) {
                if (
                    this.case?.shared?.isMemberOfTeam(
                        this.currentAccount,
                        team.capacity,
                    )
                )
                    contacts = contacts.concat(
                        team.members.filter((tm: TeamMember) => tm.private),
                    );
            }
            // remove currentAccount from contacts
            contacts = contacts.filter(
                (tm: TeamMember) => tm.account.id != this.currentAccount?.id,
            );
            contacts = [
                ...new Map(
                    contacts.map((contact: TeamMember) => [
                        contact.account?.id,
                        contact,
                    ]),
                ).values(),
            ];
            const allowInvite =
                assignment.task?.task_type!.startsWith("message.invite");

            if (!isDialogOpen(this.dialog, SendTemplateDialog)) {
                this.dialog
                    .open(SendTemplateDialog, {
                        data: {
                            to: physician,
                            subject: subject,
                            owner: owners,
                            context: this.case?.data,
                            reference: this.case?.asReference,
                            allowInvite,
                            sources: sources,
                            repository: organization,
                            uploadOwner: organization,
                            contacts: contacts,
                            assignment,
                            template,
                        },
                        minWidth: 600,
                        disableClose: true,
                        hasBackdrop: true,
                    })
                    .afterClosed()
                    .subscribe((msg: Message) => {
                        if (allowInvite && this.case instanceof Case)
                            CaseComponent.HandleMessageInvitePermissions(
                                msg,
                                this.roleService,
                                this.case,
                            );
                    });
            }
        } else if (assignment?.task?.taskType === TASK_TYPE.CLOSE_CASE) {
            const { availableStatuses, defaultStatuses } = this.statusService;
            const statuses = (
                availableStatuses.length ? availableStatuses : defaultStatuses).filter(
                (s: Status) => s.attributes.closes_case,
            );

            this.inquiryService
                .close(this.case.shared, false, undefined, statuses)
                .subscribe(() => {});
        } else {
            references?.forEach((ref: AssignmentReference) => {
                if (!metadata.references) metadata.references = {};
                metadata.references[ref.item_type] = {
                    item_data: ref.item_data,
                    reference: ref.reference,
                };
            });
            metadata.assignment = assignment;
            metadata.viewOnly = this.viewOnly;

            if (tabName) {
                this.tabChangeService.changeTab(tabName, metadata);
            }
        }
    }

    taskHasAction(task: ObjectOrReference<Assignment>): boolean {
        return (
            task instanceof Assignment &&
            !(
                task.task?.taskType?.includes(TASK_TYPE.SUMMARY) ||
                task.task?.taskType?.includes(TASK_TYPE.GENERIC) ||
                (task.task?.taskType === TASK_TYPE.DOCUSIGN_SIGNATURE &&
                    this.docusignService.isDocusignEnabled) ||
                this.isCaseClosed
            )
        );
    }

    getTaskAction(task: ObjectOrReference<Assignment>): string {
        // Check here whether the task type actually has an action.  If so, pull the first word from the name and use that as the action
        // If not, return empty string
        // For now, we're going to assume all tasks have an action

        const displayName = task.displayName;
        if (!this.taskHasAction(task)) {
            return "";
        }
        const parts = displayName?.split(" ") ?? [""];
        return parts[0];
    }

    getTaskTitle(task: ObjectOrReference<Assignment>): string {
        // If the task has an action, return all but the first word for the name, otherwise, return this full displayName
        // For now, we're going to assume all tasks have an action
        const displayName = task.displayName ?? task?.name;
        if (!this.taskHasAction(task)) {
            return displayName ?? "";
        }
        const parts = displayName?.split(" ") ?? ["", ""];
        parts.shift();

        return parts.join(" ");
    }

    taskEnabled(task: ObjectOrReference<Assignment>): boolean {
        return (
            task instanceof Assignment &&
            task.pending &&
            task.assignee?.id === this.currentAccount?.id &&
            //                 || !!this.case?.isOrganizationAdministrator(this.currentAccount) || !!this.case?.isProgramAdministrator(this.currentAccount)
            !this.isDocusignTask(task)
        );
    }
    taskEnabledOrCompleted(task: ObjectOrReference<Assignment>): boolean {
        return (
            task instanceof Assignment && (this.taskEnabled(task) || !!task.completed)
        );
    }

    isPhysicianTask(task: ObjectOrReference<Assignment>): boolean {
        return (
            task instanceof Assignment &&
            this.case instanceof Case &&
            !!this.case?.isPhysicianStaff(task.assignee)
        );
    }
    isPharmaTask(task: ObjectOrReference<Assignment>): boolean {
        return (
            task instanceof Assignment &&
            this.case instanceof Case &&
            !!this.case?.isPharmaStaff(task.assignee)
        );
    }
    isSummaryTask(task: ObjectOrReference<Assignment>): boolean {
        return task instanceof Assignment && task.task.taskType == TASK_TYPE.SUMMARY;
    }
    taskIcon(task: ObjectOrReference<Assignment>): any {
        if (this.isDocusignTask(task)) return faPenFancy;
        if (this.isSummaryTask(task)) return faBuilding;
        if (this.isPhysicianTask(task)) return faUserDoctor;
        if (this.isPharmaTask(task)) return faBuilding;
        return undefined;
    }

    sendReminder(e: MouseEvent, assignment: ObjectOrReference<Assignment>) {
        this.terminateEvent(e);

        this.service.remind(assignment, this.case!).subscribe(() => {
            this.snackbar.open(
                "Reminder sent successfully! Check the communications tab for more details",
                undefined,
                { duration: 5000 },
            );
        });
    }

    hasNotification(assignment: ObjectOrReference<Assignment>): boolean {
        const notification = this.getNotificationForAssignment(assignment);

        return !!notification;
    }
    matBadge(assignment: ObjectOrReference<Assignment>) {
        const hasNotification = this.hasNotification(assignment);
        if (!hasNotification) return undefined;
        return "!";
    }
    getNotificationForAssignment(assignment: ObjectOrReference<Assignment>) {
        return this?.notifications?.find((n) => n.object.id === assignment?.id);
    }

    markNotificationAsRead(assignment: ObjectOrReference<Assignment>) {
        const notification = this.getNotificationForAssignment(assignment);
        if (!notification) return;

        this.notifications = this.notifications.filter((n) => n.id !== notification.id);
        this.appNotificationService
            .clear(notification)
            .subscribe({ error: (e) => console.log(e) });
    }

    assignmentHasFrequency(assignment: ObjectOrReference<Assignment>) {
        return (
            assignment instanceof Assignment &&
            !!assignment.frequency_amount &&
            !!assignment.frequency_unit
        );
    }

    frequencyValue(assignment: Assignment) {
        let frequencyDisplayValue = "One Time";
        if (this.assignmentHasFrequency(assignment)) {
            frequencyDisplayValue = `Every ${assignment.frequency_amount} ${assignment.frequency_unit}`;
            if (assignment.frequency_amount && assignment.frequency_amount > 1) {
                frequencyDisplayValue += "s";
            }
        }
        return frequencyDisplayValue;
    }

    frequencySubTitle(assignment: Assignment) {
        let frequencySubTitle =
            this.assignmentHasFrequency(assignment) ? "Forever" : "";
        if (assignment.frequency_end_date) {
            frequencySubTitle =
                assignment.frequency_end_date < new Date() ? "Ended on " : "Until ";
            frequencySubTitle += `${this.datePipe.transform(
                assignment.frequency_end_date,
                "shortDate",
            )}`;
        }
        return frequencySubTitle;
    }

    dueByColumnValue(assignment: Assignment) {
        if (assignment.due_date) {
            return this.datePipe.transform(assignment.due_date, "shortDate");
        } else if (assignment.dependencies.length) {
            const unCompleted = assignment.dependencies
                .map((a) => this.asObject(a))
                .filter((a) => !a.completed);
            return `${unCompleted.length} Tasks`;
        }

        return undefined;
    }

    statusDetails(assignment: Assignment): { icon: string; value: string } {
        if (assignment.completed) {
            return { icon: "check_circle", value: "Completed" };
        } else if (assignment.pending) {
            return { icon: "schedule", value: "Pending" };
        }

        return { icon: "block_outline", value: "Blocked" };
    }

    assignmentCompleted(assignment: Assignment) {
        return !!assignment.completed;
    }
    statusTooltip(assignment: Assignment) {
        if (assignment?.completed_by?.name) {
            return `Completed By ${assignment?.completed_by?.name} on ${this.datePipe.transform(assignment.completed, "shortDate")}`;
        } else if (assignment?.completed) {
            return `Completed on ${this.datePipe.transform(assignment.completed, "shortDate")}`;
        }
        return "";
    }

    hanldeAssigneeChange(event: MatSelectChange, assignment: Assignment) {
        assignment.assignee = (event.value as Assignment).asReference;
        this.service.update(assignment).subscribe();
    }

    editFrequency(
        assignment: Assignment,
        triggerEdit: boolean = false,
        dialogTitle = "Task Frequency",
    ) {
        if (!this.canEditTask(assignment) || (!!assignment.completed && triggerEdit))
            return;

        if (!isDialogOpen(this.dialog, FrequencyAssignmentDialog)) {
            this.service
                .list({ workflow: this.case!.id! })
                .pipe(
                    map(
                        (list: APIListResult<Assignment>) =>
                            list as ObjectOrReference<Assignment>[],
                    ),
                )
                .subscribe((otherAssignments) => {
                    this.dialog.open(FrequencyAssignmentDialog, {
                        data: {
                            object: assignment,
                            triggerEdit,
                            dialogTitle,
                            otherAssignments,
                        },
                        minWidth: "50%",
                        disableClose: true,
                    });
                });
        }
    }

    requiresReauthorization(assignment: Assignment): boolean {
        if (assignment.task.taskType === TASK_TYPE.DOCUSIGN_SIGNATURE) {
            const envelopeReference = assignment.references?.find(
                (ref) => ref.item_type == "docusign.envelope",
            );
            if (
                envelopeReference?.reference &&
                envelopeReference.reference instanceof DocusignEnvelope
            ) {
                const envelope = envelopeReference.reference;
                return envelope.requires_reauthorization ?? false;
            }
        }
        return false;
    }

    @ViewChildren("tableRow", { read: ViewContainerRef })
    rowContainers: QueryList<ViewContainerRef> = new QueryList<ViewContainerRef>();
    get displayedColumns() {
        return ["task", "status", "due_date", "frequency", "assignee", "actions"];
    }

    expandedRow: Assignment | null = null;
    toggleFrequencyTable(index: number, assignment: Assignment, event?: MouseEvent) {
        const clickedRow = this.rowContainers.find(
            (r) => r.element.nativeElement.id === assignment.id,
        );
        if (!clickedRow) return;

        if (event) {
            this.terminateEvent(event);
        }

        if (this.expandedRow != null) {
            clickedRow.clear();
        }

        if (this.expandedRow?.id === assignment.id) {
            this.expandedRow = null;
        } else {
            const inlineComponent = clickedRow.createComponent(TaskTableComponent);
            inlineComponent.instance.group = assignment;
            inlineComponent.instance.isNested = true;
            inlineComponent.instance.case = this.case;
            this.expandedRow = assignment;
        }
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}
}
