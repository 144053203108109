import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Injector,
    NgModuleRef,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewRef,
} from "@angular/core";
import { APIObject } from "../../../services/models/api-object";
import { MatMenuTrigger } from "@angular/material/menu";

export interface TabProperties {
    type: Type<any> | undefined;
    breadcrumb?: string;
    title?: string;
    subtitle?: string;
    label?: string;
    tooltip?: string;
    sticky?: boolean;
    icon?: any;
    styles?: string[];

    // callbacks
    hasChanges?: (tab: Tab) => boolean;
    beforeClose?: (tab: Tab) => boolean; // return False to prevent close
    activated?: (tab: Tab) => void;
    closed?: (tab: Tab) => void;
}
export interface Tab extends TabProperties {
    content?: ComponentRef<any>;
    viewRef?: ViewRef;
}

export let tabFrame: TabFrameComponent | undefined;

@Component({
    selector: "tab-container",
    template: `
        <ng-content></ng-content>
    `,
    host: { class: "scrollable" },
})
export class TabContainer {}

@Component({
    selector: "tab-frame",
    templateUrl: "./tab-frame.component.html",
    styleUrls: ["./tab-frame.component.scss"],
})
export class TabFrameComponent implements AfterViewInit, OnDestroy {
    @ViewChild(MatMenuTrigger) protected contextMenu?: MatMenuTrigger;
    @ViewChild("tabContentTemplate", { read: ViewContainerRef })
    protected contentContainer?: ViewContainerRef;

    contextMenuPosition = { x: "0px", y: "0px" };
    tabs_: Tab[] = [];
    tabStack: Tab[] = [];
    selected_?: Tab;

    constructor(protected changeDetection: ChangeDetectorRef) {
        tabFrame = this;
    }
    ngOnDestroy(): void {
        if (tabFrame == this) tabFrame = undefined;
    }

    get tabs(): Tab[] {
        return this.tabs_;
    }
    set tabs(v: Tab[]) {
        this.tabs_ = v;
    }
    get selectedTab(): Tab | undefined {
        return this.selected_;
    }
    get currentTab(): Tab | undefined {
        return this.tabStack.length ? this.tabStack[0] : undefined;
    }

    removeTab(tab: Tab): boolean {
        if (
            tab.sticky ||
            (this.hasChanges(tab) && tab.beforeClose && !tab.beforeClose(tab))
        )
            return false;
        if (tab.viewRef) {
            tab.viewRef.destroy();
            tab.content?.destroy();
            tab.viewRef = undefined;
            tab.content = undefined;
        }
        if (tab.closed) tab.closed(tab);
        this.tabs = this.tabs.filter((t: Tab) => t !== tab);
        return true;
    }
    closeTab(tab?: Tab): boolean {
        if (tab && this.tabs.find((t: Tab) => t === tab)) {
            const selected = this.selectedTab === tab;
            if (tab.sticky || !this.removeTab(tab)) return false;
            tab.content = undefined;
            if (selected) this.selectTab();
        }
        return true;
    }
    closeOtherTabs(tab?: Tab): boolean {
        return this.tabs
            .filter((t: Tab) => !t.sticky && t !== tab)
            .map((t: Tab) => this.closeTab(t))
            .reduce((prev: boolean, current: boolean) => prev || current);
    }
    closeAllTabs(): boolean {
        return this.closeOtherTabs();
    }
    selectTab(tab?: Tab): void {
        if (this.tabStack.length) {
            if (!this.selectCrumb(undefined, -1)) return;
        }
        if (tab && !this.tabs.find((t: Tab) => t === tab))
            this.tabs = [...this.tabs, tab];
        if (!tab && this.tabs.length) tab = this.tabs[0];
        this.selected_ = tab;
        this.tabStack = [];
        this.showTab(this.selectedTab);
    }
    showTab(tab?: Tab, addToStack: boolean = true): void {
        if (this.contentContainer && tab) {
            let view: ViewRef | undefined;
            this.contentContainer.detach();
            if (tab?.viewRef && !tab.viewRef.destroyed) view = tab.viewRef;
            else if (tab?.content) view = tab.content.hostView;
            if (view) {
                tab.viewRef = this.contentContainer.insert(view);
                if (tab.activated) tab.activated(tab);
            }
        } else this.tabStack = [];
        if (tab && addToStack) this.tabStack = [tab, ...this.tabStack];
    }
    hasChanges(tab: Tab) {
        return !!tab.hasChanges && tab.hasChanges(tab);
    }
    exists(tab: Tab): boolean {
        return !!this.tabs.find((t: Tab) => t === tab);
    }

    iconIsMaterial(tab: Tab): boolean {
        return typeof tab.icon === "string";
    }
    iconIsAwesome(tab: Tab): boolean {
        return typeof tab.icon !== "string";
    }

    addTab(tab: Tab): void {
        if (tab.type) {
            tab.content = this.createComponentTab(tab.type);
            if (tab.content?.location?.nativeElement?.classList)
                tab.content.location.nativeElement.classList.add(
                    "wrapper",
                    "absolute",
                    ...(tab.styles ?? []),
                );
        }
        this.tabs = [...this.tabs, tab];
    }
    createComponentTab<T>(
        type: Type<T>,
        injector?: Injector,
        nodes?: any[][],
        module?: NgModuleRef<any>,
    ): ComponentRef<T> | undefined {
        injector = injector ?? this.contentContainer?.injector;
        const componentOptions = {
            injector: injector,
            ngModuleRef: module,
            projectableNodes: nodes,
        };
        const component = this.contentContainer?.createComponent(
            type,
            componentOptions,
        );
        if (component) this.contentContainer?.detach(0);
        return component;
    }

    showContextMenu(event: MouseEvent, tab: Tab): void {
        event.preventDefault();
        if (this.contextMenu) {
            this.contextMenuPosition.x = event.clientX + "px";
            this.contextMenuPosition.y = event.clientY + "px";
            this.contextMenu.menuData = { tab: tab };
            this.contextMenu.menu?.focusFirstItem("mouse");
            this.contextMenu.openMenu();
        }
    }
    closeContextMenu(event: MouseEvent): void {
        this.contextMenu?.closeMenu();
    }

    ngAfterViewInit() {
        this.selectTab();
        this.changeDetection.detectChanges();
    }
    faIcon(tab: Tab): any {
        return tab.icon;
    }

    get breadcrumbs(): Tab[] {
        return this.tabStack.slice(1).reverse();
    }
    popTab(): Tab | undefined {
        let tab = this.tabStack.shift();
        if (tab && (tab.sticky || !this.removeTab(tab))) {
            this.tabStack.unshift(tab);
            tab = undefined;
        }
        return tab;
    }
    selectCrumb(event?: MouseEvent, index: number = 0): boolean {
        event?.preventDefault();
        event?.stopPropagation();
        let popCount = this.tabStack.length - index - 1;
        let ret = true;
        while (popCount > 0 && this.tabStack.length) {
            const tab = this.tabStack.shift();
            if (tab && !tab.sticky && !this.removeTab(tab)) {
                ret = false;
                break;
            }
            popCount--;
        }
        if (ret && this.tabStack.length) this.showTab(this.tabStack[0], false);
        return ret;
    }
    popObjectTabs(object: APIObject): void {
        const newStack = this.tabStack.filter(
            (t: Tab) => t.sticky ?? t.content?.instance?.object?.id != object.id,
        );
        if (newStack.length != this.tabStack.length) {
            this.tabStack = newStack;
            if (this.tabStack.length) this.showTab(this.tabStack[0], false);
        }
    }
}
