<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <mat-form-field class="full">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Case Status Name" formControlName="display_name" />
    </mat-form-field>
    <ng-container
        *ngIf="fullObject?.attributes?.is_inquiry_status"
        formGroupName="attributes"
    >
        <div class="container">
            <mat-checkbox
                [disabled]="!canBeDefault"
                formControlName="is_default_inquiry_status"
            >
                Default Status
            </mat-checkbox>
        </div>
        <div class="container">
            <mat-checkbox [disabled]="!canCloseCase" formControlName="closes_case">
                Closes Case
            </mat-checkbox>
        </div>
    </ng-container>
</object-form>
