import { AfterViewInit, Component, Input, ViewChild, inject } from "@angular/core";
import { FileItem } from "ng2-file-upload";
import { mergeMap, of } from "rxjs";
import { FileUploaderCustom } from "src/common/utilities/FileUploaderCustom";
import { DocumentComponent } from "src/program/components/document-repository/document.component";
import { Account } from "src/services/models/account";
import { APIListResult, ObjectReference } from "src/services/models/api-object";
import { Case } from "src/services/models/case";
import {
    Document,
    DocumentOwner,
    DocumentRepository,
    DocumentTypeDefinition,
} from "src/services/models/document";
import { Inquiry } from "src/services/models/inquiry";
import { DocumentFileItem, DocumentService } from "src/services/program.services";
import { SessionService } from "src/services/session.service";
import { ConfirmDialog } from "../../confirm.dialog";
import { ObjectAdminComponent } from "../../object-admin.component";
import { ObjectViewMode } from "../../object.component";
import { BaseDataTypeComponent } from "./data-type.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";

export type FormDocument = Document | ObjectReference | FileItem;
@Component({
    selector: "dt-document",
    template: `
        <mat-table [dataSource]="documents" *ngIf="documents.length" matSort>
            <ng-container matColumnDef="alias_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    File Name
                </mat-header-cell>
                <mat-cell *matCellDef="let doc">
                    <ng-container *ngIf="editing != doc && isDocument(doc)">
                        <mat-icon
                            *ngIf="needsRedaction(doc)"
                            (click)="documentClicked($event, doc)"
                            class="error warning-space"
                            matTooltip="Potential unredacted PHI detected"
                        >
                            warning
                        </mat-icon>
                        <mat-icon
                            *ngIf="!needsRedaction(doc) && redactionProcessing(doc)"
                            (click)="documentClicked($event, doc)"
                            class="warning warning-space"
                            matTooltip="Document being processed for potential PHI"
                        >
                            warning
                        </mat-icon>
                        <div class="flex stack">
                            <div
                                class="clickable"
                                (click)="documentClicked($event, doc)"
                            >
                                {{ doc.displayName }}
                            </div>
                            <div *ngIf="!!doc.alias" class="secondary super-small">
                                {{ doc.name }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="editing != doc && isFileItem(doc)">
                        <div>{{ doc.file.name }}</div>
                        <div *ngIf="!!doc.name_alias" class="secondary super-small">
                            {{ doc.name_alias }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="editing == doc">
                        <mat-form-field class="table-cell-input">
                            <mat-label>File Alias</mat-label>
                            <input
                                matInput
                                placeholder="File Alias"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="alias"
                            />
                            <mat-hint>{{ doc.name || doc.file?.name }}</mat-hint>
                        </mat-form-field>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="document_type">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Type
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let doc"
                    (click)="
                        !editing && canEditDocument(doc) && editDocument($event, doc)
                    "
                    [class.pointer]="canEditDocument(doc)"
                >
                    <span
                        *ngIf="editing != doc && (doc.file_type || doc.document_type)"
                    >
                        {{ documentTypeDisplay(doc.file_type || doc.document_type) }}
                    </span>
                    <span
                        *ngIf="editing != doc && !doc.file_type && !doc.document_type"
                        class="secondary"
                    >
                        Not Set
                    </span>
                    <mat-form-field *ngIf="editing == doc" class="table-cell-input">
                        <mat-label>Document Type</mat-label>
                        <mat-select
                            placeholder="Document Type"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="documentType"
                        >
                            <mat-optgroup
                                *ngFor="let group of documentTypeGroups()"
                                [label]="group || 'Other'"
                            >
                                <mat-option
                                    *ngFor="let type of documentTypesForGroup(group)"
                                    [value]="type.type"
                                >
                                    {{ type.displayName }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="source">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Uploaded by
                </mat-header-cell>
                <mat-cell *matCellDef="let doc">
                    <div class="stack">
                        <div *ngIf="isDocument(doc)">
                            {{ doc.uploaded_by?.displayName }}
                        </div>
                        <div *ngIf="isFileItem(doc)">
                            {{ currentAccount?.displayName }}
                        </div>
                        <div
                            class="secondary super-small"
                            *ngIf="
                                isDocument(doc) && doc.owner?.id !== doc.uploaded_by?.id
                            "
                        >
                            {{ doc.owner?.displayName }}
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="uploaded_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="date-column">
                    Date
                </mat-header-cell>
                <mat-cell *matCellDef="let doc" class="date-column">
                    <ng-container *ngIf="isDocument(doc)">
                        {{
                            isToday(doc.uploaded_at) ? "New" : (
                                (doc.uploaded_at | localizedDate)
                            )
                        }}
                    </ng-container>
                    <ng-container *ngIf="isFileItem(doc)">Pending Upload</ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
                <mat-cell *matCellDef="let doc" class="overflow">
                    <button
                        mat-icon-button
                        type="button"
                        *ngIf="editing !== doc"
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ doc: doc }"
                        [disabled]="!!editing"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        type="button"
                        *ngIf="editing == doc"
                        class="green"
                        (click)="saveDocument($event, doc)"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            <mat-menu #overflow="matMenu">
                <ng-template matMenuContent let-doc="doc">
                    <button
                        mat-menu-item
                        type="button"
                        [class.error]="canEditDocument(doc)"
                        *ngIf="needsRedaction(doc)"
                        (click)="
                            canEditDocument(doc) &&
                                needsRedaction(doc) &&
                                editObject($event, doc, true)
                        "
                        [disabled]="!canEditDocument(doc)"
                    >
                        <mat-icon class="warning-space">warning</mat-icon>
                        Needs Redaction
                    </button>
                    <button
                        mat-menu-item
                        type="button"
                        (click)="canViewDocument(doc) && viewDocument($event, doc)"
                        [disabled]="!canViewDocument(doc)"
                    >
                        View
                    </button>
                    <button
                        mat-menu-item
                        *ngIf="shouldShowRerunRedaction(doc)"
                        (click)="rerunRedaction($event, doc)"
                        [disabled]="!canEditDocument(doc)"
                    >
                        Rerun Auto Redaction
                    </button>
                    <button
                        mat-menu-item
                        type="button"
                        (click)="canEditDocument(doc) && editObject($event, doc, true)"
                        *ngIf="!needsRedaction(doc) && isRedactable(doc)"
                        [disabled]="!canEditDocument(doc)"
                    >
                        Manual Redaction
                    </button>
                    <button
                        mat-menu-item
                        type="button"
                        (click)="canEditDocument(doc) && editDocument($event, doc)"
                        [disabled]="!canEditDocument(doc)"
                    >
                        Edit Details
                    </button>
                    <button
                        mat-menu-item
                        type="button"
                        (click)="isDocument(doc) && downloadDocument($event, doc)"
                        [disabled]="!isDocument(doc)"
                    >
                        Download
                    </button>
                    <button
                        mat-menu-item
                        type="button"
                        (click)="canEditDocument(doc) && removeDocument($event, doc)"
                        [disabled]="!canEditDocument(doc)"
                    >
                        Remove
                    </button>
                </ng-template>
            </mat-menu>
        </mat-table>
        <div
            *ngIf="!viewOnly && (multiple || !documents.length)"
            ng2FileDrop
            class="drop-zone"
            [class.over]="fileOver"
            (fileOver)="fileOver = $event"
            [uploader]="uploader"
        >
            <div class="flex stack center drop-items">
                <mat-icon class="upload-icon">cloud_upload</mat-icon>
                <input
                    type="file"
                    ng2FileSelect
                    [id]="elementId"
                    [uploader]="uploader"
                    hidden
                    multiple
                />
                <div>
                    <p>
                        Drag & drop your file
                        <span *ngIf="multiple">s</span>
                        here
                    </p>
                    <p>OR</p>
                </div>
                <label [for]="elementId" class="mat-flat-button file-button">
                    Browse Files
                </label>
            </div>
        </div>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeDocument extends BaseDataTypeComponent implements AfterViewInit {
    elementId: string = Date.now().toString();
    fileOver: boolean = false;
    uploader!: FileUploaderCustom;
    service: DocumentService;
    dialog: MatDialog;
    session: SessionService;
    editing?: FormDocument;
    alias?: string;
    documentType?: string;

    @Input() owner!: DocumentOwner;
    @Input() repository!: DocumentRepository;
    @Input() sources: DocumentRepository[] = [];
    @Input() shared: boolean = true;
    @Input() multiple: boolean = false;

    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

    get displayedColumns(): string[] {
        return ["alias_name", "document_type", "source", "uploaded_at", "actions"];
    }
    get documents(): FormDocument[] {
        if (this.control?.value && Array.isArray(this.control.value)) {
            return [...this.control.value];
        }

        return [];
    }
    get currentAccount(): Account | undefined {
        return this.session.currentAccount;
    }
    get repo(): DocumentRepository {
        let repo = this.repository || this.form || this.currentAccount!;
        if (this.shared && repo instanceof Case) repo = repo?.shared;
        return repo;
    }
    get documentOwner(): DocumentOwner {
        return this.owner || this.currentAccount!;
    }

    constructor() {
        super();
        this.service = inject(DocumentService);
        this.session = inject(SessionService);
        this.dialog = inject(MatDialog);
    }

    ngAfterViewInit(): void {
        this.service
            .fileUploader(this.documentOwner, this.repo, this.currentAccount!)
            .subscribe((uploader?: FileUploaderCustom) => {
                if (uploader) {
                    this.uploader = uploader;
                    const onAfter = this.uploader.onAfterAddingFile;
                    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
                        (fileItem as DocumentFileItem)._uploader = this.uploader;
                        const docs = this.documents;
                        docs.push(fileItem);
                        this.control?.setValue(docs);
                        this.control.markAsTouched();
                        this.control.markAsDirty();
                        return onAfter(fileItem);
                    };
                }
            });
        // for existing values, get the document info from the server
        this.updateDocuments();
    }

    redactionProcessing(doc: FormDocument): boolean {
        return doc instanceof Document && !!doc.attributes?.redaction_processing;
    }
    needsRedaction(doc: FormDocument): boolean {
        return doc instanceof Document && !!doc.attributes?.detected_phi?.length;
    }
    documentClicked(event: MouseEvent, doc: FormDocument): void {
        this.cancelEdit();
        if (
            (this.needsRedaction(doc) || this.isRedactable(doc)) &&
            this.canEditDocument(doc) &&
            doc instanceof Document
        )
            this.editObject(event, doc, true);
        else if (this.canViewDocument(doc) && doc instanceof Document)
            this.viewObject(event, doc, true);
        else this.downloadDocument(event, doc);
    }
    cancelEdit(event?: MouseEvent): void {
        this.alias = undefined;
        this.documentType = undefined;
        this.editing = undefined;
    }
    isRedactable(doc: FormDocument): boolean {
        return (
            doc instanceof Document &&
            (doc.file_format == "application/pdf" ||
                !!doc.file_format?.startsWith("image/"))
        );
    }
    downloadDocument(event: MouseEvent, doc: FormDocument): void {
        this.trigger?.closeMenu();
        if (doc instanceof Document) this.service.download(doc);
    }
    editDocument(event: MouseEvent, doc: FormDocument): void {
        this.trigger?.closeMenu();
        if (this.isDocument(doc)) {
            this.alias = (doc as Document).alias;
            this.documentType = (doc as Document).file_type;
        } else if (this.isFileItem(doc)) {
            this.alias = (doc as DocumentFileItem).name_alias;
            this.documentType = (doc as DocumentFileItem).document_type;
        }
        this.editing = doc;
    }
    saveDocument(event: MouseEvent, doc: FormDocument): void {
        if (doc instanceof Document) {
            doc.alias = this.alias == "" ? undefined : this.alias;
            doc.file_type = this.documentType;
            this.service
                .update(doc)
                .subscribe((updated: Document | undefined) => doc.update(updated));
        } else if (doc instanceof FileItem) {
            (doc as DocumentFileItem).name_alias =
                this.alias == "" ? undefined : this.alias;
            (doc as DocumentFileItem).document_type = this.documentType;
        }
        this.alias = undefined;
        this.documentType = undefined;
        this.editing = undefined;
    }
    viewDocument(event: MouseEvent, doc: FormDocument): void {
        this.cancelEdit();
        this.trigger?.closeMenu();
        if (this.canViewDocument(doc) && doc instanceof Document)
            this.viewObject(event, doc, true);
    }
    removeDocument(event: MouseEvent, doc: FormDocument): void {
        this.cancelEdit();
        this.trigger?.closeMenu();
        let docs = this.documents;
        if (this.isFileItem(doc)) {
            (doc as FileItem).remove();
            docs = this.documents.filter((d: FormDocument) => d !== doc);
            this.control.setValue(docs);
        } else if (this.isDocument(doc)) {
            // also need to delete the document from the repo
            const document = doc as Document;
            this.dialog
                .open(ConfirmDialog, {
                    data: {
                        message:
                            "Are you sure you want to delete '" +
                            document.displayName +
                            "'?",
                    },
                    disableClose: true,
                    hasBackdrop: true,
                    minWidth: "50vw",
                })
                .afterClosed()
                .pipe(
                    mergeMap((confirm: boolean) => {
                        if (confirm) {
                            docs = this.documents.filter(
                                (d: FormDocument) => d !== doc,
                            );
                            this.control.setValue(docs);
                            return this.service.destroy(document);
                        }
                        return of(undefined);
                    }),
                )
                .subscribe();
        }
    }
    canViewDocument(doc: FormDocument): boolean /* NOSONAR */ {
        return (
            doc instanceof Document &&
            (doc.file_format == "application/pdf" ||
                !!doc.file_format?.startsWith("image/"))
        );
    }
    canEditDocument(doc: FormDocument): boolean {
        if (doc instanceof ObjectReference) return false;
        if (doc instanceof FileItem) return true;
        return (
            this.currentAccount?.id == doc.uploaded_by?.id || // The user uploaded the document
            this.currentAccount?.hasRole("object.admin", doc.owner) || // The user is an administrator for the document owner
            this.currentAccount?.hasRole("object.admin", this.repo) || // The user is an administrator for the repository
            (this.repo instanceof Inquiry && this.repo?.isAdmin(this.currentAccount)) || // The user is an administrator for the inquiry
            (this.repo instanceof Case && this.repo?.isAdmin(this.currentAccount)) || // The user is an administrator for the case
            this.currentAccount?.id == this.repo?.id || // This is the user's repository
            (this.repo instanceof Case &&
                this.currentAccount?.hasRole("object.edit", this.repo?.shared)) || //user has edit role on the inquiry
            (this.repo instanceof Case &&
                this.currentAccount?.hasRole("object.edit", this.repo)) || //user has edit role on the case
            false
        );
    }
    isDocumentShared(doc: FormDocument): boolean {
        if (!(doc instanceof Document)) return false;
        if (this.repo?.type == "program.case") {
            if (doc.repository?.type == "program.inquiry") return true;
        } else if (this.repo?.type === "program.inquiry") {
            return doc.repository?.id === this.repo?.id;
        }
        return false;
    }
    editObject(
        event: MouseEvent | undefined,
        object: Document,
        asDialog: boolean = false,
    ): DocumentComponent | undefined {
        let mode = ObjectViewMode.Create;
        if (this.viewOnly) mode = ObjectViewMode.View;
        else if (object.id) mode = ObjectViewMode.Edit;
        const instance = ObjectAdminComponent.showObject<Document>(
            object,
            DocumentComponent,
            mode,
            asDialog ? this.objectDialogConfiguration(object, mode) : undefined,
        ) as DocumentComponent;
        if (this.documentOwner && this.repo && !this.viewOnly) {
            instance.initializeUploader(this.documentOwner, this.repo, this.sources);
        }
        return instance;
    }
    viewObject(
        event: MouseEvent | undefined,
        object: Document,
        asDialog: boolean = false,
    ): DocumentComponent | undefined {
        return this.editObject(event, object, asDialog);
    }

    documentTypeDisplay(type: string): string {
        const documentType = Document.documentTypes.find(
            (dt: DocumentTypeDefinition) => dt.type == type,
        );
        return documentType?.displayName ?? "Unknown Type";
    }
    documentTypeGroups(): (string | undefined)[] {
        return Document.documentTypes
            .map((dt: DocumentTypeDefinition) => dt.group)
            .filter(
                (
                    value: string | undefined,
                    index: number,
                    list: (string | undefined)[],
                ) => list.indexOf(value) === index,
            );
    }
    documentTypesForGroup(group: string | undefined): DocumentTypeDefinition[] {
        return Document.documentTypes
            .filter((dt: DocumentTypeDefinition) => dt.group == group)
            .sort((a: DocumentTypeDefinition, b: DocumentTypeDefinition) => {
                if (a.displayName < b.displayName) return -1;
                if (a.displayName > b.displayName) return 1;
                return 0;
            });
    }
    documentTypes(): DocumentTypeDefinition[] {
        return Document.documentTypes;
    }

    isToday(d?: Date): boolean {
        const today = new Date();
        return d?.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
    }
    isDocument(doc: FormDocument): boolean {
        return doc instanceof Document;
    }
    isFileItem(doc: FormDocument): boolean {
        return doc instanceof FileItem;
    }
    isObjectReference(doc: FormDocument): boolean {
        return doc instanceof ObjectReference;
    }

    protected objectDialogConfiguration(
        object: Document,
        mode: ObjectViewMode,
    ): MatDialogConfig {
        let config: MatDialogConfig = {
            minWidth: 480,
            maxWidth: "90vw",
            maxHeight: "90vh",
            disableClose: true,
            hasBackdrop: true,
        };
        if (mode != ObjectViewMode.Create) {
            config = {
                ...(config || {}),
                maxWidth: "100%",
                maxHeight: "100%",
            };
        } else {
            config = {
                ...(config || {}),
                minWidth: "50%",
                disableClose: true,
                autoFocus: "dialog",
                hasBackdrop: true,
            };
        }
        return config;
    }

    protected updateDocuments(): void {
        if (!this.control.value) return;

        const docs =
            Array.isArray(this.control.value) ?
                this.control.value
            :   [this.control.value];
        let refs = docs.map((value: string) => {
            const parts = value.split(":");
            return parts[1];
        });
        if (!refs.length) return;
        this.service
            .list({ ids: refs.join(",") })
            .subscribe((docs: APIListResult<Document>) => this.control.setValue(docs));
    }

    rerunRedaction(event: MouseEvent, doc: Document): void {
        this.trigger?.closeMenu();

        if (this.canEditDocument(doc) && doc.id) {
            doc.attributes.detected_phi = [];
            doc.initial_redaction_completed = false;
            doc.attributes.redaction_processing = true;

            // Invoke the runRedaction method
            this.service.runRedaction([doc.id]).subscribe({
                next: () => {
                    if (doc.id) {
                        this.service.retrieve(doc.id);
                    }
                },
                error: (err) => {
                    console.error("Error re-running redaction", err);
                    doc.attributes.redaction_processing = false;
                },
            });
        }
    }

    shouldShowRerunRedaction(doc: Document): boolean {
        return (
            !!doc.initial_redaction_completed &&
            !this.needsRedaction(doc) &&
            !doc.attributes.redaction_processing
        );
    }
}
