import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild,
} from "@angular/core";
import { SearchableList } from "../utilities/searchable-list";
import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/overlay";
import { filter, map, pairwise } from "rxjs/operators";

interface ScrollPosition {
    sH: number;
    sT: number;
    cH: number;
}

@Component({
    selector: "scrolling-list",
    template: `
        <div #results class="scrolling-list-results" (resized)="onResized($event)">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            .scrolling-list-results {
                position: relative;
                flex: 1 1 auto;
            }
        `,
    ],
})
export class ScrollingListComponent implements AfterViewInit {
    @Input() list?: SearchableList<any>;
    @Input() scrollThreshold: number = 0.7;
    @Input() infiniteScroll: boolean = true;
    @ViewChild("results") results!: ElementRef;

    constructor(protected scrollDispatcher: ScrollDispatcher) {}

    get count(): number {
        return this.list?.count ?? 0;
    }

    ngAfterViewInit() {
        this.updateScrollWatcher();
        this.list?.listChanged.subscribe(() => this.checkListSize());
    }

    @HostListener("window:resize", ["$event"])
    onResized(event: any): void {
        this.checkListSize();
    }

    checkListSize(): void {
        if (this.infiniteScroll) {
            const count = this.scrollDispatcher
                .getAncestorScrollContainers(this.results)
                .map((scrollable: CdkScrollable) =>
                    this.isScrollPastThreshold(
                        this.scrollPosition(scrollable.getElementRef()),
                    ),
                )
                .filter((pastThreshold: boolean) => pastThreshold).length;
            if (count > 0 && this.list?.canAddPage) this.loadMore();
        }
    }
    loadMore(): void {
        this.list?.update();
    }

    protected scrollPosition(element?: ElementRef): ScrollPosition {
        const native = element?.nativeElement;
        return {
            sH: native?.scrollHeight,
            sT: native?.scrollTop,
            cH: native?.clientHeight,
        };
    }
    protected updateScrollWatcher() {
        if (this.results && this.scrollThreshold) {
            this.scrollDispatcher
                .ancestorScrolled(this.results)
                .pipe(
                    filter(
                        (scrollable: void | CdkScrollable) =>
                            scrollable instanceof CdkScrollable,
                    ),
                    map((scrollable: void | CdkScrollable) =>
                        this.scrollPosition(
                            (scrollable as CdkScrollable).getElementRef(),
                        ),
                    ),
                    pairwise(),
                    filter((positions: ScrollPosition[]) =>
                        ScrollingListComponent.isScrollingDown(positions),
                    ),
                    filter((positions: ScrollPosition[]) =>
                        this.isScrollPastThreshold(positions[1]),
                    ),
                    filter(() => !!this.list),
                )
                .subscribe(() => this.loadMore());
        }
    }
    protected static isScrollingDown(positions: ScrollPosition[]): boolean {
        return positions[0].sT < positions[1].sT;
    }
    protected isScrollPastThreshold(position: ScrollPosition): boolean {
        if (position.cH > 0) {
            const left = position.sH - (position.sT + position.cH);
            const percent = (position.cH - left) / position.cH;
            return percent > this.scrollThreshold;
        }
        return false;
    }
}
