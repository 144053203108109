import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { mergeMap, of } from "rxjs";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { ObjectViewMode } from "src/common/components/object.component";
import { SearchableListComponent } from "src/common/components/searchable-list.component";
import { ObjectReference } from "src/services/models/api-object";
import { Status } from "src/services/models/case";
import { StatusService } from "src/services/program.services";
import { CaseStatusComponent, StatusComponent } from "./status.component";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "status-admin",
    templateUrl: "./status-admin.component.html",
    styleUrls: ["./status.component.scss"],
})
export class StatusAdminComponent extends SearchableListComponent<Status> {
    objectView = StatusComponent;

    get displayedColumns(): string[] {
        return ["name", "default", "closes", "actions"];
    }

    @Input() displayName = "Statuses";

    private _owner: ObjectReference | undefined;
    get owner() {
        return this._owner;
    }
    @Input() set owner(o: ObjectReference | undefined) {
        this._owner = o;
        if (this.owner) {
            this.list.refresh();
        }
    }

    constructor(
        protected service: StatusService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
    }

    newObject(): Status | undefined {
        const obj = super.newObject();
        if (obj) {
            obj.owner = this._owner?.asReference!;
            obj.attributes = {} as Status["attributes"];
        }
        return obj;
    }

    editObject(
        event: MouseEvent | undefined,
        object: Status,
        asDialog?: boolean,
    ): StatusComponent | undefined {
        const instance: StatusComponent = super.editObject(
            event,
            object,
            asDialog,
        ) as StatusComponent;
        instance.caseStatuses = this.list.items;
        return instance;
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters.owner_id = this._owner?.id!;
        return filters;
    }

    protected objectDialogConfiguration(
        object: Status,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        if (mode != ObjectViewMode.Create) {
            return {
                ...config,
                width: "90%",
                minHeight: "75%",
            };
        }
        return config;
    }

    deleteStatus(status: Status) {
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        "Are you sure you want to delete this status? All the cases or inquiries that have this status will automatically be assigned 'No Status' as their new status.",
                },
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) => {
                    if (confirm) {
                        return this.service.destroy(status);
                    } else {
                        return of(null);
                    }
                }),
            )
            .subscribe((s) => {
                this.list.refresh();
            });
    }

    onSortChange(event: any): void {
        if (event.direction) {
            let field = event.active;
            if (field == "default") field = "attributes__is_default_inquiry_status";
            else if (field == "closes") field = "attributes__closes_case";
            this.list.ordering = [
                { field: field, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }
}

@Component({
    selector: "case-status-admin",
    templateUrl: "./status-admin.component.html",
    styleUrls: ["./status.component.scss"],
})
export class CaseStatusAdmin extends StatusAdminComponent {
    objectView = CaseStatusComponent;

    newObject(): Status | undefined {
        const obj = super.newObject();
        if (obj)
            obj.attributes = {
                is_inquiry_status: true,
                is_default_inquiry_status: false,
                closes_case: false,
            } as Status["attributes"];
        return obj;
    }
}
