import { Component } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";

@Component({
    selector: "dt-date",
    template: `
        <p *ngIf="viewOnly && !hasValues && compoundIndex == 0" class="secondary">
            Value not provided
        </p>
        <p *ngIf="viewOnly && hasValues">{{ displayValue | date: "mediumDate" }}</p>
        <mat-form-field class="column" [ngClass]="inputClass" *ngIf="!viewOnly">
            <mat-label>{{ label }}</mat-label>
            <input
                matInput
                [placeholder]="placeholder"
                [matDatepicker]="picker"
                [formControl]="control"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint *ngIf="!!instructions">
                <quill-view-html [content]="instructions"></quill-view-html>
            </mat-hint>

            <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
        </mat-form-field>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeDate extends BaseDataTypeComponent {
    protected getErrorMessage(): string | undefined {
        const msg = super.getErrorMessage();
        if (msg) return msg;
        else if (this.control.hasError("invalidDate"))
            return "Please enter a valid date.";
        return undefined;
    }
}
