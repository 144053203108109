import { Component, OnInit, inject } from "@angular/core";
import {
    ObjectComponent,
    ObjectViewMode,
} from "../../../common/components/object.component";
import { Program } from "../../../services/models/program";
import { ProgramService } from "../../../services/program.services";
import {
    AbstractControl,
    AsyncValidator,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import {
    APIListResult,
    ObjectOrReference,
    ObjectReference,
} from "../../../services/models/api-object";
import { faCapsules } from "@fortawesome/free-solid-svg-icons";
import { Account } from "../../../services/models/account";
import { Role } from "../../../services/models/role";
import { AccountService, OrganizationService } from "../../../services/iam.services";
import { catchError, map, mergeMap } from "rxjs/operators";
import { AddProgramAdminDialog } from "./add-admin.dialog";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { Observable, of } from "rxjs";
import { Sort } from "@angular/material/sort";
import { Organization } from "src/services/models/organization";

class INDValidator implements AsyncValidator {
    protected service: ProgramService;
    constructor(protected program?: Program | ObjectReference) {
        this.service = inject(ProgramService);
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.service.checkIND(control.value).pipe(
            map((valid: string | undefined) => {
                if (!valid || this.program?.id == valid) return of(null);
                return of({ uniqueIND: true });
            }),
            catchError(() => of(null)),
        );
    }
}

@Component({
    selector: "program",
    templateUrl: "./program.component.html",
    styleUrls: ["./program.component.scss"],
})
export class ProgramComponent extends ObjectComponent<Program> implements OnInit {
    organizationService: OrganizationService;
    organizations: ObjectReference[] = [];
    drugNameIcon = faCapsules as any;
    domainControl: UntypedFormControl = new UntypedFormControl();
    objectName = "Program";
    siteUrl!: string;
    integrateHead!: string;
    integrateBody!: string;
    showHeadCode: boolean = false;
    showBodyCode: boolean = false;
    accountService: AccountService;
    availableAdmins: Account[] = [];
    responseTimeHint =
        "If not set, no response time will be sent in the inquiry response.";

    displayedColumns: string[] = ["account-name", "actions"];

    get isOrgAdmin(): boolean {
        return !!this.currentAccount?.roles.find(
            (r: Role) =>
                r.role === "object.admin" &&
                r.object.id == this.fullObject?.organization.id,
        );
    }
    constructor(protected service: ProgramService) {
        super(service);
        this.accountService = inject(AccountService);
        this.organizationService = inject(OrganizationService);
    }

    ngOnInit() {
        // mode is only defined after this component is created
        // only place where conditionally adding the required validator will work properly
        if (this.mode == this.ObjectViewMode.Edit) {
            this.formGroup.controls["slug"].setValidators([Validators.required]);
        }
    }
    get intakeAddress(): string {
        const id = this.object?.id?.replace(/-/g, "");
        return id ? id + "@" + this.service.session.environment.intakeDomain : "";
    }
    get intakeWeb(): string {
        const orgSlug = this.fullObject?.org_slug;
        const programSlug = this.fullObject?.slug;
        return window.location.origin + "/intake/" + orgSlug + "/" + programSlug;
    }

    removeCORSDomain(event: MouseEvent, domain: string): void {
        event.preventDefault();
        event.stopPropagation();
        if (this.fullObject) {
            this.service
                .setCORSAccessDomain(this.fullObject, domain, true)
                .subscribe((domains: string[]) => {
                    if (this.fullObject) this.fullObject.cors_domains = domains;
                    this.domainControl.reset();
                });
        }
    }
    addCORSDomain(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (this.fullObject && this.domainControl.value) {
            this.service
                .setCORSAccessDomain(this.fullObject, this.domainControl.value)
                .subscribe((domains: string[]) => {
                    if (this.fullObject) this.fullObject.cors_domains = domains;
                });
        }
    }

    get administrators(): ObjectOrReference<Account>[] {
        return (
            this.fullObject?.roles
                ?.filter((r: Role) => r.role.indexOf("object.admin") != -1)
                .filter((r: Role) => !!r.account)
                .map((r: Role) => r.account!) ?? []
        );
    }

    isOrganizationAdmin(account: ObjectReference): boolean {
        return !!this.fullObject?.roles?.find(
            (r: Role) =>
                r.account?.id == account.id &&
                r.role == "object.admin" &&
                r.object.id == this.fullObject?.organization.id,
        );
    }
    addAdministrator(event: MouseEvent): void {
        this.terminateEvent(event);
        this.dialog
            .open(AddProgramAdminDialog, {
                data: {
                    program: this.fullObject,
                    available: this.availableAdmins,
                },
                minWidth: "50vw",
                disableClose: true,
                hasBackdrop: true,
            })
            .afterClosed()
            .subscribe((roles: Role[]) => {
                if (roles && this.fullObject) this.fullObject.roles = roles;
            });
    }
    removeAdministrator(event: MouseEvent, account: ObjectReference): void {
        this.terminateEvent(event);
        if (account) {
            this.dialog
                .open(ConfirmDialog, {
                    data: {
                        message:
                            "Are you sure you want to remove " +
                            account.displayName +
                            " as an administrator for this program?",
                    },
                    disableClose: true,
                    hasBackdrop: true,
                    minWidth: "50vw",
                })
                .afterClosed()
                .pipe(
                    mergeMap((confirm: boolean) =>
                        confirm ?
                            this.service.deleteRole(
                                this.fullObject!.serialize(),
                                "object.admin",
                                account.serialize(),
                            )
                        :   of(undefined),
                    ),
                )
                .subscribe((roles: Role[] | undefined) => {
                    if (roles && this.fullObject) {
                        this.fullObject.roles = roles;
                    }
                });
        }
    }

    validateIND(control: AbstractControl): Observable<ValidationErrors | null> {
        if (control.value)
            return this.service.checkIND(control.value).pipe(
                map((valid: string | undefined) => {
                    if (!valid || this.object?.id == valid) return null;
                    return { uniqueIND: true };
                }),
                catchError(() => of(null)),
            );
        return of(null);
    }

    onSortChange(event: Sort): void {}
    owner?: Organization;
    protected setObject(v?: Program): void {
        super.setObject(v);
        if (v?.organization?.id) {
            this.organizationService.retrieve(v?.organization.id).subscribe((org) => {
                this.owner = org;
            });
        }
        this.initializeIntegrationCode();
        this.updateAvailableAdmins();
        if (this.mode == ObjectViewMode.Create) this.objectName = "Create Program";
        else if (this.mode == ObjectViewMode.Edit)
            this.objectName = "Edit " + (this.object?.displayName ?? "Program");
        else this.objectName = "View " + (this.object?.displayName ?? "Program");
    }
    protected initializeIntegrationCode(): void {
        this.siteUrl = window.location.origin;
        this.integrateHead =
            '<link rel="stylesheet" href="' +
            this.siteUrl +
            '/assets/intake.css">\n<script type="application/javascript" src="' +
            this.siteUrl +
            '/assets/intake.js"></script>';
        this.integrateBody =
            '<div class="eap-request">\n' +
            '  <p class="eap-instructions">To submit a request for access, please fill out the form below</p>\n' +
            '    <form id="eap-inquiry" name="eap-inquiry">\n' +
            "      <-- This hidden input field must remain -->\n" +
            '      <input type="hidden" name="cors_object" value="program.program:' +
            this.object?.id +
            '" />\n' +
            '      <div class="eap-form-section full">Clinic Information</div>\n' +
            '      <div class="eap-form-field full">\n' +
            '        <label for="sender">Requesting Physician:</label>\n' +
            '        <input class="field-value" type="text" id="sender" name="sender" placeholder="Email Address" />\n' +
            "      </div>\n" +
            '      <div class="eap-form-field full">\n' +
            '        <label for="clinic.address">Clinic Address:</label>\n' +
            '        <textarea class="field-value" id="clinic.address" name="clinic.address" placeholder="123 Main St.\nSome Town, CA, 12345"></textarea>\n' +
            "      </div>\n" +
            '      <div class="eap-form-field full">\n' +
            '        <label for="physician.phone">Physician Telephone:</label>\n' +
            '        <input class="field-value" type="text" id="physician.phone" name="physician.phone" placeholder="(201) 555-1234 x5678" />\n' +
            "      </div>\n" +
            '      <div class="eap-form-section full">Patient Information</div>\n' +
            '      <div class="eap-form-field full">\n' +
            '        <label for="patient.initials">Initials:</label>\n' +
            '        <div class="field-value eap-form-field">\n' +
            '          <input style="flex: 0 0 auto; width: 10rem; margin-right: 1rem;" type="text" id="patient.initials" name="patient.initials" placeholder="AZ">\n' +
            '          <label for="patient.age" style="width: auto;">Age:</label>\n' +
            '          <input style="flex: 0 0 auto; width: 10rem; margin-right: 1rem;" type="text" id="patient.age" name="patient.age" placeholder="0-99+">\n' +
            '          <label for="patient.sex" style="width: auto;">Sex:</label>\n' +
            '          <input style="flex: 0 0 auto; width: 10rem; margin-right: 1rem;" type="text" id="patient.sex" name="patient.sex" placeholder="M/F">\n' +
            "        </div>\n" +
            "      </div>\n" +
            '      <div class="eap-form-field full">\n' +
            '        <label for="patient.history">Medical History:</label>\n' +
            '        <textarea class="field-value" id="patient.history" name="patient.history" placeholder="Brief medical history"></textarea>\n' +
            "      </div>\n" +
            '      <div class="eap-form-field full eap-form-submit">\n' +
            "        <label></label>\n" +
            '        <button class="field-value" type="button" onclick="submitInquiry(\'success.html\', \'error.html\')">Submit Request</button>\n' +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</div>";
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            organization: [null, Validators.required],
            drug_name: [null, Validators.required],
            name: [null],
            ind: [
                null,
                {
                    validators: [Validators.pattern("^[0-9]*$")],
                    asyncValidators: [this.validateIND.bind(this)],
                },
            ],
            slug: [null], // required validator will be added if this object is in edit mode
            response_time: [null],
        });
    }
    protected precommitTransform(v: Program): any {
        if (!v.name) {
            v.name = v.drug_name;
        }

        v.organization = this.formGroup.get("organization")?.value;
        return super.precommitTransform(v);
    }
    protected onCommitError(err: any) {
        super.onCommitError(err);
        this.session.handleError(err);
    }
    get programAdminRoles(): string {
        return Role.roles
            .filter((r) => !r.value.includes("none"))
            .map((r) => r.value)
            .join("|");
    }

    protected updateAvailableAdmins(): void {
        if (this.fullObject?.organization) {
            this.accountService
                .list({
                    role:
                        this.fullObject.organization.id + ":" + this.programAdminRoles,
                })
                .pipe(map((accounts: APIListResult<Account>) => accounts as Account[]))
                .subscribe((accounts: Account[]) => {
                    this.availableAdmins = accounts;
                });
        } else {
            this.availableAdmins = [];
        }
    }
}
