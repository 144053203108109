import { Pipe, PipeTransform } from "@angular/core";
import { LocalizedDatePipe } from "./localized-date.pipe";

@Pipe({
    name: "relativeDate",
    pure: false,
})
export class RelativeDatePipe implements PipeTransform {
    constructor(private localizedDatePipe: LocalizedDatePipe) {}

    transform(value: Date, format = "mediumDate"): string {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);
        const lastMonth = new Date(today);
        lastMonth.setMonth(lastMonth.getMonth() - 1);

        if (value >= today) {
            return "Last 24 Hours";
        } else if (value >= yesterday) {
            return "Yesterday";
        } else if (value > lastWeek) {
            return "Last 7 Days";
        } else if (value > lastMonth) {
            return "This Month";
        } else {
            return this.localizedDatePipe.transform(value, format) ?? "";
        }
    }
}
