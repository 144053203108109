import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, inject } from "@angular/core";
import { AuthenticationService, AuthenticationState } from "src/services/auth.service";
import { SessionComponent } from "src/services/components/session.component";
import { mergeMap, of } from "rxjs";

@Component({
    template: ``,
})
export class AuthorizationComponent extends SessionComponent implements OnInit {
    protected authService: AuthenticationService;

    constructor(
        protected route: ActivatedRoute,
        private router: Router,
    ) {
        super();
        this.authService = inject(AuthenticationService);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            const code = params["code"];
            let next = undefined;
            if (!code) {
                this.authService.authorize(next);
            } else {
                this.authService
                    .token(code)
                    .pipe(
                        mergeMap((token: string | undefined) => {
                            if (token) {
                                return this.session.restRequest<any>(
                                    ["iam", "session", "login"],
                                    undefined,
                                    "post",
                                );
                            }
                            return of(token);
                        }),
                    )
                    .subscribe(() => {
                        // We only pass state information in non-auth0 requests
                        if (params["state"] && !this.authService.isAuth0Client) {
                            const state: AuthenticationState = JSON.parse(
                                atob(params["state"]),
                            );
                            let url = state.next;
                            if (state.params) url += "?" + state.params;
                            this.router.navigateByUrl(url);
                        } else {
                            this.router.navigateByUrl("/");
                        }
                    });
            }
        });
    }
}
