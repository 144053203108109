import { Component } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";

@Component({
    selector: "dt-text-short",
    template: `
        <p *ngIf="viewOnly && !hasValues && compoundIndex == 0" class="secondary">
            Value not provided
        </p>
        <p *ngIf="viewOnly && hasValues">{{ displayValue }}</p>
        <mat-form-field class="column" [ngClass]="inputClass" *ngIf="!viewOnly">
            <mat-label>{{ label }}</mat-label>
            <input
                [type]="inputType"
                [max]="max"
                [min]="min"
                matInput
                [placeholder]="placeholder"
                class="test"
                [formControl]="control"
                [required]="isRequired"
            />
            <mat-hint *ngIf="!!instructions">
                <quill-view-html [content]="instructions"></quill-view-html>
            </mat-hint>
            <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
        </mat-form-field>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeTextShort extends BaseDataTypeComponent {
    get isNumberType() {
        return this.type?.name === "number";
    }

    get inputType() {
        if (this.isNumberType) return "number";
        return "";
    }

    get max() {
        if (this.isNumberType) return this.attributes?.validators?.number?.max;
        return undefined;
    }

    get min() {
        if (this.isNumberType) return this.attributes?.validators?.number?.min;
        return undefined;
    }

    getErrorMessage() {
        const msg = super.getErrorMessage();
        if (msg) return msg;
        const name = this.label ?? "Value";
        if (this.isNumberType && this.attributes?.validators?.number) {
            const { min, max } = this.attributes.validators.number;
            if (min && !max) {
                return `${name} must be greater than or equal to ${min}.`;
            } else if (max && !min) {
                return `${name} must be less than or equal to ${max}.`;
            } else if (min && max) {
                return `${name} must be between ${min} and ${max}.`;
            }
        }
        return undefined;
    }
}
