import { Component } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";

@Component({
    selector: "dt-text-email",
    template: `
        <p *ngIf="viewOnly && !hasValues && compoundIndex == 0" class="secondary">
            Value not provided
        </p>
        <p *ngIf="viewOnly && hasValues">{{ displayValue }}</p>
        <mat-form-field class="column" [ngClass]="inputClass" *ngIf="!viewOnly">
            <mat-label>{{ label }}</mat-label>
            <input
                matInput
                [placeholder]="placeholder"
                [formControl]="control"
                [required]="isRequired"
            />
            <mat-hint *ngIf="!!instructions">{{ instructions }}</mat-hint>
            <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
        </mat-form-field>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeTextEmail extends BaseDataTypeComponent {
    protected getErrorMessage(): string | undefined {
        const msg = super.getErrorMessage();
        if (msg) return msg;
        else if (this.control.hasError("invalidEmailAddress"))
            return "Please enter a valid email address.";
        return undefined;
    }
}
