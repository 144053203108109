<div class="table full">
    <div class="header">
        <div class="row flex">
            <div class="column flexible">Timestamp</div>
            <div class="column flexible">User</div>
            <div class="column flexible">Activity</div>
        </div>
    </div>
    <div class="body striped">
        <div class="row flex bold info not-found" *ngIf="!auditTrail.length">
            No audit entries found.
        </div>
        <div class="row flex" *ngFor="let audit of auditTrail">
            <div class="column flexible">{{ audit.timestamp | localizedDate }}</div>
            <div class="column flexible">{{ audit.account?.displayName }}</div>
            <div class="column flexible">
                <span *ngIf="audit.activity != 7">{{ audit.activityDisplay }}</span>
                <span *ngIf="audit.activity == 7">{{ audit.eventDisplay }}</span>
            </div>
        </div>
    </div>
</div>
