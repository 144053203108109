import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Case } from "src/services/models/case";
import { Inquiry } from "src/services/models/inquiry";
import { Patient } from "src/services/models/patient";

@Component({
    selector: "confirm-bulk-upload-dialog",
    template: `
        <h3 mat-dialog-title>Confirm Upload</h3>

        <mat-dialog-content>
            <p>
                Please confirm the cases that you want to upload to. You can select or
                unselect each one.
            </p>

            <mat-form-field class="full">
                <mat-select [formControl]="formControl" multiple>
                    <mat-option *ngFor="let obj of data.patients" [value]="obj">
                        {{ obj.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-dialog-content>

        <mat-dialog-actions>
            <div class="spacer"></div>
            <button mat-button (click)="onCancel()">Cancel</button>
            <button mat-button (click)="onConfirm()" color="accent" mat-flat-button>
                Confirm
            </button>
        </mat-dialog-actions>
    `,
})
export class ConfirmBulkUploadDialog {
    get targets() {
        return this.formControl.value;
    }
    formControl: FormControl;
    constructor(
        public dialogRef: MatDialogRef<ConfirmBulkUploadDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: { patients: (Case | Inquiry | Patient)[] },
    ) {
        this.formControl = new FormControl(this.data.patients);
    }

    onConfirm(): void {
        this.dialogRef.close(this.formControl.value);
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
