import { SearchableListComponent } from "../../searchable-list.component";
import { UntypedFormControl } from "@angular/forms";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";
import { DataField } from "src/services/models/data";
import { Sort } from "@angular/material/sort";
import { Case } from "src/services/models/case";
import { Program } from "src/services/models/program";
import { ObjectView } from "../../object-admin.component";
import { ObjectViewEntryPoint, ObjectViewMode } from "../../object.component";
import { DataFieldEditorComponent } from "./data-field.component";
import { DataFieldService } from "src/services/data.services";
import { ObjectRepository } from "src/services/models/compound";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "data-field-list",
    templateUrl: "./data-field-list.component.html",
    styleUrls: ["../data-admin.component.scss"],
})
export class DataFieldListComponent extends SearchableListComponent<DataField> {
    objectView: ObjectView<DataField> | undefined = DataFieldEditorComponent;
    @ViewChild("search") searchElement?: ElementRef;

    protected repo_?: ObjectRepository;
    get repository(): ObjectRepository | undefined {
        return this.repo_;
    }
    @Input() set repository(v: ObjectRepository | undefined) {
        if (v?.id !== this.repo_?.id) {
            this.repo_ = v;
            this.list.refresh();
        }
    }

    get displayedColumns(): string[] {
        return [
            "display_name",
            "data_type__display_name",
            "owner_id",
            "used_in",
            "actions",
        ];
    }

    searchTermControl: UntypedFormControl = new UntypedFormControl();
    showSearch: boolean = false;

    constructor(
        protected service: DataFieldService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
    }

    protected override postSearch(list: DataField[]): DataField[] {
        list = list.filter((field: DataField) => {
            const isRequestField = !field.owner && field.name?.startsWith("request.");
            return (
                DataFieldEditorComponent.ExcludedDataTypes.indexOf(
                    field.data_type?.name ?? "",
                ) == -1 && !isRequestField
            );
        });
        return list;
    }

    canEdit(field: DataField): boolean {
        return (
            this.currentAccount?.hasRole("object.edit", field.owner) ||
            this.currentAccount?.hasRole("object.admin", field.owner) ||
            this.currentAccount?.hasRole("organization.administrator", field.owner) ||
            this.currentAccount?.isSystemAdministrator ||
            (field.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.edit",
                    (field.owner as Program).organization,
                )) ||
            (field.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (field.owner as Program).organization,
                )) ||
            (field.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (field.owner as Program).organization,
                )) ||
            false
        );
    }
    hasDeletePermission(field: DataField): boolean {
        const hasPermission =
            this.currentAccount?.hasRole("object.admin", field.owner) ||
            this.currentAccount?.hasRole("organization.administrator", field.owner) ||
            this.currentAccount?.isSystemAdministrator ||
            (field.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (field.owner as Program).organization,
                )) ||
            (field.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (field.owner as Program).organization,
                )) ||
            false;
        return hasPermission;
    }
    canDelete(type: DataField): boolean {
        return this.hasDeletePermission(type) && !type.used_in;
    }

    editObject(
        event: MouseEvent | undefined,
        object: DataField,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<DataField> | undefined {
        if (!this.isObject(object)) return;
        const instance = super.editObject(
            event,
            object,
            asDialog,
            viewOnly,
        ) as DataFieldEditorComponent;
        instance.autosave = false;
        return instance;
    }
    duplicateObject(
        event: MouseEvent,
        object: DataField,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<DataField> | undefined {
        const owner = object.owner ?? this.repository;
        const duplicate = object.duplicate(owner);
        const instance = this.editObject(event, duplicate, asDialog);
        (instance as DataFieldEditorComponent)?.formGroup.markAsDirty();
        if (asDialog && instance?.dialogReference)
            instance.dialogReference
                .afterClosed()
                .subscribe((form: DataField) => this.onAfterCreate(form, instance));
        return instance;
    }
    newObject(data?: any): DataField | undefined {
        return super.newObject({
            ...data,
            owner: this.repository,
        });
    }
    protected objectDialogConfiguration(
        object: DataField,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            minWidth: "50vw",
        };
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }
    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        if (this.repository?.type == "program.case")
            filters["repo"] =
                this.repository.id + "," + (this.repository as Case).shared.id;
        if (this.repository?.type == "program.program")
            filters["repo"] =
                this.repository.id + "," + (this.repository as Program).organization.id;
        else filters["repo"] = this.repository?.id ?? "";
        return filters;
    }
}
