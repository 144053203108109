import {
    APIListResult,
    ObjectOrReference,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import {
    CompoundDataType,
    DataField,
    DataFieldValue,
    DataForm,
    DataFormField,
    DataFormFieldCondition,
    DataType,
    DataFormReference,
    DataReference,
} from "./models/data";
import { APIService } from "src/services/api.service";
import { forkJoin, map, Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { ObjectFactory, ObjectReference } from "./models/api-object";
import { Program } from "./models/program";
import { Organization } from "./models/organization";
import { DocumentRepository } from "./models/document";
import { RequestFilter } from "src/common/utilities/request";

@Injectable()
export class DataFormService extends APIService<DataForm> {
    constructor() {
        super(DataForm, ["program", "form"]);
    }
    getReferenceType(): typeof ObjectReference {
        return DataFormReference;
    }

    getValuesByFieldNames(id: string, fieldNames: string[], formName: string) {
        const data = {
            id,
            fieldNames,
            formName,
        };

        return this.request<DataFieldValue[]>(
            [this.endpoint, "values_by_field_names"].join("/"),
            undefined,
            data,
            "post",
        );
    }

    exportAsPdf(fileName: string, formIds: string[], formHeader: string = "") {
        this.session.download(
            ["program", "form", "export_pdf"],
            fileName,
            { ids: formIds.join(","), header: formHeader },
            "get",
        );
    }

    exportProgramsData(
        programs: ObjectOrReference<Program>[],
        org: Organization,
        fileName: string,
    ) {
        this.session.download(["program", "form", "export_programs"], fileName, {
            ids: programs.map((p) => p.id).join(","),
            orgId: org.id!,
        });
    }

    versions(form: DataForm): Observable<DataForm[]> {
        return this.request<DataForm[]>(
            [this.endpoint, form.id, "versions"].join("/"),
        ).pipe(
            map((result: any) =>
                result.map((o: any) => ObjectFactory.makeObject<DataForm>(o)),
            ),
        );
    }
    siblingNames(
        form: OptionalObjectOrReference<DataForm>,
        owner: OptionalObjectOrReference<DocumentRepository>,
    ): Observable<string[]> {
        return form?.id || owner?.id ?
                this.request<string[]>(
                    [this.endpoint, "sibling_names"].join("/"),
                    undefined,
                    { self: form?.id, owner: owner?.id },
                    "post",
                ).pipe(map((result: any) => result as string[]))
            :   of([]);
    }

    static getTemplates(
        dataFormService: DataFormService,
        ownerIds: string[],
        isAdmin: boolean = false,
        workflowId?: string,
    ): Observable<DataForm[]> {
        if (!ownerIds.length) return of([]);

        const filters: RequestFilter = {
            owned: ownerIds.join(","),
            is_template: "True",
            attributes: "canInstantiate:True",
            next: "0",
        };
        const formsObs = dataFormService
            .list(filters)
            .pipe(map((forms: APIListResult<DataForm>) => (forms || []) as DataForm[]));
        let adminFormsObs: Observable<DataForm[]> = of([]);

        if (isAdmin && workflowId) {
            //PER MED-2177 admins/managers should also see forms apart of the workflow
            adminFormsObs = dataFormService
                .list({ workflow: workflowId, next: "0", is_template: "True" })
                .pipe(
                    map(
                        (forms: APIListResult<DataForm>) => (forms || []) as DataForm[],
                    ),
                );
        }

        return forkJoin([formsObs, adminFormsObs]).pipe(
            map((forms) => {
                const combinedForms = forms[0].concat(forms[1]);

                return combinedForms;
            }),
        );
    }
}

@Injectable()
export class DataFieldService extends APIService<DataField> {
    constructor() {
        super(DataField, ["program", "field"]);
    }

    getReferenceType(): typeof ObjectReference {
        return DataReference;
    }

    conflicts(name: string, repositories: string[]): Observable<DataField[]> {
        const data = {
            name: name,
            repositories: repositories,
        };
        return this.action<DataField[]>("conflicts", undefined, data, "post").pipe(
            map((result: any) => result.map((v: any) => this.makeObject(v))),
        );
    }
}

@Injectable()
export class DataFieldValueService extends APIService<DataFieldValue> {
    constructor() {
        super(DataFieldValue, ["program", "field_value"]);
    }
    bulkUpdate(
        values: DataFieldValue[],
        forms?: ObjectReference[],
    ): Observable<DataFieldValue[]> {
        const data = {
            values: values.map((v: DataFieldValue) => v.serialize()),
            forms: forms?.map((f: ObjectReference) => f.serialize()),
        };
        return this.action<DataFieldValue[]>("upsert", undefined, data, "put").pipe(
            map((result: any) =>
                result.map((o: any) => ObjectFactory.makeObject<DataFieldValue>(o)),
            ),
        );
    }
}

@Injectable()
export class DataTypeService extends APIService<DataType> {
    constructor() {
        super(DataType, ["program", "type"]);
    }

    getReferenceType(): typeof ObjectReference {
        return DataReference;
    }

    conflicts(name: string, repositories: string[]): Observable<DataType[]> {
        const data = {
            name: name,
            repositories: repositories,
        };
        return this.action<DataType[]>("conflicts", undefined, data, "post").pipe(
            map((result: any) => result.map((v: any) => this.makeObject(v))),
        );
    }
}

@Injectable()
export class DataFormFieldFactory extends ObjectFactory<DataFormField> {
    constructor() {
        super(DataFormField);
    }
}

@Injectable()
export class CompoundDataTypeFactory extends ObjectFactory<CompoundDataType> {
    constructor() {
        super(CompoundDataType);
    }
}

@Injectable()
export class DataFormFieldConditionService extends APIService<DataFormFieldCondition> {
    constructor() {
        super(DataFormFieldCondition, ["program", "condition"]);
    }
}
