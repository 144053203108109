<div
    class="detail-tab-body-content"
    #content
    [@translateTab]="{
        value: positionState,
        params: { animationDuration: animationDuration }
    }"
    (@translateTab.start)="onTranslateTabStarted($event)"
    (@translateTab.done)="translateTabComplete.next($event)"
    cdkScrollable
>
    <ng-template detailTabBodyHost></ng-template>
</div>
