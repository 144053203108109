import { Inquiry } from "src/services/models/inquiry";
import { AccountService } from "./../../../../services/iam.services";
import { CreateRequestDialog } from "./../../../../program/components/request/create-request.component";
import { ObjectOrReference } from "./../../../../services/models/api-object";
import { SystemAdminComponent } from "./../../../../admin/components/system/system-admin.component";
import { OrganizationSettingsComponent } from "./../../../../admin/components/organization/settings.component";
import { AccountComponent } from "./../../../../admin/components/account/account.component";
import {
    PatientListMode,
    PatientsComponent,
} from "../../../../program/components/patients/patients.component";
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    inject,
} from "@angular/core";
import {
    DashboardRole,
    SessionComponent,
} from "../../../../services/components/session.component";
import version from "../../../../version.json";
import { contentStack } from "src/common/components/content-stack.component";
import { DashboardComponent } from "src/app/components/dashboard/dashboard.component";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { ObjectViewMode } from "src/common/components/object.component";
import { InfinitePatientsComponent } from "src/program/components/patients/infinite-patients.component";
import { Role } from "src/services/models/role";
import { GlobalShipmentListComponent } from "src/program/components/shipment/global-shipment-list.component";
import { Account } from "src/services/models/account";
import { Case } from "src/services/models/case";
import { CaseComponent } from "src/program/components/case/case.component";
import { InquiryComponent } from "src/program/components/inquiry/inquiry.component";
import { Organization } from "src/services/models/organization";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { AppnotificationDrawerComponent } from "src/notifications/appnotification-drawer/appnotification-drawer.component";
import { AppNotification } from "src/services/models/appNotification";
import { PaginatedPatientsComponent } from "src/program/components/patients/paginated-patients.component";

@Component({
    selector: "authenticated-frame",
    templateUrl: "./authenticated.frame.component.html",
    styleUrls: ["./authenticated.frame.component.scss"],
})
export class AuthenticatedFrameComponent extends SessionComponent {
    protected snackBar: MatSnackBar;
    readonly currentYear: number = new Date().getFullYear();
    version: string = version.version;
    build: string = version.build;
    enableBreadcrumbs: boolean = false;
    dialog: MatDialog;
    accountService: AccountService;

    @Output() notificationClick = new EventEmitter<void>();
    onNotificationClick() {
        this.notificationClick.emit();
    }

    @Input() notificationCount?: number;
    constructor() {
        super();
        this.snackBar = inject(MatSnackBar);
        this.dialog = inject(MatDialog);
        this.accountService = inject(AccountService);
        this.session.onMessage.subscribe((msg: string) =>
            this.snackBar.open(msg, undefined, { duration: 5000 }),
        );
    }

    get showBreadcrumbs(): boolean {
        return this.enableBreadcrumbs && (contentStack?.count ?? 0) > 1;
    }
    showShipments(event: MouseEvent) {
        this.terminateEvent(event);
        contentStack?.push(
            {
                type: GlobalShipmentListComponent,
                breadcrumbText: "Shipments",
            },
            0,
        );
    }
    mapDashboardRoleToPatientListMode(
        role: DashboardRole,
    ): PatientListMode | undefined {
        switch (role) {
            case "provider":
                return "physician";
            case "pharma":
                return "pharma";
            default:
                console.error(`Cannot map DashboardRole ${role} to PatientListMode`);
                return undefined;
        }
    }

    showPatients(event: MouseEvent, title: string = ""): void {
        this.terminateEvent(event);

        if (!this.dashboardRoleDefined || !this.dashboardRole) return;
        const componentMode = this.mapDashboardRoleToPatientListMode(
            this.dashboardRole,
        );
        if (!componentMode) return;

        const instance = contentStack?.push(
            {
                type: PaginatedPatientsComponent,
                breadcrumbText: "Patients",
            },
            0,
        );

        if (instance instanceof PaginatedPatientsComponent) {
            instance.title = title;
            instance.mode = componentMode;
            instance.enablePagination = true;
        }
    }
    showPrograms(event: MouseEvent): void {
        this.terminateEvent(event);
        contentStack?.push(
            {
                type: OrganizationSettingsComponent,
                breadcrumbText:
                    this.isAnyOrganizationAdministrator ?
                        "Organization Settings"
                    :   "Programs",
            },
            1,
        );
    }
    showDashboard(event: MouseEvent): void {
        this.terminateEvent(event);
        contentStack?.push(
            {
                type: DashboardComponent,
                breadcrumbIcon: faHome,
                breadcrumbText: "Dashboard",
            },
            0,
        );
    }
    showAccount(event: MouseEvent): void {
        const instance = contentStack?.push(
            {
                type: AccountComponent,
                breadcrumbText: "Account Settings",
            },
            1,
        );
        if (instance) {
            instance.mode = ObjectViewMode.Edit;
            instance.autosave = true;
            instance.object = this.currentAccount;
        }
    }
    showOrganization(event: MouseEvent): void {
        const instance = contentStack?.push(
            {
                type: OrganizationSettingsComponent,
                breadcrumbText: "Organization Settings",
            },
            1,
        );
        instance?.selectTab(2);
    }
    showAdmin(event: MouseEvent): void {
        contentStack?.push(
            {
                type: SystemAdminComponent,
                breadcrumbText: "System Administration",
            },
            1,
        );
    }

    updateDashboardRole(): void {
        this.accountService
            .dashboardRole(this.currentAccount)
            .subscribe((role: any) => {
                this.dashboardRole = role["role"];
            });
    }

    createRequest(event: MouseEvent): void {
        this.terminateEvent(event);

        this.dialog
            .open(CreateRequestDialog, {
                data: {
                    organizations: this.manageableOrganizations(),
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "80vw",
            })
            .afterClosed()
            .subscribe((ci?: Case | Inquiry) => {
                if (ci) {
                    const view =
                        ci.type == "program.case" ? CaseComponent : InquiryComponent;
                    const instance = contentStack?.push<
                        CaseComponent | InquiryComponent
                    >(
                        {
                            type: view,
                            breadcrumbText: ci.displayName,
                        },
                        0,
                    );
                    if (instance) {
                        instance.mode = ObjectViewMode.Edit;
                        instance.autosave = true;
                        instance.autosaveOnCreate = false;
                        instance.object = ci;
                    }
                }
            });
    }

    get isAnyOrganizationAdministrator(): boolean {
        return !!this.currentAccount?.roles.find(
            (r: Role) =>
                r.object.type == Organization.object_type &&
                r.role.split("|").includes("object.admin"),
        );
    }

    manageableOrganizations(): ObjectOrReference<Organization>[] {
        return (
            this.currentAccount?.roles
                .filter(
                    (r: Role) =>
                        r?.object &&
                        r?.role &&
                        r?.object?.type == Organization.object_type &&
                        r?.role?.split("|").includes("object.admin"),
                )
                .map((r: Role) => r?.object as ObjectOrReference<Organization>)
                .filter(
                    (org: ObjectOrReference<Organization>) =>
                        !this.currentAccount?.isViewOnlyOnOrg(org),
                ) ?? []
        );
    }

    get canCreateRequest(): boolean {
        if (this.dashboardRole !== "pharma") return false;

        const orgs = this.manageableOrganizations();
        return orgs.length > 0;
    }

    get isViewOnly() {
        return !!this.currentAccount?.isViewOnlyOnOrg();
    }
    get rootIsDashboard(): boolean {
        return contentStack?.root?.type == DashboardComponent;
    }
    get rootIsShipments() {
        return contentStack?.root?.type == GlobalShipmentListComponent;
    }
    get rootIsPatients(): boolean {
        return contentStack?.root?.type?.prototype instanceof PatientsComponent;
    }

    protected onCurrentAccountChanged(a: Account | undefined): void {
        if (a) this.updateDashboardRole();
        else this.dashboardRole = "none";
    }
    get helpUrl() {
        return "https://medasystems.com/help";
    }

    @ViewChild(AppnotificationDrawerComponent)
    menuDrawer?: AppnotificationDrawerComponent;

    matBadge(notifiations?: number) {
        return AppNotification.matBadge(notifiations);
    }
}
