import { SessionComponent } from "src/services/components/session.component";
import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { Program } from "src/services/models/program";
import { Case } from "src/services/models/case";
import { Inquiry } from "src/services/models/inquiry";
import { Organization } from "src/services/models/organization";
import { Account } from "src/services/models/account";
import { MatTabGroup } from "@angular/material/tabs";

export type DataRepository = Program | Case | Inquiry | Organization | Account;

@Component({
    selector: "data-admin",
    templateUrl: "./data-admin.component.html",
    styleUrls: ["./data-admin.component.scss"],
})
export class DataAdminComponent extends SessionComponent implements AfterViewInit {
    @ViewChild(MatTabGroup) tabGroup?: MatTabGroup;

    @Input() set repository(v: DataRepository | undefined) {
        this._repository = v;
    }
    get repository(): DataRepository | undefined {
        return this._repository;
    }
    protected _repository?: DataRepository;

    ngAfterViewInit(): void {
        this.tabGroup?.realignInkBar();
    }
}
