import { SessionService } from "src/services/session.service";
import { Component } from "@angular/core";

@Component({
    template: ``,
})
export class LoginComponent {
    constructor(session: SessionService) {
        session.login("/");
    }
}
