<object-form
    class="wrapper shipping-details"
    [controller]="controller"
    [formGroup]="formGroup"
    [multiStep]="true"
>
    <div class="wrapper flex stack" cdkScrollable>
        <mat-stepper #stepper>
            <mat-step
                [hasError]="!isFormGroupValid('patientProductDetails')"
                formGroupName="patientProductDetails"
            >
                <ng-template matStepLabel>Patient/Product Details</ng-template>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Patient Identifier</mat-label>
                        <input
                            matInput
                            placeholder="Patient Identifier"
                            formControlName="patient"
                        />
                        <mat-error *ngIf="hasError('patient')">
                            A patient identifier is required.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>Project Code</mat-label>
                        <input
                            matInput
                            placeholder="Project Code"
                            formControlName="project_code"
                        />
                        <mat-error *ngIf="hasError('project_code')">
                            A project code is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Unique Treatment Number</mat-label>
                        <input
                            matInput
                            placeholder="Unique Treatment Number"
                            formControlName="ind"
                        />
                        <mat-error *ngIf="hasError('ind')">
                            An Unique Treatment Number is required.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>Product Name</mat-label>
                        <input
                            matInput
                            placeholder="Product Name"
                            formControlName="product_name"
                        />
                        <mat-error *ngIf="hasError('product_name')">
                            A product name required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Lot / Batch ID</mat-label>
                        <input
                            matInput
                            placeholder="Lot / Batch ID"
                            formControlName="lot"
                        />
                        <mat-error *ngIf="hasError('lot')">
                            A lot or batch ID is required.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="date-input flexible">
                        <mat-label>Lot Expiry</mat-label>
                        <input
                            matInput
                            [matDatepicker]="lot_date_picker"
                            formControlName="lot_expiration"
                            placeholder="Lot Expiry"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="lot_date_picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #lot_date_picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </mat-step>
            <mat-step formGroupName="dosage" [hasError]="!isFormGroupValid('dosage')">
                <ng-template matStepLabel>Shipping Quantity and Dosage</ng-template>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Total Quantity</mat-label>
                        <input
                            matInput
                            placeholder="Total Quantity"
                            formControlName="total_quantity"
                            type="number"
                        />
                        <mat-error *ngIf="hasError('total_quantity')">
                            A quantity is required.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>Unit</mat-label>
                        <mat-select formControlName="total_unit" placeholder="Unit">
                            <mat-option *ngFor="let unit of units" [value]="unit.value">
                                {{ unit.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('total_unit')">
                            A unit must be specified for total quantity.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Dose Quantity</mat-label>
                        <input
                            matInput
                            placeholder="Dose Quantity"
                            formControlName="dose_quantity"
                            type="number"
                        />
                        <mat-error *ngIf="hasError('dose_quantity')">
                            A dose quantity is required.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>Unit</mat-label>
                        <mat-select formControlName="dose_unit" placeholder="Unit">
                            <mat-option *ngFor="let unit of units" [value]="unit.value">
                                {{ unit.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('dose_unit')">
                            A unit must be specified for the dose quantity.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Dose Form</mat-label>
                        <mat-select formControlName="dose_form" placeholder="Dose Form">
                            <mat-optgroup
                                *ngFor="let group of doseFormOptionGroups"
                                [label]="group.name"
                                [disabled]="group.disabled"
                            >
                                <mat-option
                                    *ngFor="let doseFormGroup of group.doseFormGroup"
                                    [value]="doseFormGroup.value"
                                >
                                    {{ doseFormGroup.viewValue }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                        <mat-error *ngIf="hasError('dose_form')">
                            A dose form is required.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field
                        class="flexible"
                        *ngIf="
                            formGroup.controls.dosage.get('dose_form')?.value ===
                            'custom'
                        "
                    >
                        <mat-label>Custom Dose Form</mat-label>
                        <input
                            matInput
                            placeholder="Custom Dose Form"
                            formControlName="custom_dose"
                        />
                        <mat-error *ngIf="hasError('custom_dose')">
                            A dose form is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-step>
            <mat-step
                formGroupName="shipping"
                [hasError]="!isFormGroupValid('shipping')"
            >
                <ng-template matStepLabel>Shipping Details</ng-template>
                <div class="row flex input-field-container" formGroupName="address">
                    <mat-form-field class="full">
                        <mat-label>Company</mat-label>
                        <input
                            matInput
                            placeholder="Company"
                            formControlName="company"
                        />
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container" formGroupName="address">
                    <mat-form-field class="flexible">
                        <mat-label>Address</mat-label>
                        <input
                            matInput
                            [placeholder]="'Address' + (hasUnitNumber ? ' 1' : '')"
                            formControlName="address1"
                        />
                        <mat-error *ngIf="hasError('address.address1')">
                            Address is required
                        </mat-error>
                    </mat-form-field>
                    <div class="unit flexible flex input-field-container">
                        <mat-form-field class="flexible" *ngIf="hasUnitNumber">
                            <mat-label>Address 2</mat-label>
                            <input
                                matInput
                                placeholder="Address 2"
                                formControlName="address2"
                            />
                        </mat-form-field>
                        <div class="btn-overlay" *ngIf="!hasUnitNumber">
                            <button
                                mat-button
                                type="button"
                                (click)="hasUnitNumber = !hasUnitNumber"
                            >
                                + Add C/O, Apt, Suite, Unit
                                <mat-icon matSuffix class="match-material-icons">
                                    tag
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row flex input-field-container" formGroupName="address">
                    <mat-form-field class="flexible">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" formControlName="city" />
                        <mat-error *ngIf="hasError('address.city')">
                            City is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>State</mat-label>
                        <mat-select placeholder="State" formControlName="state">
                            <mat-option
                                *ngFor="let state of states"
                                [value]="state.abbreviation"
                            >
                                {{ state.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('address.state')">
                            State is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>Postal Code</mat-label>
                        <input
                            matInput
                            #postalCode
                            maxlength="5"
                            placeholder="Postal Code"
                            formControlName="postal_code"
                        />
                        <mat-hint>{{ postalCode.value.length }} / 5</mat-hint>
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Shipping Condition</mat-label>
                        <mat-select
                            formControlName="shipping_conditions"
                            placeholder="Shipping Condition"
                            multiple
                        >
                            <mat-option value="room_temp">Room Temperature</mat-option>
                            <mat-option value="frozen">Frozen</mat-option>
                            <mat-option value="on_ice">On Ice</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Ship Date</mat-label>
                        <input
                            matInput
                            [matDatepicker]="ship_date_picker"
                            formControlName="ship_date"
                            placeholder="Ship Date"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="ship_date_picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #ship_date_picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>Tracking Reference</mat-label>
                        <input
                            matInput
                            placeholder="Tracking Reference"
                            formControlName="tracking_reference"
                        />
                    </mat-form-field>
                </div>
            </mat-step>
            <mat-step
                *ngIf="mode == ObjectViewMode.Edit"
                formGroupName="receipt"
                [optional]="true"
                [hasError]="!isFormGroupValid('receipt')"
            >
                <ng-template matStepLabel>Receipt Information</ng-template>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Received By</mat-label>
                        <input
                            matInput
                            placeholder="Received By"
                            formControlName="recipient"
                        />
                    </mat-form-field>
                    <mat-form-field class="flexible" style="width: 200px">
                        <mat-label>Received Date</mat-label>
                        <input
                            matInput
                            [matDatepicker]="receipt_date_picker"
                            formControlName="received_date"
                            placeholder="Received Date"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="receipt_date_picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #receipt_date_picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="row flex input-field-container">
                    <mat-form-field class="flexible">
                        <mat-label>Receipt Conditions</mat-label>
                        <mat-select
                            placeholder="Receipt Conditions"
                            formControlName="receipt_conditions"
                            multiple
                        >
                            <mat-option value="intact">
                                Contained intact supply of product
                            </mat-option>
                            <mat-option value="conditions">
                                Shipment arrived according to prescribed conditions
                            </mat-option>
                            <mat-option value="alarmed">
                                Temperature monitoring device indicated alarm during
                                shipment
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flexible">
                        <mat-label>Shipping Discrepancies (Optional)</mat-label>
                        <textarea
                            matInput
                            placeholder="Shipping Discrepancies (Optional)"
                            [rows]="1"
                            formControlName="discrepancies"
                        ></textarea>
                    </mat-form-field>
                    <!-- TODO: file upload for temperature tracker -->
                </div>
            </mat-step>
            <mat-step
                *ngIf="mode == ObjectViewMode.Edit"
                formGroupName="verification"
                [optional]="true"
                [hasError]="!isFormGroupValid('verification')"
            >
                <ng-template matStepLabel>
                    Shipment Information Verification
                </ng-template>
                <div class="row flex input-field-container" style="padding-left: 5px">
                    <mat-checkbox
                        class="flexible"
                        formControlName="signoff"
                        (change)="signoffChanged($event)"
                    >
                        Shipment Information Verified
                    </mat-checkbox>
                    <div class="info flexible" *ngIf="signoffByAccount">
                        Verified by: {{ signoffByAccount.displayName }}
                    </div>
                </div>
                <div class="row flex input-field-container" style="margin-top: 30px">
                    <mat-form-field class="flexible">
                        <mat-label>Signoff Notes (Optional)</mat-label>
                        <textarea
                            matInput
                            placeholder="Signoff Notes (Optional)"
                            formControlName="signoff_notes"
                        ></textarea>
                    </mat-form-field>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</object-form>
