import { of } from "rxjs";
import { DocumentService } from "./../../../services/program.services";
import { ObjectFactory, ObjectReference } from "./../../../services/models/api-object";
import { Component, ElementRef, Inject, ViewChild, inject } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { FileItem } from "ng2-file-upload";
import { Document } from "../../../services/models/document";
import { DocusignTab } from "../../../services/models/docusign";
import { PDFDocumentProxy, PDFPageProxy } from "ng2-pdf-viewer";
import { CdkDragEnd, CdkDragMove } from "@angular/cdk/drag-drop";
import { mergeMap } from "rxjs/operators";
import { defined } from "src/common/utilities/flatten";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

export interface DocusignTabsDialogData {
    document: Document | FileItem | ObjectReference;
    tabs?: DocusignTab[];
}

@Component({
    templateUrl: "docusign-tabs.dialog.html",
    styleUrls: ["./docusign-tabs.dialog.scss"],
})
export class DocusignTabsDialog extends SessionComponent {
    static readonly PDFRatio = 96 / 72;

    @ViewChild("pdfContainer") pdfContainer?: ElementRef;

    zoom: number = 1.0;
    _page: number = 1;
    get page(): number {
        return this._page;
    }
    set page(v: number) {
        this._page = v;
        this.pdf?.getPage(v).then((p: PDFPageProxy) => (this.currentPage = p));
    }
    currentPage?: PDFPageProxy;
    _tabs: DocusignTab[] = [];
    tempTabs: DocusignTab[] = [];
    set tabs(v: DocusignTab[]) {
        this.tempTabs = this.tabs;
        this._tabs = v;
    }
    get tabs() {
        return this._tabs;
    }

    pdf?: PDFDocumentProxy;
    dragOffset: { x: number; y: number } = { x: 0, y: 0 };

    protected documentService: DocumentService;
    protected snackbar: MatSnackBar;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: DocusignTabsDialogData,
        protected dialogRef: MatDialogRef<DocusignTabsDialog>,
    ) {
        super();

        this.documentService = inject(DocumentService);
        this.snackbar = inject(MatSnackBar);

        if (this.data.tabs) this.tabs = this.data.tabs;
        if (this.data.document instanceof FileItem)
            this.data.document._file
                .arrayBuffer()
                .then(
                    (buffer: ArrayBuffer) =>
                        (this.documentContents = new Uint8Array(buffer)),
                );
        else {
            const obs =
                this.data.document instanceof Document ?
                    this.session.downloadBlob(
                        this.data.document.file,
                        this.data.document.name,
                    )
                :   this.documentService.retrieve(this.data.document.id ?? "0").pipe(
                        mergeMap((doc: Document | undefined) => {
                            if (doc) {
                                this.data.document = doc;
                                return this.session.downloadBlob(doc.file, doc.name);
                            }
                            // TODO: Handle the error case when a document doesn't exist
                            return of(undefined);
                        }),
                    );
            obs.subscribe((blob: Blob | undefined) => {
                blob?.arrayBuffer().then((buffer: ArrayBuffer) => {
                    this.documentContents = new Uint8Array(buffer);
                });
            });
        }
    }
    //the offset prevents the pdf from creating extraneous scroll bars
    protected dimensionOffset = 15;
    get pageWidth(): string {
        let width = this.currentPage?.getViewport({ scale: this.zoom }).width ?? 100;
        width += this.dimensionOffset;
        return width * DocusignTabsDialog.PDFRatio + "px";
    }
    get pageHeight(): string {
        let height = this.currentPage?.getViewport({ scale: this.zoom }).height ?? 100;
        height += this.dimensionOffset;
        return height * DocusignTabsDialog.PDFRatio + "px";
    }
    get pageArray(): number[] {
        return [...Array(this.totalPages).keys()];
    }
    get totalPages(): number {
        return this.pdf?.numPages ?? 0;
    }
    firstPage(): void {
        this.page = 1;
    }
    lastPage(): void {
        this.page = this.totalPages;
    }
    nextPage(): void {
        this.page = this.page < this.totalPages ? this.page + 1 : this.page;
    }
    previousPage(): void {
        this.page = this.page > 1 ? this.page - 1 : this.page;
    }
    onPDFLoaded(pdf: PDFDocumentProxy): void {
        this.pdf = pdf;
        this.page = this.page; // NOSONAR
    }
    isTabWithinBounds(node: Node) {
        const pdf = document.getElementsByClassName("tab-container")[0] as Node;
        return pdf?.contains(node);
    }
    pdfViewer!: Element;
    addTab(event: any): void {
        if (!this.pdfViewer) {
            this.pdfViewer = event.currentTarget;
        }

        const rect = this.pdfViewer.getBoundingClientRect();
        const x = event.clientX - rect.left - this.tabWidth / 2 - this.dimensionOffset;
        const y =
            event.clientY - rect.top - this.tabHeight / 2 - this.dimensionOffset + 12;

        const tab = ObjectFactory.makeObject<DocusignTab>(
            {
                page: this.page,
                x: x / DocusignTabsDialog.PDFRatio,
                y: y / DocusignTabsDialog.PDFRatio,
                document:
                    this.data.document instanceof Document ?
                        this.data.document
                    :   undefined,
                fileItem:
                    this.data.document instanceof FileItem ?
                        this.data.document
                    :   undefined,
            },
            DocusignTab.object_type,
        );

        if (this.isTabWithinBounds(event.target as Node)) {
            this.tabs = defined([...this.tabs, tab]);
        } else {
            this.session.message = "Please click inside of the document.";
        }
    }
    removeTab(event: MouseEvent, tab: DocusignTab, dragged = false): void {
        this.terminateEvent(event);
        if (dragged) {
            this.dragged = this.dragged.filter((t: DocusignTab) => t !== tab);
        } else {
            this.tabs = this.tabs.filter((t: DocusignTab) => t !== tab);
        }
    }

    tabsForCurrentPage(): DocusignTab[] {
        return this.tabs.filter((tab: DocusignTab) => tab.page === this.page);
    }
    draggedtabsForCurrentPage(): DocusignTab[] {
        return this.dragged.filter((tab: DocusignTab) => tab.page === this.page);
    }
    tabWidth = 62;
    tabHeight = 46;
    dragstyleForTab(tab: DocusignTab) {
        const x = tab.x! * DocusignTabsDialog.PDFRatio + this.dimensionOffset;
        const y = tab.y! * DocusignTabsDialog.PDFRatio + this.dimensionOffset - 12;
        return {
            position: "absolute",
            left: x + "px",
            top: y + "px",
            width: this.tabWidth + "px",
            height: this.tabHeight + "px",
        };
    }
    styleForTab(tab: DocusignTab): { [klass: string]: any } {
        const x = tab.x! * DocusignTabsDialog.PDFRatio;
        const y = tab.y! * DocusignTabsDialog.PDFRatio;

        let adjustedX = x + this.dimensionOffset;
        const adjustedY = y + this.dimensionOffset - 12;

        return {
            position: "absolute",
            left: adjustedX + "px",
            top: adjustedY + "px",
            width: this.tabWidth + "px",
            height: this.tabHeight + "px",
        };
    }
    close(): void {
        this.dialogRef.close(this.allTabs);
    }
    get allTabs() {
        return [...this.dragged, ...this.tabs];
    }
    tempDragged: DocusignTab[] = [];
    _dragged: DocusignTab[] = [];
    get dragged() {
        return this._dragged;
    }
    set dragged(v) {
        this.tempDragged = v;
        this._dragged = v;
    }

    dragSignHere(event: CdkDragMove, tab: DocusignTab): void {
        const rect = event.source.element.nativeElement.getBoundingClientRect();
        this.dragOffset = {
            x: event.pointerPosition.x - rect.x,
            y: event.pointerPosition.y - rect.y,
        };
    }
    //if any part of the 2 elements intersect, return true
    doElsCollide(el1: Element, el2: Element): boolean {
        const domRect1 = el1.getBoundingClientRect();
        const domRect2 = el2.getBoundingClientRect();

        return !(
            domRect1.top > domRect2.bottom ||
            domRect1.right < domRect2.left ||
            domRect1.bottom < domRect2.top ||
            domRect1.left > domRect2.right
        );
    }

    dropSignHere(event: CdkDragEnd, tab: DocusignTab): void {
        const sourceRect = event.source.element.nativeElement;
        const { offsetLeft, offsetTop } = sourceRect;
        let { x, y } = event.distance;
        x = offsetLeft + x - this.dimensionOffset;
        y = offsetTop + y - this.dimensionOffset + 12;

        this.dragOffset = { x: 0, y: 0 };
        const newTab = ObjectFactory.makeObject<DocusignTab>(tab) as DocusignTab;
        if (newTab) {
            newTab.x = x / DocusignTabsDialog.PDFRatio;
            newTab.y = y / DocusignTabsDialog.PDFRatio;
        }

        const pdf = document.getElementsByClassName("textLayer")[0];
        if (this.doElsCollide(pdf, sourceRect)) {
            this.dragged = defined([
                ...this.dragged.filter((t: DocusignTab) => t !== tab),
                newTab,
            ]);
            this.tabs = this.tabs.filter((t: DocusignTab) => t !== tab);
        } else {
            this.tabs = this.tempTabs.filter((t) => t !== tab);
            this.dragged = this.tempDragged.filter((t) => t !== tab);
            this.snackbar.open("Please drag inside the document", undefined, {
                duration: 2000,
            });
        }
    }

    documentContents?: Uint8Array;
}
