import { TabChangeEvent } from "src/services/component.services";
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
} from "@angular/core";
import { Message } from "src/services/models/message";
import { htmlToText, isHTML } from "src/common/utilities/utilities";
import { DocumentService } from "src/services/program.services";
import { ObjectReference } from "src/services/models/api-object";
import { Case } from "src/services/models/case";
import { SendTemplateDialog } from "src/common/components/template/send-template.dialog";
import { DocumentRepository } from "src/services/models/document";
import { Inquiry } from "src/services/models/inquiry";
import { TeamMember } from "src/services/models/team";
import { MatDialog } from "@angular/material/dialog";
import { AppNotification } from "src/services/models/appNotification";
import {
    AppNotificationService,
    MessageService,
} from "src/services/notification.services";
import { RequestFilter } from "src/common/utilities/request";
import { SearchableListComponent } from "src/common/components/searchable-list.component";

@Component({
    selector: "communications",
    templateUrl: "./communication.component.html",
    styleUrls: ["./communication.component.scss"],
})
export class CommunicationComponent extends SearchableListComponent<Message> {
    @Input() notifications: AppNotification[] = [];
    private _messageToOpen?: TabChangeEvent;

    @Input()
    get messageToOpen(): TabChangeEvent | undefined {
        return this._messageToOpen;
    }

    set messageToOpen(value: TabChangeEvent | undefined) {
        this._messageToOpen = value;

        if (value?.data?.message) {
            if (value.data?.message instanceof ObjectReference) {
                this.service
                    .resolveReference(value?.data?.message)
                    .subscribe((message) => {
                        if (message) this.toggleMessage(message);
                    });
            } else this.toggleMessage(value?.data?.message as Message);
        } else if (value) {
            this.sendMessage();
        }
    }
    @Output() messageToOpenUsed = new EventEmitter<void>();
    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);

        if (this?.repository instanceof Case && this?.repository?.shared?.id) {
            filters["repository"] =
                `${this.repository.id},${this.repository.shared.id}`;
        } else if (this.repository?.id) {
            filters["repository"] = this.repository.id;
        } else {
            filters["repository"] = "0";
        }

        return filters;
    }
    _repository?: Case | Inquiry;
    get repository() {
        return this._repository;
    }
    @Input() set repository(v: Case | Inquiry | undefined) {
        this._repository = v;
        this.displayMessage = (this.list.items as Message[]).find(
            (m: Message) => m.id == this.displayMessage?.id,
        );
    }
    @Input() viewOnly = false;
    get inquiry(): Inquiry | undefined {
        if (this.repository?.type === "program.case")
            return (this.repository as Case).shared;
        else if (this.repository?.type === "program.inquiry")
            return this.repository as Inquiry;
        return undefined;
    }
    get case(): Case | undefined {
        if (this.repository?.type === "program.case") return this.repository as Case;
        return undefined;
    }
    get isCase(): boolean {
        return this.repository?.type === "program.case";
    }

    displayMessage?: Message;
    displayMessageText?: string; // this gets set to a striped text version if we determine the selected message is html
    showAsHTML: boolean = false;
    documentService: DocumentService;
    columns = ["to", "from", "subject", "date", "actions"];
    showSearch: boolean = false;

    get showMessageAsHTML(): boolean {
        return !!this.displayMessageText && this.showAsHTML;
    }
    get isProgramStaff(): boolean {
        return !!this.inquiry?.isPharmaStaff(this.currentAccount);
    }
    get physician(): TeamMember | undefined {
        return this.inquiry?.physician;
    }
    get physicianEmail(): string | undefined {
        return this.physician?.email;
    }
    get documentSources(): DocumentRepository[] {
        let sources = this.currentAccount ? [this.currentAccount.asReference] : [];
        if (this.repository) sources = [...sources, this.repository.asReference];
        if (this.inquiry) sources = [...sources, this.inquiry.asReference];
        if (this.isProgramStaff) {
            if (this.inquiry?.organization)
                sources = [...sources, this.inquiry.organization];
            if (this.inquiry?.program) sources = [...sources, this.inquiry.program];
        }
        if (
            this.case?.owner &&
            !sources.find((ref: ObjectReference) => ref.id === this.case?.owner.id)
        )
            sources = [...sources, this.case.owner];
        return sources;
    }
    get messages() {
        return this.list.items as Message[];
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        //update the displayed message whenever the list changes
        this.list.listChanged.subscribe(() => 
            this.updateMessageView()
        )
    }
    getTargetNames(message: Message) {
        return message.targets
            .map((t) => (t?.account?.displayName ? t?.account?.displayName : t.email))
            .join(", ");
    }
    isMessageType(m: Message): boolean {
        return m.type.includes("message");
    }
    isToDoType(m: Message): boolean {
        // AB-01/2022 - TODO: when to do messages are implemented(EA-580) this will need to get updated , since they aren't, this will just return false
        return false;
    }
    isToday(d: Date): boolean {
        const today = new Date();
        const td = new Date(d);
        return td.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
    }
    appNotificationService: AppNotificationService;
    constructor(
        protected service: MessageService,
        protected changeDetection: ChangeDetectorRef,
        protected dialog: MatDialog,
    ) {
        super(service, changeDetection, 10, "message-list");
        this.documentService = inject(DocumentService);
        this.appNotificationService = inject(AppNotificationService);

        this.list.ordering = [{ field: "created_at", ascending: false }];
    }

    resetSearchTerm(event: MouseEvent): void {
        this.terminateEvent(event);
        this.searchTermControl.reset();
        this.showSearch = false;
    }
    toggleMessage(message: Message): void {
        console.log(message);
        this.displayMessage = message == this.displayMessage ? undefined : message;
        if (this.displayMessage && isHTML(this.displayMessage.message)) {
            this.displayMessageText = htmlToText(this.displayMessage.message);
        } else this.displayMessageText = undefined;

        this.markNotificationAsRead(message);
        if (this.messageToOpen) {
            this.messageToOpenUsed.emit();
        }
    }
    updateMessageView(): void {
        // discard the message view when it is no longer in the list
        if (
            !!this.displayMessage && 
            !this.list.items.includes(this.displayMessage)
        ){
            this.displayMessage = undefined;
        }
    }
    downloadAttachment(event: MouseEvent, attachment: ObjectReference): void {
        this.terminateEvent(event);
        this.documentService.download(attachment);
    }
    sendMessage(event?: MouseEvent): void {
        this.terminateEvent(event);
        const organization_id =
            this?.inquiry?.organization?.id ?? this.case?.shared?.organization?.id;
        const owners = (
            this.isProgramStaff ?
                [this.inquiry?.program?.id, this.case?.owner.id, organization_id]
            :   [this.case?.owner.id])?.filter((s) => !!s);

        // get the last message subject to use for the quick response
        const sortedMessages = (this.list.items as Message[]).sort(
            (a: Message, b: Message) => {
                const aDate = a.sent ?? new Date();
                const bDate = b.sent ?? new Date();
                if (aDate < bDate) return 1;
                if (aDate == bDate) return 0;
                return -1;
            },
        );
        let lastMessage = sortedMessages?.length ? sortedMessages[0] : undefined;
        let subject = lastMessage?.subject;
        if (!!subject && !subject.toLowerCase().startsWith("re:"))
            subject = "Re: " + subject;

        let contacts =
            this.inquiry?.teamMembers().filter((tm: TeamMember) => !tm.private) ?? [];
        // add back private members on teams the current user exists in
        for (const team of this.inquiry?.teams ?? []) {
            if (this.inquiry?.isMemberOfTeam(this.currentAccount, team.capacity))
                contacts = contacts.concat(
                    team.members.filter((tm: TeamMember) => tm.private),
                );
        }
        // remove currentAccount from contacts
        contacts = contacts.filter(
            (tm: TeamMember) => tm.account.id != this.currentAccount?.id,
        );
        const uniqueContacts = [
            ...new Map(
                contacts.map((contact: TeamMember) => [contact.account?.id, contact]),
            ).values(),
        ];

        let primary = uniqueContacts.find(
            (r: TeamMember) => r.email == lastMessage?.sender.email,
        );
        if (!primary)
            primary = uniqueContacts.find(
                (r: TeamMember) =>
                    lastMessage?.targets.length &&
                    r.email == lastMessage?.targets[0].email,
            );
        if (!primary) primary = this.physician;
        if (this.messageToOpen) {
            this.messageToOpen = undefined;
        }
        const ref =
            this?.repository?.type === Case.object_type ?
                (this.repository as Case).shared.asReference
            :   this.repository?.asReference;
        this.dialog
            .open(SendTemplateDialog, {
                data: {
                    to: primary,
                    subject: subject,
                    owner: owners.join(","),
                    context: this.inquiry?.data,
                    reference: ref,
                    allowInvite: false,
                    sources: this.documentSources,
                    repository: this.inquiry,
                    uploadOwner: this.inquiry?.organization ?? this.case?.owner,
                    contacts: uniqueContacts,
                    send_notification: false,
                },
                disableClose: true,
                hasBackdrop: true,
            })
            .afterClosed()
            .subscribe(() => {
                this.list.refresh();
            });
    }

    hasNotification(message: Message): boolean {
        const notification = this.getNotificationForMessage(message);

        return !!notification;
    }
    matBadge(message: Message) {
        const hasNotification = this.hasNotification(message);
        if (!hasNotification) return undefined;
        return "!";
    }
    getNotificationForMessage(message: Message) {
        return this?.notifications?.find((n) => n.object.id === message?.id);
    }

    markNotificationAsRead(message: Message) {
        const notification = this.getNotificationForMessage(message);
        if (!notification) return;

        this.notifications = this.notifications.filter((n) => n.id !== notification.id);
        this.appNotificationService
            .clear(notification)
            .subscribe({ error: (e) => console.log(e) });
    }
}
