import { Component, Inject, inject } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { Account } from "../../../services/models/account";
import { Program } from "../../../services/models/program";
import { ProgramService } from "../../../services/program.services";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Role } from "../../../services/models/role";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "add-program-admin",
    templateUrl: "./add-admin.dialog.html",
    styleUrls: ["./program.component.scss"],
})
export class AddProgramAdminDialog extends SessionComponent {
    available: Account[] = [];
    program: Program;
    programService: ProgramService;
    accountControl: UntypedFormControl;
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<AddProgramAdminDialog>,
    ) {
        super();
        this.program = data.program;
        this.available = data.available;
        this.programService = inject(ProgramService);
        this.accountControl = new UntypedFormControl(null, Validators.required);
    }

    isProgramAdmin(account: Account): boolean {
        return !!this.program.roles?.find(
            (r: Role) =>
                r.account?.id == account.id && r.role.indexOf("object.admin") != -1,
        );
    }

    onSave(): void {
        this.loading = true;
        const account = this.accountControl.value as Account;

        this.programService
            .addRole(this.program.serialize(), "object.admin", account.serialize())
            .subscribe((roles: Role[]) => this.dialogRef.close(roles));
    }
}
