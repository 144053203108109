<object-form
    class="flexible"
    [controller]="controller"
    [formGroup]="formGroup"
    [hideControls]="true"
>
    <div class="loading-placeholder" *ngIf="!fullObject"></div>
    <div class="wrapper flex stack" *ngIf="!!fullObject">
        <div class="row flex flexible">
            <div class="column flex flexible">
                <div class="info field-header">Timestamp:</div>
                <div>
                    {{ fullObject.timestamp | localizedDate }}
                    {{ fullObject.timestamp | date: "mediumTime" }}
                </div>
            </div>
            <div class="column flex flexible">
                <div class="info field-header">Account:</div>
                <div *ngIf="!!fullObject.account">
                    {{ fullObject.account.displayName }}
                </div>
                <div *ngIf="!fullObject.account" class="italic">MedaSystems</div>
            </div>
        </div>
        <div class="row flex flexible">
            <div class="column flex flexible">
                <div class="info field-header">Event:</div>
                <div>{{ entryEvent(fullObject) }}</div>
            </div>
            <div class="column flex flexible">
                <div class="info field-header">Object Type:</div>
                <div>{{ entryType(fullObject) }}</div>
            </div>
        </div>
        <div class="row flex flexible">
            <div class="column flex flexible">
                <div class="info field-header">Object State:</div>
                <div class="audit-state">
                    <pre>{{ state }}</pre>
                </div>
            </div>
        </div>
        <div
            class="audit-table"
            *ngIf="!!fullObject.history && fullObject.history.length > 0"
        >
            <mat-table
                [dataSource]="pagedHistory"
                matSort
                (matSortChange)="onSortChange($event)"
            >
                <mg-container matColumnDef="timestamp">
                    <mat-header-cell *matHeaderCellDef>Timestamp</mat-header-cell>
                    <mat-cell
                        *matCellDef="let entry"
                        [class.audit-top]="entry.expanded"
                        class="pointer"
                        (click)="changeObject(entry)"
                    >
                        <div class="flex stack">
                            <div>{{ entry.timestamp | localizedDate }}</div>
                            <div class="secondary small">
                                {{ entry.timestamp | date: "mediumTime" }}
                            </div>
                        </div>
                    </mat-cell>
                </mg-container>
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef>Account</mat-header-cell>
                    <mat-cell
                        *matCellDef="let entry"
                        [class.audit-top]="entry.expanded"
                        class="pointer"
                        (click)="changeObject(entry)"
                    >
                        <span *ngIf="entry.account">
                            {{ entry.account.displayName }}
                        </span>
                        <span *ngIf="!entry.account" class="italic">MedaSystems</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="reference_type">
                    <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                    <mat-cell
                        *matCellDef="let entry"
                        [class.audit-top]="entry.expanded"
                        class="pointer"
                        (click)="changeObject(entry)"
                    >
                        <div class="flex stack">
                            <div>{{ entryType(entry) }}</div>
                            <div
                                class="secondary small"
                                *ngIf="entry.reference?.displayName"
                            >
                                {{ entry.reference.displayName }}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="event">
                    <mat-header-cell *matHeaderCellDef>Event</mat-header-cell>
                    <mat-cell
                        *matCellDef="let entry"
                        class="flex stack center"
                        [class.audit-top]="entry.expanded"
                        class="pointer"
                        (click)="changeObject(entry)"
                    >
                        <div class="flex full center">
                            <div class="spacer">{{ entryEvent(entry) }}</div>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.selected-entry]="row?.id == fullObject.id"
                ></mat-row>
            </mat-table>
            <mat-paginator
                (page)="handlePageEvent($event)"
                [pageSizeOptions]="pageSizeOptions"
                [pageSize]="perPage"
                [length]="pagedHistory.length"
            ></mat-paginator>
        </div>
    </div>
    <div form-actions class="row flex mat-dialog-actions">
        <button
            mat-raised-button
            type="button"
            color="warn"
            (click)="restoreObject()"
            *ngIf="!!fullObject?.reference?.is_deleted"
        >
            <mat-icon>warning</mat-icon>
            Restore Object
        </button>
        <div class="spacer"></div>
        <button mat-flat-button type="button" class="fixed" mat-dialog-close>
            Close
        </button>
    </div>
    <div class="absolute audit-loading" *ngIf="loading">
        <div class="row flex center">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</object-form>
