<h3 mat-dialog-title>Update Email</h3>
<object-form
    [formGroup]="formGroup"
    [controller]="controller"
    [hideControls]="true"
    [showErrors]="false"
>
    <mat-dialog-content>
        <div class="wrapper flex stack full">
            <mat-form-field class="full">
                <mat-label>New Email Address</mat-label>
                <input matInput formControlName="email" />
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Password</mat-label>
                <input
                    matInput
                    formControlName="password"
                    type="password"
                    autocomplete="off"
                />
                <mat-hint>
                    Please verify your password to continue with this request.
                </mat-hint>
            </mat-form-field>
        </div>
    </mat-dialog-content>
</object-form>
<mat-dialog-actions>
    <div class="spacer"></div>
    <button mat-flat-button type="button" class="fixed" mat-dialog-close>Cancel</button>
    <mat-spinner *ngIf="loading" mode="indeterminate" [diameter]="50"></mat-spinner>
    <button
        mat-flat-button
        type="button"
        class="fixed"
        color="accent"
        [disabled]="!formValid"
        (click)="formValid && updateEmail()"
        *ngIf="!loading"
    >
        Save
    </button>
</mat-dialog-actions>
