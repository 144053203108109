<div class="wrapper flexible flex stack" *ngIf="group">
    <div
        class="row flex stack"
        *ngFor="let field of sortedFields; index as i"
        [formGroup]="group"
    >
        <data-form-field
            class="flexible"
            *ngIf="!isGroupField(field) && conditionValid(field)"
            [form]="form"
            [field]="field"
            [value]="fieldValues(field)"
            [control]="fieldControl(field)"
            [mode]="mode"
            [productOptions]="productOptions"
            [allowPartial]="allowPartial"
        ></data-form-field>
        <ng-container *ngIf="isGroupField(field) && conditionValid(field)">
            <div class="full between" [class.group-header]="!!i">
                <h3>{{ field.displayName }}</h3>
            </div>

            <quill-view-html
                class="full subtitle"
                *ngIf="!viewOnly"
                [content]="subtitle(field)"
            ></quill-view-html>
            <div class="group-indent flexible flex stack">
                <data-form-group
                    class="flexible flex stack"
                    [form]="form"
                    [mode]="mode"
                    [parent]="this"
                    [formField]="field"
                    [viewOnly]="viewOnly"
                    [group]="fieldGroup(field)"
                    [fields]="fieldChildren(field)"
                    [productOptions]="productOptions"
                    [values]="fieldValues(field)"
                    [allowPartial]="allowPartial"
                ></data-form-group>
            </div>
        </ng-container>
    </div>
</div>
