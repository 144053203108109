<div class="wrapper absolute flex stack">
    <div class="inquiry-overview flex mat-elevation-z4 pharma">
        <div class="header-panel flex stack case-reference">
            <div *ngIf="!!fullObject?.program">
                {{ fullObject?.program?.displayName }}
            </div>
            <div *ngIf="!fullObject?.program" class="secondary">
                Program Not Specified
            </div>
            <div class="secondary small">
                Inquiry {{ fullObject?.reference_identifier }}
            </div>
        </div>
        <div class="header-panel flex stack physician">
            {{ physicianName }}
        </div>
        <div class="header-panel flex stack logo-panel">
            {{ patientInstitution }}
        </div>
    </div>
    <ng-container *ngIf="mode != ObjectViewMode.Create">
        <div *ngIf="!fullObject || !currentAccount" class="spinner">
            <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
            <div class="spinner-message">Loading Inquiry ...</div>
        </div>
        <div
            *ngIf="fullObject && currentAccount"
            class="wrapper flexible flex stack inquiry-view"
            cdkScrollable
        >
            <detail-tab-group>
                <div
                    tab-header
                    class="content-card flex stack between status-container"
                >
                    <div class="flex between full status-header">
                        <div>Inquiry Status</div>
                        <mat-chip
                            class="case-status-chip"
                            *ngIf="
                                !caseStatus?.attributes?.is_default_inquiry_status &&
                                !caseStatus?.attributes?.closes_case
                            "
                            color="accent"
                            selected
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                        >
                            {{ caseStatus?.display_name }}
                        </mat-chip>
                        <mat-chip
                            class="case-status-chip"
                            *ngIf="caseStatus?.attributes?.closes_case"
                            color="warn"
                            mat-icon-button
                            selected
                            [matMenuTriggerFor]="menu"
                        >
                            {{ caseStatus?.display_name }}
                        </mat-chip>
                        <mat-chip
                            class="case-status-chip"
                            *ngIf="caseStatus?.attributes?.is_default_inquiry_status"
                            selected
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                        >
                            {{ caseStatus?.display_name }}
                        </mat-chip>
                        <mat-menu #menu="matMenu">
                            <button
                                mat-menu-item
                                *ngFor="let status of caseStatuses"
                                (click)="changeStatus($event, status)"
                            >
                                <span>{{ status.display_name }}</span>
                            </button>
                        </mat-menu>
                    </div>
                    <mat-form-field
                        color="primary"
                        class="assignee"
                        *ngIf="isPharmaStaff"
                    >
                        <mat-label>Assigned To</mat-label>
                        <mat-select
                            [formControl]="assignee"
                            [disabled]="viewOnly"
                            *ngIf="fullObject"
                        >
                            <mat-option (click)="unAssign()" *ngIf="isAssigned">
                                Unassign
                            </mat-option>
                            <mat-option
                                *ngFor="let option of availableAssignees"
                                [value]="option"
                            >
                                {{ option.displayName }}
                            </mat-option>
                        </mat-select>
                        <mat-hint>
                            <span
                                class="pointer brand-medium"
                                (click)="assignToMe($event)"
                                *ngIf="!isAssignedToMe"
                            >
                                <mat-icon>assignment_ind</mat-icon>
                                Assign to Me
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <detail-tab label="Details">
                    <div class="content-card">
                        <div class="card-header flex">
                            <div class="title">Inquiry Details</div>
                            <div class="spacer"></div>
                            <div class="header-actions flex">
                                <button
                                    [class.button-disabled]="
                                        !summary?.dataForms?.length
                                    "
                                    mat-flat-button
                                    class="brand-medium"
                                    (click)="exportAsPdf($event)"
                                >
                                    <mat-icon matTooltip="Download">
                                        file_download
                                    </mat-icon>
                                    Export Details
                                </button>
                                <button
                                    [class.button-disabled]="viewOnly"
                                    mat-flat-button
                                    class="brand-medium"
                                    [matTooltip]="closeTooltip"
                                    (click)="closeCase($event)"
                                    *ngIf="isPharmaStaff && isInquiryActive"
                                >
                                    <mat-icon>close</mat-icon>
                                    Close Inquiry
                                </button>
                                <button
                                    [class.button-disabled]="viewOnly"
                                    mat-flat-button
                                    class="brand-medium"
                                    [matTooltip]="responseTooltip"
                                    (click)="quickResponse($event)"
                                >
                                    <mat-icon>reply</mat-icon>
                                    Quick Response
                                </button>
                                <button
                                    [class.button-disabled]="!canCreateCase"
                                    mat-flat-button
                                    class="brand-medium"
                                    [matTooltip]="caseTooltip"
                                    (click)="createCase($event)"
                                    *ngIf="isPharmaStaff && isInquiryActive"
                                >
                                    <mat-icon>open_in_new</mat-icon>
                                    Create Case
                                </button>
                            </div>
                        </div>
                        <case-summary
                            [showExportButton]="false"
                            [repository]="fullObject"
                        ></case-summary>
                    </div>
                </detail-tab>
                <detail-tab label="Documents">
                    <ng-template detail-tab-label>
                        <div class="flex">
                            <div
                                class="flex"
                                style="padding-right: 1rem"
                                [matBadge]="matBadge(documentNotifications || [])"
                                matBadgeColor="accent"
                                matBadgeOverlap="false"
                                matBadgePosition="after"
                            >
                                <span class="flexible">Documents</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="content-card">
                        <document-repository
                            [viewOnly]="viewOnly"
                            *ngIf="!!fullObject"
                            [sources]="documentSources"
                            [repository]="fullObject"
                            [owner]="fullObject.organization"
                            [notifications]="documentNotifications"
                            [documentToOpen]="documentToOpen"
                            (documentToOpenUsed)="documentToOpen = undefined"
                        ></document-repository>
                    </div>
                </detail-tab>
                <detail-tab label="Communications">
                    <ng-template detail-tab-label>
                        <div
                            class="flex"
                            [matBadge]="matBadge(messageNotifications || [])"
                            matBadgeColor="accent"
                            matBadgeOverlap="false"
                        >
                            <span class="flexible">Communications</span>
                        </div>
                    </ng-template>
                    <div class="content-card">
                        <communications
                            *ngIf="!!fullObject"
                            [repository]="this.fullObject"
                            [viewOnly]="viewOnly"
                            [notifications]="messageNotifications"
                            [messageToOpen]="messageEvent"
                            (messageToOpenUsed)="messageEvent = undefined"
                        ></communications>
                    </div>
                </detail-tab>
            </detail-tab-group>
        </div>
    </ng-container>
</div>
