import { Account } from "./account";
import {
    APIObject,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
} from "./api-object";

export type RoleDefinition = { value: string; display: string; type: string };

export class Role extends APIObject {
    static object_type: string = "iam.role";
    object!: ObjectOrReference<APIObject>;
    account: OptionalObjectOrReference<Account>;
    email!: string; // read-only
    role!: string;
    private!: boolean;

    // Organizations that the account has a role at
    organizations!: ObjectReference[]; // Organization, read-only

    static roles: RoleDefinition[] = [
        // JT (10 Sep 2022) Removed all non permission roles
        // Object Roles - order of this list is important (least access to most)
        { value: "object.none", display: "No Access", type: "object" },
        { value: "object.view", display: "View Only", type: "object" },
        { value: "object.edit", display: "Editor", type: "object" },
        { value: "object.manager", display: "Manager", type: "object" },
        { value: "organization.manager", display: "Manager", type: "organization" },
        { value: "object.admin", display: "Administrator", type: "object" },
    ];

    // JT (10 Setp 2022) Need to keep this until all orgs are migrated to new roles
    static get organizationRoles(): string {
        return Role.roles
            .filter((r: RoleDefinition) => r.type == "organization")
            .map((r: RoleDefinition) => r.value)
            .join("|");
    }
    static get allOrganizationRoles(): RoleDefinition[] {
        return Role.roles.filter(
            (r: RoleDefinition) =>
                r.type == "organization" || r.type == "provider" || r.type == "patient",
        );
    }
    static get objectRoles(): string {
        return Role.roles
            .filter((r: RoleDefinition) => r.type == "object")
            .map((r: RoleDefinition) => r.value)
            .join("|");
    }

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["account", "private"]);
        this._readOnly.push(...["organizations", "email"]);
        this._references.push(...["account", "object"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "object", ObjectReference);
        this.setMember(data, patch, "account", Account);
        this.setMember(data, patch, "email");
        this.setMember(data, patch, "role");
        this.setMember(data, patch, "private");
        this.setMember(data, patch, "organizations", ObjectReference, true);
    }

    get isHcpRole() {
        return this.role.includes("provider") || this.role.includes("physician");
    }
    get orgRoles(): RoleDefinition[] {
        const parts = this.role.split("|");
        const orgRoles = Role.allOrganizationRoles;
        const defs = parts
            .map((r: string) =>
                orgRoles.find(
                    (v: RoleDefinition) => v.value.toLowerCase() === r.toLowerCase(),
                ),
            )
            .filter((r: RoleDefinition | undefined) => !!r)
            .map((r: RoleDefinition | undefined) => r as RoleDefinition);
        return defs;
    }
    get objectRole(): RoleDefinition | undefined {
        const parts = this.role
            .split("|")
            .map((p: string) =>
                Role.roles.find(
                    (r: RoleDefinition) => r.value.toLowerCase() == p.toLowerCase(),
                ),
            )
            .filter((r: RoleDefinition | undefined) => !!r && r.type == "object");
        return parts.length ? parts[0] : undefined;
    }
    get messageRole(): RoleDefinition | undefined {
        const parts = this.role
            .split("|")
            .map((p: string) =>
                Role.roles.find(
                    (r: RoleDefinition) => r.value.toLowerCase() == p.toLowerCase(),
                ),
            )
            .filter((r: RoleDefinition | undefined) => !!r && r.type == "message");
        return parts.length ? parts[0] : undefined;
    }

    get roleDisplay(): string {
        const roles = this.role
            .split("|")
            .map((r: string) => Role.roles.find((ar: RoleDefinition) => ar.value == r))
            .filter((rd: RoleDefinition | undefined) => !!rd) as RoleDefinition[];
        return roles.length ? roles[0].display : "Unspecified Role";
    }

    get roleError(): string {
        const parts = this.role.split("|");
        let def = Role.roles.find(
            (r: RoleDefinition) => r.value.toLowerCase() == parts[0].toLowerCase(),
        );
        let ret = !def ? `Unknown role: ${parts[0]}` : "";

        return ret;
    }
}
