import { Component, ViewEncapsulation, inject } from "@angular/core";
import {
    AccountService,
    OrganizationService,
    RoleService,
} from "src/services/iam.services";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    constructor() {
        inject(AccountService); // Need to inject these here otherwise SessionService cannot create Acccount objectcs
        inject(RoleService);
        inject(OrganizationService);
    }
}
