import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "help-dialog",
    templateUrl: "./help-dialog.component.html",
    styleUrls: ["./help-dialog.component.scss"],
})
export class HelpDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) protected data: any) {}
}
