import { DatePipe } from "@angular/common";
import { Component, Inject, Input, ViewChild, inject } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    UntypedFormArray,
    UntypedFormGroup,
} from "@angular/forms";
import { CompoundDataType, DataForm, DataType } from "src/services/models/data";
import { SessionService } from "src/services/session.service";
import { ObjectViewMode } from "../../object.component";
import { DataFormComponent } from "../data-form.component";
import { FileItem } from "ng2-file-upload";
import { MatTable } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "dt-multiple",
    template: `
        <mat-table [dataSource]="formArray.controls">
            <ng-container *ngFor="let column of valueColumns" [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef>
                    {{ columnLabel(column) }}
                </mat-header-cell>
                <mat-cell *matCellDef="let value" (click)="editValue($event, value)">
                    {{ columnValue(column, value) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
                <mat-cell *matCellDef="let value" class="overflow">
                    <button
                        mat-icon-button
                        type="button"
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ value: value }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="add-value">
                <mat-footer-cell
                    *matFooterCellDef
                    [attr.colspan]="compoundColumns.length"
                    (click)="addValue($event)"
                >
                    <mat-icon color="primary">add_box</mat-icon>
                    <div class="primary multiple-add">Add {{ singular }}</div>
                </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="no-values">
                <mat-footer-cell
                    *matFooterCellDef
                    [attr.colspan]="compoundColumns.length"
                >
                    No {{ label }} defined
                </mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="compoundColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: compoundColumns"></mat-row>
            <mat-footer-row
                *matFooterRowDef="footerColumn"
                [class.hidden-footer]="!canEdit && !!formArray.controls.length"
            ></mat-footer-row>
            <mat-menu #overflow="matMenu">
                <ng-template matMenuContent let-value="value">
                    <button mat-menu-item (click)="editValue($event, value)">
                        Edit
                    </button>
                    <button mat-menu-item (click)="removeValue($event, value)">
                        Remove
                    </button>
                </ng-template>
            </mat-menu>
        </mat-table>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeMultiple {
    @Input() type?: DataType;
    @Input() attributes: any;
    @Input() label?: string;
    @Input() mode: ObjectViewMode = ObjectViewMode.View;
    @Input() control?: AbstractControl;
    @Input() form?: DataForm;

    @ViewChild(MatTable) _matTable?: MatTable<any>;

    get valueColumns(): string[] {
        if (this.type?.isCompound)
            return this.type?.compound?.map((cdt: CompoundDataType) => cdt.name!) ?? [];
        return ["value"];
    }
    get compoundColumns(): string[] {
        let columns = this.valueColumns;
        if (this.canEdit) columns = [...columns, "actions"];
        return columns;
    }
    get footerColumn(): string[] {
        if (this.canEdit) return ["add-value"];
        return ["no-values"];
    }
    get singular(): string {
        return this.attributes?.layout?.singular || this.label;
    }
    get canEdit(): boolean {
        return this.mode != ObjectViewMode.View;
    }
    get formArray(): UntypedFormArray {
        return this.control as UntypedFormArray;
    }

    protected dialog: MatDialog;
    protected datePipe: DatePipe;

    constructor() {
        this.dialog = inject(MatDialog);
        this.datePipe = inject(DatePipe);
    }

    formatCompoundValue(
        compound: DataType,
        value: any,
        attributes: any = {},
    ): string | undefined {
        if (!compound.compound?.length) {
            if (value instanceof Date) {
                return this.datePipe.transform(value, "mediumDate") ?? undefined;
            } else if (compound.name == "datetime" || compound.name == "date") {
                const date = Date.parse(value as string);
                if (date)
                    return this.datePipe.transform(date, "mediumDate") ?? undefined;
            } else if (
                Array.isArray(value) &&
                value.every((v) => v instanceof FileItem)
            ) {
                return value.map((file: FileItem) => file.file.name).join(", ");
            }
            return value;
        } else if (attributes?.layout?.compound == "inline") {
            return compound.compound
                .filter((child: CompoundDataType) => value.hasOwnProperty(child.name))
                .map((child: CompoundDataType) =>
                    this.formatCompoundValue(
                        child.child,
                        value[child.name!],
                        attributes?.hasOwnProperty(child.name) ?
                            attributes[child.name!]
                        :   {},
                    ),
                )
                .join(" ");
        } else {
            return compound.compound
                .filter((child: CompoundDataType) => value.hasOwnProperty(child.name))
                .map((child: CompoundDataType) => {
                    const label = this.compoundLabel(child);
                    const child_value = this.formatCompoundValue(
                        child.child,
                        value[child.name!],
                        attributes?.hasOwnProperty(child.name) ?
                            attributes[child.name!]
                        :   {},
                    );
                    return label + ": " + child_value;
                })
                .join("\n");
        }
    }

    columnLabel(column: string): string {
        if (this.type?.isCompound) {
            const compound = this.type?.compound?.find(
                (cdt: CompoundDataType) => cdt.name == column,
            );
            if (compound) return this.compoundLabel(compound);
        }
        return this.attributes?.advanced?.multiple_label || "Values";
    }
    columnValue(column: string, value: AbstractControl): any {
        if (this.type?.isCompound) {
            const compound = this.type?.compound?.find(
                (cdt: CompoundDataType) => cdt.name == column,
            );
            if (compound) return this.compoundValue(compound, value);
        }
        if (this.type)
            return this.formatCompoundValue(this.type, value.value, this.attributes);
        return undefined;
    }

    compoundAttributes(compound: CompoundDataType): any {
        return this.attributes ? this.attributes[compound.name!] : {};
    }
    compoundLabel(compound: CompoundDataType): string {
        return (
            this.compoundAttributes(compound)?.label ||
            this.compoundAttributes(compound)?.placeholder ||
            compound.display_name
        );
    }
    compoundValue(compound: CompoundDataType, value: AbstractControl): any {
        if (
            value.value?.hasOwnProperty(compound.name) &&
            compound.child instanceof DataType
        ) {
            return this.formatCompoundValue(
                compound.child,
                value.value[compound.name!],
                this.compoundAttributes(compound),
            );
        }
        return undefined;
    }

    removeValue(event: MouseEvent, value: UntypedFormGroup): void {
        const index = this.formArray.controls.findIndex(
            (ctrl: AbstractControl) => ctrl == value,
        );
        if (index != -1) {
            this.formArray.removeAt(index);
            this.control?.markAsDirty();
            this._matTable?.renderRows();
        }
    }
    addValue(event: MouseEvent): void {
        this.editValue(event);
    }
    editValue(event: MouseEvent, value?: UntypedFormGroup): void {
        const adding = value == undefined;
        let control: UntypedFormGroup | FormControl;
        if (!this.type?.isCompound) {
            control = new FormControl(value?.value);
            control.addValidators(
                DataFormComponent.getValidators(this.type!, this.attributes),
            );
        } else {
            control = DataFormComponent.buildCompoundFormGroup(
                this.type!,
                value?.value,
                this.attributes,
            );
        }

        this.dialog
            .open(DataTypeMultipleDialog, {
                data: {
                    adding: adding,
                    type: this.type,
                    mode: this.mode,
                    formGroup: control,
                    attributes: this.attributes,
                    label: this.label,
                    form: this.form,
                },
                minWidth: "75vw",
                maxWidth: "95vw",
                maxHeight: "95vh",
                hasBackdrop: true,
                disableClose: true,
            })
            .afterClosed()
            .subscribe((updatedValue: any) => {
                if (updatedValue && value) {
                    value.setValue(updatedValue);
                    this.control?.markAsDirty();
                } else if (updatedValue) {
                    this.formArray.push(control);
                    this.control?.markAsDirty();
                    this._matTable?.renderRows();
                }
            });
    }
}

@Component({
    selector: "data-type-multiple",
    template: `
        <h3 mat-dialog-title>{{ title }}</h3>
        <mat-dialog-content>
            <data-type
                class="flex flexible"
                [form]="form"
                [label]="label"
                [type]="type"
                [attributes]="attributes"
                [control]="formGroup"
                [mode]="mode"
                [required]="true"
            ></data-type>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="spacer"></div>
            <button mat-flat-button type="button" class="fixed" (click)="cancelEdit()">
                Cancel
            </button>
            <button
                mat-flat-button
                type="button"
                class="fixed"
                color="accent"
                *ngIf="!adding"
                [disabled]="!formGroup.valid"
                (click)="formGroup.valid && confirmEdit()"
            >
                Update
            </button>
            <button
                mat-flat-button
                type="button"
                class="fixed"
                color="accent"
                *ngIf="!!adding"
                [disabled]="!formGroup.valid"
                (click)="formGroup.valid && confirmEdit()"
            >
                Add
            </button>
        </mat-dialog-actions>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeMultipleDialog {
    protected session: SessionService;

    get title(): string {
        return (
            (this.adding ? "Add " : "Update ") +
            (this.attributes?.layout?.singular || this.label)
        );
    }
    get label(): string {
        return this.data.label;
    }
    get type(): DataType {
        return this.data.type;
    }
    get attributes(): any {
        return this.data.attributes;
    }
    get adding(): boolean {
        return this.data.adding;
    }
    get formGroup(): UntypedFormGroup {
        return this.data.formGroup;
    }
    get mode(): ObjectViewMode {
        return this.data.mode;
    }
    get form() {
        return this.data.form || undefined;
    }
    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<DataTypeMultipleDialog>,
    ) {
        this.session = inject(SessionService);
        this.session.onLogout.subscribe(() => this.dialogRef.close());
    }

    cancelEdit(): void {
        this.dialogRef.close();
    }
    confirmEdit(): void {
        this.dialogRef.close(this.formGroup.value);
    }
}
