import { RoleDefinition } from "src/services/models/role";
import { Role } from "./role";
import {
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
} from "./api-object";
import { APIObject } from "src/services/models/api-object";
import { Account, AccountReference } from "./account";
import { Organization } from "./organization";
import { Program } from "./program";
import { Inquiry } from "./inquiry";
import { Case } from "./case";
import { Capability } from "./capability";

export class Team extends APIObject {
    static object_type: string = "program.team";
    organization!: ObjectOrReference<Organization>;
    program: OptionalObjectOrReference<Program>;
    display_name?: string;
    team_type!: string;
    members?: TeamMember[];

    get displayName(): string | undefined {
        return this.display_name;
    }

    initialize(data?: any, patch: boolean = false): void {
        this._optional.push(...["program", "display_name", "members"]);
        this._references.push(...["organization", "program"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "organization", Organization);
        this.setMember(data, patch, "program", Program);
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "team_type");
        this.setMember(data, patch, "members", TeamMember, true);
    }
}

export class TeamMember extends APIObject {
    static object_type: string = "program.teammember";
    team?: OptionalObjectOrReference<Team>;
    override?: OptionalObjectOrReference<CaseTeam>;
    account!: ObjectReference;
    role!: string;
    email!: string;
    permission?: Role;
    capabilities?: Capability[];
    private!: boolean;
    is_invited!: boolean;
    invite_expired!: boolean;
    is_active!: boolean;
    static DefinedRoles: RoleDefinition[] = [
        { value: "owner", display: "Owner", type: "pharma" },
        { value: "manager", display: "Manager", type: "organization" },
        { value: "admin", display: "Administrator", type: "system" },
        { value: "member", display: "Team Member", type: "system" },
        { value: "physician", display: "Physician", type: "provider" },
    ];

    get displayName(): string | undefined {
        return this.account.displayName;
    }

    get capabilityDisplay() {
        return this.capabilities?.map((c) => c.displayName).join(", ") ?? "";
    }
    initialize(data?: any, patch: boolean = false): void {
        this._optional.push(...["permission", "team", "override"]);
        this._readOnly.push(...["email", "is_invited", "invite_expired", "is_active"]);
        this._references.push(...["team", "override", "account"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "team", Team);
        this.setMember(data, patch, "is_invited");
        this.setMember(data, patch, "invite_expired");
        this.setMember(data, patch, "is_active");
        this.setMember(data, patch, "override", CaseTeam);
        this.setMember(data, patch, "account", AccountReference);
        this.setMember(data, patch, "role");
        this.setMember(data, patch, "email");
        this.setMember(data, patch, "permission", Role);
        this.setMember(data, patch, "private");

        this.setMember(data, patch, "capabilities", Capability, true);
    }

    get permissionLevel(): string {
        const permissions = this.permission?.role.split("|");
        let level =
            Role.roles
                .filter(
                    (rd: RoleDefinition) =>
                        rd.type == "object" || rd.type == "organization",
                ) // only grab the object roles
                .reverse() // reverse it so it's most privileged to least
                .find((rd: RoleDefinition) =>
                    permissions?.find((p: string) => p == rd.value),
                )?.value ?? "object.view"; // find the first one we have // default permission is object.view

        const roles = this.role.split("|");
        if (roles.indexOf("admin") != -1 || roles.indexOf("owner") != -1)
            level = "object.admin"; // admin and owner role implies admin permissions
        else if (roles.indexOf("manager") != -1) level = "object.edit"; // Manager has edit acces per MED-1500

        return level;
    }

    hasEquivilentRoles(roles: string[]): boolean {
        const currentRoles = this.role.split("|");
        let sameRoles = false;
        if (currentRoles.length === roles.length) {
            for (let r of currentRoles) {
                sameRoles = roles.indexOf(r) !== -1;
                if (!sameRoles) break;
            }
        }
        return sameRoles;
    }
}
export class TeamMemberFactory extends ObjectFactory<TeamMember> {
    constructor() {
        super(TeamMember);
    }
}
export class CaseTeam extends APIObject {
    static object_type: string = "program.caseteam";
    team!: Team;
    inquiry!: ObjectOrReference<Inquiry>;
    case?: ObjectOrReference<Case>;
    capacity!: string;
    _members!: TeamMember[]; // read-only - base team membership
    overrides!: TeamMember[]; // Ad-hoc team members, and base-team overrides
    inherited!: TeamMember[]; // read-only - Implicit roles granted by admin status

    get caseTeamName(): string | undefined {
        return this.case ? this.case.displayName + " Team" : undefined;
    }
    get inquiryTeamName(): string | undefined {
        return this.inquiry ? this.inquiry.displayName + " Team" : undefined;
    }
    get displayName(): string | undefined {
        return this.team?.displayName ?? this.caseTeamName ?? this.inquiryTeamName;
    }

    initialize(data?: any, patch: boolean = false): void {
        this._optional.push(...["case"]);
        this._readOnly.push(...["inherited", "members"]);
        this._references.push(...["inquiry", "case"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "team", Team);
        this.setMember(data, patch, "inquiry", Inquiry);
        this.setMember(data, patch, "case", Case);
        this.setMember(data, patch, "capacity");
        this.setMember(data, patch, "members", TeamMember, true, undefined, "_members");
        this.setMember(data, patch, "overrides", TeamMember, true);
        this.setMember(data, patch, "inherited", TeamMember, true);
    }

    inheritedForAccount(account?: Account | ObjectReference): TeamMember | undefined {
        return (
            this.overrideForAccount(account) ??
            this.inherited.find((tm: TeamMember) => tm.account.id == account?.id)
        );
    }
    overrideForAccount(account?: Account | ObjectReference): TeamMember | undefined {
        return this.overrides.find((tm: TeamMember) => tm.account.id == account?.id);
    }
    memberForAccount(account?: Account | ObjectReference): TeamMember | undefined {
        return (
            this.overrideForAccount(account) ??
            this._members.find((tm: TeamMember) => tm.account.id == account?.id)
        );
    }
    roleForAccount(account?: Account | ObjectReference): string | undefined {
        return this.memberForAccount(account)?.role;
    }
    permissionForAccount(account?: Account | ObjectReference): string {
        return (
            this.memberForAccount(account)?.permissionLevel ??
            this.inheritedForAccount(account)?.permissionLevel ??
            "object.none"
        );
    }
    get members(): TeamMember[] {
        const members = this._members.filter(
            (tm: TeamMember) => !this.inheritedForAccount(tm.account),
        );
        const inherited = this.inherited.filter(
            (tm: TeamMember) => !this.overrideForAccount(tm.account),
        );
        return [...members, ...inherited, ...this.overrides];
    }

    isInherited(member: TeamMember): boolean {
        return !!this.inherited.find((tm: TeamMember) => tm == member);
    }
    capabilityDisplayForMember(member: TeamMember): string {
        // means they have a role on the org but no override
        if (member.capabilities?.length && this.isInherited(member)) {
            return member.capabilityDisplay;
        }

        //means user has an override so we need to find their capability from their inherited member
        //per the configurable role ticket, the role a user has in the org settings should be displayed

        const inheritedMember = this?.inherited.find(
            (tm) => tm.account.id === member.account.id,
        );

        if (inheritedMember) {
            return inheritedMember.capabilityDisplay;
        }

        return "";
    }
}
