import { ObjectViewMode } from "./../object.component";
import { Component, Input } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { SessionComponent } from "src/services/components/session.component";
import {
    ObjectOrReference,
    ObjectReference,
    objectsOnly,
} from "src/services/models/api-object";
import {
    CompoundDataType,
    DataFieldValue,
    DataForm,
    DataFormField,
} from "src/services/models/data";
import { Program } from "src/services/models/program";

@Component({
    selector: "data-form-group",
    templateUrl: "./data-form-group.component.html",
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormGroupComponent extends SessionComponent {
    @Input() form?: DataForm;
    @Input() mode: ObjectViewMode = ObjectViewMode.View;
    @Input() viewOnly: boolean = false;
    @Input() parent?: DataFormGroupComponent;
    @Input() productOptions: ObjectOrReference<Program>[] = [];
    @Input() formField?: ObjectOrReference<DataFormField>;
    @Input() group: FormGroup | undefined;
    @Input() set fields(v: ObjectOrReference<DataFormField>[]) {
        this.sortedFields = [...v].sort(
            (
                a: ObjectOrReference<DataFormField>,
                b: ObjectOrReference<DataFormField>,
            ) => {
                if (a instanceof ObjectReference && b instanceof ObjectReference)
                    return 0;
                if (a instanceof ObjectReference) return -1;
                if (b instanceof ObjectReference) return 1;
                return a.order - b.order;
            },
        );
    }
    @Input() values: ObjectOrReference<DataFieldValue>[] = [];
    @Input() allowPartial: boolean = false;

    sortedFields: ObjectOrReference<DataFormField>[] = [];

    get depth(): number {
        return (this.parent?.depth ?? 0) + 1;
    }

    previewControl(
        field: ObjectOrReference<DataFormField>,
    ): AbstractControl | undefined {
        return (field instanceof DataFormField ? field : undefined)?.previewControl;
    }
    subtitle(field: ObjectOrReference<DataFormField>): string {
        if (field instanceof DataFormField)
            return field.instructions ?? field.field.description ?? "";
        return "";
    }

    isGroupField(field: ObjectOrReference<DataFormField>): boolean {
        return (
            field instanceof DataFormField &&
            (!!field.children?.length || field.field.data_type.name == "group")
        );
    }
    fieldValues(
        field: ObjectOrReference<DataFormField>,
    ): ObjectOrReference<DataFieldValue>[] {
        let values: DataFieldValue[] = [];
        values =
            objectsOnly(this.form?.values, DataFieldValue).filter(
                (value: DataFieldValue) => value.form_field?.id == field.id,
            ) || [];
        return values;
    }
    fieldControl(
        field: ObjectOrReference<DataFormField>,
        compound?: CompoundDataType,
    ): AbstractControl | undefined {
        let control = this.previewControl(field);
        if (compound?.name) {
            const group = control as FormGroup;
            control = group.controls[compound.name];
        }
        return control;
    }
    fieldGroup(field: ObjectOrReference<DataFormField>): FormGroup | undefined {
        const control = this.previewControl(field);
        return control instanceof FormGroup ? control : undefined;
    }
    fieldChildren(
        field: ObjectOrReference<DataFormField>,
    ): ObjectOrReference<DataFormField>[] {
        return (field instanceof DataFormField ? field : undefined)?.children ?? [];
    }
    conditionValid(field: ObjectOrReference<DataFormField>): boolean {
        if (field instanceof ObjectReference) {
            return false;
        }
        if (!field.conditions.length) return true;

        return field.conditions.every((condition) => {
            const { dependent_field, expected_value, condition_type } = condition;
            let dependent = this.sortedFields.find(
                (f) => f?.id === dependent_field?.id,
            );

            if (dependent instanceof ObjectReference || !dependent?.fieldName)
                return false;

            let equals: boolean | undefined = undefined;
            const dependentValue = this?.group?.value[`${dependent.fieldName}`];
            const valueType = typeof dependentValue;

            if (dependentValue instanceof Program) {
                const expected =
                    expected_value.includes("program.program:") ?
                        expected_value.split(":")[1]
                    :   expected_value;
                equals = dependentValue.id === expected;
            } else if (valueType === "string" || valueType === "number") {
                equals = dependentValue === expected_value;
            } else if (dependentValue instanceof Date) {
                equals =
                    dependentValue.getTime() === new Date(expected_value).getTime();
            }

            if (equals === undefined) return false;

            return condition_type === "equals" ? equals : !equals;
        });
    }
}
