<div *ngIf="!list.hasLoaded">
    <div class="spinner">
        <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
    </div>
    <div class="spinner-message">Loading Checklist ...</div>
</div>

<mat-vertical-stepper
    #checklistStepper
    *ngIf="list.hasLoaded"
    [selectedIndex]="selected || 0"
>
    <mat-step *ngFor="let group of list.items; index as i">
        <ng-template matStepLabel>
            <span *ngIf="isObject(group)">{{ asObject(group).task.name }}</span>
            <div *ngIf="isReference(group)" referenceWrapper [primary]="true"></div>
        </ng-template>
        <ng-template matStepContent>
            <span class="task-group-description" *ngIf="isObject(group)">
                {{ asObject(group).task.description }}
            </span>
            <div *ngIf="isReference(group)" referenceWrapper [primary]="true"></div>
            <task-table
                [notifications]="notifications"
                [viewOnly]="viewOnly"
                [case]="case"
                [group]="group"
                [firstUncompletedTask]="firstUncompletedTask"
            ></task-table>
        </ng-template>
    </mat-step>
</mat-vertical-stepper>
