import { APIObject, ObjectReference, OptionalObjectOrReference } from "./api-object";
import { Organization } from "./organization";
import { capitalizeFirstLetter } from "src/common/utilities/utilities";

export class Capability extends APIObject {
    static object_type: string = "iam.capability";
    owner?: OptionalObjectOrReference<Organization>;
    name?: string;
    display_name?: string;
    capacity?: "pharma" | "physician";

    get displayName() {
        return this.display_name;
    }

    get capacityDisplay() {
        return capitalizeFirstLetter(this.capacity ?? "");
    }

    initialize(data: any, patch: boolean): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "capacity");
    }
}
