import { Component, Inject, Input } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ObjectComponent } from "src/common/components/object.component";
import { Case, Status } from "src/services/models/case";
import { Inquiry } from "src/services/models/inquiry";
import { InquiryService } from "src/services/program.services";

@Component({
    selector: "reject-case-dialog",
    templateUrl: "./reject-case.component.html",
    styleUrls: ["./case.component.scss"],
})
export class RejectCaseDialog extends ObjectComponent<Inquiry> {
    protected inquiry: Inquiry;

    rejectCase: boolean = false;
    title: string = "Close Case";
    status: any;
    @Input() statusses: Status[] = [];

    protected rejectingCase: boolean;
    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<RejectCaseDialog>,
        protected service: InquiryService,
    ) {
        super(service);
        this.inquiry = data?.object;
        this.object = data.object;
        this.rejectCase = data.rejectCase;
        this.status = data?.status as Status;

        this.objectChange.subscribe((v) => {
            data?.onChange(v);
        });

        this.title = data?.title;
        if (data?.rejectCase) {
            this.rejectingCase = true;
        } else {
            this.rejectingCase = false;
        }
    }

    get rejectionOptions() {
        return this.rejectCase ? Case.RejectionOptions : Inquiry.RejectionOptions;
    }

    getRejectionOptionKeys(): string[] {
        return Object.keys(this.rejectionOptions);
    }

    ngAfterViewInit(): void {
        this.formGroup.controls?.status?.setValue(this.status?.id);
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            status: [null, Validators.required],
            rejection_status: [null],
            rejection_reason: [null, Validators.maxLength(500)],
        });
    }

    protected onAutosave(value: any): void {
        super.onAutosave({ ...value, status: "reject" });
    }

    protected onCommitSuccess(v: Inquiry): boolean {
        const res = super.onCommitSuccess(v);
        this.dialogRef.close();
        return res;
    }

    onClose(): void {
        this.dialogRef.close({ ...this.formGroup.value, preview: true });
    }

    get statusValid() {
        return this.formGroup.controls.status.valid;
    }
    closeWithoutReason() {
        const newStatus = this.formGroup.value["status"];
        this.dialogRef.close({ status: newStatus, preview: false });
    }
}
