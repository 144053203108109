import { Component, Input } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";

@Component({
    selector: "dt-lookup",
    template: `
        <p *ngIf="viewOnly && !hasValues && compoundIndex == 0" class="secondary">
            Value not provided
        </p>
        <p *ngIf="viewOnly && hasValues">{{ displayValue }}</p>
        <mat-form-field class="column" [ngClass]="inputClass" *ngIf="!viewOnly">
            <mat-label>{{ label }}</mat-label>
            <mat-select
                [placeholder]="placeholder"
                [formControl]="control"
                [required]="isRequired"
                [multiple]="false"
                *ngIf="!multipleValues"
            >
                <mat-option
                    *ngFor="let option of allowedOptions"
                    [value]="option.value"
                >
                    {{ option.display_name }}
                </mat-option>
            </mat-select>
            <mat-select
                [placeholder]="placeholder"
                [formControl]="control"
                [required]="isRequired"
                [multiple]="true"
                *ngIf="multipleValues"
            >
                <mat-option
                    *ngFor="let option of allowedOptions"
                    [value]="option.value"
                >
                    {{ option.display_name }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="!!instructions">
                <quill-view-html [content]="instructions"></quill-view-html>
            </mat-hint>
            <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
        </mat-form-field>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeLookup extends BaseDataTypeComponent {
    @Input() options: { value: string; display_name: string }[] = [];
    @Input() multiple: boolean = false;
    get multipleValues(): boolean {
        return (this?.attributes?.multiple as boolean) || this.multiple;
    }

    get allowedOptions(): { value: string; display_name: string }[] {
        return this.options.filter(
            (option: { value: string; display_name: string }) => {
                const allowed = this.attributes?.allowed;
                return allowed ? allowed.includes(option.value) : true;
            },
        );
    }
}
