import { ObjectReference } from "src/services/models/api-object";
import { Injectable } from "@angular/core";
import { APIService } from "./api.service";
import { Observable } from "rxjs";

// NOTE: This service is only accessible to system administrators
@Injectable()
export class AdminService extends APIService<any> {
    constructor() {
        super(ObjectReference, ["admin"]);
    }

    migrateData(): Observable<any> {
        return this.request(
            [this.endpoint, "migrate"].join("/"),
            undefined,
            {},
            "post",
        );
    }
}
