import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ObjectComponent } from "src/common/components/object.component";
import { Assignment } from "src/services/models/assignment";
import { AssignmentService } from "src/services/program.services";

@Component({
    selector: "complete-todo",
    styleUrls: ["./document.component.scss"],
    templateUrl: "./complete-todo.component.html",
})
export class CompleteTodoComponent extends ObjectComponent<Assignment> {
    assignment: Assignment | undefined;
    @Output() onAssignmentComplete = new EventEmitter<boolean>();
    objectName: string = "Complete Todo";

    get controller() {
        return this;
    }

    constructor(
        protected service: AssignmentService,
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<CompleteTodoComponent>,
    ) {
        super(service);
        this.assignment = data?.assignment;
        this.objectName = `Close Todo: ${this.assignment?.task?.displayName}`;
    }

    completeAssignment() {
        if (this.assignment) {
            this.assignment.completed = new Date();
            this.assignment.completed_by = this.currentAccount?.asReference;
            this.service.update(this.assignment).subscribe(() => {
                this.onAssignmentComplete.emit(true);
                this.dialogRef.close(this.assignment);
            });
        } else this.dialogRef.close();
    }

    cancelComplete() {
        this.onAssignmentComplete.emit(false);
    }
}
