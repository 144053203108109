<div class="container">
    <div class="staff-header flex">
        <div class="title org-title" [id]="list.listTitle">Staff</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field color="primary" class="program-search margin-right">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Staff</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSystemAdministrator && canMigrate"
                (click)="migrateStaffTeam($event)"
            >
                <mat-icon>upgrade</mat-icon>
                Migrate Team
            </button>
            <button
                mat-flat-button
                class="org-button"
                color="accent"
                type="button"
                (click)="createObject($event, true)"
            >
                <mat-icon>add</mat-icon>
                Add Staff Member
            </button>
        </div>
    </div>
    <div *ngIf="list.hasLoaded" class="card-grid">
        <mat-card *ngFor="let account of list.items" class="example-card card-row">
            <ng-container *ngIf="isTeamMember(account)">
                <div class="card-column">
                    <div class="card-row">
                        <div
                            class="card-column card-header"
                            matColumnDef="account-info"
                        >
                            {{ account.displayName }}
                        </div>
                        <div class="card-column card-header" matColumnDef="permission">
                            Permission
                        </div>
                        <div class="card-column card-header" matColumnDef="role">
                            Role
                        </div>
                        <div class="card-column card-header" matColumnDef="products">
                            Products
                        </div>
                        <div class="card-column card-header" matColumnDef="programs">
                            Programs
                        </div>
                        <div class="card-column card-header" matColumnDef="countries">
                            Countries
                        </div>
                    </div>
                    <div class="card-row">
                        <div
                            class="card-column card-text-content"
                            matColumnDef="account-info"
                        >
                            {{ account.email }}
                        </div>
                        <div
                            class="card-column card-text-content"
                            matColumnDef="permission"
                        >
                            {{ displayNameForRole(account.permission?.role ?? "") }}
                        </div>
                        <!-- TODO: Role Content -->
                        <div
                            class="card-column card-text-content"
                            matColumnDef="capability"
                        >
                            {{ account.capabilityDisplay }}
                        </div>
                        <!-- TODO: Products Content -->
                        <div
                            class="card-column card-text-content"
                            matColumnDef="products"
                        ></div>
                        <!-- TODO: Programs Content -->
                        <div
                            class="card-column card-text-content"
                            matColumnDef="programs"
                        ></div>
                        <!-- TODO: Countries Content -->
                        <div
                            class="card-column card-text-content"
                            matColumnDef="countries"
                        ></div>
                    </div>
                </div>
                <div class="card-column card-button">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ account: account }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #overflow="matMenu">
                        <ng-template matMenuContent let-account="account">
                            <button
                                mat-menu-item
                                (click)="
                                    canEditStaff(account) &&
                                        editObject($event, account, true)
                                "
                                [disabled]="!canEditStaff(account)"
                            >
                                Edit
                            </button>
                            <button
                                mat-menu-item
                                (click)="
                                    canEditStaff(account) &&
                                        deleteStaff($event, account)
                                "
                                [disabled]="!canEditStaff(account)"
                            >
                                Remove
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>
            </ng-container>
        </mat-card>
    </div>
    <div *ngIf="list.loadingProgress" class="error not-found text-center">
        <span *ngIf="list.searching && !list.hasLoaded">
            <mat-progress-spinner
                diameter="20"
                class="inline"
                mode="indeterminate"
            ></mat-progress-spinner>
            Loading...
        </span>
        <span *ngIf="list.initialized && !list.list.items.length">No staff found.</span>
    </div>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
