import { AdminService } from "./../../../services/admin.service";
import { Component, inject } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { FileUploaderCustom } from "src/common/utilities/FileUploaderCustom";
import { FormControl } from "@angular/forms";
import { FileItem } from "ng2-file-upload";
import { HttpErrorResponse } from "@angular/common/http";
import { map } from "rxjs";

@Component({
    selector: "system-admin",
    templateUrl: "./system-admin.component.html",
    styles: [
        `
            .system-view {
                padding-top: 2rem;
                padding-bottom: 2rem;
                overflow-y: scroll;
            }
        `,
        `
            .config-actions {
                padding-top: 1rem;
            }
        `,
        `
            .log-display {
                min-height: 5rem;
                max-height: 30rem;
            }
        `,
    ],
})
export class SystemAdminComponent extends SessionComponent {
    protected adminService: AdminService;
    uploader?: FileUploaderCustom;
    fileOver: boolean = false;
    elementId: string = Date.now().toString();

    mode: FormControl = new FormControl("create");

    processingConfigFile: boolean = false;
    logDisplay: string[] = [];

    constructor() {
        super();
        this.adminService = inject(AdminService);

        this.session.authorization
            .pipe(
                map((authorization: string | undefined) => {
                    const uploader = new FileUploaderCustom({
                        url: [this.adminService.endpoint, "config"].join("/"),
                        authToken: authorization,
                    });
                    uploader.onBuildItemForm = (item: FileItem, form: FormData) => {
                        form.append("mode", this.mode.value);
                        form.append("filename", item.file.name ?? "file");
                    };
                    uploader.onSuccessItem = (item: FileItem, response: string) => {
                        const json = JSON.parse(response);
                        this.onConfigComplete(json);
                    };
                    uploader.onErrorItem = (
                        item: FileItem,
                        response: string,
                        status: number,
                    ) => {
                        const error = new HttpErrorResponse({
                            error: response,
                            status: status,
                        });
                        this.session.handleError(error);
                        this.onConfigComplete(error);
                    };
                    return uploader;
                }),
            )
            .subscribe((uploader: FileUploaderCustom) => (this.uploader = uploader));
    }

    clearUploadQueue(): void {
        this.uploader?.clearQueue();
    }
    clearLog(): void {
        this.logDisplay = [];
    }
    applyConfiguration(): void {
        if (this.uploader?.queue) {
            this.processingConfigFile = true;
            const originalOnCompleteAll = this.uploader.onCompleteAll.bind(
                this.uploader,
            );
            this.uploader.onCompleteAll = () => {
                originalOnCompleteAll();
                this.processingConfigFile = false;
                this.uploader?.clearQueue();
            };
            this.uploader.uploadAllOneRequest();
        }
    }
    onConfigComplete(response: any): void {
        if (response instanceof HttpErrorResponse) {
            this.logDisplay.push(
                "Error " +
                    response.status +
                    " processing config file: " +
                    response.error,
            );
        } else {
            this.logDisplay = this.logDisplay.concat(response);
        }
    }

    migrateData(event: MouseEvent): void {
        this.terminateEvent(event);
        this.adminService.migrateData().subscribe();
    }
}
