import { Component } from "@angular/core";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Status } from "src/services/models/case";
import { StatusService } from "src/services/program.services";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { GenerateUniqueIdentifier } from "src/common/utilities/utilities";
import { ObjectFactory, ObjectOrReference } from "src/services/models/api-object";
import { forkJoin } from "rxjs";
import { defined } from "src/common/utilities/flatten";

@Component({
    selector: "status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
})
export class StatusComponent extends ObjectComponent<Status> {
    objectName = "Status";
    constructor(protected service: StatusService) {
        super(service);
    }

    protected _caseStatuses: Status[] = [];
    set caseStatuses(v: ObjectOrReference<Status>[]) {
        forkJoin(
            v.map((tv: ObjectOrReference<Status>) =>
                ObjectFactory.objectObservable(tv),
            ),
        ).subscribe(
            (statuses: (Status | undefined)[]) =>
                (this._caseStatuses = defined(statuses)),
        );
    }

    get canBeDefault() {
        const hasDefault = !!this._caseStatuses?.find(
            (s) =>
                s?.attributes.is_default_inquiry_status &&
                s.attributes.is_inquiry_status,
        );
        const isDefault = this.fullObject?.attributes?.is_default_inquiry_status;
        const doesCloseCase = !!(this.formGroup.controls["attributes"] as any)
            ?.controls["closes_case"]?.value;
        return (!hasDefault || (hasDefault && isDefault)) && !doesCloseCase;
    }

    get canCloseCase() {
        const isDefault = !!(this.formGroup.controls["attributes"] as any)?.controls[
            "is_default_inquiry_status"
        ]?.value;
        return !isDefault;
    }

    protected setObject(v?: Status): void {
        super.setObject(v);
        if (this.mode == ObjectViewMode.Create) this.objectName = "Status";
        else if (this.mode == ObjectViewMode.Edit)
            this.objectName = "Edit " + (this.fullObject?.display_name ?? "Status");
        else this.objectName = "View " + (this.object?.displayName ?? "Status");
    }
    protected onCommitError(err: any) {
        super.onCommitError(err);
        this.session.handleError(err);
    }

    protected precommitTransform(v: any) {
        v.name = GenerateUniqueIdentifier(
            v?.display_name,
            this._caseStatuses?.map((s) => s?.name),
        );
        return v;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            owner: [null, Validators.required],
            display_name: [null, Validators.required],
        });
    }
}

@Component({
    selector: "status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
})
export class CaseStatusComponent extends StatusComponent {
    objectName = "Case Status";

    protected setObject(v?: Status | undefined): void {
        if (v) {
            v.attributes = {
                ...(v?.attributes || {}),
                is_inquiry_status: true,
            };
        }
        super.setObject(v);
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            id: [null],
            owner: [null, Validators.required],
            display_name: [null, Validators.required],
            attributes: this.formBuilder.group({
                is_default_inquiry_status: [null],
                closes_case: [null],
                is_inquiry_status: [null],
            }),
        });
    }
}
