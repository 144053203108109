import { Directive, ElementRef, Renderer2, Input, OnInit } from "@angular/core";

// This directive is used to wrap content in a reference-wrapper div and replaces
// the host element's content with a loading div while the content is loading.
@Directive({
    selector: "[referenceWrapper]",
})
export class ReferenceWrapperDirective implements OnInit {
    @Input() primary: boolean = false;
    @Input() secondary: boolean = false;
    @Input() icon: boolean = false;
    @Input() taskIcon: boolean = false;
    @Input() chip: boolean = false;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit() {
        // Add the 'reference-wrapper' class to the host element
        this.renderer.addClass(this.el.nativeElement, "reference-wrapper");

        // Apply additional classes based on input properties
        if (this.primary) this.renderer.addClass(this.el.nativeElement, "primary");
        if (this.secondary) this.renderer.addClass(this.el.nativeElement, "secondary");
        if (this.icon) this.renderer.addClass(this.el.nativeElement, "icon");
        if (this.taskIcon) this.renderer.addClass(this.el.nativeElement, "task-icon");
        if (this.chip) this.renderer.addClass(this.el.nativeElement, "chip");

        // Create the 'reference-loading' div and wrap the host element's content
        const loadingDiv = this.renderer.createElement("div");
        this.renderer.addClass(loadingDiv, "reference-loading");

        // Move the host element's content into the loadingDiv
        while (this.el.nativeElement.firstChild) {
            this.renderer.appendChild(loadingDiv, this.el.nativeElement.firstChild);
        }

        // Append the loadingDiv back into the host element
        this.renderer.appendChild(this.el.nativeElement, loadingDiv);
    }
}
