import { APIObject, NamedObject } from "./api-object";

export class WorkflowReference extends APIObject {
    static object_type: string = "program.workflowreference";
    item_type!: string;
    reference_object_type?: string;
    reference_identifier?: string;

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["reference_object_type"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "item_type");
        this.setMember(data, patch, "reference_object_type");
        this.setMember(data, patch, "reference_identifier");
    }
}

export class Workflow extends NamedObject {
    static object_type: string = "program.Workflow";
    name!: string;
    get displayName(): string | undefined {
        return this.display_name;
    }
    display_name!: string;
    description!: string;
    region?: string;
    references!: WorkflowReference[]; // read-only

    initialize(data: any, patch: boolean): void {
        this._readOnly.push(...["references"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "region");
        this.setMember(data, patch, "references", WorkflowReference, true);
    }
}
