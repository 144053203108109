import {
    DataFormAttributes,
    DataFormFieldCondition,
} from "./../../../../services/models/data";
import { debounceTime, filter } from "rxjs";
import { AfterViewInit, Component, Inject } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    Validators,
} from "@angular/forms";
import { CompoundDataType, DataFormField, DataType } from "src/services/models/data";
import { DataFormEditorComponent } from "./data-form.component";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { AppInjector } from "src/common/utilities/injector";
import { DataFormFieldConditionService } from "src/services/data.services";
import { ObjectReference } from "src/services/models/api-object";
import { Program } from "src/services/models/program";
import { DataFormComponent } from "../../data-form/data-form.component";
import { ObjectViewMode } from "../../object.component";

type SelectOption = {
    display_name: string;
    value: string;
};

@Component({
    templateUrl: "./data-form-field.component.html",
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormFieldEditorComponent implements AfterViewInit {
    widthOptions: SelectOption[] = [
        { display_name: "Full", value: "full" },
        { display_name: "75%", value: "three-fourth" },
        { display_name: "50%", value: "half" },
        { display_name: "25%", value: "quarter" },
    ];

    static DefaultWidth: string = "full";
    static DefaultMinLines: number = 3;
    static DefaultMaxLines: number = 7;
    static DefaultMultipleLabel: string = "Values";

    formField: DataFormField;
    compound?: CompoundDataType;
    attributes: DataFormAttributes;
    parentName?: string;
    formGroup: FormGroup;
    compoundFormGroup: FormGroup;
    conditionFormGroup: FormGroup;
    availableDependents?: DataFormField[];
    productOptions?: Program[];
    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: {
            formField: DataFormField;
            compound?: CompoundDataType;
            attributes?: any;
            parentName?: string;
            fieldsInForm: DataFormField[];
            productOptions: Program[];
        },
        protected formBuilder: FormBuilder,
        protected dialogReference: MatDialogRef<DataFormFieldEditorComponent>,
        protected dialog: MatDialog,
    ) {
        this.formField = data.formField;
        this.availableDependents = data.fieldsInForm;
        this.productOptions = data.productOptions;
        this.compound = data.compound;
        this.parentName = data.parentName;
        if (!this.formField.attributes) this.formField.attributes = {};
        this.attributes = data.attributes || this.formField.attributes;
        this.formGroup = this.formBuilder.group({
            prompt: [],
            label: [],
            placeholder: [],
            instructions: [],
            width: [DataFormFieldEditorComponent.DefaultWidth],
            minLines: [
                DataFormFieldEditorComponent.DefaultMinLines,
                Validators.pattern("^[0-9]*$"),
            ],
            maxLines: [
                DataFormFieldEditorComponent.DefaultMaxLines,
                Validators.pattern("^[0-9]*$"),
            ],
            maxLength: [null, Validators.pattern("^[0-9]*$")],
            multipleLabel: [],
            singular: [],
            allowedOptions: [],
            alternateCheckbox: [false],
            alternateCheckboxText: [],
            compoundField: [],
            numberValidationType: [""],
            min: [],
            max: [],
        });
        this.compoundFormGroup = this.formBuilder.group({
            label: [],
            placeholder: [],
            width: [DataFormFieldEditorComponent.DefaultWidth],
        });
        this.conditionFormGroup = this.formBuilder.group({
            dependent_field: [],
            condition_type: [],
            expected_value: [],
        });
    }
    get expectedValueControl() {
        return this.conditionFormGroup.get("expected_value") as AbstractControl;
    }
    get dependentFieldControl() {
        return this.conditionFormGroup?.get("dependent_field") as AbstractControl;
    }
    ngAfterViewInit(): void {
        this.updateProperties();

        this.formGroup
            .get("prompt")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onPromptChanged(v));
        this.formGroup
            .get("label")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onLabelChanged(v));
        this.formGroup
            .get("placeholder")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onPlaceholderChanged(v));
        this.formGroup
            .get("instructions")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onInstructionsChanged(v));
        this.formGroup
            .get("width")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onWidthChanged(v));
        this.formGroup
            .get("minLines")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onMinLinesChanged(v));
        this.formGroup
            .get("maxLines")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onMaxLinesChanged(v));
        this.formGroup
            .get("maxLength")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onMaxLengthChanged(v));
        this.formGroup
            .get("multipleLabel")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onMultipleLabelChanged(v));
        this.formGroup
            .get("singular")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onSingularChanged(v));
        this.formGroup
            .get("allowedOptions")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string[]) => this.onAllowedOptionsChanged(v));
        this.formGroup
            .get("alternateCheckboxText")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onAlternateCheckboxTextChanged(v));
        this.formGroup
            .get("compoundField")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: CompoundDataType) => this.onCompoundFieldChanged(v));
        this.formGroup
            .get("numberValidationType")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: any) => (this.numberValidatorType = v));
        this.formGroup
            .get("max")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v) => (this.maxNumber = v));
        this.formGroup
            .get("min")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v) => (this.minNumber = v));
        this.compoundFormGroup
            .get("label")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onCompoundLabelChanged(v));
        this.compoundFormGroup
            .get("placeholder")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onCompoundPlaceholderChanged(v));
        this.compoundFormGroup
            .get("width")
            ?.valueChanges.pipe(debounceTime(400))
            .subscribe((v: string) => this.onCompoundWidthChanged(v));
        this.conditionFormGroup.valueChanges
            .pipe(
                filter(
                    ({ condition_type, dependent_field, expected_value }) =>
                        condition_type && dependent_field && expected_value,
                ),
                debounceTime(400),
            )
            .subscribe((v) => {
                this.formField.conditions[0] = new DataFormFieldCondition({
                    ...this.formField.conditions[0],
                    ...v,
                    expected_value: DataFormComponent.transformFormFieldObjects(
                        v.expected_value,
                    ),
                    form_field: this.formField,
                });
            });
    }
    get numberValidationTypeValue() {
        return this.formGroup.get("numberValidationType")?.value;
    }
    compareObjects = (a: any, b: any): boolean => {
        return a == b;
    };

    onPromptChanged(value: string): void {
        this.formField.displayName = value;
    }
    onLabelChanged(value: string): void {
        this.label = value;
    }
    onPlaceholderChanged(value: string): void {
        this.placeholder = value;
    }
    onInstructionsChanged(value: string): void {
        this.instructions = value;
    }
    onWidthChanged(value: string): void {
        this.width = value;
    }
    onMinLinesChanged(value: string): void {
        this.minLines = parseInt(value);
    }
    onMaxLinesChanged(value: string): void {
        this.maxLines = parseInt(value);
    }
    onMaxLengthChanged(value: string): void {
        this.maxLength = parseInt(value);
    }
    onMultipleLabelChanged(value: string): void {
        this.multipleLabel = value;
    }
    onSingularChanged(value: string): void {
        this.singular = value;
    }
    onAllowedOptionsChanged(value: string[]): void {
        this.allowedOptions = value;
    }
    onAlternateCheckboxTextChanged(value: string): void {
        this.alternateCheckboxText = value;
    }
    onCompoundFieldChanged(value: CompoundDataType): void {
        this.updateCompoundProperties(value);
    }

    get isControlField(): boolean {
        return DataFormEditorComponent.isControlFormField(this.formField);
    }
    get isGroup(): boolean {
        return this.dataType.name == "group";
    }
    get isInstruction(): boolean {
        return this.dataType.name == "instructions";
    }
    get isCompound(): boolean {
        return this.dataType.isCompound;
    }
    get isBoolean(): boolean {
        return this.dataType.name == "boolean";
    }
    get isRadio(): boolean {
        return !!this.dataType.name?.includes("radio");
    }
    get isLongText(): boolean {
        return this.dataType.name == "text.long";
    }
    get isLookup(): boolean {
        return this.dataType.isLookup;
    }
    get isInline(): boolean {
        return this.attributes?.layout?.compound == "inline";
    }
    get isDate(): boolean {
        return this.dataType.name == "date" || this.dataType.name == "datetime";
    }
    get isPromptHidden(): boolean {
        return !!this.attributes?.hidePrompt;
    }
    get isAlternateCheckbox(): boolean {
        return this.attributes?.alt?.control == "checkbox";
    }
    get isNumber() {
        return this.dataType.name === "number";
    }
    get hasPrompt(): boolean {
        return !this.isInstruction && !this.compound;
    }
    get hasLabel(): boolean {
        return !this.isControlField && !this.isCompound;
    }
    get hasPlaceholder(): boolean {
        return !this.isControlField && !this.isCompound;
    }
    get hasInstructions(): boolean {
        return !this.isCompound && !this.isBoolean && !this.isRadio;
    }
    get hasWidth(): boolean {
        return (
            !this.isCompound &&
            !this.isBoolean &&
            !this.isRadio &&
            !this.multiple &&
            !this.isGroup
        );
    }
    get hasMultipleLabel(): boolean {
        return this.multiple && !this.isCompound && !this.isLookup;
    }
    get hasSingular(): boolean {
        return this.multiple && !this.isLookup;
    }
    get hasAlternateControl(): boolean {
        return this.isBoolean || this.isDate;
    }

    get hasAdvancedProperties(): boolean {
        return (
            this.hasSingular ||
            this.hasMultipleLabel ||
            this.isLongText ||
            this.isLookup ||
            this.hasAlternateControl ||
            this.isNumber
        );
    }
    get hasLayoutProperties(): boolean {
        return this.hasWidth || this.isLongText || this.hasPrompt || this.isCompound;
    }

    get compoundFields(): CompoundDataType[] {
        return this.dataType.compound ?? [];
    }
    get selectedCompoundField(): CompoundDataType | undefined {
        return this.formGroup.get("compoundField")?.value;
    }
    compoundLabel(compound?: CompoundDataType): string {
        return (
            this.compoundAttributes(compound)?.label ||
            this.compoundAttributes(compound)?.placeholder ||
            compound?.display_name ||
            ""
        );
    }
    compoundPlaceholder(compound?: CompoundDataType): string {
        return this.compoundAttributes(compound).placeholder;
    }
    compoundWidth(compound?: CompoundDataType): string {
        let width = this.compoundAttributes(compound).layout?.width || "full";
        if (width == "25%") width = "quarter";
        else if (width == "50%") width = "half";
        else if (width == "75%") width = "three-fourth";
        else if (width == "100%") width = "full";
        return width;
    }
    compoundAttributes(compound?: CompoundDataType): any {
        if (!compound) return {};
        if (!this.attributes[compound.name!]) this.attributes[compound.name!] = {};
        return this.attributes[compound.name!];
    }
    compoundIsCompound(compound?: CompoundDataType): boolean {
        return !!compound?.child.isCompound;
    }
    compoundHasLabel(compound?: CompoundDataType): boolean {
        return !this.compoundIsCompound(compound);
    }
    compoundHasPlaceholder(compound?: CompoundDataType): boolean {
        return !this.compoundIsCompound(compound);
    }
    compoundHasWidth(compound?: CompoundDataType): boolean {
        return (
            !this.compoundIsCompound(compound) &&
            !compound?.child.name?.includes("radio") &&
            compound?.child.name != "boolean" &&
            compound?.child.name != "group" &&
            !compound?.multiple
        );
    }
    onCompoundLabelChanged(value: string): void {
        if (value && value != "")
            this.compoundAttributes(this.selectedCompoundField).label = value;
        else delete this.compoundAttributes(this.selectedCompoundField).label;
    }
    onCompoundPlaceholderChanged(value: string): void {
        if (value && value != "")
            this.compoundAttributes(this.selectedCompoundField).placeholder = value;
        else delete this.compoundAttributes(this.selectedCompoundField).placeholder;
    }
    onCompoundWidthChanged(value: string): void {
        if (value && value != "") {
            if (!this.compoundAttributes(this.selectedCompoundField).layout)
                this.compoundAttributes(this.selectedCompoundField).layout = {};
            this.compoundAttributes(this.selectedCompoundField).layout.width = value;
        } else delete this.compoundAttributes(this.selectedCompoundField).layout?.width;
    }
    showCompoundProperties(compound?: CompoundDataType): void {
        this.dialog.open(DataFormFieldEditorComponent, {
            data: {
                formField: this.formField,
                compound: compound,
                attributes: this.compoundAttributes(compound),
                parentName: this.displayName,
            },
            minWidth: "35vw",
            hasBackdrop: false,
            position: {
                left: "16px",
                top: "16px",
            },
        });
    }

    get dataType(): DataType {
        if (this.compound) return this.compound.child;
        return this.formField.field.data_type;
    }
    get previewControl(): AbstractControl | undefined {
        return this.compound ?
                this.compound.previewControl
            :   this.formField.previewControl;
    }
    set previewControl(v: AbstractControl | undefined) {
        if (this.compound) this.compound.previewControl = v;
        else this.formField.previewControl = v;
    }
    get controlName(): string | undefined {
        return this.compound ? this.compound.name : this.formField.field.name;
    }

    get displayName(): string {
        let displayName = this.parentName ?? "";
        if (this.compound) displayName += ": " + this.compound?.display_name;
        else displayName += this.formField.field.displayName;
        return displayName;
    }

    get label(): string {
        if (this.compound) return this.attributes.label;
        return this.formField.label;
    }
    set label(v: string) {
        if (this.compound) {
            if (v && v != "") this.attributes.label = v;
            else delete this.attributes.label;
        } else this.formField.label = v;
        this.updateAlternateCheckboxText();
    }
    get placeholder(): string {
        if (this.compound) return this.attributes.placeholder;
        else return this.formField.placeholder;
    }
    set placeholder(v: string) {
        if (this.compound) {
            if (v && v != "") this.attributes.placeholder = v;
            else delete this.attributes.placeholder;
        } else this.formField.placeholder = v;
    }
    get instructions(): string {
        if (this.compound) return this.attributes.instructions;
        else return this.formField.instructions;
    }
    set instructions(v: string) {
        if (this.compound) {
            if (v && v != "") this.attributes.instructions = v;
            else delete this.attributes.instructions;
        } else this.formField.instructions = v;
    }
    get width(): string {
        let width = this.attributes?.layout?.width;
        if (width == "25%") width = "quarter";
        else if (width == "50%") width = "half";
        else if (width == "75%") width = "three-fourth";
        else if (width == "100%") width = "full";
        return width;
    }
    set width(v: string) {
        if (this.compound) {
            if (v && v != "" && v != "full" && v != "100%") {
                if (!this.attributes.layout) this.attributes.layout = {};
                this.attributes.layout.width = v;
            } else {
                delete this.attributes.layout?.width;
            }
        } else this.formField.width = v;
    }

    get alternateCheckboxText(): string | undefined {
        return this.attributes?.alt?.checkboxText || this.instructions || this.label;
    }
    set alternateCheckboxText(value: string | undefined) {
        if (value && value != "") {
            if (!this.attributes.alt) this.attributes.alt = {};
            this.attributes.alt.checkboxText = value;
        } else {
            delete this.attributes?.alt?.checkboxText;
        }
    }

    get minLines(): number | undefined {
        return (
            this.attributes?.layout?.min_lines ||
            DataFormFieldEditorComponent.DefaultMinLines
        );
    }
    set minLines(v: number | undefined) {
        if (v && v != 0 && v != DataFormFieldEditorComponent.DefaultMinLines) {
            if (!this.attributes.layout) this.attributes.layout = {};
            this.attributes.layout.min_lines = v;
        } else {
            delete this.attributes?.layout?.min_lines;
        }
        this.rebuildControl();
    }
    get maxLines(): number | undefined {
        return (
            this.attributes?.layout?.max_lines ||
            DataFormFieldEditorComponent.DefaultMaxLines
        );
    }
    set maxLines(v: number | undefined) {
        if (v && v != 0 && v != DataFormFieldEditorComponent.DefaultMaxLines) {
            if (!this.attributes.layout) this.attributes.layout = {};
            this.attributes.layout.max_lines = v;
        } else {
            delete this.attributes?.layout?.max_lines;
        }
    }
    get autoresize(): boolean {
        return !!this.attributes?.layout?.autoresize;
    }
    set autoresize(v: boolean) {
        if (v) {
            if (!this.attributes.layout) this.attributes.layout = {};
            this.attributes.layout.autoresize = true;
        } else {
            delete this.attributes?.layout?.autoresize;
        }
    }
    get maxLength(): number | undefined {
        return this.attributes?.validators?.max_length;
    }
    set maxLength(v: number | undefined) {
        if (v && v != 0) {
            if (!this.attributes.validators) this.attributes.validators = {};
            this.attributes.validators.max_length = v;
        } else {
            delete this.attributes?.validators?.max_length;
        }
    }
    get numberValidatorType() {
        return this?.attributes.validators?.number?.type || "";
    }
    set numberValidatorType(v: string | undefined) {
        if (!this.attributes.validators) this.attributes.validators = {};
        if (!this?.attributes.validators?.number)
            this.attributes.validators.number = {};

        if (v !== undefined) {
            this.attributes.validators.number.type = v;
            if (v === "min" && this.attributes.validators.number.max) {
                delete this.attributes.validators.number.max;
                this.formGroup.get("max")?.setValue(undefined);
            } else if (v === "max" && this.attributes.validators.number.min) {
                delete this.attributes.validators.number.min;
                this.formGroup.get("min")?.setValue(undefined);
            } else if (v === "") {
                this.attributes.validators.number = {};
                this.formGroup.get("max")?.setValue(undefined);
                this.formGroup.get("min")?.setValue(undefined);
            }
        } else {
            delete this.attributes.validators.number.type;
        }
    }
    get maxNumber() {
        return this?.attributes.validators?.number?.max;
    }
    set maxNumber(v: number) {
        if (!this.attributes.validators) this.attributes.validators = {};
        if (!this?.attributes.validators?.number)
            this.attributes.validators.number = {};
        if (v) {
            this.attributes.validators.number.max = v;
        } else {
            delete this.attributes.validators.number.max;
        }
    }

    get minNumber() {
        return this?.attributes.validators?.number?.min;
    }

    set minNumber(v) {
        if (!this.attributes.validators) this.attributes.validators = {};
        if (!this?.attributes.validators?.number)
            this.attributes.validators.number = {};

        if (v) {
            this.attributes.validators.number.min = v;
        } else {
            delete this.attributes.validators.number.min;
        }
    }
    get renderMinField() {
        if (!this.isNumber || this.numberValidationTypeValue === "") return false;
        return (
            this.numberValidationTypeValue === "min" ||
            this.numberValidationTypeValue === "range"
        );
    }
    get renderMaxField() {
        if (!this.isNumber || this.numberValidationTypeValue === "") return false;
        return (
            this.numberValidationTypeValue === "max" ||
            this.numberValidationTypeValue === "range"
        );
    }
    get multipleLabel(): string {
        return (
            this.attributes?.advanced?.multiple_label ||
            DataFormFieldEditorComponent.DefaultMultipleLabel
        );
    }
    set multipleLabel(v: string) {
        if (v && v != "" && v != DataFormFieldEditorComponent.DefaultMultipleLabel) {
            if (!this.attributes.advanced) this.attributes.advanced = {};
            this.attributes.advanced.multiple_label = v;
        } else {
            delete this.attributes?.advanced?.multiple_label;
        }
    }
    get singular(): string {
        return this.attributes?.layout?.singular || this.label;
    }
    set singular(v: string) {
        if (v && v != "") {
            if (!this.attributes.layout) this.attributes.layout = {};
            this.attributes.layout.singular = v;
        } else {
            delete this.attributes?.layout?.singular;
        }
    }

    get required(): boolean {
        return this.compound ? this.compound.required : this.formField.required;
    }
    set required(v: boolean) {
        if (this.compound) this.compound.required = v;
        else this.formField.required = v;
    }

    get multiple(): boolean {
        return (
            this.attributes?.multiple ||
            (this.compound ? this.compound.multiple : this.formField.multiple)
        );
    }
    set multiple(v: boolean) {
        if (this.compound) this.compound.multiple = v;
        else this.formField.multiple = v;
    }

    specifyOptions: boolean = false;
    get options(): SelectOption[] {
        return this.dataType.lookup ?? [];
    }
    get allowedOptions(): string[] {
        return this.attributes?.allowed;
    }
    get expectedValueOptions() {
        const depRef = this.dependentFieldControl.value as ObjectReference;
        const dependent = this.availableDependents?.find((f) => f.id === depRef.id);
        if (dependent && dependent?.field?.data_type.displayType !== "program") {
            return dependent.field.data_type.lookup || [];
        }
        return this.productOptions || [];
    }
    set allowedOptions(v: string[]) {
        if (v?.length) {
            this.attributes.allowed = v;
        } else {
            delete this.attributes?.allowed;
        }
    }

    get condition() {
        // currently only supports one condition
        return this.formField.conditions[0] || undefined;
    }

    selectAllOptions(event: MouseEvent): void {
        this.formGroup
            .get("allowedOptions")
            ?.setValue(this.options.map((option: SelectOption) => option.value));
    }

    toggleRequired(): void {
        this.required = !this.required;
    }
    toggleMultiple(): void {
        this.multiple = !this.multiple;
        // handle legacy multiple attribute
        if (!this.formField.multiple) delete this.formField.attributes?.multiple;
        this.rebuildControl();
    }
    toggleHidePrompt(): void {
        if (this.isPromptHidden) {
            delete this.attributes?.hidePrompt;
        } else {
            this.attributes.hidePrompt = true;
        }
    }
    toggleAutoresize(): void {
        this.autoresize = !this.autoresize;
        this.updateEnableDisable();
    }
    toggleSpecifyOptions(): void {
        this.specifyOptions = !this.specifyOptions;
        if (!this.specifyOptions) this.allowedOptions = [];
        this.updateEnableDisable();
    }
    toggleInline(): void {
        if (this.isInline) {
            delete this.attributes.layout?.compound;
        } else {
            if (!this.attributes.layout) this.attributes.layout = {};
            this.attributes.layout.compound = "inline";
        }
    }
    toggleAlternateCheckbox(event: MatCheckboxChange): void {
        if (event.checked) {
            if (!this.attributes.alt) this.attributes.alt = {};
            this.attributes.alt.control = "checkbox";
            this.updateAlternateCheckboxText();
        } else {
            delete this.attributes?.alt?.control;
        }
    }

    protected updateAlternateCheckboxText(): void {
        this.formGroup
            .get("alternateCheckboxText")
            ?.setValue(this.alternateCheckboxText);
    }
    protected updateCompoundProperties(compound?: CompoundDataType): void {
        this.compoundFormGroup.get("label")?.setValue(this.compoundLabel(compound));
        this.compoundFormGroup
            .get("placeholder")
            ?.setValue(this.compoundPlaceholder(compound));
        this.compoundFormGroup.get("width")?.setValue(this.compoundWidth(compound));
    }

    protected updateEnableDisable(): void {
        if (this.autoresize) {
            this.formGroup.get("minLines")?.enable();
            this.formGroup.get("maxLines")?.enable();
        } else {
            this.formGroup.get("minLines")?.disable();
            this.formGroup.get("maxLines")?.disable();
        }
        if (this.specifyOptions) {
            this.formGroup.get("allowedOptions")?.enable();
        } else {
            this.formGroup.get("allowedOptions")?.disable();
        }
    }
    protected rebuildControl(): void {
        // when we toggle multiple, we need to rebuild the form control to handle multiple values
        const oldControl = this.previewControl;
        if (this.multiple) this.previewControl = new UntypedFormArray([]);
        else if (this.dataType.isCompound)
            this.previewControl = DataFormEditorComponent.buildCompoundFormGroup(
                this.dataType,
                undefined,
                this.attributes,
            );
        else this.previewControl = new UntypedFormControl();
        if (this.controlName && oldControl?.parent) {
            const formGroup = oldControl.parent as FormGroup;
            formGroup.setControl(this.controlName, this.previewControl);
        }
    }

    protected updateProperties(): void {
        const prompt =
            this.formField?.attributes?.prompt ?
                this.formField?.attributes?.prompt
            :   this.displayName;
        this.formGroup.get("prompt")?.setValue(prompt);
        this.formGroup.get("label")?.setValue(this.label);
        this.formGroup.get("placeholder")?.setValue(this.placeholder);
        this.formGroup.get("instructions")?.setValue(this.instructions);
        this.formGroup
            .get("width")
            ?.setValue(this.width || DataFormFieldEditorComponent.DefaultWidth);
        this.formGroup.get("minLines")?.setValue(this.minLines);
        this.formGroup.get("maxLines")?.setValue(this.maxLines);
        this.formGroup.get("maxLength")?.setValue(this.maxLength);
        this.formGroup.get("numberValidationType")?.setValue(this.numberValidatorType);
        this.formGroup.get("min")?.setValue(this.minNumber);
        this.formGroup.get("max")?.setValue(this.maxNumber);
        this.formGroup.get("multipleLabel")?.setValue(this.multipleLabel);
        this.formGroup.get("singular")?.setValue(this.singular);
        this.formGroup.get("allowedOptions")?.setValue(this.allowedOptions);
        this.formGroup
            .get("compoundField")
            ?.setValue(
                this.dataType.compound?.length ? this.dataType.compound[0] : undefined,
            );
        this.formGroup.get("alternateCheckbox")?.setValue(this.isAlternateCheckbox);
        this.updateAlternateCheckboxText();

        this.formGroup.updateValueAndValidity();
        this.updateEnableDisable();
        this.updateCompoundProperties(this.selectedCompoundField);
        if (this.condition) {
            const { dependent_field, expected_value, condition_type } = this.condition;
            this.conditionFormGroup?.get("dependent_field")?.setValue(dependent_field);
            this.conditionFormGroup?.get("expected_value")?.setValue(expected_value);
            this.conditionFormGroup.get("condition_type")?.setValue(condition_type);
        }
    }
    get expectedValueDataType() {
        const dependent_field = this.conditionFormGroup?.get("dependent_field")?.value;

        if (
            dependent_field instanceof DataFormField &&
            dependent_field.field.data_type
        ) {
            return dependent_field.field.data_type;
        }

        const formField = this.availableDependents?.find((ff) => {
            return ff.id == dependent_field.id;
        });

        if (formField?.field.data_type) {
            return formField?.field.data_type;
        }

        return undefined;
    }
    get bindings() {
        if (this.isControlField) return {};

        //for non control fields it will be displaying in a mat-hint, which should not take more than one line
        return {
            customEnter: {
                key: "enter",
                handler: () => {}, //do nothing to prevent a newline from being added
            },
        };
    }

    get toolBar() {
        if (this.isControlField) {
            return []; //will default to all options
        }

        return [[{ color: [] }], ["link"], ["clean"]];
    }

    compareFn(f1: any, f2: any): boolean {
        return f1 && f2 ? f1.id === f2.id : f1 === f2;
    }

    get expectedValueViewMode() {
        return ObjectViewMode.Edit;
    }

    removeCondtion(event?: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.condition?.id) {
            const conditionService = AppInjector.get(DataFormFieldConditionService);
            conditionService.destroy(this.condition).subscribe(() => {
                this.conditionFormGroup.reset();
                this.formField.conditions = [];
            });
        } else {
            this.conditionFormGroup.reset();
            this.formField.conditions = [];
        }
    }
}
