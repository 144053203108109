import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import { merge, Subject, Subscription } from "rxjs";
import {
    AfterContentChecked,
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ContentChild,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    InjectionToken,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from "@angular/core";
import { CdkPortal, CdkPortalOutlet, TemplatePortal } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import {
    AnimationEvent,
    AnimationTriggerMetadata,
    state,
    style,
    transition,
    trigger,
    animate,
} from "@angular/animations";
import { TabChangeService } from "src/services/component.services";

const tabGroupInjectionToken = new InjectionToken<any>("detail-tab-group");
const tablLabelInjectionToken = new InjectionToken<any>("detail-tab-label");
const tabInjectionToken = new InjectionToken<any>("detail-tab");
const tabContentInjectionToken = new InjectionToken<DetailTabContent>(
    "detail-tab-content",
);

type DetailTabBodyPositionState =
    | "left"
    | "center"
    | "right"
    | "left-origin-center"
    | "right-origin-center";

const tabAnimations: { readonly translateTab: AnimationTriggerMetadata } = {
    translateTab: trigger("translateTab", [
        state(
            "center, void, left-origin-center, right-origin-center",
            style({ transform: "none" }),
        ),
        state(
            "left",
            style({ transform: "translate3d(-100%, 0, 0)", minHeight: "1px" }),
        ),
        state(
            "right",
            style({ transform: "translate3d(100%, 0, 0)", minHeight: "1px" }),
        ),
        transition(
            "* => left, * => right, left => center, right => center",
            animate("{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)"),
        ),
        transition("void => left-origin-center", [
            style({ transform: "translate3d(-100%, 0, 0)" }),
            animate("{{ animationDuration }} cubic-bezier(0.35, 0, 0.25, 1)"),
        ]),
        transition("void => right-origin-center", [
            style({ transform: "translate3d(100%, 0, 0)" }),
            animate("{{ animationDuration }} cubic-bezier(0.35, 0, 0.25, 1)"),
        ]),
    ]),
};

@Directive({
    selector: "[detailTabContent]",
    providers: [{ provide: tabContentInjectionToken, useExisting: DetailTabContent }],
})
export class DetailTabContent {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: "[detailTabBodyHost]",
})
export class DetailTabBodyPortal extends CdkPortalOutlet implements OnInit, OnDestroy {
    protected centeringSubscription?: Subscription;
    protected leavingSubscription?: Subscription;

    constructor(
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        @Inject(forwardRef(() => DetailTabBody)) protected host: DetailTabBody,
        @Inject(DOCUMENT) document: any,
    ) {
        super(componentFactoryResolver, viewContainerRef, document);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.centeringSubscription = this.host.beforeCentering
            .pipe(startWith(this.host.isCenterPosition(this.host.positionState)))
            .subscribe((isCentering: boolean) => {
                if (isCentering && !this.hasAttached()) this.attach(this.host.content);
            });
        this.leavingSubscription = this.host.afterLeavingCenter.subscribe(() =>
            this.detach(),
        );
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.centeringSubscription?.unsubscribe();
        this.leavingSubscription?.unsubscribe();
    }
}

@Component({
    selector: "detail-tab-body",
    templateUrl: "./detail-tab-body.component.html",
    styleUrls: ["./tab-detail.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [tabAnimations.translateTab],
    host: {
        class: "detail-tab-body",
    },
})
export class DetailTabBody implements OnInit, OnDestroy {
    @ViewChild(CdkPortalOutlet) portalHost!: CdkPortalOutlet;
    protected positionIndex?: number;
    positionState?: DetailTabBodyPositionState;

    @Output() readonly onCentering: EventEmitter<number> = new EventEmitter<number>();
    @Output() readonly beforeCentering: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() readonly afterLeavingCenter: EventEmitter<void> =
        new EventEmitter<void>();
    @Output() readonly onCentered: EventEmitter<void> = new EventEmitter<void>();

    @Input() content?: TemplatePortal;
    @Input() origin?: number;
    @Input() animationDuration: string = "500ms";
    @Input() set position(v: number | undefined) {
        this.positionIndex = v ?? 0;
        this.positionState = this.computePositionState();
    }

    readonly translateTabComplete = new Subject<AnimationEvent>();

    constructor(protected elementRef: ElementRef<HTMLElement>) {
        this.translateTabComplete
            .pipe(
                distinctUntilChanged(
                    (x: AnimationEvent, y: AnimationEvent) =>
                        x.fromState === y.fromState && x.toState == y.toState,
                ),
            )
            .subscribe((event: AnimationEvent) => {
                if (
                    this.isCenterPosition(event.toState) &&
                    this.isCenterPosition(this.positionState)
                )
                    this.onCentered.emit();
                if (
                    this.isCenterPosition(event.fromState) &&
                    this.isCenterPosition(this.positionState)
                )
                    this.afterLeavingCenter.emit();
            });
    }

    ngOnInit(): void {
        if (this.positionState == "center" && this.origin !== undefined)
            this.positionState = this.computePositionState(this.origin);
    }
    ngOnDestroy(): void {
        this.translateTabComplete.complete();
    }

    onTranslateTabStarted(event: AnimationEvent): void {
        const isCentering = this.isCenterPosition(event.toState);
        this.beforeCentering.emit(isCentering);
        if (isCentering)
            this.onCentering.emit(this.elementRef.nativeElement.clientHeight);
    }
    isCenterPosition(state?: string): boolean {
        return (
            state == "center" ||
            state == "left-origin-center" ||
            state == "right-origin-center"
        );
    }
    computePositionState(origin?: number): DetailTabBodyPositionState {
        if (origin === undefined) {
            if ((this.positionIndex || 0) < 0) return "left";
            else if ((this.positionIndex || 0) > 0) return "right";
            else return "center";
        }
        return (origin || 0) <= 0 ? "left-origin-center" : "right-origin-center";
    }
}

@Directive({
    selector: "[detailTabLabel], [detail-tab-label]",
    providers: [{ provide: tablLabelInjectionToken, useExisting: DetailTabLabel }],
})
export class DetailTabLabel extends CdkPortal {
    constructor(
        templateRef: TemplateRef<any>,
        viewContainerRef: ViewContainerRef,
        @Inject(tabInjectionToken) @Optional() public closestTab: any,
    ) {
        super(templateRef, viewContainerRef);
    }
}

@Component({
    selector: "detail-tab",
    template: `
        <ng-template><ng-content></ng-content></ng-template>
    `,
    styleUrls: ["./tab-detail.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [{ provide: tabInjectionToken, useExisting: DetailTab }],
    host: {
        class: "detail-tab",
    },
})
export class DetailTab implements OnDestroy, OnChanges, AfterContentInit {
    @ContentChild(tablLabelInjectionToken)
    get labelTemplate(): DetailTabLabel | undefined {
        return this._labelTemplate;
    }
    set labelTemplate(v: DetailTabLabel | undefined) {
        this.setLabelTemplate(v);
    }
    protected _labelTemplate?: DetailTabLabel;
    @Input() label: string = "";

    @ContentChild(DetailTabContent, { read: TemplateRef, static: true })
    templateContent?: TemplateRef<DetailTabContent>;
    @ViewChild(TemplateRef, { static: true }) implicitContent?: TemplateRef<any>;
    protected contentPortal?: TemplatePortal;
    get content(): TemplatePortal | undefined {
        return this.contentPortal;
    }

    @Input() disabled: boolean = false;
    @Output() onSelected: EventEmitter<DetailTab> = new EventEmitter<DetailTab>(); // event contains previous tab
    @Output() onDeselected: EventEmitter<DetailTab> = new EventEmitter<DetailTab>(); // event contains next tab

    readonly stateChanges = new Subject<void>();
    position?: number;
    origin?: number;
    isActive: boolean = false;

    constructor(
        protected viewContainerRef: ViewContainerRef,
        @Inject(tabGroupInjectionToken) @Optional() public closestTabGroup: any,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty("label") || changes.hasOwnProperty("disabled"))
            this.stateChanges.next();
    }
    ngAfterContentInit(): void {
        if (this.templateContent || this.implicitContent)
            this.contentPortal = new TemplatePortal(
                (this.templateContent ?? this.implicitContent)!,
                this.viewContainerRef,
            );
    }
    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    protected setLabelTemplate(label?: DetailTabLabel): void {
        if (label && label.closestTab === this) this._labelTemplate = label;
    }
}

@Component({
    selector: "detail-tab-group",
    templateUrl: "./detail-tab-group.component.html",
    styleUrls: ["./tab-detail.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [{ provide: tabGroupInjectionToken, useExisting: DetailTabGroup }],
    host: {
        class: "detail-tab-group",
    },
})
export class DetailTabGroup
    implements AfterContentChecked, AfterContentInit, OnDestroy
{
    @ContentChildren(DetailTab, { descendants: true }) allTabs!: QueryList<DetailTab>;
    @ViewChild("tabBodyWrapper") tabBodyWrapper!: ElementRef;
    tabs: QueryList<DetailTab> = new QueryList<DetailTab>();

    protected indexToSelect?: number;
    protected tabsSubscription?: Subscription;
    protected tabLabelSubscription?: Subscription;

    get selectedIndex(): number | undefined {
        return this._selectedIndex;
    }
    set selectedIndex(v: number | undefined) {
        // NOSONAR
        this.indexToSelect = v;
    }
    protected _selectedIndex?: number;

    @Output() readonly selectedIndexChanged: EventEmitter<number> =
        new EventEmitter<number>();
    @Output() readonly selectedTabChanged: EventEmitter<DetailTab> =
        new EventEmitter<DetailTab>();

    private tabNamesMap: { [key: string]: { tab: DetailTab; index: number } } = {};

    constructor(
        elementRef: ElementRef,
        protected changeDetectorRef: ChangeDetectorRef,
        private service: TabChangeService,
    ) {
        this.service.tabWillChange
            .pipe(
                map((props) => {
                    const { tabName } = props;
                    if (this.tabNamesMap[tabName]) {
                        const { tab, index } = this.tabNamesMap[tabName];
                        this.onSelectTab(tab, index);
                        return props;
                    }
                    return {} as any;
                }),
            )
            .subscribe();
    }

    ngAfterContentChecked(): void {
        const indexToSelect = Math.min(
            Math.max(this.tabs.length - 1, 0),
            Math.max(this.indexToSelect ?? 0, 0),
        );
        this.indexToSelect = indexToSelect;
        if (this.selectedIndex != indexToSelect) {
            const first = this.selectedIndex === undefined;
            if (!first && this.tabs.length) {
                this.selectedTabChanged.emit(this.tabs.toArray()[indexToSelect]);
                const wrapper = this.tabBodyWrapper.nativeElement;
                wrapper.style.minHeight = wrapper.clientHeight + "px";
            }
            Promise.resolve().then(() => {
                this.tabs.forEach(
                    (tab: DetailTab, index: number) =>
                        (tab.isActive = index == indexToSelect),
                );
                if (!first) {
                    this.selectedIndexChanged.emit(indexToSelect);
                    this.tabBodyWrapper.nativeElement.style.minHeight = "";
                }
            });
        }

        this.tabs.forEach((tab: DetailTab, index: number) => {
            tab.position = index - indexToSelect;
            if (this.selectedIndex !== undefined && tab.position == 0 && !tab.origin)
                tab.origin = indexToSelect - this.selectedIndex;
        });

        if (this.selectedIndex !== indexToSelect) {
            this._selectedIndex = indexToSelect;
            this.changeDetectorRef.markForCheck();
        }
    }

    ngAfterContentInit(): void {
        this.allTabs.changes
            .pipe(startWith(this.allTabs))
            .subscribe((tabs: QueryList<DetailTab>) => {
                this.tabNamesMap = Object.assign(
                    {},
                    ...tabs.map((tab: DetailTab, index: number) => ({
                        [tab?.label?.toLowerCase()]: { tab, index },
                    })),
                );
                this.tabs.reset(
                    tabs.filter(
                        (tab: DetailTab) =>
                            tab.closestTabGroup === this || !tab.closestTabGroup,
                    ),
                );
                this.tabs.notifyOnChanges();
            });

        if (this.tabLabelSubscription) {
            this.tabLabelSubscription.unsubscribe();
            this.tabLabelSubscription = undefined;
        }
        this.tabLabelSubscription = merge(
            ...this.tabs.map((tab: DetailTab) => tab.stateChanges),
        ).subscribe(() => {
            this.changeDetectorRef.markForCheck();
        });

        this.tabsSubscription = this.tabs.changes.subscribe(() => {
            const indexToSelect = Math.min(
                Math.max(this.tabs.length - 1, 0),
                Math.max(this.indexToSelect ?? 0, 0),
            );
            if (indexToSelect === this.selectedIndex) {
                const tabs = this.tabs.toArray();
                for (let i = 0; i < tabs.length; i++) {
                    if (tabs[i].isActive) {
                        this.indexToSelect = this._selectedIndex = i;
                        break;
                    }
                }
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.tabs.destroy();
        this.tabsSubscription?.unsubscribe();
        this.tabLabelSubscription?.unsubscribe();
    }

    onSelectTab(
        tab: DetailTab,
        index: number,
        changedWithButton: boolean = true,
    ): void {
        if (!tab.disabled) {
            const currentTab =
                this.selectedIndex != undefined ?
                    this.allTabs.get(this.selectedIndex)
                :   undefined;
            currentTab?.onDeselected.emit(tab);
            this.selectedIndex = index;

            //this.service.currentTab = tab;
            tab.onSelected.emit(currentTab);

            if (changedWithButton) {
                this.service.tabWillChange.next({} as any);
            }
        }
    }
}
