import {
    AfterViewInit,
    EventEmitter,
    ViewChildren,
    Output,
    ViewChild,
    Component,
    Input,
    inject,
} from "@angular/core";
import {
    FormGroup,
    UntypedFormControl,
    UntypedFormArray,
    UntypedFormGroup,
} from "@angular/forms";
import {
    CdkDragEnter,
    CdkDragExit,
    CdkDragMove,
    CdkDragRelease,
    CdkDragEnd,
    CdkDrag,
    CdkDropList,
    CdkDragDrop,
    CdkDragStart,
} from "@angular/cdk/drag-drop";
import { ObjectViewMode } from "src/common/components/object.component";
import { DataFormField } from "src/services/models/data";
import { DragDropService } from "src/common/utilities/drag-drop";
import { DataFormEditorComponent } from "./data-form.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { DataFormFieldEditorComponent } from "./data-form-field.component";
import { DataFormGroupComponent } from "../../data-form/data-form-group.component";
import { ObjectOrReference, objectsOnly } from "src/services/models/api-object";
import { MatDialog } from "@angular/material/dialog";

export type FormFieldEvent = {
    field: DataFormField;
    group?: FormGroup;
};

@Component({
    selector: "data-form-editor-group",
    templateUrl: "./data-form-group.component.html",
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormGroupEditorComponent
    extends DataFormGroupComponent
    implements AfterViewInit
{
    @ViewChild(CdkDropList) dropList?: CdkDropList;
    @ViewChildren(DataFormGroupEditorComponent)
    formGroupEditors: DataFormGroupEditorComponent[] = [];
    @Input() autoScrollStep: number = 16;
    @Input() useDragHandles: boolean = false;

    @Output() formFieldAdded: EventEmitter<FormFieldEvent> =
        new EventEmitter<FormFieldEvent>();
    @Output() formFieldRemoved: EventEmitter<FormFieldEvent> =
        new EventEmitter<FormFieldEvent>();
    @Output() formFieldChanged: EventEmitter<FormFieldEvent> =
        new EventEmitter<FormFieldEvent>();

    draggingGroup: boolean = false;
    dragDropService: DragDropService;
    allowDropPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        return this.canDrop(drag, drop);
    };

    bottomSheet: MatBottomSheet;
    dialog: MatDialog;

    get canEdit(): boolean {
        return this.mode == ObjectViewMode.Create && !this.viewOnly;
    }
    get isBuilder(): boolean {
        return (
            (this.mode == ObjectViewMode.Edit || this.mode == ObjectViewMode.Create) &&
            this.isTemplate
        );
    }
    get isTemplate(): boolean {
        return !this.form?.template;
    }

    constructor() {
        super();
        this.dragDropService = inject(DragDropService);
        this.bottomSheet = inject(MatBottomSheet);
        this.dialog = inject(MatDialog);
    }

    ngAfterViewInit(): void {
        if (this.dropList) this.dragDropService.register(this.dropList);
    }

    isControlField(field: ObjectOrReference<DataFormField>): boolean {
        return DataFormEditorComponent.isControlFormField(field);
    }

    isInline(field: ObjectOrReference<DataFormField>): boolean {
        return (
            field instanceof DataFormField &&
            field.attributes?.layout?.compound == "inline"
        );
    }
    isMultiple(field: ObjectOrReference<DataFormField>): boolean {
        return (
            field instanceof DataFormField &&
            (field.attributes?.multiple || field.multiple)
        );
    }
    isRequired(field: ObjectOrReference<DataFormField>): boolean {
        return field instanceof DataFormField && field.required;
    }

    toggleInline(event: MouseEvent, field: ObjectOrReference<DataFormField>): void {
        if (field instanceof DataFormField) {
            if (this.isInline(field)) {
                delete field.attributes!.layout.compound;
            } else {
                if (!field.attributes) field.attributes = {};
                if (!field.attributes.layout) field.attributes.layout = {};
                field.attributes.layout.compound = "inline";
            }
            this.onFormFieldChanged(field);
        }
    }
    toggleRequired(event: MouseEvent, field: ObjectOrReference<DataFormField>): void {
        if (field instanceof DataFormField) {
            field.required = !field.required;
            this.onFormFieldChanged(field);
        }
    }
    toggleMultiple(event: MouseEvent, field: ObjectOrReference<DataFormField>): void {
        if (field instanceof DataFormField) {
            field.multiple = !this.isMultiple(field);

            // handle legacy multiple attribute
            if (!field.multiple) delete field.attributes?.multiple;

            // when we toggle multiple, we need to rebuild the form control to handle multiple values
            if (field.multiple) field.previewControl = new UntypedFormArray([]);
            else if (field.field.data_type.isCompound)
                field.previewControl = DataFormEditorComponent.buildCompoundFormGroup(
                    field.field.data_type,
                    undefined,
                    field.attributes,
                );
            else field.previewControl = new UntypedFormControl();
            if (field.field.name)
                this.group?.setControl(field.field.name, field.previewControl);
            this.onFormFieldChanged(field);
        }
    }
    editFormField(event: MouseEvent, field: ObjectOrReference<DataFormField>): void {
        if (field instanceof DataFormField) {
            this.dialog
                .open(DataFormFieldEditorComponent, {
                    data: {
                        formField: field,
                        fieldsInForm: objectsOnly(
                            this.sortedFields,
                            DataFormField,
                        ).filter(
                            (f) =>
                                this.canBeDependent(f) &&
                                (f?.id !== field?.id || f.order !== field.order),
                        ),
                        productOptions: this.productOptions,
                    },
                    width: "50vw",
                    minWidth: "400px",
                    maxHeight: "80vh",
                    hasBackdrop: false,
                    position: {
                        left: "2rem",
                        top: "2rem",
                    },
                })
                .afterClosed()
                .subscribe((changed: boolean) => this.onFormFieldChanged(field));
        }
    }
    canBeDependent(formField: DataFormField): boolean {
        const allowed = [
            "text.short",
            "text.long",
            "program",
            "date",
            "datetime",
            "lookup",
        ];
        const allowedType = allowed.includes(formField.field.data_type.displayType);
        const notCompound = !formField.field.data_type.isCompound;
        const notMultiple = !formField.multiple;
        return (
            notMultiple && notCompound && allowedType && !this.isControlField(formField)
        );
    }
    canDrop(drag: CdkDrag, drop: CdkDropList): boolean {
        let canDrop = false;
        if (this.isBuilder) {
            if (this.dragDropService.currentHoverDropListId == null) canDrop = true;
            else canDrop = drop.id === this.dragDropService.currentHoverDropListId;
        }
        return canDrop;
    }
    onDrop(event: CdkDragDrop<DataFormField>): void {
        const formField = event.item.data;

        this.removeFormField(formField);
        formField.group = this.formField;

        // drop the field in the spot specified
        this.sortedFields.splice(event.currentIndex, 0, formField);
        this.sortedFields.forEach(
            (field: ObjectOrReference<DataFormField>, index: number) => {
                if (field instanceof DataFormField) field.order = index;
            },
        );
        if (this.formField instanceof DataFormField)
            this.formField.children = this.sortedFields;
        if (formField.isToolboxField) {
            this.onFormFieldAdded(formField, this.group);
            formField.isToolboxField = false;
        } else {
            this.onFormFieldChanged(formField, this.group);
        }
    }
    onDragStart(event: CdkDragStart): void {
        this.draggingGroup = this.isGroupField(event.source.data);
    }
    onDragEnter(event: CdkDragEnter): void {
        this.dragDropService.dragEntered(event);
    }
    onDragMoved(event: CdkDragMove): void {
        this.dragDropService.dragMoved(event);
    }
    onDragExit(event: CdkDragExit): void {
        this.dragDropService.dragExited(event);
    }
    onDragEnd(event: CdkDragEnd): void {
        this.draggingGroup = false;
    }
    onDragReleased(event: CdkDragRelease): void {
        this.dragDropService.dragReleased(event);
    }

    removeFormField(
        field: ObjectOrReference<DataFormField>,
        visited: DataFormGroupEditorComponent[] = [],
    ): UntypedFormGroup | undefined {
        visited.push(this);
        const filtered = this.sortedFields.filter(
            (sorted: ObjectOrReference<DataFormField>) => sorted != field,
        );
        if (filtered.length != this.sortedFields.length) {
            this.sortedFields = filtered;
            this.sortedFields.forEach(
                (field: ObjectOrReference<DataFormField>, index: number) => {
                    if (field instanceof DataFormField) field.order = index;
                },
            );
            if (this.formField instanceof DataFormField)
                this.formField.children = this.sortedFields;
            return this.group;
        }
        for (let subgroup of this.formGroupEditors) {
            const hasVisited = visited.indexOf(subgroup) != -1;
            const formGroup =
                hasVisited ? undefined : subgroup.removeFormField(field, visited);
            if (formGroup) return formGroup;
        }
        const parentEditor = this.parent as DataFormGroupEditorComponent;
        return parentEditor?.removeFormField(field, visited) ?? undefined;
    }
    onFormFieldAdded(field: DataFormField, group?: FormGroup): void {
        this.formFieldAdded.emit({ field: field, group: group ?? this.group });
    }
    onFormFieldRemoved(field: DataFormField, group?: FormGroup): void {
        this.formFieldRemoved.emit({ field: field, group: group ?? this.group });
    }
    onFormFieldChanged(field: DataFormField, group?: FormGroup): void {
        this.formFieldChanged.emit({ field: field, group: group ?? this.group });
    }
}
