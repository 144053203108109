<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
    [hideControls]="hideControls"
    [hideHeader]="showSpinner"
>
    <div form-title class="flexible flex" *ngIf="mode != ObjectViewMode.Create">
        <div class="spacer"></div>
        <button
            mat-flat-button
            class="brand-medium"
            *ngIf="!viewOnly"
            (click)="toggleEditMode($event)"
        >
            <span *ngIf="mode == ObjectViewMode.View">
                <mat-icon matTooltip="Edit">open_in_new</mat-icon>
                Edit Details
            </span>
            <span *ngIf="mode == ObjectViewMode.Edit">
                <mat-icon matTooltip="View">open_in_new_off</mat-icon>
                View Details
            </span>
        </button>
        <button
            mat-flat-button
            class="brand-medium"
            (click)="exportAsPDF($event)"
            *ngIf="allowExport"
            matTooltip="Export"
        >
            <mat-icon>file_download</mat-icon>
            {{ exportButtonText }}
        </button>
    </div>
    <div class="container flexible flex stack" *ngIf="!showSpinner">
        <div class="flexible flex stack">
            <p class="subtitle" *ngIf="!viewOnly && !!fullObject">
                {{ fullObject!.description }}
            </p>
            <data-form-group
                class="flexible flex stack"
                [form]="fullObject"
                [mode]="mode"
                [viewOnly]="viewOnly"
                [group]="formGroup"
                [fields]="formFields"
                [values]="fullObject?.values || []"
                [productOptions]="productOptions"
            ></data-form-group>
        </div>
    </div>
    <div *ngIf="showSpinner">
        <div class="spinner">
            <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
        </div>
        <div class="spinner-message">Building Form ...</div>
    </div>
    <ng-content *ngIf="!showSpinner"></ng-content>
</object-form>
