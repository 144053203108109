import { APIObject, ObjectReference } from "./api-object";

export type ActivityDefinition = { value: number; display: string };
export type EventDefinition = { value: string; display: string };
export type AuditFilterOption = { value: any; displayName: string; count: number };

export class AuditReference extends ObjectReference {
    is_deleted?: boolean;

    initialize(data?: any, patch: boolean = false) {
        super.initialize(data, patch);
        this.setMember(data, patch, "is_deleted");
    }
}
export class Audit extends APIObject {
    static object_type: string = "common.audit";
    timestamp!: Date;
    reference?: AuditReference; // optional
    account?: AuditReference; // Account, optional
    ip_address?: string; // read-only
    previous?: ObjectReference; // Audit, optional
    activity!: number;
    event?: string; // optional
    value?: object; // read-only
    state?: object; // read-only
    history?: Audit[]; // read-only

    get isComplete(): boolean {
        return super.isComplete && !!this.history;
    }

    static activities: ActivityDefinition[] = [
        { value: 1, display: "Object Created" },
        { value: 2, display: "Object Modified" },
        { value: 3, display: "Object Deleted" },
        { value: 4, display: "Object Relationship Added" },
        { value: 5, display: "Object Relationship Removed" },
        { value: 6, display: "Object Relationship Cleared" },
        { value: 7, display: "Event" },
    ];
    static events: EventDefinition[] = [];

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["reference", "account", "previous", "event"]);
        this._readOnly.push(...["value", "state", "ip_address", "history"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "timestamp", Date);
        this.setMember(data, patch, "reference", AuditReference);
        this.setMember(data, patch, "account", AuditReference);
        this.setMember(data, patch, "ip_address");
        this.setMember(data, patch, "previous", ObjectReference);
        this.setMember(data, patch, "activity");
        this.setMember(data, patch, "event");
        this.setMember(data, patch, "value");
        this.setMember(data, patch, "state");
        this.setMember(data, patch, "history", Audit, true);
    }

    get activityDisplay(): string {
        const def = Audit.activities.find(
            (a: ActivityDefinition) => a.value === this.activity,
        );
        return def ? def.display : "Unknown Activity";
    }
    get eventDisplay(): string {
        const def = Audit.events.find((e: EventDefinition) => e.value === this.event);
        return def ? def.display : "Unknown Event";
    }
}

export class AuditFilter {
    earliest?: Date;
    latest?: Date;
    types: AuditFilterOption[] = [];
    events: AuditFilterOption[] = [];
    activities: AuditFilterOption[] = [];
    users: AuditFilterOption[] = [];
}
