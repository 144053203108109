import { Component, Input } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ObjectComponent } from "src/common/components/object.component";
import { AuditService } from "src/services/iam.services";
import { Audit, AuditFilter, AuditFilterOption } from "src/services/models/audit";
import { AuditRestoreComponent } from "./audit-restore.component";

@Component({
    selector: "audit-detail",
    templateUrl: "./audit-detail.component.html",
    styleUrls: ["./admin-audit.component.scss"],
})
export class AuditDetailComponent extends ObjectComponent<Audit> {
    objectName = "Audit Entry";

    @Input() filters?: AuditFilter;

    page: number = 0;
    perPage: number = 10;
    paginatorLength: number = 0;
    pageSizeOptions: number[] = [5, 10, 25, 50];
    displayedColumns: string[] = ["timestamp", "account", "reference_type", "event"];
    loading: boolean = false;

    constructor(protected service: AuditService) {
        super(service);
    }

    get state(): string {
        return this.fullObject ? JSON.stringify(this.fullObject?.state, null, 4) : "";
    }
    get pagedHistory(): Audit[] {
        const start = this.page * this.perPage;
        const end = start + this.perPage;
        return this.fullObject?.history?.slice(start, end) ?? [];
    }

    handlePageEvent(event: PageEvent): void {
        this.page = event.pageIndex;
        this.perPage = event.pageSize;
    }

    onSortChange(sort: Sort): void {}

    restoreObject(): void {
        if (this.object) {
            this.service.dependencies(this.object).subscribe((deps: Audit[]) => {
                this.dialog
                    .open(AuditRestoreComponent, {
                        data: deps,
                        minWidth: "66vw",
                        minHeight: "66vh",
                        maxWidth: "90vw",
                        maxHeight: "90vh",
                        disableClose: true,
                        hasBackdrop: true,
                    })
                    .afterClosed()
                    .subscribe((restore?: Audit[]) => {
                        if (restore) {
                            const restoreObjects = restore.map(
                                (a: Audit) => a.asReference,
                            );
                            this.service.restore(restoreObjects).subscribe({
                                next: () => {
                                    this.snackbar.open(
                                        "Objects successfully restored.",
                                        undefined,
                                        { duration: 3000 },
                                    );
                                    this.setObjectOrReference(this.object?.asReference);
                                },
                                error: () => {
                                    this.snackbar.open(
                                        "Error restoring objects.",
                                        undefined,
                                        { duration: 3000 },
                                    );
                                },
                            });
                        }
                    });
            });
        }
    }

    changeObject(entry: Audit): void {
        this.setObjectOrReference(entry);
    }

    entryType(entry: Audit): string {
        const type = this.filters?.types.find(
            (t: AuditFilterOption) => t.value == entry.reference?.type,
        );
        if (!type?.displayName && !entry.reference?.type) return "Unknown Type";
        return type?.displayName ?? entry.reference?.type ?? "Unknown Type";
    }
    entryEvent(entry: Audit): string {
        const event =
            this.filters?.events.find(
                (t: AuditFilterOption) => entry.event && t.value == entry.event,
            ) ??
            this.filters?.activities.find(
                (t: AuditFilterOption) => t.value == entry.activity,
            );
        return event?.displayName ?? entry.event ?? entry.activity.toString();
    }

    protected setObject(v?: Audit | undefined): void {
        super.setObject(v);
        this.objectName = "Audit Entry: " + v?.displayName;
        this.paginatorLength = this.fullObject?.history?.length ?? 0;
    }
}
