import { ChangeDetectorRef, Directive, inject } from "@angular/core";
import { debounceTime, filter, finalize, mergeMap, tap } from "rxjs/operators";
import { Observable, forkJoin, of } from "rxjs";
import {
    APIObject,
    ObjectChangeEvent,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
    PaginatedList,
} from "src/services/models/api-object";
import { SessionComponent } from "src/services/components/session.component";
import { APIService } from "src/services/api.service";
import { SearchableList } from "../utilities/searchable-list";
import { ObjectViewEntryPoint, ObjectViewMode } from "./object.component";
import { ConfirmDialog } from "./confirm.dialog";
import { contentStack, ContentView } from "./content-stack.component";
import { ComponentType } from "@angular/cdk/overlay";
import { Breadcrumb, BreadcrumbProvider } from "./breadcrumbs/breadcrumb.component";
import { RequestFilter } from "../utilities/request";
import { defined } from "../utilities/flatten";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AppInjector } from "../utilities/injector";

export type ObjectView<T extends APIObject> = ComponentType<ObjectViewEntryPoint<T>>;

@Directive()
export class ObjectAdminComponent<T extends APIObject>
    extends SessionComponent
    implements BreadcrumbProvider
{
    list: SearchableList<T>;
    objectView?: ObjectView<T>;
    listTitle?: string;
    protected dialog: MatDialog;
    protected subscriptionIndex?: number;
    protected highlight: string[] = [];
    get paginatorLength() {
        return this?.list.paginatorLength;
    }
    constructor(
        protected service: APIService<T>,
        protected changeDetection: ChangeDetectorRef,
        pageSize?: number,
        listTitle: string = "object-admin",
    ) {
        super();
        this.dialog = inject(MatDialog);
        this.list = new SearchableList<T>(
            this.service,
            this.filter.bind(this),
            pageSize,
            listTitle,
        );
        this.list.postSearch = this.resolveReferences.bind(this);
        this.listTitle = listTitle;
    }
    protected resolveReferences(list: PaginatedList<T>): PaginatedList<T> {
        setTimeout(() => {
            this.list.searching = true;
            this.list.hasLoaded = false;
            const observables = this.list.items.map((v: ObjectOrReference<T>) =>
                this.resolveReferenceObservable(v),
            );
            forkJoin(observables)
                .pipe(
                    finalize(() => {
                        this.list.searching = false;
                        this.list.hasLoaded = true;
                    }),
                )
                .subscribe((result: (T | undefined)[]) => {
                    this.list.updateItems(this.postSearch(defined(result)));
                });
        });
        return list;
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.list.listChanged.subscribe(() => this.changeDetection.detectChanges());
        this.subscriptionIndex = this.service.subscribe(this.list.filters);
        this.service.objectCreated
            .pipe(
                filter(
                    (event: ObjectChangeEvent<T>) =>
                        !!this.subscriptionIndex &&
                        (event.matched_filters ?? []).includes(this.subscriptionIndex),
                ),
                debounceTime(250),
            )
            .subscribe((event: ObjectChangeEvent<T>) =>
                this.onObjectCreated(event.object),
            );
        this.service.objectUpdated
            .pipe(
                filter(
                    (event: ObjectChangeEvent<T>) =>
                        !!this.subscriptionIndex &&
                        (event.matched_filters ?? []).includes(this.subscriptionIndex),
                ),
                debounceTime(250),
                tap(() => console.log("Object updated")),
            )
            .subscribe((event: ObjectChangeEvent<T>) =>
                this.onObjectUpdated(event.replace ?? event.object, event.change_set),
            );
        this.service.objectDeleted
            .pipe(
                filter((event: ObjectChangeEvent<T>) => !!this.subscriptionIndex),
                debounceTime(250),
            )
            .subscribe((event: ObjectChangeEvent<T>) =>
                this.onObjectDeleted(event.object),
            );
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.service.unsubscribe(this.subscriptionIndex);
    }

    isHighlighted(item: T): boolean {
        const highighted = !!item.id && this.highlight.includes(item.id);
        return highighted;
    }

    getBreadcrumbs(current: boolean): Breadcrumb | Breadcrumb[] | undefined {
        return undefined;
    }

    isReference(item: ObjectOrReference<T>): boolean {
        return item instanceof ObjectReference;
    }
    isObject(item: ObjectOrReference<T>): boolean {
        return !this.isReference(item);
    }
    asObject(item: ObjectOrReference<T>): T {
        return item as T;
    }

    static showObject<U extends APIObject>(
        object: U | ObjectReference | undefined,
        view: ObjectView<U> | undefined,
        mode: ObjectViewMode = ObjectViewMode.View,
        dialogConfiguration?: MatDialogConfig,
    ): ObjectViewEntryPoint<U> | undefined {
        let instance: ObjectViewEntryPoint<U> | undefined;
        if (object && view) {
            if (dialogConfiguration) {
                // Display the object view in a dialog
                const dialog = AppInjector.get(MatDialog);
                const ref = dialog.open(view, dialogConfiguration);
                instance = ref.componentInstance;
                instance.dialogReference = ref;
            } else {
                // Display the object on the content stack
                const contentView: ContentView<ObjectViewEntryPoint<U>> = {
                    type: view,
                    breadcrumbText: object.displayName,
                };
                // JT - we removed breadcrumb navigation, so every view should be the only one on the stack, set it at index 0
                instance = contentStack?.push(contentView, 0);
            }
            if (instance) {
                instance.mode = mode;
                instance.autosave = !dialogConfiguration;
                instance.autosaveOnCreate = false;
                instance.object = object;
            }
        }
        return instance;
    }
    viewObject(
        event: MouseEvent | undefined,
        object?: T,
        asDialog: boolean = false,
    ): ObjectViewEntryPoint<T> | undefined {
        return this.editObject(event, object, asDialog, true);
    }
    editObject(
        event: MouseEvent | undefined,
        object?: T,
        asDialog: boolean = false,
        viewOnly: boolean = false,
    ): ObjectViewEntryPoint<T> | undefined {
        let mode = ObjectViewMode.Create;
        if (viewOnly) mode = ObjectViewMode.View;
        else if (object?.id) mode = ObjectViewMode.Edit;
        object = object ?? this.newObject();
        const instance = ObjectAdminComponent.showObject<T>(
            object,
            this.getObjectView(),
            mode,
            asDialog ? this.objectDialogConfiguration(object, mode) : undefined,
        );
        if (asDialog && instance?.dialogReference)
            instance.dialogReference.afterClosed().subscribe((v?: any) => {
                if (v instanceof APIObject && v.id == object?.id) {
                    object?.update(v);
                }
                this.list.refresh(true);
            });
        return instance;
    }
    createObject(
        event?: MouseEvent,
        asDialog: boolean = true,
    ): ObjectViewEntryPoint<T> | undefined {
        const instance = this.editObject(event, this.newObject(), asDialog);
        if (asDialog && instance?.dialogReference)
            instance.dialogReference
                .afterClosed()
                .subscribe((o: T) => this.onAfterCreate(o, instance));
        return instance;
    }
    deleteObject(event: MouseEvent, object: T): void {
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        "Are you sure you want to delete '" + object.displayName + "'?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) =>
                    confirm ? this.service.destroy(object) : of(null),
                ),
            )
            .subscribe();
    }
    disableObject(
        event: MouseEvent,
        object: T,
        message: string | undefined = undefined,
    ): void {
        // some objects we dont actually want deleted, we just want to flip a boolean flag
        const disabledObject: any = { ...object };
        disabledObject.deleted = true;

        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        message ??
                        "Are you sure you want to delete '" + object.displayName + "'?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) =>
                    confirm ? this.service.patch(object, disabledObject) : of(null),
                ),
            )
            .subscribe((o) => {
                if (o) {
                    object.update({ ...o });
                }
            });
    }

    newObject(data?: any): T | undefined {
        return ObjectFactory.makeObject<T>(data, this.service.object_type) as T;
    }

    protected objectDialogConfiguration(
        object: T | undefined,
        mode: ObjectViewMode,
    ): MatDialogConfig {
        return {
            minWidth: 480,
            maxWidth: "90vw",
            maxHeight: "90vh",
            disableClose: true,
            hasBackdrop: true,
        };
    }
    protected tabSubtitle(object: T): string | undefined {
        return undefined;
    }
    protected getObjectView(): ObjectView<T> | undefined {
        return this.objectView;
    }

    protected updateList(term?: string | null): void {
        term = term === null ? "" : term ?? this.list.searchTerm;
        const filters = this.list.update(term);
        if (this.subscriptionIndex)
            this.service.subscribe(filters, this.subscriptionIndex);
    }

    protected onAfterCreate(o: T, instance: ObjectViewEntryPoint<T>): void {}
    protected filter(filters: RequestFilter): RequestFilter {
        return filters;
    }
    protected postSearch(items: T[]): T[] {
        return items;
    }
    protected resolveReferenceObservable(
        ref: OptionalObjectOrReference<T>,
    ): Observable<T | undefined> {
        return ObjectFactory.objectObservable(ref).pipe(
            tap((o: T | undefined) => this.list.replaceReference(o)),
        );
    }

    protected onObjectCreated(o: ObjectOrReference<T>): void {
        // can we just add the object to the list? how does this affect paging
        if (this.list.isPaginated) this.updateList();
        else {
            // make sure the item doesn't already exist in the list
            if (!this.list.items.find((v: ObjectOrReference<T>) => v.id == o.id)) {
                if (o.id) this.highlight.push(o.id);
                this.list.list.items = [o, ...this.list.list.items];
                this.list.listChanged.next(this.list.list.items);
            }
        }
    }
    protected onObjectDeleted(o: ObjectOrReference<T>): void {
        if (this.list.isPaginated) this.updateList();
        else {
            this.list.list.items = this.list.items.filter(
                (v: ObjectOrReference<T>) => v.id != o.id,
            );
            this.list.listChanged.next(this.list.list.items);
        }
    }
    protected onObjectUpdated(o: ObjectOrReference<T>, change_set?: any): void {
        const object = this.list.items.find((l: ObjectOrReference<T>) => l.id == o.id);
        if (!object) this.onObjectCreated(o);
        else if (object instanceof ObjectReference && !(o instanceof ObjectReference)) {
            // we're replacing a reference with a real object
            const index = this.list.items.indexOf(object);
            this.list.list.items[index] = o;
            this.list.list.items = [...this.list.list.items]; // make sure the binding updates
            this.list.listChanged.next(this.list.list.items);
        } else {
            // the object update should be handled by the caching mechanism, but in case we have objects that weren't cached, update it
            object.update(o);
        }
    }
}
