import { debounceTime, filter } from "rxjs/operators";
import { ObjectAdminComponent } from "./object-admin.component";
import { APIObject } from "src/services/models/api-object";
import { ChangeDetectorRef, Directive, ElementRef, ViewChild } from "@angular/core";
import { APIService } from "src/services/api.service";
import { UntypedFormControl } from "@angular/forms";

@Directive()
export class SearchableListComponent<
    T extends APIObject,
> extends ObjectAdminComponent<T> {
    showSearch: boolean = false;
    searchTermControl: UntypedFormControl = new UntypedFormControl();
    @ViewChild("search") searchElement?: ElementRef;

    constructor(
        protected service: APIService<T>,
        protected changeDetection: ChangeDetectorRef,
        pageSize: number = -1,
        listTitle: string = "searchable-list",
    ) {
        super(service, changeDetection, pageSize, listTitle);
    }

    get displayedColumns(): string[] {
        return this.getDisplayedColumns();
    }
    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => this.updateList(term));
    }
    resetSearchTerm(event?: MouseEvent): void {
        this.terminateEvent(event);
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
    }
    toggleSearch(event?: MouseEvent): void {
        this.terminateEvent(event);
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
    }
    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }
    onFocusOut(event?: any): void {
        this.autoToggleSearch();
    }

    protected getDisplayedColumns(): string[] {
        return [];
    }
}
