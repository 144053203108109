import { APIObject, ObjectReference } from "./api-object";
import { Case } from "./case";
import { DataForm } from "./data";
import { Inquiry } from "./inquiry";

export class Shipment extends APIObject {
    static object_type: string = "shipping.shipment";
    inquiry?: Inquiry;
    data_form?: DataForm;
    request_type?: string;
    quantity_requested?: string;

    case_reference?: string;
    reference_identifier?: string;

    program_name?: string;
    physician_institution?: string;
    physician?: string;
    case_name?: string;
    date_requested?: Date;
    date_received?: Date;

    case?: Case; // Case - the case that this shipment belongs to
    ind?: string; // the IND from the case or an overwritten value
    patient?: string; // a patient identifier
    project_code?: string; // a projet code number
    product_name?: string; // the product name from the case or an overwritten value
    lot?: string; // lot # of the shipment
    lot_expiration?: Date; // lot expiration date
    total_quantity?: number; // total quantity of the shipment
    total_unit?: string; // a unit specifier
    dose_form?: string; // dose format
    dose_quantity?: number; // dose quantity
    dose_unit?: string; // a unit specifier
    address?: string; // the address where the shipment is going to
    shipping_conditions?: string[]; // a list of conditions for shipping
    ship_date?: Date; // optional - the date the shipment left
    tracking_reference?: string; // optional - a reference number for the shipment
    received_date?: Date; // optional - the date the shipment was received
    recipient?: string; // the person who received the shipment
    receipt_conditions?: string[]; // a list of conditions that the shipment was received in
    discrepancies?: string; // notes about any shipping discrepancies
    temperature_tracker?: ObjectReference; // Attachment - a file with temperature tracking information
    signoff?: boolean; // whether the shipment has been ok'd
    signoff_by?: ObjectReference; // Account - the person signing off on the shipment
    signoff_notes?: string; // notes regarding the signoff, next steps

    get completed(): boolean {
        return false;
    }

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(
            ...[
                "inquiry",
                "case",
                "ind",
                "patient",
                "project_code",
                "product_name",
                "lot",
                "lot_expiration",
                "total_quantity",
                "total_unit",
                "dose_form",
                "dose_quantity",
                "dose_unit",
                "address",
                "shipping_conditions",
                "ship_date",
                "received_date",
                "tracking_reference",
                "recipient",
                "receipt_conditions",
                "discrepancies",
                "receipt_conditions",
                "temperature_tracker",
                "signoff",
                "signoff_by",
                "signoff_notes",
                "request_type",
                "quantity_requested",
            ],
        );

        this._readOnly.push(
            "program_name",
            "reference_identifier",
            "case_reference",
            "physician_institution",
            "physician",
            "case_name",
            "date_requested",
        );

        super.initialize(data, patch);
        this.setMember(data, patch, "inquiry", Inquiry);
        this.setMember(data, patch, "data_form", DataForm);
        this.setMember(data, patch, "quantity_requested");
        this.setMember(data, patch, "request_type");
        this.setMember(data, patch, "reference_identifier");
        this.setMember(data, patch, "case_reference");
        this.setMember(data, patch, "program_name");
        this.setMember(data, patch, "physician_institution");
        this.setMember(data, patch, "date_requested", Date);
        this.setMember(data, patch, "date_received", Date);
        this.setMember(data, patch, "case_name");
        this.setMember(data, patch, "physician");
        this.setMember(data, patch, "case", Case);
        this.setMember(data, patch, "ind");
        this.setMember(data, patch, "patient");
        this.setMember(data, patch, "project_code");
        this.setMember(data, patch, "product_name");
        this.setMember(data, patch, "lot");
        this.setMember(data, patch, "lot_expiration", Date);
        this.setMember(data, patch, "total_quantity");
        this.setMember(data, patch, "total_unit");
        this.setMember(data, patch, "dose_form");
        this.setMember(data, patch, "dose_quantity");
        this.setMember(data, patch, "dose_unit");
        this.setMember(data, patch, "address");
        this.setMember(data, patch, "shipping_conditions", undefined, true);
        this.setMember(data, patch, "ship_date", Date);
        this.setMember(data, patch, "tracking_reference");
        this.setMember(data, patch, "received_date", Date);
        this.setMember(data, patch, "recipient");
        this.setMember(data, patch, "receipt_conditions", undefined, true);
        this.setMember(data, patch, "discrepancies");
        this.setMember(data, patch, "temperature_tracker", ObjectReference);
        this.setMember(data, patch, "signoff");
        this.setMember(data, patch, "signoff_by", ObjectReference);
        this.setMember(data, patch, "signoff_notes");
    }
}
