<object-form [controller]="controller" [formGroup]="formGroup" [hideHeader]="false">
    <div class="wrapper flex stack">
        <ng-container *ngIf="mode != ObjectViewMode.View">
            <mat-form-field class="flexible">
                <mat-label>Type</mat-label>
                <mat-select formControlName="_type">
                    <mat-option value="basic" *ngIf="mode != ObjectViewMode.Create">
                        Basic
                    </mat-option>
                    <mat-option value="lookup">Lookup</mat-option>
                    <mat-option value="compound">Compound</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flexible">
                <mat-label>Display Name</mat-label>
                <input #input1 matInput formControlName="display_name" />
                <mat-hint align="end">
                    {{ input1.value.length }} / {{ displayNameMaxLength }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="flexible">
                <mat-label>Description (Optional)</mat-label>
                <input matInput formControlName="description" />
            </mat-form-field>
            <mat-form-field class="flexible">
                <mat-label>Identifier</mat-label>
                <input matInput formControlName="name" />
                <mat-error *ngIf="!!identifierControl?.getError('conflict')">
                    This identifier must be unique among data types within this
                    {{ repositoryType }}.
                </mat-error>
                <mat-icon
                    matSuffix
                    *ngIf="!!willOverride.length"
                    class="amber"
                    matTooltip="This identifier conflicts with other available data types and may override default behavior.  Please ensure that this behavior is intended."
                >
                    warning
                </mat-icon>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="mode == ObjectViewMode.View">
            <div class="row flex flexible">
                <div class="column flex flexible">
                    <div class="info field-header">Type</div>
                    <div *ngIf="type != 'lookup' && type != 'compound'">Basic</div>
                    <div *ngIf="type == 'lookup'">Lookup</div>
                    <div *ngIf="type == 'compound'">Compound</div>
                </div>
            </div>
            <div class="row flex flexible">
                <div class="column flex flexible">
                    <div class="info field-header">Name</div>
                    <div class="flex stack">
                        <div>{{ fullObject?.display_name }}</div>
                        <div class="secondary small">{{ fullObject?.name }}</div>
                    </div>
                </div>
            </div>
            <div class="row flex flexible">
                <div class="column flex flexible">
                    <div class="info field-header">Description</div>
                    <div *ngIf="fullObject?.description">
                        {{ fullObject?.description }}
                    </div>
                    <div *ngIf="!fullObject?.description" class="secondary">
                        Not Specified
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="wrapper flex stack lookup-table" *ngIf="type == 'lookup'">
        <div class="lookup-table-header flex">
            <div class="spacer"></div>
            <div class="header-actions">
                <button
                    mat-flat-button
                    *ngIf="isEditing && !_editLookupValue"
                    type="button"
                    class="brand-medium"
                    (click)="addLookupValue()"
                >
                    <mat-icon>open_in_new</mat-icon>
                    Add Lookup Value
                </button>
            </div>
        </div>
        <mat-table
            #lookupTable
            cdkDropList
            [cdkDropListDisabled]="!isEditing || isLookupSorted || !!_editLookupValue"
            [dataSource]="sortedLookupValues"
            matSort
            (matSortChange)="onLookupSortChange($event)"
            (cdkDropListDropped)="onLookupValueDrop($event)"
        >
            <ng-container matColumnDef="display_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Display Name
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let lookupValue"
                    [class.pointer]="isEditing && !editingLookupValue(lookupValue)"
                    (click)="
                        !editingLookupValue(lookupValue) && editLookupValue(lookupValue)
                    "
                >
                    <div
                        class="flex full center-align"
                        [formGroup]="lookupValueFormGroup"
                    >
                        <mat-icon
                            class="drag-handle"
                            cdkDragHandle
                            *ngIf="isEditing && !isLookupSorted && !_editLookupValue"
                            class="secondary"
                        >
                            drag_indicator
                        </mat-icon>
                        <span *ngIf="!editingLookupValue(lookupValue)">
                            {{ lookupValue.display_name }}
                        </span>
                        <mat-form-field
                            class="full"
                            *ngIf="editingLookupValue(lookupValue)"
                        >
                            <input
                                matInput
                                #input2
                                placeholder="Display Name"
                                formControlName="display_name"
                                [errorStateMatcher]="errorMatcher"
                            />
                            <mat-hint align="end">
                                {{ input2.value.length }} / {{ displayNameMaxLength }}
                            </mat-hint>
                            <mat-error
                                *ngIf="
                                    lookupValueFormGroup
                                        .get('display_name')
                                        ?.hasError('required')
                                "
                            >
                                ⚠ A display name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="value">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Value
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let lookupValue"
                    [class.pointer]="isEditing && !editingLookupValue(lookupValue)"
                    (click)="
                        !editingLookupValue(lookupValue) && editLookupValue(lookupValue)
                    "
                >
                    <div
                        class="flex full center-align"
                        [formGroup]="lookupValueFormGroup"
                    >
                        <span *ngIf="!editingLookupValue(lookupValue)">
                            {{ lookupValue.value }}
                        </span>
                        <mat-form-field
                            class="full"
                            *ngIf="editingLookupValue(lookupValue)"
                        >
                            <input
                                matInput
                                formControlName="value"
                                [errorStateMatcher]="errorMatcher"
                            />
                            <mat-error
                                *ngIf="
                                    lookupValueFormGroup
                                        .get('value')
                                        ?.hasError('required')
                                "
                            >
                                ⚠ A value is required
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    lookupValueFormGroup
                                        .get('value')
                                        ?.hasError('unique')
                                "
                            >
                                ⚠ This value is already used.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell
                    *matHeaderCellDef
                    class="overflow"
                    mat-sort-header
                ></mat-header-cell>
                <mat-cell *matCellDef="let lookupValue" class="overflow">
                    <button
                        mat-icon-button
                        type="button"
                        *ngIf="isEditing && !editingLookupValue(lookupValue)"
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ lookupValue: lookupValue }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        type="button"
                        class="error"
                        *ngIf="
                            editingLookupValue(lookupValue) &&
                            !lookupValueFormGroup?.valid
                        "
                        (click)="cancelEditLookupValue(false)"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        type="button"
                        class="accent"
                        *ngIf="
                            editingLookupValue(lookupValue) &&
                            lookupValueFormGroup?.valid
                        "
                        (click)="cancelEditLookupValue(true)"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="no-records">
                <mat-footer-cell
                    *matFooterCellDef
                    [attr.colspan]="lookupColumns.length"
                    class="error not-found"
                >
                    No lookup values defined.
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="lookupColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: lookupColumns" cdkDrag></mat-row>
            <mat-footer-row
                *matFooterRowDef="['no-records']"
                [class.hidden-footer]="!!lookupValues.length"
            ></mat-footer-row>

            <mat-menu #overflow="matMenu">
                <ng-template matMenuContent let-lookupValue="lookupValue">
                    <button mat-menu-item (click)="editLookupValue(lookupValue)">
                        Edit
                    </button>
                    <button
                        mat-menu-item
                        (click)="removeLookupValue(lookupValue)"
                        [disabled]="!canDelete"
                    >
                        Remove
                    </button>
                    <button
                        mat-menu-item
                        *ngIf="!isLookupSorted"
                        (click)="moveLookupValueToTop($event, lookupValue)"
                    >
                        Move to Top
                    </button>
                    <button
                        mat-menu-item
                        *ngIf="!isLookupSorted"
                        (click)="moveLookupValueToBottom($event, lookupValue)"
                    >
                        Move to Bottom
                    </button>
                </ng-template>
            </mat-menu>
        </mat-table>
    </div>
    <div class="wrapper flex stack lookup-table" *ngIf="type == 'compound'">
        <div class="lookup-table-header flex">
            <div class="spacer"></div>
            <div class="header-actions">
                <button
                    mat-flat-button
                    *ngIf="isEditing && !_editLookupValue"
                    type="button"
                    class="brand-medium"
                    (click)="addCompoundType()"
                >
                    <mat-icon>open_in_new</mat-icon>
                    Add Property
                </button>
            </div>
        </div>
        <mat-table
            #compoundTable
            cdkDropList
            [cdkDropListDisabled]="
                !isEditing || isCompoundSorted || !!_editCompoundType
            "
            [dataSource]="sortedCompoundTypes"
            matSort
            (matSortChange)="onCompoundSortChange($event)"
            (cdkDropListDropped)="onCompoundTypeDrop($event)"
        >
            <ng-container matColumnDef="child">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Property Type
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let compound"
                    [class.pointer]="isEditing && !editingCompoundType(compound)"
                    (click)="
                        !editingCompoundType(compound) && editCompoundType(compound)
                    "
                >
                    <div
                        class="flex full center-align"
                        [formGroup]="compoundTypeFormGroup"
                    >
                        <mat-icon
                            class="drag-handle"
                            cdkDragHandle
                            *ngIf="isEditing && !isCompoundSorted && !_editCompoundType"
                            class="secondary"
                        >
                            drag_indicator
                        </mat-icon>
                        <div *ngIf="!editingCompoundType(compound)">
                            <div>{{ compound.child.display_name }}</div>
                            <div class="secondary small">{{ compound.child.name }}</div>
                        </div>
                        <autocomplete-search
                            class="full"
                            *ngIf="editingCompoundType(compound)"
                            label="Data Type"
                            [formControl]="compoundTypeControl"
                            [options]="availableDataTypesObs"
                            placeholder="Search Data Types"
                            [hint]="{
                                text: '⚠ Required by identifier',
                                class: 'warning',
                                condition: compoundHasHistoricIdentifier(compound)
                            }"
                        ></autocomplete-search>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="display_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Display Name
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let compound"
                    [class.pointer]="isEditing && !editingCompoundType(compound)"
                    (click)="
                        !editingCompoundType(compound) && editCompoundType(compound)
                    "
                >
                    <div
                        class="flex full center-align"
                        [formGroup]="compoundTypeFormGroup"
                    >
                        <span *ngIf="!editingCompoundType(compound)">
                            {{ compound.display_name }}
                        </span>
                        <mat-form-field
                            class="full"
                            *ngIf="editingCompoundType(compound)"
                        >
                            <input
                                matInput
                                #input3
                                placeholder="Display Name"
                                formControlName="display_name"
                                [errorStateMatcher]="errorMatcher"
                            />
                            <mat-hint align="end">
                                {{ input3.value.length }} / {{ displayNameMaxLength }}
                            </mat-hint>
                            <mat-error
                                *ngIf="
                                    compoundTypeFormGroup
                                        .get('display_name')
                                        ?.hasError('required')
                                "
                            >
                                ⚠ A display name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Identifier
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let compound"
                    [class.pointer]="isEditing && !editingCompoundType(compound)"
                    (click)="
                        !editingCompoundType(compound) && editCompoundType(compound)
                    "
                >
                    <div
                        class="flex full center-align"
                        [formGroup]="compoundTypeFormGroup"
                    >
                        <span *ngIf="!editingCompoundType(compound)">
                            {{ compound.name }}
                        </span>
                        <mat-form-field
                            class="full"
                            *ngIf="editingCompoundType(compound)"
                        >
                            <input
                                matInput
                                formControlName="identifier"
                                [errorStateMatcher]="errorMatcher"
                            />
                            <mat-hint
                                class="warning"
                                *ngIf="compoundHasHistoricIdentifier(compound)"
                            >
                                ⚠ Previously used identifier
                            </mat-hint>
                            <mat-error
                                *ngIf="
                                    compoundTypeFormGroup
                                        .get('identifier')
                                        ?.hasError('required')
                                "
                            >
                                ⚠ An identifier is required
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    compoundTypeFormGroup
                                        .get('identifier')
                                        ?.hasError('unique')
                                "
                            >
                                ⚠ This identifier is already used.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="required">
                <mat-header-cell
                    *matHeaderCellDef
                    class="compound-check flex center"
                    mat-sort-header
                >
                    Required
                </mat-header-cell>
                <mat-cell *matCellDef="let compound" class="compound-check flex center">
                    <ng-container [formGroup]="compoundTypeFormGroup">
                        <mat-icon
                            *ngIf="!editingCompoundType(compound) && !compound.required"
                            class="error"
                        >
                            clear
                        </mat-icon>
                        <mat-icon
                            *ngIf="!editingCompoundType(compound) && compound.required"
                            class="accent"
                        >
                            done
                        </mat-icon>
                        <mat-checkbox
                            *ngIf="editingCompoundType(compound)"
                            formControlName="required"
                        ></mat-checkbox>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="multiple">
                <mat-header-cell
                    *matHeaderCellDef
                    class="compound-check flex center"
                    mat-sort-header
                >
                    Multiple
                </mat-header-cell>
                <mat-cell *matCellDef="let compound" class="compound-check flex center">
                    <ng-container [formGroup]="compoundTypeFormGroup">
                        <mat-icon
                            *ngIf="!editingCompoundType(compound) && !compound.multiple"
                            class="error"
                        >
                            clear
                        </mat-icon>
                        <mat-icon
                            *ngIf="!editingCompoundType(compound) && compound.multiple"
                            class="accent"
                        >
                            done
                        </mat-icon>
                        <mat-checkbox
                            *ngIf="editingCompoundType(compound)"
                            formControlName="multiple"
                        ></mat-checkbox>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell
                    *matHeaderCellDef
                    class="overflow"
                    mat-sort-header
                ></mat-header-cell>
                <mat-cell *matCellDef="let compound" class="overflow">
                    <button
                        mat-icon-button
                        type="button"
                        *ngIf="isEditing && !editingCompoundType(compound)"
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ compound: compound }"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        type="button"
                        class="error"
                        *ngIf="
                            editingCompoundType(compound) &&
                            !compoundTypeFormGroup.valid
                        "
                        (click)="cancelEditCompoundType(false)"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        type="button"
                        class="accent"
                        *ngIf="
                            editingCompoundType(compound) && compoundTypeFormGroup.valid
                        "
                        (click)="cancelEditCompoundType(true)"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="no-records">
                <mat-footer-cell
                    *matFooterCellDef
                    [attr.colspan]="compoundColumns.length"
                    class="error not-found"
                >
                    No properties defined.
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="compoundColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: compoundColumns" cdkDrag></mat-row>
            <mat-footer-row
                *matFooterRowDef="['no-records']"
                [class.hidden-footer]="!!compoundTypes.length"
            ></mat-footer-row>

            <mat-menu #overflow="matMenu">
                <ng-template matMenuContent let-compound="compound">
                    <button mat-menu-item (click)="editCompoundType(compound)">
                        Edit
                    </button>
                    <button
                        mat-menu-item
                        (click)="removeCompoundType(compound)"
                        [disabled]="!canDelete"
                    >
                        Remove
                    </button>
                    <button
                        mat-menu-item
                        *ngIf="!isCompoundSorted"
                        (click)="moveCompoundTypeToTop($event, compound)"
                    >
                        Move to Top
                    </button>
                    <button
                        mat-menu-item
                        *ngIf="!isCompoundSorted"
                        (click)="moveCompoundTypeToBottom($event, compound)"
                    >
                        Move to Bottom
                    </button>
                </ng-template>
            </mat-menu>
        </mat-table>
    </div>
</object-form>
