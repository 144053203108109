import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChildren,
    QueryList,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";

export interface FilterDefinition {
    displayName: string; // The name displayed in the UI
    controlName: string; // The name of the control in the FormGroup
    placeholder?: string; // Placeholder text for the select
}

export interface DateFilterConfig {
    betweenDateLabel?: string;
    betweenEndDateControlName: string;
    betweenStartDateControlName: string;
    dateAfterLabel?: string;
    dateAfterControlName: string;
    dateBeforeLabel?: string;
    dateBeforeControlName: string;
}

@Component({
    selector: "data-filter",
    templateUrl: "./data-filter.component.html",
    styleUrls: ["./data-filter.component.scss"],
})
export class DataFilterComponent {
    @Input() filterDefinitions!: FilterDefinition[];
    @Input() filterFormGroup!: FormGroup;
    @Input() filterTitle?: string = "";
    @Input() dateFilterConfigs!: DateFilterConfig[];
    @Input() dynamicOptions!: { [key: string]: any[] };
    @Input() getOptionValue!: (option: any, controlName: string) => any;
    @Input() getOptionDisplay!: (option: any) => string;
    @Input() isOptionDisabled?: (option: any) => boolean;

    @Output() cancelFilter = new EventEmitter<MouseEvent>();
    @Output() clearAllFilters = new EventEmitter<MouseEvent>();
    @Output() resetFilter = new EventEmitter<MouseEvent>();

    @ViewChildren("beforePicker") beforePickers!: QueryList<MatDatepicker<any>>;
    @ViewChildren("afterPicker") afterPickers!: QueryList<MatDatepicker<any>>;

    getOptions(controlName: string): any[] {
        return this.dynamicOptions[controlName] || [];
    }

    onCancel(event: MouseEvent): void {
        this.cancelFilter.emit(event);
    }

    onClearAll(event: MouseEvent): void {
        this.clearAllFilters.emit(event);
    }

    onReset(event: MouseEvent): void {
        this.resetFilter.emit(event);
    }
}
