import { AccountComponent } from "./../account/account.component";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { CapabilityService, RoleService } from "src/services/iam.services";
import {
    APIListResult,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
} from "src/services/models/api-object";
import { SearchableListComponent } from "./../../../common/components/searchable-list.component";
import { TeamMemberService, TeamService } from "./../../../services/program.services";
import { ChangeDetectorRef, Component, Input, inject } from "@angular/core";
import { Organization } from "../../../services/models/organization";
import { Team, TeamMember } from "src/services/models/team";
import { Sort } from "@angular/material/sort";
import { Role } from "src/services/models/role";
import {
    ObjectViewEntryPoint,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Account } from "src/services/models/account";
import { RequestFilter } from "src/common/utilities/request";
import { mergeMap } from "rxjs";
import { MatDialogConfig } from "@angular/material/dialog";
import { Capability } from "src/services/models/capability";

type OptionalOrganizationOrReference = Organization | ObjectReference | undefined;
@Component({
    selector: "organization-staff",
    templateUrl: "./staff.component.html",
    styleUrls: ["./organization.component.scss"],
})
export class OrganizationStaffComponent extends SearchableListComponent<TeamMember> {
    protected teamService: TeamService;
    protected roleService: RoleService;
    protected capabilityService: CapabilityService;

    get displayedColumns(): string[] {
        return ["account-name", "permission", "email", "actions"];
    }
    @Input()
    isHcpStaff: boolean | undefined = false;

    defaultCapabilites: Capability[] = [];
    customCapabilities: Capability[] = [];
    @Input() set team(v: Team | undefined) {
        if (v !== this.team_) {
            this.team_ = v;
            if (this.team_) this.organization_ = this.team_.organization;
            this.updateList();
        }
    }
    get team(): Team | undefined {
        return this.team_;
    }
    protected team_: Team | undefined;

    @Input() set organization(v: OptionalOrganizationOrReference) {
        if (v !== this.organization_) {
            this.organization_ = v;
            this.getOrganizationStaffTeam();
            this.getCustomCapabilities();
        }
    }
    getCustomCapabilities() {
        this.capabilityService
            .list({ org: this?.organization?.id ?? "-1" })
            .subscribe((response) => {
                this.customCapabilities = response as Capability[];
            });
    }
    displayNameForRole(role: string) {
        return Role.roles.find((r) => r.value === role)?.display;
    }
    get organization(): OptionalOrganizationOrReference {
        return this.organization_;
    }
    protected organization_!: OptionalOrganizationOrReference;
    constructor(
        protected service: TeamMemberService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "staff-list");
        this.teamService = inject(TeamService);
        this.roleService = inject(RoleService);
        this.capabilityService = inject(CapabilityService);
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.getCapabilities();
    }
    onSortChange(event: Sort): void {
        if (event.direction) {
            let field = event.active;
            if (event.active === "account-name") field = "account__first_name";
            else if (event.active === "permission") field = "permission__role";
            else if (event.active === "email") field = "account__email";

            this.list.ordering = [
                { field: field, ascending: event.direction == "asc" },
            ];

            if (event.active == "account-name") {
                this.list.ordering.push({
                    field: "account__last_name",
                    ascending: event.direction == "asc",
                });
            }
        } else this.list.ordering = [];
    }

    isTeamMember(object: ObjectOrReference<TeamMember>): object is TeamMember {
        return (object as TeamMember).email !== undefined;
    }

    editObject(
        event: MouseEvent | undefined,
        object?: TeamMember,
        asDialog: boolean = false,
        viewOnly: boolean = false,
    ): ObjectViewEntryPoint<TeamMember> | undefined {
        let mode = viewOnly ? ObjectViewMode.View : ObjectViewMode.Create;
        if (object?.id) mode = ObjectViewMode.Edit;
        const instance = ObjectAdminComponent.showObject<Account>(
            object?.account,
            AccountComponent,
            mode,
            asDialog ? this.objectDialogConfiguration(object, mode) : undefined,
        );
        if (asDialog && instance?.dialogReference)
            instance.dialogReference
                .afterClosed()
                .subscribe(() => this.list.refresh(true));

        (instance as AccountComponent).teamMember = object;
        (instance as AccountComponent).organization = this.organization;
        (instance as AccountComponent).teamMembers = this.list.items as TeamMember[];
        (instance as AccountComponent).capabilities = [
            ...this.defaultCapabilites,
            ...this.customCapabilities,
        ];

        return instance as ObjectViewEntryPoint<TeamMember> | undefined;
    }
    createObject(
        event?: MouseEvent,
        asDialog: boolean = true,
    ): ObjectViewEntryPoint<TeamMember> | undefined {
        const newObject = ObjectFactory.makeObject<TeamMember>(
            {
                team: this.team?.asReference,
                role: "member",
                permission: ObjectFactory.makeObject<Role>(
                    {
                        object: this.team?.organization,
                        role: "object.view",
                    },
                    Role.object_type,
                ) as Role,
                account: ObjectFactory.makeObject<Account>(
                    {},
                    Account.object_type,
                ) as Account,
                private: true,
            },
            TeamMember.object_type,
        ) as TeamMember;
        return this.editObject(event, newObject, asDialog);
    }
    deleteStaff(event: MouseEvent, member: TeamMember): void {
        this.terminateEvent(event);
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: `Are you sure you want to remove ${member.account.displayName}'?`,
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.service.destroy(member).subscribe(() => this.updateList());
                }
            });
    }

    canEditStaff(account: Account): boolean {
        return true;
    }

    protected objectDialogConfiguration(
        object: TeamMember | undefined,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            minWidth: "75vw",
        };
    }

    get canMigrate(): boolean {
        return !this.team && !!this.organization;
    }
    migrateStaffTeam(event: MouseEvent): void {
        // get all roles for the organization
        this.roleService
            .list({ role_object: this.organization!.id! })
            .subscribe((roles_: APIListResult<Role>) => {
                const members = (roles_ as Role[]).map((r: Role) => {
                    let role = "member";
                    if (
                        r.role.includes("organization.administrator") ||
                        r.role.includes("object.admin")
                    )
                        role = "admin";
                    else if (r.role.includes("provider.physician")) role = "physician";
                    return ObjectFactory.makeObject<TeamMember>(
                        {
                            account: r.account?.asReference,
                            role: role,
                            permission: r,
                            private: r.private,
                        },
                        TeamMember.object_type,
                    );
                });
                // create the new team
                const team = ObjectFactory.makeObject<Team>(
                    {
                        organization: this.organization!.asReference,
                        team_type: "staff",
                        members: members,
                    },
                    Team.object_type,
                );
                const obs = this.teamService.create(team);
                obs.subscribe((team: Team | undefined) => {
                    this.team = team;
                });
            });
    }

    protected getOrganizationStaffTeam(): void {
        if (this.organization) {
            this.teamService
                .list({ organization: this.organization?.id ?? "0", type: "staff" })
                .pipe(
                    mergeMap((teams: APIListResult<Team>) => {
                        let team = undefined;
                        if (teams instanceof Team) team = teams;
                        else if (teams instanceof ObjectReference) team = teams;
                        else if (Array.isArray(teams)) {
                            if (teams.length) team = teams[0];
                        } else if (teams.items.length) {
                            team = teams.items[0];
                        }
                        return ObjectFactory.objectObservable(team);
                    }),
                )
                .subscribe((team: Team | undefined) => {
                    this.team = team;
                });
        }
    }

    protected getCapabilities(): void {
        if (!this.organization) return;
        this.capabilityService
            .list({ org: "0", capacity: !this.isHcpStaff ? "pharma" : "physician" })
            .subscribe((capabilities: APIListResult<Capability>) => {
                this.defaultCapabilites = capabilities as Capability[];
            });
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["team"] = this.team ? this.team.id! : "0";
        return filters;
    }
}
