import { Component } from "@angular/core";
import { ObjectComponent } from "../object.component";
import { Template } from "../../../services/models/template";
import { TemplateService } from "../../../services/notification.services";
import {
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { map } from "rxjs/operators";
import { notOnlyWhitespace } from "src/common/utilities/validators";

type fieldMap = { name: string; varName: string };

@Component({
    selector: "email-template",
    templateUrl: "./template.component.html",
    styleUrls: ["./template.component.scss"],
})
export class TemplateComponent extends ObjectComponent<Template> {
    static TEMPLATE_VARAIBLE_REGEX = /(\{\{[^}\n]+}})/g;
    templateTooltip =
        "{{ inquiry.sender }} - Inquiry Sender Address\n{{ physician.name.first }} - Requesting Physician First Name\n{{ physician.name.last }} - Requesting Physician Last Name\n";

    showDefaultValues: boolean = false;
    templateFieldNames: string[] = [];
    objectName = "Template";

    constructor(protected service: TemplateService) {
        super(service);
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            owner: [null],
            name: [null, [Validators.required, notOnlyWhitespace]],
            subject: [null, [Validators.required, notOnlyWhitespace]],
            html: [null, [Validators.required, notOnlyWhitespace]],
            default_context: this.formBuilder.array([]),
        });
    }

    get templateControl(): UntypedFormControl {
        return this.asFormControl(this.formGroup.controls.html);
    }
    get subjectControl(): UntypedFormControl {
        return this.asFormControl(this.formGroup.controls.subject);
    }
    get defaultsArray(): UntypedFormArray {
        return this.asFormArray(this.formGroup.controls.default_context);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.templateControl.valueChanges
            .pipe(
                map(
                    (template: string) =>
                        (this.subjectControl.value || "") + "\n\n" + template,
                ),
            )
            .subscribe((text: string) => this.updateTemplateVariables(text));
        this.subjectControl.valueChanges
            .pipe(
                map((subject: string) => subject + "\n\n" + this.templateControl.value),
            )
            .subscribe((text: string) => this.updateTemplateVariables(text));
    }

    toggleDefaultValues(event: MouseEvent): void {
        this.terminateEvent(event);
        setTimeout(() => (this.showDefaultValues = !this.showDefaultValues));
    }
    updateTemplateVariables(text: string, context?: any): void {
        const vars = RegExp(TemplateComponent.TEMPLATE_VARAIBLE_REGEX)
            .exec(text)
            ?.map((varName: string) =>
                varName
                    .replace("{{", "")
                    .replace("}}", "")
                    .trim()
                    .replace(/\.+$/, "")
                    .replace(/\.+/g, " ")
                    .replace(/ +/g, ".")
                    .toLowerCase(),
            );
        const removeIndexes = this.templateFieldNames
            .filter(
                (name: string) => !vars?.find((varName: string) => varName === name),
            )
            .map((name: string) => this.templateFieldNames.indexOf(name))
            .sort((a: number, b: number) => a - b)
            .reverse();
        removeIndexes.forEach((index: number) => this.defaultsArray.removeAt(index));
        this.templateFieldNames = this.templateFieldNames.filter((name: string) =>
            vars?.find((varName: string) => varName === name),
        );
        vars
            ?.filter(
                (varName: string) =>
                    !this.templateFieldNames.find(
                        (fieldName: string) => fieldName === varName,
                    ),
            )
            .forEach((varName: string) => {
                this.templateFieldNames.push(varName);
                this.defaultsArray.push(this.formBuilder.control(null));
            });
        vars?.forEach((varName: string) => {
            const index = this.templateFieldNames.indexOf(varName);
            const control = this.defaultsArray.at(index);
            let value = control.value;
            if (!value && context?.hasOwnProperty(varName)) value = context[varName];
            control.setValue(value);
        });
    }
    replaceVarNames(text: string): string {
        const modified = RegExp(TemplateComponent.TEMPLATE_VARAIBLE_REGEX)
            .exec(text)
            ?.map((field: string) => {
                return {
                    name: field,
                    varName: field
                        .replace("{{", "")
                        .replace("}}", "")
                        .trim()
                        .replace(/\.+$/, "")
                        .replace(/\.+/g, " ")
                        .replace(/ +/g, ".")
                        .toLowerCase(),
                };
            })
            ?.reduce(
                (text: string, map: fieldMap) =>
                    text.replace(
                        new RegExp(map.name, "g"),
                        "{{ " + map.varName + " }}",
                    ),
                text,
            );
        return modified ?? text;
    }

    protected setObject(v?: Template): void {
        super.setObject(v);
        this.updateTemplateVariables(v?.subject + "\n\n" + v?.text, v?.default_context);
    }

    protected precommitTransform(v: any): any {
        let context: any = {};
        this.templateFieldNames.forEach((fieldName: string, index: number) => {
            if (this.defaultsArray.controls[index].value)
                context[fieldName] = this.defaultsArray.controls[index].value;
        });
        v.default_context = context;
        v.html = this.replaceVarNames(v.html);
        v.subject = this.replaceVarNames(v.subject);
        v.text = null;
        return super.precommitTransform(v);
    }
    protected onCommitSuccess(v: Template): boolean {
        const result = super.onCommitSuccess(v);
        this.snackbar.open("Template changes successfully saved.", undefined, {
            duration: 2000,
        });
        return result;
    }
    handleKeyDown(event: KeyboardEvent): void {
        // override to allow enter character
    }
}
