import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Component, Inject } from "@angular/core";
import { DataForm } from "src/services/models/data";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    template: `
        <h3 mat-dialog-title>Add Form</h3>
        <mat-dialog-content class="flex stack" [formGroup]="formGroup">
            <mat-form-field class="full">
                <mat-select
                    formControlName="form"
                    placeholder="Select a form to create a new instance of"
                >
                    <mat-option *ngFor="let form of availableForms" [value]="form">
                        {{ form.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox *ngIf="canShare" formControlName="shared">
                Share this form
            </mat-checkbox>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="spacer"></div>
            <button
                mat-flat-button
                type="button"
                class="fixed"
                [mat-dialog-close]="undefined"
            >
                Cancel
            </button>
            <button
                mat-flat-button
                type="button"
                class="fixed"
                color="accent"
                [disabled]="!formGroup.valid"
                [mat-dialog-close]="formGroup.value"
            >
                Create
            </button>
        </mat-dialog-actions>
    `,
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormSelectComponent {
    formGroup: FormGroup;
    availableForms: DataForm[] = [];
    canShare: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { forms: DataForm[]; canShare: boolean },
        protected fb: FormBuilder,
        protected dialogReference: MatDialogRef<DataFormSelectComponent>,
    ) {
        this.availableForms = data.forms;
        this.canShare = data.canShare;
        this.formGroup = this.fb.group({
            form: [null, Validators.required],
            shared: [true],
        });
    }
}
