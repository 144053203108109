import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./routes/home/home.component";
import { authGuard } from "../iam/guards/auth.guard";
import { HealthCheckComponent } from "../common/components/health-check.component";
import { DocusignAuthorizationComponent } from "../integration/docusign/authorization.component";
import { IntakeComponent } from "../intake/components/intake/intake.component";
import { AuthenticationRedirectGuard } from "src/iam/guards/redirect.guard";
import { AuthorizationComponent } from "src/iam/components/authorization.component";
import { LoginComponent } from "src/iam/components/login.component";
import { DocusignEnvelopeStatusComponent } from "src/integration/docusign/envelope-status.component";

const routes: Routes = [
    {
        path: "iam",
        loadChildren: () => import("../iam/iam.module").then((m) => m.IAMModule),
    },

    {
        path: "reset/:resetId",
        component: AuthenticationRedirectGuard,
        canActivate: [AuthenticationRedirectGuard],
    },
    {
        path: "activate/:activate_id",
        component: AuthenticationRedirectGuard,
        canActivate: [AuthenticationRedirectGuard],
    },
    {
        path: "update_email/:update_id",
        component: AuthenticationRedirectGuard,
        canActivate: [AuthenticationRedirectGuard],
    },
    { path: "login-auth", component: AuthorizationComponent },
    { path: "login", component: LoginComponent },

    { path: "intake/:organizationSlug", component: IntakeComponent },
    { path: "intake/:organizationSlug/:programSlug", component: IntakeComponent },

    { path: "docusign/authorization", component: DocusignAuthorizationComponent },
    {
        path: "docusign/envelope/:envelopeId/status",
        component: DocusignEnvelopeStatusComponent,
    },

    { path: "health-check", component: HealthCheckComponent },

    { path: "", component: HomeComponent, canActivate: [authGuard] },
    { path: "**", redirectTo: "/", pathMatch: "prefix" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutes {}
