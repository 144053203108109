import { debounceTime, filter, mergeMap, switchMap } from "rxjs/operators";
import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import { CaseService } from "src/services/program.services";
import {
    ProgramService,
    TeamService,
    WorkflowService,
    InquiryService,
} from "./../../../services/program.services";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SessionComponent } from "src/services/components/session.component";
import { Component, Inject, ViewChild, inject } from "@angular/core";
import {
    APIListResult,
    APIObject,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    ProgramReference,
} from "src/services/models/api-object";
import { Organization } from "src/services/models/organization";
import { Program } from "src/services/models/program";
import { map, of } from "rxjs";
import { DataForm } from "src/services/models/data";
import { ObjectViewMode } from "src/common/components/object.component";
import { Team, TeamMember, TeamMemberFactory } from "src/services/models/team";
import { Workflow } from "src/services/models/workflow";
import { HttpEvent } from "@angular/common/http";
import { Inquiry } from "src/services/models/inquiry";
import { Case } from "src/services/models/case";
import { CompoundDataTypeFactory, DataFormService } from "src/services/data.services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";

export type CreateRequestDialogData = {
    organizations?: ObjectReference[];
};

@Component({
    selector: "create-request",
    templateUrl: "./create-request.component.html",
    styleUrls: ["./create-request.component.scss"],
})
export class CreateRequestDialog extends SessionComponent {
    organizations: (ObjectReference | Organization)[] = [];
    programs: ObjectOrReference<Program>[] = [];
    formGroup: FormGroup;
    programService: ProgramService;
    dataFormService: DataFormService;
    teamService: TeamService;
    workflowService: WorkflowService;
    caseService: CaseService;
    inquiryService: InquiryService;
    intakeForm?: DataForm;
    staffTeam?: Team;
    workflows: Workflow[] = [];
    loading = false;

    @ViewChild(DataFormComponent) intakeFormComponent?: DataFormComponent;

    ObjectViewMode = ObjectViewMode;

    get createAsCase(): boolean {
        return !!this.formGroup.get("createAsCase")?.value;
    }
    get title(): string {
        return this.createAsCase ? "Create Case" : "Create Inquiry";
    }
    get primary(): TeamMember | undefined {
        return this.formGroup.get("primary")?.value;
    }
    get organization(): ObjectReference | Organization | undefined {
        return this.formGroup.get("organization")?.value;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: CreateRequestDialogData,
        protected formBuilder: FormBuilder,
        protected dialogRef: MatDialogRef<CreateRequestDialog>,
    ) {
        super();
        this.programService = inject(ProgramService);
        this.dataFormService = inject(DataFormService);
        this.teamService = inject(TeamService);
        this.workflowService = inject(WorkflowService);
        this.caseService = inject(CaseService);
        this.inquiryService = inject(InquiryService);
        inject(CompoundDataTypeFactory);
        inject(TeamMemberFactory);

        this.organizations = data.organizations ?? [];
        this.formGroup = this.formBuilder.group({
            organization: [null, Validators.required],
            createAsCase: [null],
            name: [null],
            workflow: [null],
            primary: [null],
            secondary: [null],
        });
        this.formGroup
            .get("organization")
            ?.valueChanges.subscribe((org: ObjectReference | Organization) => {
                this.updatePrograms(org);
                this.updateOrganizationStaff(org);
                this.updateWorkflows(org);
            });
        this.formGroup
            .get("name")
            ?.valueChanges.pipe(
                debounceTime(400),
                filter((name: string) => !!name),
                switchMap((name: string) =>
                    name ?
                        this.caseService.check_case_name_validity({
                            name: name,
                            organization: this.organization?.id,
                        })
                    :   of(undefined),
                ),
            )
            .subscribe((result?: HttpEvent<{ count: number }>) => {
                const { count } = result as any;
                if (count) this.formGroup.get("name")?.setErrors({ uniqueName: true });
                else this.formGroup.get("name")?.setErrors(null);
            });
        this.formGroup
            .get("createAsCase")
            ?.valueChanges.subscribe((isCase: boolean) => {
                if (isCase) {
                    this.formGroup.get("name")?.setValidators(Validators.required);
                    this.formGroup.get("workflow")?.setValidators(Validators.required);
                    this.formGroup.get("primary")?.setValidators(Validators.required);
                } else {
                    this.formGroup.get("name")?.setValidators(null);
                    this.formGroup.get("workflow")?.setValidators(null);
                    this.formGroup.get("primary")?.setValidators(null);
                }
            });
        this.updateIntakeForm();
    }
    orgChange(e: MatSelectChange) {
        const org = e.value;
        this.updatePrograms(org);
        this.updateOrganizationStaff(org);
        this.updateWorkflows(org);

        this.formGroup.controls["organization"].setValue(org);
    }
    get intakeFormGroup() {
        return this.intakeFormComponent?.formGroup;
    }

    ngAfterViewInit(): void {
        this.formGroup.setValue({
            createAsCase: false,
            organization: this.organizations.length ? this.organizations[0] : null,
            name: null,
            workflow: null,
            primary: null,
            secondary: null,
        });
    }
    create(event?: MouseEvent): void {
        const organization = this.formGroup.value["organization"];

        let intakeFormData: { [key: string]: any } = {};

        intakeFormData = this.intakeFormComponent?.formGroup.value || {};
        for (let key in intakeFormData) {
            if (intakeFormData.hasOwnProperty(key)) {
                const value = intakeFormData[key];
                if (value instanceof APIObject)
                    intakeFormData[key] = value.type + ":" + value.id;
            }
        }

        const inquiry_data = {
            "request.source": "in-app",
            "request.role": "internal",
            organization: organization.asReference.serialize(),
            intakeForm: this.intakeForm?.asReference.serialize(),
            data: intakeFormData,
        };
        this.loading = true;
        this.inquiryService
            .intake(inquiry_data)
            .pipe(
                map((result: any) => ObjectFactory.makeObject<Inquiry>(result)),
                mergeMap((o: ObjectOrReference<Inquiry>) =>
                    ObjectFactory.objectObservable(o),
                ),
                mergeMap((inquiry: Inquiry | undefined) => {
                    if (inquiry && this.createAsCase) {
                        const new_case = ObjectFactory.makeObject<Case>(
                            {
                                owner: organization.asReference.serialize(),
                                shared: inquiry,
                                name: this.formGroup.value["name"],
                                workflow:
                                    this.formGroup.value[
                                        "workflow"
                                    ].asReference.serialize(),
                                primary:
                                    this.formGroup.value[
                                        "primary"
                                    ].asReference.serialize(),
                                secondary:
                                    this.formGroup.value["secondary"]?.map(
                                        (tm: TeamMember) => tm.asReference.serialize(),
                                    ) || [],
                            },
                            Case.object_type,
                        );
                        return this.caseService.create(new_case);
                    }
                    return of(inquiry);
                }),
            )
            .subscribe({
                next: (c: Case | Inquiry | undefined) => {
                    this.dialogRef.close(c);
                    this.loading = false;
                },
                error: (err: any) => {
                    this.loading = false;
                    this.dialogRef.close();
                    console.error(err);
                    const message =
                        "Unable to create " +
                        (this.createAsCase ? "case" : "inquiry") +
                        ". Please contact the system administrator.";
                    this.snackbar.open(message, undefined, { duration: 2000 });
                },
            });
    }
    hasError(field: string, error: string): boolean {
        return this.formGroup.get(field)?.hasError(error) ?? false;
    }

    protected updatePrograms(org?: Organization | ObjectReference): void {
        if (org?.id && this.currentAccount?.id) {
            this.programService
                .list({
                    organization: org.id,
                    admin: this.currentAccount.id,
                    use_reference: "True",
                })
                .pipe(
                    map(
                        (results: APIListResult<ProgramReference>) =>
                            results as ProgramReference[],
                    ),
                )
                .subscribe((programs: ProgramReference[]) => {
                    this.programs = programs;
                });
        } else {
            this.programs = [];
        }
    }
    protected updateIntakeForm(): void {
        this.dataFormService
            .list({ name: "intake.internal", is_template: "True" })
            .subscribe((forms: APIListResult<DataForm>) => {
                if (forms && (forms as DataForm[]).length) {
                    this.intakeForm = (forms as DataForm[])[0];
                } else {
                    this.intakeForm = undefined;
                }
            });
    }
    protected updateOrganizationStaff(org?: Organization | ObjectReference): void {
        this.teamService
            .list({ organization: org?.id ?? "0", type: "staff" })
            .subscribe((teams_: APIListResult<Team>) => {
                const teams = teams_ as Team[];
                this.staffTeam = teams.length ? teams[0] : undefined;
                this.updatePrimary();
            });
    }
    protected updatePrimary(): void {
        const owner = this.currentAccount;
        const primary = this.staffTeam?.members?.find(
            (tm: TeamMember) => tm.account.id == owner?.id,
        );
        this.formGroup.controls.primary.setValue(primary);
    }
    protected updateWorkflows(org?: Organization | ObjectReference): void {
        this.workflowService
            .list({ owned: org?.id ?? "0" })
            .subscribe((workflows: APIListResult<Workflow>) => {
                this.workflows = workflows as Workflow[];
                if (this.workflows.length === 1) {
                    this.formGroup.get("workflow")?.setValue(this.workflows[0].id);
                }
            });
    }
    updateValidity() {
        if (this.createAsCase) {
            this.formGroup.controls.name.enable();
            this.formGroup.controls.workflow.enable();
            this.formGroup.controls.primary.enable();
        } else {
            this.formGroup.controls.name.disable();
            this.formGroup.controls.workflow.disable();
            this.formGroup.controls.primary.disable();
        }
    }
}
