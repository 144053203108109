<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div class="wrapper flex stack flexible" *ngIf="mode == ObjectViewMode.Create">
        <mat-form-field class="full">
            <mat-label>Organization Name</mat-label>
            <mat-icon matSuffix>business</mat-icon>
            <input matInput placeholder="Organization Name" formControlName="name" />
        </mat-form-field>
    </div>
    <div class="wrapper flex stack flexible" *ngIf="mode != ObjectViewMode.Create">
        <div
            [class.mat-dialog-content]="!!dialogReference"
            [class.remove-styling]="!!dialogReference"
        >
            <mat-tab-group
                color="primary"
                class="margin-top"
                [class.nested]="!!dialogReference"
            >
                <mat-tab
                    label="Staff"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template matTabContent>
                        <div class="content-card">
                            <organization-staff
                                *ngIf="fullObject"
                                [organization]="fullObject"
                                [isHcpStaff]="isHcpStaff"
                            ></organization-staff>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Programs"
                    *ngIf="
                        (isOrganizationAdministrator || isSystemAdministrator) &&
                        !isHcpStaff
                    "
                >
                    <ng-template matTabContent>
                        <div class="content-card">
                            <program-list
                                *ngIf="fullObject"
                                [organization]="fullObject"
                            ></program-list>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Tools"
                    *ngIf="
                        (isOrganizationAdministrator || isSystemAdministrator) &&
                        !isHcpStaff
                    "
                >
                    <ng-template matTabContent>
                        <div class="content-card">
                            <div class="title">Data Export</div>
                            <div class="container">
                                <div class="row flex full">
                                    <button
                                        mat-flat-button
                                        color="accent"
                                        class="brand-medium"
                                        (click)="exportCaseData($event)"
                                    >
                                        Export Case Data
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="content-card" *ngIf="redactionEntitled">
                            <div class="title">Redaction</div>
                            <div class="container flex stack">
                                <mat-checkbox
                                    class="redaction-option"
                                    name="disableRedaction"
                                    ngDefaultControl
                                    [formControl]="disableRedaction"
                                    matTooltip="When checked, documents uploaded to this organization will not automatically be analyzed for PHI and no redaction hints will be presented"
                                >
                                    Temporarily disable AI-assisted Redaction
                                </mat-checkbox>
                                <mat-checkbox
                                    class="redaction-option"
                                    name="hideExternalRedaction"
                                    ngDefaultControl
                                    [formControl]="hideExternalRedaction"
                                >
                                    Hide redaction warnings from external users
                                </mat-checkbox>
                                <mat-checkbox
                                    class="redaction-option"
                                    name="hideInternalRedaction"
                                    ngDefaultControl
                                    [formControl]="hideInternalRedaction"
                                >
                                    Hide redaction warnings from internal users
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Documents"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template mat-tab-label>
                        <mat-icon
                            *ngIf="!!tabErrors['Documents']"
                            class="fixed"
                            [ngClass]="tabErrors['Documents'].severity"
                            [matTooltip]="tabErrors['Documents'].message"
                        >
                            warning
                        </mat-icon>
                        Documents
                    </ng-template>

                    <div class="content-card">
                        <document-repository
                            [repository]="object"
                            [owner]="object"
                            [canAdd]="true"
                            (setCaseError)="setTabError('Documents', $event)"
                            [redactionEnabled]="
                                fullObject?.isEntitlementEnabled('redaction') || false
                            "
                        ></document-repository>
                    </div>
                </mat-tab>
                <mat-tab
                    label="Messaging"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template matTabContent>
                        <div class="content-card" *ngIf="!!fullObject">
                            <template-list
                                [owner]="fullObject"
                                [inCard]="true"
                            ></template-list>
                        </div>
                        <div class="content-card" *ngIf="!!fullObject && !isHcpStaff">
                            <div class="container">
                                <div class="title">Branding</div>
                                <div
                                    class="settings-section-content flex stack flexible row"
                                >
                                    <object-form
                                        [hideHeader]="!!dialogReference"
                                        [hideControls]="!!dialogReference"
                                        [controller]="controller"
                                        [formGroup]="brandingFormGroup"
                                    >
                                        <div
                                            class="flex flexible template-logo"
                                            *ngIf="
                                                !!fullObject?.settings?.template_logo
                                            "
                                        >
                                            <div class="flexible">
                                                <img
                                                    [src]="templateLogoSource"
                                                    class="template-logo-image"
                                                    alt="Organization Logo"
                                                />
                                            </div>
                                            <div class="fixed">
                                                <button
                                                    mat-icon-button
                                                    (click)="removeLogo($event)"
                                                    matTooltip="Remove Logo"
                                                >
                                                    <mat-icon class="red">
                                                        delete
                                                    </mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            class="template-logo uploader"
                                            *ngIf="
                                                !fullObject?.settings?.template_logo &&
                                                uploader
                                            "
                                        >
                                            <div
                                                ng2FileDrop
                                                class="drop-zone"
                                                [class.over]="fileOver"
                                                (fileOver)="fileOver = $event"
                                                [uploader]="uploader"
                                            >
                                                <input
                                                    type="file"
                                                    ng2FileSelect
                                                    id="file-select"
                                                    #fileSelect
                                                    [uploader]="uploader"
                                                    hidden
                                                />
                                                Drop an image file
                                                <strong>here</strong>
                                                or
                                                <button
                                                    mat-raised-button
                                                    (click)="fileSelect.click()"
                                                >
                                                    select
                                                </button>
                                                to set a logo.
                                            </div>
                                        </div>
                                        <div class="contact-information">
                                            Contact Information
                                        </div>
                                        <div class="row flex">
                                            <mat-form-field class="full">
                                                <mat-label>
                                                    Address (Optional)
                                                </mat-label>
                                                <input
                                                    matInput
                                                    placeholder="Contact Address (Optional)"
                                                    formControlName="contact_address"
                                                />
                                            </mat-form-field>
                                        </div>
                                        <div class="row flex">
                                            <mat-form-field class="column flexible">
                                                <mat-label>Phone (Optional)</mat-label>
                                                <input
                                                    matInput
                                                    placeholder="Contact Phone (Optional)"
                                                    formControlName="contact_phone"
                                                />
                                            </mat-form-field>
                                            <mat-form-field class="column flexible">
                                                <mat-label>Email (Optional)</mat-label>
                                                <input
                                                    matInput
                                                    placeholder="Contact Email (Optional)"
                                                    formControlName="contact_email"
                                                />
                                            </mat-form-field>
                                        </div>
                                    </object-form>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Properties"
                    *ngIf="isOrganizationAdministrator || isSystemAdministrator"
                >
                    <ng-template matTabContent>
                        <div class="content-card" *ngIf="object">
                            <organization-properties
                                [isHcpStaff]="!!isHcpStaff"
                                [object]="object"
                                [autosave]="autosave"
                                [mode]="mode"
                            ></organization-properties>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab
                    label="Data"
                    *ngIf="
                        (isOrganizationAdministrator || isSystemAdministrator) &&
                        !isHcpStaff &&
                        canUseFormBuilder
                    "
                >
                    <ng-template matTabContent>
                        <div class="content-card no-padding">
                            <data-admin [repository]="this.fullObject!"></data-admin>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Entitlements" *ngIf="isSystemAdministrator">
                    <ng-template matTabContent>
                        <div class="content-card">
                            <div class="container">
                                <div class="title">Entitlements / Restrictions</div>
                                <div class="container flex stack">
                                    <mat-checkbox
                                        name="enableRedaction"
                                        ngDefaultControl
                                        [formControl]="enableRedaction"
                                    >
                                        Enable Redaction
                                    </mat-checkbox>
                                    <mat-checkbox
                                        name="enableRedaction"
                                        ngDefaultControl
                                        [formControl]="enableFormBuilder"
                                    >
                                        Enable Form Builder
                                    </mat-checkbox>
                                    <mat-checkbox
                                        name="inquiryOnly"
                                        ngDefaultControl
                                        [formControl]="inquiryOnly"
                                    >
                                        Allow Inquiries Only
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</object-form>
