<div *ngIf="!list.hasLoaded" class="spinner-container">
    <mat-progress-spinner
        diameter="40"
        class="inline"
        mode="indeterminate"
    ></mat-progress-spinner>
    <p>Loading Discussion...</p>
</div>

<div class="flex stack discussion-container" *ngIf="list.hasLoaded">
    <div #messageList class="message-list flexible flex" (scroll)="onScroll($event)">
        <div
            #messages
            class="flex full message-line"
            *ngFor="let entry of discussionEntries"
            [attr.data-id]="entry?.id"
        >
            <div class="message-spacer flexible" *ngIf="isSender(entry)"></div>
            <div class="message flexible flex stack" [class.sender]="isSender(entry)">
                <div class="message-header flex stack full">
                    <div class="small">{{ entry.account.displayName }}</div>
                    <div class="super-small secondary">
                        {{ entry.timestamp | localizedDate: "medium" }}
                    </div>
                </div>
                <quill-view-html
                    class="message-text full"
                    [content]="entry.message"
                ></quill-view-html>
            </div>
            <div class="message-spacer flexible" *ngIf="!isSender(entry)"></div>
        </div>
        <div class="flex full discussion-start" #start>
            <div class="flexible no-messages bold info">
                This is the start of the discussion
            </div>
        </div>
    </div>
    <div class="flex message-entry content-middle" *ngIf="canSendMessage">
        <div class="flexible flex">
            <rich-text-editor
                class="flexible"
                [disabled]="viewOnly || loading"
                matInput
                [bindings]="bindings"
                [customToolbar]="customToolbar"
                [staffAccounts]="staffWithCaseAccess"
                [enableMentions]="true"
                [formControl]="messageText"
                placeholder="Add to the discussion"
                [currentAccountId]="currentAccount!.id"
            ></rich-text-editor>
        </div>
        <div class="fixed send-button flex">
            <mat-spinner
                *ngIf="loading"
                mode="indeterminate"
                [diameter]="50"
            ></mat-spinner>
            <button
                *ngIf="!loading"
                type="button"
                mat-icon-button
                (click)="sendMessage()"
                [disabled]="!messageText.value || !messageText.value.length || loading"
                matTooltip="Post Message"
            >
                <mat-icon>add_comment</mat-icon>
            </button>
        </div>
    </div>
</div>
