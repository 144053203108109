<object-form
    class="wrapper"
    [class.absolute]="!dialogReference"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <mat-form-field appearance="outline" class="full input">
        <mat-label>Account</mat-label>
        <mat-select formControlName="account">
            <mat-select-trigger>
                <div>{{ formGroup.controls.account.value?.displayName }}</div>
                <div class="small">{{ formGroup.controls.account.value?.email }}</div>
            </mat-select-trigger>
            <mat-option *ngFor="let account of availableAccounts" [value]="account">
                {{ account.displayName }}
                <span class="small option-spacer">{{ account.email }}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full input">
        <mat-label>Object Type</mat-label>
        <mat-select [formControl]="objectType">
            <mat-option *ngFor="let type of availableObjectTypes" [value]="type">
                {{ type.displayName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full input">
        <mat-label>Object</mat-label>
        <mat-select formControlName="object">
            <mat-option *ngFor="let object of availableObjects" [value]="object">
                {{ object.displayName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full input">
        <mat-label>Permissions</mat-label>
        <mat-select formControlName="roles">
            <mat-option *ngFor="let role of availableRoles" [value]="role">
                {{ role.display }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</object-form>
