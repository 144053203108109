<object-form [controller]="controller" [hideControls]="true">
    <h3 mat-dialog-title>Complete Task</h3>
    <mat-dialog-content>
        <span class="task-user-question">
            Have you completed the to-do: "{{ assignment?.task?.displayName }}"
        </span>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="spacer"></div>
        <button mat-flat-button type="button" class="fixed" mat-dialog-close>No</button>
        <button
            mat-flat-button
            type="button"
            class="fixed"
            color="accent"
            (click)="completeAssignment()"
        >
            Yes, Mark Complete
        </button>
    </mat-dialog-actions>
</object-form>
