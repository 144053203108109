<div class="container">
    <div class="roles-header flex">
        <div class="title">Permissions</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field color="primary" *ngIf="!isSearchEmpty" class="roles-search">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Roles/Permissions</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Organizations"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Add Permission
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="object__type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Role/Permission Type
            </mat-header-cell>
            <mat-cell
                *matCellDef="let role"
                (click)="editObject($event, role, true)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                {{ role.object?.type }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="object__name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Object</mat-header-cell>
            <mat-cell
                *matCellDef="let role"
                (click)="editObject($event, role, true)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                {{ role.object?.displayName }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Permission
            </mat-header-cell>
            <mat-cell
                *matCellDef="let role"
                (click)="editObject($event, role, true)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                {{ role.roleDisplay }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let role" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ role: role }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="list.searching && !list.hasLoaded">
                    <mat-progress-spinner
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                    Loading...
                </span>

                <span *ngIf="list.hasLoaded && !list.list.items.length">
                    No roles/permissions found.
                </span>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>

        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-role="role">
                <button mat-menu-item (click)="editObject($event, role, true)">
                    Edit
                </button>
                <button mat-menu-item (click)="deleteObject($event, role)">
                    Delete
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
</div>
