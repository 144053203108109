<div class="flex between help-header border-bottom">
    <div class="flex stack">
        <h3 mat-dialog-title class="title">Welcome to MedaSystems help!</h3>
        <p class="secondary-title">Ask a question or make a suggestion</p>
    </div>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<div class="content-container border-bottom">
    <mat-dialog-content>
        <div class="section">
            <h4 class="title text">Start a conversation</h4>
            <h5 class="text">Our usual reply time is a day</h5>
            <button mat-flat-button color="accent">
                <mat-icon style="padding-right: 0.25rem">send</mat-icon>
                <span>Send a message</span>
            </button>
        </div>
    </mat-dialog-content>
</div>
<div class="content-container border-bottom">
    <mat-dialog-content class="border">
        <div class="section">
            <h4 class="title text">Search for help</h4>
            <mat-form-field>
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input matInput placeholder="Search" />
            </mat-form-field>
        </div>
    </mat-dialog-content>
</div>
<div class="content-container border-bottom">
    <mat-dialog-content class="border">
        <div class="section">
            <h4 class="title text">Request & Vote on Features</h4>
            <h5 class="text">Do you have any feedback or a feature request for us?</h5>
            <div class="flex between">
                <button mat-flat-button color="accent">Suggest Something</button>
                <button mat-stroked-button>Review The Idea Board</button>
            </div>
        </div>
    </mat-dialog-content>
</div>
