import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import {
    CompoundDataType,
    DataFieldValue,
    DataForm,
    DataFormAttributes,
    DataFormField,
    DataType,
} from "src/services/models/data";
import { DocumentFileItem, ProgramService } from "src/services/program.services";
import {
    DataFormService,
    DataFieldValueService,
    DataFormFieldFactory,
} from "src/services/data.services";
import {
    AbstractControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Case } from "src/services/models/case";
import { Document } from "src/services/models/document";
import { DataField, Inquiry } from "src/services/models/inquiry";
import { DatePipe } from "@angular/common";
import {
    DateIsFutureValidator,
    DateIsPastValidator,
    DropDownAutoCompleteValidator,
    IsDateValidator,
    OptionalEmailValidator,
} from "src/common/utilities/validators";
import { flatten, GenerateUniqueIdentifier } from "src/common/utilities/utilities";
import { Program } from "src/services/models/program";
import { Organization } from "src/services/models/organization";
import {
    APIListResult,
    APIObject,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    objectsOnly,
} from "src/services/models/api-object";
import { FormError, ObjectComponent, ObjectViewMode } from "../object.component";
import { Observable, combineLatest, forkJoin, map, mergeMap, of } from "rxjs";
import { ConfirmDialog } from "../confirm.dialog";
import { FileItem } from "ng2-file-upload";
import { defined } from "src/common/utilities/flatten";
//FYI Currently cannot use autocomplete for existing forms, will instead use regular input field

@Component({
    selector: "data-form",
    templateUrl: "./data-form.component.html",
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormComponent extends ObjectComponent<DataForm> {
    static nonControlDataTypes = ["instructions"]; // These data types do not generate form controls
    formLoading = false;
    @Input()
    useSpinner: boolean = true;
    get showSpinner() {
        return (
            (this.useSpinner &&
                this.formLoading &&
                this.mode === ObjectViewMode.Create) ||
            (this.useSpinner && !this.isObject(this.fullObject))
        );
    }
    programService: ProgramService;
    dataFieldValueService: DataFieldValueService;
    formFields: DataFormField[] = []; // these are the root group fields
    _submitProcessing: boolean = false;
    _organization?: Organization | ObjectReference;
    datePipe: DatePipe;

    @Input() exportButtonText: string = "Export Form";
    @Input() allowExport: boolean = false;
    @Input() repository?: Case | Inquiry;
    @Input() viewOnly: boolean = false;
    @Input() set organization(v: Organization | ObjectReference | undefined) {
        this._organization = v;
        this.updateProductOptions();
    }
    @Input() productOptions: ObjectOrReference<Program>[] = [];
    @Input() hideControls: boolean = false; // necessary for instances where we provide our own submit button
    @Input() createAttributes: DataFormAttributes = {};
    @Input() intake: boolean = false;

    @Output() formSubmitted: EventEmitter<DataForm> = new EventEmitter<DataForm>(true);

    get organization(): Organization | ObjectReference | undefined {
        return this._organization;
    }
    get isPhysician(): boolean {
        return !!this.repository?.isPhysicianStaff(this.currentAccount);
    }
    get walkthroughEnabled(): boolean {
        return this.isPhysician && !this.completedWalkthrough;
    }
    get submitProcessing(): boolean {
        return this._submitProcessing;
    }
    set submitProcessing(v: boolean) {
        this._submitProcessing = v;
    }

    get formButtonText(): string {
        return super.getIsValid() ? "Submit" : "Save";
    }

    get exists(): boolean {
        let exists = super.exists;
        // if we're creating an instance of an existing form, it doesn't exist yet
        if (exists && !this.fullObject?.template && this.mode == ObjectViewMode.Create)
            exists = false;

        return exists;
    }

    constructor(protected service: DataFormService) {
        super(service);
        this.programService = inject(ProgramService);
        this.dataFieldValueService = inject(DataFieldValueService);
        this.datePipe = inject(DatePipe);
        inject(DataFormFieldFactory);
    }

    onCancel(): void {
        if (!this.formGroup.touched) {
            this.dialogReference?.close();
        } else {
            this.dialog
                .open(ConfirmDialog, {
                    data: {
                        message:
                            "Are you sure you want to cancel? Any unsaved work will be lost.",
                    },
                    disableClose: true,
                    hasBackdrop: true,
                    minWidth: "50vw",
                })
                .afterClosed()
                .subscribe((confirm: any) => {
                    if (confirm) {
                        this.toggleEditMode();
                        this.dialogReference?.close();
                    }
                });
        }
    }

    protected getIsValid(): boolean {
        if (this.intake) return super.getIsValid(); // For an intake form, use the standard isValid

        const errors = this.formErrors(this.formGroup.controls).filter(
            (error: FormError) => error.error != "required",
        );
        return errors.length == 0;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({});
    }

    toggleEditMode(event?: MouseEvent) {
        if (event) this.terminateEvent(event);
        if (this.mode == ObjectViewMode.Edit) {
            this.mode = ObjectViewMode.View;
        } else {
            this.mode = ObjectViewMode.Edit;
        }
    }
    exportAsPDF(event: MouseEvent): void {
        this.terminateEvent(event);

        if (!this.allowExport || !this.object?.id) return;

        const date = this.datePipe
            .transform(new Date(), "mediumDate")
            ?.replaceAll(", ", "_")
            .replaceAll(" ", "_");
        let formName = this.fullObject?.displayName?.replaceAll(" ", "_") ?? "Form";
        let reference = this.repository?.reference_identifier?.toString();
        let caseName =
            this.repository instanceof Case ?
                this.repository?.name?.replaceAll(" ", "_")
            :   undefined;

        reference = reference ? reference + "_" : "";
        formName = formName ? formName + "_" : "";
        caseName = caseName ? caseName + "_" : "";

        const fileName = reference + caseName + formName + date + ".pdf";
        this.service.exportAsPdf(fileName, [this.object.id]);
    }
    isGroupField(field: DataFormField): boolean {
        return !!field.children?.length || field?.field?.data_type?.name == "group";
    }

    protected precommitTransform(v: any) {
        let form = this.fullObject;

        if (this.mode == ObjectViewMode.Create && !form?.id) {
            // create a new instance of the form without values (we'll add those after)
            form = ObjectFactory.makeObject<DataForm>(
                {
                    name: this.fullObject?.name,
                    display_name: this.fullObject?.displayName,
                    description: this.fullObject?.description,
                    region: this.fullObject?.region,
                    template: this.fullObject?.template ?? this.fullObject?.asReference,
                    owner:
                        this.repository?.asReference ??
                        this.fullObject?.owner ??
                        this.organization?.asReference,
                    attributes: this.createAttributes,
                },
                DataForm.object_type,
            ) as DataForm;
        }
        if (this.fullObject && form)
            form.values = this.getFormFieldValues(
                this.formFields,
                this.fullObject,
                this.formGroup.value,
            );
        return form;
    }
    protected commitObject(v: any): DataForm {
        // need to override here otherwise for the case where we're creating a template instance, otherwise it will try to recreate the template
        const object = new this.api.type({});
        object.update(v);
        return object;
    }

    protected commit(v: any): Observable<DataForm | undefined> {
        const exists = this.exists;
        const _v = this.precommitTransform(v);
        const obj = this.commitObject(_v);

        if (this.intake) {
            // If this is an intake form, we can't submit here, we need to do it as part of the intake request
            // So return the commit object which will be passed in the formSubmitted handler
            return of(obj);
        }
        console.log(exists);
        // this only works with non reference values
        const objValues = objectsOnly(obj.values, DataFieldValue);
        // If any of the values in the form generate observables, we need to complete those before creating/updating the form
        let observables = objValues.reduce(
            (result: any, value: DataFieldValue) => {
                // Check if value.value is an Observable
                if (value.value instanceof Observable) {
                    result[value.form_field!.id!] = value.value;
                } else if (Array.isArray(value.value)) {
                    const observablesInItems: Observable<any>[] = value.value.map(
                        (item) => {
                            if (typeof item === "object" && !Array.isArray(item)) {
                                const observablesInItem: any = {};
                                Object.entries(item).forEach(([key, valueInItem]) => {
                                    if (valueInItem instanceof Observable) {
                                        observablesInItem[key] = valueInItem;
                                    } else observablesInItem[key] = of(valueInItem);
                                });
                                return combineLatest(observablesInItem);
                            }
                            return of(item); // Return original item if not an object containing observables
                        },
                    );

                    if (observablesInItems.length > 0) {
                        result[value.form_field!.id!] =
                            combineLatest(observablesInItems);
                    }
                }

                return result;
            },
            { __root__: of(undefined) },
        );

        return forkJoin(observables).pipe(
            mergeMap((results: any) => {
                Object.keys(results).forEach((formFieldId: string) => {
                    const update = objValues.find(
                        (dfv: DataFieldValue) => dfv.form_field?.id === formFieldId,
                    );
                    if (update) update.value = results[formFieldId];
                });
                return exists ?
                        this.api.update(obj)
                    :   this.api.create(obj).pipe(
                            map((result: DataForm | undefined) => {
                                if (!result) throw new Error("No object returned");
                                return result;
                            }),
                        );
            }),
        );
    }

    protected onCommitSuccess(v: DataForm | undefined): boolean {
        const adding = super.onCommitSuccess(v);
        if (!this.intake)
            this.snackbar.open("Saved changes successfully.", undefined, {
                duration: 2000,
            });
        this.toggleEditMode();
        this.formSubmitted.emit(v);
        return adding;
    }
    protected onCommitError(err: any): void {
        super.onCommitError(err);
        this.formSubmitted.emit(err);
        this.snackbar.open(
            "An error occured while saving changes.  Please contact your system administrator.",
            undefined,
            { duration: 2000 },
        );
    }

    protected isControlField(formField: DataFormField): boolean {
        return (
            formField?.field?.data_type?.name == "group" ||
            formField?.field?.data_type?.name == "instructions"
        );
    }
    protected dataForFieldName(fieldName: string, data: any): any {
        if (typeof data != "object" || data == null || Array.isArray(data))
            return undefined;
        if (data.hasOwnProperty(fieldName)) {
            let value = data[fieldName];
            if (value instanceof APIObject) value = value.type + ":" + value.id;
            else if (Array.isArray(value))
                value = value.map((v: any) =>
                    v instanceof APIObject ? v.type + ":" + v.id : v,
                );
            if (value === null) value = undefined;
            return value;
        }
        for (const group in data) {
            const fieldData = this.dataForFieldName(fieldName, data[group]);
            if (fieldData) return fieldData;
        }
        return undefined;
    }
    static transformFormFieldObjects(value: any): any {
        if (Array.isArray(value)) {
            const fileItems = value.filter((v: any) => v instanceof FileItem);
            const hasArrayValue = value.some((item) => {
                return Object.values(item).some((value) => Array.isArray(value));
            });
            if (fileItems.length) {
                const others = value.filter((v: any) => !(v instanceof FileItem));
                const item = fileItems[0];
                value = (item as DocumentFileItem)._uploader
                    ?.uploadAllObservable()
                    .pipe(
                        map((result: any[]) =>
                            defined(
                                result.map((res: any) =>
                                    ObjectFactory.makeObject<Document>(res),
                                ),
                            ),
                        ),
                        map((result: ObjectOrReference<Document>[]) => [
                            ...others,
                            ...result,
                        ]),
                        map((v: any[]) =>
                            v.map((tv: any) =>
                                tv instanceof APIObject ? tv.type + ":" + tv.id : tv,
                            ),
                        ),
                    );
            } else if (hasArrayValue) {
                value.forEach((obj) => {
                    for (const key in obj) {
                        if (Array.isArray(obj[key])) {
                            obj[key] = DataFormComponent.transformFormFieldObjects(
                                obj[key],
                            );
                        }
                    }
                });
            } else {
                value = value.map((v: any) =>
                    v instanceof APIObject ? v.type + ":" + v.id : v,
                );
            }
        } else if (value instanceof FileItem) {
            value = (value as DocumentFileItem)._uploader?.uploadAllObservable().pipe(
                map((result: any[]) =>
                    defined(
                        result.map((res: any) =>
                            ObjectFactory.makeObject<Document>(res),
                        ),
                    ),
                ),
                map((result: ObjectOrReference<Document>[]) =>
                    result.length ? result[0].type + ":" + result[0].id : undefined,
                ),
            );
        } else if (value instanceof APIObject) {
            value = value.type + ":" + value.id;
        }
        return value;
    }
    protected getFormFieldValues(
        groupFields: DataFormField[],
        form: DataForm,
        data: any,
    ): DataFieldValue[] {
        const values: DataFieldValue[] = [];
        groupFields.forEach((ff: DataFormField) => {
            const fieldName = ff.fieldName ?? ff.field.name ?? "";
            const value = this.dataForFieldName(fieldName, data);
            // all the fields and values should have already been converted from ObjectReference to actual DataFormFields and DataFormValues
            // but this just ensures none slip by
            const children = objectsOnly(form.form_fields, DataFormField).filter(
                (f: DataFormField) =>
                    f instanceof DataFormField && f.group?.id == ff.id,
            );
            const formValues = objectsOnly(form.values, DataFieldValue);
            if (children.length > 0) {
                const childUpdates = this.getFormFieldValues(
                    children,
                    form,
                    value || {},
                );
                values.push(...childUpdates);
            } else if (!this.isControlField(ff)) {
                const oldValues = formValues.filter(
                    (dfv: DataFieldValue) => dfv.form_field?.id == ff.id,
                );
                const oldValue = oldValues.length ? oldValues[0] : undefined;
                const isEqual = APIObject.isEqual(oldValue?.value, value);
                if (!isEqual) {
                    const updatedValue =
                        oldValue ??
                        (ObjectFactory.makeObject<DataFieldValue>(
                            {
                                repository_id: form.id,
                                repository_type: form.type,
                                form_field: ff.asReference,
                                field: ff.field.asReference,
                            },
                            DataFieldValue.object_type,
                        ) as DataFieldValue);
                    if (updatedValue)
                        updatedValue.value =
                            DataFormComponent.transformFormFieldObjects(value);
                    if (updatedValue?.value) values.push(updatedValue);
                } else if (oldValue) {
                    values.push(oldValue);
                }
            }
        });
        return values;
    }

    static getValidators(
        type: DataType | undefined,
        attributes: any,
        required: boolean = false,
    ): any {
        if (!type) return [];
        const regexNumeric = "^[+-]?\\d*(([,.]\\d{3})+)?([,.]\\d+)?([eE][+-]?\\d+)?$";
        const validators = [];
        let pattern;

        // Required
        if (required) validators.push(Validators.required);

        // Base type validations
        if (type.name == "number")
            pattern = attributes?.Validators?.pattern || regexNumeric;
        if (type.name?.includes("email")) validators.push(OptionalEmailValidator); // JT - Always optional - if it's a required field, it will also have Validators.required

        if (type.name?.includes("date")) {
            if (type.name?.includes(".past")) {
                validators.push(DateIsPastValidator);
            } else if (type.name.includes("future")) {
                validators.push(DateIsFutureValidator);
            } else {
                validators.push(IsDateValidator);
            }
        }

        // Attribute additions/overrides
        if (attributes?.validators?.pattern) pattern = attributes.validators.pattern;
        if (attributes?.validators?.max_length)
            validators.push(Validators.maxLength(attributes.validators.max_length));
        if (attributes?.validators?.min_length)
            validators.push(Validators.minLength(attributes.validators.min_length));
        if (attributes?.validators?.number) {
            const { min, max } = attributes.validators.number;

            if (max) validators.push(Validators.max(max));
            if (min) validators.push(Validators.min(min));
        }
        if (pattern) validators.push(Validators.pattern(pattern));

        return validators;
    }

    static transformValueForFieldType(type: DataType, value: any): any {
        if (Array.isArray(value))
            return value.map((v: any) => this.transformValueForFieldType(type, v));

        if (
            type?.name == "date" ||
            type?.name == "datetime" ||
            type?.name?.startsWith("date")
        ) {
            if (value) {
                value = new Date(value);
                if (isNaN(value)) value = undefined;
            }
        }
        return value;
    }
    static buildCompoundFormGroup(
        type: DataType,
        value: any,
        attributes: any,
        required: boolean = false,
    ): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        type.compound?.forEach((compound: CompoundDataType) => {
            const compoundName = compound.name!;
            const childValue = DataFormComponent.transformValueForFieldType(
                compound.child,
                value?.hasOwnProperty(compoundName) ? value[compoundName] : undefined,
            );
            const childAttributes =
                attributes?.hasOwnProperty(compoundName) ?
                    attributes[compoundName]
                :   {};
            if (compound.child.isCompound) {
                const group = DataFormComponent.buildCompoundFormGroup(
                    compound.child,
                    childValue,
                    childAttributes,
                    required && compound.required,
                );
                formGroup.addControl(compoundName, group);
            } else if (
                DataFormComponent.nonControlDataTypes.indexOf(
                    compound.child.name || "",
                ) == -1
            ) {
                const validators = DataFormComponent.getValidators(
                    compound.child,
                    childAttributes,
                    required && compound.required,
                );
                const asyncValidators =
                    childAttributes.autocomplete ?
                        [DropDownAutoCompleteValidator]
                    :   undefined;
                compound.previewControl = new UntypedFormControl(
                    childValue,
                    validators,
                    asyncValidators,
                );
                formGroup.addControl(compoundName, compound.previewControl);
            }
        });
        return formGroup;
    }
    protected getUsedFieldNames(group: FormGroup, result: string[] = []): string[] {
        Object.keys(group.controls).forEach((fieldName: string) => {
            if (result.indexOf(fieldName) == -1) {
                result.push(fieldName);
                const control = group.controls[fieldName];
                if (control instanceof FormGroup)
                    this.getUsedFieldNames(control, result);
            }
        });
        return result;
    }
    protected buildFormFieldControl(
        field: DataFormField,
        allFields: DataFormField[] = [],
        values: DataFieldValue[] = [],
    ): AbstractControl | undefined {
        const children =
            allFields?.filter((f: DataFormField) => f.group?.id == field.id) || [];
        if (children.length > 0) {
            // it's a subgroup
            field.previewControl = this.buildFormFieldControls(
                allFields,
                values,
                field,
            );
        } else {
            const childValues = DataFormComponent.transformValueForFieldType(
                field.field.data_type,
                flatten(
                    values
                        .filter(
                            (value: DataFieldValue) => value.form_field?.id == field.id,
                        )
                        .map((value: DataFieldValue) =>
                            Array.isArray(value.value) ? value.value : [value.value],
                        ),
                ),
            );
            const multiple = field.attributes?.multiple || field.multiple;
            if (!field?.field?.data_type?.isCompound) {
                // it's a basic data type
                if (
                    DataFormComponent.nonControlDataTypes.indexOf(
                        field?.field?.data_type?.name || "",
                    ) == -1
                ) {
                    // check to see if it needs a form control
                    const validators = DataFormComponent.getValidators(
                        field.field.data_type,
                        field.attributes,
                        field.required,
                    );
                    const asyncValidators =
                        field.attributes?.autocomplete ?
                            [DropDownAutoCompleteValidator]
                        :   undefined;
                    if (this.isGroupField(field)) {
                        field.previewControl = new UntypedFormGroup({});
                    } else if (field?.field?.data_type?.isLookup && multiple) {
                        field.previewControl = new UntypedFormControl(
                            childValues,
                            validators,
                            asyncValidators,
                        );
                    } else if (multiple) {
                        if (field?.field?.data_type?.name === "document") {
                            field.previewControl = new UntypedFormControl(
                                childValues.length ? childValues : [],
                                validators,
                                asyncValidators,
                            );
                        } else {
                            field.previewControl = new UntypedFormArray(
                                childValues.map(
                                    (value: any) =>
                                        new UntypedFormControl(
                                            value,
                                            validators,
                                            asyncValidators,
                                        ),
                                ),
                            );
                        }
                    } else {
                        // set the first found value if any (is this an error that needs to be displayed?)
                        const controlValue = childValues.length ? childValues[0] : null;
                        field.previewControl = new UntypedFormControl(
                            controlValue,
                            validators,
                            asyncValidators,
                        );
                    }
                }
            } else if (field?.field?.data_type?.isCompound && multiple) {
                // it's a compound field with multiple values
                const formArray = childValues.map((value: any) =>
                    DataFormComponent.buildCompoundFormGroup(
                        field.field.data_type,
                        value,
                        field.attributes,
                        field.required,
                    ),
                );
                field.previewControl = new UntypedFormArray(formArray);
            } else {
                // it's a compound type with a signle value
                const compoundValue = childValues.length > 0 ? childValues[0] : {};
                field.previewControl = DataFormComponent.buildCompoundFormGroup(
                    field.field.data_type,
                    compoundValue,
                    field.attributes,
                    field.required,
                );
            }
        }
        return field.previewControl;
    }
    protected buildFormFieldControls(
        fields?: DataFormField[],
        values: DataFieldValue[] = [],
        parent?: DataFormField,
    ): UntypedFormGroup {
        const formGroup = this.formBuilder.group({});
        const children =
            fields?.filter((field: DataFormField) => field.group?.id == parent?.id) ??
            [];
        if (parent) parent.children = children;
        children.sort((a: DataFormField, b: DataFormField) => a.order - b.order);
        children.forEach((child: DataFormField) => {
            child.fieldName = GenerateUniqueIdentifier(
                child.field.name ?? "_unnamed",
                Object.keys(formGroup.controls),
            );
            const control = this.buildFormFieldControl(child, fields, values);
            if (control) formGroup.addControl(child.fieldName, control);
        });
        return formGroup;
    }
    protected buildForm(form?: DataForm): void {
        const fieldObs =
            form?.form_fields.map((field: ObjectOrReference<DataFormField>) =>
                ObjectFactory.objectObservable(field),
            ) ?? [];
        const valueObs =
            form?.values.map((value: ObjectOrReference<DataFieldValue>) =>
                ObjectFactory.objectObservable(value),
            ) ?? [];
        forkJoin([...fieldObs, ...valueObs]).subscribe(
            (fieldOrValue: (DataFormField | DataFieldValue | undefined)[]) => {
                const allFields = fieldOrValue.filter(
                    (fov: DataFormField | DataFieldValue | undefined) =>
                        fov instanceof DataFormField,
                ) as DataFormField[];
                const allValues = fieldOrValue.filter(
                    (fov: DataFormField | DataFieldValue | undefined) =>
                        fov instanceof DataFieldValue,
                ) as DataFieldValue[];
                if (form) form.values = allValues;
                this.formFields = allFields.filter(
                    (field: DataFormField) => field.group?.id == undefined,
                );
                this.formGroup = this.buildFormFieldControls(allFields, allValues);
            },
        );
    }
    objectName = "";
    protected setObject(v?: DataForm): void {
        super.setObject(v);
        this.buildForm(this.fullObject); // this has to be fullObject and not v because they are two different objects and we lose access to v
        this.updateProductOptions();
        this.objectName = this.fullObject?.displayName ?? "Unnamed Form";
    }
    isObject(o: any) {
        return o instanceof DataForm;
    }
    protected updateProductOptions(): void {
        if (this.organization?.id || this.fullObject?.owner?.id) {
            // Only update if we've got an authenticated account, otherwise we'll overwrite the intake config options
            if (this.currentAccount) {
                this.programService
                    .list({
                        repository:
                            this.organization?.id ?? this.fullObject?.owner?.id ?? "0",
                        deleted: "false",
                        exclude_fields: ["roles"].join(","),
                    })
                    .subscribe((progs: APIListResult<Program>) => {
                        this.productOptions = progs as Program[];
                    });
            }
        } else if (this.currentAccount) {
            this.productOptions = [];
        }
    }
}
