<div
    mat-dialog-title
    class="flex"
    cdkDrag
    cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-global-overlay-wrapper"
>
    <div class="title-1">{{ title }}</div>
    <div class="spacer"></div>
</div>
<div
    mat-dialog-content
    class="form-field-properties flex"
    [formGroup]="formGroup"
    resizableDialog
>
    <div class="wrapper flex stack flexible">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="General">
                <div class="wrapper flex stack property-tab">
                    <mat-form-field class="full">
                        <mat-label>Display Name</mat-label>
                        <textarea
                            matInput
                            formControlName="display_name"
                            [cdkTextareaAutosize]="true"
                            [cdkAutosizeMinRows]="1"
                            [cdkAutosizeMaxRows]="12"
                        ></textarea>
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="full">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description"></textarea>
                    </mat-form-field>
                    <mat-slide-toggle
                        [disabled]="viewOnly"
                        [checked]="canInstantiate"
                        (toggleChange)="toggleCanInstantiate()"
                        matTooltip="Selecting this option will allow additional instances of this form to be created within a case."
                    >
                        Can Instantiate
                    </mat-slide-toggle>
                </div>
            </mat-tab>
            <mat-tab label="Advanced">
                <div class="wrapper flex stack property-tab">
                    <mat-slide-toggle
                        *ngIf="!viewOnly"
                        [checked]="autogenerateName"
                        (toggleChange)="toggleAutogenerate()"
                    >
                        Auto-generate unique identifier
                    </mat-slide-toggle>
                    <mat-form-field class="full identifier-spacing">
                        <mat-label *ngIf="!autogenerateName">Identifier</mat-label>
                        <input
                            matInput
                            formControlName="name"
                            (focusout)="onIdentifierFocus()"
                        />
                        <mat-hint *ngIf="!autogenerateName">
                            Must be lowercase. Cannot contain spaces
                        </mat-hint>
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div mat-dialog-actions>
    <div class="spacer"></div>
    <button
        mat-flat-button
        type="button"
        color="primary"
        [mat-dialog-close]="formGroup.dirty"
    >
        Dismiss
    </button>
</div>
