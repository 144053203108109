import { Component, Inject, inject } from "@angular/core";
import { SessionService } from "../../services/session.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export type ConfirmDialogData = {
    title?: string;
    message: string;
    ok?: string;
    cancel?: string;
};

@Component({
    selector: "confirm-dialog",
    template: `
        <h3 mat-dialog-title>{{ data.title || "Confirmation" }}</h3>
        <mat-dialog-content
            [innerHtml]="data.message || 'Are you sure?'"
        ></mat-dialog-content>
        <mat-dialog-actions>
            <div class="spacer"></div>
            <button
                mat-flat-button
                type="button"
                class="fixed"
                [mat-dialog-close]="false"
            >
                {{ data.cancel || "Cancel" }}
            </button>
            <button
                mat-flat-button
                type="button"
                class="fixed"
                color="accent"
                [mat-dialog-close]="true"
            >
                {{ data.ok || "OK" }}
            </button>
        </mat-dialog-actions>
    `,
})
export class ConfirmDialog {
    protected session: SessionService;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data_: ConfirmDialogData,
        protected dialogRef: MatDialogRef<ConfirmDialog>,
    ) {
        this.session = inject(SessionService);
        this.session.onLogout.subscribe(() => this.dialogRef.close(false));
    }

    get data(): ConfirmDialogData {
        return this.data_;
    }
}
