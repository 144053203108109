import { AfterViewInit, Directive, OnDestroy, inject } from "@angular/core";
import { Message, SessionService } from "../session.service";
import { APIObject } from "../models/api-object";
import { Account } from "../models/account";
import { HttpErrorResponse } from "@angular/common/http";
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

let gComponentCounter: number = 0;
let DISABLE_TIMER: boolean = false; // Only ever set this to true for local testing - DO NOT COMMIT changes to this line
export type DashboardRole = "provider" | "pharma" | "none";
@Directive()
export abstract class SessionComponent implements AfterViewInit, OnDestroy {
    readonly session: SessionService;
    compareObjects = APIObject.compareObjects;
    asFormControl = SessionComponent.asFormControl;
    asFormGroup = SessionComponent.asFormGroup;
    asFormArray = SessionComponent.asFormArray;
    formGroupControl = SessionComponent.formGroupControl;

    componentId = gComponentCounter++;
    constructor() {
        this.session = inject(SessionService);
        this.snackbar = inject(MatSnackBar);
    }

    get currentAccount(): Account | undefined {
        return this.session.currentAccount;
    }
    get errorMessage(): string | undefined {
        return this.session.messages ?
                this.session.messages?.map((m: Message) => m.message).join("<br />")
            :   undefined;
    }
    get isSystemAdministrator(): boolean {
        return !!this.currentAccount?.isSystemAdministrator;
    }

    get completedWalkthrough() {
        return true;
    }
    get walkthroughRendered() {
        return false;
    }

    protected snackbar: MatSnackBar;
    protected accountSubscription?: Subscription;
    protected logoutSubscription?: Subscription;
    protected timerSubscription?: Subscription;

    ngAfterViewInit() {
        this.accountSubscription = this.session.currentAccountChanged.subscribe(
            (a: Account | undefined) => this.onCurrentAccountChanged(a),
        );
        this.logoutSubscription = this.session.onLogout.subscribe(() =>
            this.onLogout(),
        );
    }
    ngOnDestroy(): void {
        this.accountSubscription?.unsubscribe();
        this.accountSubscription = undefined;

        this.logoutSubscription?.unsubscribe();
        this.logoutSubscription = undefined;

        this.timerSubscription?.unsubscribe();
        this.timerSubscription = undefined;
    }

    setError(msg: string, err?: HttpErrorResponse): void {
        let message = msg;
        if (err?.error?.detail) message += " - " + err.error.detail;
        this.session.message = message;
    }

    protected terminateEvent(event?: Event): void {
        event?.stopPropagation();
        event?.preventDefault();
    }
    protected onCurrentAccountChanged(a: Account | undefined): void {}
    protected onLogout(): void {}
    protected onTimer(): void {}

    static asFormControl(ctrl: AbstractControl): UntypedFormControl {
        return ctrl as UntypedFormControl;
    }
    static asFormArray(ctrl: AbstractControl): UntypedFormArray {
        return ctrl as UntypedFormArray;
    }
    static asFormGroup(ctrl: AbstractControl): UntypedFormGroup {
        return ctrl as UntypedFormGroup;
    }
    static formGroupControl(group: AbstractControl, ctrl: string): UntypedFormControl {
        return SessionComponent.asFormGroup(group).controls[ctrl] as UntypedFormControl;
    }

    private _dashboardRole: undefined | DashboardRole = undefined;

    set dashboardRole(role: DashboardRole) {
        this._dashboardRole = role;
    }

    get dashboardRole(): DashboardRole | undefined {
        return this._dashboardRole;
    }

    get dashboardRoleDefined() {
        return this._dashboardRole !== undefined;
    }
}
