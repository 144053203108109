import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Injector,
    NgModuleRef,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewRef,
} from "@angular/core";
export let contentStack: ContentStack | undefined;

export interface ContentView<T> {
    type: Type<T> | undefined;
    breadcrumbText?: string; // Deprecated: Move to providing breadcrumbs in the component instance
    breadcrumbIcon?: any; // Deprecated: Move to providing breadcrumbs in the component instance
    content?: ComponentRef<T>;
    viewRef?: ViewRef;
}

@Component({
    selector: "content-stack",
    template: `
        <div class="wrapper absolute flex stack">
            <div class="content-container">
                <ng-template #contentTemplate></ng-template>
            </div>
        </div>
    `,
    styles: [
        `
            .content-container {
                position: relative;
                flex: 1 1 auto;
                overflow-y: scroll;
            }
        `,
    ],
})
export class ContentStack implements OnDestroy {
    @ViewChild("contentTemplate", { read: ViewContainerRef })
    protected contentContainer?: ViewContainerRef;

    protected stack: ContentView<any>[] = [];
    onStackChanged: EventEmitter<ContentView<any>[]> = new EventEmitter<
        ContentView<any>[]
    >(true);

    constructor(protected resolver: ComponentFactoryResolver) {
        contentStack = this;
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (contentStack == this) contentStack = undefined;
    }

    get count(): number {
        return this.stack.length;
    }
    get breadcrumbs(): ContentView<any>[] {
        return this.stack.slice().reverse();
    }
    get root(): ContentView<any> | undefined {
        return this.stack.length > 0 ? this.stack[this.stack.length - 1] : undefined;
    }

    push<T>(view: ContentView<T> | Type<T>, index: number = -1): T | undefined {
        if (!("type" in view))
            // If it's not a ContentView, coerce it to be
            view = { type: view };
        if (view.type && !view.content) {
            view.content = this.createComponent<T>(view.type);
            if (view.content?.location?.nativeElement?.classList)
                view.content.location.nativeElement.classList.add(
                    "wrapper",
                    "absolute",
                    "no-padding",
                );
        }
        if (index != -1) {
            while (this.stack.length > index) this.pop(false);
        }
        this.stack.unshift(view);
        this.updateView();
        return view.content?.instance;
    }
    pop(update: boolean = true): ContentView<any> | undefined {
        const view = this.stack.shift();
        if (view) {
            view.viewRef?.destroy();
            view.content?.destroy();
            view.viewRef = undefined;
            view.content = undefined;
        }
        if (update) {
            this.updateView();
        }
        return view;
    }
    selectCrumb(event: MouseEvent, index: number): void {
        event.preventDefault();
        event.stopPropagation();
        let popCount = Math.max(0, this.stack.length - index - 1);
        while (popCount-- > 0) this.pop(false);
        this.updateView();
    }

    protected createComponent<T>(
        type: Type<T>,
        injector?: Injector,
        nodes?: any[][],
        module?: NgModuleRef<any>,
    ): ComponentRef<T> | undefined {
        injector = injector ?? this.contentContainer?.injector;
        const componentOptions = {
            injector: injector,
            ngModuleRef: module,
            projectableNodes: nodes,
        };
        const component = this.contentContainer?.createComponent(
            type,
            componentOptions,
        );
        if (component) this.contentContainer?.detach(0);
        return component;
    }
    protected updateView(): void {
        const view = this.stack.length ? this.stack[0] : undefined;
        if (this.contentContainer) {
            this.contentContainer.detach();
            let v: ViewRef | undefined;
            if (view?.viewRef && !view.viewRef.destroyed) v = view.viewRef;
            else if (view?.content) v = view.content.hostView;
            if (view && v) view.viewRef = this.contentContainer.insert(v);
        } else this.stack = [];
        this.onStackChanged.emit(this.stack);
    }
}
