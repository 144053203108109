import { TabChangeObjectMetaData } from "src/program/components/assignment/task-table.component";
import { CASE_TAB_NAMES } from "../component.services";
import {
    APIObject,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
} from "./api-object";
import { Task, TASK_TYPE } from "./task";
import { Case } from "./case";
import { Inquiry } from "./inquiry";
import { Account } from "./account";

export class Assignment extends APIObject {
    static object_type: string = "program.assignment";
    workflow!: ObjectOrReference<Case | Inquiry>; // Case or Inquiry - if set to an Inquiry, then the assignment is shared
    task!: Task; // a Task object
    assigned_on?: Date;
    assigned_by: OptionalObjectOrReference<Account>; // Account, optional
    assignee: OptionalObjectOrReference<Account>; // Account, optional
    reference: OptionalObjectOrReference<APIObject>; // any Object, optional - used to link a task to something like a document/communication, etc
    dependencies!: ObjectOrReference<Assignment>[]; // a list of Task references, optional on write
    due_date?: Date; // when the task is due, optional
    completed?: Date; // when the task was completed, optional
    pending!: boolean; // if the task is currently pending, read-only
    case_name?: string; // read-only
    order!: number;
    parent: OptionalObjectOrReference<Assignment>;
    references?: AssignmentReference[];
    completed_by: OptionalObjectOrReference<Account>;
    display_name?: string; // overrides the task display name
    description?: string; // overrides the task description
    frequency_unit?: string;
    frequency_amount?: number;
    frequency_end_date?: Date;
    get displayName(): string | undefined {
        return this.display_name ?? this.task?.name;
    }
    get taskDescription(): string | undefined {
        return this.description ?? this.task.description;
    }

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(
            ...[
                "assigned_by",
                "assignee",
                "reference",
                "dependencies",
                "due_date",
                "references",
                "display_name",
                "description",
                "frequency_unit",
                "frequency_amount",
                "frequency_end_date",
            ],
        );
        this._readOnly.push(...["pending", "case_name"]);
        this._references.push(
            ...[
                "workflow",
                "assigned_by",
                "assignee",
                "reference",
                "dependencies",
                "parent",
                "completed_by",
            ],
        );
        super.initialize(data, patch);
        this.setMember(data, patch, "workflow", ObjectReference);
        this.setMember(data, patch, "task", Task);
        this.setMember(data, patch, "assigned_on", Date);
        this.setMember(data, patch, "assigned_by", Account);
        this.setMember(data, patch, "assignee", Account);
        this.setMember(data, patch, "reference", ObjectReference);
        this.setMember(data, patch, "dependencies", Assignment, true);
        this.setMember(data, patch, "due_date", Date);
        this.setMember(data, patch, "completed", Date);
        this.setMember(data, patch, "completed_by", Account);
        this.setMember(data, patch, "pending");
        this.setMember(data, patch, "case_name");
        this.setMember(data, patch, "order");
        this.setMember(data, patch, "parent", Assignment);
        this.setMember(data, patch, "references", AssignmentReference, true);
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "frequency_unit");
        this.setMember(data, patch, "frequency_amount");
        this.setMember(data, patch, "frequency_end_date", Date);
    }

    get isOverdue(): boolean {
        const currentDate = new Date();
        return !!this.due_date && this.due_date < currentDate;
    }

    get taskTypeToTabGroupRelation(): TabChangeObjectMetaData {
        const obj: TabChangeObjectMetaData = {} as TabChangeObjectMetaData;
        let taskType = this.task.taskType as string;
        taskType = taskType?.replace(".internal", "") || "";
        switch (taskType) {
            case TASK_TYPE.SHARE_DOCUMENT:
                obj.share = true;
                obj.upload = true;
                obj.tabName = CASE_TAB_NAMES.DOCUMENTS;
                break;
            case TASK_TYPE.UPLOAD_DOCUMENT:
                obj.upload = true;
                obj.tabName = CASE_TAB_NAMES.DOCUMENTS;
                break;
            case TASK_TYPE.REVIEW_DOCUMENT:
                obj.tabName = CASE_TAB_NAMES.DOCUMENTS;
                break;
            case TASK_TYPE.CREATE_SHIPMENT:
                obj.tabName = CASE_TAB_NAMES.SHIPMENTS;
                (obj as any).create = true;
                break;
            case TASK_TYPE.CONFIRM_SHIPMENT:
                obj.tabName = CASE_TAB_NAMES.SHIPMENTS;
                break;
            case TASK_TYPE.INVITATION_SEND_MESSAGE:
                obj.tabName = CASE_TAB_NAMES.SEND_MESSAGE_DIALOG;
                break;
            case TASK_TYPE.SEND_COMMUNICATION:
                obj.tabName = CASE_TAB_NAMES.COMMUNICATIONS;
                break;
            case TASK_TYPE.DATA:
                obj.tabName = CASE_TAB_NAMES.DATA;
                break;
            case TASK_TYPE.DATA_REVIEW:
                obj.tabName = CASE_TAB_NAMES.DATA;
                break;
            default:
                obj.tabName = CASE_TAB_NAMES.UNKNOWN;
        }
        return obj;
    }
}

export class AssignmentReference extends APIObject {
    static object_type: string = "program.assignmentreference";
    item_data?: string;
    item_type!: string;
    reference?: ObjectOrReference<APIObject>;

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["item_data", "reference"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "reference", ObjectReference);
        this.setMember(data, patch, "item_data");
        this.setMember(data, patch, "item_type");
    }
}

export class AssignmentGroup extends APIObject {
    name!: string;
    description!: string;
    assignments!: Assignment[];

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["assignments"]);
        super.initialize(data, patch);
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "description");
        this.setMember(data, patch, "assignments", Assignment, true);
    }
}
