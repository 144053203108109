import { CaseTeam, Team, TeamMemberFactory } from "src/services/models/team";
import {
    TeamService,
    CaseTeamService,
    StatusService,
    StatusFactory,
} from "./../../../services/program.services";
import { AccountService, OrganizationSettingsFactory } from "src/services/iam.services";
import { TeamMember } from "./../../../services/models/team";
import { Component, Input, ViewChild, inject } from "@angular/core";
import {
    ObjectComponent,
    ObjectViewMode,
} from "../../../common/components/object.component";
import { Inquiry } from "../../../services/models/inquiry";
import {
    CaseService,
    DocumentService,
    InquiryService,
    ProgramService,
} from "../../../services/program.services";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Account } from "../../../services/models/account";
import { Program } from "../../../services/models/program";
import { filter, map } from "rxjs/operators";
import { faCapsules } from "@fortawesome/free-solid-svg-icons";
import { SendTemplateDialog } from "../../../common/components/template/send-template.dialog";
import { Message } from "../../../services/models/message";
import { Case, Status } from "../../../services/models/case";
import { CaseComponent } from "../case/case.component";
import { ObjectAdminComponent } from "../../../common/components/object-admin.component";
import { DocumentRepository, Document } from "../../../services/models/document";
import {
    APIListResult,
    APIObject,
    ObjectFactory,
    ObjectReference,
} from "../../../services/models/api-object";
import {
    DataFieldDefinition,
    Organization,
} from "../../../services/models/organization";
import { OrganizationService } from "../../../services/iam.services";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { DatePipe } from "@angular/common";
import { DataFormService } from "src/services/data.services";
import { DataForm } from "src/services/models/data";
import { CaseSummary } from "../case/case-summary.component";
import { MatDialog } from "@angular/material/dialog";
import {
    AppNotificationService,
    MessageService,
} from "src/services/notification.services";
import { AppNotification } from "src/services/models/appNotification";
import { RelatedObjectEvent } from "src/services/api.service";
import { WebsocketObjectAction } from "src/common/utilities/request";
import {
    CASE_TAB_NAMES,
    TabChangeEvent,
    TabChangeService,
} from "src/services/component.services";
import { Subscription } from "rxjs";
import { CommunicationComponent } from "../communication/communication.component";
@Component({
    selector: "inquiry",
    templateUrl: "./inquiry.component.html",
    styleUrls: ["./inquiry.component.scss"],
})
export class InquiryComponent extends ObjectComponent<Inquiry> {
    @Input()
    documentNotificationToOpen?: Document;
    @Input()
    messageToOpen?: Message;

    @Input()
    formToOpen?: DataForm;

    @ViewChild(CaseSummary) summary?: CaseSummary;
    openApiSubscriptions: (number | undefined)[] = [];
    tabGroupService: TabChangeService;
    protected programService: ProgramService;
    protected caseService: CaseService;
    protected documentService: DocumentService;
    protected organizationService: OrganizationService;
    protected accountService: AccountService;
    protected teamService: TeamService;
    protected caseTeamService: CaseTeamService;
    protected caseStatusService: StatusService;
    protected appNotificationService: AppNotificationService;
    protected datePipe: DatePipe;
    protected dataFormService: DataFormService;

    protected dialog: MatDialog;
    availablePrograms: Program[] = [];
    displayMessage?: Message;
    displayMessageText?: string; // this gets set to a striped text version if we determine the selected message is html
    showAsHTML: boolean = false;
    objectName = "Inquiry";
    roleControls: { [key: string]: UntypedFormControl } = {};
    productOptions: Program[] = [];
    staffTeam?: Team;
    organization?: Organization;
    allowCases: boolean = false;

    drugNameIcon = faCapsules;

    dataFieldDefinitions: DataFieldDefinition[] = [];
    tabChangeSubscription?: Subscription;
    constructor(protected service: InquiryService) {
        super(service);
        this.programService = inject(ProgramService);
        this.caseService = inject(CaseService);
        this.documentService = inject(DocumentService);
        this.organizationService = inject(OrganizationService);
        this.accountService = inject(AccountService);
        this.teamService = inject(TeamService);
        this.caseTeamService = inject(CaseTeamService);
        this.dialog = inject(MatDialog);
        this.caseStatusService = inject(StatusService);
        this.appNotificationService = inject(AppNotificationService);
        this.tabGroupService = inject(TabChangeService);
        this.datePipe = inject(DatePipe);
        this.dataFormService = inject(DataFormService);

        inject(StatusFactory);
        inject(TeamMemberFactory);
        inject(MessageService);
        inject(OrganizationSettingsFactory);

        this.tabChangeSubscription = this.tabGroupService.tabWillChange.subscribe(
            (event) => this.handleTabSelection(event),
        );
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.tabChangeSubscription) {
            this.tabChangeSubscription.unsubscribe();
        }
        this.openApiSubscriptions.forEach((sub) => {
            this.api.unsubscribe(sub);
        });
    }
    documentToOpen?: TabChangeEvent;
    messageEvent?: TabChangeEvent;
    handleTabSelection(event: TabChangeEvent) {
        switch (event?.tabName) {
            case CASE_TAB_NAMES.DOCUMENTS:
                this.documentToOpen = event;
                break;
            case CASE_TAB_NAMES.COMMUNICATIONS:
                this.messageEvent = event;
                break;
            default:
                break;
        }
    }
    openMessage(message: Message) {
        const data = {
            message,
        };

        this.tabGroupService.changeTab(CASE_TAB_NAMES.COMMUNICATIONS, data);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.documentNotificationToOpen) {
            this.openDocument(this.documentNotificationToOpen);
            this.documentNotificationToOpen = undefined;
        } else if (this.messageToOpen) {
            this.openMessage(this.messageToOpen);
            this.messageToOpen = undefined;
        }
        this.assignee.valueChanges.subscribe((assignee: ObjectReference) => {
            if (!assignee?.id) return;
            const owners = this.fullObject?.teamMembers("pharma", "owner");
            const foundOwner = !!owners?.find(
                (tm: TeamMember) => tm.account.id == assignee.id,
            );
            const same = foundOwner || (!assignee && !owners?.length);
            if (!same) {
                // we're changing the owner - we need to remove all current owners from the ad-hoc team (overrides)
                const team = this.fullObject?.team("pharma");
                if (team) {
                    let found = false;
                    team.overrides.forEach((tm: TeamMember) => {
                        tm.role = tm.role
                            .split("|")
                            .filter((role: string) => role != "owner")
                            .join("|");

                        // if the new assignee has an ad-hoc role, add 'owner' to it
                        if (tm.account.id == assignee.id && tm.role != "") {
                            tm.role += "|owner";
                            found = true;
                        }
                    });
                    team.overrides = team.overrides.filter(
                        (tm: TeamMember) => tm.role != "",
                    );
                    // then add the new assignee as an owner
                    if (!found) {
                        const member = ObjectFactory.makeObject<TeamMember>(
                            {
                                override: team.asReference,
                                account: assignee,
                                role: "owner",
                                private: false,
                            },
                            TeamMember.object_type,
                        ) as TeamMember;
                        if (member) team.overrides.push(member);
                    }
                    this.caseTeamService.update(team).subscribe(() => {
                        this.updateAssignee();
                        this.snackbar.open(
                            "Assignment updated successfully",
                            undefined,
                            { duration: 5000 },
                        );
                    });
                }
            }
        });
    }

    get contacts(): TeamMember[] {
        return (
            this.fullObject?.teamMembers().filter((tm: TeamMember) => !tm.private) ?? []
        );
    }
    get physician(): TeamMember | undefined {
        return this.fullObject?.teamMember("provider", "physician");
    }

    get patientInstitution(): string {
        return this.fullObject?.physicianInstitution ?? "Institution not Specified";
    }

    get availableAssignees(): ObjectReference[] {
        return this.staffTeam?.members?.map((tm: TeamMember) => tm.account) ?? [];
    }
    assignToMe(event: MouseEvent): void {
        this.assignee.setValue(this.currentAccount?.asReference);
    }
    unAssign() {
        const team = this.fullObject?.team("pharma");
        if (team) {
            team.overrides = [];
            this.caseTeamService.update(team).subscribe(() => {
                this.updateAssignee();
                this.snackbar.open("Assignment updated successfully", undefined, {
                    duration: 5000,
                });
            });
        }
    }

    changeStatus(_event: MouseEvent, status: Status) {
        if (status.id != this.fullObject?.case_status?.id) {
            const inquiry = this.fullObject;
            if (!status?.attributes.closes_case) {
                this.service.patch(inquiry!, { case_status: status }).subscribe();
            } else {
                // dont terminal event so the menu stops showing.
                this.closeCase(undefined, status);
            }
        }
    }

    get isAssigned() {
        return this.assignee.value !== undefined;
    }
    get isAssignedToMe(): boolean {
        const assignee = this.assignee.value as ObjectReference;
        return assignee?.id == this.currentAccount?.id;
    }
    assignee: UntypedFormControl = new UntypedFormControl();

    get physicianName(): string {
        return this.physician?.displayName ?? "Physician not Specified";
    }

    get isInquiryActive() {
        return !this.fullObject?.case_status?.attributes.closes_case;
    }
    get isInquiryClosed(): boolean {
        return !this.isInquiryActive;
    }
    get isPharmaStaff(): boolean {
        return !!this.fullObject?.isMemberOfTeam(this.currentAccount, "pharma");
    }

    // JT (10 Sep 2022) Removed the contact functions as they haven't been used since v2 and we're going to implement them differently if they come back

    spamTooltip: string =
        "This action will mark the inquiry status as 'Spam' and remove it from future searches.";
    markAsSpam(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        setTimeout(() => {
            this.formGroup.controls.status.setValue("spam");
            this.formGroup.controls.status.markAsDirty();
        });
    }

    // JT (10 Sep 2022) Removed toggleMessage and showMessageAsHTML - this functionality moved to the communications component long ago

    responseTooltip: string =
        "This action will allow you to send a quick response from one of your available templates to the sender of the inquiry";

    @ViewChild(CommunicationComponent)
    communicationComponent?: CommunicationComponent;
    quickResponse(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.viewOnly) return;

        const owners = [this.fullObject?.program?.id, this.fullObject?.organization?.id]
            .filter((val?: string) => !!val)
            .join(",");

        const messages = this?.communicationComponent?.messages;
        if (!messages?.length) return;

        const sortedMessages = messages.sort((a: Message, b: Message) => {
            const aDate = a.sent ?? new Date();
            const bDate = b.sent ?? new Date();
            if (aDate < bDate) return 1;
            if (aDate == bDate) return 0;
            return -1;
        });
        let subject = sortedMessages?.length ? sortedMessages[0].subject : undefined;
        if (!!subject && !subject.toLowerCase().startsWith("re:"))
            subject = "Re: " + subject;

        let sources: DocumentRepository[] = [];
        if (this.currentAccount)
            sources = [...sources, this.currentAccount.asReference];
        if (this.fullObject?.organization)
            sources = [...sources, this.fullObject.organization];
        if (this.fullObject?.program) sources = [...sources, this.fullObject.program];

        this.dialog
            .open(SendTemplateDialog, {
                data: {
                    to: this.physician,
                    subject: subject,
                    owner: owners,
                    context: this.fullObject?.data,
                    reference: this.object?.asReference,
                    sources: sources,
                    repository: this.object,
                    uploadOwner: this.fullObject?.organization,
                    contacts: this.contacts,
                    send_notification: false,
                },
                minWidth: 600,
                disableClose: true,
                hasBackdrop: true,
            })
            .afterClosed()
            .subscribe((message?: Message) => {
                if (message) {
                    this.formGroup.controls.status.setValue("response");
                    this.formGroup.controls.status.markAsDirty();
                }
            });
    }

    get canCreateCase(): boolean {
        return (
            !!this.formGroup.controls.program.value && !this.viewOnly && this.allowCases
        );
    }
    caseTooltip: string = "";
    setCaseToolTip(): void {
        if (!this.allowCases)
            this.caseTooltip =
                "Please contact your sales representative to enable this functionality.";
        else
            this.caseTooltip =
                "This action will convert the inquiry into a case and invite the sender of the inquiry to access the system.";
    }

    get viewOnly() {
        return !!this.currentAccount?.isViewOnlyOnOrg(this.fullObject?.organization);
    }
    createCase(event: MouseEvent): void {
        this.terminateEvent(event);
        if (!this.allowCases) {
            this.dialog.open(ConfirmDialog, {
                data: {
                    message:
                        "Please contact your sales representative to enable this functionality.",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            });
        }
        if (!this.canCreateCase) return;
        const ref = this.dialog.open(CaseComponent, {
            minWidth: 480,
            disableClose: true,
            hasBackdrop: true,
        });
        ref.componentInstance.dialogReference = ref;
        ref.componentInstance.mode = ObjectViewMode.Create;
        ref.componentInstance.autosave = false;
        ref.componentInstance.autosaveOnCreate = true;
        ref.componentInstance.staffTeam = this.staffTeam;
        ref.componentInstance.object = ObjectFactory.makeObject<Case>(
            {
                shared: this.fullObject,
                owner: this.fullObject?.organization,
            },
            Case.object_type,
        );
        ref.afterClosed().subscribe((c: Case) => {
            if (c) {
                this.formGroup.controls.status.setValue("case");
                this.formGroup.controls.status.markAsDirty();
                ObjectAdminComponent.showObject<Case>(
                    c,
                    CaseComponent,
                    ObjectViewMode.Edit,
                );
            }
        });
    }

    downloadAttachment(event: MouseEvent, attachment: ObjectReference): void {
        this.terminateEvent(event);
        this.documentService.download(attachment);
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            program: [null],
            status: [null],
        });
    }
    caseStatuses: Status[] = [];

    protected setObject(v?: Inquiry) {
        const hasOrgChanged =
            this?.fullObject?.organization?.id !== v?.organization?.id &&
            v?.organization?.id;
        super.setObject(v);
        if (v?.id) this.getUnReadNotifications();

        this.updateSelectedProgram();
        this.updateOrganizationProperties();
        this.updateProductOptions();
        this.updateAvailableAssignees();
        this.openApiSubscriptions.push(this.api.subscribe(v!.asReference));

        /// prevent multiple requests for same program
        if (hasOrgChanged) {
            this.updateAvailablePrograms();
        }
        if (this.fullObject?.organization?.id) {
            this.getStatuses();
        }
        this.api.relatedObjectEvent
            .pipe(
                filter(
                    (event: RelatedObjectEvent<any, Inquiry>) =>
                        event.relation?.id == this.object?.id &&
                        event.relation?.type == this.object?.type,
                ),
            )
            .subscribe((event: RelatedObjectEvent<any, Inquiry>) =>
                this.handleRelatedEvent(event.action, event.relatedObject),
            );
    }

    openDocument(document: Document) {
        const data = {
            document,
            upload: false,
        };
        console.log(document);
        this.tabGroupService.changeTab(CASE_TAB_NAMES.DOCUMENTS, data);
    }

    handleRelatedEvent(action: WebsocketObjectAction, related: APIObject): void {
        switch (related.type) {
            case Document.object_type:
                break;
            case AppNotification.object_type:
                this.handleAppNotificationEvent(action, related as AppNotification);
                break;
            case Message.object_type:
                break;
            case CaseTeam.object_type:
                this.updateAssignee();
                break;
            default:
                console.log("Unhandled related event: " + related.type);
        }
    }

    handleAppNotificationEvent(
        action: WebsocketObjectAction,
        notification: AppNotification,
    ) {
        if (notification.account.id !== this?.currentAccount?.id) {
            return;
        }

        let index = this.notifications.findIndex((obj) => obj.id === notification?.id);

        if (action === WebsocketObjectAction.CREATE && index === -1) {
            this.notifications.push(notification);
        } else if (action === WebsocketObjectAction.UPDATE && index !== -1) {
            this.notifications[index] = notification;
        }

        this.handleNotifications();
    }

    protected precommitTransform(v: any): any {
        if (v.program) v.program = v.program.asReference;
        else v.program = null;
        return super.precommitTransform(v);
    }

    get caseStatus() {
        return this.fullObject?.case_status;
    }

    protected updateSelectedProgram(): void {
        if (this.object)
            this.formGroup.controls.program.setValue(
                this.availablePrograms.find(
                    (program: Program) => program?.id == this.fullObject?.program?.id,
                ),
            );
    }
    protected updateAvailablePrograms(): void {
        if (this?.fullObject?.organization?.id) {
            this.programService
                .list({
                    organization: this.fullObject?.organization?.id || "0",
                    exclude_fields: ["roles"].join(","),
                })
                .pipe(map((results: APIListResult<Program>) => results as Program[]))
                .subscribe((results: Program[]) => {
                    this.availablePrograms = results;
                    this.updateSelectedProgram();
                });
        } else {
            setTimeout(() => (this.availablePrograms = []));
        }
    }
    protected updateOrganizationProperties(): void {
        if (this.fullObject?.organization?.id) {
            this.organizationService
                .retrieve(this.fullObject.organization.id)
                .subscribe((org: Organization | undefined) => {
                    this.organization = org;
                    this.dataFieldDefinitions = org?.settings?.data_types ?? [];
                    this.allowCases =
                        this.organization?.isEntitlementEnabled("case", true) ?? false;
                    this.setCaseToolTip();
                });
        } else {
            this.organization = undefined;
            this.allowCases = false;
            this.setCaseToolTip();
        }
    }

    protected updateProductOptions(): void {
        if (this.fullObject?.organization?.id)
            this.programService
                .list({
                    organization: this.fullObject.organization.id || "0",
                    exclude_fields: ["roles"].join(","),
                })
                .subscribe(
                    (progs: APIListResult<Program>) =>
                        (this.productOptions = progs as Program[]),
                );
        else {
            this.productOptions = [];
        }
    }
    protected updateAvailableAssignees(): void {
        if (this.isPharmaStaff) {
            this.teamService
                .list({
                    organization: this.fullObject?.organization?.id ?? "0",
                    type: "staff",
                })
                .subscribe((teams_: APIListResult<Team>) => {
                    const teams = teams_ as Team[];
                    this.staffTeam = teams.length ? teams[0] : undefined;
                    this.updateAssignee();
                });
        }
    }
    protected updateAssignee(): void {
        const owner = this.fullObject?.teamMember("pharma", "owner");
        const assignee = this.staffTeam?.members?.find(
            (tm: TeamMember) => tm.account.id == owner?.account.id,
        );
        this.assignee.setValue(assignee?.account);
    }

    protected onCurrentAccountChanged(a: Account | undefined): void {
        super.onCurrentAccountChanged(a);
    }

    closeTooltip: string =
        "This action will close the inquiry and allow you indicate a reason.";
    closeCase(event: MouseEvent | undefined, status?: Status) {
        this.terminateEvent(event);

        if (this.viewOnly) return;

        this.service.close(
            this.fullObject!,
            true,
            status,
            this.caseStatuses?.filter((s) => s?.attributes?.closes_case),
        );
    }

    get inquiryFilter() {
        return {
            owned: this.fullObject?.id,
            is_template: "False",
            version: "1",
        };
    }

    get documentSources(): DocumentRepository[] {
        let sources = this.currentAccount ? [this.currentAccount.asReference] : [];
        if (this.fullObject) sources = [...sources, this.fullObject.asReference];

        if (this.isPharmaStaff) {
            if (this.fullObject?.organization)
                sources = [...sources, this.fullObject.organization];
            if (this.fullObject?.program)
                sources = [...sources, this.fullObject.program];
        }

        return sources;
    }
    exportAsPdf(event: MouseEvent) {
        this.terminateEvent(event);

        const date = this.datePipe
            .transform(new Date(), "mediumDate")
            ?.replaceAll(", ", "_")
            .replaceAll(" ", "_");

        const ids = this.summary!.dataForms!.map((df) => df.id!);
        const reference = this?.fullObject?.reference_identifier;
        const caseName =
            this?.fullObject?.displayName?.replaceAll(" ", "_") ?? "unnamed";
        const fileName = `${reference}_${caseName}_Summary_Forms_${date}.pdf`;
        this.dataFormService.exportAsPdf(fileName, ids, `Case ${reference} Summary`);
    }

    getStatuses() {
        this.caseStatusService
            .getAvailableInquiryStatus({
                owner_id: `${this.fullObject?.organization?.id},0`,
            })
            .subscribe((statuses) => {
                const { availableStatuses, systemProvidedStatuses } = statuses;

                if (!availableStatuses.length) {
                    //When no statuses exist, we should display system generated statuses
                    this.caseStatuses = systemProvidedStatuses;
                } else {
                    this.caseStatuses = availableStatuses;
                }
            });
    }

    _notifications: AppNotification[] = [];
    get notifications() {
        return this._notifications;
    }
    set notifications(v: AppNotification[]) {
        this._notifications = v;
        this.handleNotifications();
    }
    getUnReadNotifications() {
        //get all of the current users un read notifications for the inquiry
        //should only do this once, any new/updates should be handled via websockets
        this.appNotificationService
            .list({
                account: this?.currentAccount?.id || "0",
                repo: `${this?.fullObject?.id}` || "0",
                is_read: "false",
            })
            .subscribe((v) => {
                this.notifications = v as AppNotification[];
            });
    }

    documentNotifications: AppNotification[] = [];
    messageNotifications: AppNotification[] = [];
    handleNotifications() {
        this.documentNotifications = this.notifications.filter((notification) => {
            return (
                notification?.object?.type === "program.document" &&
                !notification?.is_read
            );
        });
        this.messageNotifications = this.notifications.filter((notification) => {
            return (
                notification?.object?.type === "notifications.message" &&
                !notification?.is_read
            );
        });
    }
    matBadge(notifiations: AppNotification[]) {
        if (!notifiations.length) return undefined;

        return notifiations.length > AppNotification.maxNotifications ?
                `${AppNotification.maxNotifications}+`
            :   `${notifiations.length}`;
    }
}
