import { TeamMemberService } from "./../../../services/program.services";
import { AccountService } from "./../../../services/iam.services";
import { Component, Inject, inject } from "@angular/core";
import { Role, RoleDefinition } from "../../../services/models/role";
import { ObjectComponent } from "../../../common/components/object.component";
import { Assignment } from "../../../services/models/assignment";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Case } from "../../../services/models/case";
import { AssignmentService } from "../../../services/program.services";
import { Account } from "src/services/models/account";
import { EmailValidator } from "src/common/utilities/validators";
import { CaseTeam, TeamMember } from "src/services/models/team";
import { mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { ObjectFactory } from "src/services/models/api-object";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "delegate-assignment",
    templateUrl: "./delegate-assignment.dialog.html",
    styleUrls: ["./assignment.component.scss"],
})
export class DelegateAssignmentDialog extends ObjectComponent<Assignment> {
    protected case?: Case;
    protected accountService: AccountService;
    protected teamMemberService: TeamMemberService;

    taskDescription = "";
    newAccount?: TeamMember = ObjectFactory.makeObject<TeamMember>(
        {
            account: ObjectFactory.makeObject<Account>(
                {
                    first_name: "New",
                    last_name: "User",
                },
                Account.object_type,
            ) as Account,
            email: undefined,
        },
        TeamMember.object_type,
    ) as TeamMember;

    get caseTeam(): CaseTeam | undefined {
        return this.data.caseTeam;
    }
    get isPharmaStaff(): boolean {
        return this.caseTeam?.capacity == "pharma";
    }
    get availableTeams(): CaseTeam[] {
        return this.data?.case?.teams;
    }
    get availableRoles(): RoleDefinition[] {
        return TeamMember.DefinedRoles.filter((rd: RoleDefinition) => {
            return (
                rd.type == "system" ||
                (!this.isExternal && rd.type == this.caseTeam?.capacity) ||
                (this.isExternal && rd.type != this.caseTeam?.capacity)
            );
        }).filter((rd: RoleDefinition) => rd.value != "admin" && rd.value != "owner");
    }
    get isExternal(): boolean {
        return this.formGroup.controls.override.value?.id != this.caseTeam?.id;
    }
    get availableAssignees(): TeamMember[] {
        // this will allow all members from teams that the currentAccount is a member of, but exclude private members from teams that they are not
        return ([] as TeamMember[]).concat.apply(
            [],
            this.availableTeams.map((ct: CaseTeam) => {
                const isMember = !!ct.members.find(
                    (tm: TeamMember) => tm.account.id == this.currentAccount?.id,
                );
                return ct.members.filter((tm: TeamMember) => isMember || !tm.private);
            }) || [],
        );
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<DelegateAssignmentDialog>,
        protected service: AssignmentService,
    ) {
        super(service);
        this.session.onLogout.subscribe(() => this.dialogRef.close());
        this.accountService = inject(AccountService);
        this.teamMemberService = inject(TeamMemberService);

        if (data?.case) {
            this.case = data.case;
        }
        this.taskDescription = data.object.task.description;

        this.formGroup.controls.member.valueChanges.subscribe((value: TeamMember) => {
            if (value === this.newAccount) {
                this.formGroup.controls.email.setValidators([
                    EmailValidator,
                    Validators.required,
                ]);
                this.formGroup.controls.first_name.setValidators(Validators.required);
                this.formGroup.controls.last_name.setValidators(Validators.required);
                this.formGroup.controls.role.setValidators(Validators.required);
            } else {
                this.formGroup.controls.email.setValidators([]);
                this.formGroup.controls.first_name.setValidators([]);
                this.formGroup.controls.last_name.setValidators([]);
                this.formGroup.controls.role.setValidators(
                    /*this.isProgramStaff ?*/ [] /*: Validators.required*/,
                );
            }
            this.formGroup.controls.email.updateValueAndValidity();
            this.formGroup.controls.first_name.updateValueAndValidity();
            this.formGroup.controls.last_name.updateValueAndValidity();
            this.formGroup.controls.role.updateValueAndValidity();
        });

        this.object = data.object;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            member: [null],
            override: [null, Validators.required],
            email: [null, EmailValidator],
            first_name: [null],
            last_name: [null],
            role: [null],
        });
    }
    get assignee(): TeamMember {
        return this.formGroup.controls.member.value;
    }
    get email(): UntypedFormControl {
        return this.formGroup.controls.email as UntypedFormControl;
    }

    teamForMember(member: TeamMember): CaseTeam | undefined {
        return this.availableTeams.find(
            (ct: CaseTeam) =>
                !!ct.members.find(
                    (tm: TeamMember) => tm.account.id == member.account.id,
                ),
        );
    }
    organizationForMember(member: TeamMember): string {
        return (
            this.teamForMember(member)?.team.organization.displayName ??
            "Unknown Institution"
        );
    }
    roleDisplayForMember(member: TeamMember): string {
        const value = [];
        const roles = member.role.split("|").filter((r: string) => r != "member");
        for (const r of roles) {
            const definition = TeamMember.DefinedRoles.find(
                (rd: RoleDefinition) => rd.value == r,
            );
            if (definition) value.push(definition.display);
        }
        return value.join(", ");
    }
    memberContext(member: TeamMember): string {
        const context = this.organizationForMember(member);
        const roles = this.roleDisplayForMember(member);
        if (roles != "") return context + " (" + roles + ")";
        return context;
    }

    protected setObject(v?: Assignment | undefined): void {
        super.setObject(v);
        if (v?.assignee) {
            const member = this.case?.isMemberOfTeam(v?.assignee);
            this.formGroup.controls.member.setValue(member);
        } else {
            this.formGroup.controls.member.setValue(undefined);
        }
        this.formGroup.controls.override.setValue(this.caseTeam);
        this.formGroup.controls.role.setValue("member");
    }

    protected precommitTransform(v: any): any {
        v["assignee"] = v["member"].account;
        return v;
    }
    protected onCommitSuccess(v: Assignment): boolean {
        this.dialogRef.close(v);
        return super.onCommitSuccess(v);
    }

    onSave() {
        this.committing = true;
        if (this.formGroup.controls.member.value == this.newAccount) {
            const account = ObjectFactory.makeObject<Account>(
                {
                    email: this.formGroup.controls.email.value,
                    first_name: this.formGroup.controls.first_name.value,
                    last_name: this.formGroup.controls.last_name.value,
                    send_invite: !this.isExternal,
                },
                Account.object_type,
            );
            const obs = account ? this.accountService.create(account) : of(undefined);
            obs.pipe(
                mergeMap((account: Account | undefined) => {
                    if (account) {
                        const member = ObjectFactory.makeObject<TeamMember>(
                            {
                                override: this.formGroup.controls.override.value,
                                account: account,
                                role: this.formGroup.controls.role.value,
                                permission: ObjectFactory.makeObject<Role>(
                                    {
                                        object: this.caseTeam?.case,
                                        account: account,
                                        role: "object.edit",
                                        private: false,
                                    },
                                    Role.object_type,
                                ),
                                private: false,
                            },
                            TeamMember.object_type,
                        );
                        return member ?
                                this.teamMemberService.create(member)
                            :   of(undefined);
                    } else {
                        // error creating account
                        return of(undefined);
                    }
                }),
            ).subscribe((member: TeamMember | undefined) => {
                if (member) {
                    const case_team = this.availableTeams.find(
                        (ct: CaseTeam) => ct.id == member.override?.id,
                    );
                    if (case_team)
                        case_team.overrides = [...case_team.overrides, member];
                    this.formGroup.controls.member.setValue(member);
                    if (this.object instanceof Assignment) {
                        this.object.assignee = member.account;
                        this.committing = false;
                        this.dialogRef.close(this.object);
                        this.service.update(this.object).subscribe();
                    } else super.onSave();
                } else {
                    // error creating team member
                    console.log("Unable to create Team Member");
                }
            });
        } else if (this.object instanceof Assignment) {
            this.object.assignee =
                this.formGroup.controls.member.value ?
                    this.formGroup.controls.member.value.account
                :   undefined;
            this.committing = false;
            this.dialogRef.close(this.object);
            this.service.update(this.object).subscribe();
        }
    }
}
