import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { SearchableListComponent } from "src/common/components/searchable-list.component";
import { RequestFilter } from "src/common/utilities/request";
import { CapabilityService } from "src/services/iam.services";
import { Capability } from "src/services/models/capability";
import { Organization } from "src/services/models/organization";
import { CapabilityComponent } from "./capability.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "capability-admin",
    templateUrl: "./capability-admin.component.html",
    styleUrls: ["../organization.component.scss"],
})
export class CapabilityAdminComponent extends SearchableListComponent<Capability> {
    objectView = CapabilityComponent;
    @Input() organization?: Organization;
    @Input() isPharma = false;
    constructor(
        protected service: CapabilityService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "capability-list");
    }

    get displayedColumns(): string[] {
        return ["name", "actions"];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["org"] = this.organization?.id ?? "-1";

        return filters;
    }

    protected objectDialogConfiguration(
        object: Capability,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);

        return {
            ...config,
            width: "50%",
        };
    }

    newObject(data?: any): Capability | undefined {
        const obj = super.newObject(data);
        if (!obj) {
            return undefined;
        }

        obj.owner = this.organization?.asReference;
        obj.capacity = this.isPharma ? "pharma" : "physician";
        return obj;
    }
}
