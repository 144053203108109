import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppRoutes } from "./app.routes";
import { AppComponent } from "./components/app/app.component";
import { IAMModule } from "../iam/iam.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { HomeComponent } from "./routes/home/home.component";
import { CommonModule as EAPCommonModule } from "../common/common.module";
import { AdminModule } from "../admin/admin.module";
import { ProgramModule } from "../program/program.module";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { CdkScrollableModule } from "@angular/cdk/scrolling";
import { NgxEchartsModule } from "ngx-echarts";
import { ReactiveFormsModule } from "@angular/forms";
import { DocusignAuthorizationComponent } from "../integration/docusign/authorization.component";
import { DocusignEnvelopeStatusComponent } from "../integration/docusign/envelope-status.component";
import { ServicesModule } from "../services/services.module";
import { IntakeModule } from "../intake/intake.module";
import { EnvironmentService } from "./environment.service";
import { CookieService } from "ngx-cookie-service";
import { AuthClientConfig, AuthModule } from "@auth0/auth0-angular";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatSidenavModule } from "@angular/material/sidenav";
import { NotificationsModule } from "src/notifications/notifications.module";

const initializeApplication =
    (environment: EnvironmentService, config: AuthClientConfig) => () => {
        return new Promise<void>((resolve) => {
            environment.load().then(() => {
                const settings = {
                    domain: environment.auth0Domain,
                    clientId: environment.auth0Client,
                    authorizationParams: {
                        redirect_uri: [window.location.origin, "login-auth"].join("/"),
                        audience: environment.auth0Audience,
                    },
                };
                config.set(settings);
                resolve();
            });
        });
    };

@NgModule({
    // ...

    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutes,
        IAMModule,
        EAPCommonModule,
        AdminModule,
        ProgramModule,
        IntakeModule,
        CdkScrollableModule,
        MatButtonModule,
        NgxEchartsModule.forRoot({ echarts: () => import("echarts") }),
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        ReactiveFormsModule,
        ServicesModule,
        AuthModule.forRoot(),
        MatSidenavModule,
        NotificationsModule,
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        DashboardComponent,
        DocusignAuthorizationComponent,
        DocusignEnvelopeStatusComponent,
    ],
    providers: [
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApplication,
            deps: [EnvironmentService, AuthClientConfig],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
