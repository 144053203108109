import { ObjectViewMode } from "./../../object.component";
import { Component, Input } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { CompoundDataType, DataForm, DataType } from "src/services/models/data";

@Component({
    selector: "dt-compound",
    template: `
        <data-type
            class="compound-property"
            *ngFor="let compound of fields; index as i"
            [formGroup]="formGroup"
            [mode]="mode"
            [form]="form"
            [label]="compoundLabel(compound)"
            [type]="compoundType(compound)"
            [inline]="compoundInline(compound)"
            [control]="compoundControl(compound)"
            [attributes]="compoundAttributes(compound)"
            [required]="compoundRequired(compound)"
            [options]="compoundOptions(compound)"
            [compoundIndex]="i"
        ></data-type>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeCompound {
    @Input() mode: ObjectViewMode = ObjectViewMode.View;
    @Input() fields: CompoundDataType[] = [];
    @Input() formGroup!: UntypedFormGroup;
    @Input() attributes?: any;
    @Input() required: boolean = false;
    @Input() inline: boolean = false;
    @Input() form?: DataForm;
    compoundLabel(compound: CompoundDataType): string {
        return (
            this.compoundAttributes(compound)?.label ||
            this.compoundAttributes(compound)?.placeholder ||
            compound.display_name
        );
    }
    compoundType(compound: CompoundDataType): DataType {
        return compound.child;
    }
    compoundInline(compound: CompoundDataType): boolean {
        return (
            this.compoundAttributes(compound)?.layout?.compound == "inline" ||
            this.inline
        );
    }
    compoundControl(compound: CompoundDataType): AbstractControl | undefined {
        return this.formGroup?.controls[compound.name!];
    }
    compoundAttributes(compound: CompoundDataType): any {
        return this.attributes ? this.attributes[compound.name!] : {};
    }
    compoundRequired(compound: CompoundDataType): any {
        return this.required && compound.required;
    }
    compoundOptions(compound: CompoundDataType): any[] {
        return compound.child.lookup ?? [];
    }
}
