import { LoginComponent } from "./components/login.component";
import { AuthorizationComponent } from "./components/authorization.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule as EAPCommonModule } from "../common/common.module";
import { ServicesModule } from "../services/services.module";
import { AuthenticatedFrameComponent } from "./components/frames/authenticated/authenticated.frame.component";
import { AuthenticationRedirectGuard } from "./guards/redirect.guard";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NotificationsModule } from "../notifications/notifications.module";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSidenavModule } from "@angular/material/sidenav";

@NgModule({
    declarations: [AuthenticatedFrameComponent, AuthorizationComponent, LoginComponent],
    exports: [AuthenticatedFrameComponent],
    providers: [AuthenticationRedirectGuard],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ScrollingModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressBarModule,
        MatSnackBarModule,
        EAPCommonModule,
        ServicesModule,
        NotificationsModule,
        MatBadgeModule,
        MatSidenavModule,
    ],
})
export class IAMModule {}
