import {
    APIListResult,
    APIObject,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import { SessionComponent } from "src/services/components/session.component";
import { Component, Input, inject } from "@angular/core";
import { Audit } from "src/services/models/audit";
import { AuditService } from "src/services/iam.services";
import { Case } from "src/services/models/case";

@Component({
    selector: "audit-trail",
    templateUrl: "./audit-trail.component.html",
    styleUrls: ["./audit-trail.component.scss"],
})
export class AuditTrailComponent extends SessionComponent {
    auditTrail: Audit[] = [];
    auditService: AuditService;

    protected _object: OptionalObjectOrReference<APIObject>;
    @Input() set object(v: OptionalObjectOrReference<APIObject>) {
        this._object = v;
        this.updateAuditTrail(v);
    }

    constructor() {
        super();
        this.auditService = inject(AuditService);
    }

    protected updateAuditTrail(v: OptionalObjectOrReference<APIObject>): void {
        if (v) {
            const auditObjects = [v.id];
            if (v instanceof Case) auditObjects.push(v.shared.id);
            this.auditService
                .list({ object: auditObjects.join(",") })
                .subscribe(
                    (objects: APIListResult<Audit>) =>
                        (this.auditTrail = objects as Audit[]),
                );
        } else this.auditTrail = [];
    }
}
