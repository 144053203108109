<div class="container">
    <div class="flex between comm-header">
        <div class="title" [id]="list.listTitle">Messages</div>
        <div class="between">
            <mat-form-field color="primary" *ngIf="showSearch" class="search-input">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input
                    matInput
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="!showSearch"
                (click)="showSearch = !showSearch"
                matTooltip="Search Messages"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                [disabled]="viewOnly"
                class="brand-medium"
                (click)="sendMessage($event)"
            >
                <mat-icon>open_in_new</mat-icon>
                <span class="add-button-text">New Message</span>
            </button>
        </div>
    </div>
    <mat-table [dataSource]="messages">
        <ng-container matColumnDef="to">
            <mat-header-cell *matHeaderCellDef>To</mat-header-cell>
            <mat-cell
                *matCellDef="let message"
                [ngClass]="{ bold: isToday(message.sent) }"
                (click)="toggleMessage(message)"
            >
                <div
                    class="clickable"
                    [matBadge]="matBadge(message)"
                    matBadgeColor="accent"
                    matBadgeOverlap="false"
                    matBadgePosition="before"
                    [matTooltip]="getNotificationForMessage(message)?.message || ''"
                    style="padding-left: 0.5rem"
                >
                    {{ getTargetNames(message) }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="from">
            <mat-header-cell *matHeaderCellDef>From</mat-header-cell>
            <mat-cell
                *matCellDef="let message"
                [ngClass]="{ bold: isToday(message.sent) }"
                (click)="toggleMessage(message)"
            >
                {{ message.sender.account!.displayName }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
            <mat-cell
                *matCellDef="let message"
                [ngClass]="{ bold: isToday(message.sent) }"
                (click)="toggleMessage(message)"
            >
                {{ message.subject }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type" *ngIf="false">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let message" (click)="toggleMessage(message)">
                <mat-chip-set>
                    <mat-chip
                        *ngIf="isMessageType(message) && !isToDoType(message)"
                        color="primary"
                        selected
                    >
                        Message
                    </mat-chip>
                    <mat-chip
                        class="todo-chip"
                        *ngIf="isToDoType(message) && !isMessageType(message)"
                        color="secondary"
                    >
                        To-Do
                    </mat-chip>
                </mat-chip-set>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
            <mat-cell
                *matCellDef="let message"
                [ngClass]="{ bold: isToday(message.sent) }"
                (click)="toggleMessage(message)"
            >
                {{
                    isToday(message.sent) ?
                        (message.sent | date: "shortTime")
                    :   (message.sent | localizedDate)
                }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let message" (click)="toggleMessage(message)">
                <mat-checkbox *ngIf="isToDoType(message)"></mat-checkbox>
            </mat-cell>
        </ng-container>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-message="message">
                <button mat-menu-item (click)="toggleMessage(message)">View</button>
                <button
                    *ngIf="hasNotification(message)"
                    mat-menu-item
                    (click)="markNotificationAsRead(message)"
                >
                    Mark Read
                </button>
            </ng-template>
        </mat-menu>

        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="list?.searching && !list.hasLoaded">
                    <mat-progress-spinner
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                    Loading messages...
                </span>
                <span *ngIf="!list?.searching && !list.list.items.length">
                    No messages found.
                </span>
            </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let message" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ message: message }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
    <div
        class="wrapper flexible message-display flex scrollable no-padding"
        style="height: auto"
        cdkScrollable
        *ngIf="!!displayMessage"
    >
        <div class="message-wrapper flexible flex stack">
            <div class="message-info flex stack">
                <div class="message-from bold flex">
                    <div>{{ displayMessage.sender.displayName }}</div>
                </div>
                <div class="message-subject flex">{{ displayMessage.subject }}</div>
                <div class="message-to small flex">
                    To:&nbsp;
                    <div>
                        <span *ngFor="let target of displayMessage.targets; index as i">
                            {{ target.displayName
                            }}{{ i < displayMessage.targets.length - 1 ? "; " : "" }}
                        </span>
                    </div>
                    <div class="spacer"></div>
                    <div>{{ displayMessage.sent | localizedDate }}</div>
                </div>
            </div>
            <quill-view-html
                class="message-text full"
                [content]="displayMessage.message"
            ></quill-view-html>

            <div
                class="attachments flex wrap"
                *ngIf="!!displayMessage.attachments.length"
            >
                <div
                    class="attachment"
                    *ngFor="let attachment of displayMessage.attachments"
                >
                    <mat-icon class="secondary inline">attachment</mat-icon>
                    <a href="#" (click)="downloadAttachment($event, attachment)">
                        {{ attachment.displayName }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
