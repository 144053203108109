import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: "localizedDate",
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value?: Date | null, format = "mediumDate"): string | undefined {
        let transform = undefined;
        try {
            // try using the detected language
            const culture = this.translateService.getBrowserCultureLang();
            const datePipe = new DatePipe(culture ?? "en-US");
            transform = datePipe.transform(value ?? "", format) ?? undefined;
        } catch (err) {
            if (!(value instanceof Date || value === null)) {
                //if a non date is passed in a list view (cuz its type is any), it will break it
                return "";
            }
            // if language is not supported, fallback to US english
            const datePipe = new DatePipe("en-US");
            transform = datePipe.transform(value ?? "", format) ?? undefined;
        }
        return transform;
    }
}
