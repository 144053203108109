import { Component } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";

@Component({
    selector: "dt-toggle",
    template: `
        <mat-slide-toggle class="dt-slide slide-toggle-padding" [formControl]="control">
            {{ instructions || label }}
        </mat-slide-toggle>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeToggle extends BaseDataTypeComponent {}
