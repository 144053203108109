import { inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { SessionService } from "../../services/session.service";
import { filter, map, take } from "rxjs/operators";

export const authGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const router = inject(Router);
    const session = inject(SessionService);
    return session.initialized.pipe(
        filter((initialized: boolean) => initialized),
        take(1),
        map(() => {
            if (!session.isAuthenticated) {
                return router.createUrlTree(["/login"]);
            }
            return true;
        }),
    );
};
