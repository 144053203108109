import { EnvironmentService } from "./../../app/environment.service";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from "@angular/router";

@Injectable()
export class AuthenticationRedirectGuard implements CanActivate {
    constructor(
        private router: Router,
        private environment: EnvironmentService,
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url = [this.environment.services, "accounts"].join("/") + state.url;
        window.location.href = url;
        return false;
    }
}
