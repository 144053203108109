import {
    AbstractControl,
    FormControl,
    Validators,
    UntypedFormGroup,
} from "@angular/forms";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Component, inject } from "@angular/core";
import { DataField, DataType } from "src/services/models/data";
import { Program } from "src/services/models/program";
import { debounceTime, filter, map, mergeMap } from "rxjs/operators";
import { DataFieldService, DataTypeService } from "src/services/data.services";
import { Observable } from "rxjs";
import { GenerateUniqueIdentifier } from "src/common/utilities/utilities";

@Component({
    selector: "data-field-editor",
    templateUrl: "./data-field.component.html",
    styleUrls: ["../data-admin.component.scss"],
})
export class DataFieldEditorComponent extends ObjectComponent<DataField> {
    static ExcludedDataTypes = ["instructions", "group", "select.program"];

    get repositoryType(): string {
        switch (this.fullObject?.owner?.type) {
            case "program.program":
                return "program";
            case "program.case":
                return "case";
            case "program.inquiry":
                return "case";
            case "iam.organization":
                return "organization";
            case "iam.account":
                return "account";
        }
        return "repository";
    }
    get identifierControl(): AbstractControl | null {
        return this.formGroup.get("name");
    }
    get dataTypeControl() {
        return this.formGroup.get("data_type") as FormControl;
    }

    repositories: string[] = ["0"];
    dataTypeService: DataTypeService;
    willOverride: DataField[] = [];

    availableDataTypes: DataType[] = [];

    filteredDataTypes?: Observable<DataType[]>;

    constructor(protected service: DataFieldService) {
        super(service);
        this.dataTypeService = inject(DataTypeService);
    }

    protected precommitTransform(v: any) {
        if (v.description == "") v.description = null;
        return v;
    }

    protected createObjectForm(): UntypedFormGroup {
        const fg = this.formBuilder.group({
            name: [null, Validators.required],
            display_name: [null, Validators.required],
            description: [null],
            data_type: [null, Validators.required],
            owner: [null],
        });

        // Automatically generate an identifier if we haven't manually edited the field
        fg
            .get("display_name")
            ?.valueChanges.pipe(debounceTime(100))
            .subscribe((value: string) => {
                const name = GenerateUniqueIdentifier(value, []);
                if (
                    this.mode == ObjectViewMode.Create &&
                    !this.identifierControl?.touched
                )
                    this.identifierControl?.setValue(name);
            });

        return fg;
    }

    canDelete(field: DataField): boolean {
        return (
            this.currentAccount?.hasRole("object.admin", this.fullObject?.owner) ||
            this.currentAccount?.hasRole(
                "organization.administrator",
                this.fullObject?.owner,
            ) ||
            this.currentAccount?.isSystemAdministrator ||
            (this.fullObject?.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (this.fullObject?.owner as Program).organization,
                )) ||
            (this.fullObject?.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (this.fullObject?.owner as Program).organization,
                )) ||
            false
        );
    }

    get isEditing(): boolean {
        return this.mode != ObjectViewMode.View;
    }

    ngAfterViewInit(): void {
        this.identifierControl?.valueChanges
            .pipe(
                debounceTime(400),
                filter(
                    (name: string) =>
                        !!this.fullObject && name != this.fullObject?.name,
                ),
                mergeMap((name: string) =>
                    this.service.conflicts(name, this.repositories),
                ),
            )
            .subscribe((conflicts: DataField[]) => {
                if (conflicts.length > 0) {
                    const error = conflicts.filter(
                        (type: DataField) =>
                            type.owner?.id == this.fullObject?.owner?.id,
                    );
                    this.willOverride = conflicts.filter(
                        (type: DataField) =>
                            type.owner?.id != this.fullObject?.owner?.id,
                    );
                    if (error.length)
                        this.identifierControl?.setErrors({ conflict: true });
                } else this.willOverride = [];
            });
        if (this.mode == ObjectViewMode.Edit) {
            this.formGroup.get("data_type")?.disable();
        } else if (this.mode == ObjectViewMode.Create) {
            this.formGroup.get("data_type")?.enable();
        }
    }

    protected setObject(v?: DataField | undefined): void {
        super.setObject(v);

        const repositories = ["0"];
        if (this.fullObject?.owner?.type == "program.program") {
            const org = (this.fullObject?.owner as Program)?.organization;
            if (org?.id) repositories.push(org.id);
        }
        if (this.fullObject?.owner?.id) repositories.push(this.fullObject.owner.id);

        this.repositories = repositories;
        this.objectName =
            this.mode == ObjectViewMode.Create ?
                "New Data Field"
            :   this.fullObject?.displayName ?? "Data Field";
    }

    getDataTypeObs() {
        const filter = {
            repo: this.repositories.join(","),
            exclude: DataFieldEditorComponent.ExcludedDataTypes.join(","),
        };

        return this.dataTypeService.list(filter).pipe(
            map((dataTypes) => {
                return (dataTypes as DataType[]).filter(
                    (type: DataType) =>
                        DataFieldEditorComponent.ExcludedDataTypes.indexOf(
                            type.name ?? "",
                        ) == -1,
                );
            }),
        );
    }
}
