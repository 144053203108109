import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SessionComponent } from "src/services/components/session.component";
import { Audit } from "src/services/models/audit";

@Component({
    selector: "audit-restore",
    templateUrl: "./audit-restore.component.html",
    styleUrls: ["./admin-audit.component.scss"],
})
export class AuditRestoreComponent extends SessionComponent {
    auditEntries: Audit[];

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: Audit[],
        protected dialogReference: MatDialogRef<AuditRestoreComponent>,
    ) {
        super();
        this.auditEntries = data;
    }

    state(audit: Audit): string {
        return audit.state ? JSON.stringify(audit.state, null, 4) : "";
    }
}
