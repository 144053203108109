import { Component, inject } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Observable, of } from "rxjs";
import { finalize } from "rxjs/operators";
import { DocusignToken } from "../../services/models/docusign";
import { DocusignService } from "../../services/docusign.service";
import { ObjectFactory } from "src/services/models/api-object";
import { EnvironmentService } from "src/app/environment.service";

@Component({
    selector: "docusign-auth",
    template: ``,
})
export class DocusignAuthorizationComponent {
    constructor(
        protected route: ActivatedRoute,
        protected docusign: DocusignService,
    ) {
        const environment = inject(EnvironmentService);
        const isAuth0Client = environment.auth0Client != "";
        this.route.queryParams.subscribe((params: Params) => {
            if (isAuth0Client) {
                //auth0 workflow will prevent the creation of the token
                localStorage.setItem("docusign", JSON.stringify(params));
                window.close();
            } else {
                const authorization = params["code"];
                const state = params["state"];

                inject(DocusignService); // ensure the factory is loaded

                let obs: Observable<DocusignToken | undefined> = of(undefined);
                if (state && authorization) {
                    const token = ObjectFactory.makeObject<DocusignToken>(
                        {
                            state: state,
                            authorization: authorization,
                        },
                        DocusignToken.object_type,
                    ) as DocusignToken;
                    obs = this.docusign.create(token);
                }
                obs.pipe(finalize(() => window.close())).subscribe();
            }
        });
    }
}
