import { Component, ViewChild } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Shipment } from "src/services/models/shipment";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { ShipmentService } from "src/services/shipping.services";
import { faCapsules } from "@fortawesome/free-solid-svg-icons";
import { MatStepper } from "@angular/material/stepper";
import { ObjectReference } from "src/services/models/api-object";
import { MatCheckboxChange } from "@angular/material/checkbox";

const v = Validators;
const r = v.required;

type SelectOption = { value: string; viewValue: string };
type DoseFormOptionGroup = {
    name: string;
    disabled?: boolean;
    doseFormGroup: SelectOption[];
};

@Component({
    selector: "shipment",
    templateUrl: "./shipment.component.html",
    styleUrls: ["./shipment.component.scss"],
})
export class ShipmentComponent extends ObjectComponent<Shipment> {
    objectName = "Shipment";
    ObjectViewMode = ObjectViewMode;
    drugNameIcon = faCapsules;

    hasUnitNumber: boolean = false;

    units: SelectOption[] = [
        { value: "g", viewValue: "g" },
        { value: "mg", viewValue: "mg" },
        { value: "l", viewValue: "L" },
        { value: "ml", viewValue: "mL" },
        { value: "vial", viewValue: "vial" },
    ];
    states = [
        { name: "Alabama", abbreviation: "AL" },
        { name: "Alaska", abbreviation: "AK" },
        { name: "American Samoa", abbreviation: "AS" },
        { name: "Arizona", abbreviation: "AZ" },
        { name: "Arkansas", abbreviation: "AR" },
        { name: "California", abbreviation: "CA" },
        { name: "Colorado", abbreviation: "CO" },
        { name: "Connecticut", abbreviation: "CT" },
        { name: "Delaware", abbreviation: "DE" },
        { name: "District Of Columbia", abbreviation: "DC" },
        { name: "Federated States Of Micronesia", abbreviation: "FM" },
        { name: "Florida", abbreviation: "FL" },
        { name: "Georgia", abbreviation: "GA" },
        { name: "Guam", abbreviation: "GU" },
        { name: "Hawaii", abbreviation: "HI" },
        { name: "Idaho", abbreviation: "ID" },
        { name: "Illinois", abbreviation: "IL" },
        { name: "Indiana", abbreviation: "IN" },
        { name: "Iowa", abbreviation: "IA" },
        { name: "Kansas", abbreviation: "KS" },
        { name: "Kentucky", abbreviation: "KY" },
        { name: "Louisiana", abbreviation: "LA" },
        { name: "Maine", abbreviation: "ME" },
        { name: "Marshall Islands", abbreviation: "MH" },
        { name: "Maryland", abbreviation: "MD" },
        { name: "Massachusetts", abbreviation: "MA" },
        { name: "Michigan", abbreviation: "MI" },
        { name: "Minnesota", abbreviation: "MN" },
        { name: "Mississippi", abbreviation: "MS" },
        { name: "Missouri", abbreviation: "MO" },
        { name: "Montana", abbreviation: "MT" },
        { name: "Nebraska", abbreviation: "NE" },
        { name: "Nevada", abbreviation: "NV" },
        { name: "New Hampshire", abbreviation: "NH" },
        { name: "New Jersey", abbreviation: "NJ" },
        { name: "New Mexico", abbreviation: "NM" },
        { name: "New York", abbreviation: "NY" },
        { name: "North Carolina", abbreviation: "NC" },
        { name: "North Dakota", abbreviation: "ND" },
        { name: "Northern Mariana Islands", abbreviation: "MP" },
        { name: "Ohio", abbreviation: "OH" },
        { name: "Oklahoma", abbreviation: "OK" },
        { name: "Oregon", abbreviation: "OR" },
        { name: "Palau", abbreviation: "PW" },
        { name: "Pennsylvania", abbreviation: "PA" },
        { name: "Puerto Rico", abbreviation: "PR" },
        { name: "Rhode Island", abbreviation: "RI" },
        { name: "South Carolina", abbreviation: "SC" },
        { name: "South Dakota", abbreviation: "SD" },
        { name: "Tennessee", abbreviation: "TN" },
        { name: "Texas", abbreviation: "TX" },
        { name: "Utah", abbreviation: "UT" },
        { name: "Vermont", abbreviation: "VT" },
        { name: "Virgin Islands", abbreviation: "VI" },
        { name: "Virginia", abbreviation: "VA" },
        { name: "Washington", abbreviation: "WA" },
        { name: "West Virginia", abbreviation: "WV" },
        { name: "Wisconsin", abbreviation: "WI" },
        { name: "Wyoming", abbreviation: "WY" },
    ];

    // List from: https://en.wikipedia.org/wiki/Dosage_form
    // (BB) Don't think we need to enumerate eveything, so have
    // condensed some. The main consideration is not knowing where *units*
    // make the type of product relevant/irrelevant. But it's imporant to consider there
    // may be cases where knowing the dose form could be helpful or critical.
    // *Medically* that could be important to track from end to end, since
    // any one type of drug may come in different forms, and ordering/delivering the correct
    // one could be a matter of life, death, and/or liability.
    // Will wait for specific instructions before condensing or expanding further.
    // Also note that the Wikipedia list doesn't look particularly groomed.

    doseFormOptionGroups: DoseFormOptionGroup[] = [
        {
            name: "Oral",
            doseFormGroup: [
                { value: "oral_pill", viewValue: "Pill / Tablet / Caps" },
                { value: "oral_thin_film", viewValue: "Thin Film" },
                { value: "oral_liquid", viewValue: "Liquid" },
                { value: "oral_powder", viewValue: "Powder" },
                { value: "oral_crystals_granular", viewValue: "Crystals / Granular" },
                { value: "oral_natural_herbal", viewValue: "Natural / Herbal" },
                { value: "oral_paste", viewValue: "Paste" },
                { value: "oral_buccal_film", viewValue: "Buccal Film" },
            ],
        },
        {
            name: "Ophthalmic",
            doseFormGroup: [
                { value: "ophthalmic_drops", viewValue: "Drops" },
                { value: "ophthalmic_lotion", viewValue: "(Eye) Lotion" },
                { value: "ophthalmic_solution", viewValue: "Solution" },
                { value: "ophthalmic_suspension", viewValue: "Suspension" },
                { value: "ophthalmic_ointment", viewValue: "Ointment" },
                { value: "ophthalmic_emulsion", viewValue: "Emulsion" },
            ],
        },
        {
            name: "Inhalation",
            // disabled: true, // Huh, cool feature
            doseFormGroup: [
                { value: "inhalation_aerosol", viewValue: "Aerosol / Inhaler" },
                { value: "inhalation_nebulizer", viewValue: "Nebulizer" },
                { value: "inhalation_vaporizer", viewValue: "Vaporizer" },
            ],
        },
        {
            name: "Parenteral",
            doseFormGroup: [
                { value: "parenteral_intradermal", viewValue: "Intradermal (ID)" },
                { value: "parenteral_subcutaneous", viewValue: "Subcutaneous (SC)" },
                { value: "parenteral_intramuscular", viewValue: "Intramuscular (IM)" },
                { value: "parenteral_intraosseous", viewValue: "Intraosseous (IO)" },
                {
                    value: "parenteral_intraperitoneal",
                    viewValue: "Intraperitoneal (IP)",
                },
                { value: "parenteral_intravenous", viewValue: "Intravenous (IV)" },
            ],
        },
        {
            name: "Topical",
            doseFormGroup: [
                { value: "topical_liquid", viewValue: "Liquid" },
                { value: "topical_ear_drops", viewValue: "Ear Drops (Otic)" },
                { value: "topical_dermal_patch", viewValue: "Dermal Patch" },
                { value: "topical_vaginal_rings", viewValue: "Vaginal Rings" },
                { value: "topical_powder_talc", viewValue: "Powder / Talc" },
            ],
        },
        {
            name: "Suppository",
            doseFormGroup: [
                { value: "suppository_nasal", viewValue: "Nasal" },
                { value: "suppository_rectal", viewValue: "Rectal" },
                { value: "suppository_vaginal", viewValue: "Vaginal" },
                { value: "suppository_urethral", viewValue: "Urethral" },
            ],
        },
        {
            name: "Custom",
            doseFormGroup: [{ value: "custom", viewValue: "Custom" }],
        },
    ];

    constructor(protected service: ShipmentService) {
        super(service);
    }

    hasError(controlPath: string): boolean {
        return (
            !!this.formGroup?.get(controlPath) &&
            !!this.formGroup.get(controlPath)?.hasError("required")
        );
    }

    protected fieldNameMappings: { [key: string]: string } = {
        ind: "IND Reference",
        project_code: "Project Code",
        patient: "Patient Identifier",
        lot: "Lot/Batch ID",
        lot_expiration: "Lot Expiration",
        product_name: "Product Name",
        total_quantity: "Total Quantity",
        total_unit: "Total Unit",
        dose_form: "Dose Form",
        dose_quantity: "Dose Quantity",
        dose_unit: "Dose Unit",
        custom_dose: "Custom Dose",
        company: "Company",
        address: "Address",
        shipping_conditions: "Shipping Condition",
        ship_date: "Ship Date",
        tracking_reference: "Tracking Reference",
        city: "City",
        state: "State",
        postal_code: "Postal Code",
        address1: "Address",
        address2: "Address 2",
    };

    protected createObjectForm(): UntypedFormGroup {
        const group = this.formBuilder.group({
            patientProductDetails: this.formBuilder.group({
                ind: [null, r],
                patient: [null, r],
                project_code: [null, r],
                product_name: [null, r],
                lot: [null, r],
                lot_expiration: [null, r],
            }),
            dosage: this.formBuilder.group({
                total_quantity: [0, [v.min(1), r]],
                total_unit: [null, r],
                dose_form: [null, r],
                custom_dose: [null],
                dose_quantity: [0, [v.min(1), r]],
                dose_unit: [null, r],
            }),
            shipping: this.formBuilder.group({
                address: this.formBuilder.group({
                    city: [null, r],
                    state: [null, r],
                    address1: [null, r],
                    address2: [null],
                    company: [null],
                    postal_code: [null, r], // has to be 5 digits and only numbers
                }),
                shipping_conditions: [null, r],
                ship_date: [null],
                tracking_reference: [null],
            }),
            receipt: this.formBuilder.group({
                received_date: [null],
                recipient: [null],
                receipt_conditions: [null],
                discrepancies: [null],
            }),
            verification: this.formBuilder.group({
                signoff: [null],
                signoff_by: [null],
                signoff_notes: [null],
            }),
        });

        const doseFormOption = group.controls["dosage"].get("dose_form");
        const customDoseForm = group.controls["dosage"].get("custom_dose");

        group.valueChanges.subscribe(() => {
            if (doseFormOption!.value === "custom") {
                if (customDoseForm!.disabled) {
                    customDoseForm!.enable();
                    customDoseForm!.setValidators(r);
                }
            } else if (customDoseForm!.enabled) {
                customDoseForm!.disable();
                customDoseForm!.setValidators(null);
            }
        });
        return group;
    }

    signoffChanged(event: MatCheckboxChange): void {
        const value = event.checked ? this.currentAccount : undefined;
        this.formGroup.controls["verification"].get("signoff_by")?.setValue(value);
        this.formGroup.controls["verification"].get("signoff_by")?.markAsDirty();
    }

    protected precommitTransform(v: any): any {
        v = {
            ...v.patientProductDetails,
            ...v.dosage,
            ...v.shipping,
            ...v.receipt,
            ...v.verification,
        };
        v.address = JSON.stringify(v.address);
        if (v.dose_form == "custom") v.dose_form = v.custom_dose;
        return super.precommitTransform(v);
    }

    protected resetObject(v: Shipment) {
        const value: any = {
            patientProductDetails: {
                ind: v?.ind,
                patient: v?.patient,
                project_code: v?.project_code,
                product_name: v?.product_name,
                lot: v?.lot,
                lot_expiration: v?.lot_expiration,
            },
            dosage: {
                total_quantity: v?.total_quantity,
                total_unit: v?.total_unit,
                dose_form: v?.dose_form,
                dose_quantity: v?.dose_quantity,
                dose_unit: v?.dose_unit,
            },
            shipping: {
                address: v?.address,
                shipping_conditions: v?.shipping_conditions,
                ship_date: v?.ship_date,
                tracking_reference: v?.tracking_reference,
            },
            receipt: {
                received_date: v?.received_date,
                recipient: v?.recipient,
                receipt_conditions: v?.receipt_conditions,
                discrepancies: v?.discrepancies,
            },
            verification: {
                signoff: v?.signoff,
                signoff_by: v?.signoff_by,
                signoff_notes: v?.signoff_notes,
            },
        };
        super.resetObject(value);
    }

    protected setObject(v?: Shipment): void {
        super.setObject(v);
        if (v?.address) {
            const address = JSON.parse(v.address);
            this.formGroup.controls["shipping"].get("address")?.setValue(address);
        }
        if (v?.dose_form) {
            const options = ([] as SelectOption[])
                .concat(
                    ...this.doseFormOptionGroups.map(
                        (group: DoseFormOptionGroup) => group.doseFormGroup,
                    ),
                )
                .filter((option: SelectOption) => option.value !== "custom");
            if (!options.find((option: SelectOption) => option.value === v.dose_form)) {
                this.formGroup.controls["dosage"].get("dose_form")?.setValue("custom");
                this.formGroup.controls["dosage"]
                    .get("custom_dose")
                    ?.setValue(v.dose_form);
            } else {
                this.formGroup.controls["dosage"]
                    .get("dose_form")
                    ?.setValue(v.dose_form);
                this.formGroup.controls["dosage"].get("custom_dose")?.setValue(null);
            }
        }
    }

    get formButtonText(): string {
        return this.myStepper?.selectedIndex === this.myStepper?.steps?.length - 1 ?
                "Save"
            :   "Next";
    }
    @ViewChild("stepper") private myStepper!: MatStepper;
    get signoffByAccount(): ObjectReference {
        return (this.formGroup.controls["verification"] as UntypedFormGroup).controls[
            "signoff_by"
        ].value;
    }

    goToNextFormStep(): void {
        const index = this.myStepper.selectedIndex;
        const currentstepKey = Object.keys(this.formGroup.controls)[index];
        if (this.formGroup.controls[currentstepKey].valid) {
            const nextStep = index + 1;
            if (nextStep >= this.myStepper.steps.length) {
                if (this.formGroup.valid) this?.onSave();
            } else this.myStepper.next();
        }
    }

    isFormGroupValid(name: string): boolean {
        const control = this.formGroup.controls[name];
        const keyIndex = Object.keys(this.formGroup.controls).indexOf(name);
        return (
            control?.valid ||
            !this?.myStepper?.steps.get(keyIndex)?.interacted ||
            (control?.dirty && control.touched && control?.valid)
        );
    }

    /// override this method if form is a multi step form
    /// each sub-form group will be checked for validity
    /// a valid form group will enable the next button
    protected getIsValid(): boolean {
        const index = this.myStepper.selectedIndex;
        const currentstepKey = Object.keys(this.formGroup.controls)[index];
        return this.formGroup.controls[currentstepKey].valid && !!this.object;
    }
}
