<div class="container">
    <ng-container *ngIf="displayMode === 'repo'">
        <div class="documents-header flex">
            <div class="title">Documents</div>
            <div class="spacer"></div>
            <div class="header-actions">
                <mat-form-field
                    color="primary"
                    *ngIf="!isSearchEmpty"
                    class="document-search"
                >
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <mat-label>Search Documents</mat-label>
                    <input
                        matInput
                        #search
                        placeholder="Enter at least 3 characters to search"
                        [formControl]="searchTermControl"
                        (focusout)="onFocusOut($event)"
                    />
                    <button
                        matSuffix
                        type="button"
                        mat-icon-button
                        (click)="resetSearchTerm($event)"
                        *ngIf="!!searchTermControl.value"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <button
                    mat-flat-button
                    class="brand-medium"
                    *ngIf="isSearchEmpty"
                    (click)="toggleSearch($event)"
                    matTooltip="Search Documents"
                >
                    <mat-icon>search</mat-icon>
                    Search
                </button>
                <mat-form-field
                    color="primary"
                    *ngIf="!isFilterDefault"
                    class="document-search"
                >
                    <mat-icon matPrefix class="search-icon">filter_list</mat-icon>
                    <mat-label>Filter Documents</mat-label>
                    <mat-select
                        #fitler
                        placeholder="Select one or more filters"
                        multiple
                        [formControl]="filterControl"
                    >
                        <mat-option value="deleted">Show Deleted</mat-option>
                        <mat-optgroup
                            *ngIf="repository?.type == 'program.case'"
                            label="Shared/Internal"
                        >
                            <mat-option value="shared">Shared</mat-option>
                            <mat-option value="internal">Internal</mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Document Types">
                            <mat-option value="type.all" (click)="setFilterDefault()">
                                All Types
                            </mat-option>
                            <mat-option value="type.none">Not Set</mat-option>
                            <mat-option
                                *ngFor="let type of documentTypes()"
                                [value]="'type.' + type.type"
                            >
                                {{ type.displayName }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                    <button
                        matSuffix
                        type="button"
                        mat-icon-button
                        (click)="resetFilters($event)"
                        matTooltip="Reset Filters"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <button
                    mat-flat-button
                    class="brand-medium"
                    *ngIf="isFilterDefault"
                    (click)="toggleFilter($event)"
                    matTooltip="Filter Documents"
                >
                    <mat-icon>filter_list</mat-icon>
                    Filter
                </button>
                <button
                    [disabled]="!canUpload"
                    mat-flat-button
                    class="brand-medium"
                    (click)="createObject($event, true)"
                >
                    <mat-icon>open_in_new</mat-icon>
                    Upload
                </button>
            </div>
        </div>
        <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
            <ng-container matColumnDef="badges">
                <mat-header-cell
                    *matHeaderCellDef
                    class="overflow overflow-badges"
                ></mat-header-cell>
                <mat-cell *matCellDef="let doc" class="overflow overflow-badges">
                    <ng-container *ngIf="editing != doc">
                        <mat-icon
                            *ngIf="
                                !hideRedactionWarnings &&
                                isInternalUser &&
                                redactionError(doc)
                            "
                            (click)="documentClicked($event, doc)"
                            class="error warning-space"
                            matTooltip="Error processing document for PHI.  Please contact the system administrator."
                        >
                            warning
                        </mat-icon>
                        <mat-icon
                            *ngIf="!hideRedactionWarnings && needsRedaction(doc)"
                            (click)="documentClicked($event, doc)"
                            class="error warning-space"
                            matTooltip="Potential unredacted PHI detected"
                        >
                            warning
                        </mat-icon>
                        <mat-icon
                            *ngIf="
                                !hideRedactionWarnings &&
                                !needsRedaction(doc) &&
                                redactionProcessing(doc)
                            "
                            (click)="documentClicked($event, doc)"
                            class="warning warning-space"
                            matTooltip="Document being processed for potential PHI"
                        >
                            warning
                        </mat-icon>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="alias_name">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="margin-left: 1rem"
                >
                    File Name
                </mat-header-cell>
                <mat-cell *matCellDef="let doc">
                    <ng-container *ngIf="editing != doc">
                        <div class="flex stack">
                            <div
                                class="clickable"
                                style="padding-left: 0.75rem"
                                (click)="documentClicked($event, doc)"
                                [matBadge]="matBadge(doc)"
                                matBadgeColor="accent"
                                matBadgeOverlap="false"
                                matBadgePosition="before"
                                [matTooltip]="
                                    getNotificationForDocument(doc)?.message || ''
                                "
                            >
                                <span>
                                    {{ doc.displayName }}
                                </span>
                            </div>
                            <div *ngIf="!!doc.alias" class="secondary super-small">
                                {{ doc.name }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="editing == doc">
                        <mat-form-field class="table-cell-input">
                            <mat-label>File Alias</mat-label>
                            <input
                                matInput
                                placeholder="File Alias"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="alias"
                            />
                            <mat-hint>{{ doc.name }}</mat-hint>
                        </mat-form-field>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="document_type">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Type
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let doc"
                    (click)="
                        !editing && canEditDocument(doc) && editDocument($event, doc)
                    "
                    [class.pointer]="canEditDocument(doc)"
                >
                    <span *ngIf="editing != doc && doc.file_type">
                        {{ documentTypeDisplay(doc.file_type) }}
                    </span>
                    <span *ngIf="editing != doc && !doc.file_type" class="secondary">
                        Not Set
                    </span>
                    <mat-form-field *ngIf="editing == doc" class="table-cell-input">
                        <mat-label>Document Type</mat-label>
                        <mat-select
                            placeholder="Document Type"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="documentType"
                        >
                            <mat-optgroup
                                *ngFor="let group of documentTypeGroups()"
                                [label]="group || 'Other'"
                            >
                                <mat-option
                                    *ngFor="let type of documentTypesForGroup(group)"
                                    [value]="type.type"
                                >
                                    {{ type.displayName }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="source">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Uploaded by
                </mat-header-cell>
                <mat-cell *matCellDef="let doc">
                    <div class="stack">
                        <div>{{ doc.uploaded_by?.displayName }}</div>
                        <div
                            class="secondary super-small"
                            *ngIf="doc.owner?.id !== doc.uploaded_by?.id"
                        >
                            {{ doc.owner.displayName }}
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="shared">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Visibility
                </mat-header-cell>
                <mat-cell *matCellDef="let doc">
                    <mat-chip-set>
                        <mat-chip
                            *ngIf="isDocumentShared(doc)"
                            color="primary"
                            selected
                        >
                            Shared
                        </mat-chip>
                        <mat-chip *ngIf="!isDocumentShared(doc)" color="secondary">
                            Internal
                        </mat-chip>
                    </mat-chip-set>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="uploaded_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="date-column">
                    Date
                </mat-header-cell>
                <mat-cell *matCellDef="let doc" class="date-column">
                    {{
                        isToday(doc.uploaded_at) ? "New" : (
                            (doc.uploaded_at | localizedDate)
                        )
                    }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
                <mat-cell *matCellDef="let doc" class="overflow">
                    <button
                        mat-icon-button
                        *ngIf="editing !== doc"
                        [matMenuTriggerFor]="overflow"
                        [matMenuTriggerData]="{ doc: doc }"
                        [disabled]="!!editing"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        *ngIf="editing == doc"
                        class="green"
                        (click)="saveDocument($event, doc)"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="no-records">
                <mat-footer-cell
                    *matFooterCellDef
                    [attr.colspan]="displayedColumns.length"
                    class="error not-found"
                >
                    <span *ngIf="list.searching && !list.hasLoaded">
                        <mat-progress-spinner
                            diameter="20"
                            class="inline"
                            mode="indeterminate"
                        ></mat-progress-spinner>
                        Loading...
                    </span>
                    <span *ngIf="list.hasLoaded && !list.list.items.length">
                        No documents found.
                    </span>
                </mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            <mat-footer-row
                *matFooterRowDef="['no-records']"
                [class.hidden-footer]="!!list.list.items.length"
            ></mat-footer-row>
            <mat-menu #overflow="matMenu">
                <ng-template matMenuContent let-doc="doc">
                    <button
                        mat-menu-item
                        [class.error]="canEditDocument(doc)"
                        *ngIf="needsRedaction(doc)"
                        (click)="
                            canEditDocument(doc) &&
                                needsRedaction(doc) &&
                                editObject($event, doc, true)
                        "
                        [disabled]="!canEditDocument(doc)"
                    >
                        <mat-icon class="warning-space">warning</mat-icon>
                        Needs Redaction
                    </button>
                    <button
                        mat-menu-item
                        (click)="canViewDocument(doc) && viewDocument($event, doc)"
                        [disabled]="!canViewDocument(doc)"
                    >
                        View
                    </button>
                    <button
                        mat-menu-item
                        *ngIf="shouldShowRerunRedaction(doc)"
                        (click)="rerunRedaction($event, doc)"
                        [disabled]="!canEditDocument(doc)"
                    >
                        Rerun Auto Redaction
                    </button>
                    <button
                        mat-menu-item
                        (click)="canEditDocument(doc) && editObject($event, doc, true)"
                        *ngIf="!needsRedaction(doc) && isRedactable(doc)"
                        [disabled]="!canEditDocument(doc)"
                    >
                        Manual Redaction
                    </button>
                    <button
                        mat-menu-item
                        (click)="canEditDocument(doc) && editDocument($event, doc)"
                        [disabled]="viewOnly || !canEditDocument(doc)"
                    >
                        Edit Details
                    </button>
                    <button mat-menu-item (click)="downloadDocument($event, doc)">
                        Download
                    </button>
                    <button
                        mat-menu-item
                        *ngIf="repository?.type == 'program.case'"
                        (click)="!isDocumentShared(doc) && shareDocument($event, doc)"
                        [disabled]="isDocumentShared(doc)"
                        [disabled]="viewOnly"
                    >
                        Share
                    </button>
                    <button
                        mat-menu-item
                        (click)="canDelete(doc) && deleteDocument($event, doc)"
                        [disabled]="!canDelete(doc)"
                    >
                        Delete
                    </button>
                    <button
                        *ngIf="hasNotification(doc)"
                        mat-menu-item
                        (click)="clearNotification(doc)"
                    >
                        Mark Read
                    </button>
                </ng-template>
            </mat-menu>
        </mat-table>
        <mat-paginator
            (page)="list.handlePageEvent($event)"
            [pageSizeOptions]="list.paginatorSizeOptions"
            [pageSize]="list.pageSize"
            [length]="list.paginatorLength"
            [disabled]="list.searching"
            [showFirstLastButtons]="true"
            [pageIndex]="list.currentPage"
        ></mat-paginator>
    </ng-container>
    <ng-container *ngIf="displayMode === 'task' && documentTasks.length">
        <div class="documents-header flex">
            <div class="title">Document tasks</div>
        </div>
        <mat-table>
            <ng-container matColumnDef="task_name">
                <mat-header-cell *matHeaderCellDef>Task name</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="file_name">
                <mat-header-cell *matHeaderCellDef>File name</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="assigned_to">
                <mat-header-cell *matHeaderCellDef>Assigned To</mat-header-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="taskColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: taskColumns"></mat-row>
        </mat-table>
        <mat-paginator
            (page)="list.handlePageEvent($event)"
            [pageSizeOptions]="list.paginatorSizeOptions"
            [pageSize]="list.pageSize"
            [length]="list.paginatorLength"
            [disabled]="list.searching"
            [showFirstLastButtons]="true"
            [pageIndex]="list.currentPage"
        ></mat-paginator>
    </ng-container>
</div>
