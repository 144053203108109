import { ChangeDetectorRef, Component } from "@angular/core";
import { PatientService } from "src/services/program.services";
import { PatientsComponent } from "./patients.component";

@Component({
    selector: "paginated-patients",
    templateUrl: "./patients.component.html",
    styleUrls: ["./patients.component.scss"],
})
export class PaginatedPatientsComponent extends PatientsComponent {
    constructor(
        protected service: PatientService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "paginated-patients_title");
    }
}
