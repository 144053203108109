import { Injectable } from "@angular/core";
import { APIService } from "./api.service";
import { Template } from "./models/template";
import { Message } from "./models/message";
import { AppNotification } from "./models/appNotification";

import { TeamMember } from "./models/team";

@Injectable()
export class TemplateService extends APIService<Template> {
    constructor() {
        super(Template, ["notification", "template"]);
    }
}
export type BulkMessageData = {
    targets: string[];
    message: Message;
    cases: string[];
    inquiries: string[];
};
@Injectable()
export class MessageService extends APIService<Message> {
    constructor() {
        super(Message, ["notification", "message"]);
    }
    bulkMessage(data: BulkMessageData) {
        data = {
            ...data,
            cases: Array.from(data.cases),
            inquiries: Array.from(data.inquiries),
            message: data?.message.serialize(),
        };
        return this.request(
            [this.endpoint, "bulk_message"].join("/"),
            undefined,
            data,
            "post",
        );
    }
}

@Injectable()
export class AppNotificationService extends APIService<AppNotification> {
    constructor() {
        super(AppNotification, ["notification", "appnotification"]);
    }

    clear(notification: AppNotification) {
        return this.request<AppNotification>(
            [this.endpoint, notification.id, "clear"].join("/"),
            undefined,
            undefined,
            "post",
        );
    }

    readAll(currentAccountId: string) {
        return this.request(
            [this.endpoint, "read_all"].join("/"),
            undefined,
            { currentAccountId },
            "post",
        );
    }

    // returns the number of unread notifications in the notification drawer
    unreadCount(currentAccountId: string) {
        return this.request(
            [this.endpoint, "unread_count"].join("/"),
            undefined,
            { currentAccountId },
            "get",
        )
    }

}
