import { TeamMember } from "src/services/models/team";
import { Component, inject } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, of } from "rxjs";
import { first, map, mergeMap } from "rxjs/operators";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { UpdateEmailDialog } from "./updateEmail.dialog";
import { AccountService, OrganizationService } from "src/services/iam.services";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Account } from "src/services/models/account";
import { ObjectFactory, ObjectReference } from "src/services/models/api-object";
import { EmailValidator } from "src/common/utilities/validators";
import { Role, RoleDefinition } from "src/services/models/role";
import { TeamMemberService } from "src/services/program.services";
import { Organization } from "src/services/models/organization";
import { Capability } from "src/services/models/capability";

@Component({
    selector: "account",
    templateUrl: "./account.component.html",
    styleUrls: ["./account.component.scss"],
})
export class AccountComponent extends ObjectComponent<Account> {
    protected organizationService: OrganizationService;
    protected teamMemberService: TeamMemberService;
    filteredOrganizations_: BehaviorSubject<ObjectReference[]> = new BehaviorSubject<
        ObjectReference[]
    >([]);
    isLoading: boolean = false;
    _teamMember?: TeamMember;
    organization?: Organization | ObjectReference;
    teamMembers: TeamMember[] = [];
    objectName: string = "Account";
    capabilities: Capability[] = [];

    get availableRoles(): RoleDefinition[] {
        return Role.roles;
    }
    get teamMember(): TeamMember | undefined {
        return this._teamMember;
    }
    set teamMember(v: TeamMember | undefined) {
        this._teamMember = v;
        //per the ticket, new staff default to public
        const isPublic =
            this.mode === ObjectViewMode.Create ? true : !this.teamMember?.private;
        this.publicControl.setValue(isPublic);
        this.publicControl.valueChanges.pipe(first()).subscribe(() => {
            this.formGroup.markAsDirty();
        });
        this.updateTeamMemberPermission();
    }
    get isOrganizationAdministrator(): boolean {
        return !!this.currentAccount?.hasRole(
            "object.admin",
            this.teamMember?.permission?.object,
        );
    }
    constructor(protected service: AccountService) {
        super(service);
        this.organizationService = inject(OrganizationService);
        this.teamMemberService = inject(TeamMemberService);
    }
    protected onAutosave(value: any): void {
        this.emailNotificationsControl?.disable();
        super.onAutosave(value);
    }
    ngOnInit() {
        // no dialog means user is looking at their account settings, otherwise they are adding/editing staff in a dialog from the staff component
        if (this.teamMember && !this.teamMember?.account && this.dialogReference) {
            this.object = ObjectFactory.makeObject<Account>(
                {},
                Account.object_type,
            ) as Account;
            if (this.teamMember?.permission && this.isSystemAdministrator) {
                this.fullObject?.roles.push(this.teamMember.permission);
            }
        }
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        if (this.mode != this.ObjectViewMode.Create) {
            this.formGroup.removeControl("email");
        } else {
            this.enforceTeamMemberUniqueEmail();
        }
        //disabling it this way will prevent the 'changed after checked' errors
        if (this.disablePermissionInput) {
            this.formGroup.controls["permission"].disable();
        }
    }
    get emailControl() {
        return this.formGroup.get("email");
    }
    enforceTeamMemberUniqueEmail() {
        this.emailControl?.valueChanges.subscribe((v) => {
            const memberOfOrgTeam = this.teamMembers.some(
                (tm) => tm.email.toLowerCase() === v.toLowerCase(),
            );
            let errors = this.emailControl?.errors || null;
            if (!memberOfOrgTeam) {
                if (errors && errors["memberOfOrgTeam"]) {
                    delete errors["memberOfOrgTeam"];
                    if (errors.keys().length == 0) {
                        errors = null;
                    }
                }
            } else {
                if (errors === null) errors = {};
                errors["memberOfOrgTeam"] = true;
            }

            this.emailControl?.setErrors(errors);
        });
    }
    get disablePermissionInput() {
        //if there is a team member account, means user is looking at existing account
        if (this.teamMember?.account) {
            return this.currentAccount?.id == this.teamMember.account?.id;
        }
        //otherwise user is creating new account so account will be undefined
        return false;
    }

    publicControl = new FormControl<boolean>(true);

    protected createObjectForm(): UntypedFormGroup {
        if (this.mode !== ObjectViewMode.Create) {
            return this.formBuilder.group({
                email: [null, [Validators.required, EmailValidator]],
                first_name: [null, Validators.required],
                last_name: [null, Validators.required],
                phone: [null],
                roles: [[]],
                permission: [null],
                status: [null],
                settings: this.formBuilder.group({
                    email_notifications: [true],
                }),
                capability: [null],
            });
        }

        return this.formBuilder.group({
            email: [null, [Validators.required, EmailValidator]],
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            phone: [null],
            roles: [[]],
            permission: [null],
            status: [null],
            capability: [null],
        });
    }

    get accountSettingsFormGroup() {
        return this.formGroup.get("settings") as UntypedFormGroup;
    }
    get emailNotificationsControl() {
        return this.accountSettingsFormGroup.get("email_notifications");
    }
    get emailNotificationsEnabled() {
        return this.emailNotificationsControl?.value;
    }
    get roles(): Role[] {
        return this.formGroup?.controls.roles?.value;
    }
    set roles(v: Role[]) {
        this.formGroup?.controls.roles?.patchValue(v);
        this.formGroup?.controls.roles?.markAsDirty();
    }

    get canResetPassword(): boolean {
        return !!this.fullObject?.is_local_user;
    }

    resetPassword(event: MouseEvent): void {
        this.terminateEvent(event);
        this.service.resetPassword(this.object!).subscribe({
            next: () => {
                if (this.currentAccount?.id == this.object?.id) {
                    this.session.logout();
                } else
                    this.session.message =
                        "Password reset email successfully sent. Please check your email for the next step.";
            },
            error: () => (this.session.message = "An unexpected error occurred."),
        });
    }

    get accountSetUp(): boolean {
        return (this.object as Account)?.is_active;
    }
    get canResendActivation(): boolean {
        return !!(this.object as Account)?.is_invited;
    }

    get canEditEmail(): boolean {
        // accounts can only edit their email
        return (this.object as Account)?.email === this.currentAccount?.email;
    }

    resendActivationEmail(event: MouseEvent) {
        this.terminateEvent(event);

        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        "Are you sure you want to resend the activation email to this account?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) => {
                    if (confirm) {
                        return this.service.resendActivationEmail(this.object!);
                    } else {
                        return of(null);
                    }
                }),
            )
            .subscribe();
    }
    get capabilityControl() {
        return this.formGroup.get("capability") as FormControl<Capability[]>;
    }
    protected updatePermission(
        account: Account | undefined,
    ): Observable<Account | undefined> {
        const oldPermission = this.teamMember?.permission?.role ?? "object.view";
        const newPermission =
            this.formGroup.controls.permission.value?.value || oldPermission;
        const exists = this.teamMember?.id ?? false;
        const capabilityChanged =
            this.capabilityControl?.value &&
            this.capabilityControl.touched &&
            this.capabilityControl.dirty;

        if (
            !account ||
            !this.teamMember ||
            (exists &&
                oldPermission == newPermission &&
                !this.publicControl.dirty &&
                !capabilityChanged)
        ) {
            return of(account);
        }

        this.teamMember.private = !this.publicControl.value;
        const accountReference = account.asReference;

        if (this.teamMember.permission) {
            this.teamMember.permission.role = newPermission;
        } else {
            this.teamMember.permission = ObjectFactory.makeObject<Role>(
                {
                    object: this.organization?.asReference,
                    account: accountReference,
                    role: newPermission,
                    private: this.teamMember.private,
                },
                Role.object_type,
            ) as Role;
        }

        if (!exists) {
            this.teamMember.account = accountReference;
            // FIX FOR MED-1539, creating duplicate permissions.
            // Otherwise the teamMember service creates another role object when the account service already creates it
            if (
                account.roles.length == 1 &&
                account.roles[0].role === this.teamMember.permission?.role
            ) {
                this.teamMember.permission = account.roles[0];
            } else if (this.teamMember.permission) {
                this.teamMember.permission.account = accountReference;
            }
        }
        if (this.teamMember.permission)
            this.teamMember.permission.private = this.teamMember.private;

        if (capabilityChanged) {
            this.teamMember.capabilities = this.capabilityControl?.value.map((v) => {
                const obj = ObjectFactory.makeObject<Capability>(
                    {
                        ...v,
                    },
                    Capability.object_type,
                );
                return obj as Capability;
            });
        }
        const obs =
            exists ?
                this.teamMemberService.update(this.teamMember)
            :   this.teamMemberService.create(this.teamMember);
        return obs.pipe(map(() => account));
    }

    protected commit(v: any): Observable<Account | undefined> {
        const obs = super.commit(v);

        this.emailNotificationsControl?.disable();
        return obs.pipe(mergeMap((v: Account | undefined) => this.updatePermission(v)));
    }

    protected onCommitSuccess(v: Account): boolean {
        const result = super.onCommitSuccess(v);
        this.snackbar.open("Account changes successfully saved.", undefined, {
            duration: 2000,
        });
        this.emailNotificationsControl?.enable();
        return result;
    }

    get filteredCount(): number {
        return this.filteredOrganizations_.getValue().length;
    }
    get filteredOrganizations(): Observable<ObjectReference[]> {
        return this.filteredOrganizations_.asObservable();
    }
    objectDisplay(object?: ObjectReference): string {
        return object?.displayName ?? "";
    }

    protected setObject(v?: Account | undefined): void {
        super.setObject(v);
        this.updateTeamMemberPermission();
    }

    protected onCommitError(res: HttpErrorResponse): void {
        super.onCommitError(res);

        if (res.error && "email" in res.error) {
            this.snackbar.open(
                "An account already exists with this email.",
                undefined,
                { duration: 4000 },
            );
        }
        this.emailNotificationsControl?.enable();
        this.session.handleError(res);
    }

    protected updateTeamMemberPermission(): void {
        const roles = (this.teamMember?.permission?.role.split("|") ?? [])
            .map((r: string) =>
                this.availableRoles.find((ar: RoleDefinition) => ar.value == r),
            )
            .filter((rd: RoleDefinition | undefined) => !!rd) as RoleDefinition[];
        const defaultRole = this.availableRoles.find(
            (ar: RoleDefinition) => ar.value == "object.view",
        );
        this.formGroup.controls.permission.setValue(
            roles?.length ? roles[0] : defaultRole,
        );

        if (this.teamMember?.capabilities) {
            this.capabilityControl?.setValue(this.teamMember.capabilities);
        }
    }

    updateEmail(event: MouseEvent) {
        this.terminateEvent(event);
        if (this.currentAccount?.email) {
            const { email } = this.currentAccount;
            this.dialog.open(UpdateEmailDialog, {
                width: "40%",
                data: {
                    email,
                },
                disableClose: true,
                hasBackdrop: true,
            });
        }
    }

    get publicCheckboxToolTip() {
        return "By making this user public they will be visible to external users. If you want this user to have tasks assigned to them or have messages sent to them, leave this box checked.";
    }

    get accountIsAlreadyMemberMessage() {
        return "An account with this email is already a member of your organization.";
    }
}
