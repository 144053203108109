import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { from, Observable } from "rxjs";

//this class is necessary because of the 'intended' behavior of ng2-file-upload's FileUploader class
//it does not support uploading files all at once and will always sent one upload request at a time
//(see issue opened in 2017: https://github.com/valor-software/ng2-file-upload/issues/671) where I got this from

// which made it complicated to implement MED-1410 (only sending one email for a bulk file upload)
// now the only backend change was setting up an endpoint to handle a bulk upload

export class FileUploaderCustom extends FileUploader {
    private _cachedLastResponse?: string;
    public get cachedLastResponse() {
        return this._cachedLastResponse;
    }

    uploadAllObservable(): Observable<any> {
        const xhrPromise = new Promise((resolve, reject) => {
            this.uploadAllOneRequest((xhr: XMLHttpRequest) => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            });
        });
        return from(xhrPromise);
    }
    uploadAllOneRequest(
        readyStateChange: (xhr: XMLHttpRequest) => void = () => {},
    ): void {
        if (!this.options.url) {
            throw new Error("this.options.url needs to be set");
        }

        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        let fakeItem: FileItem;

        for (const item of this.queue) {
            item.isReady = true;
            item.isUploading = true;
            item.isUploaded = false;
            item.isSuccess = false;
            item.isCancel = false;
            item.isError = false;
            item.progress = 0;

            if (typeof item._file.size !== "number") {
                throw new TypeError("The file specified is no longer valid");
            }
            formData.append("files", item._file, item.file.name);
            this.onBuildItemForm(item, formData);
        }

        if (this.options.additionalParameter) {
            Object.keys(this.options.additionalParameter).forEach((key) => {
                if (this.options.additionalParameter) {
                    formData.append(key, this.options.additionalParameter[key]);
                }
            });
        }

        xhr.onerror = () => {
            //onerror fires when there is a failure on the network level
            this.onErrorItem(fakeItem, "Network Error", xhr.status, {});
        };

        xhr.onabort = () => {
            this.onErrorItem(fakeItem, "", xhr.status, {});
        };

        xhr.open("POST", this.options.url, true);
        xhr.withCredentials = true;
        if (this.options.headers) {
            for (let _i = 0, _a = this.options.headers; _i < _a.length; _i++) {
                const header = _a[_i];
                xhr.setRequestHeader(header.name, header.value);
            }
        }
        if (this.authToken && this.authTokenHeader) {
            xhr.setRequestHeader(this.authTokenHeader, this.authToken);
        }

        xhr.onload = () => {
            const headers = this._parseHeaders(xhr.getAllResponseHeaders());
            const response = this._transformResponse(xhr.response, headers);

            this._cachedLastResponse = response;
            const gist = this._isSuccessCode(xhr.status) ? "Success" : "Error";
            const method = "_on" + gist + "Item";
            for (const item of this.queue) {
                (this as any)[method](item, response, xhr.status, headers);
            }
            this._onCompleteItem(this.queue[0], response, xhr.status, headers);
        };

        // only way to update progress
        // probably why the library only uploads one at a time
        xhr.upload.onprogress = (e) => {
            const amtCompleted = Math.round((e.loaded / e.total) * 100);

            this.queue.forEach((item) => {
                item.progress = amtCompleted;
            });
        };
        xhr.onreadystatechange = () => readyStateChange(xhr);
        xhr.send(formData);
    }

    setOptions(options: FileUploaderOptions): void {
        if (options.url == "") options.url = this.options.url;
        super.setOptions(options);
    }
}
