import { RoleService } from "src/services/iam.services";
import { TeamMemberService } from "./../../../services/program.services";
import { Inquiry } from "src/services/models/inquiry";
import { SessionComponent } from "src/services/components/session.component";
import { Component, Input, inject } from "@angular/core";
import { Case } from "src/services/models/case";
import { AddRoleDialog } from "../case/add-role.dialog";
import { Role, RoleDefinition } from "src/services/models/role";
import { CaseTeam, Team, TeamMember } from "src/services/models/team";
import { SendTemplateDialog } from "src/common/components/template/send-template.dialog";
import { CaseComponent } from "../case/case.component";
import { Message } from "src/services/models/message";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { ObjectFactory } from "src/services/models/api-object";
import { MatDialog } from "@angular/material/dialog";
import { Capability } from "src/services/models/capability";

@Component({
    selector: "team",
    templateUrl: "./team.component.html",
    styleUrls: ["./team.component.scss"],
})
export class TeamComponent extends SessionComponent {
    protected teamMemberService: TeamMemberService;
    protected roleService: RoleService;
    @Input() viewOnly = false;
    protected fullObject?: Case | Inquiry;
    @Input() set case(v: Case | Inquiry) {
        this.fullObject = v;
    }

    @Input() staffTeam?: Team;

    get isPhysicianStaff(): boolean {
        return !!this.fullObject?.isMemberOfTeam(this.currentAccount, "provider");
    }
    get isPharmaStaff(): boolean {
        return !!this.fullObject?.isMemberOfTeam(this.currentAccount, "pharma");
    }
    get isEditor(): boolean {
        const permitted = ["object.admin", "object.edit", "organization.manager"];
        return permitted.includes(this.permissions);
    }
    get isAdministrator(): boolean {
        return this.permissions === "object.admin";
    }
    get isManager() {
        if (this.fullObject instanceof Case) {
            return !!this.currentAccount?.hasRole(
                "organization.manager",
                this.fullObject.owner,
            );
        }

        return !!this.currentAccount?.hasRole(
            "organization.manager",
            this.fullObject!.organization,
        );
    }
    get permissions(): string {
        return (
            this.fullObject?.permissions(
                this.currentAccount,
                this.caseTeam?.capacity,
            ) ?? "object.none"
        );
    }

    get team(): TeamMember[] {
        return ([] as TeamMember[]).concat
            .apply(
                [],
                this.fullObject?.teams?.map((ct: CaseTeam) => {
                    const isMember = !!ct.members.find(
                        (tm: TeamMember) => tm.account.id == this.currentAccount?.id,
                    );
                    return ct.members.filter(
                        (tm: TeamMember) => isMember || !tm.private,
                    );
                }) ?? [],
            )
            .filter((tm: TeamMember) => {
                const unInvitedPhysician =
                    tm.role === "physician" && !this.isInvited(tm);
                return unInvitedPhysician || tm.permissionLevel != "object.none";
            });
    }
    get caseTeam(): CaseTeam | undefined {
        if (this.isPharmaStaff)
            return this.fullObject?.teams?.find(
                (ct: CaseTeam) => ct.capacity == "pharma",
            );
        else if (this.isPhysicianStaff)
            return this.fullObject?.teams?.find(
                (ct: CaseTeam) => ct.capacity == "provider",
            );
        return undefined;
    }

    columns: string[] = ["name", "organization", "role", "permission", "actions"];

    teamForMember(member: TeamMember): CaseTeam | undefined {
        return this.fullObject?.teams?.find(
            (ct: CaseTeam) =>
                !!ct.members.find(
                    (tm: TeamMember) => tm.account.id == member.account.id,
                ),
        );
    }
    isInternal(member: TeamMember): boolean {
        const member_team = this.teamForMember(member);
        return !!this.fullObject?.isMemberOfTeam(
            this.currentAccount,
            member_team?.capacity,
        );
    }
    isInherited(member: TeamMember): boolean {
        return !!this.fullObject?.teams?.find((ct: CaseTeam) => ct.isInherited(member));
    }

    organizationForMember(member: TeamMember): string {
        return (
            this.teamForMember(member)?.team.organization.displayName ??
            "Unknown Institution"
        );
    }
    roleForMember(member: TeamMember): string {
        let roles = [];
        const definition = TeamMember.DefinedRoles.find(
            (rd: RoleDefinition) => rd.value == member.role,
        );
        if (definition && definition.value === "owner") roles.push(definition.display);

        const permission = Role.roles.find(
            (rd: RoleDefinition) => rd.value == member?.permissionLevel,
        );
        if (permission) roles.push(permission.display);

        if (!roles.length) roles = ["Team Member"];
        return roles.join(", ");
    }
    isMemberOwner(member: TeamMember): boolean {
        return !!member.role.split("|").find((r: string) => r == "owner");
    }

    constructor(protected dialog: MatDialog) {
        super();
        this.teamMemberService = inject(TeamMemberService);
        this.roleService = inject(RoleService);
    }

    editAccess(
        event: MouseEvent,
        member?: TeamMember,
        readOnly = false,
        invite = false,
    ): void {
        const caseTeam = member ? this.teamForMember(member) : this.caseTeam;
        let orgMember = undefined;

        if (caseTeam && !caseTeam.isInherited(member!)) {
            orgMember = caseTeam?.inherited.find(
                (tm) => tm.account.id == member?.account.id,
            );
        }

        this.dialog
            .open(AddRoleDialog, {
                data: {
                    object: this.fullObject,
                    member,
                    orgMember,
                    caseTeam: caseTeam,
                    staffTeam: this.staffTeam,
                    readOnly,
                    invite,
                    isExternal: member ? !this.isInternal(member) : false,
                },
                width: "50%",
                disableClose: true,
                hasBackdrop: true,
            })
            .afterClosed()
            .subscribe((tm?: TeamMember) => {
                if (member && tm) member.update(tm);
                else if (tm) {
                    const ct = this.fullObject?.teams?.find(
                        (ict: CaseTeam) => ict.id == tm.override?.id,
                    );
                    if (ct) ct.overrides = [...ct.overrides, tm];
                }
            });
    }

    inviteMember(event: MouseEvent, member: TeamMember): void {
        const subject = "You've been invited to collaborate";
        let organization;
        let sources: any[] = [];
        if (this.fullObject instanceof Case) {
            organization = this.fullObject.shared.organization;
            sources = [
                organization,
                this.fullObject,
                this.currentAccount,
                this.fullObject.shared,
                this.fullObject.shared.program,
            ];
        } else if (this.fullObject) {
            organization = this.fullObject.organization;
            sources = [
                organization,
                this.fullObject,
                this.currentAccount,
                this.fullObject.program,
            ];
        }
        this.dialog
            .open(SendTemplateDialog, {
                data: {
                    to: member,
                    subject: subject,
                    owner: organization?.id,
                    context: this.fullObject?.data,
                    reference: this.fullObject?.asReference,
                    allowInvite: true,
                    sources: sources,
                    repository: this.case,
                    uploadOwner: organization,
                    contacts: [member],
                },
                minWidth: 600,
            })
            .afterClosed()
            .subscribe((msg: Message) => {
                CaseComponent.HandleMessageInvitePermissions(
                    msg,
                    this.roleService,
                    this.fullObject,
                );
                if (member?.id) {
                    // TODO Websockets: Upodate this with Django Websockets
                    this.teamMemberService.retrieve(member.id).subscribe((o) => {
                        member?.update(o);
                    });
                }
            });
    }

    removeAccess(event: MouseEvent, member: TeamMember): void {
        const data = {
            title: `Revoke Access`,
            message: `Are you sure you want to revoke ${member.account.displayName}'s access ?`,
            ok: "Revoke",
        };
        this.dialog
            .open(ConfirmDialog, {
                disableClose: true,
                data,
            })
            .afterClosed()
            .subscribe((confirm: boolean) => {
                if (!confirm) {
                    return;
                } else {
                    const case_team = this.teamForMember(member);
                    if (case_team)
                        TeamComponent.setRoles(
                            case_team,
                            member,
                            this.teamMemberService,
                            ["member"],
                            "object.none",
                        );
                }
            });
    }
    canEditAccess(member: TeamMember): boolean {
        return (
            !!this.caseTeam &&
            this.isAdministrator &&
            (!this.canBeInvited(member) || this.isInvited(member))
        );
    }
    memberIsCurrentUser(member: TeamMember) {
        return member.account.id === this.currentAccount?.id;
    }

    canRemoveAccess(member: TeamMember): boolean {
        return (
            !!this.caseTeam &&
            this.isAdministrator &&
            member.account.id != this.currentAccount?.id
        );
    }

    canBeInvited(member: TeamMember): boolean {
        return (
            this.teamForMember(member)?.capacity == "provider" &&
            (!member.is_active || member.permission?.role == "object.none")
        );
    }
    isInvited(member: TeamMember): boolean {
        return !!member.is_invited && member.permissionLevel != "object.none";
    }
    isInviteExpired(member: TeamMember): boolean {
        return this.isInvited(member) && !!member.invite_expired;
    }

    canMakeOwner(member: TeamMember): boolean {
        const hasOwner = this.isMemberOwner(member);
        const isPharma = this.teamForMember(member)?.capacity == "pharma";

        // per MED-1658, only admins or managers can change case owner
        return !hasOwner && isPharma && (this.isAdministrator || this.isManager);
    }
    canRemoveOwner(member: TeamMember): boolean {
        const hasOwner = this.isMemberOwner(member);
        const isPharma = this.teamForMember(member)?.capacity == "pharma";
        return (
            hasOwner &&
            isPharma &&
            this.isPharmaStaff &&
            (this.isAdministrator || this.isManager)
        );
    }
    makeOwner(event: MouseEvent, member: TeamMember): void {
        const case_team = this.teamForMember(member);
        const isInherited = this.isInherited(member);
        const isBaseTeam = !!member.team;
        let roles =
            isInherited || isBaseTeam ?
                [] // for inherited or base team, we don't need to carry their existing roles
            :   member.role.split("|").filter((r: string) => r != "member"); // if we're adding other roles, we don't need the 'member' role
        roles.push("owner");
        if (case_team)
            TeamComponent.setRoles(case_team, member, this.teamMemberService, roles);
    }
    removeOwner(event: MouseEvent, member: TeamMember): void {
        const case_team = this.teamForMember(member);
        const hasInherited = case_team?.inherited.find(
            (tm: TeamMember) => tm.account.id == member.account.id,
        );
        const hasBaseTeam = case_team?._members.find(
            (tm: TeamMember) => tm.account.id == member.account.id,
        );
        let roles = member.role.split("|").filter((r: string) => r != "owner"); // remove owner role
        if (!roles.length) {
            if (hasBaseTeam || hasInherited) {
                // remove the override
                this.teamMemberService.destroy(member).subscribe(() => {
                    if (case_team)
                        case_team.overrides = case_team?.overrides.filter(
                            (tm: TeamMember) => tm.id != member.id,
                        );
                });
            } else {
                roles = ["member"]; // if there are no other roles, set member
            }
        }
        if (case_team && roles.length)
            TeamComponent.setRoles(case_team, member, this.teamMemberService, roles);
    }

    static overrideRoles(
        case_team: CaseTeam,
        member: TeamMember,
        teamMemberService: TeamMemberService,
        roles: string[],
        permission?: string,
    ): void {
        let override: TeamMember | undefined;
        const removeAccess =
            roles.length == 1 && roles[0] == "member" && permission == "object.none";
        const sameRoles = member.hasEquivilentRoles(roles);
        const samePermission = member.permissionLevel == permission;
        const needsOverride = removeAccess || !sameRoles || !samePermission;

        if (needsOverride) {
            const roleObject = case_team.case ? case_team.case : case_team.inquiry;
            let permissionLevel = permission ?? member.permissionLevel;
            const isPrivate = removeAccess || !!member.permission?.private;
            if (removeAccess) {
                permissionLevel = "object.none";
                roles = ["member"];
            }

            override = ObjectFactory.makeObject<TeamMember>(
                {
                    override: case_team.asReference,
                    account: member.account,
                    role: roles.join("|"),
                    permission: ObjectFactory.makeObject<Role>(
                        {
                            object: roleObject,
                            account: member.account,
                            role: permissionLevel,
                            private: isPrivate,
                        },
                        Role.object_type,
                    ) as Role,
                    private: isPrivate,
                },
                TeamMember.object_type,
            ) as TeamMember;

            if (override)
                teamMemberService.create(override).subscribe((result?: TeamMember) => {
                    if (result) case_team.overrides = [...case_team.overrides, result];
                });
        }
    }
    static updateOverride(
        case_team: CaseTeam,
        member: TeamMember,
        teamMemberService: TeamMemberService,
        roles: string[],
        permission?: string,
        capabilities: Capability[] = [],
    ): void {
        const base_member = case_team._members.find(
            (tm: TeamMember) => tm.account.id == member.account.id,
        );
        const inherited_member = case_team.inherited.find(
            (tm: TeamMember) => tm.account.id == member.account.id,
        );
        const isProvider = case_team.capacity == "provider";
        const removeAccess =
            roles.length == 1 && roles[0] == "member" && permission == "object.none";
        const explicitRemoval =
            (!!base_member || !!inherited_member || isProvider) && removeAccess;
        const roleObject = case_team.case ? case_team.case : case_team.inquiry;
        let destroyed = false;

        if (explicitRemoval && !!member.permission) {
            // we need to maintain the member object otherwise it would revert to their base team or inherited value
            member.permission.role = "object.none"; // update the permission to remove access
            member.role = "member"; // remove any roles they have
            member.permission.private = true; // set it private
            member.private = true;
        } else if (explicitRemoval) {
            // we need to maintain the member object otherwise it would revert to their base team  or inherited value
            member.permission = ObjectFactory.makeObject<Role>(
                {
                    // but we need to add a permssions denying them access
                    object: roleObject,
                    account: member.account,
                    role: "object.none",
                    private: true,
                },
                Role.object_type,
            ) as Role; // create a new permission object
            member.role = "member"; // remove any roles
            member.private = true; // set it private
        } else if (removeAccess) {
            // if they don't have base team or inherited access, we can just remove their member object
            teamMemberService
                .destroy(member)
                .subscribe(
                    () =>
                        (case_team.overrides = case_team.overrides.filter(
                            (tm: TeamMember) => tm.id != member.id,
                        )),
                );
            destroyed = true;
        } else if (member.permission) {
            // no special cases, just update the object
            member.permission.role =
                permission ?? member.permission?.role ?? "object.view";
            member.role = roles.join("|");
        } else {
            member.permission = ObjectFactory.makeObject<Role>(
                {
                    object: roleObject,
                    account: member.account,
                    role: permission ?? "object.view",
                    private: member.private,
                },
                Role.object_type,
            ) as Role;
            member.role = roles.join("|");
        }

        if (!destroyed)
            teamMemberService
                .update(member)
                .subscribe((tm?: TeamMember) => member.update(tm ?? {}));

        if (capabilities && inherited_member) {
            inherited_member.capabilities = capabilities;
            teamMemberService.update(inherited_member).subscribe();
        }
    }
    static setRoles(
        case_team: CaseTeam,
        member: TeamMember,
        teamMemberService: TeamMemberService,
        roles: string[],
        permission?: string,
        capabilities: Capability[] = [],
    ): void {
        const isInherited = case_team.isInherited(member);
        const isBaseTeam = !!member.team;
        const hasExistingOverride = member.override?.id == case_team.id;
        const needsOverride = isBaseTeam || isInherited || !hasExistingOverride;

        if (needsOverride)
            TeamComponent.overrideRoles(
                case_team,
                member,
                teamMemberService,
                roles,
                permission,
            );
        else if (hasExistingOverride)
            TeamComponent.updateOverride(
                case_team,
                member,
                teamMemberService,
                roles,
                permission,
                capabilities,
            );
    }

    capabilityDisplay(member: TeamMember): string {
        const caseTeam = this.teamForMember(member);
        return caseTeam?.capabilityDisplayForMember(member) ?? "";
    }
}
