import { Injectable } from "@angular/core";
import { APIService } from "./api.service";
import { Organization, OrganizationSettings } from "./models/organization";
import { Account, AccountSettings } from "./models/account";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { APIObject, ObjectFactory, ObjectReference } from "./models/api-object";
import { Audit, AuditFilter } from "./models/audit";
import { Role } from "./models/role";
import { FileItem, FileUploader } from "ng2-file-upload";
import { RequestFilter } from "src/common/utilities/request";
import { Capability } from "./models/capability";

@Injectable()
export class OrganizationService extends APIService<Organization> {
    constructor() {
        super(Organization, ["iam", "organization"]);
    }

    setCORSAccessDomain(
        org: APIObject,
        domain?: string,
        remove: boolean = false,
    ): Observable<string[]> {
        return this.request(
            [this.endpoint, org.id, "cors"].join("/"),
            undefined,
            { domain: domain, remove: remove ? "True" : "False" },
            "post",
        ).pipe(map((res: any) => res as string[]));
    }

    removeLogo(org: Organization): Observable<string> {
        return this.request(
            [this.endpoint, org.id, "template_logo"].join("/"),
            undefined,
            { file: null },
            "post",
        ).pipe(map((res: any) => res as string));
    }

    roles(org: Organization): Observable<Role[]> {
        return this.request<Role[]>([this.endpoint, org.id, "roles"].join("/")).pipe(
            map((res: any) => res.map((v: any) => ObjectFactory.makeObject<Role>(v))),
        );
    }
    logo(org: Organization | ObjectReference): Observable<string> {
        return this.request<string>([this.endpoint, org.id, "logo"].join("/")).pipe(
            map((res: any) => res as string),
        );
    }

    exportConfig(organization: Organization) {
        const fileName = organization.slug + ".json";
        this.session.download(
            ["iam", "organization", organization.id!, "config"],
            fileName,
        );
    }

    logoUploader(
        org?: Organization | ObjectReference,
    ): Observable<FileUploader | undefined> {
        if (org?.id) {
            return this.session.authorization.pipe(
                map((authorization: string | undefined) => {
                    const uploader = new FileUploader({
                        url: [
                            this.session.environment.services,
                            this.endpoint,
                            org.id,
                            "template_logo",
                        ].join("/"),
                        allowedMimeType: ["image/jpeg", "image/png", "image/tiff"],
                        authToken: authorization,
                        autoUpload: true,
                    });
                    uploader.onSuccessItem = (item: FileItem, response: string) => {
                        const data = JSON.parse(response);
                        if (org instanceof Organization && org.settings)
                            // the random number will force the browser to reload the img since all template_logo share the same url
                            org.settings.template_logo =
                                data.url + "?random+=" + Math.random();
                    };
                    return uploader;
                }),
            );
        } else {
            return of(undefined);
        }
    }
}

@Injectable()
export class OrganizationSettingsFactory extends ObjectFactory<OrganizationSettings> {
    constructor() {
        super(OrganizationSettings);
    }
}

@Injectable()
export class AccountService extends APIService<Account> {
    constructor() {
        super(Account, ["iam", "account"]);
    }

    resetPassword(account: Account | ObjectReference): Observable<any> {
        return this.request<Role[]>(
            [this.endpoint, account.id, "reset"].join("/"),
            undefined,
            {},
            "post",
        );
    }
    resendActivationEmail(account: Account | ObjectReference): Observable<any> {
        return this.request<Role[]>(
            [this.endpoint, account.id, "resend"].join("/"),
            undefined,
            {},
            "post",
        );
    }
    rolesForObject(
        account: Account | ObjectReference,
        object: ObjectReference,
    ): Observable<Role[]> {
        return this.request<Role[]>(
            [this.endpoint, account.id, "roles"].join("/"),
            undefined,
            object,
            "post",
        ).pipe(
            map((value: any) =>
                value.map((v: any) => ObjectFactory.makeObject<Role>(v)),
            ),
        );
    }
    removeRolesForObject(
        account: Account | ObjectReference,
        object: ObjectReference,
    ): Observable<Role[]> {
        return this.request<Role[]>(
            [this.endpoint, account.id, "roles"].join("/"),
            undefined,
            object,
            "delete",
        ).pipe(
            map((value: any) =>
                value.map((v: any) => ObjectFactory.makeObject<Role>(v)),
            ),
        );
    }

    updateEmail(data: any) {
        return this.request(
            [this.endpoint, "update_email"].join("/"),
            undefined,
            data,
            "post",
        );
    }

    walkthrough(account: ObjectReference, status: boolean = false) {
        const { id } = account;
        return this.request(
            [this.endpoint, "walkthrough"].join("/"),
            undefined,
            { id, status },
            "patch",
        );
    }

    dashboardRole(account?: Account | ObjectReference): Observable<any> {
        return this.request(
            [this.endpoint, account?.id ?? "0", "dashboard_role"].join("/"),
        );
    }

    auth0Sync(account?: Account | ObjectReference): Observable<any> {
        return this.request(
            [this.endpoint, account?.id ?? "0", "auth0_sync"].join("/"),
            undefined,
            {},
            "post",
        );
    }
}

@Injectable()
export class AuditService extends APIService<Audit> {
    constructor() {
        super(Audit, ["iam", "audit"]);
    }

    filters(filter: RequestFilter): Observable<AuditFilter> {
        const endpoint = [this.endpoint, "filters"].join("/");
        return this.request<AuditFilter>(endpoint, filter).pipe(
            map((result: any) => result as AuditFilter),
        );
    }

    dependencies(object: Audit | ObjectReference): Observable<Audit[]> {
        const endpoint = [this.endpoint, object.id, "dependencies"].join("/");
        return this.request<Audit[]>(endpoint).pipe(
            map((result: any) =>
                result.map((o: any) => ObjectFactory.makeObject<Audit>(o)),
            ),
        );
    }
    restore(objects: (Audit | ObjectReference)[]): Observable<any> {
        const endpoint = [this.endpoint, "restore"].join("/");
        objects = objects.map((o: Audit | ObjectReference) => o.asReference);
        return this.request(endpoint, undefined, objects, "post");
    }

    detail(object: APIObject | ObjectReference, full = false): Observable<Audit[]> {
        const filter = {
            type: object.type,
            full: full ? "true" : "false",
        };
        const endpoint = [this.endpoint, object.id, "trail"].join("/");
        return this.request<Audit[]>(endpoint, filter).pipe(
            map((result: any) =>
                result.map((o: any) => ObjectFactory.makeObject<Audit>(o)),
            ),
        );
    }
}

// NOTE: This services is only accessible to system administrators
@Injectable()
export class RoleService extends APIService<Role> {
    constructor() {
        super(Role, ["iam", "role"]);
    }
}

@Injectable()
export class AccountSettingsFactory extends ObjectFactory<AccountSettings> {
    constructor() {
        super(AccountSettings);
    }
}

@Injectable()
export class CapabilityService extends APIService<Capability> {
    constructor() {
        super(Capability, ["iam", "capability"]);
    }
}
