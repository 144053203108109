<button
    mat-mini-fab
    *ngIf="!isInquiry && canAdd && !editing"
    class="add-data-field"
    color="accent"
    (click)="addField($event)"
    matTooltip="Add Data Field"
>
    <mat-icon>add</mat-icon>
</button>
<div class="table full">
    <div class="header">
        <div class="row flex">
            <div class="column flex fixed icon-column"></div>
            <div class="column flex fixed stack field-name">Field</div>
            <div class="column flex flexible field-value">Value</div>
            <div class="column flex fixed types-column">Type</div>
        </div>
    </div>
    <div class="body striped">
        <div class="row flex bold info not-found" *ngIf="!fieldCount">
            No data fields found.
        </div>
        <div class="row flex no-border" *ngFor="let field of dataFieldValues">
            <ng-container *ngIf="!formGroup || editing != field">
                <div class="column flex fixed icon-column">
                    <button
                        class="inline"
                        mat-icon-button
                        (click)="editField($event, field)"
                        *ngIf="canEditField(field) && !editing"
                        matTooltip="Edit Data"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        class="inline"
                        mat-icon-button
                        matTooltip="Move to Shared"
                        *ngIf="tab === 'internal' && canEditField(field) && !editing"
                        (click)="triggerShareEvent(field.name)"
                    >
                        <mat-icon>share</mat-icon>
                    </button>
                </div>
                <div class="column flex stack fixed field-name">
                    <div>{{ field.displayName }}</div>
                    <div
                        class="secondary super-small"
                        *ngIf="field.displayName !== field.name && !!field.displayName"
                    >
                        {{ field.name }}
                    </div>
                </div>
                <div
                    class="column flex wrap flexible field-value"
                    *ngIf="editing !== field"
                >
                    <span *ngIf="field.type == 'text.short' || field.type == 'number'">
                        {{ field.value }}
                    </span>
                    <span *ngIf="field.type == 'date'">
                        {{ field.value | localizedDate }}
                    </span>
                    <pre *ngIf="field.type == 'text.long'">{{ field.value }}</pre>
                </div>
                <div class="column flex fixed types-column">
                    <mat-icon
                        class="secondary"
                        *ngIf="field.type == 'text.short'"
                        matTooltip="Short Text"
                    >
                        short_text
                    </mat-icon>
                    <mat-icon
                        class="secondary"
                        *ngIf="field.type == 'text.long'"
                        matTooltip="Long Text"
                    >
                        article
                    </mat-icon>
                    <mat-icon
                        class="secondary"
                        *ngIf="field.type == 'date'"
                        matTooltip="Date"
                    >
                        event
                    </mat-icon>
                    <mat-icon
                        class="secondary"
                        *ngIf="field.type == 'number'"
                        matTooltip="Number"
                    >
                        tag
                    </mat-icon>
                </div>
            </ng-container>
            <ng-container *ngIf="formGroup && editing == field" [formGroup]="formGroup">
                <div class="column flex fixed icon-column">
                    <button class="inline" mat-icon-button (click)="cancelEdit($event)">
                        <mat-icon class="red">clear</mat-icon>
                    </button>
                    <button
                        class="inline"
                        mat-icon-button
                        (click)="formGroup.valid && commitEdit($event)"
                        [disabled]="!formGroup.valid"
                    >
                        <mat-icon class="green">check</mat-icon>
                    </button>
                </div>
                <div class="column flex stack fixed field-name" *ngIf="!isAdding">
                    <div>{{ field.displayName }}</div>
                    <div
                        class="secondary super-small"
                        *ngIf="field.displayName !== field.name && !!field.displayName"
                    >
                        {{ field.name }}
                    </div>
                </div>
                <div class="column flex stack fixed field-name" *ngIf="isAdding">
                    <mat-form-field>
                        <mat-label>Field Name</mat-label>
                        <input
                            matInput
                            placeholder="New Field Name"
                            formControlName="displayName"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            [displayWith]="optionToDisplayName"
                        >
                            <mat-option
                                *ngFor="let option of filteredOptions | async"
                                [value]="option"
                            >
                                {{ option.displayName }}
                                <span class="small secondary">{{ option.field }}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="formGroup.hasError('required', 'displayName')"
                        >
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div
                    class="column flex wrap flexible field-value"
                    *ngIf="editing == field"
                >
                    <mat-form-field
                        class="long-text flexible"
                        *ngIf="fieldType == 'text.long'"
                    >
                        <mat-label>Field Value</mat-label>
                        <textarea
                            #longTextArea
                            matInput
                            formControlName="value"
                            [cdkTextareaAutosize]="true"
                            [cdkAutosizeMinRows]="3"
                            [cdkAutosizeMaxRows]="12"
                            (keyup.enter)="addLineOnEnter($event, longTextArea)"
                        ></textarea>
                        <mat-error *ngIf="formGroup.hasError('required', 'value')">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="date flexible" *ngIf="fieldType == 'date'">
                        <mat-label>Field Value</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker"
                            formControlName="value"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="formGroup.hasError('required', 'value')">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field
                        class="short-text flexible"
                        *ngIf="fieldType == 'text.short' || fieldType == 'number'"
                    >
                        <mat-label>Field Value</mat-label>
                        <input matInput formControlName="value" />
                        <mat-error *ngIf="formGroup.hasError('required', 'value')">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                    <div class="column flex fixed types-column">
                        <ng-container *ngIf="canChangeFieldType(field)">
                            <button
                                class="inline"
                                mat-icon-button
                                (click)="changeFieldType($event, 'text.short')"
                                [disabled]="fieldType == 'text.short'"
                            >
                                <mat-icon matTooltip="Short Text">short_text</mat-icon>
                            </button>
                            <button
                                class="inline"
                                mat-icon-button
                                (click)="changeFieldType($event, 'text.long')"
                                [disabled]="fieldType == 'text.long'"
                            >
                                <mat-icon matTooltip="Long Text">article</mat-icon>
                            </button>
                            <button
                                class="inline"
                                mat-icon-button
                                (click)="changeFieldType($event, 'date')"
                                [disabled]="fieldType == 'date'"
                            >
                                <mat-icon matTooltip="Date">event</mat-icon>
                            </button>
                            <button
                                class="inline"
                                mat-icon-button
                                (click)="changeFieldType($event, 'number')"
                                [disabled]="fieldType == 'number'"
                            >
                                <mat-icon matTooltip="Number">tag</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!canChangeFieldType(field)">
                            <mat-icon
                                class="secondary"
                                *ngIf="fieldType == 'text.short'"
                                matTooltip="Short Text"
                            >
                                short_text
                            </mat-icon>
                            <mat-icon
                                class="secondary"
                                *ngIf="fieldType == 'text.long'"
                                matTooltip="Long Text"
                            >
                                article
                            </mat-icon>
                            <mat-icon
                                class="secondary"
                                *ngIf="fieldType == 'date'"
                                matTooltip="Date"
                            >
                                event
                            </mat-icon>
                            <mat-icon
                                class="secondary"
                                *ngIf="fieldType == 'number'"
                                matTooltip="Number"
                            >
                                tag
                            </mat-icon>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
