<div class="container" [formGroup]="formGroup">
    <div class="title">Properties</div>
    <div class="settings-section-content flex flexible row">
        <div class="flex flexible">
            <mat-form-field class="flexible header-field">
                <mat-label>Change Organization Name</mat-label>
                <input matInput formControlName="name" />
                <mat-error>Organization name cannot be empty.</mat-error>
            </mat-form-field>
            <mat-form-field
                class="flexible header-field organization-edit-field"
                *ngIf="!isHcpStaff"
            >
                <mat-label>Change Organization Slug</mat-label>
                <input matInput formControlName="slug" />
                <mat-hint>This is used in the URL for the intake form.</mat-hint>
                <mat-error>Slug cannot be empty.</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div
        class="settings-section-content flex flexible row"
        formGroupName="settings"
        *ngIf="!isHcpStaff"
    >
        <mat-form-field class="flexible margin-right">
            <mat-label>Shipment Form</mat-label>
            <mat-select formControlName="shipment_form">
                <mat-option *ngFor="let form of dataForms" [value]="form.id">
                    {{ form?.display_name ?? form.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="flexible">
            <mat-label>Adverse Event URL</mat-label>

            <input matInput formControlName="adverse_event" />
            <mat-hint>
                Make sure the URL starts with starts with "http://" or "https://".
            </mat-hint>
            <mat-error>
                Please enter a valid URL, starting with "http://" or "https://".
            </mat-error>
        </mat-form-field>
    </div>
    <div class="settings-section-content flex flexible row" *ngIf="!isHcpStaff">
        <div class="column flexible flex stack">
            <div class="title-1">Intake</div>
            <div class="intake-domain flex">
                <mat-checkbox
                    class="settings-checkbox"
                    name="disablePublicIntake"
                    ngDefaultControl
                    [formControl]="disablePublicIntake"
                    matTooltip="When checked, users will not be able to submit an intake from the page below."
                >
                    Disable Public Intake
                </mat-checkbox>
            </div>
            <div class="intake-domain flex">
                <button mat-icon-button [disabled]="true">
                    <mat-icon>public</mat-icon>
                </button>
                <a
                    *ngIf="!publicIntakeDisabled"
                    target="_blank"
                    rel="noopener noreferrer"
                    [href]="intakeWeb"
                >
                    {{ intakeWeb }}
                </a>
                <span *ngIf="publicIntakeDisabled" class="secondary">
                    {{ intakeWeb }}
                </span>
            </div>
        </div>
    </div>
    <div
        class="settings-section-content flex flexible row"
        *ngIf="currentAccount?.isSystemAdministrator && this.fullObject"
    >
        <div class="spacer"></div>
        <div class="">
            <button
                mat-flat-button
                color="accent"
                type="button"
                (click)="exportConfig($event)"
            >
                Export Organization Configuration
            </button>
        </div>
        <div class="spacer"></div>
    </div>
</div>
<case-status-admin
    *ngIf="!isHcpStaff"
    [owner]="fullObject"
    displayName="Case Statuses"
></case-status-admin>
<capability-admin
    [organization]="fullObject"
    [isPharma]="!isHcpStaff"
></capability-admin>
