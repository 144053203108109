<h3 mat-dialog-title>
    <span *ngIf="auditEntries.length > 1">Object Has Dependencies</span>
    <span *ngIf="auditEntries.length == 1">Confirm Restore Object</span>
</h3>
<mat-dialog-content>
    <div>
        <p *ngIf="auditEntries.length == 1">
            Are you sure you want to restore this object?
        </p>
        <p *ngIf="auditEntries.length > 1">
            The selected object had dependencies at the time of its deletion which must
            also be restored. Are you sure you want to restore
            {{ auditEntries.length }} objects?
        </p>
        <p>The follwing data will be restored:</p>
    </div>
    <div class="audit-state audit-dependencies">
        <pre *ngFor="let audit of auditEntries">{{ state(audit) }}</pre>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="flex">
    <div class="spacer"></div>
    <button mat-flat-button type="button" mat-dialog-close>Cancel</button>
    <button
        mat-raised-button
        type="button"
        color="warn"
        [mat-dialog-close]="auditEntries"
    >
        <mat-icon>warning</mat-icon>
        Restore
        <span *ngIf="auditEntries.length > 1">Objects</span>
    </button>
</mat-dialog-actions>
