import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Ordering, SearchableList } from "../utilities/searchable-list";

export type OrderingDirection = "ascending" | "descending" | "none";
export type OrderingChangedEvent = { field: string; direction: OrderingDirection };

@Component({
    selector: "[ordering]",
    template: `
        <ng-content></ng-content>
        <div class="ordering-controls">
            <button
                mat-icon-button
                type="button"
                class="ordering-button"
                *ngIf="isAscending"
                (click)="toggleDirection($event)"
            >
                <mat-icon>arrow_drop_up</mat-icon>
            </button>
            <button
                mat-icon-button
                type="button"
                class="ordering-button"
                *ngIf="isDescending || isUnset"
                [ngClass]="{ disabled: isUnset }"
                (click)="toggleDirection($event)"
            >
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
        </div>
    `,
    styles: [
        ".ordering-controls { display: flex; }",
        ".ordering-controls > button.mat-icon-button.ordering-button { margin: 0; }",
    ],
})
export class OrderingDirective {
    @Input("ordering") list!: SearchableList<any>;
    @Input("orderingField") field!: string;
    @Input("direction") set direction(v: OrderingDirection) {
        this.setDirection(v);
    }
    @Output() orderingChanged: EventEmitter<OrderingChangedEvent> =
        new EventEmitter<OrderingChangedEvent>(true);

    get direction(): OrderingDirection {
        return this._direction;
    }
    protected _direction: OrderingDirection = "none";

    get isAscending() {
        return this.direction === "ascending";
    }
    get isDescending() {
        return this.direction === "descending";
    }
    get isUnset() {
        return this.direction === "none";
    }
    get asOrdering(): Ordering | undefined {
        switch (this.direction) {
            case "ascending":
                return { field: this.field, ascending: true };
            case "descending":
                return { field: this.field, ascending: false };
            default:
                return undefined;
        }
    }

    toggleDirection(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (this.direction === "descending") this.direction = "ascending";
        else if (this.direction === "ascending") this.direction = "none";
        else this.direction = "descending";
    }
    protected setDirection(v: OrderingDirection): void {
        if (this._direction !== v) {
            this._direction = v;
            const newOrdering = this.list.ordering.filter(
                (o: Ordering) => o.field !== this.field,
            );
            if (v !== "none")
                newOrdering.unshift({
                    field: this.field,
                    ascending: v === "ascending",
                });
            this.list.ordering = newOrdering;
            this.orderingChanged.emit({ field: this.field, direction: this.direction });
        }
    }
}
