<mat-table [dataSource]="list.items">
    <ng-container matColumnDef="task">
        <mat-header-cell *matHeaderCellDef>Task Name</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment; let index = index"
            (click)="
                asObject(assignment).assignee?.id === currentAccount?.id &&
                    assignment.pending &&
                    handleClick($event, assignment)
            "
            (keydown)="onKeyPress($event)"
            (keypress)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
        >
            @if (!isReference(assignment)) {
                <div
                    class="flex center"
                    style="padding-right: 0.5rem"
                    [class.nested]="isNested"
                    [matBadge]="matBadge(assignment)"
                    matBadgeColor="accent"
                    matBadgeOverlap="false"
                    matBadgePosition="after"
                >
                    <div
                        [class.pointer]="!assignment.completed"
                        *ngIf="!isCaseClosed && taskEnabledOrCompleted(assignment)"
                    >
                        <mat-checkbox
                            [disabled]="
                                !taskEnabled(assignment) ||
                                (viewOnly &&
                                    asObject(assignment).assignee?.id !==
                                        currentAccount?.id)
                            "
                            [checked]="assignment.completed"
                        ></mat-checkbox>
                    </div>

                    @if (!isNested && assignmentHasFrequency(assignment)) {
                        <button
                            mat-icon-button
                            (click)="
                                $event.stopPropagation();
                                toggleFrequencyTable(index, assignment)
                            "
                        >
                            <mat-icon>
                                {{
                                    expandedRow?.id === assignment?.id ?
                                        "expand_less"
                                    :   "expand_more"
                                }}
                            </mat-icon>
                        </button>
                    }

                    <span
                        class="task-action"
                        [class.disabled]="!asObject(assignment).pending"
                        (click)="onActionLinkClick(assignment, $event)"
                        (keydown)="onKeyPress($event)"
                        (keypress)="onKeyDown($event)"
                        (keyup)="onKeyUp($event)"
                    >
                        {{ getTaskAction(assignment) }}
                    </span>
                    <span>
                        {{ getTaskTitle(assignment) }}
                    </span>
                </div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let assignment">
            @if (!isReference(assignment)) {
                <div
                    class="flex center"
                    [matTooltip]="statusTooltip(assignment)"
                    [class.completed-icon]="!!assignment.completed"
                >
                    <mat-icon class="status-icon">
                        {{ statusDetails(assignment).icon }}
                    </mat-icon>

                    {{ statusDetails(assignment).value }}
                </div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="due_date">
        <mat-header-cell *matHeaderCellDef>Due By</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment"
            (click)="
                canEditTask(assignment) &&
                    editFrequency(assignment, true, 'Edit Triggers')
            "
            (keydown)="onKeyPress($event)"
            (keypress)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
            [class.pointer]="canEditTask(assignment)"
            [class.notAllowed]="assignment.completed || !canEditTask(assignment)"
            class="flex center"
        >
            @if (!isReference(assignment)) {
                <div>{{ dueByColumnValue(assignment) }}</div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="frequency">
        <mat-header-cell *matHeaderCellDef>Frequency</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment"
            (click)="canEditTask(assignment) && editFrequency(assignment)"
            (keydown)="onKeyPress($event)"
            (keypress)="onKeyDown($event)"
            (keyup)="onKeyUp($event)"
            class="frequency-cell"
            [class.pointer]="canEditTask(assignment)"
            [class.notAllowed]="!canEditTask(assignment)"
        >
            @if (!isReference(assignment)) {
                <div>{{ frequencyValue(assignment) }}</div>
                <div class="small">
                    <em>{{ frequencySubTitle(assignment) }}</em>
                </div>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef>Assignee</mat-header-cell>
        <mat-cell
            *matCellDef="let assignment"
            [class.notAllowed]="!canEditTask(assignment)"
        >
            @if (!isReference(assignment)) {
                <mat-select
                    [(ngModel)]="assignment.assignee"
                    [disabled]="
                        !canEditTask(assignment) ||
                        !!assignment.completed ||
                        isDocusignTask(assignment)
                    "
                    (selectionChange)="hanldeAssigneeChange($event, assignment)"
                >
                    <mat-option
                        *ngFor="let assignee of availableAssignees"
                        [value]="assignee.account"
                    >
                        {{ assignee.account.name }}
                    </mat-option>
                </mat-select>
            } @else {
                <div
                    *ngIf="isReference(assignment)"
                    referenceWrapper
                    [primary]="true"
                ></div>
            }
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
        <mat-cell *matCellDef="let assignment" class="overflow">
            <button
                *ngIf="isObject(assignment)"
                mat-icon-button
                [matMenuTriggerFor]="overflow"
                [matMenuTriggerData]="{ assignment: assignment }"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <mat-menu #overflow="matMenu">
        <ng-template matMenuContent let-assignment="assignment">
            <button
                mat-menu-item
                *ngIf="!isSummaryTask(assignment)"
                [disabled]="!canViewTask(assignment)"
                (click)="canViewTask(assignment) && viewTask($event, assignment)"
            >
                View
            </button>
            <button
                mat-menu-item
                *ngIf="!isSummaryTask(assignment)"
                [disabled]="!canEditTask(assignment)"
                (click)="canEditTask(assignment) && editTask($event, assignment)"
            >
                Edit Properties
            </button>
            @if (
                !isSummaryTask(assignment) &&
                !isDocusignTask(assignment) &&
                !!asAssignment(assignment).completed
            ) {
                <button
                    mat-menu-item
                    [disabled]="!canReopenTask(assignment)"
                    (click)="completeTaskWithConfirmation(assignment)"
                >
                    Reopen Task
                </button>
            } @else if (!isSummaryTask(assignment) && !isDocusignTask(assignment)) {
                <button
                    mat-menu-item
                    [disabled]="!canCompleteTask(assignment)"
                    (click)="
                        canCompleteTask(assignment) &&
                            completeTaskWithConfirmation(assignment)
                    "
                >
                    Mark Completed
                </button>
            }
            <button
                mat-menu-item
                [disabled]="
                    !asObject(assignment).pending ||
                    asObject(assignment).completed ||
                    viewOnly ||
                    assigneeIsCurrentUser(assignment)
                "
                (click)="sendReminder($event, assignment)"
            >
                Send Reminder
            </button>
            <button
                *ngIf="hasNotification(assignment)"
                mat-menu-item
                (click)="markNotificationAsRead(assignment)"
            >
                Mark Read
            </button>
        </ng-template>
    </mat-menu>
    <mat-header-row
        [class.none]="isNested"
        *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row
        #tableRow
        [id]="row.id"
        *matRowDef="let row; columns: displayedColumns; let index = index"
    ></mat-row>
</mat-table>
