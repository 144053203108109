<div class="container">
    <div class="programs-header flex">
        <div class="title" [id]="list.listTitle">Programs</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search margin-right"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Programs</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Programs"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Create Program
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell
                *matCellDef="let program"
                (click)="editObject($event, program, true)"
            >
                {{ program.displayName }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deleted">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell
                *matCellDef="let program"
                (click)="editObject($event, program, true)"
            >
                <mat-chip-set>
                    <mat-chip *ngIf="program.deleted" color="warn" selected>
                        Closed
                    </mat-chip>
                    <mat-chip *ngIf="!program.deleted" color="accent" selected>
                        Open
                    </mat-chip>
                </mat-chip-set>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let program" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ program: program }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="list.searching && !list.hasLoaded">
                    <mat-progress-spinner
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                    Loading...
                </span>
                <span *ngIf="list.hasLoaded && !list.list.items.length">
                    No programs found.
                </span>
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-program="program">
                <button mat-menu-item (click)="editObject($event, program, true)">
                    Edit
                </button>
                <button
                    mat-menu-item
                    *ngIf="!program.deleted"
                    (click)="
                        isOrganizationAdministrator && disableObject($event, program)
                    "
                    [disabled]="!isOrganizationAdministrator"
                >
                    Close Program
                </button>
                <button
                    mat-menu-item
                    *ngIf="program.deleted"
                    (click)="
                        isOrganizationAdministrator && reopenProgram($event, program)
                    "
                    [disabled]="!isOrganizationAdministrator"
                >
                    Re-Open Program
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
