import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export enum CASE_TAB_NAMES {
    DOCUMENTS = "documents",
    DISCUSSION = "discussion",
    COMMUNICATIONS = "communications",
    CASE_DETAILS = "case details",
    DATA = "forms",
    SHIPMENTS = "shipments",
    TEAM = "team",
    UNKNOWN = "",
    SEND_MESSAGE_DIALOG = "send_message_dialog",
    CHECK_LIST = "checklist",
    CASE_SUMMARY = "case summary",
    AUDIT = "audit",
}

export interface TabChangeEvent {
    tabName: CASE_TAB_NAMES;
    data?: any;
}
@Injectable()
export class TabChangeService {
    tabWillChange = new Subject<TabChangeEvent>();
    changeTab(tabName: CASE_TAB_NAMES, data: any) {
        this.tabWillChange.next({ tabName, data });
    }
}
