import { CaseTeam, TeamMember } from "./team";
import { APIObject } from "src/services/models/api-object";
import { NamedObject, ObjectReference } from "./api-object";
import { Inquiry } from "./inquiry";
import { Account } from "./account";
import { Template } from "./template";
import { DisplayNameMap } from "src/common/utilities/utilities";
import { CaseTeamService } from "../program.services";
import { Observable, Subject } from "rxjs";

/*
Case Object
    A Case object represents an inquiry for which an Organization has decided to engage in discussion, either internally,
    or with the sending physician.  Each party to an inquiry has their own instance of a Case object, derived from the initial
    Inquiry. (ie the physician's Case object is distinct from the Organization's Case object).  Any information that the
    physician or Organization want to use for purely internal discussion/management is stored in their respective Case object
    whereas, anything that they want to share with each other is stored in the Inquiry object that the Cases were derived from.
    If an internal data field (or other object) needs to be shared, it is removed from the Case object and added to the Inquiry
    object.

    When converting an Inquiry to a Case, the owner should be set to the same owner as the assigned Program from the generating
    Inquiry.  When communicating with a sending physician, the physician instance of the Case can have the owner set to the
    physician's Account object.

    To provide other staff members at an Organization with access to a Case document, a Role can be added to the case for any Account.
 */
export class Case extends NamedObject {
    static object_type: string = "program.case";
    owner!: ObjectReference; // Account or Organization
    shared!: Inquiry;
    data: any; // optional
    template?: ObjectReference; // write-only, Template, optional
    status?: string; // write-only, optional
    workflow?: string;
    workflow_template?: ObjectReference;
    primary!: ObjectReference;
    secondary!: ObjectReference[];
    is_physician!: boolean;
    physician_institution!: string;
    physician_name!: string;
    static RejectionOptions: DisplayNameMap[] = [
        { value: "declined", displayName: "Patient Declined Treatment" },
        { value: "terminated", displayName: "Treatment Terminated" },
        { value: "completed", displayName: "Treatment Completed" },
        { value: "withdraw", displayName: "Withdrawal of request" },
        { value: "other", displayName: "Other" },
    ];
    get isCaseActive() {
        const activeStatuses = Inquiry.OpenStatuses;
        return activeStatuses.some((status) => status.value === this.shared?.status);
    }

    get program(): ObjectReference | undefined {
        return this.shared.program;
    }

    get isCaseClosed() {
        const closedStatuses = Inquiry.ClosedStatuses;
        return (
            closedStatuses.some((status) => status.value === this.shared?.status) &&
            !this.isCaseActive
        );
    }
    get teams(): CaseTeam[] | undefined {
        return this.shared.teams;
    }
    get reference_identifier(): number {
        return this.shared.reference_identifier;
    }

    initialize(data?: any, patch: boolean = false) {
        this._optional.push(...["data", "template", "status", "primary", "secondary"]);
        this._readOnly.push(
            ...["roles", "is_physician", "physician_name", "physician_institution"],
        );
        super.initialize(data, patch);

        this.setMember(data, patch, "owner", ObjectReference);
        this.setMember(data, patch, "shared", Inquiry);
        this.setMember(data, patch, "data");
        this.setMember(data, patch, "template", Template);
        this.setMember(data, patch, "status");
        this.setMember(data, patch, "workflow");
        this.setMember(data, patch, "workflow_template");
        this.setMember(data, patch, "primary", ObjectReference);
        this.setMember(data, patch, "secondary", ObjectReference, true);
        this.setMember(data, patch, "is_physician");
        this.setMember(data, patch, "physician_institution");
        this.setMember(data, patch, "physician_name");
    }
    // teams list slowed down case loading. A way to lazy load it in the background

    initTeams(CaseTeamService: CaseTeamService): Observable<void> {
        const subject = new Subject<void>();
        CaseTeamService.list({ inquiry: this.shared.id! }).subscribe((teams) => {
            this.shared.teams = teams as CaseTeam[];
            subject.next();
        });
        return subject.asObservable();
    }

    isMemberOfTeam(
        account: ObjectReference | Account | undefined,
        team?: string,
    ): TeamMember | undefined {
        return this.shared.isMemberOfTeam(account, team);
    }
    teamMembers(team?: string, role?: string): TeamMember[] {
        return this.shared.teamMembers(team, role);
    }
    teamMember(team?: string, role?: string): TeamMember | undefined {
        return this.shared.teamMember(team, role);
    }
    team(capacity: string): CaseTeam | undefined {
        return this.shared.team(capacity);
    }
    permissions(
        account: ObjectReference | Account | undefined,
        capacity?: string,
    ): string {
        return this.shared.permissions(account, capacity);
    }
    isPharmaStaff(account?: ObjectReference | Account): boolean {
        return this.shared.isPharmaStaff(account);
    }
    isPhysicianStaff(account?: ObjectReference | Account): boolean {
        return this.shared.isPhysicianStaff(account);
    }
    caseTeam(account?: ObjectReference | Account): CaseTeam | undefined {
        return this.shared.caseTeam(account);
    }
    isAdmin(account?: ObjectReference | Account): boolean {
        return this.shared.isAdmin(account);
    }
    isEditor(account?: ObjectReference | Account): boolean {
        return this.shared.isEditor(account);
    }
    isViewer(account?: ObjectReference | Account): boolean {
        return this.shared.isViewer(account);
    }
    get physician(): TeamMember | undefined {
        return this.shared.physician;
    }
    get physicianName(): string {
        return this.physician_name;
    }
    get physicianInstitution(): string {
        return this.physician_institution;
    }
    get contacts(): TeamMember[] {
        return this.shared.contacts;
    }

    isInvited(member: TeamMember): boolean {
        return !!member.is_invited && member.permissionLevel != "object.none";
    }

    teamTab(currentAccount: Account) {
        return ([] as TeamMember[]).concat
            .apply(
                [],
                this?.shared?.teams?.map((ct: CaseTeam) => {
                    const isMember = !!ct.members.find(
                        (tm: TeamMember) => tm.account.id == currentAccount?.id,
                    );
                    return ct.members.filter(
                        (tm: TeamMember) => isMember || !tm.private,
                    );
                }) ?? [],
            )
            .filter((tm: TeamMember) => {
                const unInvitedPhysician =
                    tm.role === "physician" && !this.isInvited(tm);
                return unInvitedPhysician || tm.permissionLevel != "object.none";
            });
    }
}

export class CaseAudit extends APIObject {
    timestamp!: Date;
    account!: ObjectReference;
    entry_type!: string;
    event!: string;
    shared!: boolean;
    details?: any;

    initialize(data?: any, patch: boolean = false) {
        this.setMember(data, patch, "timestamp", Date);
        this.setMember(data, patch, "account", ObjectReference);
        this.setMember(data, patch, "entry_type");
        this.setMember(data, patch, "event");
        this.setMember(data, patch, "shared");
        this.setMember(data, patch, "details");
    }
}

type StatusAttributes = {
    is_default_inquiry_status: boolean;
    closes_case: boolean;
    is_inquiry_status: boolean;
    is_no_status: boolean;
};
export class Status extends APIObject {
    static object_type: string = "program.status";
    name!: string;
    display_name!: string;
    owner!: ObjectReference;

    attributes!: StatusAttributes;

    initialize(data?: any, patch: boolean = false): void {
        super.initialize(data, patch);
        this.setMember(data, patch, "name");
        this.setMember(data, patch, "display_name");
        this.setMember(data, patch, "attributes");
        this.setMember(data, patch, "owner", ObjectReference);
    }
}
