import { Component, Input } from "@angular/core";
import { BaseDataTypeComponent } from "./data-type.component";

@Component({
    selector: "dt-radio",
    template: `
        <p *ngIf="viewOnly && !hasValues && compoundIndex == 0" class="secondary">
            Value not provided
        </p>
        <p *ngIf="viewOnly && hasValues">{{ displayValue }}</p>
        <mat-radio-group
            class="data-form-radio-container"
            [formControl]="control"
            *ngIf="!viewOnly"
        >
            <span *ngFor="let radio of options">
                <mat-radio-button class="radio" [value]="radio.value" color="primary">
                    {{ radio.display_name }}
                </mat-radio-button>
            </span>
        </mat-radio-group>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeRadio extends BaseDataTypeComponent {
    protected _options: { value: string; display_name: string }[] = [];
    @Input() set options(opts: { value: string; display_name: string }[]) {
        this._options = opts || [];
    }
    get options(): { value: string; display_name: string }[] {
        return this.attributes?.options || this._options;
    }
}
