import { Injectable } from "@angular/core";
import { APIService } from "./api.service";
import { Shipment } from "./models/shipment";

/*
Shipment Service
    - list, create, retrieve, update, delete operations supported normally
    - available list filters:
        case: a Case id - will return all shipments associated with the specified case
 */
@Injectable()
export class ShipmentService extends APIService<Shipment> {
    constructor() {
        super(Shipment, ["shipping", "shipment"]);
    }
}
