import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Component, Input, ViewChild, inject } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { OptionalEmailValidator } from "src/common/utilities/validators";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { mergeMap } from "rxjs/operators";
import { NEVER } from "rxjs";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import {
    DataFieldDefinition,
    DataType,
    Organization,
    OrganizationSettings,
} from "src/services/models/organization";
import { CapabilityService, OrganizationService } from "src/services/iam.services";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { StatusService } from "src/services/program.services";
import {
    CompoundDataTypeFactory,
    DataFormFieldConditionService,
    DataFormService,
} from "src/services/data.services";
import { Status } from "src/services/models/case";
import { Program } from "src/services/models/program";
import { ExportCaseDataDialog } from "./ExportCaseDataDialog";
import { DatePipe } from "@angular/common";
import { ObjectOrReference } from "src/services/models/api-object";
import { CapabilityAdminComponent } from "./capability/capability-admin.component";
import { Capability } from "src/services/models/capability";
import { OrganizationProperties } from "./org-properties/org-properties.component";

@Component({
    selector: "organization",
    templateUrl: "./organization.component.html",
    styleUrls: ["./organization.component.scss"],
})
export class OrganizationComponent extends ObjectComponent<Organization> {
    objectName = "Organization";
    templateLogo?: string;
    uploader?: FileUploader;
    fileOver: boolean = false;

    autosave = true;
    mode = ObjectViewMode.Edit;
    dataFormService: DataFormService;
    caseStatusService: StatusService;
    @Input() isHcpStaff: boolean | undefined;
    datePipe: DatePipe;

    constructor(
        protected service: OrganizationService,
        dataFormService: DataFormService,
        caseStatusService: StatusService,
    ) {
        super(service);
        this.dataFormService = dataFormService;
        this.caseStatusService = caseStatusService;
        inject(CompoundDataTypeFactory);
        inject(DataFormFieldConditionService);
        inject(CapabilityService);
        this.datePipe = inject(DatePipe);
    }

    get intakeAddress(): string {
        const id = this.object?.id?.replace(/-/g, "");
        return id ? id + "@" + this.service.session.environment.intakeDomain : "";
    }
    get intakeWeb(): string {
        const orgSlug = this.fullObject?.slug;
        return window.location.origin + "/intake/" + orgSlug;
    }
    get brandingFormGroup(): UntypedFormGroup {
        return this.formGroup.get("settings") as UntypedFormGroup;
    }

    // settings flags
    disableRedaction: UntypedFormControl = new UntypedFormControl();
    hideExternalRedaction: UntypedFormControl = new UntypedFormControl();
    hideInternalRedaction: UntypedFormControl = new UntypedFormControl();

    // Entitlements controls - if this gets more complicated, break it out into it's own component JT
    enableRedaction: UntypedFormControl = new UntypedFormControl();
    enableFormBuilder: UntypedFormControl = new UntypedFormControl();
    inquiryOnly: UntypedFormControl = new UntypedFormControl();

    get redactionEntitled(): boolean {
        const entitlementsControl = this.formGroup.get("entitlements");
        return !!entitlementsControl?.value?.redaction?.enabled;
    }
    get formBuilderEntitled(): boolean {
        const entitlementsControl = this.formGroup.get("entitlements");
        return !!entitlementsControl?.value?.formBuilder?.enabled;
    }

    get canUseFormBuilder(): boolean {
        return (
            this.fullObject?.isEntitlementEnabled("formBuilder") ||
            this.isSystemAdministrator
        );
    }

    exportCaseData(event: MouseEvent) {
        this.terminateEvent(event);
        this.dialog
            .open(ExportCaseDataDialog, {
                data: {
                    organization: this.fullObject,
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .subscribe((v?: ObjectOrReference<Program>[]) => {
                if (!v) return;

                const date = this.datePipe
                    .transform(new Date(), "mediumDate")
                    ?.replaceAll(", ", "_")
                    .replaceAll(" ", "_");
                // Only use the programs that were selected from the dialog, not the entire list
                const programNames = v!.map((p) => p.displayName).join("_");
                const fileName = `${programNames}_Export_${date}.xlsx`;
                this.dataFormService.exportProgramsData(v, this.fullObject!, fileName);
                this.snackbar.open(
                    "Your download may take a few minutes. You can find it in your downloads once completed.",
                    undefined,
                    { duration: 5000 },
                );
            });
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.enableRedaction.valueChanges.subscribe((checked: boolean) => {
            if (this.currentAccount?.isSystemAdministrator) {
                const entitlementsControl = this.formGroup.get("entitlements");
                if (entitlementsControl?.value?.redaction?.enabled != checked) {
                    let entitlements = entitlementsControl?.value;
                    if (!entitlements)
                        entitlements = { redaction: { enabled: checked } };
                    else if ("redaction" in entitlements)
                        entitlements.redaction.enabled = checked;
                    else entitlements.redaction = { enabled: checked };
                    entitlementsControl?.setValue(entitlements);
                    entitlementsControl?.markAsDirty();
                }
            }
        });
        this.enableFormBuilder.valueChanges.subscribe((checked: boolean) => {
            if (this.currentAccount?.isSystemAdministrator) {
                const entitlementsControl = this.formGroup.get("entitlements");
                if (entitlementsControl?.value?.formBuilder?.enabled != checked) {
                    let entitlements = entitlementsControl?.value;
                    if (!entitlements)
                        entitlements = { formBuilder: { enabled: checked } };
                    else if ("formBuilder" in entitlements)
                        entitlements.formBuilder.enabled = checked;
                    else entitlements.formBuilder = { enabled: checked };
                    entitlementsControl?.setValue(entitlements);
                    entitlementsControl?.markAsDirty();
                }
            }
        });
        this.inquiryOnly.valueChanges.subscribe((checked: boolean) => {
            if (this.currentAccount?.isSystemAdministrator) {
                const entitlementsControl = this.formGroup.get("entitlements");
                checked = !checked; // we use reverse syntax for disabling (inquiryOnly=True => case.enabled=false)
                if (entitlementsControl?.value?.case?.enabled != checked) {
                    let entitlements = entitlementsControl?.value;
                    if (!entitlements) entitlements = { case: { enabled: checked } };
                    else if ("case" in entitlements)
                        entitlements.case.enabled = checked;
                    else entitlements.case = { enabled: checked };
                    entitlementsControl?.setValue(entitlements);
                    entitlementsControl?.markAsDirty();
                }
            }
        });
        this.disableRedaction.valueChanges.subscribe((checked: boolean) => {
            const settingsGroupControl = this.formGroup.get(
                "settings",
            ) as UntypedFormGroup;
            const settingsControl = settingsGroupControl?.get("settings");
            if (settingsControl?.value?.redaction?.disabled != checked) {
                let settings = settingsControl?.value;
                if (!settings) settings = { redaction: {} };
                else if (!("redaction" in settings)) settings.redaction = {};
                settings.redaction.disabled = checked;
                settingsControl?.setValue(settings);
                settingsControl?.markAsDirty();
            }
        });
        this.hideExternalRedaction.valueChanges.subscribe((checked: boolean) => {
            const settingsGroupControl = this.formGroup.get(
                "settings",
            ) as UntypedFormGroup;
            const settingsControl = settingsGroupControl?.get("settings");
            if (settingsControl?.value?.redaction?.hideExternal != checked) {
                let settings = settingsControl?.value;
                if (!settings) settings = { redaction: {} };
                else if (!("redaction" in settings)) settings.redaction = {};
                settings.redaction.hideExternal = checked;
                settingsControl?.setValue(settings);
                settingsControl?.markAsDirty();
            }
        });
        this.hideInternalRedaction.valueChanges.subscribe((checked: boolean) => {
            const settingsGroupControl = this.formGroup.get(
                "settings",
            ) as UntypedFormGroup;
            const settingsControl = settingsGroupControl?.get("settings");
            if (settingsControl?.value?.redaction?.hideInternal != checked) {
                let settings = settingsControl?.value;
                if (!settings) settings = { redaction: {} };
                else if (!("redaction" in settings)) settings.redaction = {};
                settings.redaction.hideInternal = checked;
                settingsControl?.setValue(settings);
                settingsControl?.markAsDirty();
            }
        });
    }

    get isOrganizationAdministrator(): boolean {
        return !!this.currentAccount?.hasRole("object.admin", this.object);
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            name: [null, Validators.required],
            entitlements: [null],
            settings: this.formBuilder.group({
                contact_address: [null],
                contact_phone: [null],
                contact_email: [null, OptionalEmailValidator],
            }),
        });
    }
    updateObjectForm(): void {
        this.formGroup.removeControl("settings");
        this.formGroup.removeControl("slug");
    }

    get templateLogoName(): string | undefined {
        const index =
            (this.fullObject?.settings?.template_logo?.lastIndexOf("/") ?? 0) + 1;
        return this.fullObject?.settings?.template_logo?.substring(index);
    }

    get templateLogoSource(): string | undefined {
        if (this.fullObject?.settings?.template_logo)
            return (
                this.service.session.environment.services +
                "/media/" +
                this.fullObject.settings.template_logo
            );
        return "";
    }

    removeLogo(event: MouseEvent): void {
        this.terminateEvent(event);
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: "Are you sure you want to delete the branding logo?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) =>
                    confirm ? this.service.removeLogo(this.fullObject!) : NEVER,
                ),
            )
            .subscribe((logo?: string) => {
                if (logo !== undefined && this.fullObject?.settings) {
                    this.fullObject.settings.template_logo = logo;
                } else if (this?.fullObject?.settings) {
                    //logo was deleted
                    this.fullObject.settings.template_logo = undefined;
                }
            });
    }

    get availableDataTypes(): DataType[] {
        return OrganizationSettings.DataTypes;
    }
    get dataFields(): DataFieldDefinition[] {
        return this.dataFields_;
    }
    set dataFields(v: DataFieldDefinition[]) {
        this.dataFields_ = v;
        const fg = this.formGroup.value;
        fg.settings.data_types = v;
        this.onAutosave(fg);
    }
    dataFields_: DataFieldDefinition[] = [];
    dataField?: DataFieldDefinition;
    dataFieldIndex?: number;
    dataFieldAdding: boolean = false;
    editDataField(event: MouseEvent, index: number): void {
        this.terminateEvent(event);
        const field = this.dataFields[index];
        this.dataField = { ...field };
        this.dataFieldIndex = index;
    }
    saveDataFields(event?: MouseEvent): void {
        if (this.dataField && this.dataFieldIndex !== undefined)
            this.dataFields_[this.dataFieldIndex] = this.dataField;
        this.dataFields = this.dataFields_;
        this.dataFieldAdding = false;
        this.cancelEditDataField(event);
    }
    createDataField(event: MouseEvent): void {
        const field = {
            field: undefined,
            displayName: undefined,
            type: OrganizationSettings.DataTypes[0].type,
        };
        const index = this.dataFields_.length;
        this.dataFields_ = [...this.dataFields_, field];
        this.dataFieldAdding = true;
        this.editDataField(event, index);
    }
    removeDataField(event: MouseEvent, index: number): void {
        this.terminateEvent(event);
        this.dataFields_.splice(index, 1);
        this.dataFields = this.dataFields_;
    }
    cancelEditDataField(event?: MouseEvent): void {
        this.terminateEvent(event);
        if (this.dataFieldAdding) {
            this.dataFields_.splice(this.dataFields_.length - 1, 1);
            this.dataFieldAdding = false;
        }
        this.dataFieldIndex = undefined;
        this.dataField = undefined;
    }
    drop(event: CdkDragDrop<DataFieldDefinition[]>) {
        moveItemInArray(this.dataFields_, event.previousIndex, event.currentIndex);
        this.dataFields_ = this.dataFields_.map(
            (f: DataFieldDefinition, index: number) => {
                return { ...f, order: index };
            },
        );
        this.saveDataFields();
    }
    displayNameForDataType(type: string): string | undefined {
        return OrganizationSettings.displayNameForDataType(type);
    }
    get dataFieldIsValid(): boolean {
        return (
            !!this.dataField?.field &&
            !!this.dataField?.displayName &&
            !!this.dataField?.type
        );
    }

    protected precommitTransform(v: any): any {
        if (this.exists) {
            this.usePatch = true;
        }

        if (this.mode != ObjectViewMode.Create) {
            // TODO: Consider doing this generally for any optional field in an object
            if (v.settings.contact_address == "") v.settings.contact_address = null;
            else if (!v.settings.contact_address) delete v.settings.contact_address;
            if (v.settings.contact_phone == "") v.settings.contact_phone = null;
            else if (!v.settings.contact_phone) delete v.settings.contact_phone;
            if (v.settings.contact_email == "") v.settings.contact_email = null;
            else if (!v.settings.contact_email) delete v.settings.contact_email;
        }
        if (!this.currentAccount?.isSystemAdministrator) {
            // only a system admin can update entitlements
            delete v.entitlements;
        }
        if (v?.settings) {
            v.settings.organization = this.object?.asReference.serialize();
        }
        return v;
    }

    protected resetObject(v: Organization) {
        if (!v.settings) {
            const t: any = { ...v };
            delete t.settings;
            super.resetObject(t);
        } else super.resetObject(v);
        const settings: UntypedFormGroup = this.formGroup.get(
            "settings",
        ) as UntypedFormGroup;
        settings?.get("shipment_form")?.setValue(v?.settings?.shipment_form?.id);
    }
    protected setObject(v?: Organization) {
        super.setObject(v);

        this.enableRedaction.setValue(
            !!this.fullObject?.isEntitlementEnabled("redaction"),
        );
        this.enableFormBuilder.setValue(
            !!this.fullObject?.isEntitlementEnabled("formBuilder"),
        );
        this.inquiryOnly.setValue(!this.fullObject?.isEntitlementEnabled("case", true)); // default if unset is true
        this.disableRedaction.setValue(
            !!this.fullObject?.setting("redaction", "disabled"),
        );
        this.hideExternalRedaction.setValue(
            !!this.fullObject?.setting("redaction", "hideExternal"),
        );
        this.hideInternalRedaction.setValue(
            !!this.fullObject?.setting("redaction", "hideInternal"),
        );

        if (this.mode == ObjectViewMode.Create) this.objectName = "Create Organization";
        else if (this.mode == ObjectViewMode.Edit)
            this.objectName =
                "Edit " + (this.object?.displayName ?? "Organization") + " Settings";
        else
            this.objectName =
                "View " + (this.object?.displayName ?? "Organization") + " Settings";

        this.initializeUploader();
    }

    protected initializeUploader(): void {
        this.service
            .logoUploader(this.object)
            .subscribe((uploader?: FileUploader) => (this.uploader = uploader));
    }

    protected onCommitSuccess(v: Organization): boolean {
        const ret = super.onCommitSuccess(v);
        this.snackbar.open("Saved changes Succesfully.", undefined, { duration: 2000 });
        return ret;
    }
    protected onCommitError(err: any): void {
        console.error(err);
        // possibly log this on Rollbar or any other logging service ---- TODO
        this.snackbar.open("Error saving changes.", undefined, { duration: 5000 });
    }
}
