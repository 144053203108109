<data-filter
    *ngIf="showFilter"
    [dateFilterConfigs]="dateFilterConfigurations"
    [dynamicOptions]="{
        assignedTo: assignees,
        caseName: caseNames,
        status: todoStatuses
    }"
    [filterDefinitions]="filterDefinitions"
    [filterFormGroup]="filterFormGroup"
    [filterTitle]="title"
    [getOptionValue]="getOptionValue"
    [getOptionDisplay]="getOptionDisplay"
    [isOptionDisabled]="isOptionDisabled"
    (cancelFilter)="cancel($event)"
    (clearAllFilters)="clearAllFilters($event)"
    (resetFilter)="resetFilter($event)"
></data-filter>

<div class="todos-container">
    <div class="todos-header flex">
        <div class="title" [id]="list.listTitle">{{ title }}</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <ng-container>
                <mat-form-field
                    color="primary"
                    *ngIf="!isSearchEmpty"
                    class="case-search"
                >
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <mat-label>Search Tasks</mat-label>
                    <input
                        matInput
                        #search
                        placeholder="Enter at least 3 characters to search"
                        [formControl]="searchTermControl"
                        (focusout)="onFocusOut($event)"
                    />
                    <button
                        matSuffix
                        type="button"
                        mat-icon-button
                        (click)="resetSearchTerm($event)"
                        *ngIf="!!searchTermControl.value"
                        matTooltip="Reset Search"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <button
                    mat-flat-button
                    class="brand-medium"
                    *ngIf="isSearchEmpty"
                    (click)="toggleSearch($event)"
                    matTooltip="Search Tasks"
                >
                    <mat-icon>search</mat-icon>
                    Search
                </button>
            </ng-container>
            <ng-container>
                <button
                    [disabled]="!list.hasLoaded || !list.list.items.length"
                    mat-flat-button
                    class="brand-medium"
                    *ngIf="!showFilter"
                    (click)="toggleFilter($event)"
                    matTooltip="Filter Tasks"
                >
                    <mat-icon>filter_list</mat-icon>
                    Filter
                </button>
            </ng-container>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="case">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Case</mat-header-cell>
            <mat-cell
                *matCellDef="let assignment"
                class="bold"
                (click)="showTask($event, assignment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                {{ assignment.case_name }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="task__name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Task</mat-header-cell>
            <mat-cell
                *matCellDef="let assignment"
                (click)="showTask($event, assignment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                {{ assignment.displayName }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignee">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Assigned To
            </mat-header-cell>
            <mat-cell
                *matCellDef="let assignment"
                (click)="showTask($event, assignment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <div *ngIf="!assignment.assignee" class="secondary">Not Assigned</div>
                <div *ngIf="!!assignment.assignee && !assigneeIsMe(assignment)">
                    {{ assignment.assignee.displayName }}
                </div>
                <div *ngIf="assigneeIsMe(assignment)">You</div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assigned_on">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Assigned On
            </mat-header-cell>
            <mat-cell *matCellDef="let assignment">
                {{
                    isToday(assignment.assigned_on) ?
                        (assignment.assigned_on | date: "shortTime")
                    :   (assignment.assigned_on | localizedDate)
                }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell
                *matCellDef="let assignment"
                (click)="showTask($event, assignment)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <mat-chip-set>
                    <mat-chip
                        *ngIf="isNew(assignment) && !isDueSoon(assignment)"
                        color="accent"
                        selected
                    >
                        New
                    </mat-chip>
                    <mat-chip
                        *ngIf="
                            isDueSoon(assignment) &&
                            !isNew(assignment) &&
                            !isOverdue(assignment)
                        "
                        color="utility"
                        class="warning"
                        selected
                    >
                        Due Soon
                    </mat-chip>
                    <mat-chip *ngIf="isOverdue(assignment)" color="warn" selected>
                        Overdue
                    </mat-chip>
                    <mat-chip *ngIf="isPending(assignment)">Pending</mat-chip>
                </mat-chip-set>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="table-footer">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                <span *ngIf="list.searching && !list.hasLoaded">
                    <mat-progress-spinner
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                    Loading...
                </span>
                <span *ngIf="list.hasLoaded && !list.list.items.length">
                    No open to do's yet!
                </span>
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.hidden-rows]="list.searching && !list.hasLoaded"
        ></mat-row>
        <mat-footer-row *matFooterRowDef="['table-footer']"></mat-footer-row>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
