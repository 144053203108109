import { Component, inject, Input, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { DataFormService } from "src/services/data.services";
import { OrganizationService } from "src/services/iam.services";
import { DataForm } from "src/services/models/data";
import { Organization } from "src/services/models/organization";
import { CapabilityAdminComponent } from "../capability/capability-admin.component";

@Component({
    selector: "organization-properties",
    templateUrl: "./org-properties.component.html",
    styleUrls: ["../organization.component.scss"],
})
export class OrganizationProperties extends ObjectComponent<Organization> {
    @Input()
    isHcpStaff: boolean = false;

    @ViewChild(CapabilityAdminComponent)
    capabilityAdminComponent?: CapabilityAdminComponent;

    disablePublicIntake: UntypedFormControl = new UntypedFormControl();
    get publicIntakeDisabled(): boolean {
        return !!this.disablePublicIntake.value;
    }

    get intakeWeb(): string {
        const orgSlug = this.fullObject?.slug;
        return window.location.origin + "/intake/" + orgSlug;
    }
    constructor(protected service: OrganizationService) {
        super(service);
        this.dataFormService = inject(DataFormService);
    }
    exportConfig(event: MouseEvent): void {
        this.service.exportConfig(this.fullObject!);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.disablePublicIntake.valueChanges.subscribe((checked: boolean) => {
            const settingsGroupControl = this.formGroup.get(
                "settings",
            ) as UntypedFormGroup;
            const settingsControl = settingsGroupControl?.get("settings");
            if (settingsControl?.value?.intake?.disableHosted != checked) {
                let settings = settingsControl?.value;
                if (!settings) settings = { intake: {} };
                else if (!("intake" in settings)) settings.intake = {};
                settings.intake.disableHosted = checked;
                settingsControl?.setValue(settings);
                settingsControl?.markAsDirty();
            }
        });
    }
    dataForms?: DataForm[] = [];
    dataFormService: DataFormService;
    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            name: [null, Validators.required],
            slug: [null, Validators.required],
            settings: this.formBuilder.group({
                shipment_form: [null],
                adverse_event: [
                    null,
                    Validators.pattern(
                        "^(https?://)([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([/\\w \\.-]*)*/?$",
                    ),
                ],
                settings: [null],
            }),
        });
    }
    protected setObject(v?: Organization) {
        super.setObject(v);

        this.disablePublicIntake.setValue(
            !!this.fullObject?.setting("intake", "disableHosted"),
        );

        if (this.fullObject?.id) {
            this.dataFormService
                .list({
                    name: "shipment.default",
                    is_template: "true",
                    owned: `0,${this.fullObject?.id || "0"}`,
                })
                .subscribe((dfr) => {
                    this.dataForms = dfr as DataForm[];
                });
        } else {
            this.dataForms = [];
        }
    }

    protected precommitTransform(v: any): any {
        if (this.exists) {
            this.usePatch = true;
        }

        if (this.mode != ObjectViewMode.Create) {
            if (v.settings.contact_address == "") v.settings.contact_address = null;
            else if (!v.settings.contact_address) delete v.settings.contact_address;
            if (v.settings.contact_phone == "") v.settings.contact_phone = null;
            else if (!v.settings.contact_phone) delete v.settings.contact_phone;
            if (v.settings.contact_email == "") v.settings.contact_email = null;
            else if (!v.settings.contact_email) delete v.settings.contact_email;
        }
        if (!this.currentAccount?.isSystemAdministrator) {
            // only a system admin can update entitlements
            delete v.entitlements;
        }
        if (v?.settings) {
            v.settings.shipment_form =
                this?.dataForms?.find((df) => df?.id === v?.settings?.shipment_form)
                    ?.asReference ?? null;
            v.settings.organization = this.object?.asReference.serialize();
        }
        return v;
    }

    protected resetObject(v: Organization) {
        if (!v.settings) {
            const t: any = { ...v };
            delete t.settings;
            super.resetObject(t);
        } else super.resetObject(v);
        const settings: UntypedFormGroup = this.formGroup.get(
            "settings",
        ) as UntypedFormGroup;
        settings?.get("shipment_form")?.setValue(v?.settings?.shipment_form?.id);
    }

    updateObjectForm(): void {
        this.formGroup.removeControl("settings");
        this.formGroup.removeControl("slug");
    }
    protected onCommitSuccess(v: Organization): boolean {
        const ret = super.onCommitSuccess(v);
        this.snackbar.open("Saved changes Succesfully.", undefined, { duration: 2000 });
        return ret;
    }
    protected onCommitError(err: any): void {
        console.error(err);
        // possibly log this on Rollbar or any other logging service ---- TODO
        this.snackbar.open("Error saving changes.", undefined, { duration: 5000 });
    }
}
