import {
    APIListResult,
    APIObject,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
    PaginatedList,
} from "./models/api-object";
import { EventEmitter, Type, inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { HttpErrorResponse, HttpEvent, HttpHeaders } from "@angular/common/http";
import { SessionService } from "./session.service";
import { tabFrame } from "../common/components/tab-frame/tab-frame.component";
import {
    RequestFilter,
    RequestMethod,
    WebsocketMessage,
    WebsocketMessageType,
    WebsocketObjectAction,
    WebsocketObjectMessage,
    WebsocketRequest,
} from "src/common/utilities/request";

type ObjectSubscription = {
    index: number; // must be set so that we know which subscrioption to modify when updating
    object_type: string; // object type ex. iam.account
    filter?: RequestFilter; // filters to subscribe to
    watch?: ObjectReference; // id of object to watch  One of filter or watch must be set
};
export type RelatedObjectEvent<T extends APIObject, U extends APIObject> = {
    relatedObject: ObjectOrReference<T>;
    action: WebsocketObjectAction;
    relation?: U;
};

let _gSubscriptionIndex: number = 0;

// The base service comprehends a Websocket-based communication method
export abstract class BaseService<T extends APIObject> extends ObjectFactory<T> {
    readonly session: SessionService;
    protected _subscriptions: { [index: number]: ObjectSubscription } = {};

    objectEvent: EventEmitter<WebsocketObjectMessage> =
        new EventEmitter<WebsocketObjectMessage>(true);
    relatedObjectEvent: EventEmitter<RelatedObjectEvent<any, any>> = new EventEmitter<
        RelatedObjectEvent<any, any>
    >(true);

    constructor(readonly type: Type<T>) {
        super(type);
        this.session = inject(SessionService);

        // update cached objects and propagate when we receive notifications from the server that they've changed
        this.session.onWebsocketMessage
            .pipe(
                // we only care about object messages that match our object type
                filter(
                    (msg: WebsocketMessage) =>
                        msg.type == WebsocketMessageType.OBJECT &&
                        (msg as WebsocketObjectMessage).object?.type ==
                            this.object_type,
                ),
            )
            .subscribe((msg: WebsocketMessage) => this.handleObjectMessage(msg));
        this.session.onReconnect.subscribe(() => this.resubscribe());
    }

    resubscribe(): void {
        if (!this.object_type) return; // don't subscribe for non-object lists
        for (let index in this._subscriptions) {
            const subscription = this._subscriptions[index];
            this.subscribe(
                subscription.watch ?? subscription.filter,
                subscription.index,
            );
        }
    }
    // If index is present, we're updating an existing subscription, else create a new one
    subscribe(
        filters?: RequestFilter | ObjectReference,
        index?: number,
        object_type?: string,
    ): number | undefined {
        let watch = undefined;
        let filter = undefined;
        if (filters instanceof ObjectReference) {
            object_type = filters.type;
            watch = filters.serialize();
            filter = undefined;
        } else {
            object_type = object_type ?? this.object_type;
            watch = undefined;
            filter = filters;
        }
        if (!object_type) return undefined; // don't subscribe for non-object lists
        if (watch === undefined && filter === undefined) return undefined;
        const subscription: ObjectSubscription = {
            object_type: object_type,
            index: index ?? ++_gSubscriptionIndex,
            filter: filter,
            watch: watch,
        };
        const subscriptionIndex = subscription.index;
        this.session
            .websocketMessage({
                type: WebsocketMessageType.SUBSCRIBE,
                data: subscription,
            })
            .subscribe(() => {
                this._subscriptions[subscriptionIndex] = subscription;
            });
        return subscription.index;
    }
    unsubscribe(index: number | undefined): void {
        if (index == undefined) return;
        this.session
            .websocketMessage({
                type: WebsocketMessageType.UNSUBSCRIBE,
                data: {
                    object_type: this.object_type,
                    index: index,
                },
            })
            .subscribe(() => {
                delete this._subscriptions[index];
            });
    }

    protected handleObjectMessage(
        msg: WebsocketMessage,
        relation?: APIObject,
    ): RelatedObjectEvent<any, any> | undefined {
        const objectMessage = msg as WebsocketObjectMessage;
        if (objectMessage.action == WebsocketObjectAction.CREATE) {
            const cached = this.makeObject(
                objectMessage.data,
                true,
                objectMessage.matched_filters,
            );
            return {
                relatedObject: cached,
                action: objectMessage.action,
                relation: relation,
            };
        } else if (objectMessage.action == WebsocketObjectAction.UPDATE) {
            const cached = this.cachedObject(objectMessage.object?.id);
            if (cached) {
                // if we've never seen the object before, ignore the message
                this.updateObject(
                    cached,
                    objectMessage.data,
                    objectMessage.matched_filters,
                );
                return {
                    relatedObject: cached,
                    action: objectMessage.action,
                    relation: relation,
                };
            }
        } else if (objectMessage.action == WebsocketObjectAction.DELETE) {
            const cached = this.cachedObject(objectMessage.object?.id);
            if (cached?.id) {
                this.objectDeleted.emit({
                    object: cached,
                    matched_filters: objectMessage.matched_filters,
                });
                delete this._cache[cached.id];
            }
            return {
                relatedObject: cached,
                action: objectMessage.action,
                relation: relation,
            };
        } else if (objectMessage.action == WebsocketObjectAction.RELATED) {
            const cached = this.cachedObject(objectMessage.object?.id);
            const relatedObjectMessage: WebsocketObjectMessage = objectMessage.data; // the data in a related event is the object event for the related object
            const relatedFactory = ObjectFactory.getObjectFactory(
                relatedObjectMessage.object?.type,
            );
            if (!relatedFactory) {
                console.error(
                    "Unable to find factory for " + relatedObjectMessage.object?.type,
                );
            }

            if (relatedFactory instanceof BaseService) {
                const relatedObjectEvent = relatedFactory?.handleObjectMessage(
                    relatedObjectMessage,
                    relation ?? cached,
                ); // pass it off to the appropriate factory for processing, this will update the object properties
                if (relation) return relatedObjectEvent;
                if (relatedObjectEvent)
                    // if we're not at the root, pass the object back up the chain
                    this.relatedObjectEvent.emit(relatedObjectEvent); // if we're at the root, notify of the related event, this will allow any components to respond to the change beyond just object properties
            }
        } else {
            this.objectEvent.emit(objectMessage);
        }
        return undefined;
    }

    action<U>(
        action: string,
        filters?: RequestFilter | undefined,
        data?: any,
    ): Observable<U | undefined> {
        return this.request(action, filters, data).pipe(
            map((result?: any) => result as U),
        );
    }
    create(object: ObjectOrReference<T>): Observable<T | undefined> {
        const data = object.serialize();
        return this.request(WebsocketObjectAction.CREATE, undefined, data).pipe(
            map((result: any) => (result ? this.makeObject(result, true) : undefined)),
            mergeMap((o: OptionalObjectOrReference<T>) =>
                ObjectFactory.objectObservable(o),
            ),
        );
    }
    override retrieve(
        id: string,
        filters?: RequestFilter,
        force: boolean = false,
    ): Observable<T | undefined> {
        return of(this._cache[id]).pipe(
            switchMap((cached: OptionalObjectOrReference<T>) =>
                cached && !(cached instanceof ObjectReference) && !force ?
                    of(cached)
                :   this.request(WebsocketObjectAction.RETRIEVE, filters).pipe(
                        map((result: any) => this.makeObject(result) as T),
                    ),
            ),
        );
    }
    update(object: ObjectOrReference<T>): Observable<T | undefined> {
        const data = object.serialize();
        return this.request(WebsocketObjectAction.UPDATE, undefined, data).pipe(
            map((result: any) => this.updateObject(object, result)),
            mergeMap((o: ObjectOrReference<T>) => ObjectFactory.objectObservable(o)),
        );
    }
    patch(object: ObjectOrReference<T>, data: any): Observable<T | undefined> {
        const change_set = object.diff(data);
        if (!change_set && !(object instanceof ObjectReference)) return of(object);
        data["id"] = object.id;
        data["type"] = object.type;
        return this.request(WebsocketObjectAction.PATCH, undefined, data).pipe(
            map((result: any) => this.updateObject(object, result)),
            mergeMap((o: ObjectOrReference<T>) => ObjectFactory.objectObservable(o)),
        );
    }
    destroy(object: ObjectOrReference<T>): Observable<ObjectOrReference<T>> {
        const data = object.asReference.serialize();
        return this.request(WebsocketObjectAction.DELETE, undefined, data).pipe(
            map(() => object),
            tap((deleted: ObjectOrReference<T>) =>
                this.objectDeleted.emit({ object: deleted }),
            ),
            tap((o: ObjectOrReference<T>) => tabFrame?.popObjectTabs(o)),
        );
    }
    list(
        filters?: RequestFilter | undefined,
        page?: number,
        pageSize: number = 10,
    ): Observable<APIListResult<T>> {
        filters = filters ?? {};
        if (page) {
            filters["page"] = page.toString();
            if (!filters["page_size"]) {
                if (pageSize) filters["page_size"] = pageSize.toString();
                else delete filters["page_size"];
            }
        }
        return this.request(WebsocketObjectAction.LIST, filters).pipe(
            map((result: any) => {
                if (Array.isArray(result))
                    return result.map((item: any) => this.makeObject(item));
                else {
                    const list = new PaginatedList<T>();
                    list.items = result.items.map((item: any) => this.makeObject(item));
                    list.pageSize = result.page_size;
                    list.count = result.count;
                    list.page = result.page;
                    return list;
                }
            }),
        );
    }

    protected request(
        action: string,
        filters?: RequestFilter,
        data?: any,
    ): Observable<any> {
        const message: WebsocketRequest = {
            type: WebsocketMessageType.REQUEST,
            action: this.object_type + ":" + action,
            filters: filters,
            data: data,
        };
        return this.session.websocketMessage(message, true).pipe(
            map((result: WebsocketMessage | undefined) => result?.data),
            catchError((err: WebsocketMessage) => this.handleError(err)),
        );
    }

    protected handleError(err: any): Observable<any> {
        throw err;
    }
}

// This leaf version of a service works both with Websocket and REST services
export abstract class APIService<T extends APIObject> extends BaseService<T> {
    protected _endpoint: string;

    constructor(
        readonly type: Type<T>,
        service: string | string[],
        protected authenticated: boolean = true,
    ) {
        super(type);
        const serviceArray = Array.isArray(service) ? [...service] : [service];
        this._endpoint = serviceArray.join("/");
    }

    get endpoint(): string {
        return this.getEndpoint();
    }

    override create(
        object: ObjectOrReference<T>,
        withFormData: boolean = false,
    ): Observable<T | undefined> {
        const endpoint = this.endpoint;
        const data = withFormData ? object.formData() : object.serialize(this.nested);
        const options = withFormData ? this.formDataOptions : undefined;
        return this.mutateObject<T>(endpoint, undefined, "post", data, options).pipe(
            map((o: any) => this.makeObject(o, true)),
            mergeMap((o: ObjectOrReference<T>) => ObjectFactory.objectObservable(o)),
            catchError((err: HttpErrorResponse) => this.handleError(err)),
        );
    }
    override retrieve(
        id: string,
        filter?: RequestFilter,
        force: boolean = false,
    ): Observable<T | undefined> {
        const endpoint = [this.endpoint, id].join("/");
        return of(this._cache[id]).pipe(
            switchMap((cached: OptionalObjectOrReference<T>) =>
                cached && !(cached instanceof ObjectReference) && !force ?
                    of(cached)
                :   this.getObject(endpoint, filter).pipe(
                        map((o: any) => this.makeObject(o) as T),
                        catchError((err: HttpErrorResponse) => this.handleError(err)),
                    ),
            ),
        );
    }
    override update(
        object: ObjectOrReference<T>,
        withFormData: boolean = false,
    ): Observable<T | undefined> {
        const endpoint = [this.endpoint, object.id].join("/");
        const data = withFormData ? object.formData() : object.serialize(this.nested);
        const options = withFormData ? this.formDataOptions : undefined;
        return this.mutateObject<T>(endpoint, undefined, "put", data, options).pipe(
            map((o: any) => this.updateObject(object, o)),
            mergeMap((o: ObjectOrReference<T>) => ObjectFactory.objectObservable(o)),
            catchError((err: HttpErrorResponse) => this.handleError(err)),
        );
    }
    override patch(
        object: ObjectOrReference<T>,
        data: any,
        withFormData: boolean = false,
    ): Observable<T | undefined> {
        const diff = object.diff(data);
        if (!diff && !(object instanceof ObjectReference)) return of(object);
        if (withFormData) {
            data = new FormData();
            for (const key in diff) {
                if (diff.hasOwnProperty(key)) {
                    let value = diff[key];
                    if (value) {
                        if (value instanceof APIObject) {
                            if (value.id) data.append(key, value.id);
                            else data.append(key, value.json());
                        } else data.append(key, value);
                    }
                }
            }
        } else data = diff;
        const endpoint = [this.endpoint, object.id].join("/");
        const options = withFormData ? this.formDataOptions : undefined;
        return this.mutateObject<T>(endpoint, undefined, "patch", data, options).pipe(
            map((o: any) => this.updateObject(object, o)),
            mergeMap((o: ObjectOrReference<T>) => ObjectFactory.objectObservable(o)),
            catchError((err: HttpErrorResponse) => this.handleError(err)),
        );
    }
    override destroy(object: ObjectOrReference<T>): Observable<ObjectOrReference<T>> {
        const endpoint = [this.endpoint, object.id].join("/");
        const options = { observe: "response" };
        return this.mutateObject<T>(endpoint, undefined, "delete", null, options).pipe(
            map(() => object),
            tap((o: ObjectOrReference<T>) => this.objectDeleted.emit({ object: o })),
            tap((o: ObjectOrReference<T>) => tabFrame?.popObjectTabs(o)),
            catchError((err: HttpErrorResponse) => this.handleError(err)),
        );
    }
    override list(
        filter?: RequestFilter,
        page?: number,
        pageSize: number = 10,
        resetActivity = true,
    ): Observable<APIListResult<T>> {
        filter = filter ?? {};
        if (page) {
            filter.page = page.toString();
            if (!filter.page_size) {
                if (pageSize) filter.page_size = pageSize.toString();
                else delete filter.page_size;
            }
        }
        return this.request<APIListResult<T>>(
            this.endpoint,
            filter,
            undefined,
            "get",
            undefined,
            true,
            resetActivity,
        ).pipe(
            map((result: any) => {
                let ret: APIListResult<T>;
                if (Array.isArray(result))
                    ret = result.map((o: any) => this.makeObject(o));
                else {
                    ret = new PaginatedList<T>();
                    ret.items = result.results.map((o: any) => this.makeObject(o));
                    ret.pageSize = result?.page_size ?? result.results.length;
                    ret.count = result?.count ?? result.results.length;
                    ret.page = result?.page ?? 1;
                }
                return ret;
            }),
            catchError((err: HttpErrorResponse) => this.handleError(err)),
        );
    }

    protected mutateObject<U>(
        endpoint: string,
        filter?: RequestFilter,
        method: RequestMethod = "get",
        data?: any,
        options?: any,
        authenticated: boolean = true,
    ): Observable<HttpEvent<U> | U> {
        return this.request<U>(
            endpoint,
            filter,
            data,
            method,
            options,
            undefined,
            undefined,
            authenticated,
        ).pipe(
            map((result: HttpEvent<U> | U | undefined /* NOSONAR */) => {
                if (!result && method != "delete") {
                    // It should not be possible to get here since we can't mutate an object that doesn't exist
                    console.log("Error mutating object");
                    throw new Error("Error mutating object");
                }
                return result as HttpEvent<U> | U;
            }),
        );
    }

    protected getObject<U>(
        endpoint: string,
        filter?: RequestFilter,
        method: RequestMethod = "get",
    ): Observable<HttpEvent<U> | U | undefined> {
        return this.request<U>(endpoint, filter, undefined, method);
    }

    override action<U>(
        detail: string,
        filters?: RequestFilter,
        data?: any,
        method: RequestMethod = "get",
        options?: any,
        progress: boolean = true,
        resetActivity: boolean = true,
    ): Observable<U | undefined> {
        const endpoint = this.endpoint + (detail ? "/" + detail : "");
        return this.request<U>(
            endpoint,
            filters,
            data,
            method,
            options,
            progress,
            resetActivity,
        ).pipe(
            map((response: HttpEvent<U> | U | undefined) => response as U | undefined),
            catchError((err: HttpErrorResponse) => this.handleError(err)),
        );
    }

    protected override request<U> /* NOSONAR */(
        endpoint: string,
        filter?: RequestFilter,
        data?: any,
        method: RequestMethod = "get",
        options?: any,
        progress: boolean = true,
        resetActivity: boolean = true,
        authenticated: boolean = true,
    ): Observable<HttpEvent<U> | U | undefined> {
        /* Only send an authenticated request when both ther service is authenticated (default) and the request is (default) */
        if (authenticated && this.authenticated)
            return this.session.restRequest<U>(
                endpoint,
                filter,
                method,
                data,
                options,
                progress,
                resetActivity,
            );
        else
            return this.session.unauthenticatedRequest<U>(
                endpoint,
                filter,
                method,
                data,
                options,
                progress,
            );
    }

    protected getEndpoint(): string {
        return this._endpoint;
    }
    protected get formDataOptions(): any {
        return {
            headers: new HttpHeaders().set("content-type", "multipart/form-data"),
        };
    }

    protected handleError(err: HttpErrorResponse): Observable<any> {
        throw err;
    }
}

export abstract class WebsocketService<T extends APIObject> extends BaseService<T> {}
