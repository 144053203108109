<h3 mat-dialog-title>{{ title }}</h3>
<object-form [controller]="controller" [formGroup]="formGroup" [hideControls]="true">
    <div class="wrapper flex stack full">
        <mat-dialog-content>
            <div
                *ngIf="errorMessage"
                class="error bold"
                [innerHTML]="errorMessage"
            ></div>
            <div class="flex stack even-spacing">
                <div>
                    <mat-form-field class="full">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status">
                            <mat-option *ngFor="let o of statusses" [value]="o.id">
                                {{ o.display_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="full">
                        <mat-label>{{ data?.dropdownLabel }}</mat-label>
                        <mat-select formControlName="rejection_status">
                            <mat-option
                                *ngFor="let o of rejectionOptions"
                                [value]="o.value"
                            >
                                {{ o.displayName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="flex stack">
                        <mat-label>
                            Additional rationale
                        </mat-label>
                        <mat-form-field appearance="outline" class="full input">
                            <textarea
                                matInput
                                #input
                                placeholder="Provide additional context or rationale for closure…"
                                formControlName="rejection_reason"
                                [maxLength]="500"
                            ></textarea>
                            <mat-hint align="end">
                                {{ input.value.length }} / 500
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <mat-dialog-actions form-actions>
        <div class="spacer"></div>
        <button mat-flat-button type="button" class="fixed" mat-dialog-close>
            Cancel
        </button>
        <button
            [disabled]="!statusValid"
            mat-flat-button
            type="button"
            class="fixed"
            color="primary"
            (click)="closeWithoutReason()"
        >
            Save without Message
        </button>
        <button
            mat-flat-button
            type="button"
            class="fixed"
            color="accent"
            [disabled]="!isValid"
            (click)="isValid && onClose()"
        >
            Continue
        </button>
    </mat-dialog-actions>
</object-form>
