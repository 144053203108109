import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, inject } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { ObjectComponent } from "src/common/components/object.component";
import { AccountService } from "src/services/iam.services";
import { Account } from "src/services/models/account";
import { EmailValidator } from "../../../common/utilities/validators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface UpdateEmailRequestData {
    id: string;
    email: string;
    password: string;
}
@Component({
    selector: "update-email-dialog",
    templateUrl: "./update-email.component.html",
    styleUrls: ["./account.component.scss"],
})
export class UpdateEmailDialog extends ObjectComponent<Account> {
    accountService: AccountService;
    loading = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<UpdateEmailDialog>,
        protected service: AccountService,
    ) {
        super(service);
        this.session.onLogout.subscribe(() => this.dialogRef.close(false));
        this.accountService = inject(AccountService);
    }
    get formValid() {
        return this.formGroup.valid;
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            email: [null, [Validators.required, EmailValidator]],
            password: [null, [Validators.required]],
        });
    }

    updateEmail() {
        this.loading = true;
        const data: UpdateEmailRequestData = this.formGroup.value;
        const id = this.currentAccount!.id;
        data["id"] = id!;

        this.accountService.updateEmail(data).subscribe({
            next: () => {
                this.loading = false;
                this.dialogRef.close();
                this.session.logout();
            },
            error: (err: HttpErrorResponse) => {
                this.loading = false;
                this.session.message =
                    "Invalid Request, please check all fields and try again.";
            },
        });
    }
}
