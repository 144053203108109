import { Component, Input } from "@angular/core";
import { ObjectComponent, ObjectViewMode } from "./object.component";

@Component({
    selector: "object-form",
    template: `
        <form
            class="wrapper flex stack flexible full"
            [formGroup]="controller.formGroup"
            (keydown)="handleKeyDown($event)"
            novalidate
            *ngIf="controller?.formGroup"
        >
            <div
                class="flex flexible"
                mat-dialog-title
                *ngIf="
                    controller?.objectName && controller.dialogReference && !hideHeader
                "
            >
                {{ controller.objectName }}
                <div class="spacer"></div>
                <ng-content select="[form-title]"></ng-content>
            </div>
            <div
                *ngIf="errorMessage && showErrors"
                class="error bold"
                style="margin-left: 20px;"
                [innerHTML]="errorMessage"
            ></div>

            <!-- Unified Content Projection Area.
            Either the Dialog or Non Dialog scenario gets rendered here depending on the condition
             -->
            <ng-template #contentProjection>
                <ng-content></ng-content>
            </ng-template>

            <!-- Dialog Scenario -->
            <ng-container *ngIf="controller.dialogReference; else nonDialogTemplate">
                <mat-dialog-content>
                    <ng-container *ngTemplateOutlet="contentProjection"></ng-container>
                </mat-dialog-content>
                <mat-dialog-actions
                    class="row flex"
                    *ngIf="!hideControls && !isViewOnly"
                >
                    <ng-container [ngTemplateOutlet]="actions"></ng-container>
                </mat-dialog-actions>
            </ng-container>

            <!-- Non-Dialog Scenario -->
            <ng-template #nonDialogTemplate>
                <ng-container *ngTemplateOutlet="contentProjection"></ng-container>
                <div
                    class="row flex object-actions"
                    *ngIf="!hideControls && !isViewOnly"
                >
                    <ng-container [ngTemplateOutlet]="actions"></ng-container>
                </div>
            </ng-template>

            <ng-template #actions>
                <div class="spacer"></div>
                <button
                    type="button"
                    *ngIf="!autosave"
                    class="fixed"
                    mat-flat-button
                    (click)="controller!.onCancel()"
                    [bdcWalkTriggerFor]="doctorTask7"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    *ngIf="!!autosave && !!controller.dialogReference"
                    class="fixed"
                    color="primary"
                    mat-flat-button
                    mat-dialog-close
                    [bdcWalkTriggerFor]="doctorTask7"
                >
                    Close
                </button>
                <button
                    type="button"
                    *ngIf="!autosave && isDialog && hasChanged"
                    mat-flat-button
                    color="primary"
                    (click)="controller!.onRevert()"
                >
                    Undo
                </button>

                <mat-spinner
                    *ngIf="controller.submitProcessing"
                    mode="indeterminate"
                    [diameter]="50"
                ></mat-spinner>
                <button
                    type="submit"
                    *ngIf="!controller.submitProcessing && !autosave"
                    mat-flat-button
                    color="accent"
                    [disabled]="!hasChanged || !isValid"
                    (click)="hasChanged && isValid && handleSubmit($event)"
                >
                    {{ controller.formButtonText }}
                </button>
            </ng-template>

            <mat-dialog-actions
                *ngIf="controller.dialogReference && !hideControls && isViewOnly"
            >
                <div class="spacer"></div>
                <button
                    type="button"
                    class="fixed"
                    color="primary"
                    mat-flat-button
                    mat-dialog-close
                    tabindex="0"
                    [bdcWalkTriggerFor]="doctorTask7"
                >
                    Close
                </button>
            </mat-dialog-actions>

            <ng-content select="[form-actions]"></ng-content>
            <ng-content select="[form-extra]"></ng-content>
            <bdc-walk-popup
                #doctorTask7
                name="doctorTask7"
                yPosition="above"
                [mustCompleted]="{ doctorOnboardingWelcome: true, doctorTask2: true }"
                [closeOnClick]="true"
                [showCloseButton]="false"
                class="doctorTask7Popup"
            >
                For now, just close this popup.
            </bdc-walk-popup>
        </form>
    `, // if you provide a form-actions template for the object-form, make sure you also set hideControls, otherwise you'll get both
})
export class ObjectFormComponent {
    @Input() controller!: ObjectComponent<any>;
    @Input() hideControls: boolean = false;
    @Input() hideHeader: boolean = false;
    @Input() showErrors: boolean = true;
    @Input() multiStep?: boolean = false;

    ObjectViewMode = ObjectViewMode;

    get errorMessage(): string | undefined {
        return this.controller?.errorMessage;
    }
    get autosave(): boolean {
        return !!this.controller?.autosave;
    }
    get hasChanged(): boolean {
        return !!this.controller?.hasChanged;
    }
    get isValid(): boolean {
        return !!this.controller?.isValid;
    }
    get isDialog(): boolean {
        return !!this.controller?.dialogReference;
    }
    get isViewOnly(): boolean {
        return this.controller?.mode == ObjectViewMode.View;
    }
    public errorString: string = "";
    get hasErrors(): boolean {
        const keys = Object.keys(this.controller?.formGroup.controls).filter(
            (key) => this.controller?.formGroup.controls[key].invalid,
        );
        return keys.length > 0;
    }

    handleKeyDown(event: KeyboardEvent): void {
        this.controller?.handleKeyDown(event);
    }

    handleSubmit(e: Event): void {
        e?.preventDefault();
        e?.stopPropagation();
        if (this.multiStep) {
            this.controller.goToNextFormStep();
        } else if (this.isValid) {
            this.controller.onSave();
        }
    }
}
