import {
    Directive,
    OnDestroy,
    OnInit,
    EventEmitter,
    ElementRef,
    Output,
} from "@angular/core";
import { ResizeSensor } from "css-element-queries";

export class ResizedEvent {
    constructor(
        readonly element: ElementRef,
        readonly newWidth: number,
        readonly newHeight: number,
        readonly oldWidth: number,
        readonly oldHeight: number,
    ) {}
}

@Directive({
    selector: "[resized]",
})
export class ResizedDirective implements OnInit, OnDestroy {
    @Output() readonly resized = new EventEmitter<ResizedEvent>();
    private oldWidth: number = 0;
    private oldHeight: number = 0;
    private resizeSensor: ResizeSensor | undefined;

    constructor(private readonly element: ElementRef) {}

    ngOnInit() {
        this.resizeSensor = new ResizeSensor(this.element.nativeElement, () =>
            this.onResized(),
        );
    }
    ngOnDestroy() {
        if (this.resizeSensor) this.resizeSensor.detach();
    }

    private onResized(): void {
        const newWidth = this.element.nativeElement.clientWidth;
        const newHeight = this.element.nativeElement.clientHeight;

        if (newWidth != this.oldWidth || newHeight != this.oldHeight) {
            const event = new ResizedEvent(
                this.element,
                newWidth,
                newHeight,
                this.oldWidth,
                this.oldHeight,
            );
            this.oldWidth = newWidth;
            this.oldHeight = newHeight;
            this.resized.emit(event);
        }
    }
}
