import {
    Component,
    Input,
    QueryList,
    ViewChild,
    ViewChildren,
    inject,
} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { MatTab, MatTabChangeEvent } from "@angular/material/tabs";
import { faCapsules } from "@fortawesome/free-solid-svg-icons";
import { Observable, of, Subscription } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";

import { Workflow, WorkflowReference } from "src/services/models/workflow";
import {
    AccountService,
    OrganizationService,
    CapabilityService,
    OrganizationSettingsFactory,
    RoleService,
} from "src/services/iam.services";
import { AccountEmail, Message } from "src/services/models/message";
import { AssignmentGroupComponent } from "src/program/components/assignment/assignment-group.component";
import {
    AssignmentService,
    CaseService,
    CaseTeamService,
    DiscussionService,
    InquiryService,
    ProgramService,
    StatusService,
    TeamMemberService,
    TeamService,
    WorkflowService,
} from "src/services/program.services";

import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Case, Status } from "src/services/models/case";
import {
    APIListResult,
    APIObject,
    ObjectOrReference,
    ObjectReference,
    ProgramReference,
} from "src/services/models/api-object";
import { RelatedObjectEvent } from "src/services/api.service";
import { DocumentRepository, Document } from "src/services/models/document";
import {
    AppNotificationService,
    MessageService,
    TemplateService,
} from "src/services/notification.services";
import { Assignment, AssignmentReference } from "src/services/models/assignment";
import { AssignmentComponent } from "src/program/components/assignment/assignment.component";
import { DataFieldDefinition, Organization } from "src/services/models/organization";
import { Inquiry } from "src/services/models/inquiry";
import {
    CASE_TAB_NAMES,
    TabChangeEvent,
    TabChangeService,
} from "src/services/component.services";
import { Program } from "src/services/models/program";
import {
    isDialogOpen,
    isElementInViewport,
    waitUntilElementLoaded,
} from "src/common/utilities/utilities";
import { SendTemplateDialog } from "src/common/components/template/send-template.dialog";
import { CaseTeam, Team, TeamMember } from "src/services/models/team";
import { DataFieldValue, DataForm } from "src/services/models/data";
import { CaseSummary } from "./case-summary.component";
import {
    CompoundDataTypeFactory,
    DataFieldService,
    DataFieldValueService,
    DataFormFieldConditionService,
    DataFormFieldFactory,
    DataFormService,
    DataTypeService,
} from "src/services/data.services";
import { ShipmentListComponent } from "src/program/components/shipment/shipment-list.component";
import { ShipmentService } from "src/services/shipping.services";
import { DetailTab } from "src/common/components/tab-detail/tab-detail.component";
import { DiscussionEntry } from "src/services/models/discussion";
import { WebsocketObjectAction } from "src/common/utilities/request";
import { AppNotification } from "src/services/models/appNotification";
import { FrequencyAssignmentDialog } from "../assignment/frequency-assignment.dialog";

export type onboardingTask = {
    name: string;
    done: boolean;
    initCb: Function; // function to be ran when a step is currently being shown. At a Minimum needs to set the header of the popup
    destroyCb?: Function; // ran when step is completed
};

type CaseSubscriptions = {
    inquiry?: number;
    case?: number;
    owner?: number;
};

@Component({
    selector: "case",
    templateUrl: "./case.component.html",
    styleUrls: ["./case.component.scss"],
})
export class CaseComponent extends ObjectComponent<Case> {
    @ViewChildren(MatTab) tabs?: QueryList<MatTab>;

    drugNameIcon = faCapsules;
    organizationService: OrganizationService;
    inquiryService: InquiryService;
    programService: ProgramService;
    accountService: AccountService;
    templateService: TemplateService;
    teamService: TeamService;
    dataFormService: DataFormService;
    shipmentService: ShipmentService;
    caseStatusService: StatusService;
    caseTeamService: CaseTeamService;
    assignmentService: AssignmentService;
    appNotificationService: AppNotificationService;
    roleService: RoleService;
    objectName = "Case";
    editingData?: string;
    workflowService: WorkflowService;
    workflows!: Workflow[];
    workflowTemplate?: Workflow;
    staffTeam?: Team;
    programOrganization?: Organization;

    productOptions: ObjectOrReference<Program>[] = [];

    caseSubscriptions: CaseSubscriptions = {};

    @ViewChild(AssignmentGroupComponent) assignmentGroup?: AssignmentGroupComponent;
    @ViewChild(CaseSummary) caseSummary?: CaseSummary;
    @ViewChild(ShipmentListComponent) shipmentList?: ShipmentListComponent;

    get caseObject() {
        return this?.object as Case;
    }
    set caseObject(c: Case) {
        this.object = c;
    }

    toDiscussion: boolean = false;

    @Input()
    formToOpen?: TabChangeEvent;
    @Input()
    formNotificationToOpen?: ObjectOrReference<DataForm>;

    @Input()
    documentToOpen?: TabChangeEvent;
    @Input()
    documentNotificationToOpen?: ObjectOrReference<Document>;

    @Input()
    messageToOpen?: TabChangeEvent;
    @Input()
    messageNotificationToOpen?: ObjectOrReference<Message>;

    tabChangeSubscription?: Subscription;
    tabChangeService: TabChangeService;
    discussionService: DiscussionService;
    constructor(protected service: CaseService) {
        super(service);
        this.tabChangeService = inject(TabChangeService);
        this.tabChangeSubscription = this.tabChangeService.tabWillChange.subscribe(
            (event) => this.handleTabSelection(event),
        );
        this.templateService = inject(TemplateService);
        this.organizationService = inject(OrganizationService);
        this.inquiryService = inject(InquiryService);
        this.workflowService = inject(WorkflowService);
        this.programService = inject(ProgramService);
        this.accountService = inject(AccountService);
        this.teamService = inject(TeamService);
        this.dataFormService = inject(DataFormService);
        this.shipmentService = inject(ShipmentService);
        this.caseStatusService = inject(StatusService);
        //this.bdcWalkService = inject(BdcWalkService);
        this.caseTeamService = inject(CaseTeamService);
        this.assignmentService = inject(AssignmentService);
        this.appNotificationService = inject(AppNotificationService);
        this.roleService = inject(RoleService);
        this.discussionService = inject(DiscussionService);
        // ensure factories used by sub-components are loaded
        inject(MessageService);
        inject(DataTypeService);
        inject(DataFieldService);
        inject(DataFormFieldFactory);
        inject(DataFieldValueService);
        inject(CompoundDataTypeFactory);
        inject(OrganizationSettingsFactory);
        inject(TeamMemberService);
        inject(DataFormFieldConditionService);
        inject(CapabilityService);
    }
    get viewOnly() {
        return this.isViewer && !this.isEditor;
    }
    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            shared: [null],
            owner: [null],
            name: [
                null,
                Validators.required,
                async () => (this.isCaseNameValid ? null : { name: "true" }),
            ],
            workflow: [null, Validators.required],
            template: [null],
            status: [null],
            primary: [null, Validators.required],
            secondary: [[]],
        });
    }

    currentTask = 0;
    assignmentToOpen?: ObjectOrReference<Assignment>;

    get isPharmaStaff(): boolean {
        return !this.isPhysicianStaff;
    }
    get isPhysicianStaff(): boolean {
        return !!this?.fullObject?.is_physician;
    }
    get contacts(): TeamMember[] {
        return this.fullObject?.contacts ?? [];
    }
    get physician(): TeamMember | undefined {
        return this.fullObject?.physician;
    }
    get caseTeam(): CaseTeam | undefined {
        return this.fullObject?.caseTeam(this.currentAccount);
    }
    get isManager(): boolean {
        return (
            !!this.fullObject?.isAdmin(this.currentAccount) ||
            !!this.currentAccount?.hasRole("object.admin", this.fullObject?.owner) ||
            !!this.currentAccount?.hasRole(
                "organization.manager",
                this.fullObject?.owner,
            ) ||
            !!this.currentAccount?.hasRole(
                "organization.manager",
                this.fullObject?.shared?.organization,
            )
        );
    }
    get isEditor(): boolean {
        return !!this.fullObject?.isEditor(this.currentAccount);
    }
    get isViewer(): boolean {
        return (
            !!this.fullObject?.isViewer(this.currentAccount) ||
            !!this.currentAccount?.isViewOnlyOnOrg(this.fullObject?.owner)
        );
    }

    selectedIndex = 0;

    setTabByIndex(index: number) {
        this.selectedIndex = index;
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.loadWorkflows();

        if (this.toDiscussion && this?.tabs) {
            //for ticket MED-833, creating a link to the discussion when a user is mentioned
            const tabs = this.tabs.toArray();
            const discussionTab = tabs.find(
                (tab) => tab.textLabel.toLowerCase() === "discussion",
            );
            if (discussionTab) {
                this._handleDiscussionTabChange(
                    {
                        tabName: CASE_TAB_NAMES.DISCUSSION,
                        data: {},
                    },
                    tabs.indexOf(discussionTab),
                );
            }
        }
        this.api.relatedObjectEvent
            .pipe(
                filter(
                    (event: RelatedObjectEvent<any, Case>) =>
                        event.relation?.id == this.object?.id &&
                        event.relation?.type == this.object?.type,
                ),
            )
            .subscribe((event: RelatedObjectEvent<any, Case>) => {
                this.handleRelatedEvent(event.action, event.relatedObject);
            });
        this.inquiryService.relatedObjectEvent
            .pipe(
                filter(
                    (event: RelatedObjectEvent<any, Inquiry>) =>
                        event.relation?.id == this.fullObject?.shared?.id,
                ),
            )
            .subscribe((event: RelatedObjectEvent<any, Inquiry>) => {
                this.handleRelatedEvent(event.action, event.relatedObject);
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        //this.walkThroughSubscription?.unsubscribe();
        //this.walkThroughSubscription = undefined;
        this.tabChangeSubscription?.unsubscribe();
        this.tabChangeSubscription = undefined;
        // Unsubscribe from case subscriptions
        this.api.unsubscribe(this.caseSubscriptions.case);
        this.api.unsubscribe(this.caseSubscriptions.inquiry);
        this.api.unsubscribe(this.caseSubscriptions.owner);
    }

    showTask(task: Assignment): void {
        if (this.object instanceof Case)
            AssignmentComponent.showAssignment(
                task,
                this.object,
                this.assignmentService,
                this.currentAccount,
            );
    }

    protected precommitTransform(v: any): any {
        v.workflow = this.workflows.find((w) => w.id == v.workflow)?.asReference;
        v.primary = v.primary.asReference;
        v.secondary = v.secondary?.map((tm: TeamMember) => tm.asReference);
        return super.precommitTransform(v);
    }

    ownerOrg?: Organization;
    caseStatuses: Status[] = [];

    formNotifications: AppNotification[] = [];
    documentNotifications: AppNotification[] = [];
    messageNotifications: AppNotification[] = [];
    discussionNotifications: AppNotification[] = [];
    assignmentNotifications: AppNotification[] = [];

    handleCaseNotifications() {
        this.formNotifications = this.notifications.filter((notification) => {
            return (
                notification?.object?.type === "program.dataform" &&
                !notification?.is_read
            );
        });

        this.documentNotifications = this.notifications.filter((notification) => {
            return (
                notification?.object?.type === "program.document" &&
                !notification?.is_read
            );
        });

        this.messageNotifications = this.notifications.filter((notification) => {
            return (
                notification?.object?.type === "notifications.message" &&
                !notification.is_read
            );
        });

        this.discussionNotifications = this.notifications.filter((notification) => {
            return (
                notification?.object?.type === "program.discussionentry" &&
                !notification.is_read
            );
        });

        this.isUserMentioned();

        this.assignmentNotifications = this.notifications.filter((notification) => {
            return (
                notification?.object?.type === "program.assignment" &&
                !notification.isRead
            );
        });
    }

    _notifications: AppNotification[] = [];
    get notifications() {
        return this._notifications;
    }
    set notifications(v: AppNotification[]) {
        this._notifications = v;
        this.handleCaseNotifications();
    }
    getUnReadNotifications() {
        //get all of the current users un read notifications for the case
        //should only do this once, any new/updates should be handled via websockets
        this.appNotificationService
            .list({
                account: this?.currentAccount?.id || "0",
                repo: `${this?.fullObject?.shared.id},${this.fullObject!.id!}` || "0",
                is_read: "false",
            })
            .subscribe((v) => {
                this.notifications = v as AppNotification[];
            });
    }

    get canCreateForm(): boolean {
        return !!this.fullObject?.isPharmaStaff(this.currentAccount);
    }
    protected setObject(v?: Case) {
        super.setObject(v);
        if (this.canCreateForm) this.getTemplates();

        const owners = v?.owner ? [v.shared.program!.id, v.owner.id] : undefined;
        this.updateDataDefinitions();
        this.updateProductOptions();
        this.updateWorkflowTemplate();
        this.initTeams();

        this.getUnReadNotifications();
        this.isUserMentioned();
        if (this.fullObject?.shared?.organization?.id) {
            const orgIds = [
                this.fullObject.shared.organization.id,
                this.fullObject.owner.id!,
            ];

            this.caseStatusService
                .getAvailableInquiryStatus({
                    owner_id: `${this.fullObject.shared.organization.id},0`,
                })
                .subscribe((statuses) => {
                    const { availableStatuses, systemProvidedStatuses } = statuses;
                    if (!availableStatuses.length) {
                        //When no statuses exist, we should display system generated statuses
                        this.caseStatuses = systemProvidedStatuses;
                    } else {
                        this.caseStatuses = availableStatuses;
                    }
                });
            this.organizationService
                .retrieve(orgIds[0])
                .subscribe((programOrg: Organization | undefined) => {
                    this.programOrganization = programOrg;
                    this.updateShipmentForm();
                    if (orgIds[1] == orgIds[0]) {
                        this.ownerOrg = this.programOrganization;
                    } else {
                        this.organizationService
                            .retrieve(orgIds[1])
                            .subscribe((owner: Organization | undefined) => {
                                this.ownerOrg = owner;
                            });
                    }
                });
        }

        // setup subscriptions for objects we want to watch
        this.caseSubscriptions.case = this.api.subscribe(this.object?.asReference);
        this.caseSubscriptions.inquiry = this.api.subscribe(
            this.fullObject?.shared.asReference,
        );
        this.caseSubscriptions.owner = this.api.subscribe(
            this.fullObject?.owner.asReference,
        );
    }
    shipmentForm?: ObjectOrReference<DataForm>;

    protected updateShipmentForm() {
        const shipmentForms =
            this.workflowTemplate?.references.filter(
                (ref: WorkflowReference) => ref.item_type == "*ref:shipment.form",
            ) ?? [];
        const obs: Observable<DataForm | undefined> =
            shipmentForms.length > 0 && !!shipmentForms[0].reference_identifier ?
                this.dataFormService.retrieve(shipmentForms[0].reference_identifier)
            :   of(undefined);
        obs.pipe(
            catchError(() => of(undefined)),
            mergeMap((form: DataForm | undefined) => {
                if (!form && this.programOrganization?.settings?.shipment_form?.id)
                    return this.dataFormService.retrieve(
                        this.programOrganization.settings.shipment_form.id,
                    );
                return of(form);
            }),
            catchError(() => of(undefined)),
            mergeMap((form: DataForm | undefined) => {
                if (!form)
                    return this.dataFormService
                        .list({ name: "shipment.default", is_template: "true" })
                        .pipe(
                            map(
                                (forms: APIListResult<DataForm>) =>
                                    (forms as DataForm[])?.[0],
                            ),
                        );
                return of(form);
            }),
        ).subscribe((form?: DataForm) => {
            this.shipmentForm = form;
        });
    }
    openAssignment(assignment: Assignment) {
        const { tabName, ...metadata } = assignment.taskTypeToTabGroupRelation;
        const references = assignment?.references;
        if (assignment.task?.taskType?.startsWith("message.")) {
            const organization = assignment.task.owner;
            const owners =
                this?.fullObject?.isPhysicianStaff(this.currentAccount) ?
                    [this.fullObject?.owner.id]
                :   [this?.fullObject?.shared?.program?.id, this.fullObject?.owner.id];

            let sources: DocumentRepository[] = [];
            if (this.currentAccount)
                sources = [...sources, this.currentAccount.asReference];
            if (organization) sources = [...sources, organization];

            const subject = references?.find(
                (r: AssignmentReference) =>
                    r instanceof AssignmentReference && r.item_type === "message.title",
            )?.item_type;
            const template = references?.find(
                (r) => r?.item_type === "notifications.template",
            )?.reference;

            const allowInvite = assignment.task?.taskType.startsWith("message.invite");

            this.dialog
                .open(SendTemplateDialog, {
                    data: {
                        to: this.physician,
                        subject: subject,
                        owner: owners,
                        context: this.fullObject?.data,
                        reference: this.fullObject?.asReference,
                        allowInvite,
                        sources: sources,
                        repository: organization,
                        uploadOwner: organization,
                        contacts: this.contacts,
                        assignment,
                        template,
                    },
                    minWidth: 600,
                })
                .afterClosed()
                .subscribe((msg: Message) => {
                    if (allowInvite)
                        CaseComponent.HandleMessageInvitePermissions(
                            msg,
                            this.roleService,
                            this.fullObject,
                        );
                });
        } else {
            references?.forEach((ref: AssignmentReference) => {
                if (!metadata.references) metadata.references = {};
                metadata.references[ref.item_type] = {
                    item_data: ref.item_data,
                    reference: ref.reference,
                };
            });
            metadata.assignment = assignment;

            if (tabName) {
                this.tabChangeService.changeTab(tabName, metadata);
            }
        }
    }

    get redactionEnabled(): boolean {
        const entitled = !!this.ownerOrg?.isEntitlementEnabled("redaction");
        const disabled = !!this.ownerOrg?.settings?.settings?.redaction?.disabled;
        return entitled && !disabled;
    }

    static HandleMessageInvitePermissions(
        msg: Message,
        roleService: RoleService,
        case_or_inquiry?: Case | Inquiry,
    ): void {
        if (msg) {
            // MED-1382 if we're inviting the physician, we need to change perms for those invited
            msg.targets.forEach((target: AccountEmail) => {
                if (target.account) {
                    // only update for provider accounts
                    const case_team_member = case_or_inquiry?.isMemberOfTeam(
                        target.account,
                        "provider",
                    );
                    if (
                        case_team_member?.permission &&
                        case_team_member?.permission?.role == "object.none"
                    ) {
                        case_team_member.permission.role = "object.edit";
                        roleService.update(case_team_member.permission).subscribe();
                    }
                }
            });
        }
    }

    get primary(): TeamMember | undefined {
        return this.formGroup.controls.primary.value;
    }
    get owner(): TeamMember | undefined {
        return this.fullObject?.teamMember("pharma", "owner");
    }

    get documentSources(): DocumentRepository[] {
        let sources = this.currentAccount ? [this.currentAccount.asReference] : [];
        if (this.fullObject) sources = [...sources, this.fullObject.asReference];
        if (this.fullObject?.shared)
            sources = [...sources, this.fullObject?.shared.asReference];
        if (this.isPharmaStaff) {
            if (this.fullObject?.shared.organization)
                sources = [...sources, this.fullObject.shared.organization];
            if (this.fullObject?.shared.program)
                sources = [...sources, this.fullObject.shared.program];
        }
        if (
            this.fullObject?.owner &&
            !sources.find(
                (ref: ObjectReference) => ref.id === this.fullObject?.owner.id,
            )
        )
            sources = [...sources, this.fullObject.owner];
        return sources;
    }

    get pharmaTeamMembers(): TeamMember[] {
        return this.fullObject?.teamMembers("pharma") ?? [];
    }

    dataFieldDefinitions: DataFieldDefinition[] = [];

    protected updateDataDefinitions(): void {
        const inquiryOrg = this.fullObject?.shared.organization;
        if (inquiryOrg) {
            let caseOrg =
                this.fullObject?.owner.type == "iam.organization" ?
                    this.fullObject?.owner
                :   undefined;
            if (caseOrg?.id == inquiryOrg.id) caseOrg = undefined;
            const caseOrgObs =
                caseOrg?.id ?
                    this.organizationService
                        .retrieve(caseOrg.id)
                        .pipe(
                            map(
                                (org: Organization | undefined) =>
                                    org?.settings?.data_types ?? [],
                            ),
                        )
                :   of([]);
            caseOrgObs
                .pipe(
                    mergeMap((fields: DataFieldDefinition[]) => {
                        return this.organizationService.retrieve(inquiryOrg.id!).pipe(
                            map(
                                (org: Organization | undefined) =>
                                    org?.settings?.data_types ?? [],
                            ),
                            map((ifields: DataFieldDefinition[]) => [
                                ...fields,
                                ...ifields,
                            ]),
                        );
                    }),
                )
                .subscribe(
                    (fields: DataFieldDefinition[]) =>
                        (this.dataFieldDefinitions = fields),
                );
        }
    }
    protected updateProductOptions(): void {
        this.programService
            .list({
                organization: this.fullObject?.shared?.organization?.id ?? "0",
                use_reference: "True",
            })
            .subscribe((progs: APIListResult<ProgramReference>) => {
                this.productOptions = progs as ProgramReference[];
            });
    }

    initTeams() {
        this.fullObject?.initTeams(this.caseTeamService).subscribe(() => {
            // will not call until the teams have been set
            this.updateOrganizationStaff();
            const tabs = this?.tabs?.toArray();
            if (this.assignmentToOpen) {
                if (this.assignmentToOpen instanceof ObjectReference) {
                    this.assignmentService
                        .resolveReference(this.assignmentToOpen)
                        .subscribe((assignment: Assignment | undefined) => {
                            if (assignment) this.openAssignment(assignment);
                        });
                } else {
                    this.openAssignment(this.assignmentToOpen);
                }

                this.assignmentToOpen = undefined;
            } else if (this.formNotificationToOpen && tabs) {
                const data = {
                    references: { form: this.formNotificationToOpen },
                };

                const tab = tabs.find((t) => t.textLabel.toLowerCase() === "forms");
                if (tab) {
                    this._handleFormTabChange(
                        {
                            tabName: CASE_TAB_NAMES.DATA,
                            data,
                        },
                        tabs.indexOf(tab),
                    );
                }
            } else if (this.documentNotificationToOpen && tabs) {
                const tab = tabs.find((t) => t.textLabel.toLowerCase() === "documents");
                if (tab) {
                    this._handleDocumentTabChange(
                        {
                            tabName: CASE_TAB_NAMES.DOCUMENTS,
                            data: {
                                document: this.documentNotificationToOpen,
                            },
                        },
                        tabs.indexOf(tab),
                    );
                }
            } else if (this.messageToOpen && tabs) {
                const tab = tabs.find(
                    (tab) => tab.textLabel.toLowerCase() === "communications",
                );
                if (tab) {
                    this._handleCommunicationTabChange(
                        {
                            tabName: CASE_TAB_NAMES.COMMUNICATIONS,
                            data: { message: this.messageToOpen },
                        },
                        tabs.indexOf(tab),
                    );
                }
            }
        });
    }
    openMessage(message: Message) {
        const data = {
            message,
        };

        this.tabChangeService.changeTab(CASE_TAB_NAMES.COMMUNICATIONS, data as any);
    }
    openDocument(document: Document) {
        const data = {
            document,
            upload: false,
        };

        this.tabChangeService.changeTab(CASE_TAB_NAMES.DOCUMENTS, data as any);
    }
    openForm(form: ObjectOrReference<DataForm>) {
        const data = {
            references: {
                form,
            },
        };

        this.tabChangeService.changeTab(CASE_TAB_NAMES.DATA, data);
    }

    protected updateOrganizationStaff(): void {
        if (!this.fullObject?.shared.teams) {
            this.initTeams();
            return;
        }
        this.teamService
            .list({
                organization: this.caseTeam?.team.organization.id ?? "0",
                type: "staff",
            })
            .subscribe((teams_: APIListResult<Team>) => {
                const teams = teams_ as Team[];
                this.staffTeam = teams.length ? teams[0] : undefined;

                this.updatePrimary();
            });
    }
    protected updatePrimary(): void {
        const owner = this.owner;
        const primary = this.staffTeam?.members?.find(
            (tm: TeamMember) => tm.account.id == owner?.account?.id,
        );
        this.formGroup.controls.primary.setValue(primary);
    }

    private loadWorkflows() {
        if (this.mode === ObjectViewMode.Create) {
            // JT - MED-864 only show workflows that are visible to the org
            const filter = {
                owned: this.fullObject?.shared.organization?.id ?? "0",
            };
            this.workflowService.list(filter).subscribe((workflows) => {
                this.workflows = workflows as Workflow[];
                if (this.workflows.length === 1) {
                    this.formGroup.get("workflow")?.setValue(this.workflows[0].id);
                }
            });
        }
    }

    protected updateWorkflowTemplate(): void {
        if (this.fullObject?.workflow_template?.id) {
            this.workflowService
                .retrieve(this.fullObject.workflow_template.id)
                .subscribe((wf: Workflow | undefined) => {
                    this.workflowTemplate = wf;
                    this.updateShipmentForm();
                });
        } else this.workflowTemplate = undefined;
    }
    get workflowTemplateName(): string {
        return this.workflowTemplate?.display_name ?? "Unspecified Workflow";
    }

    get caseStatus() {
        return this.fullObject?.shared.case_status;
    }

    get isCaseActive() {
        return !this.fullObject?.shared.case_status.attributes?.closes_case;
    }

    get isCaseClosed() {
        return this.fullObject?.shared.case_status.attributes?.closes_case;
    }
    protected handleAppNotificationEvent(
        action: WebsocketObjectAction,
        notification: AppNotification,
    ) {
        if (notification.account.id !== this?.currentAccount?.id) {
            return;
        }

        let index = this.notifications.findIndex((obj) => obj.id === notification?.id);

        if (action === WebsocketObjectAction.CREATE && index === -1) {
            this.notifications.push(notification);
        } else if (action === WebsocketObjectAction.UPDATE && index !== -1) {
            this.notifications[index] = notification;
        }

        this.handleCaseNotifications();
    }

    protected handleRelatedEvent(
        action: WebsocketObjectAction,
        related: APIObject,
    ): void {
        switch (related?.type) {
            case DiscussionEntry.object_type:
                break;
            case Document.object_type:
                break;
            case DataForm.object_type:
                break;
            case DataFieldValue.object_type:
                break;
            case AppNotification.object_type:
                this.handleAppNotificationEvent(action, related as AppNotification);
                break;
            case Message.object_type:
                break;
            case Assignment.object_type:
                this.handleAssignmentRelatedEvent(action, related as Assignment);
                break;
            default:
                console.log("Unhandled related event: " + related?.type);
        }
    }
    handleAssignmentRelatedEvent(action: WebsocketObjectAction, related: Assignment) {
        if (this.assignmentGroup && action == WebsocketObjectAction.CREATE) {
            this.assignmentGroup.list.refresh();
        }
    }
    rejectCase(event: MouseEvent | undefined, status?: Status): void {
        this.terminateEvent(event);
        this.inquiryService.close(
            this.fullObject?.shared!,
            false,
            status,
            this.caseStatuses?.filter((s) => s.attributes?.closes_case),
        );
    }

    handleTabSelection(event: TabChangeEvent) {
        const { tabName } = event;
        const tabs = this?.tabs?.toArray();
        if (!tabs?.length) {
            console.error("No tabs found");
            return;
        }

        const matTab = tabs.find(
            (tab) => tab.textLabel.toLowerCase() === event.tabName.toLowerCase(),
        );

        if (!matTab) return;
        const tabIndex = tabs.indexOf(matTab);
        //this is to handle tabchange requests from child components
        switch (tabName) {
            case CASE_TAB_NAMES.CHECK_LIST:
                this._handleChecklistTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.DISCUSSION:
                this._handleDiscussionTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.COMMUNICATIONS:
                this._handleCommunicationTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.CASE_SUMMARY:
                this._handleCaseSummaryTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.DOCUMENTS:
                this._handleDocumentTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.DATA:
                this._handleFormTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.SHIPMENTS:
                this._handleShipmentTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.TEAM:
                this._handleTeamTabChange(event, tabIndex);
                break;
            case CASE_TAB_NAMES.AUDIT:
                this._handleAuditTabChange(event, tabIndex);
                break;
            default:
                console.error("Unknown tab requested: ", tabName);
                break;
        }
    }
    private _handleChecklistTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);
    }
    private _handleFormTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);

        //since tabs are lazy rendered, and wont render until after the tab change
        //their tab service also doesnt initialize until after the event is fired
        if (event?.data?.references?.form) {
            this.formToOpen = event;
        }
    }

    private _handleDocumentTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);

        this.documentToOpen = event;
    }

    private _handleCommunicationTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);
        this.messageToOpen = event;
    }
    private _handleCaseSummaryTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);
    }

    shipmentToOpen?: TabChangeEvent;
    private _handleShipmentTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);
        this.shipmentToOpen = event;
    }
    private _handleTeamTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);
    }
    private _handleAuditTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);
    }
    private _handleDiscussionTabChange(event: TabChangeEvent, index: number) {
        this.setTabByIndex(index);
    }
    get isAdmin() {
        return (
            this.currentAccount?.hasRole(
                "object.admin",
                this.fullObject?.shared?.organization,
            ) ??
            this.currentAccount?.hasRole("object.admin", this.fullObject?.owner) ??
            this?.currentAccount?.isSystemAdministrator
        );
    }

    templateForms: DataForm[] = [];
    getTemplates() {
        const ownerIds = [];
        if (this.fullObject?.owner.id) ownerIds.push(this.fullObject.owner.id);
        if (this.fullObject?.program?.id) ownerIds.push(this.fullObject.program.id);
        const isAdmin = this.isAdmin || this.isManager;

        DataFormService.getTemplates(
            this.dataFormService,
            ownerIds,
            isAdmin,
            this.fullObject?.workflow_template?.id,
        ).subscribe((forms) => {
            this.templateForms = forms;
        });
    }
    createTodo(event: MouseEvent): void {
        if (!isDialogOpen(this.dialog, AssignmentComponent)) {
            this.assignmentService
                .list({ workflow: this.fullObject!.id! })
                .pipe(map((list: APIListResult<Assignment>) => list as Assignment[]))
                .subscribe((assignments: Assignment[]) => {
                    const otherAssignments = assignments
                        .filter((a: Assignment) => a.parent && !a.completed)
                        .sort((a: Assignment, b: Assignment) => a.order - b.order);
                    const instance = this.assignmentGroup?.createObject(
                        event,
                    ) as AssignmentComponent;
                    instance.shipmentForm = this.shipmentForm;
                    instance.otherAssignments = otherAssignments;
                });
        }
    }

    tasks: onboardingTask[] = [
        {
            name: "doctorTask1",
            done: false,
            initCb: async () => {
                this.dim = true;
                // highlight workflow component
                const el = document.getElementsByClassName("detail-tab-body")[0]; // targeting the workflow detail-tab
                if (!isElementInViewport(el)) el.scrollIntoView();
                el.classList.add("highlight");

                //initCbs runs slightly before the popup is inserted in the DOM
                const header = await waitUntilElementLoaded(
                    ".doctorTask1Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
        },
        {
            name: "doctorTask2",
            done: false,
            initCb: async () => {
                this.dim = true;
                const el = document.getElementsByClassName("detail-tab-body")[0]; // targeting the workflow detail-tab
                if (!isElementInViewport(el)) el.scrollIntoView();

                if (!el.classList.contains("highlight")) {
                    el.classList.add("highlight");
                }

                const header = await waitUntilElementLoaded(
                    ".doctorTask2Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
            destroyCb: () => (this.dim = false),
        },
        {
            name: "doctorTask3",
            done: false,
            initCb: async () => {
                this.dim = true;
                const el = document.getElementsByClassName("detail-tab-body")[0]; // targeting the workflow detail-tab
                if (!isElementInViewport(el)) el.scrollIntoView();

                if (!el.classList.contains("highlight")) {
                    el.classList.add("highlight");
                }

                const header = await waitUntilElementLoaded(
                    ".doctorTask3Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
        },
        {
            name: "doctorTask4",
            done: false,
            initCb: async () => {
                this.dim = true;
                const el = document.getElementsByClassName("detail-tab-body")[0]; // targeting the workflow detail-tab
                if (!isElementInViewport(el)) el.scrollIntoView();
                if (!el.classList.contains("highlight")) {
                    el.classList.add("highlight");
                }

                const header = await waitUntilElementLoaded(
                    ".doctorTask4Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
            destroyCb: () => {
                // done with workflow so remove highlight
                const el = document.getElementsByClassName("detail-tab-body")[0];
                el.classList.remove("highlight");
            },
        },
        {
            name: "doctorTask5",
            done: false,
            initCb: async () => {
                if (!this.dim) this.dim = true;
                // highlight tabs component
                const el = document.getElementById("doctorTask5");
                el?.scrollIntoView(); // will center around the tabs
                el?.classList.add("highlight");

                const header = await waitUntilElementLoaded(
                    ".doctorTask5Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
            destroyCb: () => {
                const el = document.getElementById("doctorTask5");
                el?.classList.remove("highlight");
            },
        },
        {
            name: "doctorTask6",
            done: false,
            initCb: async () => {
                if (!this.dim) this.dim = true;
                // highlight team tab
                const el = document.getElementById("doctorTask6");
                if (!isElementInViewport(el!)) el?.scrollIntoView();

                el?.classList.add("highlight");
                // mat-button styling prevents tab from rising above the dim, this class gets around it
                el?.classList.add("selected");

                const header = await waitUntilElementLoaded(
                    ".doctorTask6Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
            destroyCb: () => {
                const el = document.getElementById("doctorTask6");
                el?.classList.remove("highlight");
                el?.classList.remove("selected");

                if (this.dim) this.dim = false;
            },
        },
        {
            name: "doctorTask7",
            done: false,
            initCb: async () => {
                this.dim = true;
                const header = await waitUntilElementLoaded(
                    ".doctorTask7Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
        },
        {
            name: "doctorTask8",
            done: false,
            initCb: async () => {
                this.dim = true;
                const el = document.getElementById("doctorTask8");
                if (!isElementInViewport(el!)) el?.scrollIntoView();

                el?.classList.add("highlight");
                el?.classList.add("selected");

                const header = await waitUntilElementLoaded(
                    ".doctorTask8Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
            destroyCb: () => {
                const el = document.getElementById("doctorTask8");
                el?.classList.remove("highlight");
            },
        },
        {
            name: "doctorTask9",
            done: false,
            initCb: async () => {
                this.dim = true;
                //targeting case overview
                const el = document.getElementById("doctorTask9");
                if (!isElementInViewport(el!)) el?.scrollIntoView();

                el?.classList.add("highlight");

                const header = await waitUntilElementLoaded(
                    ".doctorTask9Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
        },
        {
            name: "doctorTask10",
            done: false,
            initCb: async () => {
                this.dim = true;
                const header = await waitUntilElementLoaded(
                    ".doctorTask10Popup  > div > div > div.title > div",
                );
                if (header) {
                    header.innerText = this.currentOnboardingHeader;
                }
            },
            destroyCb: () => {
                const el = document.getElementById("doctorTask9");
                el?.classList.remove("highlight");
            },
        },
    ];

    // way to programtically control if walkthrough renders
    walkThroughVisible = false;
    // as of 06/2022 the only account that has an onboarding is the doctor
    get showWalkthrough(): boolean {
        return !!(
            this.isPhysicianStaff &&
            this.walkthroughRendered &&
            !this.completedWalkthrough &&
            this.walkThroughVisible
        );
    }

    dim = false;
    get currentOnboardingHeader() {
        return `Step ${this.currentTask}/${this.tasks.length}`;
    }

    initializeWalkthrough() {
        return; // per MED-2833 turn off walkthrough for now
        // this.walkThroughVisible = true;
        // this.walkThroughSubscription = this.bdcWalkService.changes.subscribe(() => {
        //     this.startWalkthrough();

        //     const completed = this.tasks.filter((t) => t.done);
        //     if (completed.length == this.tasks.length) {
        //         this.currentTask = this.tasks.length;
        //         this.walkThroughVisible = false;
        //         this.accountService
        //             .walkthrough(this.currentAccount?.asReference!, true)
        //             .subscribe(() => {
        //                 //will ensure current account has completed_walkthrough: true and will re-render tasks so checkboxes are no longer disabled
        //                 this.currentAccount?.initialize({
        //                     completed_walkthrough: true,
        //                 });
        //                 this.assignmentGroup?.list.refresh();
        //                 this.walkThroughVisible = false;
        //             });
        //     } else this.currentTask = completed.length + 1;
        // });

        // // emits an observable any time a task is displayed or hidden
        // // ie completing a step will emit 2 events, 1 for the new task being displayed and 1 for the 1 just completed
        // this.bdcWalkService.changesDisplaying.subscribe((e: BdcDisplayEvent) => {
        //     const task = this.tasks.find((task) => task.name === e.id);
        //     if (e.visible && task?.initCb) {
        //         this.dim = false;
        //         task?.initCb();
        //     } else if (task?.destroyCb) {
        //         //if not visible, call destroy callback
        //         task.destroyCb();
        //     }
        // });
    }
    resetWalkthrough() {
        return; // per MED-2833 turn off walkthrough for now
        // this.bdcWalkService.reset();
        // this.tabGroupService.changeTab(CASE_TAB_NAMES.CHECK_LIST);
        // this.accountService
        //     .walkthrough(this.currentAccount?.asReference!, false)
        //     .subscribe(() => {
        //         //for some reason on first login, if the user replays immediately, this does not get updated on the front end and the user needs to refresh
        //         // if they interact with anything else, completed_walkthrough is updated
        //         if (this.currentAccount?.completed_walkthrough) {
        //             this.currentAccount?.initialize({ completed_walkthrough: false });
        //         }

        //         if (this?.assignmentGroup?.stepper) {
        //             //if user clicked on another step in the checklist, this will bring it back to first
        //             this.assignmentGroup.stepper.reset();
        //         }
        //         this.walkThroughVisible = true;
        //         this.dim = true;
        //         this.startWalkthrough();
        //     });
    }
    stopWalkthrough() {
        this.walkThroughVisible = false;
        this.dim = false;
        this.accountService
            .walkthrough(this.currentAccount?.asReference!, true)
            .subscribe(() => {
                //this.bdcWalkService.setTaskCompleted("doctorOnboardingWelcome", false); // all popups are dependent on this task being true
                this.tasks.forEach((task) =>
                    task.destroyCb ? task.destroyCb() : () => {},
                );
                this.dim = false;

                //will ensure current account has completed_walkthrough: true and will re-render tasks so checkboxes are no longer disabled
                this.currentAccount?.initialize({ completed_walkthrough: true });
                this.assignmentGroup?.list.refresh();
            });
    }

    startWalkthrough() {
        return; // per MED-2833 turn off walkthrough for now
        // if (!this.showWalkthrough) return;
        // this.dim = true;
        // this.tasks.forEach((task: onboardingTask, index: number) => {
        //     const done = this.bdcWalkService.getTaskCompleted(task.name);
        //     const displaying = this.bdcWalkService.getIsDisplaying(task.name);
        //     task.done = done;
        //     //MED-1097 - on first login the init cbs are not called for whatever step the user was on when they logged off
        //     if (!done && displaying) {
        //         task.initCb();
        //     }
        // });
    }

    protected getIsValid(): boolean {
        const res = super.getIsValid();
        return this.mode === ObjectViewMode.Create ? res && !this.hasTimeout : res;
    }

    hasTimeout = false;
    caseNameInput(e: Event) {
        if (!this.hasTimeout) {
            this.hasTimeout = true;
            this.isCaseNameValid = true;
            setTimeout(() => this.checkCaseName(), 3000);
        }
    }
    isCaseNameValid = true;

    private checkCaseName() {
        const value = this.formGroup.get("name")?.value;
        this.hasTimeout = false;
        if (!value) {
            this.isCaseNameValid = true;
            return;
        }
        this.service
            .check_case_name_validity({
                name: value,
                organization: this?.fullObject?.owner?.id,
            })
            .subscribe((o: any) => {
                const { count } = o;
                this.isCaseNameValid = !count;
                const control = this.formGroup.get("name");
                control?.updateValueAndValidity();
            });
    }

    isAuditSelected = false;
    onAuditSelected(previousTab: DetailTab): void {
        this.isAuditSelected = true;
    }
    onAuditDeselected(nextTab: DetailTab): void {
        this.isAuditSelected = false;
    }

    isDiscussionTabSelected = false;
    matBadge(notifiations: AppNotification[]) {
        if (!notifiations.length) return undefined;

        return notifiations.length > AppNotification.maxNotifications ?
                `${AppNotification.maxNotifications}+`
            :   `${notifiations.length}`;
    }
    notificationForObjectId(id: string, notifications: AppNotification[]) {
        return notifications.find((n) => n?.object?.id === id);
    }
    isUserMentioned() {
        if (!this.discussionNotifications.length) return;

        const ids = `${this.fullObject?.id},${this.fullObject?.shared?.id}`;
        this.discussionService
            .isUserMentioned(ids)
            .subscribe((mentioned) => (this._currentUserIsMentioned = mentioned));
    }

    _currentUserIsMentioned = false;
    get currentUserIsMentioned() {
        return this._currentUserIsMentioned && this.discussionNotifications.length;
    }
    get adverseEventUrl() {
        return this.programOrganization?.settings?.adverse_event;
    }

    handleUserTabChange(event: MatTabChangeEvent) {
        //to keep selectedIndex in sync with user controlled tab changes
        this.setTabByIndex(event.index);
    }
}
