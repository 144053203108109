import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, EMPTY, Observable, Subject, of } from "rxjs";
import { Account } from "src/services/models/account";
import { EnvironmentService } from "src/app/environment.service";
import { Router } from "@angular/router";
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHeaders,
} from "@angular/common/http";
import { Injectable, EventEmitter, inject } from "@angular/core";
import {
    mergeMap,
    catchError,
    map,
    tap,
    finalize,
    filter,
    first,
    delay,
} from "rxjs/operators";
import {
    RequestFilter,
    RequestMethod,
    WebsocketMessage,
    WebsocketMessageType,
    WebsocketRequest,
    WebsocketResponse,
    queryStringFromFilters,
} from "src/common/utilities/request";
import { AuthenticationService } from "./auth.service";
import { webSocket as createWebsocket, WebSocketSubject } from "rxjs/webSocket";
import { ObjectFactory, OptionalObjectOrReference } from "./models/api-object";
import { AccountSettingsFactory } from "./iam.services";

// JT - Moved authentication related structures and code to AuthenticationService services/auth.service.ts
// JT - Moved request types to common/utilities/request.ts

export type Message = { message: string; timer?: any };

@Injectable()
export class SessionService {
    readonly http: HttpClient;
    readonly router: Router;
    readonly environment: EnvironmentService;
    readonly cookies: CookieService;
    readonly auth: AuthenticationService;

    onMessage: EventEmitter<string> = new EventEmitter<string>(true);
    onLogout: EventEmitter<void> = new EventEmitter<void>(true);
    onDisconnect: EventEmitter<void> = new EventEmitter<void>(true); // emitted when the websocket disconnects
    onReconnect: EventEmitter<void> = new EventEmitter<void>(true); // emitted when the websocket reconnects
    currentAccountChanged: BehaviorSubject<Account | undefined> = new BehaviorSubject<
        Account | undefined
    >(undefined);
    initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    protected sequence: number = 0;
    protected websocket?: WebSocketSubject<WebsocketMessage>;
    onWebsocketMessage: Subject<WebsocketMessage> = new Subject<WebsocketMessage>();

    private progressCounter: number = 0;
    private ignoreActivityTimer: boolean = false;
    private activityTimer: any;
    private messageList: Message[] = [];

    get isInitialized(): boolean {
        return this.initialized.getValue();
    }
    get progress(): boolean {
        return this.progressCounter > 0;
    }
    set progress(v: boolean) {
        setTimeout(() => (this.progressCounter += v ? 1 : -1));
    }
    set skipActivityTimer(v: boolean) {
        this.ignoreActivityTimer = v;
    }
    set message(msg: string | undefined) {
        this.setMessage(msg);
    }
    get messages(): Message[] {
        return this.messageList;
    }
    get authorization(): Observable<string | undefined> {
        return this.auth
            .token()
            .pipe(
                map((token: string | undefined) =>
                    token ? "Bearer " + token : undefined,
                ),
            );
    }
    get isAuthenticated(): boolean {
        return !!this.currentAccount;
    }
    get currentAccount(): Account | undefined {
        return this.currentAccountChanged.getValue();
    }
    set currentAccount(v: Account | undefined) {
        const current = this.currentAccount;
        const changed = !!v && !!current ? v.id != current.id : v != current;
        if (changed) this.currentAccountChanged.next(v);
    }
    get websocketOpen(): boolean {
        return !!this.websocket && !this.websocket.closed;
    }

    constructor() {
        this.http = inject(HttpClient);
        this.router = inject(Router);
        this.environment = inject(EnvironmentService);
        this.cookies = inject(CookieService);
        this.auth = inject(AuthenticationService);
        inject(AccountSettingsFactory);
        this.connectWebsocket(() => {
            this.initialize().subscribe((account?: Account) => {});
        });
    }

    initialize(): Observable<Account | undefined> {
        return this.restRequest<Account>(["iam", "session", "validate"]).pipe(
            map((response: any) =>
                response ? ObjectFactory.makeObject<Account>(response) : undefined,
            ),
            mergeMap((account: OptionalObjectOrReference<Account>) =>
                ObjectFactory.objectObservable(account),
            ),
            tap((account: Account | undefined) => (this.currentAccount = account)),
            finalize(() => this.initialized.next(true)),
            catchError((err: any) => {
                this.currentAccount = undefined;
                throw err;
            }),
        );
    }
    login(next?: string): void {
        if (!next) {
            next = this.router.routerState.snapshot.url;
        }
        this.auth.authorize(next);
    }
    logout(next?: string): void {
        next = next ?? "/login";
        this.restRequest<any>(
            ["iam", "session", "logout"],
            undefined,
            "post",
        ).subscribe(() => {
            if (this.activityTimer) {
                clearTimeout(this.activityTimer);
                this.activityTimer = undefined;
            }
            this.currentAccount = undefined;
            this.cookies.deleteAll("/");
            localStorage.removeItem("st");
            localStorage.removeItem("bdcWalkthrough"); //so onboarding state doesnt persist for other accounts
            this.auth.unauthorize(next);
        });
    }

    protected baseRequest<T>(
        endpoint: string,
        method: RequestMethod = "get",
        data?: any,
        options?: any,
        progress: boolean = true,
        resetActivity: boolean = true,
    ): Observable<HttpEvent<T> | undefined> {
        options = options ?? {};
        let obs: Observable<HttpEvent<T> | undefined> = of(undefined);
        switch (method) {
            case "get":
                obs = this.http.get<T>(endpoint, options);
                break;
            case "post":
                obs = this.http.post<T>(endpoint, data, options);
                break;
            case "put":
                obs = this.http.put<T>(endpoint, data, options);
                break;
            case "patch":
                obs = this.http.patch<T>(endpoint, data, options);
                break;
            case "delete":
                if (data) options.body = data;
                obs = this.http.delete<T>(endpoint, options);
                break;
        }
        if (resetActivity && !this.ignoreActivityTimer) this.resetActivityTimer();
        return progress ? this.observableWithProgress(obs) : obs;
    }
    protected _websocketRequest(
        message: WebsocketMessage,
        synchronous: boolean = false,
        progress: boolean = true,
        resetActivity: boolean = true,
    ): Observable<WebsocketMessage | undefined> {
        let obs;
        if (synchronous) {
            obs = this.onWebsocketMessage.pipe(
                tap({
                    subscribe: () => {
                        // JT - Dec 2023 - Exceptions encountered during serializing message cause an error object to be sent
                        // to the WebsocketSubject that it doesn't know how to handle and subsequently crashes the connection
                        // Shortcutting that with a try-catch here and offloading to handleError.
                        // Should revisit whether this is still necessary in subsequent RXJS updates
                        try {
                            this.websocket?.next(message); // send the message
                        } catch (e: any) {
                            this.handleError(e);
                        }
                    },
                }),
                filter((msg: WebsocketMessage) => msg.sequence == message.sequence), // we're only interested in our response
                first(), // and only 1 message
                map((response: WebsocketMessage) => {
                    if (response.type == WebsocketMessageType.ERROR) throw response; // if it's an error, throw it
                    return response;
                }),
            );
        } else {
            // we don't need to wait for a response
            // JT - Dec 2023 - See comment above re: serializing
            try {
                this.websocket?.next(message);
            } catch (e: any) {
                this.handleError(e);
            }
            obs = of(undefined);
        }
        if (resetActivity && !this.ignoreActivityTimer) this.resetActivityTimer();
        return progress ? this.observableWithProgress(obs) : obs;
    }
    websocketMessage(
        message: WebsocketMessage,
        synchronous: boolean = false,
    ): Observable<WebsocketMessage | undefined> {
        const sequence = ++this.sequence;
        if (!this.websocketOpen) {
            // this is a websocketOnly message, so if the socket isn't open, throw an error
            const response: WebsocketResponse = {
                type: WebsocketMessageType.ERROR,
                sequence: sequence,
                status: 502,
                errors: ["Websocket connection closed."],
            };
            return of(response).pipe(
                map((response: WebsocketResponse) => {
                    throw response;
                }),
            );
        }
        return this.auth.token().pipe(
            mergeMap((token: string | undefined) => {
                message.token = token;
                message.sequence = sequence;
                return this._websocketRequest(message, synchronous);
            }),
        );
    }

    restRequest<T>(
        detail: string | string[],
        filters?: RequestFilter,
        method: RequestMethod = "get",
        data?: any,
        options?: any,
        progress: boolean = true,
        resetActivity: boolean = true,
        forceHTTP: boolean = false, // bypass the websocket; great for multipart/form-data
    ): Observable<HttpEvent<T> | T | undefined> {
        const sequence = ++this.sequence;
        let endpoint = (Array.isArray(detail) ? detail : [detail]).join("/");
        return this.auth.token().pipe(
            mergeMap((token: string | undefined) => {
                if (!token)
                    return of(undefined); // All requests must be authenticated
                else if (this.websocketOpen && !forceHTTP) {
                    // if the websocket is open, we're going to wrap the REST request as a ws message to reduce overhead
                    const message: WebsocketRequest = {
                        type: WebsocketMessageType.REQUEST,
                        sequence: sequence,
                        token: token,
                        action:
                            "REST." +
                            method.toUpperCase() +
                            (endpoint.startsWith("/") ? ":" : ":/") +
                            endpoint,
                        filters: filters,
                        data: data,
                    };
                    return this._websocketRequest(
                        message,
                        true,
                        progress,
                        resetActivity,
                    ).pipe(
                        map((response: WebsocketMessage | undefined) => {
                            // convert websocket response into HttpEvent<T> | T | undefined
                            if (response?.type == WebsocketMessageType.ERROR)
                                throw response;
                            else if (response?.type == WebsocketMessageType.RESPONSE) {
                                const resp = response as WebsocketResponse;
                                const status = resp?.status ?? 0;
                                if (status >= 400) {
                                    const err = new HttpErrorResponse({
                                        status: status,
                                        error: resp?.data ?? [],
                                    });
                                    this.handleError(err);
                                    return undefined;
                                }
                                return resp.data as T;
                            }
                            return undefined;
                        }),
                    );
                } else {
                    options = options ?? {};
                    const headers = options.headers || new HttpHeaders();
                    options.headers = headers.set("Authorization", "Bearer " + token);
                    endpoint =
                        [this.environment.services, endpoint].join("/") +
                        queryStringFromFilters(filters);
                    return this.baseRequest<T>(
                        endpoint,
                        method,
                        data,
                        options,
                        progress,
                        resetActivity,
                    );
                }
            }),
        );
    }
    unauthenticatedRequest<T>(
        endpoint: string,
        filters?: RequestFilter,
        method: RequestMethod = "get",
        data?: any,
        options?: any,
        progress: boolean = true,
    ): Observable<HttpEvent<T> | undefined> {
        endpoint =
            [this.environment.services, endpoint].join("/") +
            queryStringFromFilters(filters);
        return this.baseRequest(endpoint, method, data, options, progress, false);
    }
    observableWithProgress<T>(obs: Observable<T>): Observable<T> {
        return of([]).pipe(
            tap(() => (this.progress = true)),
            mergeMap(() => obs),
            finalize(() => (this.progress = false)),
        );
    }

    protected getBackoffTime(attempt?: number): number {
        return attempt == undefined ? 0 : (
                Math.min(2 ** (attempt ?? 0) * 250 + Math.random() * 250, 32000)
            );
    }
    protected connectWebsocket(
        onConnect: (reconnect: boolean) => void = () => {},
        config: { reconnect: boolean; count?: number } = { reconnect: false },
    ): void {
        this._connectWebsocket(onConnect, config)
            .pipe(
                tap({
                    error: (error: any) => this.handleError(error),
                }),
                catchError((_) => EMPTY),
                mergeMap((obs: WebSocketSubject<WebsocketMessage>) => obs),
            )
            .subscribe({
                next: (msg: WebsocketMessage) => {
                    try {
                        this.onWebsocketMessage.next(msg);
                    } catch (e) {
                        console.log("Error receiving websocket message: " + e);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                },
            });
    }
    protected _connectWebsocket(
        onConnect: (reconnect: boolean) => void = () => {},
        config: { reconnect: boolean; count?: number } = { reconnect: false },
    ): Observable<WebSocketSubject<WebsocketMessage>> {
        if (!this.websocket || this.websocket.closed) {
            let services = this.environment.services;
            services = services.replace(/http(s)?:\/\//, "");
            const url = [
                this.environment.websocketScheme,
                services,
                this.environment.websocketEndpoint,
            ].join("/");
            return of(config).pipe(
                tap((config: { reconnect: boolean; count?: number }) => {
                    if (config.reconnect)
                        console.log(
                            "Session Websocket: Attempting reconnect in " +
                                this.getBackoffTime(config.count) +
                                "ms (attempt: " +
                                config.count +
                                ")...",
                        );
                    else console.log("Session Websocket: Connecting...");
                }),
                delay(this.getBackoffTime(config.count)), // Random backoff with a maximum of 32s,
                mergeMap((config: { reconnect: boolean; count?: number }) => {
                    this.websocket = createWebsocket<WebsocketMessage>({
                        url: url,
                        openObserver: {
                            next: () => {
                                config.count = 0;
                                console.log("Session Websocket: Connected.");
                                onConnect(config.reconnect);
                                if (config.reconnect) this.onReconnect.next();
                            },
                        },
                        closeObserver: {
                            next: () => {
                                console.log("Session Websocket: Connection closed.");
                                this.websocket = undefined;
                                this.onDisconnect.next();
                                this.connectWebsocket(onConnect, {
                                    reconnect: true,
                                    count: (config.count ?? -1) + 1,
                                });
                            },
                        },
                    });
                    return of(this.websocket);
                }),
            );
        } else {
            console.log("connectWebsocket called twice!");
        }
        return of(this.websocket);
    }
    protected closeWebsocket(): void {
        this.websocket?.complete();
    }

    getExtension(filename?: string): string | undefined {
        const parts = filename?.split(".");
        if (parts && (parts?.length === 1 || (parts[0] === "" && parts.length === 2)))
            return undefined;
        return parts?.pop();
    }

    protected _download(
        path: string | string[],
        filename?: string,
        filter?: RequestFilter,
        method: RequestMethod = "get",
        authenticated: boolean = true,
    ): Observable<HttpEvent<Blob> | undefined> {
        path =
            Array.isArray(path) ?
                [this.environment.services, ...path]
            :   [this.environment.services, "media", path];
        filter = filter ?? {};
        filter["seed"] = "" + Math.random();
        const endpoint = path.join("/") + queryStringFromFilters(filter);
        const options: any = { responseType: "blob" };
        const tokenObservable = authenticated ? this.auth.token() : of(undefined);
        return tokenObservable.pipe(
            mergeMap((token: string | undefined) => {
                if (token) {
                    const headers = options.headers || new HttpHeaders();
                    options.headers = headers.set("Authorization", "Bearer " + token);
                }
                return this.baseRequest<Blob>(
                    endpoint,
                    method,
                    undefined,
                    options,
                    false,
                );
            }),
        );
    }
    download(
        path: string | string[],
        filename?: string,
        filter?: RequestFilter,
        method: RequestMethod = "get",
        authenticated: boolean = true,
    ): void {
        const obs = this._download(path, filename, filter, method, authenticated).pipe(
            tap((blob: any) => {
                if (blob) {
                    const lastPart = Array.isArray(path) ? path[path.length - 1] : path;
                    filename = filename ?? lastPart ?? "downloaded-file";
                    const extension = this.getExtension(lastPart);
                    const fileExtension = this.getExtension(filename);
                    if (!fileExtension && extension) filename += "." + extension;
                    const a = document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                return blob;
            }),
        );
        this.observableWithProgress(obs).subscribe({
            next: () => {},
            error: (err: HttpErrorResponse) => {
                if (err.status == 500) {
                    console.log("Error downloading file: " + err.error);
                    this.message =
                        "Server error while downloading file, please contact the system administrator.";
                } else this.handleError(err);
            },
        });
    }
    downloadBlob(
        path: string | string[],
        filename?: string,
        filter?: RequestFilter,
        method: RequestMethod = "get",
        authenticated: boolean = true,
    ): Observable<Blob> {
        return this._download(path, filename, filter, method, authenticated).pipe(
            map((blob: any) => blob as Blob),
        );
    }

    handleError(err: HttpErrorResponse): Observable<any> {
        if (err?.error?.detail == "Account is locked.") {
            this.login(this.router.url);
        } else if (err && Array.isArray(err.error)) {
            for (let error of err.error) this.message = error;
        } else {
            this.message =
                err?.error?.error_description ??
                err?.error?.detail ??
                err?.error?.error ??
                err?.message ??
                "Internal Server Error";
            console.log(this.message);
        }
        throw err;
    }

    protected resetActivityTimer(): void {
        if (this.activityTimer) {
            clearTimeout(this.activityTimer);
            this.activityTimer = undefined;
        }
        if (this.currentAccount)
            this.activityTimer = setTimeout(
                () => this.onActivityTimer(),
                this.environment.activityTimeout * 1000,
            );
    }
    protected onActivityTimer(): void {
        this.logout(this.router.url);
    }

    protected setMessage(msg?: string, timer: number = 10000): void {
        if (!msg) this.messageList.forEach((m: Message) => this.clearMessage(m));
        else {
            const m: Message = { message: msg };
            m.timer = setTimeout(() => this.clearMessage(m), timer);
            const messageExists = this.messageList.some((message) =>
                message.message.includes(msg),
            );
            if (!messageExists) {
                this.messageList.push(m);
                this.onMessage.emit(m.message);
            }
        }
    }
    protected clearMessage(message: Message): void {
        if (message.timer) {
            clearTimeout(message.timer);
            message.timer = undefined;
        }
        this.messageList = this.messages.filter((m: Message) => m !== message);
    }
}
