<div class="breadcrumbs flex mat-elevation-z4" *ngIf="stackCount > 1">
    <div class="breadcrumb" *ngFor="let crumb of crumbs; index as idx">
        <button
            class="breadcrumb-button"
            mat-button
            color="primary"
            (click)="onSelect($event, crumb)"
            [disabled]="idx == crumbs.length - 1 || !crumb.onSelect"
        >
            <ng-container *ngIf="iconIsMaterial(crumb.icon)">
                <mat-icon>{{ crumb.icon! }}</mat-icon>
            </ng-container>
            <ng-container *ngIf="iconIsAwesome(crumb.icon)">
                <fa-icon [icon]="faIcon(crumb.icon!)"></fa-icon>
            </ng-container>
            {{ crumb.text }}
        </button>
    </div>
</div>
