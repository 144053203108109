<div
    class="wrapper flexible flex stack builder-list builder-form"
    *ngIf="group"
    cdkDropList
    [cdkDropListAutoScrollStep]="autoScrollStep"
    [cdkDropListConnectedTo]="dragDropService.dropLists"
    [cdkDropListEnterPredicate]="allowDropPredicate"
    (cdkDropListEntered)="onDragEnter($event)"
    (cdkDropListExited)="onDragExit($event)"
    (cdkDropListDropped)="onDrop($event)"
>
    <div
        class="row builder-field empty-group flex secondary"
        *ngIf="!sortedFields.length"
    >
        There are no fields in this group yet. Drag a field here from the toolbox on the
        left to add it to this group.
    </div>
    <div
        class="row builder-field flex builder-hover"
        [class.builder-field-group]="isGroupField(field)"
        [class.depth-even]="!(depth % 2)"
        *ngFor="let field of sortedFields; index as i"
        [formGroup]="group"
        cdkDrag
        [cdkDragData]="field"
        (cdkDragStarted)="onDragStart($event)"
        (cdkDragMoved)="onDragMoved($event)"
        (cdkDragEnded)="onDragEnd($event)"
        (cdkDragReleased)="onDragReleased($event)"
    >
        <div class="flexible builder-group flex stack" *ngIf="isGroupField(field)">
            <div class="full between" [class.group-header]="!!i">
                <h3>{{ field.displayName }}</h3>
            </div>
            <quill-view-html
                class="full subtitle"
                *ngIf="!viewOnly"
                [content]="subtitle(field)"
            ></quill-view-html>

            <div *cdkDragPreview class="row builder-field">
                <div class="full between" [class.group-header]="!!i">
                    <h3>{{ field.displayName }}</h3>
                </div>

                <quill-view-html
                    class="full subtitle"
                    *ngIf="!viewOnly"
                    [content]="subtitle(field)"
                ></quill-view-html>

                <div clas="builder-group-preview group-indent">
                    +{{ fieldChildren(field).length }} Fields
                </div>
            </div>
            <div class="group-indent flexible flex stack">
                <data-form-editor-group
                    class="flexible flex stack"
                    [form]="form"
                    [mode]="mode"
                    [viewOnly]="viewOnly"
                    [autoScrollStep]="autoScrollStep"
                    [parent]="this"
                    [useDragHandles]="useDragHandles"
                    [formField]="field"
                    [group]="fieldGroup(field)"
                    [fields]="fieldChildren(field)"
                    [productOptions]="productOptions"
                    (formFieldAdded)="onFormFieldAdded($event.field, $event.group)"
                    (formFieldRemoved)="onFormFieldRemoved($event.field, $event.group)"
                    (formFieldChanged)="onFormFieldChanged($event.field, $event.group)"
                ></data-form-editor-group>
            </div>
            <div class="builder-options fixed flex">
                <div class="flex stack option-button">
                    <div class="small">Configure</div>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="editFormField($event, field)"
                    >
                        <mat-icon>construction</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isBuilder">
            <div class="builder-placeholder" *cdkDragPlaceholder></div>
        </ng-container>
        <data-form-field
            class="flexible"
            *ngIf="!isGroupField(field)"
            [field]="field"
            [value]="fieldValues(field)"
            [control]="fieldControl(field)"
            [mode]="mode"
            [productOptions]="productOptions"
            [allowPartial]="allowPartial"
        >
            <div
                class="builder-options fixed flex"
                [class.multiple]="isMultiple(field)"
            >
                <div class="flex stack option-button" *ngIf="!isControlField(field)">
                    <div class="small">Required</div>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="toggleRequired($event, field)"
                    >
                        <mat-icon *ngIf="isRequired(field)" class="accent">
                            done
                        </mat-icon>
                        <mat-icon *ngIf="!isRequired(field)" class="error">
                            clear
                        </mat-icon>
                    </button>
                </div>
                <div class="flex stack option-button" *ngIf="!isControlField(field)">
                    <div class="small">Multiple</div>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="toggleMultiple($event, field)"
                    >
                        <mat-icon *ngIf="isMultiple(field)" class="accent">
                            done
                        </mat-icon>
                        <mat-icon *ngIf="!isMultiple(field)" class="error">
                            clear
                        </mat-icon>
                    </button>
                </div>
                <div class="flex stack option-button">
                    <div class="small">Configure</div>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="editFormField($event, field)"
                    >
                        <mat-icon>construction</mat-icon>
                    </button>
                </div>
            </div>
        </data-form-field>
        <div
            *ngIf="isBuilder && useDragHandles"
            class="builder-drag-handle"
            cdkDragHandle
        >
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
        </div>
    </div>
</div>
