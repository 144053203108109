import { AccountService, CapabilityService } from "src/services/iam.services";
import { Component, inject, ViewChild } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { Program } from "../../../services/models/program";
import { Account } from "../../../services/models/account";
import {
    AssignmentReferenceFactory,
    AssignmentService,
    InquiryService,
    ProgramService,
    TaskFactory,
} from "../../../services/program.services";
import { DataPoint } from "../../../common/utilities/utilities";
import { EChartsOption, registerMap } from "echarts";
import { contentStack } from "src/common/components/content-stack.component";
import { PatientsComponent } from "src/program/components/patients/patients.component";
import { Countries, Country, latlong } from "src/services/models/country";
import { map, skipWhile, startWith, take } from "rxjs/operators";
import {
    Breadcrumb,
    BreadcrumbProvider,
} from "src/common/components/breadcrumbs/breadcrumb.component";
import { APIListResult, ProgramReference } from "src/services/models/api-object";
import {
    DataFieldService,
    DataFormFieldFactory,
    DataFormService,
    DataTypeService,
} from "src/services/data.services";
import { TodosComponent } from "src/program/components/todos/todos.component";
import { PaginatedPatientsComponent } from "src/program/components/patients/paginated-patients.component";
import { interval } from "rxjs";

type CountryProductDataPoint = {
    program: string;
    country: string;
    count: number;
};
type CountryTotal = { [code: string]: number };

type FilterOption = {
    displayName: string;
    amountOfDays: string;
};
type ProgramOption = {
    name?: string;
    id?: string;
};

type FilterState = {
    [key: string]: { index: number; programFilter: string };
};
@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent extends SessionComponent implements BreadcrumbProvider {
    protected inquiryService: InquiryService;
    protected programService: ProgramService;
    protected accountService: AccountService;
    dailyInquiryOptions?: EChartsOption;
    dailyInquiryUpdate?: any;
    inquiryStatusOptions?: EChartsOption;
    inquiryStatusUpdate?: any;
    caseStatusOptions?: EChartsOption;
    caseStatusUpdate?: any;
    shipmentsOptions: EChartsOption = {
        title: {
            text: "Shipments by Day",
            subtext: "Past 30 Days",
        },
        legend: { show: false },
        tooltip: {
            trigger: "none",
            axisPointer: { type: "line" },
        },
        xAxis: {
            type: "category",
            axisTick: { alignWithLabel: true },
            axisLine: { onZero: false },
            nameLocation: "middle",
            axisPointer: {
                label: {
                    formatter: (params: any) =>
                        params.value +
                        (params.seriesData.length ?
                            ": " + params.seriesData[0].data
                        :   ""),
                },
            },
            data: [],
        },
        yAxis: {
            type: "value",
            nameLocation: "middle",
        },
        series: [
            {
                type: "line",
                data: [],
            },
        ],
    };
    productCountryOptions?: EChartsOption;
    productCountryUpdate?: any;
    institutionListOptions?: EChartsOption;
    institutionListUpdate?: any;
    programs: ProgramOption[] = [{ name: "All Programs", id: "" }];

    static get breadcrumb(): Breadcrumb {
        return {
            icon: "home",
            text: "Dashboard",
            onSelect: () => contentStack?.push(DashboardComponent, 0),
        };
    }
    getBreadcrumbs(current: boolean): Breadcrumb | Breadcrumb[] | undefined {
        return {
            icon: "home",
            text: "Dashboard",
        };
    }

    constructor() {
        super();
        this.inquiryService = inject(InquiryService);
        this.programService = inject(ProgramService);
        this.accountService = inject(AccountService);

        inject(TaskFactory);
        inject(AssignmentReferenceFactory);
        inject(DataFormService);
        inject(DataFormFieldFactory);
        inject(DataTypeService);
        inject(DataFieldService);
        inject(CapabilityService);
        this.session.onLogout.subscribe(() => this.clearIntervals());

        this.session.http.get("assets/map/world.json").subscribe((worldJson: any) => {
            registerMap("world", worldJson);
            interval(100)
                .pipe(
                    startWith(0),
                    skipWhile(
                        () =>
                            !this.patients?.list.hasLoaded &&
                            !this.todos?.list.hasLoaded,
                    ),
                    take(1),
                )
                .subscribe(() => {
                    this.updateCharts();
                });
        });
    }

    @ViewChild(TodosComponent) todos?: TodosComponent;
    @ViewChild(PaginatedPatientsComponent) patients?: PaginatedPatientsComponent;

    protected onCurrentAccountChanged(a: Account | undefined) {
        super.onCurrentAccountChanged(a);
        this.clearIntervals();
        if (a) {
            if (a) {
                interval(100)
                    .pipe(
                        startWith(0),
                        skipWhile(
                            () =>
                                !this.patients?.list.hasLoaded &&
                                !this.todos?.list.hasLoaded,
                        ),
                        take(1),
                    )
                    .subscribe(() => {
                        this.inquiryStatusUpdate = setInterval(
                            () => this.updateCharts(),
                            300000,
                        );
                    });
            }
        } else {
            clearInterval(this.inquiryStatusUpdate);
            this.inquiryStatusUpdate = undefined;
        }
        this.updatePrograms();
        this.updateDashboardRole();
    }

    updateDashboardRole(): void {
        this.accountService
            .dashboardRole(this.currentAccount)
            .subscribe(
                (role: any) => (this.dashboardRole = role ? role["role"] : "none"),
            );
    }

    updatePrograms(): void {
        if (this.currentAccount?.id) {
            this.programService
                .list({
                    admin: this.currentAccount.id || "0",
                    use_reference: "True",
                })
                .pipe(
                    map(
                        (results: APIListResult<ProgramReference>) =>
                            results as ProgramReference[],
                    ),
                )
                .subscribe((programs: ProgramReference[]) => {
                    if (Array.isArray(programs)) {
                        const newPrograms: ProgramOption[] = [
                            { name: "All Programs", id: "" },
                        ];
                        programs.forEach((program: ProgramReference) => {
                            newPrograms.push({ name: program.name, id: program.id });
                        });
                        this.programs = newPrograms;
                    }
                });
        } else {
            this.programs = [{ name: "All Programs", id: "" }];
        }
    }
    get canSeeCharts(): boolean {
        return this.programs.length > 1;
    }

    showInquiries(event: MouseEvent): void {
        this.terminateEvent(event);
        contentStack?.push(
            {
                type: PatientsComponent,
            },
            1,
        );
    }
    dateTickFormat(value: string): string {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const date = new Date(value);
        if (date.getDay() == 1) {
            return date.getDate() + " " + months[date.getMonth()];
        }
        return "";
    }

    clearIntervals(): void {
        if (this.dailyInquiryUpdate) clearInterval(this.dailyInquiryUpdate);
        if (this.inquiryStatusUpdate) clearInterval(this.inquiryStatusUpdate);
    }
    updateCharts(): void {
        this.updateDailyInquiryData();
        this.updateInquiryStatusData();
        this.updateCaseStatusData();
        this.updateInstitutionData();
        this.updateProductCountryData();
    }

    filtersState: FilterState = {
        // these each default to filter by the last 30 days
        inquriyCount: { index: 2, programFilter: "" },
        inquiryInstituions: { index: 2, programFilter: "" },
        inquiryStatus: { index: 2, programFilter: "" },
        caseStatus: { index: 2, programFilter: "" },
        productCountry: { index: 2, programFilter: "" },
    };

    dropDownFilterOptions: FilterOption[] = [
        { displayName: "Last Day", amountOfDays: "1" },
        { displayName: "Last 7 Days", amountOfDays: "7" },
        { displayName: "Last 30 Days", amountOfDays: "30" },
        { displayName: "Last Year", amountOfDays: "365" },
        { displayName: "All Time", amountOfDays: "0" },
    ];
    async onFilterChange(
        option: FilterOption | null,
        key: string,
        programId: string = "",
    ): Promise<void> {
        const updateFilters = async () => {
            if (option) {
                const newFilter = this.dropDownFilterOptions.indexOf(option);
                if (newFilter != -1) {
                    this.filtersState[key].index = newFilter;
                } else {
                    this.filtersState[key].index = 2;
                }
            }
            if (programId.length) {
                this.filtersState[key].programFilter = programId;
            } else {
                this.filtersState[key].programFilter = "";
            }
        };
        await updateFilters(); // to ensure state is updated first before updating the charts
        this.updateCharts();
    }
    updateDailyInquiryData(): void {
        const { amountOfDays, displayName } =
            this.dropDownFilterOptions[this.filtersState.inquriyCount.index];

        const params = {
            chart: "daily",
            days: amountOfDays,
            admin: this.currentAccount?.id ?? "",
            programId: "",
        };
        if (this.filtersState.inquriyCount?.programFilter) {
            params.programId = this.filtersState.inquriyCount.programFilter;
        }

        this.inquiryService
            .action("data", params, undefined, "get", undefined, true, false)
            .subscribe((result: any) => {
                const dayOffSet = Number(
                    this.dropDownFilterOptions[this.filtersState.inquriyCount.index]
                        .amountOfDays,
                );
                const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                let series = [];
                let axis: string[] = [];
                const now: Date = new Date();
                const then: Date = new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate(),
                );
                then.setDate(then.getDate() - dayOffSet);
                while (then < now) {
                    const r: { date: string; value: number } = result.find((r: any) => {
                        const d = new Date(r.date);
                        return (
                            d.getFullYear() == then.getFullYear() &&
                            d.getMonth() == then.getMonth() &&
                            d.getDate() == then.getDate()
                        );
                    });
                    const value = r ? r.value : 0;
                    const name = days[then.getDay()] + " " + then.getDate().toString();
                    const point = { name: then.toDateString(), value: value };
                    axis.push(name);
                    series.push(point);
                    then.setDate(then.getDate() + 1);
                }

                this.dailyInquiryOptions = {
                    title: {
                        text: "Inquiries by Day",
                        subtext: displayName,
                    },
                    legend: { show: false },
                    tooltip: {
                        trigger: "none",
                        axisPointer: { type: "line" },
                    },
                    xAxis: {
                        type: "category",
                        axisTick: { alignWithLabel: true },
                        axisLine: { onZero: false },
                        nameLocation: "middle",
                        axisPointer: {
                            label: {
                                formatter: (params: any) =>
                                    params.value +
                                    (params.seriesData.length ?
                                        ": " + params.seriesData[0].data
                                    :   ""),
                            },
                        },
                        data: axis,
                    },
                    yAxis: {
                        type: "value",
                        nameLocation: "middle",
                    },
                    series: [
                        {
                            type: "line",
                            data: series,
                        },
                    ],
                    color: [
                        "#5a7997",
                        "#91afcd",
                        "#bbdddd",
                        "#2f9582",
                        "#2f80ed",
                        "#223554",
                    ],
                };
            });
    }

    updateStatusData(
        filterStateKey: "inquiryStatus" | "caseStatus",
        chartType: string,
        titleText: string,
        seriesName: string,
        optionsKey: "inquiryStatusOptions" | "caseStatusOptions",
    ): void {
        const { amountOfDays, displayName } =
            this.dropDownFilterOptions[this.filtersState[filterStateKey].index];
        const params = {
            chart: chartType,
            days: amountOfDays,
            admin: this.currentAccount?.id ?? "",
            programId: "",
        };
        if (this.filtersState[filterStateKey].programFilter) {
            params.programId = this.filtersState[filterStateKey].programFilter;
        }

        this.inquiryService
            .action<
                DataPoint[]
            >("data", params, undefined, "get", undefined, true, false)
            .subscribe((result: DataPoint[] | undefined) => {
                result = result ?? [];
                const series = result;
                this[optionsKey] = {
                    title: {
                        text: titleText,
                        subtext: displayName,
                    },
                    tooltip: {
                        trigger: "item",
                    },
                    legend: {
                        bottom: "5%",
                        left: "center",
                    },
                    series: [
                        {
                            name: seriesName,
                            type: "pie",
                            data: series,
                            radius: ["40%", "70%"],
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: "#fff",
                                borderWidth: 2,
                            },
                            label: {
                                show: false,
                                position: "center",
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontWeight: "bold",
                                },
                            },
                            labelLine: {
                                show: false,
                            },
                        },
                    ],
                    color: [
                        "#5a7997",
                        "#91afcd",
                        "#bbdddd",
                        "#2f9582",
                        "#2f80ed",
                        "#223554",
                    ],
                };
            });
    }

    updateInquiryStatusData(): void {
        this.updateStatusData(
            "inquiryStatus",
            "status-inquiry",
            "Recent Inquiries by Status",
            "Inquiries Status",
            "inquiryStatusOptions",
        );
    }

    updateCaseStatusData(): void {
        this.updateStatusData(
            "caseStatus",
            "status-case",
            "Recent Cases by Status",
            "Case Status",
            "caseStatusOptions",
        );
    }

    updateProductCountryData(): void {
        const { amountOfDays, displayName } =
            this.dropDownFilterOptions[this.filtersState.productCountry.index];
        const params = {
            chart: "product-country",
            days: amountOfDays,
            admin: this.currentAccount?.id ?? "",
            programId: "",
        };
        if (this.filtersState.productCountry.programFilter) {
            params.programId = this.filtersState.productCountry.programFilter;
        }
        this.inquiryService
            .action<
                CountryProductDataPoint[]
            >("data", params, undefined, "get", undefined, true, false)
            .subscribe((result: CountryProductDataPoint[] | undefined) => {
                result = result ?? [];
                const countryTotals: CountryTotal = {};
                const programTotals: { [program: string]: CountryTotal } = {};
                const countryBreakdown: { [code: string]: CountryTotal } = {};
                let max = 0;
                result.forEach((r: CountryProductDataPoint) => {
                    const country = countryTotals[r.country] || 0;
                    countryTotals[r.country] = country + r.count;
                    if (country + r.count > max) max = country + r.count;
                    const program = programTotals[r.program] || {};
                    const pcountry = program[r.country] || 0;
                    program[r.country] = pcountry + r.count;
                    programTotals[r.program] = program;
                    const breakdown = countryBreakdown[r.country] || {};
                    const bprogram = breakdown[r.program] || 0;
                    breakdown[r.program] = bprogram + r.count;
                    countryBreakdown[r.country] = breakdown;
                });
                const countryTotalToData = (dict: CountryTotal) => {
                    return Object.keys(countryTotals).map((code: string) => {
                        return {
                            name:
                                Countries.find((c: Country) => c.value == code)
                                    ?.display_name ?? "Unknown",
                            groupId: code,
                            value: [
                                latlong[code]?.longitude || -200,
                                latlong[code]?.latitude || -50,
                                dict[code] || 0,
                            ],
                        };
                    });
                };
                const countryData = countryTotalToData(countryTotals);
                this.productCountryOptions = {
                    title: {
                        text: "Recent Inquiries by Country/Product",
                        subtext: displayName,
                    },
                    tooltip: {
                        trigger: "item",
                        formatter: (params: any) => {
                            const breakdown =
                                countryBreakdown[params.data.groupId] || {};
                            const programs = Object.keys(breakdown).map(
                                (program: string) =>
                                    program +
                                    ": <b>" +
                                    (breakdown[program] || 0) +
                                    "</b>",
                            );
                            programs.unshift("<b>" + params.name + "</b>");
                            return programs.join("<br />");
                        },
                    },
                    visualMap: {
                        show: false,
                        min: 0,
                        max: max,
                        inRange: {
                            symbolSize: [6, 60],
                        },
                    },
                    geo: {
                        name: "World Population (2010)",
                        type: "map",
                        map: "world",
                        roam: false,
                    },
                    series: [
                        {
                            name: "Country Total",
                            type: "scatter",
                            coordinateSystem: "geo",
                            data: countryData,
                        },
                    ],
                    color: [
                        "#5a7997",
                        "#91afcd",
                        "#bbdddd",
                        "#2f9582",
                        "#2f80ed",
                        "#223554",
                    ],
                };
            });
    }
    updateInstitutionData(): void {
        const { amountOfDays, displayName } =
            this.dropDownFilterOptions[this.filtersState.inquiryInstituions.index];
        const params = {
            chart: "institution",
            days: amountOfDays,
            admin: this.currentAccount?.id ?? "",
            programId: "",
        };
        if (this.filtersState.inquiryInstituions.programFilter) {
            params.programId = this.filtersState.inquiryInstituions.programFilter;
        }
        this.inquiryService
            .action<
                DataPoint[]
            >("data", params, undefined, "get", undefined, true, false)
            .subscribe((result: DataPoint[] | undefined) => {
                result = result ?? [];
                result.sort((a: DataPoint, b: DataPoint) => a.value - b.value);
                const topCount = result.length > 10 ? 9 : result.length;

                const top = result.slice(0, topCount);
                if (topCount != result.length) {
                    const other = result
                        .slice(topCount)
                        .reduce((a: number, b: DataPoint) => a + b.value, 0);
                    top.push({ name: "Other", value: other });
                }
                this.institutionListOptions = {
                    title: {
                        text: "Recent Inquiries by Institution",
                        subtext: displayName,
                    },
                    yAxis: {
                        type: "category",
                        axisLabel: {
                            inside: true,
                            color: "#000",
                        },
                        z: 4,
                        axisLine: {
                            symbolSize: [10, 0],
                        },
                        data: top.map((d: DataPoint) => (d.name ? d.name : "Unknown")),
                    },
                    xAxis: {
                        type: "value",
                    },
                    series: [
                        {
                            data: top.map((d: DataPoint) => d.value),
                            type: "bar",
                            z: 0,
                        },
                    ],
                    color: ["#bbdddd", "#2f9582", "#2f80ed", "#223554"],
                };
            });
    }
}
