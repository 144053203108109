<div class="wrapper absolute flex">
    <div cdkScrollable class="tabs-wrapper" *ngIf="tabs.length">
        <div class="tabs mat-elevation-z2">
            <div
                class="tab"
                *ngFor="let tab of tabs.slice().reverse()"
                (click)="selectTab(tab)"
                [matTooltip]="tab.tooltip || ''"
                matTooltipPosition="after"
                (contextmenu)="showContextMenu($event, tab)"
            >
                <div class="active" *ngIf="tab === selectedTab"></div>
                <div class="changed" *ngIf="hasChanges(tab)"></div>
                <mat-icon
                    *ngIf="!tab.sticky"
                    class="tab-close"
                    inline
                    (click)="closeTab(tab)"
                >
                    cancel
                </mat-icon>
                <mat-icon *ngIf="iconIsMaterial(tab)">{{ tab.icon }}</mat-icon>
                <fa-icon
                    *ngIf="iconIsAwesome(tab) && !!tab.icon"
                    class="row-icon fixed"
                    [icon]="faIcon(tab)"
                ></fa-icon>
                <div *ngIf="!!tab.label" class="tab-label">{{ tab.label }}</div>
            </div>
        </div>
    </div>
    <div class="tab-content">
        <div class="content-header">
            <div class="breadcrumbs">
                <div class="breadcrumb" *ngFor="let tab of breadcrumbs; index as idx">
                    <span *ngIf="!!idx">&nbsp;/&nbsp;</span>
                    <button mat-button (click)="selectCrumb($event, idx)">
                        {{ tab.breadcrumb || tab.title || tab.label || tab.tooltip }}
                    </button>
                </div>
            </div>
            <div class="title" *ngIf="currentTab">
                {{ currentTab.title || currentTab.label || currentTab.tooltip }}
            </div>
            <div class="sub-title small secondary" *ngIf="currentTab?.subtitle">
                {{ currentTab?.subtitle }}
            </div>
        </div>
        <div class="content-container">
            <ng-template #tabContentTemplate></ng-template>
        </div>
    </div>
</div>
<div
    class="context-menu-trigger"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu class="context-menu" #contextMenu="matMenu">
    <ng-template matMenuContent let-tab="tab">
        <div
            (mouseleave)="closeContextMenu($event)"
            (contextmenu)="$event.preventDefault()"
        >
            <button
                mat-menu-item
                class="context-menu-item"
                [disabled]="tab.sticky"
                (click)="!tab.sticky && closeTab(tab)"
            >
                Close Tab
            </button>
            <button mat-menu-item class="context-menu-item" (click)="closeAllTabs()">
                Close All Tabs
            </button>
            <button
                mat-menu-item
                class="context-menu-item"
                (click)="closeOtherTabs(tab)"
            >
                Close Other Tabs
            </button>
        </div>
    </ng-template>
</mat-menu>
