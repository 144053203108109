import { APIListResult, ObjectOrReference } from "src/services/models/api-object";
import { DataTypeEditorComponent } from "./data-type.component";
import { FormControl } from "@angular/forms";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";
import { DataType } from "src/services/models/data";
import { SearchableListComponent } from "../../searchable-list.component";
import { Sort } from "@angular/material/sort";
import { Case } from "src/services/models/case";
import { Program } from "src/services/models/program";
import { ObjectView } from "../../object-admin.component";
import { ObjectViewEntryPoint, ObjectViewMode } from "../../object.component";
import { DataTypeService } from "src/services/data.services";
import { ObjectRepository } from "src/services/models/compound";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "data-type-admin",
    templateUrl: "./data-type-admin.component.html",
    styleUrls: ["../data-admin.component.scss"],
})
export class DataTypeAdminComponent extends SearchableListComponent<DataType> {
    static readonly ExcludedDataTypes = [
        "instructions",
        "select.program",
        "group",
        "object.reference",
    ];

    objectView: ObjectView<DataType> | undefined = DataTypeEditorComponent;
    @ViewChild("search") searchElement: ElementRef<any> | undefined;

    @Input() set repository(v: ObjectRepository | undefined) {
        this._repository = v;
        this.list.refresh();
        this.updateAllDataTypes();
    }
    get repository(): ObjectRepository | undefined {
        return this._repository;
    }
    protected _repository?: ObjectRepository;

    searchTermControl: FormControl = new FormControl();
    showSearch: boolean = false;
    allTypes: ObjectOrReference<DataType>[] = [];

    get displayedColumns(): string[] {
        return ["display_name", "is_compound", "owner_id", "used_in", "actions"];
    }

    constructor(
        protected service: DataTypeService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
    }

    protected updateAllDataTypes(): void {
        // We need to get the full list of types to pass to the editor
        const filters = this.filter({});
        this.service.list(filters).subscribe((result: APIListResult<DataType>) => {
            this.allTypes = result as ObjectOrReference<DataType>[];
        });
    }

    protected override postSearch(list: DataType[]): DataType[] {
        return list.filter(
            (type: DataType) =>
                DataTypeAdminComponent.ExcludedDataTypes.indexOf(type.name ?? "") == -1,
        );
    }

    canEdit(type: DataType): boolean {
        return (
            this.currentAccount?.hasRole("object.edit", type.owner) ||
            this.currentAccount?.hasRole("object.admin", type.owner) ||
            this.currentAccount?.hasRole("organization.administrator", type.owner) ||
            this.currentAccount?.isSystemAdministrator ||
            (type.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.edit",
                    (type.owner as Program).organization,
                )) ||
            (type.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (type.owner as Program).organization,
                )) ||
            (type.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (type.owner as Program).organization,
                )) ||
            false
        );
    }
    canDuplicate(type: DataType): boolean {
        return this.canEdit(type) && !type.isBasic;
    }
    hasDeletePermission(type: DataType): boolean {
        const hasPermission =
            this.currentAccount?.hasRole("object.admin", type.owner) ||
            this.currentAccount?.hasRole("organization.administrator", type.owner) ||
            this.currentAccount?.isSystemAdministrator ||
            (type.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "object.admin",
                    (type.owner as Program).organization,
                )) ||
            (type.owner?.type == "program.program" &&
                this.currentAccount?.hasRole(
                    "organization.administrator",
                    (type.owner as Program).organization,
                )) ||
            false;
        return hasPermission;
    }
    canDelete(type: DataType): boolean {
        return this.hasDeletePermission(type) && !type.used_by && !type.used_in;
    }

    editObject(
        event: MouseEvent | undefined,
        object: DataType,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<DataType> | undefined {
        const instance = super.editObject(
            event,
            object,
            asDialog,
            viewOnly,
        ) as DataTypeEditorComponent;
        instance.autosave = false;
        instance.dataTypes = this.allTypes;
        return instance;
    }
    duplicateObject(
        event: MouseEvent,
        object: DataType,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<DataType> | undefined {
        const duplicate = object.duplicate(object.owner ?? this.repository);
        const instance = this.editObject(event, duplicate, asDialog);
        (instance as DataTypeEditorComponent)?.formGroup.markAsDirty();
        if (asDialog && instance?.dialogReference)
            instance.dialogReference
                .afterClosed()
                .subscribe((form: DataType) => this.onAfterCreate(form, instance));
        return instance;
    }
    newObject(data?: any): DataType | undefined {
        return super.newObject({
            ...data,
            owner: this.repository?.asReference,
        });
    }
    protected objectDialogConfiguration(
        object: DataType,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            minWidth: "50vw",
        };
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        if (this.repository?.type == "program.case")
            filters["repo"] =
                this.repository.id + "," + (this.repository as Case).shared.id;
        else if (this.repository?.type == "program.program")
            filters["repo"] =
                this.repository.id + "," + (this.repository as Program).organization.id;
        else filters["repo"] = this.repository?.id ?? "";
        filters["exclude"] = DataTypeAdminComponent.ExcludedDataTypes.join(",");
        return filters;
    }
}
